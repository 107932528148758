import { FC, useEffect, useState, useCallback, useContext } from 'react';
import { Alert, Inline, Stack, SmallText, H6, Card, Button, Icon } from '@parallel-mono/components';
import styled from 'styled-components';
import { get, isEmpty, isEqual } from 'lodash';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import cx from 'classnames';

import SwitchImg from './images/switch.svg';
import SelectedImg from './images/selected.svg';
import SelectAccount from './SelectAccount';
import ComingSoon from './ComingSoon';

import { ToastBody, Image } from '@/components';
import { truncateTextMid } from '@/utils/format';
import { useAccount, useModal } from '@/hooks';
import { WalletContext, Wallet, WalletType } from '@/contexts/WalletsContext';

const Container = styled(Card)`
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 23.5rem;
  padding: 1.5rem 1.5rem 2rem;
  z-index: 9997;
`;
const StackMargin = styled(Stack)`
  margin-top: 0.75rem;
`;
const InlineBorder = styled(Inline).attrs({
  inset: '0.75rem 1.25rem 0.75rem 0.75rem',
  gap: '0.5rem'
})`
  height: 4.25rem;
  border: 1px solid var(--clr-gray06);
  border-radius: 1rem;
  background-size: cover;
`;
const ImageWrapper = styled.div`
  width: 2.75rem;
  width: 2.75rem;
  padding: 0.375rem;
  background-color: white;
  border-radius: 50%;
  box-shadow: var(--box-shadow01);
`;
const StackEllipsis = styled(Stack)`
  max-width: 8rem;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const BlackTextSmallBg = styled(SmallText)`
  padding: 0 0.375rem;
  background: var(--clr-gray05);
  border-radius: 0.25rem;
`;
const InlineFlexEnd = styled(Inline)`
  justify-self: flex-end;
  margin-left: auto;
`;
const AccountName = styled(H6)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface WalletsListProps {
  opened: boolean;
}

const openNewPage = (link: string) => {
  window.open(link, '_blank');
};

const WalletsList: FC<WalletsListProps> = ({ opened }) => {
  const { account, loadAccounts } = useAccount();
  const { wallet: activeWallet, walletList, handleSwitchWallet } = useContext(WalletContext);
  const [openSwitchModal, setOpenSwitchModal] = useState(false);
  const [copyState, copyToClipboard] = useCopyToClipboard();

  const { holder: comingSoonModal, openModal: openComingSoonModal } = useModal(ComingSoon, {
    size: '500px',
    closable: false,
    classNames: {
      modal: cx('compact'),
      header: cx('compact')
    }
  });

  const handleConnect = useCallback(
    (type: WalletType) => {
      handleSwitchWallet(type);
      loadAccounts();
    },
    [handleSwitchWallet, loadAccounts]
  );

  const handleClickInstall = useCallback((wallet: Wallet) => {
    const { extensionLink } = wallet;
    openNewPage(extensionLink);
  }, []);

  useEffect(() => {
    const { value, error } = copyState;
    if (value) {
      toast(<ToastBody type="success" text="Address copied!" />, { autoClose: 3000 });
    }
    if (error) {
      toast.error(<ToastBody type="error" text={error.message} />, { autoClose: 3000 });
    }
  }, [copyState]);

  const handleWalletClick = useCallback(
    (enable: boolean, type: WalletType) => {
      if (enable) {
        handleSwitchWallet(type);
      }
    },
    [handleSwitchWallet]
  );

  const renderWalletActions = useCallback(
    (wallet: Wallet) => {
      const { enable, showComingSoonDetail, walletInstalled } = wallet;
      if (!enable) {
        return (
          <SmallText
            style={{
              cursor: showComingSoonDetail ? 'pointer' : 'auto'
            }}
            onClick={() => {
              if (showComingSoonDetail) {
                openComingSoonModal(wallet);
              }
            }}
          >
            Coming soon
          </SmallText>
        );
      }
      if (activeWallet?.type === wallet.type && account) {
        return (
          <>
            <Button
              onClick={e => {
                e.stopPropagation();
                setOpenSwitchModal(true);
              }}
              variant="link"
            >
              <Image src={SwitchImg} width="1.5rem" />
            </Button>
            <Image src={SelectedImg} width="1.5rem" />
          </>
        );
      }
      if (walletInstalled) {
        return (
          <Button size="small" skin="secondary" onClick={() => handleConnect(wallet.type)}>
            Connect
          </Button>
        );
      }
      if (!walletInstalled && isEmpty(wallet.accounts)) {
        return (
          <Button size="small" skin="secondary" onClick={() => handleClickInstall(wallet)}>
            Install
          </Button>
        );
      }
      return null;
    },
    [account, activeWallet?.type, handleClickInstall, handleConnect, openComingSoonModal]
  );

  return (
    <>
      {opened && (
        <Container>
          <SmallText skin="secondary">Wallets</SmallText>
          <StackMargin gap="0.5rem">
            {Object.values(walletList).map(wallet => {
              const { type, walletName, label, selectedAccount, enable, icon, bgImage } = wallet;
              const accountName = get(selectedAccount, 'meta.name');
              const address = get(selectedAccount, 'address');
              const clickable = enable && !isEmpty(wallet.accounts);
              return (
                <Stack gap="0.5rem" key={type}>
                  <InlineBorder
                    style={{
                      backgroundImage: bgImage,
                      cursor: clickable ? 'pointer' : 'auto'
                    }}
                    onClick={() => clickable && handleWalletClick(enable, type)}
                  >
                    <ImageWrapper>{icon}</ImageWrapper>
                    <StackEllipsis gap=".25rem">
                      <AccountName>{accountName || walletName}</AccountName>
                      <Inline gap="0.25rem">
                        {enable && address ? (
                          <>
                            <Button
                              variant="link"
                              onClick={e => {
                                e.stopPropagation();
                                copyToClipboard(address);
                              }}
                            >
                              <Icon size="small" name="copy" />
                            </Button>
                            <SmallText>{truncateTextMid(address, 4, 4)}</SmallText>
                          </>
                        ) : (
                          <BlackTextSmallBg>{label}</BlackTextSmallBg>
                        )}
                      </Inline>
                    </StackEllipsis>
                    <InlineFlexEnd gap="1rem" alignItems="center">
                      {renderWalletActions(wallet)}
                    </InlineFlexEnd>
                  </InlineBorder>
                  {activeWallet?.type === wallet.type &&
                    activeWallet.walletInstalled &&
                    isEqual(get(activeWallet, 'accounts'), []) && (
                      <Alert type="error">
                        No wallet account detected on your selected extension. Please make sure you
                        are logged in or have an account set up.
                      </Alert>
                    )}
                </Stack>
              );
            })}
          </StackMargin>
          {/* <GrayTextBigAlign fontWeight="medium">Log Out</GrayTextBigAlign> */}
          {activeWallet && activeWallet.accounts && (
            <SelectAccount
              walletType={activeWallet.type}
              opened={openSwitchModal}
              closeCallback={() => {
                setOpenSwitchModal(false);
              }}
            />
          )}
        </Container>
      )}
      {comingSoonModal}
    </>
  );
};

export default WalletsList;
