import { useState, useRef, useEffect, MutableRefObject } from 'react';
import { AnyFunction, Codec } from '@polkadot/types/types';
import { StorageEntryTypeLatest } from '@polkadot/types/interfaces';
import { PromiseResult } from '@polkadot/api/types';
import { isUndefined } from 'lodash';

type VoidFn = () => void;
type TrackFnResult = Promise<unknown>;
interface QueryTrackFn {
  (...params: any[]): TrackFnResult;
  meta?: {
    type?: StorageEntryTypeLatest;
  };
}

interface QueryMapFn extends QueryTrackFn {
  meta: {
    type: StorageEntryTypeLatest;
  };
}

export type TrackFn = PromiseResult<AnyFunction> | QueryTrackFn;

interface Tracker {
  isActive: boolean;
  serialized: string | null;
  subscriber: TrackFnResult | null;
}

type CallFn = (...params: unknown[]) => Promise<VoidFn>;

export interface Options<T> {
  defaultValue?: T;
  transform?: (value: any, params?: any) => T;
  withParams?: boolean;
  withParamsTransform?: boolean;
}

const isMapFn = (fn: unknown): fn is QueryMapFn => {
  return !!(fn as QueryTrackFn).meta?.type?.isMap;
};

export const unsubscribe = (tracker: MutableRefObject<Tracker>) => {
  tracker.current.isActive = false;
  if (tracker.current.subscriber) {
    tracker.current.subscriber.then(unsubFn => (unsubFn as VoidFn)()).catch(console.error);
    tracker.current.subscriber = null;
  }
};

const subscribe = <T>(
  tracker: MutableRefObject<Tracker>,
  fn: TrackFn,
  params: any[],
  setValue: (value: T) => void,
  { transform = value => value, withParamsTransform, withParams }: Options<T>
) => {
  const validParams = params.filter(p => !isUndefined(p));

  unsubscribe(tracker);

  setTimeout(() => {
    const canQuery =
      !!fn && (isMapFn(fn) ? validParams.length === fn.meta.type.asMap.hashers.length : true);

    if (canQuery) {
      tracker.current.isActive = true;

      tracker.current.subscriber = (fn as CallFn)(...validParams, (value: Codec) => {
        if (tracker.current.isActive) {
          if (withParams) {
            setValue([params, transform(value)] as any);
          } else {
            setValue(withParamsTransform ? transform(value, params) : transform(value));
          }
        }
      });
    } else {
      tracker.current.subscriber = null;
    }
  }, 0);
};

const useApiCall = <T>(
  fn: TrackFn | undefined,
  params?: any[] | null,
  options: Options<T> = {}
): T | undefined => {
  const [value, setValue] = useState<T | undefined>((options || {}).defaultValue);
  const tracker = useRef<Tracker>({ isActive: false, serialized: null, subscriber: null });

  useEffect(() => {
    return () => unsubscribe(tracker);
  }, []);

  useEffect(() => {
    if (fn) {
      const serialized = JSON.stringify({ f: fn.toString(), p: params });
      if (serialized !== tracker.current.serialized) {
        tracker.current.serialized = serialized;
        subscribe<T>(tracker, fn, params || [], setValue, options);
      }
    }
  }, [fn, params, options]);

  return value;
};

export default useApiCall;
