import { useState, useEffect } from 'react';
import { ApiPromise } from '@polkadot/api';
import { map } from 'lodash';

import { useApiCall } from '@/hooks';

export interface StreamStatus {
  Ongoing?: {
    asCollateral: boolean;
  };
  Completed?: {
    cancelled: boolean;
  };
}

export interface StreamListItem {
  id: string;
  remainingBalance: string;
  deposit: string;
  assetId: number;
  recipient: string;
  sender: string;
  startTime: number;
  endTime: number;
  status: StreamStatus;
}

const removeComma = (v: string) => v.replaceAll(',', '');

const transformValue = (value: any, id: string) => {
  if (!value.toHuman()) {
    return null;
  }
  const { remainingBalance, deposit, assetId, recipient, sender, startTime, endTime, status } =
    value.toHuman();
  return {
    id,
    assetId: Number(assetId),
    remainingBalance: removeComma(remainingBalance),
    deposit: removeComma(deposit),
    recipient: removeComma(recipient),
    sender: removeComma(sender),
    startTime: Number(removeComma(startTime)) * 1000,
    endTime: Number(removeComma(endTime)) * 1000,
    status
  };
};

const useFetchList = (api: ApiPromise, address: string, type: 'Send' | 'Receive') => {
  const [list, setList] = useState([] as StreamListItem[]);
  const [isReady, setIsReady] = useState<boolean>(false);

  const streamIds = useApiCall<string[]>(api.query.streaming.streamLibrary, [address, type], {
    transform: value => {
      const humanValue = value.toHuman();
      return humanValue && humanValue.map((id: string) => id.replace(',', ''));
    }
  });
  const details = useApiCall(streamIds && api.query.streaming.streams.multi, [streamIds], {
    transform: (values: []) => {
      setIsReady(true);
      return values
        .map((value: any, index: number) => {
          return transformValue(value, streamIds![index]);
        })
        .filter(v => !!v);
    }
  });

  useEffect(() => {
    setList([]);
  }, [address]);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (streamIds && details) {
      // find missing ids
      const existingIds = map(details, 'id');
      const missingIds = streamIds.filter(id => !existingIds.includes(id));

      if (missingIds.length > 0) {
        let count = 0;
        timer = setInterval(() => {
          api.query.streaming.streams.multi([missingIds]).then(values => {
            const missingDetails = values
              .map((value, index) => {
                return transformValue(value, missingIds[index]);
              })
              .filter(v => !!v);
            count += 1;
            if (missingDetails.length === missingIds.length || count === 10) {
              clearInterval(timer);
              setList(details.concat(missingDetails) as StreamListItem[]);
            }
          });
        }, 2000);
      } else {
        setList(details as StreamListItem[]);
      }
    }

    if (streamIds === null) {
      setIsReady(true);
      setList([]);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [streamIds, details, type, api]);

  return { isReady, list };
};

export default useFetchList;
