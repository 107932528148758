import styled from 'styled-components';

export type Variant = 'primary' | 'secondary';

type State = 'hover' | 'active';

export interface LinkBaseProps {
  variant: Variant;
  disabled: boolean;
}

const textDecorationHelper =
  (state?: State) =>
  ({ variant, disabled }: LinkBaseProps) => {
    if (disabled) return 'none';
    const standardTextDecorations = {
      primary: 'none',
      secondary: 'underline'
    };
    if (state === 'hover') return 'underline';
    return standardTextDecorations[variant];
  };

const LinkBase = styled.a`
  font-family: var(--font-family);
  text-decoration: ${textDecorationHelper()};
  color: ${({ disabled }) => (disabled ? 'var(--clr-gray02)' : 'var(--clr-link)')};
  &:hover {
    text-decoration: ${textDecorationHelper('hover')};
  }

  &:focus {
    outline: 1px solid var(--clr-divide);
    outline-offset: 0.125rem;
  }

  &:focus-visible {
    outline: 1px solid var(--clr-divide);
    outline-offset: 0.125rem;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export default LinkBase;
