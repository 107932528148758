interface EventCaches {
  [key: string]: Array<(data?: unknown) => void>;
}

export class Observer<T extends string> {
  private events: EventCaches = {};

  on(eventName: T, fn: (data?: unknown) => void) {
    this.events[eventName] = this.events[eventName] || [];
    this.events[eventName].push(fn);
  }

  emit(eventName: T, data?: unknown) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn: (data?: unknown) => void) => fn(data));
    }
  }

  off(eventName: T, fn?: (data?: unknown) => void) {
    if (this.events[eventName]) {
      const newCaches = fn ? this.events[eventName].filter(e => e !== fn) : [];
      this.events[eventName] = newCaches;
    }
  }
}
