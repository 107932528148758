import { useCallback, useMemo } from 'react';
import { useAsyncFn } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import axios from 'axios';

import { TokenPair } from './useTransactionTokens';

import { useChainConnections } from '@/hooks/useChainConnection';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { AssetsData } from '@/hooks/types';
import config from '@/config';

type AmmData = {
  volume: {
    list: {
      poolVolume: {
        weekly: Record<string, { id: string; val: number }>;
      };
    };
  };
};

export interface LpAssetsApy {
  ammData: AsyncState<AmmData>;
  calculateApyByAssetId: (tokenPair: TokenPair | undefined) => number | undefined;
  fetchAmmData: () => Promise<AmmData>;
}

export const useLpAssetsApy = (assetsPriceSource: AssetsData | undefined): LpAssetsApy => {
  const {
    parachain: { api }
  } = useChainConnections();

  const lpFee = useMemo(
    () => balanceToAmountByDecimal<number>(api.consts.amm.lpFee, 'million', 'number'),
    [api.consts.amm.lpFee]
  );

  const [ammData, fetchAmmData] = useAsyncFn<() => Promise<AmmData>>(async () => {
    const response = await axios.get(
      `${config.ANALYSIS_SERVICE_URL}/api/amm?network=${config.relayChain}`
    );
    if (response.data && response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch amm data');
  }, []);

  const calculateApy = useCallback(
    (weeklyVolume: number, poolTotalValue: number) => {
      const weeksPerYear = 365 / 7;
      return (weeklyVolume * weeksPerYear * lpFee) / poolTotalValue;
    },
    [lpFee]
  );

  const calculateApyByAssetId = useCallback(
    (tokenPair: TokenPair | undefined) => {
      if (tokenPair && assetsPriceSource && ammData.value) {
        const [fromTokenPair, toTokenPair] = tokenPair;
        const baseAssetValue =
          fromTokenPair.guideAmount! * assetsPriceSource[fromTokenPair.assetId];
        const quoteAssetValue = toTokenPair.guideAmount! * assetsPriceSource[toTokenPair.assetId];

        const poolTotalValue = baseAssetValue + quoteAssetValue;
        const weeklyPoolVolume = ammData.value.volume.list.poolVolume.weekly;

        return calculateApy(weeklyPoolVolume?.[fromTokenPair.lpAssetId!]?.val || 0, poolTotalValue);
      }
      return undefined;
    },
    [assetsPriceSource, ammData.value, calculateApy]
  );

  return { ammData, calculateApyByAssetId, fetchAmmData };
};
