import { FC, ReactElement, cloneElement } from 'react';
import { isBoolean } from 'lodash';
import styled from 'styled-components';

import { Stack, Inline, InlineProps } from '../Layout';

import Avatar, { SkeletonAvatarProps } from './Avatar';
import Button, { SkeletonButtonProps } from './Button';
import Paragraph, { SkeletonParagraphProps } from './Paragraph';
import Title, { SkeletonTitleProps } from './Title';

const SkeletonWrapper = styled(Inline)`
  background: '#fff';
`;

const SkeletonContent = styled(Stack)`
  flex: 1;
`;

interface SkeletonProps extends Omit<InlineProps, 'title'> {
  active?: boolean;
  loading?: boolean;
  avatar?: boolean | SkeletonAvatarProps;
  paragraph?: boolean | SkeletonParagraphProps;
  title?: boolean | SkeletonTitleProps;
}

interface SkeletonType extends FC<SkeletonProps> {
  Avatar: FC<SkeletonAvatarProps>;
  Button: FC<SkeletonButtonProps>;
  Paragraph: FC<SkeletonParagraphProps>;
  Title: FC<SkeletonTitleProps>;
}

const renderSkeletonElement = <T,>(element: ReactElement, props: T) => {
  if (isBoolean(props)) {
    return props ? element : null;
  }

  return cloneElement(element, props);
};

/**
 * Provide a placeholder while you wait for content to load, or to visualize content that doesn't exist yet.
 */
const Skeleton: SkeletonType = ({
  children,
  active = true,
  loading = true,
  avatar = false,
  paragraph = true,
  title = true,
  ...props
}) => {
  if (loading) {
    return (
      <SkeletonWrapper inset="2rem" {...props}>
        {renderSkeletonElement(<Avatar active={active} />, avatar)}
        <SkeletonContent>
          {renderSkeletonElement(<Title active={active} />, title)}
          {renderSkeletonElement(<Paragraph active={active} rows={4} />, paragraph)}
        </SkeletonContent>
      </SkeletonWrapper>
    );
  }

  return (children ?? null) as ReactElement;
};

Skeleton.Avatar = Avatar;
Skeleton.Button = Button;
Skeleton.Paragraph = Paragraph;
Skeleton.Title = Title;

export { Skeleton };
export type {
  SkeletonProps,
  SkeletonType,
  SkeletonAvatarProps,
  SkeletonButtonProps,
  SkeletonParagraphProps,
  SkeletonTitleProps,
};
