import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import initApiSocket, { isSocketReady } from '@/utils/apiSocket/initApiSocket';
import { ChainMap, Chains, useChainConnections } from '@/hooks/useChainConnection';
import config from '@/config';

const useConnectChains = (initialChains?: Chains[]) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const getOrDefault = useCallback(
    (key: string, defaultValue: string): string => searchParams.get(key) || defaultValue,
    [searchParams]
  );

  const chainUrlMap: { [key in Chains]: string } = useMemo(
    () => ({
      [Chains.RelayChain]: getOrDefault('relay_rpc', config.RELAYCHAIN),
      [Chains.ParaChain]: getOrDefault('rpc', config.PARALLEL),
      [Chains.AcalaChain]: getOrDefault('acala_rpc', config.CROSS_CHAIN.ACALA_CHAIN),
      [Chains.PhalaChain]: getOrDefault('phala_rpc', config.CROSS_CHAIN.PHALA_CHAIN),
      [Chains.StatemineChain]: getOrDefault('statemine_rpc', config.CROSS_CHAIN.STATEMINE_CHAIN),
      [Chains.InterlayChain]: getOrDefault('interlay_rpc', config.CROSS_CHAIN.INTERLAY_CHAIN)
    }),
    [getOrDefault]
  );

  const { updateChains, ...chainObject } = useChainConnections();

  const getChainConnection = useCallback(
    async (chain: Chains) => {
      if (chainObject[chain]) {
        return chainObject[chain];
      }
      const url = chainUrlMap[chain];
      const connection = await initApiSocket(url);
      updateChains({ [chain]: connection } as ChainMap);
      return connection;
    },
    [chainObject, chainUrlMap, updateChains]
  );

  const isChainsReady = useCallback(
    (chainList: Chains[]) => {
      return chainList.every(chain =>
        chainObject[chain] ? isSocketReady(chainObject[chain]) : false
      );
    },
    [chainObject]
  );

  useEffect(() => {
    initialChains?.forEach(async chainName => {
      if (!chainObject[chainName]) {
        const url = chainUrlMap[chainName];
        const connection = await initApiSocket(url);
        updateChains({ [chainName]: connection } as ChainMap);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialChains, updateChains]);

  return {
    getChainConnection,
    isChainsReady
  };
};

export default useConnectChains;
