import { memo } from 'react';

import PageWrapper from '../PageWrapper';

import Stream from './Stream';
import LoadingStatus from './LoadingStatus';

import { Chains } from '@/hooks';

export default memo(() => (
  <PageWrapper
    initialChains={[Chains.ParaChain, Chains.RelayChain]}
    loadingComponent={<LoadingStatus />}
  >
    <Stream />
  </PageWrapper>
));
