import { FC } from 'react';
import { sum } from 'lodash';
import { CryptoAsset } from '@parallel-mono/business-components';
import { Button, H2, Stack } from '@parallel-mono/components';

import { Pool } from '@/pages/Farm/types';
import { useAccount, useChainConnections, useTransactionFee, useTxFeeValidation } from '@/hooks';
import { balanceFormatter } from '@/utils/format';
import { signAndSend } from '@/utils/txCall';

type Props = {
  closeModal: () => void;
  data: Pool;
};

const RedeemModal: FC<Props> = ({ closeModal, data }) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const redeemTransaction = api.tx.farming.redeem(
    data.asset.id,
    data.rewardAsset.id,
    data.lockDuration
  );
  const redeemAmount = sum(
    data.user.pendingWithdrawals.filter(p => p.timeLeft <= 0).map(p => p.amount)
  );

  const { account } = useAccount();
  const transactionFee = useTransactionFee({
    api,
    tx: redeemTransaction
  });

  const { TxFeeTips } = useTxFeeValidation();

  const handleClick = () => {
    closeModal();
    signAndSend({
      api,
      tx: redeemTransaction,
      account
    });
  };
  return (
    <Stack justifyContent="center">
      <Stack alignItems="center" gap="0.5rem">
        <CryptoAsset symbol={data.asset.name} />
        <H2 fontWeight="bold">{balanceFormatter(redeemAmount)}</H2>
      </Stack>
      <Button skin="primary" block onClick={handleClick} disabled={redeemAmount === 0}>
        {`Redeem ${balanceFormatter(redeemAmount)} ${data.asset.name}`}
      </Button>
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default RedeemModal;
