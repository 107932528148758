import { useState, useEffect } from 'react';
import { Balance } from '@polkadot/types/interfaces';

import { CurrencyId, AssetInfo } from '@/hooks/types';
import { useChainConnections, useApiCall, useAssetInfos } from '@/hooks';
import config from '@/config';
import { balanceToAmountByDecimal } from '@/utils/calculations';

interface LoanAsset extends AssetInfo {
  totalReserves: number;
  availableLiquidity: number;
}

type TotalReserves = Record<CurrencyId, number>;

export type LoanAssets = Record<CurrencyId, LoanAsset | undefined>;

export const useLoanAssets = (assetIds: CurrencyId[] = []) => {
  const {
    parachain: { api }
  } = useChainConnections();

  const [loanAssets, setLoanAssets] = useState<LoanAssets | undefined>();

  const { assetInfos } = useAssetInfos({
    address: config.loanAddress
  });

  const totalReservesOpt = {
    transform: (values: Balance[]) => {
      return values.reduce<TotalReserves>((result, balance, index) => {
        const assetId = assetIds[index];
        const assetInfo = assetInfos.find(info => info.assetId === assetId);
        result[assetIds[index]] = assetInfo
          ? balanceToAmountByDecimal<number>(balance, assetInfo.decimals, 'number')
          : 0;
        return result;
      }, {} as TotalReserves);
    }
  };

  const totalReservesMap = useApiCall<TotalReserves>(
    assetIds.length > 0 ? api.query.loans.totalReserves.multi : undefined,
    [assetIds],
    totalReservesOpt
  );

  useEffect(() => {
    if (totalReservesMap && assetIds.length > 0) {
      const newLoanAssets = assetIds.reduce((result, assetId) => {
        const assetInfo = assetInfos.find(info => info.assetId === assetId);
        const totalReserves = totalReservesMap[assetId] ?? 0;
        result[assetId] = {
          totalReserves,
          availableLiquidity: assetInfo ? Math.max(assetInfo.balance - totalReserves, 0) : 0,
          ...assetInfo!
        };
        return result;
      }, {} as LoanAssets);

      setLoanAssets(newLoanAssets);
    }
  }, [assetIds, assetInfos, totalReservesMap]);

  return { loanAssets };
};
