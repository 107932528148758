const typography = {
  display1: {
    fontSize: '3rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '3.875rem',
    fontWeight: 'var(--font-weight-regular)'
  },
  display2: {
    fontSize: '2.25rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '3rem',
    fontWeight: 'var(--font-weight-medium)'
  },
  header1: {
    fontSize: '2rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '3rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  header2: {
    fontSize: '1.5rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '2.25rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  header3: {
    fontSize: '1.25rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.875rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  header4: {
    fontSize: '1.125rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.75rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  header5: {
    fontSize: '1rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.5rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  header6: {
    fontSize: '0.875rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.25rem',
    fontWeight: 'var(--font-weight-bold)'
  },
  bodyBig: {
    fontSize: '1.125rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.75rem',
    fontWeight: 'var(--font-weight-regular)'
  },
  body: {
    fontSize: '1rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.5rem',
    fontWeight: 'var(--font-weight-regular)'
  },
  bodySmall: {
    fontSize: '0.875rem',
    fontFamily: 'var(--font-family)',
    lineHeight: '1.25rem',
    fontWeight: 'var(--font-weight-regular)'
  }
};

export default typography;
