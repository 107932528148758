import { FC } from 'react';
import Identicon from '@polkadot/react-identicon';

import Image from '../Image';

import parallelAccImage0 from './images/parallel-account-0.svg';
import parallelAccImage1 from './images/parallel-account-1.svg';

import { WalletType } from '@/contexts/WalletsContext/types';

interface AccountImageProps {
  type: WalletType;
  address?: string;
  index?: number;
}

const srcMapping: Record<string, any> = {
  parallelAccImage0,
  parallelAccImage1
};

export const randomPickAccImage = (prefix: string, index: number = 0) => {
  return `${prefix}${Math.floor(index % 2)}`;
};

const AccountImage: FC<AccountImageProps> = ({ type, address, index }) => {
  let imageSrc;
  switch (type) {
    case WalletType.Polkadot:
      return <Identicon value={address} size={40} theme="polkadot" />;
    case WalletType.Parallel: {
      imageSrc = srcMapping[randomPickAccImage('parallelAccImage', index)];
      return <Image src={imageSrc} />;
    }
    default:
      return <Image src={parallelAccImage0} />;
  }
};

export default AccountImage;
