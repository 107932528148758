import { FC, ReactNode, HTMLAttributeAnchorTarget, ComponentProps } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import styled, { css } from 'styled-components';

const linkStyle = css`
  color: var(--clr-text01);
  white-space: nowrap;
  font-weight: var(--font-weight-medium);
  padding: 10px 16px;
  &.selected,
  &:hover {
    color: var(--clr-blue);
  }
`;

const RouteNavLink: FC<ComponentProps<typeof NavLink>> = ({ children, className, ...props }) => {
  return (
    <NavLink
      className={({ isActive }) => classnames(className as string, isActive ? 'selected' : '')}
      {...props}
    >
      {children}
    </NavLink>
  );
};

const RouteNavLinkStyled = styled(RouteNavLink)`
  ${linkStyle}
`;

const ExternalLinkStyled = styled.a`
  ${linkStyle}
`;

interface MenuItemProps {
  path?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  className?: string;
  onMenuClick?: () => void;
  hide?: boolean;
  children: ReactNode;
}

const MenuItem: FC<MenuItemProps> = ({
  href,
  path,
  target,
  className,
  onMenuClick,
  hide,
  children
}) => {
  if (hide) return null;
  if (href) {
    return (
      <ExternalLinkStyled
        className={className}
        href={href}
        target={target}
        onClick={() => onMenuClick?.()}
      >
        {children}
      </ExternalLinkStyled>
    );
  }

  if (path) {
    return (
      <RouteNavLinkStyled className={className} to={path} onClick={() => onMenuClick?.()}>
        {children}
      </RouteNavLinkStyled>
    );
  }

  return <>{children}</>;
};

export type { MenuItemProps };
export default MenuItem;
