import { FC, ReactNode, useMemo, useState } from 'react';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { noop } from 'lodash';
import { Inline, Stack, H5, Button, Text } from '@parallel-mono/components';
import { TokenInput, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';

import { isValidAmountInput } from '@/utils/validate';
import { signAndSend } from '@/utils/txCall';
import { useAccount, useChainConnections, useTransactionFee, useTxFeeValidation } from '@/hooks';
import { balanceFormatter } from '@/utils/format';

type Props = {
  closeModal: () => void;
  assetName: string;
  availableTag: string;
  availableAmount: number;
  excludeTransactionFee?: boolean;
  information: {
    key: string;
    title: string | ReactNode;
    content: string;
  }[];
  generateTx: (amount: number) => SubmittableExtrinsic<'promise'>;
  onChange?: (amount: number) => void;
  insufficientText: string;
};

const InputModal: FC<Props> = ({
  closeModal,
  assetName,
  availableTag,
  availableAmount,
  excludeTransactionFee = false,
  information,
  generateTx,
  onChange = noop,
  insufficientText
}) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();

  const [amount, setAmount] = useState<number | null>(null);
  const changeAmount = (value: number | null) => {
    if (value === null) {
      setAmount(null);
      onChange(0);
    } else if (isValidAmountInput(value.toString(), 12)) {
      setAmount(value);
      onChange(value);
    }
  };

  const { TxFeeTips } = useTxFeeValidation();
  const transactionFee = useTransactionFee(
    {
      api,
      tx: generateTx(amount ?? 0)
    },
    [amount]
  );

  const availableBalance = useMemo(() => {
    const available = excludeTransactionFee
      ? availableAmount - 3 * (transactionFee || 0)
      : availableAmount;
    return available > 0 ? available : 0;
  }, [availableAmount, excludeTransactionFee, transactionFee]);

  const handleClose = () => {
    closeModal();
    setAmount(0);
  };

  const handleClick = () => {
    handleClose();
    signAndSend({
      api,
      tx: generateTx(amount ?? NaN),
      account
    });
  };

  const infos = useMemo<InfoPanelProps['infos']>(
    () => information.map(({ title, content }) => ({ title, value: content })),
    [information]
  );

  return (
    <Stack justifyContent="center">
      <TokenInput
        label={<H5>Amount</H5>}
        hint={
          <Inline gap="0.25rem">
            <Text skin="secondary">{availableTag}:</Text>
            <H5>
              {balanceFormatter(availableBalance)} {assetName}
            </H5>
          </Inline>
        }
        token={assetName.toUpperCase()}
        value={amount}
        placeholder="Amount"
        decimals={4}
        onChange={changeAmount}
        error={(amount ?? NaN) > availableBalance ? insufficientText : null}
      />
      {infos.length > 0 ? <InfoPanel infos={infos} /> : null}
      <Button
        skin="primary"
        block
        disabled={amount === null || amount > availableBalance || (amount ?? NaN) <= 0}
        onClick={handleClick}
      >
        Confirm
      </Button>
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default InputModal;
