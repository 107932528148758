import styled from 'styled-components';
import { Stack, Card, Skeleton, Inline } from '@parallel-mono/components';
import { range } from 'lodash';

const CardStyled = styled(Card)`
  width: 100%;
  border: 1px solid var(--clr-gray03);
`;

export const LoadingDashboard = () => {
  return (
    <Stack>
      {range(4).map(i => (
        <CardStyled key={i} inset="1rem">
          <Inline gap="0.8rem" alignItems="center">
            <Inline gap="0.5rem" alignItems="center">
              <Skeleton.Avatar width="2rem" height="2rem" variant="round" />
              <Skeleton.Avatar width="2rem" height="2rem" variant="round" />
            </Inline>
            <Skeleton.Title height="1.3rem" />
          </Inline>
        </CardStyled>
      ))}
    </Stack>
  );
};
