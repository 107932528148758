import { CurrencyId } from '@parallel-finance/types/interfaces';
import { find } from 'lodash';
import { Stack, Text } from '@parallel-mono/components';

import { AbstractChain, ValidationParams } from '../AbstractChain';
import { Chains, TxCallback } from '../types';
import { TxFee } from '../../types';
import { StatemintChainCurrency } from '../statemint/types';
import { MAX_STATEMINE_TRANSACTION_FEE } from '../statemint/constants';
import { calculateCommonErrorMessage } from '../../helpers/calculateErrorMessage';

import { TO_CHAINS, CURRENCIES, MINIMUM_TRANSFER_AMOUNT } from './constants';
import { generateTxs } from './helpers/generateTxs';
import { calculateAssetsInfo } from './helpers/calculateAssetsInfo';
import { ChainMetadata } from './types';

import { signAndSend, txFee } from '@/utils/txCall';
import { AssetDetailInfo } from '@/hooks/types';
import config from '@/config';

export class ParallelChain extends AbstractChain<ChainMetadata> {
  static toChains = TO_CHAINS;

  static currencies = CURRENCIES;

  static minimumTransferAmount = MINIMUM_TRANSFER_AMOUNT;

  metadataSubscribe = async () => {
    const { api, account, subscribeValue } = this;
    subscribeValue(api.rpc.system.properties, [], 'chainProperties');
    subscribeValue(account && api.query.system.account, [account?.address], 'accountInfo');

    // the `api.query.assets.metadata.entries` will not trigger the subscribe callback, so put it to the metadata manually
    this.metadata.assetsMetadata = await api.query.assets.metadata.entries();

    const assetIds: CurrencyId[] =
      this.metadata.assetsMetadata
        ?.flatMap((m: any) => m[0])
        .map(({ args: [id] }) => id.toString()) || [];
    subscribeValue(
      api.query.assets.account.multi,
      [assetIds?.map(id => [id, account.address])],
      'assetsBalance'
    );
  };

  calculateAssetsInfo = async () => {
    const { api, metadata } = this;
    const assetsInfo = await calculateAssetsInfo(api, metadata);
    return assetsInfo;
  };

  getTxFee = async (
    amount: number,
    asset: AssetDetailInfo,
    nativeAsset: AssetDetailInfo,
    toChain: Chains,
    customParams?: Record<string, any>
  ): Promise<TxFee> => {
    const toChainAssetsInfo = customParams?.toChainAssetsInfo;
    const toChainNativeAsset = find(
      toChainAssetsInfo,
      (toChainAsset: AssetDetailInfo) => toChainAsset.isNative
    );
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount, toChain, toChainNativeAsset);
    const fee = amount ? await txFee({ tx, account }) : 0;

    return { currency: nativeAsset.symbol, fee };
  };

  transfer = async (
    amount: number,
    asset: AssetDetailInfo,
    toChain: Chains,
    options?: TxCallback,
    customParams?: Record<string, any>
  ) => {
    const toChainAssetsInfo = customParams?.toChainAssetsInfo;
    const toChainNativeAsset = find(
      toChainAssetsInfo,
      (toChainAsset: AssetDetailInfo) => toChainAsset.isNative
    );
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount, toChain, toChainNativeAsset);
    signAndSend({ api, tx, account, ...(options || {}) });
  };

  // eslint-disable-next-line class-methods-use-this
  getExtraInfos = (toChain: Chains) => {
    if (toChain === Chains.Statemint) {
      return [
        {
          label: 'Fees',
          value: `${MAX_STATEMINE_TRANSACTION_FEE} ${StatemintChainCurrency.USDT}`,
          tooltip: 'Cross chain transaction fees charged by the blockchain for this transfer'
        }
      ];
    }
    return [];
  };

  // eslint-disable-next-line class-methods-use-this
  public validate = (args: ValidationParams): string | null => {
    const { toChain, fromChain, currency, fromChainAssets, toChainAssets } = args;
    const fromChainNativeAsset = fromChainAssets.find(asset => asset.isNative);
    const toChainNativeAsset = toChainAssets.find(asset => asset.isNative);
    const commonError = calculateCommonErrorMessage({
      ...args,
      fromChain,
      toChain,
      fromChainNativeAsset: fromChainNativeAsset!,
      toChainNativeAsset: toChainNativeAsset!,
      transferCurrency: args.currency
    });
    if (commonError) {
      return commonError;
    }
    if (toChain.name === Chains.Statemint && currency === StatemintChainCurrency.USDT) {
      const relayAssetOnParallel = find(
        fromChainAssets,
        (asset: AssetDetailInfo) => asset.symbol === config.relayChainToken
      );
      if (
        !relayAssetOnParallel ||
        relayAssetOnParallel.availableBalance.gte(MAX_STATEMINE_TRANSACTION_FEE)
      ) {
        return null;
      }
      return `Insufficient ${relayAssetOnParallel.symbol} balance to pay transaction fees.`;
    }
    return null;
  };

  // eslint-disable-next-line class-methods-use-this
  validateWarning = ({
    toChain,
    minimumTransferAmount,
    toChainAssets,
    transferAmount
  }: ValidationParams) => {
    const toChainNativeAsset = toChainAssets.find(asset => asset.isNative);
    if (
      toChainNativeAsset?.symbol === config.relayChainToken &&
      toChainNativeAsset?.availableBalance.lte(0) &&
      transferAmount !== minimumTransferAmount
    ) {
      return (
        <Stack gap="0.25rem">
          <Text>
            Insufficient {toChainNativeAsset?.symbol} balance on {toChain.label} would result in
            funds loss.
          </Text>
          <Text>
            Please cross-chain{' '}
            <b>
              {minimumTransferAmount} {toChainNativeAsset.symbol}
            </b>{' '}
            first, and then continue to operate the remaining tokens after confirming the arrival.
          </Text>
        </Stack>
      );
    }
    return null;
  };
}
