import { flatten } from 'lodash';

import { AssetInfo } from '@/hooks/types';
import config from '@/config';

interface SymbolInfo {
  symbol: string;
  prefix?: string;
  primaryToken: string;
  secondaryToken?: string;
  leases?: string;
}

export const parseTokenSymbol = (symbol: string) => {
  const slices = symbol.split(/(?!\d)\/(?!\d)/);
  const result = {
    symbol
  } as SymbolInfo;

  slices.forEach((slice, index) => {
    const leasesExp = /-(\d+[/|-]\d+)$/;
    const leases = slice.match(leasesExp)?.[1];
    const sections = slice.split(/-(?!\d)/);
    const [section1, section2] = sections;
    const tokenName = sections.length > 1 ? section2 : section1;

    if (leases) {
      result.leases = leases;
    }
    if (section2 && index === 0) {
      result.prefix = section1;
    }
    result[index === 0 ? 'primaryToken' : 'secondaryToken'] = tokenName.replace(leasesExp, '');
  });

  return result;
};

const getGroupSortByRule = (sortFields: string[], symbolInfos: SymbolInfo[]): SymbolInfo[] => {
  return symbolInfos.sort((a, b) => {
    if (a.primaryToken === b.primaryToken) {
      if (a.secondaryToken === b.secondaryToken) {
        return a.leases && b.leases ? a.leases.localeCompare(b.leases) : 0;
      }
      return sortFields.indexOf(a.secondaryToken!) - sortFields.indexOf(b.secondaryToken!);
    }
    return sortFields.indexOf(a.primaryToken) - sortFields.indexOf(b.primaryToken);
  });
};

export const sortAssetsBySpecificRule = (assetInfos: AssetInfo[], configuration: typeof config) => {
  const {
    relayChainToken,
    mmAssetOrder: { primaryRule, secondaryRule }
  } = configuration;

  const primaryAssetInfos = primaryRule.reduce((result, symbol) => {
    const assetInfo = assetInfos.find(item => item.symbol === symbol);
    if (assetInfo) {
      result.push(assetInfo);
    }
    return result;
  }, [] as AssetInfo[]);

  const secondaryAssetInfos = flatten(
    secondaryRule.map(rule => {
      const ruleAssetInfos = assetInfos
        .filter(item => item.symbol.startsWith(rule))
        .map(({ symbol }) => {
          return parseTokenSymbol(symbol);
        });

      return getGroupSortByRule([...primaryRule, `c${relayChainToken}`], ruleAssetInfos).map(
        ({ symbol }) => assetInfos.find(item => item.symbol === symbol)
      );
    })
  ) as AssetInfo[];

  const restAssetInfos = assetInfos.filter(
    item =>
      !primaryAssetInfos.some(({ symbol }) => item.symbol === symbol) &&
      !secondaryAssetInfos.some(({ symbol }) => item.symbol === symbol)
  );

  return [...primaryAssetInfos, ...secondaryAssetInfos, ...restAssetInfos];
};
