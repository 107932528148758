import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { FC } from 'react';

import config from '@/config';

const client = new ApolloClient({
  uri: `${config.PARALLEL_DAPP_BFF_SERVICE}/graphql`,
  cache: new InMemoryCache()
});

export const CreateApolloClient: FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
