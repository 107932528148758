import { memo } from 'react';
import styled from 'styled-components';
import { Stack, Inline, Card } from '@parallel-mono/components';

import { NavigationBar } from '@/components';
import OverviewContent from '@/pages/Overview/OverviewContent';

const PageWrapper = styled(Inline).attrs({
  alignItems: 'center',
  grow: true
})`
  margin-left: auto;
  margin-right: auto;
  width: 846px;
  ${({ theme }) => theme.breakpoints.down('lg')`
    width: 100%;
  `};
`;

const Overview = () => {
  return (
    <PageWrapper>
      <Stack style={{ width: '100%' }} gap="2rem">
        <Card>
          <NavigationBar type="overview" />
        </Card>
        <OverviewContent />
      </Stack>
    </PageWrapper>
  );
};

export default memo(Overview);
