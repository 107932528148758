import { FC } from 'react';
import { CryptoAsset, CryptoAssetProps } from '@parallel-mono/business-components';

import { LiquidationFreeIcon } from './LiquidationFreeIcon';

interface AssetColumnProps extends Omit<CryptoAssetProps, 'symbolSize'> {
  isLiquidationFree: boolean;
}

const AssetColumn: FC<AssetColumnProps> = ({ symbol, isLiquidationFree, ...others }) => (
  <>
    <CryptoAsset symbol={symbol} {...others} gap="0.5rem" showName />
    {isLiquidationFree && <LiquidationFreeIcon />}
  </>
);

export default AssetColumn;
