import { createContext, memo, useContext, useMemo } from 'react';
import { AssetBalance } from '@polkadot/types/interfaces';
import { Option, Vec } from '@polkadot/types';

import { useAccount, useAssetInfos } from '@/hooks';
import { useChainConnections } from '@/hooks/useChainConnection';
import { AssetInfo } from '@/hooks/types';

type ContextValue = {
  isReady: boolean;
  balances: Vec<Option<AssetBalance>> | undefined;
  assetIds: string[];
  assetInfos: AssetInfo[];
};

const AssetInfosContext = createContext<ContextValue>({
  isReady: false,
  balances: undefined,
  assetIds: [],
  assetInfos: []
});

export const AssetInfosContextProvider = memo(({ children }) => {
  const { account } = useAccount();

  const assetsInfo = useAssetInfos({ address: account?.address ?? '' });

  return <AssetInfosContext.Provider value={assetsInfo}>{children}</AssetInfosContext.Provider>;
});

export const useCurrentAccountAssetInfos = ({ filterCollateralAsset = true } = {}) => {
  const {
    parachain: { api }
  } = useChainConnections();

  const { assetInfos, ...others } = useContext(AssetInfosContext);
  const collateralCurrency = useMemo(() => api.consts.liquidStaking.collateralCurrency, [api]);
  const filteredInfos = useMemo(
    () => assetInfos.filter(({ assetId }) => assetId !== collateralCurrency.toString()),
    [assetInfos, collateralCurrency]
  );

  const value = useMemo(
    () => ({ ...others, assetInfos: filterCollateralAsset ? filteredInfos : assetInfos }),
    [assetInfos, filterCollateralAsset, filteredInfos, others]
  );
  return value;
};
