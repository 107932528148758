import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Card, Stack } from '@parallel-mono/components';

import { Swap } from './Swap';
import { Liquidity } from './Liquidity';
import { TransactionType } from './types';
import { DataContext, DataContextType } from './DataContext';
import { useExtendAssetInfos } from './hooks/useExtendAssetInfos';
import { usePools } from './hooks/usePools';
import { useLpAssetsApy } from './hooks/useLpAssetsApy';
import { useAssetDetails } from './hooks/useAssetDetails';
import { useAmmDetails } from './hooks/useAmmDetails';
import { TransactionToken } from './hooks/useTransactionTokens';
// import RecentTransaction from './components/RecentTransaction';

import { KeepAliveTabs, KeepAliveTab } from '@/components';
import { Chains, useAssetPrices, useConnectChains } from '@/hooks';

const SwapContainer = styled(Stack)`
  position: relative;
  width: 480px;
  margin: 0 auto;
  min-height: 460px;
  ${({ theme }) => theme.breakpoints.down('sm')`
    width: 100%;
  `};
`;

export const Main = () => {
  const [selectedTokens, setSelectedTokens] = useState<TransactionToken[]>([]);
  const [transactionType, setTransactionType] = useState<TransactionType>(TransactionType.Swap);

  const tabs = useMemo<KeepAliveTab[]>(() => {
    return [
      {
        title: 'Swap',
        key: 'swap',
        tabContent: <Swap />
      },
      {
        title: 'Liquidity',
        key: 'liquidity',
        tabContent: <Liquidity />
      }
    ];
  }, []);

  const handleSelectTokens = useCallback(
    (fromToken: TransactionToken, toToken: TransactionToken) => {
      setSelectedTokens([fromToken, toToken]);
    },
    []
  );

  useConnectChains([Chains.RelayChain]);
  const poolSource = usePools();
  const assetsPriceSource = useAssetPrices();
  const assetInfoSource = useExtendAssetInfos();
  const lpAssetsApySource = useLpAssetsApy(assetsPriceSource);
  const assetDetailSource = useAssetDetails();
  const ammDetailsSource = useAmmDetails();

  const data = useMemo<DataContextType>(
    () => ({
      poolSource,
      assetsPriceSource,
      assetInfoSource,
      lpAssetsApySource,
      assetDetailSource,
      ammDetailsSource,
      selectedTokens,
      handleSelectTokens,
      transactionType
    }),
    [
      assetDetailSource,
      assetInfoSource,
      assetsPriceSource,
      lpAssetsApySource,
      poolSource,
      ammDetailsSource,
      selectedTokens,
      handleSelectTokens,
      transactionType
    ]
  );

  const handleChangeTransactionType = useCallback((key: string) => {
    setTransactionType(key as TransactionType);
  }, []);

  return (
    <DataContext.Provider value={data}>
      <SwapContainer>
        <Card>
          <KeepAliveTabs
            tabs={tabs}
            activeKey={transactionType}
            onTabChange={handleChangeTransactionType}
            gap="2rem"
          />
        </Card>
        {/* <SideBar /> */}
        {/* <RecentTransaction /> */}
      </SwapContainer>
    </DataContext.Provider>
  );
};
