import { FC, useCallback, useMemo, useState } from 'react';
import { upperFirst } from 'lodash';
import { H3, Stack } from '@parallel-mono/components';
import styled from 'styled-components';

import { AssetRow } from '../../LendAndBorrowTable';
import ViewAssetAnalytics from '../../components/ViewAssetAnalytics';

import LendPane from './LendPane';
import WithdrawPane from './WithdrawPane';

import { useAccount, useChainConnections } from '@/hooks';
import { KeepAliveTab, KeepAliveTabs } from '@/components';

const ModalWrapper = styled(Stack)`
  min-height: 420px;
`;

export enum SupplyActionType {
  Lend = 'lend',
  Withdraw = 'withdraw'
}

interface ISupplyWithdrawModalProps {
  data: {
    tabKey: SupplyActionType;
    assetId: string;
  };
  assetInfos: AssetRow[];
  closeModal: () => void;
}

const LendWithdrawModal: FC<ISupplyWithdrawModalProps> = ({
  data: { tabKey, assetId },
  assetInfos,
  closeModal
}) => {
  const { account } = useAccount();
  const {
    parachain: { api }
  } = useChainConnections();

  const [activeTabKey, setActiveTabKey] = useState(tabKey);
  const assetInfo = useMemo(
    () => assetInfos.find(info => info.assetId === assetId),
    [assetInfos, assetId]
  );

  const tabs = useMemo<KeepAliveTab[]>(() => {
    return [
      {
        title: 'Lend',
        key: SupplyActionType.Lend,
        tabContent: (
          <LendPane token={assetInfo!} api={api} account={account} handleClose={closeModal} />
        )
      },
      {
        title: 'Withdraw',
        key: SupplyActionType.Withdraw,
        tabContent: (
          <WithdrawPane token={assetInfo!} api={api} account={account} handleClose={closeModal} />
        )
      }
    ];
  }, [account, api, assetInfo, closeModal]);

  const handleChangeSelectedTab = useCallback((key: string) => {
    setActiveTabKey(key as SupplyActionType);
  }, []);

  if (!assetInfo) return null;

  return (
    <ModalWrapper gap="2rem">
      <H3>{upperFirst(activeTabKey)}</H3>
      <Stack gap="0.5rem">
        <ViewAssetAnalytics token={assetInfo.symbol} />
        <KeepAliveTabs
          tabs={tabs}
          defaultActiveKey={tabKey}
          onTabChange={handleChangeSelectedTab}
        />
      </Stack>
    </ModalWrapper>
  );
};

export default LendWithdrawModal;
