import { FC, useRef, useState, useMemo } from 'react';
import { Inline, H5, Button } from '@parallel-mono/components';
import styled from 'styled-components';
import { KeyringPair } from '@polkadot/keyring/types';
import { useClickAway } from 'react-use';
import { upperCase } from 'lodash';

import WalletsList from './WalletsList';
import { ReactComponent as AccountBG } from './images/account-bg.svg';

import { truncateTextMid } from '@/utils/format';

const Container = styled.div`
  position: relative;
`;

const AccountWrapper = styled(Inline).attrs({
  inset: '0.25rem 0.25rem 0.25rem 1rem',
  gap: '0.5rem',
  alignItems: 'center',
  justifyContent: 'center'
})`
  border-radius: 100px;
  border: 2px solid var(--clr-white);
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  padding-right:
  &:hover {
    background-color: var(--clr-bg02);
  }
  ${({ theme }) => theme.breakpoints.down('lg')`
    width: 100%;
  `};
`;

const IconWrapper = styled(Inline).attrs({
  gap: '0',
  alignItems: 'center'
})`
  position: relative;
`;

const AccountNameLetter = styled(H5)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--clr-white);
`;

interface CurrentAccountProps {
  account: KeyringPair;
}

const CurrentAccountClassName = 'current-account';

const CurrentAccount: FC<CurrentAccountProps> = ({ account }) => {
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const walletsListRef = useRef<HTMLDivElement>(null);
  const accountName = useMemo<string>(() => (account?.meta?.name as string) ?? '', [account]);

  useClickAway(walletsListRef, (e: MouseEvent) => {
    const rootElement = document.getElementById('root');
    if (rootElement && rootElement.contains(e.target as Node)) {
      setOpenSelectModal(false);
    }
  });

  return (
    <Container ref={walletsListRef} className={CurrentAccountClassName}>
      <Inline
        onClick={() => {
          setOpenSelectModal(v => !v);
        }}
      >
        {account ? (
          <AccountWrapper>
            <H5 fontWeight="medium">{truncateTextMid(account.address, 0, 4)}</H5>
            <IconWrapper>
              <AccountNameLetter>{upperCase(accountName[0])}</AccountNameLetter>
              <AccountBG width="2rem" />
            </IconWrapper>
          </AccountWrapper>
        ) : (
          <Button skin="primary">Connect Wallet</Button>
        )}
      </Inline>
      <WalletsList opened={openSelectModal} />
    </Container>
  );
};

export default CurrentAccount;
