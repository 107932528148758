import { useEffect, useState } from 'react';
import { AccountInfo, ChainProperties } from '@polkadot/types/interfaces';
import { Text } from '@polkadot/types';
import { BN_ZERO, BN } from '@polkadot/util';

import { RelayChainToken } from '../config/index';

import { useChainConnections } from './useChainConnection';
import { useAccount } from './useAccount';
import useApiCall from './useApiCall';
import { AssetInfo } from './types';

import { chainAssetIds } from '@/utils/constants';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import config from '@/config';

export const defaultRelayAsset: Record<RelayChainToken, AssetInfo> = {
  [RelayChainToken.KSM]: {
    symbol: 'KSM',
    assetId: '100',
    decimals: new BN(config.rawDecimals.KSM),
    balance: 0,
    existentialDeposit: config.defaultEdValue,
    lockedBalance: 0,
    availableBalance: 0,
    maxAvailableBalance: 0,
    isNative: false
  },
  [RelayChainToken.DOT]: {
    symbol: 'DOT',
    assetId: '101',
    decimals: new BN(config.rawDecimals.DOT),
    balance: 0,
    existentialDeposit: config.defaultEdValue,
    lockedBalance: 0,
    availableBalance: 0,
    maxAvailableBalance: 0,
    isNative: false
  }
};

const useRelayAssetInfo = () => {
  const {
    relaychain: { api }
  } = useChainConnections();
  const { account } = useAccount();
  const [relayAssetInfo, setRelayAssetInfo] = useState<AssetInfo>(
    defaultRelayAsset[config.relayChainToken]
  );
  const [isReady, setIsReady] = useState(false);
  const { relayAssetId } = chainAssetIds[config.chain];

  const tokenProperties = useApiCall<ChainProperties>(api.rpc.system.properties);
  const accountInfo = useApiCall<AccountInfo>(account && api.query.system.account, [
    account?.address
  ]);
  const chainName = useApiCall<Text>(api.rpc.system.chain);

  useEffect(() => {
    if (
      tokenProperties &&
      chainName &&
      api &&
      api.consts.balances.existentialDeposit !== undefined
    ) {
      const { tokenSymbol, tokenDecimals } = tokenProperties;
      const { free = BN_ZERO, frozen = BN_ZERO } = accountInfo?.data || {};
      const decimals = tokenDecimals.isSome
        ? tokenDecimals.unwrap()[0]
        : new BN(config.rawDecimals[config.relayChainToken]);
      const symbol = tokenSymbol.isSome
        ? tokenSymbol.unwrap()[0].toString()
        : config.relayChainToken;
      const balance = balanceToAmountByDecimal<number>(free, decimals, 'number');
      const lockedBalance = balanceToAmountByDecimal<number>(frozen, decimals, 'number');
      const existentialDeposit = balanceToAmountByDecimal<number>(
        api.consts.balances.existentialDeposit,
        decimals,
        'number'
      );
      const availableBalance = Math.max(balance - lockedBalance - existentialDeposit, 0);

      const maxAvailableBalance = Math.max(availableBalance - config.relayChainMaxTxFee, 0);

      setRelayAssetInfo({
        assetId: relayAssetId,
        network: chainName,
        symbol,
        decimals,
        balance,
        existentialDeposit,
        lockedBalance,
        availableBalance,
        maxAvailableBalance,
        isNative: false
      });

      setIsReady(true);
    }
  }, [accountInfo, chainName, tokenProperties, relayAssetId, api]);
  return { isReady, relayAssetInfo };
};

export default useRelayAssetInfo;
