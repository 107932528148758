import { createContext } from 'react';

import { FullPool } from './hooks/usePools';
import { LpAssetsApy } from './hooks/useLpAssetsApy';
import { ExtendAssetDetail } from './hooks/useAssetDetails';
import { TransactionToken } from './hooks/useTransactionTokens';
import { ExtendAssetInfo } from './hooks/useExtendAssetInfos';
import { TransactionType } from './types';

import { AssetsData } from '@/hooks/types';

export interface DataContextType {
  poolSource: {
    isReady: boolean;
    pools: FullPool[];
  };
  assetsPriceSource: AssetsData | undefined;
  assetInfoSource: {
    isReady: boolean;
    isBalanceReady: boolean;
    assetIds: string[];
    assetInfos: ExtendAssetInfo[];
  };
  lpAssetsApySource: LpAssetsApy;
  assetDetailSource: {
    isReady: boolean;
    assetDetails: ExtendAssetDetail[];
  };
  ammDetailsSource: {
    isReady: boolean;
    ammDetails: Record<string, any> | undefined;
  };
  selectedTokens: TransactionToken[];
  handleSelectTokens: (fromToken: TransactionToken, toToken: TransactionToken) => void;
  transactionType: TransactionType;
}
export const DataContext = createContext<DataContextType>({} as DataContextType);
