import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';

import { ParaChainId } from '../../constants';
import { createXcmV3 } from '../../helpers/createXcm';

import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';

export const generateToAcalaTx = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  return api.tx.xTokens.transfer(
    asset.assetId,
    amountToBalanceByDecimals<BN>(amount, asset.decimals, 'bn'),
    createXcmV3(account?.address, ParaChainId.Acala),
    { Unlimited: '' }
  );
};
