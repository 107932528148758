import { FC, ReactNode, useMemo, useCallback, useState, ReactElement } from 'react';
import { Tabs, TabsProps, Stack, Skeleton } from '@parallel-mono/components';
import styled from 'styled-components';
import { usePrevious } from 'react-use';
import { debounce } from 'lodash';

import { useDualModeState } from '@/hooks';
import useAsyncEffect from '@/hooks/useAsyncEffect';

export interface KeepAliveTab {
  title: ReactNode;
  key: string;
  tabContent: ReactElement;
}

export interface TabHeaderProps {
  tabs: KeepAliveTab[];
  activeTab?: string;
  updateTabKey: (key: string) => void;
}

export interface KeepAliveTabsProps {
  tabs: KeepAliveTab[];
  onTabChange?: (key: string) => void;
  defaultActiveKey?: string;
  activeKey?: string;
  TabHeader?: FC<TabHeaderProps>;
  iconMode?: boolean;
  block?: boolean;
  gap?: string;
}

enum TabContentState {
  HIDDEN = 0,
  VISIBLE = 1,
  TRANSITION = 2
}

const getFinalTab = (tabs: KeepAliveTab[], key: string | undefined) => {
  return tabs.find(tab => tab.key === key);
};

const TabContent = styled.div<{ state: TabContentState }>`
  display: ${({ state }) => {
    if (state === TabContentState.HIDDEN) {
      return 'none';
    }
    if (state === TabContentState.VISIBLE) {
      return 'block';
    }
    return null;
  }};

  position: ${({ state }) => (state === TabContentState.TRANSITION ? 'relative' : null)};
`;

const TabContentView = styled.div<{ state: TabContentState }>`
  visibility: ${({ state }) => (state === TabContentState.TRANSITION ? 'hidden' : 'visible')};
`;

const SkeletonStyled = styled(Skeleton)`
  position: absolute;
  width: 100%;
`;

const StyledTabs = styled(Tabs)`
  .tab {
    height: 3rem;
  }
` as typeof Tabs;

interface MountedTab extends KeepAliveTab {
  state: TabContentState;
}

export const KeepAliveTabs: FC<KeepAliveTabsProps> = ({
  tabs,
  defaultActiveKey,
  activeKey,
  onTabChange,
  TabHeader,
  gap = '1.5rem',
  ...props
}) => {
  const [finalKey, setFinalKey] = useDualModeState(defaultActiveKey, activeKey);
  const [mountedTabs, setMountedTabs] = useState<MountedTab[]>([]);
  const prevFinalKey = usePrevious(finalKey);

  const finalDefaultActiveTab = useMemo(
    () => getFinalTab(tabs, defaultActiveKey),
    [defaultActiveKey, tabs]
  );

  const finalActiveTab = useMemo(() => getFinalTab(tabs, finalKey), [tabs, finalKey]);

  const updateFinalKey = useCallback(
    (key: string) => {
      setFinalKey(key);
      onTabChange?.(key);
    },
    [onTabChange, setFinalKey]
  );

  const handleActiveTabChange = useCallback(
    (tab: TabsProps<KeepAliveTab>['tabs'][number]) => {
      const newKey = tab.key as string;
      updateFinalKey(newKey);
    },
    [updateFinalKey]
  );

  const switchMountedTab = useCallback(
    (tab: KeepAliveTab | undefined, prevMountedTabs?: MountedTab[]) => {
      if (!tab) return;
      const newMountedTabs = (prevMountedTabs ?? mountedTabs).map(mountedTab => ({
        ...mountedTab,
        state: mountedTab.key === tab.key ? TabContentState.VISIBLE : TabContentState.HIDDEN
      }));
      setMountedTabs(newMountedTabs);
    },
    [mountedTabs]
  );

  const switchMountedTabByDebounce = useMemo(
    () => debounce(switchMountedTab, 2e3),
    [switchMountedTab]
  );

  useAsyncEffect(() => {
    if (finalKey) {
      const tab = tabs.find(({ key }) => key === finalKey);
      const hasMountedTab = mountedTabs.some(({ key }) => key === tab!.key);
      const mountedTabsLoaded = mountedTabs.every(
        ({ state }) => state !== TabContentState.TRANSITION
      );

      if (hasMountedTab) {
        if (mountedTabsLoaded) {
          switchMountedTab(tab);
        }
      } else {
        const newTab = tab as MountedTab;
        const nonEmptyMountedTabs = mountedTabs.length > 0;
        newTab.state = nonEmptyMountedTabs ? TabContentState.HIDDEN : TabContentState.VISIBLE;
        const prevMountedTabs = mountedTabs.map(mountedTab => ({
          ...mountedTab,
          state: mountedTab.key === prevFinalKey ? TabContentState.TRANSITION : mountedTab.state
        }));
        const newMountedTabs = [...prevMountedTabs, newTab];
        setMountedTabs(newMountedTabs);
        if (nonEmptyMountedTabs) {
          switchMountedTabByDebounce(tab, newMountedTabs);
        }
      }
    }
  }, [finalKey, prevFinalKey, tabs]);

  useAsyncEffect(() => {
    setMountedTabs(prevMountedTabs =>
      prevMountedTabs.map(tab => {
        const currentTab = tabs.find(({ key }) => key === tab.key);
        if (currentTab)
          return {
            ...tab,
            ...currentTab
          };
        return tab;
      })
    );
  }, [tabs]);

  return (
    <Stack gap={gap}>
      {TabHeader ? (
        <TabHeader tabs={tabs} activeTab={finalKey} updateTabKey={updateFinalKey} />
      ) : (
        <StyledTabs
          classNames={{ tab: 'tab' }}
          block={false}
          tabs={tabs}
          defaultActiveTab={finalDefaultActiveTab}
          activeTab={finalActiveTab}
          onActiveTabChange={handleActiveTabChange}
          {...props}
        />
      )}

      {mountedTabs.map(({ key, tabContent, state }) => {
        return (
          <TabContent key={key} state={state}>
            {state === TabContentState.TRANSITION && <SkeletonStyled loading inset="0" />}
            <TabContentView state={state}>{tabContent}</TabContentView>
          </TabContent>
        );
      })}
    </Stack>
  );
};
