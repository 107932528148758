import { createGlobalStyle, CSSObject } from 'styled-components';

const GlobalStyle = createGlobalStyle`

body {
  background-color: var(--clr-bg01);
  color: var(--clr-text01);
  ${({ theme }) => theme.typography.body as CSSObject};
  overflow: hidden;
}

input {
  font-family: var(--font-family);
}

#root {
  height: 100vh;
  overflow: auto;
}

ol,ul {
  list-style: none;
}

a {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --font-family: "DM Sans", "sans-serif";

  --clr-white: #ffffff;
  --clr-black: #2F2F2F;
  --clr-gray01: #5E6D7C;
  --clr-gray02: #9BA4AE;
  --clr-gray03: #D7D9E1;
  --clr-gray04: #F0F3FB;
  --clr-gray05: #F8F8F8;
  --clr-gray06: #EEEEEE;

  --clr-red: #EB5757;
  --clr-red-rgb: #FDEEEE;
  --clr-green-dark: #1DA63B;
  --clr-green-light: #d3ffdd;
  --clr-green-pantone: #1da63b;
  --clr-green: #11BC91;
  --clr-blue-dark: #0B2C9A;
  --clr-blue-medium: #0098c8;
  --clr-blue-light: #d9ecf7;
  --clr-blue: #0C3EE3;
  --clr-blue-rgb: 12, 62, 227;
  --clr-yellow: #FFBB0E;

  --clr-text01: var(--clr-black);
  --clr-text02: var(--clr-gray01);
  --clr-text03: var(--clr-gray02);
  --clr-text04: var(--clr-white);
  --clr-highlight: var(--clr-blue);
  --clr-highlight-medium: var(--clr-blue-medium);
  --clr-data-highlight: var(--clr-green);
  --clr-data-highlight-dark: var(--clr-green-dark);

  --clr-bg01: var(--clr-gray05);
  --clr-bg02: var(--clr-gray04);

  --clr-divide: var(--clr-gray03);
  --clr-link: var(--clr-blue);

  --box-shadow01: 0px 4px 16px rgba(58, 78, 88, 0.15);
  --box-shadow02: 0px 0px 16px rgba(58, 78, 88, 0.05);

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --border-radius: 16px
}

`;

export default GlobalStyle;
