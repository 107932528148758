import { ChainIcon } from '@parallel-mono/business-components';
import { Image } from '@parallel-mono/components';

import { BaseWallet, WalletType } from './types';
import parallelWallet from './images/parallel-wallet-bg.png';
import subWallet from './images/subwallet.png';

export const walletList: BaseWallet[] = [
  {
    type: WalletType.Polkadot,
    walletName: 'PolkadotJS',
    label: 'Polkadot JS',
    enable: true,
    icon: <ChainIcon name="polkadot" size="small" />,
    extensionLink: 'https://polkadot.js.org/extension/',
    bgImage: 'none'
  },
  {
    type: WalletType.Parallel,
    walletName: 'Parallel Wallet',
    label: 'Multichain',
    enable: true,
    icon: <ChainIcon name="parallel" size="small" />,
    description:
      'One wallet for everything. Connect and sign transactions on any chain using one wallet.',
    extensionLink:
      'https://chrome.google.com/webstore/detail/parallel-wallet/jbkgjmpfammbgejcpedggoefddacbdia',
    bgImage: `url(${parallelWallet}`
  },
  {
    type: WalletType.SubWallet,
    walletName: 'SubWallet',
    label: 'Multichain Wallet',
    enable: true,
    icon: <Image name="subwallet" src={subWallet} width="32px" />,
    extensionLink:
      'https://chromewebstore.google.com/detail/subwallet-polkadot-wallet/onhogfjeacnfoofkfgppdlbmlmnplgbn',
    bgImage: 'none'
  }
];
