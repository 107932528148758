import { FC, ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { Icon, IconName } from '../icon/icon';
import { InlineProps, Inline } from '../Layout';
import { Skin } from '../ThemeProvider';

export type AlertType = 'info' | 'warning' | 'error';
export type AlertProps = {
  type?: AlertType;
  icon?: ReactNode;
} & InlineProps;

const Container = styled(Inline)<{ skin: Skin }>`
  border-radius: 8px;
  color: ${({ skin, theme }) => theme.skin[skin].main};
  background-color: ${({ skin, theme }) => theme.skin[skin].contrastBackground};
`;

const iconNameMap: Record<AlertType, IconName> = {
  info: 'warning',
  error: 'warning',
  warning: 'warning',
};

const skinMap: Record<AlertType, Skin> = {
  info: 'neutral',
  error: 'error',
  warning: 'warning',
};

const NoShrinkIcon = styled(Icon)`
  flex-shrink: 0;
`;

export const Alert: FC<AlertProps> = ({ children, type = 'info', icon, inset, gap, ...others }) => {
  const iconName = iconNameMap[type];
  const skin = skinMap[type];
  const theme = useTheme();
  return (
    <Container gap="1rem" inset="1rem" skin={skin} {...others}>
      {icon || <NoShrinkIcon name={iconName} color={theme.skin[skin].main} />}
      <div>{children}</div>
    </Container>
  );
};

export default Alert;
