import { isNil } from 'lodash';
import styled from 'styled-components';
import { withComponentThemeOverride } from '../../HOC/withComponentThemeOverride';
import { FontWeight, Skin, TypographyVariant } from '../ThemeProvider/types';

export interface BasicTypographyProps {
  variant: TypographyVariant;
  skin?: Skin;
  fontWeight?: FontWeight;
}

const Typography = styled.p<BasicTypographyProps>(({ theme, variant, skin, fontWeight }) => {
  const overrides = isNil(fontWeight)
    ? {}
    : {
        fontWeight: theme.typography.fontWeight[fontWeight],
      };
  return {
    ...theme.typography[variant],
    ...overrides,
    color: skin ? theme.skin[skin].main : 'inherit',
  };
});

const OverridableTypography = withComponentThemeOverride(Typography, 'Typography');

export { OverridableTypography as Typography };
