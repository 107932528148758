import { AccountData } from '@polkadot/types/interfaces';

import { CommonChainMetadata } from '../types';

export enum InterlayChainCurrency {
  INTR = 'INTR',
  IBTC = 'IBTC',
  KINT = 'KINT',
  KBTC = 'KBTC'
}

export interface ChainMetadata extends CommonChainMetadata {
  tokensInfo: AccountData[];
}
