import { isFunction } from 'lodash';
import { memo, useEffect, useMemo } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-use';

export type RedirectFromHashRouteProps = {
  from: Parameters<typeof matchPath>[0];
  to?: string | ((pathname: string) => string);
};

const getFinalPath = (to: RedirectFromHashRouteProps['to'], pathname: string) => {
  if (isFunction(to)) {
    return to(pathname);
  }
  return to;
};

export const RedirectFromHashRoute = memo((props: RedirectFromHashRouteProps) => {
  const { from, to } = props;
  const { hash, host, protocol } = useLocation();
  const navigate = useNavigate();
  const normalizedHash = hash?.substring(1) ?? '';
  const hashURL = useMemo(
    () => (normalizedHash ? new URL(`${protocol}//${host}${normalizedHash}`) : null),
    [normalizedHash, protocol, host]
  );
  const hashPathname = hashURL?.pathname;
  const hashSearch = hashURL?.search;
  useEffect(() => {
    const match = hashPathname ? matchPath(from, hashPathname) : null;
    if (match && hashPathname) {
      const finalPath = getFinalPath(to ?? hashPathname, hashPathname);
      navigate({ pathname: finalPath, search: hashSearch }, { replace: true });
    }
  }, [from, to, hashPathname, hashSearch, navigate]);
  return null;
});
