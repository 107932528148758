import styled from 'styled-components';
import { useState, useMemo, FC, useCallback } from 'react';
import { BN_ZERO } from '@polkadot/util';
import { floor } from 'lodash';
import { useDebounce } from 'react-use';
import {
  Inline,
  Stack,
  H5,
  Text,
  SmallText,
  Button,
  Tooltip,
  Spinner,
  Icon
} from '@parallel-mono/components';
import {
  CryptoAsset,
  TokenInput,
  ChainIcon,
  InfoPanel,
  InfoPanelProps
} from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import Checkbox from '../Checkbox';

import { AssetInfo } from '@/hooks/types';
import { useApiCall, useChainConnections, useAccount, useXcmTx } from '@/hooks';
import { signAndSend, txFee } from '@/utils/txCall';
import config, { RelayChainToken } from '@/config';
import { isValidAmountInput } from '@/utils/validate';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { balanceFormatter } from '@/utils/format';
import { useCurrentAccountRelayAssetInfo } from '@/contexts/AssetsInfoContext';
import getFeatureToggle from '@/utils/toggles/getFeatureToggle';

const enum Step {
  INIT,
  PROCESSING,
  DONE,
  FAIL
}

const StyledValue = styled(Inline)`
  flex: 1;
  white-space: nowrap;
`;

const ProcessStyled = styled(Stack)`
  padding-top: 36px;
`;
const defaultAsset: AssetInfo = {
  network: undefined,
  symbol: '',
  decimals: BN_ZERO,
  balance: 0,
  existentialDeposit: 0,
  lockedBalance: 0,
  assetId: '',
  availableBalance: 0,
  maxAvailableBalance: 0,
  isNative: false
};

const DepositModal: FC<{
  minDeposit?: number;
  asset?: AssetInfo;
  closeModal: () => void;
}> = ({
  minDeposit = config.relayChainToken === RelayChainToken.DOT ? 5 : 0.1,
  asset = defaultAsset,
  closeModal
}) => {
  const [checked, setChecked] = useState(false);
  const {
    relaychain: { api: relayApi },
    parachain: { api: paraApi }
  } = useChainConnections();

  const [amount, setAmount] = useState<number | null>(null);
  const amountNum = amount ?? 0;
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const [step, setStep] = useState(Step.INIT);
  const chainName = useApiCall<string>(paraApi.rpc.system.chain) ?? '';
  const relayChainName = useApiCall<string>(relayApi.rpc.system.chain) ?? '';

  const { account } = useAccount();
  const generateXcmTx = useXcmTx(amountNum, asset.decimals, account?.address);
  const { relayAssetInfo: relayAsset } = useCurrentAccountRelayAssetInfo();
  const { availableBalance: relayAssetAvailableBalance, maxAvailableBalance } = relayAsset;

  const { isSufficientBalance, TxFeeTips } = useTxFeeValidation(relayAsset);

  const parallelBalance = asset.balance;

  const depositWarn = useMemo(() => {
    return maxAvailableBalance > 0 && maxAvailableBalance - amountNum < 0;
  }, [amountNum, maxAvailableBalance]);

  const minDepositWarn = useMemo(
    () => amount !== null && !depositWarn && amountNum <= minDeposit,
    [amount, depositWarn, amountNum, minDeposit]
  );

  const hideTransferVal = useMemo(() => {
    return amount === null || !account || depositWarn || minDepositWarn || step === Step.PROCESSING;
  }, [account, amount, depositWarn, minDepositWarn, step]);

  const isDepositDisabled = useMemo(
    () => hideTransferVal || !checked || isSufficientBalance,
    [hideTransferVal, checked, isSufficientBalance]
  );

  useDebounce(
    () => {
      const getFee = async () => {
        setTransactionFee(null);
        const tx = generateXcmTx();
        const fee = await txFee({
          tx,
          account
        });
        setTransactionFee(fee);
      };
      if (account) {
        getFee();
      }
    },
    500,
    [generateXcmTx]
  );

  const handleClickMax = useCallback(() => {
    setAmount(floor(maxAvailableBalance > 0 ? maxAvailableBalance : 0, 4));
  }, [maxAvailableBalance]);

  const handleDeposit = async () => {
    setStep(Step.PROCESSING);
    setChecked(false);
    signAndSend({
      api: relayApi,
      account,
      tx: generateXcmTx(),
      txSuccessCb: () => {
        setStep(Step.DONE);
      },
      txFailedCb: () => {
        setStep(Step.FAIL);
      }
    });
  };

  const resetModal = () => {
    setStep(Step.INIT);
    setAmount(null);
    closeModal();
  };

  const renderErrMsg = () => {
    if (depositWarn) {
      return 'Insufficient balance.';
    }

    if (minDepositWarn) {
      return `The deposit amount should be greater than ${minDeposit} ${asset.symbol}`;
    }

    return null;
  };

  const infos = useMemo<InfoPanelProps['infos']>(
    () => [
      {
        title: `${chainName} Balance`,
        tip: `This is your new balance on the ${chainName} chain.`,
        value: (
          <StyledValue alignItems="center" justifyContent="flex-end" gap="2px">
            <H5>{formatNumber(parallelBalance)}</H5>
            {!hideTransferVal ? (
              <>
                <Icon name="arrowRight" />
                <H5>
                  {formatNumber(Math.max(parallelBalance + amountNum - (transactionFee || 0), 0))}
                </H5>
              </>
            ) : null}
            <H5>{asset.symbol}</H5>
          </StyledValue>
        )
      },
      {
        title: `${relayChainName} Balance`,
        tip: 'After the deposit transaction completes, this is your new balance on the relay chain.',
        value: (
          <StyledValue alignItems="center" justifyContent="flex-end" gap="2px">
            <H5>{formatNumber(relayAssetAvailableBalance)}</H5>
            {!hideTransferVal ? (
              <>
                <Icon name="arrowRight" />
                <H5>
                  {formatNumber(
                    Math.max(relayAssetAvailableBalance - amountNum - (transactionFee || 0), 0)
                  )}
                </H5>
              </>
            ) : null}
            <H5>{asset.symbol}</H5>
          </StyledValue>
        )
      }
    ],
    [
      amountNum,
      asset.symbol,
      chainName,
      hideTransferVal,
      parallelBalance,
      relayAssetAvailableBalance,
      relayChainName,
      transactionFee
    ]
  );

  const depositAndWithdrawDisabled = useMemo(
    () => getFeatureToggle('TOGGLE_RELAY_CHAIN_DEPOSIT_DISABLED'),
    []
  );

  return (
    <>
      {step === Step.INIT && (
        <>
          <Inline justifyContent="space-between" alignItems="center" inset="1rem 5rem">
            <CryptoAsset symbol={config.relayChainToken} symbolSize="large" />
            <Icon name="arrowRight" color="var(--clr-grey1)" />
            <ChainIcon name="parallel" size="large" />
          </Inline>
          <Stack gap="1.5rem">
            <TokenInput
              label={<H5>{asset.symbol} Amount</H5>}
              hint={
                <Text skin="secondary">
                  Available: {balanceFormatter(relayAssetAvailableBalance)}{' '}
                  <Tooltip
                    getPopupContainer={(trigger: HTMLElement) =>
                      trigger.parentElement as HTMLElement
                    }
                    placement="top-end"
                    content={`This is your available balance that can be transferred from the relay chain to ${chainName}.`}
                  />
                </Text>
              }
              token={asset.symbol.toUpperCase()}
              placeholder="0"
              value={amount}
              onChange={value => {
                if (value === null || isValidAmountInput(value.toString(), 12, 4)) {
                  setAmount(value);
                }
              }}
              error={renderErrMsg()}
              actionButtonText="Max"
              onAction={handleClickMax}
              inputProps={{ autoFocus: true }}
            />

            {infos.length > 0 ? <InfoPanel infos={infos} /> : null}

            <Inline>
              <Checkbox checked={checked} onChange={target => setChecked(target.checked)}>
                <SmallText skin="secondary">
                  I acknowledge I am not using ledger wallet. Funds deposited from ledger will not
                  be able to be used or refunded.
                </SmallText>
              </Checkbox>
            </Inline>

            <Tooltip
              disabled={!depositAndWithdrawDisabled}
              content="Deposit is currently not enabled for this asset."
            >
              <Button
                skin="primary"
                block
                size="large"
                onClick={handleDeposit}
                disabled={isDepositDisabled || depositAndWithdrawDisabled}
              >
                Confirm
              </Button>
            </Tooltip>

            <TxFeeTips
              txFee={transactionFee}
              toolTip={`This is the deposit transaction fee in ${asset.symbol}.`}
            />
          </Stack>
        </>
      )}
      {step === Step.PROCESSING && (
        <ProcessStyled gap="24px" alignItems="center" justifyContent="center">
          <Spinner size="large" />
          <Stack gap="4px" alignItems="center">
            <H5>Depositing</H5>
            <Text>This may take up to 20 seconds...</Text>
          </Stack>
        </ProcessStyled>
      )}
      {step === Step.FAIL && (
        <ProcessStyled gap="24px" alignItems="center" justifyContent="center">
          <Icon name="containedClose" size="xlarge" color="var(--clr-red)" />
          <Stack gap="4px" alignItems="center">
            <H5>Some error has occurred.</H5>
            <Text>Your deposit cannot be processed. Please try again later.</Text>
          </Stack>

          <Button skin="secondary" onClick={resetModal}>
            Okay
          </Button>
        </ProcessStyled>
      )}
      {step === Step.DONE && (
        <ProcessStyled gap="24px" alignItems="center" justifyContent="center">
          <Icon name="containedCheck" size="xlarge" color="var(--clr-green)" />
          <Stack gap="4px" alignItems="center">
            <H5>
              {amount} {asset.symbol} Deposited!
            </H5>
            <Text>You can now use it on any Parallel product.</Text>
          </Stack>
          <Button skin="secondary" onClick={resetModal}>
            Done
          </Button>
        </ProcessStyled>
      )}
    </>
  );
};

export default DepositModal;
