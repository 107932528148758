import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

import config from '@/config';

const useMarketTVL = () => {
  const [totalValue, setTotalValue] = useState<number>(0);

  const fetchTotalValue = useCallback(async () => {
    try {
      const { data } = await axios.get<number>(
        `${config.DEFILLAMA_SERVICE_URL}/tvl/parallel-lending`
      );

      setTotalValue(data);
    } catch (error) {
      console.log(error);
    }
  }, [setTotalValue]);

  useEffect(() => {
    fetchTotalValue();
  }, [fetchTotalValue]);

  return totalValue;
};

export default useMarketTVL;
