import { ButtonHTMLAttributes, ComponentProps, FC, ReactElement } from 'react';
import {
  NumericInputBase,
  Inline,
  Stack,
  SmallText,
  Text,
  Button
} from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import styled, { CSSObject } from 'styled-components';
import { isFunction } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

interface WrapperProps {
  withError?: boolean;
}

export const Wrapper = styled(Inline)<WrapperProps>`
  --border-error: 1px solid var(--clr-red);
  --border-normal: 1px solid var(--clr-divide);
  --border-highlight: 1px solid var(--clr-highlight);

  width: 100%;
  border-radius: 7px;
  padding: 10px;
  border: ${props => (props.withError ? 'var(--border-error)' : 'var(--border-normal)')};
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: 5px;
  `};
  :focus-within {
    border: ${props => (props.withError ? 'var(--border-error)' : 'var(--border-highlight)')};
  }
`;
const Max = styled(Button)`
  padding: 3px 8px;
`;

const StyledInput = styled(NumericInputBase)`
  width: 100%;
  min-width: 0;
  border: 0px;
  padding: 10px 0px;
  outline: none;
  background: none;
  color: var(--clr-text01);
  ${({ theme }) => theme.typography.header3 as CSSObject};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    color: var(--clr-text03);
  }
  -moz-appearance: textfield;
  :disabled {
    cursor: not-allowed;
  }
`;

interface NumberInputProps extends ComponentProps<typeof NumericInputBase> {
  token?: string;
  clickMax?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
  errorMessage?: ReactElement | string | null;
  maxBtnDisabled?: boolean;
  usdBalance?: number;
  maxButtonText?: string;
}

export const NumberInput: FC<NumberInputProps> = ({
  token,
  clickMax,
  errorMessage,
  maxButtonText = 'Max',
  maxBtnDisabled = false,
  usdBalance,
  children,
  decimals = 6,
  thousandSeparator = true,
  ...rest
}) => {
  const shouldShowUsdBalance = usdBalance !== 0 && usdBalance != null;

  return (
    <>
      <Wrapper withError={!!errorMessage} alignItems="center" justifyContent="space-between">
        <Inline gap="0.75rem" alignItems="center" width="100%">
          {token && <CryptoAsset symbol={token} symbolSize="small" />}
          <Stack gap="0.2rem" width="100%">
            <StyledInput
              min={0}
              onWheel={e => {
                e.currentTarget.blur();
              }}
              thousandSeparator={thousandSeparator}
              decimals={decimals}
              {...rest}
            />
            {shouldShowUsdBalance && (
              <SmallText skin="secondary">
                ~{`${formatNumber(usdBalance as number, { output: 'currency' })}`}
              </SmallText>
            )}
          </Stack>
          {children}
        </Inline>
        {isFunction(clickMax) && (
          <Max skin="secondary" onClick={clickMax} disabled={maxBtnDisabled}>
            <SmallText>{maxButtonText}</SmallText>
          </Max>
        )}
      </Wrapper>
      {errorMessage && (
        <Text skin="error" forwardedAs="div">
          {errorMessage}
        </Text>
      )}
    </>
  );
};
