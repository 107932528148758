import { BasicTypographyProps, Typography } from './Typography';
import { withDeterminedVariant } from '../../HOC/withDeterminedVariant';
import { TypographyVariant } from '../ThemeProvider/types';
import { StyledComponent, DefaultTheme } from 'styled-components';

type PropsWithoutVariant = Omit<BasicTypographyProps, 'variant'>;

type TextType = StyledComponent<'p', DefaultTheme, PropsWithoutVariant, never>;

export const BigText = withDeterminedVariant(
  Typography,
  TypographyVariant.bodyBig
) as unknown as TextType;

export const Text = withDeterminedVariant(
  Typography,
  TypographyVariant.body
) as unknown as TextType;

export const SmallText = withDeterminedVariant(
  Typography,
  TypographyVariant.bodySmall
) as unknown as TextType;
