import { Chains } from '../types';

import { StatemintChainCurrency } from './types';

import config, { Chain } from '@/config';

export const CURRENCIES = {
  [Chains.Parallel]: [StatemintChainCurrency.USDT]
};

export const TO_CHAINS = [Chains.Parallel];

export const MAX_STATEMINE_TRANSACTION_FEE = {
  [Chain.HEIKO]: 0.005,
  [Chain.PARALLEL]: 0.7
}[config.chain];

export const MINIMUM_TRANSFER_AMOUNT = {
  [Chain.PARALLEL]: {
    [StatemintChainCurrency.USDT]: 1
  },
  [Chain.HEIKO]: {
    [StatemintChainCurrency.USDT]: 1
  }
}[config.chain];

export const USDT_ASSET_ID = 1984;
