import { useMemo, useState, FC } from 'react';
import dayjs from 'dayjs';
import { ApiPromise } from '@polkadot/api';
import { Stack, SmallText, BigText } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { TOKEN_META } from '../constant';
import TableRowProcess from '../TableRowProcess';
import { StreamListItem } from '../useFetchList';

import CancelModal from './CancelModal';
import SendTableMenu from './SendTableMenu';

import { useAccount, useAssetPrices } from '@/hooks';
import { balanceFormatter, truncateTextMid } from '@/utils/format';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { StyledDataGrid, StyledDataGridColumn } from '@/components';

interface SendTableProps {
  isReady?: boolean;
  api: ApiPromise;
  rows: StreamListItem[];
}

const SendTable: FC<SendTableProps> = ({ isReady, api, rows }) => {
  const assetsPrice = useAssetPrices();
  const { account } = useAccount();

  const [cancelRowData, setCancelRowData] = useState({} as { id: string; remaining: string });
  const [cancelingIds, setCancelingIds] = useState<string[]>([]);

  const onCancelClick = (id: string, value: number, symbol: string) => {
    setCancelingIds(ids => ids.concat(id));
    setCancelRowData({
      id,
      remaining: `${balanceFormatter(value)} ${symbol}`
    });
  };

  const columns = useMemo<StyledDataGridColumn<StreamListItem>[]>(() => {
    const calculateUSD = (amount: number, assetId: number) => {
      if (assetsPrice && assetsPrice[assetId]) {
        return amount * assetsPrice[assetId];
      }
      return 0;
    };

    return [
      {
        name: 'deposit',
        title: 'Amount',
        width: '1fr',
        render: ({ data: { deposit, assetId } }) => {
          const { decimals, symbol } = TOKEN_META[assetId];
          const amount = balanceToAmountByDecimal<number>(deposit, decimals, 'number');
          return (
            <Stack gap="0">
              <BigText>
                {balanceFormatter(amount)} {symbol}
              </BigText>
              <SmallText skin="secondary">
                {formatNumber(calculateUSD(amount, assetId), { output: 'currency' })}
              </SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'sent',
        title: 'Sent',
        width: '1fr',
        render: ({ data }) => {
          return <TableRowProcess data={data} />;
        }
      },
      {
        name: 'to',
        title: 'To',
        width: '1fr',
        render: ({ data: { recipient } }) => {
          return <BigText>{truncateTextMid(recipient)}</BigText>;
        }
      },
      {
        name: 'start',
        title: 'Start',
        width: '1fr',
        render: ({ data: { startTime } }) => {
          return <BigText>{dayjs(startTime).format('MM/DD/YYYY hh:mma')}</BigText>;
        }
      },
      {
        name: 'end',
        title: 'End',
        width: '1fr',
        render: ({ data: { endTime } }) => {
          return <BigText>{dayjs(endTime).format('MM/DD/YYYY hh:mma')}</BigText>;
        }
      },
      {
        name: 'menu',
        title: '',
        width: '0.1fr',
        render: ({ data }) => {
          return (
            <SendTableMenu
              rowData={data}
              cancelingIds={cancelingIds}
              onCancelClick={onCancelClick}
            />
          );
        }
      }
    ];
  }, [assetsPrice, cancelingIds]);

  const handleCancelModalClose = () => {
    setCancelingIds(ids => ids.filter(id => id !== cancelRowData.id));
  };

  return (
    <>
      <StyledDataGrid<StreamListItem>
        title="Recent Streams"
        loading={!isReady}
        columns={columns}
        data={rows}
      />
      <CancelModal
        api={api}
        account={account}
        cancelingIds={cancelingIds}
        cancelRowData={cancelRowData}
        handleClose={handleCancelModalClose}
      />
    </>
  );
};

export default SendTable;
