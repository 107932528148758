// @ts-nocheck
/* eslint-disable */
import { assign, groupBy } from 'lodash';

export const lastTokens = allSwaps => {
  if (allSwaps) {
    const tokenSnapshot = allSwaps.map((item, i) => {
      const pairToken = item?.assets.map((tokens, b) => {
        return {
          date: tokens?.date,
          ticker: tokens.token.ticker,
          val: tokens.token.val
        };
      });
      const baseToken = item?.assets.map((tokens, b) => {
        return {
          date: tokens?.date,
          ticker: tokens.baseToken.ticker,
          val: tokens.baseToken.val
        };
      });
      return assign({ pairToken, baseToken });
    });

    const tokenArray = tokenSnapshot.map((item, i) => {
      return [...item?.pairToken, ...item?.baseToken];
    });

    const flatArray = groupBy(tokenArray.flat(), 'ticker');
    const detailing = Object.entries(flatArray).map((item, i) => {
      const reducedvalues = Object.values(
        item[1].reduce((r, o) => {
          r[o.date] = r[o.date] || { ticker: o.ticker, date: o.date, val: 0 };
          r[o.date].val += +o.val;
          return r;
        }, {})
      );
      return reducedvalues;
    });

    return groupBy(detailing.flat(), 'ticker');
  }
};
