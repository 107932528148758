import { isNumber, map, zipObject } from 'lodash';
import { css } from 'styled-components';
import { BreakpointFunctions, Breakpoints, DeviceType, ScreenSize } from './types';

const valueToString = (value: number | string) => (isNumber(value) ? `${value}px` : value);

const createGradedQuerys = (
  configs: { name: DeviceType | ScreenSize; value: number | string }[]
) => {
  const querys = configs.map(({ value }, index) => {
    if (index === configs.length - 1) {
      return `(min-width: ${valueToString(value)})`;
    }

    return `(min-width: ${valueToString(value)}) and (max-width: calc(${valueToString(
      configs[index + 1].value
    )} - 1px))`;
  });

  return zipObject(map(configs, 'name'), querys);
};

const deviceGrades: DeviceType[] = ['mobile', 'tablet', 'desktop'];
const screenSizeGrades: ScreenSize[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export const creatBreakpointFunctions = (breakpoints: Breakpoints): BreakpointFunctions => {
  const deviceQuerys = createGradedQuerys(
    deviceGrades.map(device => ({
      name: device,
      value: breakpoints[device] ?? 0,
    }))
  );
  const screenSizeQuerys = createGradedQuerys(
    screenSizeGrades.map(size => ({
      name: size,
      value: breakpoints[size] ?? 0,
    }))
  );

  return {
    up:
      type =>
      (...args) =>
        css`
          @media screen and (min-width: ${valueToString(breakpoints[type])}) {
            ${css(...args)}
          }
        `,
    down:
      type =>
      (...args) =>
        css`
          @media screen and (max-width: calc(${valueToString(breakpoints[type])} - 1px)) {
            ${css(...args)}
          }
        `,
    only:
      type =>
      (...args) =>
        css`
          @media screen and ${type in deviceQuerys ? deviceQuerys[type] : screenSizeQuerys[type]} {
            ${css(...args)}
          }
        `,
    not:
      type =>
      (...args) =>
        css`
          @media screen and not (${type in deviceQuerys
              ? deviceQuerys[type]
              : screenSizeQuerys[type]}) {
            ${css(...args)}
          }
        `,
    between:
      (start, end) =>
      (...args) =>
        css`
          @media screen and (min-width: ${valueToString(
              breakpoints[start]
            )}) and (max-width: ${valueToString(breakpoints[end])}) {
            ${css(...args)}
          }
        `,
  } as BreakpointFunctions;
};
