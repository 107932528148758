import styled, { CSSObject } from 'styled-components';
import { Inline, Text } from '@parallel-mono/components';

import InputText from './InputText';
import { NumberInput } from './NumberInput';

interface WrapperProps {
  withError?: boolean;
}

export const ErrorText = styled(Text).attrs({ skin: 'error' })``;

export const Wrapper = styled(Inline)<WrapperProps>`
  --border-error: 1px solid var(--clr-red);
  --border-normal: 1px solid var(--clr-divide);
  --border-highlight: 1px solid var(--clr-highlight);

  width: 100%;
  border-radius: 7px;
  padding: 10px;
  border: ${props => (props.withError ? 'var(--border-error)' : 'var(--border-normal)')};
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: 5px;
  `};
  :focus-within {
    border: ${props => (props.withError ? 'var(--border-error)' : 'var(--border-highlight)')};
  }
`;

export const Input = styled.input`
  width: 100%;
  min-width: 0;
  border: 0px;
  padding: 10px 0px;
  outline: none;
  background: none;
  color: var(--clr-text01);
  ${({ theme }) => theme.typography.header3 as CSSObject};
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::placeholder {
    color: var(--clr-text03);
  }
  -moz-appearance: textfield;
`;

export { InputText, NumberInput };
