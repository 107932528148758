import { u8aConcat, stringToU8a } from '@polkadot/util';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { blake2AsU8a } from '@polkadot/util-crypto';

import { createAddress } from '@/utils/utils';

export const loansFarmingRewardAccountId = (): string => {
  const entropy = blake2AsU8a(
    u8aConcat(stringToU8a('loans/farming'), decodeAddress(createAddress('par/loan'))),
    256
  );
  return encodeAddress(entropy);
};
