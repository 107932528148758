import { useContext, useMemo } from 'react';
import { Keyring } from '@polkadot/keyring';
import { objectSpread } from '@polkadot/util';
import { KeyringPair$Meta } from '@polkadot/keyring/types';

import { ExtensionWallets } from '@/contexts/WalletsContext/types';
import { AccountsContext } from '@/contexts/AccountsContext';

const useLoadAccounts = (extensionName: ExtensionWallets) => {
  const { accounts, connectAccounts } = useContext(AccountsContext);

  const keyring = useMemo(() => {
    if (!accounts) {
      return null;
    }

    const filteredAccount = accounts.filter(account => {
      const otherSupportedWallet = [ExtensionWallets.SubWallet, ExtensionWallets.Parallel];

      // Polkadot wallet will compatible with all other wallets except Parallel wallet
      if (
        extensionName === ExtensionWallets.Polkadot &&
        !otherSupportedWallet.includes(account.meta.source as ExtensionWallets)
      ) {
        return true;
      }
      return account.meta.source === extensionName;
    });
    const keyringInstance = new Keyring();
    filteredAccount.forEach(account => {
      const meta = objectSpread<KeyringPair$Meta>({}, account.meta, { isInjected: true });
      keyringInstance.addFromAddress(account.address, meta, null, account.type);
    });
    return keyringInstance;
  }, [accounts, extensionName]);

  return { keyring, connectAccounts };
};

export default useLoadAccounts;
