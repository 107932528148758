import { useState, memo, useCallback, useMemo, FC } from 'react';
import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { Stack, Inline, H5, Text, Button, Tooltip, Icon } from '@parallel-mono/components';
import { TokenInput, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { BN } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

import { AssetRow } from '../../LendAndBorrowTable';
import { HfProgressBar } from '../../components/HfProgressBar';
import { useBorrowLimit, useLiquidationFreeLimit } from '../../hooks';

import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { ConnectToWallet } from '@/components';
import { useTransactionFee } from '@/hooks';
import { toFloorFixed, balanceFormatter } from '@/utils/format';
import { signAndSend } from '@/utils/txCall';
import { isValidAmountInput } from '@/utils/validate';
import { isFloatLessThan, amountToBalanceByDecimals } from '@/utils/calculations';
import { MAX_TRANSACTION_FEE } from '@/utils/constants';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

interface IRepayPaneProps {
  token: AssetRow;
  handleClose: Function;
  api: ApiPromise;
  account: KeyringPair;
}

const RepayPane: FC<IRepayPaneProps> = ({ token, handleClose, api, account }) => {
  const [borrowAmount, setBorrowAmount] = useState<number | null>(null);
  const [errorInputMessage, setErrorInputMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const toTXAmount = amountToBalanceByDecimals<BN>(borrowAmount || 0, token.decimals, 'bn');

  const threshold = 1e-4;
  const isRepayBorrowAll = isFloatLessThan(token.borrowedAmount! - Number(borrowAmount), threshold);
  const { TxFeeTips } = useTxFeeValidation();
  const transactionFee = useTransactionFee(
    {
      api,
      tx: isRepayBorrowAll
        ? api.tx.loans.repayBorrowAll(token.assetId)
        : api.tx.loans.repayBorrow(token.assetId, toTXAmount)
    },
    [toTXAmount.toString()]
  );
  const { nativeAssetInfo: nativeToken } = useCurrentAccountNativeAssetInfo();

  const { lfCollateralLimit, lfAccountBorrowValue } = useBorrowLimit();

  const { lfLiquidityLimit, checkBorrowLiquidationFree, getRemainingBorrowLimit } =
    useLiquidationFreeLimit();

  const isLiquidationFreeAsset = checkBorrowLiquidationFree(token.assetId);

  const remainingBorrowLimit = getRemainingBorrowLimit(token.assetId);
  const maxBorrowLimit = useMemo(() => {
    return Math.max(lfAccountBorrowValue - lfCollateralLimit, 0) + remainingBorrowLimit;
  }, [lfCollateralLimit, lfAccountBorrowValue, remainingBorrowLimit]);

  const isNativeToken = token.symbol === nativeToken.symbol;

  const edValueAndTxFee = useMemo(() => {
    if (isNativeToken && nativeToken?.existentialDeposit) {
      return nativeToken.existentialDeposit + MAX_TRANSACTION_FEE;
    }
    return 0;
  }, [nativeToken, isNativeToken]);

  const confirmRepay = () => {
    setProcessing(true);
    signAndSend({
      api,
      account,
      tx: isRepayBorrowAll
        ? api.tx.loans.repayBorrowAll(token.assetId)
        : api.tx.loans.repayBorrow(token.assetId, toTXAmount),
      txSuccessCb: () => {
        handleClose();
      },
      txProcessingCb: () => {
        handleClose();
      },
      txFailedCb: () => {
        setProcessing(false);
      }
    });
  };

  const inputChangeHandler = useCallback(
    (value: number | null) => {
      setErrorInputMessage('');

      // check if entered amount is greater than max allowed amount
      if (Number(value) > token.borrowedAmount!) {
        setErrorInputMessage('Cannot exceed borrowing limit.');
      }
      // check for negative numbers
      if (value !== null && Number(value) <= 0) {
        setErrorInputMessage('Please enter a value greater than zero');
      }

      if (Number(value) > token.availableBalance - edValueAndTxFee) {
        setErrorInputMessage('Insufficient balance to repay the borrowing.');
      }
      if (isValidAmountInput(value?.toString() ?? '', 12, 6) || value === null) {
        setBorrowAmount(value);
      }
    },
    [edValueAndTxFee, token]
  );

  const handleClickMax = useCallback(() => {
    const newBorrowedAmount = token.borrowedAmount as number;
    const repayAvailable =
      token.availableBalance > newBorrowedAmount
        ? newBorrowedAmount - (isNativeToken ? MAX_TRANSACTION_FEE : 0)
        : token.maxAvailableBalance;

    if (repayAvailable <= 0) return;
    inputChangeHandler(toFloorFixed(repayAvailable, 6));
  }, [
    token.availableBalance,
    token.borrowedAmount,
    token.maxAvailableBalance,
    isNativeToken,
    inputChangeHandler
  ]);

  const buttonMessage = () => {
    if (!token.borrowedAmount) return 'No Debt to Repay';
    return !borrowAmount ? 'Enter an Amount' : 'Repay';
  };
  const repayValue = useMemo(() => {
    return Number(borrowAmount) * token.price;
  }, [borrowAmount, token.price]);

  const newLfLiquidityLimit = useMemo(() => {
    const addedAmount = repayValue - maxBorrowLimit + remainingBorrowLimit;
    return lfLiquidityLimit + Math.max(0, addedAmount);
  }, [lfLiquidityLimit, repayValue, maxBorrowLimit, remainingBorrowLimit]);

  const newBorrowLimit = useMemo(() => {
    if (!isLiquidationFreeAsset) {
      return remainingBorrowLimit + repayValue;
    }
    return Math.min(maxBorrowLimit, remainingBorrowLimit + repayValue);
  }, [remainingBorrowLimit, repayValue, isLiquidationFreeAsset, maxBorrowLimit]);

  const infos = useMemo(
    () =>
      [
        isLiquidationFreeAsset
          ? {
              title: 'Liquidation Free Limit',
              tip: 'This is the maximum amount you can borrow for a liquidation free loan with no risk of liquidation, after you add collateral. It depends on the value you have deposited and the available liquidity.',
              value: (
                <Inline gap="0.2rem">
                  <H5>{formatNumber(lfLiquidityLimit, { output: 'currency' })}</H5>
                  {!!borrowAmount && (
                    <>
                      <Icon name="arrowRight" />
                      <H5>{formatNumber(newLfLiquidityLimit, { output: 'currency' })}</H5>
                    </>
                  )}
                </Inline>
              )
            }
          : null,
        {
          title: 'Borrow Limit',
          tip: 'This is the maximum amount you can borrow, after you add collateral. It depends on the value you have deposited and the available liquidity.',
          value: (
            <Inline gap="0.2rem">
              <H5>{formatNumber(remainingBorrowLimit, { output: 'currency' })}</H5>
              {!!borrowAmount && (
                <>
                  <Icon name="arrowRight" />
                  <H5>{formatNumber(newBorrowLimit, { output: 'currency' })}</H5>
                </>
              )}
            </Inline>
          )
        }
      ].filter(info => info) as InfoPanelProps['infos'],
    [
      borrowAmount,
      isLiquidationFreeAsset,
      lfLiquidityLimit,
      newBorrowLimit,
      newLfLiquidityLimit,
      remainingBorrowLimit
    ]
  );

  return (
    <Stack gap="1.5rem">
      <TokenInput
        label={<H5>{token.symbol} Amount</H5>}
        hint={
          <Inline gap="0.2rem" alignItems="center">
            <Inline gap="0.5rem" alignItems="center">
              <Text skin="secondary">Borrowing:</Text>
              <H5>{balanceFormatter(token.borrowedAmount!)}</H5>
            </Inline>
            <Inline>
              <Tooltip content="This is the amount that you have borrowed." />
            </Inline>
          </Inline>
        }
        token={token.name.toUpperCase()}
        value={borrowAmount}
        onChange={inputChangeHandler}
        onAction={handleClickMax}
        placeholder="0"
        error={errorInputMessage}
        actionButtonText="Max"
      />
      <HfProgressBar isLiquidationFreeAsset={isLiquidationFreeAsset} addBorrow={-repayValue} />

      <InfoPanel infos={infos} />

      {account ? (
        <Button
          skin="primary"
          size="large"
          onClick={() => confirmRepay()}
          block
          disabled={
            processing || !token.borrowedAmount || !borrowAmount || Boolean(errorInputMessage)
          }
        >
          {buttonMessage()}
        </Button>
      ) : (
        <ConnectToWallet block skin="secondary">
          Connect Wallet
        </ConnectToWallet>
      )}
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default memo(RepayPane);
