import { useCallback, useEffect, useState } from 'react';
import { AssetId } from '@polkadot/types/interfaces';
import { ITuple } from '@polkadot/types/types';
import { Liquidity, Shortfall } from '@parallel-finance/types/interfaces';

import { AssetInfo } from '@/hooks/types';
import { useAccount, useApiCall, useChainConnections } from '@/hooks';
import { rateToNumber } from '@/utils/utils';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

export const useLiquidationFreeLimit = () => {
  const [collateralLiquidity, setCollateralLiquidity] = useState<number[]>([]);

  const {
    parachain: { api }
  } = useChainConnections();

  const lfCollateralAssetIds = useApiCall<string[]>(
    api?.query?.loans?.liquidationFreeCollaterals,
    undefined,
    {
      transform: (ids: AssetId[]) => {
        return ids.map(id => id.toString());
      }
    }
  );
  const { assetInfos } = useCurrentAccountAssetInfos();

  const lfCollateralAssets = lfCollateralAssetIds
    ? assetInfos.filter(({ assetId }) => lfCollateralAssetIds?.includes(assetId))
    : [];

  const lfBorrowAssetId = api.consts.loans.liquidationFreeAssetId.toString();

  const lfBorrowAsset = lfBorrowAssetId
    ? assetInfos.find(({ assetId }) => lfBorrowAssetId === assetId)
    : ({} as AssetInfo);

  const currentBlock = useApiCall(api.query.system.number);

  const { account } = useAccount();
  const getCollateralLiquidity = useCallback(async () => {
    const ret = await Promise.resolve<ITuple<[Liquidity, Shortfall, Liquidity, Shortfall]>>(
      api.rpc.loans.getCollateralLiquidity(account?.address)
    );
    setCollateralLiquidity(ret.map(val => rateToNumber(val)));
  }, [account, api.rpc.loans]);

  useEffect(() => {
    getCollateralLiquidity();
  }, [currentBlock, getCollateralLiquidity]);

  const checkIsCollateralLiquidationFree = useCallback(
    (assetId: string) => (lfCollateralAssetIds || []).includes(assetId),
    [lfCollateralAssetIds]
  );

  const checkBorrowLiquidationFree = useCallback(
    (assetId: string) => lfBorrowAssetId === assetId,
    [lfBorrowAssetId]
  );

  const getBorrowLimit = useCallback(
    (isLiquidationFreeAsset: boolean = false) => {
      if (collateralLiquidity.length === 0) return 0;
      const [liquidity, shortfall, lfLiquidityLimit] = collateralLiquidity;
      const nonLfAssetBorrowLimit =
        liquidity > 0 ? liquidity - lfLiquidityLimit : -shortfall - lfLiquidityLimit;

      return isLiquidationFreeAsset ? Math.max(liquidity, lfLiquidityLimit) : nonLfAssetBorrowLimit;
    },
    [collateralLiquidity]
  );

  const getRemainingBorrowLimit = useCallback(
    (assetId: string) => {
      if (collateralLiquidity.length === 0) return 0;
      const [, , lfLiquidityLimit] = collateralLiquidity;

      const isLiquidationFreeAsset = checkBorrowLiquidationFree(assetId);

      return (
        getBorrowLimit(isLiquidationFreeAsset) - (isLiquidationFreeAsset ? lfLiquidityLimit : 0)
      );
    },
    [checkBorrowLiquidationFree, collateralLiquidity, getBorrowLimit]
  );

  return {
    lfCollateralAssetIds,
    lfCollateralAssets,
    lfBorrowAsset,
    lfBorrowAssetId,
    lfLiquidityLimit: collateralLiquidity?.[2] || 0,
    checkBorrowLiquidationFree,
    checkIsCollateralLiquidationFree,
    getBorrowLimit,
    getRemainingBorrowLimit
  };
};
