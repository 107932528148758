import { Stack, Inline, H6, Alert, SmallText, Button } from '@parallel-mono/components';
import styled from 'styled-components';
import { FC, useCallback } from 'react';
import { get, isEqual, isEmpty } from 'lodash';

import parallelWallet from './images/parallel-wallet-bg.png';

import { Wallet, WalletType } from '@/contexts/WalletsContext/types';
import { truncateTextMid } from '@/utils/format';

const InlineBorder = styled(Inline).attrs({
  inset: '0.75rem 1.25rem 0.75rem 0.75rem',
  gap: '0.5rem'
})`
  height: 4.25rem;
  border: 1px solid var(--clr-gray06);
  border-radius: 1rem;
  background-size: cover;
`;
const BlackTextSmallBg = styled(SmallText)`
  padding: 0 0.375rem;
  background: var(--clr-gray05);
  border-radius: 0.25rem;
`;
const ImageWrapper = styled.div`
  width: 2.75rem;
  width: 2.75rem;
  padding: 0.375rem;
  background-color: white;
  border-radius: 50%;
  box-shadow: var(--box-shadow01);
`;
const StackEllipsis = styled(Stack)`
  max-width: 8rem;
  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const AccountName = styled(H6)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const InlineFlexEnd = styled(Inline)`
  justify-self: flex-end;
  margin-left: auto;
`;

type Props = {
  wallet: Wallet;
  activeWallet?: Wallet;
  handleConnect: (type: WalletType) => void;
};

const WalletItem: FC<Props> = ({ wallet, handleConnect, activeWallet }) => {
  const {
    type,
    walletName,
    label,
    selectedAccount,
    enable,
    icon,
    walletInstalled,
    accounts,
    extensionLink
  } = wallet;
  const accountName = get(selectedAccount, 'meta.name');
  const address = get(selectedAccount, 'address');

  const handInstall = useCallback(() => window.open(extensionLink, '_blank'), [extensionLink]);

  const renderWalletActions = useCallback(() => {
    if (walletInstalled) {
      return (
        <Button size="small" skin="secondary" onClick={() => handleConnect(type)}>
          Connect
        </Button>
      );
    }
    if (!walletInstalled && isEmpty(accounts)) {
      return (
        <Button size="small" skin="secondary" onClick={handInstall}>
          Install
        </Button>
      );
    }
    return null;
  }, [accounts, handInstall, handleConnect, type, walletInstalled]);

  return (
    <Stack gap="0.5rem" key={type}>
      <InlineBorder
        style={{
          backgroundImage: type === WalletType.Parallel ? `url(${parallelWallet}` : 'none'
        }}
      >
        <ImageWrapper>{icon}</ImageWrapper>
        <StackEllipsis gap=".25rem">
          <AccountName>{accountName || walletName}</AccountName>
          <Inline gap="0.25rem">
            {enable && address ? (
              <SmallText>{truncateTextMid(address, 4, 4)}</SmallText>
            ) : (
              <BlackTextSmallBg>{label}</BlackTextSmallBg>
            )}
          </Inline>
        </StackEllipsis>
        <InlineFlexEnd gap="1rem" alignItems="center">
          {renderWalletActions()}
        </InlineFlexEnd>
      </InlineBorder>
      {activeWallet?.type === wallet.type &&
        activeWallet.walletInstalled &&
        isEqual(get(activeWallet, 'accounts'), []) && (
          <Alert type="error">
            No wallet account detected on your selected extension. Please make sure you are logged
            in or have an account set up.
          </Alert>
        )}
    </Stack>
  );
};

export default WalletItem;
