import { addressToHex } from '@/utils/utils';

export const createXcm = (address: string) => ({
  V3: {
    parents: 1,
    interior: {
      X1: {
        AccountId32: {
          network: null,
          id: addressToHex(address)
        }
      }
    }
  }
});
