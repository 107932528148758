import { FC, useMemo } from 'react';
import { LineChart, Line, Tooltip, YAxis } from 'recharts';
import styled, { useTheme } from 'styled-components';
import { Stack, Inline, SmallText, H6 } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { upperFirst, range } from 'lodash';

const CustomTooltipWrapper = styled(Stack)`
  background: var(--clr-black);
  border-radius: 0.25rem;
  color: var(--clr-white);
`;

const Dot = styled.div<{ color: string }>`
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background: ${({ color }) => color};
`;

interface Point {
  rate: number;
  utilization: number;
  apy: number;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: {
    color: string;
    value?: number;
    name: string;
    payload: Point;
  }[];
}
const CustomTooltip: FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper inset="0.8rem" gap="0.8rem">
        {payload.map(({ color, name, value, payload: point }) => {
          const isUtilization = name === 'utilization';
          return (
            <Inline justifyContent="space-between" alignItems="center" key={name}>
              <Inline gap="0.3rem" alignItems="center">
                {isUtilization || <Dot color={color} />}
                <SmallText>{upperFirst(name)}</SmallText>
              </Inline>
              <H6 fontWeight="medium">
                {formatNumber((isUtilization ? point.rate : value)!, {
                  output: 'percent'
                })}
              </H6>
            </Inline>
          );
        })}
      </CustomTooltipWrapper>
    );
  }

  return null;
};

interface InterestRateModelChartProps {
  jumpRate: number;
  fullRate: number;
  utilizationRatio: number;
}

export const InterestRateModelChart: FC<InterestRateModelChartProps> = ({
  jumpRate,
  fullRate,
  utilizationRatio
}) => {
  const theme = useTheme();

  const data = useMemo<Point[]>(() => {
    const MIN_RATE = 0.01;
    const ratePoints = range(0, 1 + MIN_RATE, MIN_RATE);
    const apyPoints = [
      ...range(0, jumpRate, jumpRate / (utilizationRatio / MIN_RATE)),
      ...range(jumpRate, fullRate, (fullRate - jumpRate) / ((1 - utilizationRatio) / MIN_RATE)),
      fullRate
    ];

    return ratePoints.map((rate, index) => ({
      rate,
      utilization: -MIN_RATE * 2,
      apy: apyPoints[index]
    }));
  }, [fullRate, jumpRate, utilizationRatio]);

  return (
    <LineChart width={460} height={200} data={data}>
      <Line
        dot={false}
        dataKey="utilization"
        stroke="var(--clr-black)"
        strokeWidth={4}
        activeDot={{ r: 6 }}
      />
      <Line
        dot={false}
        dataKey="apy"
        stroke={theme.skin.primary.main}
        strokeWidth={2}
        activeDot={{ r: 6 }}
      />
      <YAxis hide type="number" domain={['dataMin', 'dataMax']} />
      <Tooltip content={<CustomTooltip />} />
    </LineChart>
  );
};
