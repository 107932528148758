export enum EventModule {
  Assets = 'assets',
  Amm = 'amm',
  Farming = 'farming',
  MoneyMarket = 'loans'
}

export enum AssetsEvents {
  Issued = 'Issued',
  Burned = 'Burned',
  Transferred = 'Transferred'
}

export enum FarmingEvents {
  RewardPaid = 'RewardPaid',
  AssetsDeposited = 'AssetsDeposited',
  AssetsWithdrew = 'AssetsWithdrew'
}

export enum MoneyMarketEvents {
  Deposited = 'Deposited',
  Redeemed = 'Redeemed',
  Borrowed = 'Borrowed',
  RepaidBorrow = 'RepaidBorrow',
  LiquidatedBorrow = 'LiquidatedBorrow',
  DistributedSupplierReward = 'DistributedSupplierReward',
  RewardPaid = 'RewardPaid'
}

export enum AmmEvents {
  Traded = 'Traded',
  LiquidityAdded = 'LiquidityAdded',
  LiquidityRemoved = 'LiquidityRemoved'
}

export enum RouteType {
  Amm = 'Amm',
  Staking = 'Staking'
}
