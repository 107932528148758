import {
  FC,
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import { Stack, Button, Inline } from '@parallel-mono/components';
import { SelectableTokenValue } from '@parallel-mono/business-components';
import { BN } from '@polkadot/util';
import { useDebounce } from 'react-use';

import { TransactionToken, TokenPair } from '../hooks/useTransactionTokens';
import { useSlippage } from '../hooks/useSlippage';
import { DataContext } from '../DataContext';
import { DownSlideAnimation } from '../components/DownSlideAnimation';

import { LiquidityDetail } from './LiquidityDetail';

import { useAccount, useGAEventTracker, useScopeState, useChainConnections } from '@/hooks';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { ConnectToWallet } from '@/components';
import { signAndSend, txFee } from '@/utils/txCall';
import { amountToBalanceByDecimals } from '@/utils/calculations';

interface LiquidityTransactionProps {
  fromTokenValue: SelectableTokenValue<TransactionToken> | undefined;
  toTokenValue: SelectableTokenValue<TransactionToken> | undefined;
  tokenPair: TokenPair | null;
  isInsufficient: boolean;
  trading: boolean;
  setTrading: Dispatch<SetStateAction<boolean>>;
  onTxSuccess?: () => void;
  onTxFailed?: () => void;
}

export const LiquidityTransaction: FC<LiquidityTransactionProps> = ({
  fromTokenValue,
  toTokenValue,
  tokenPair,
  isInsufficient,
  trading,
  setTrading,
  onTxSuccess,
  onTxFailed
}) => {
  const { account } = useAccount();
  const GAEventTrackerWalletConnect = useGAEventTracker('Wallet Connect Button');
  const {
    parachain: { api }
  } = useChainConnections();

  const { TxFeeTips } = useTxFeeValidation();

  const { holder: slippageHolder, slippage, invalidSlippage } = useSlippage();

  const { assetsPriceSource, assetInfoSource, lpAssetsApySource } = useContext(DataContext);
  const { assetInfos } = assetInfoSource;
  const { calculateApyByAssetId } = lpAssetsApySource;

  const nativeAssetInfo = useMemo(() => assetInfos.find(({ isNative }) => isNative), [assetInfos]);

  const [transactionFee, setTransactionFee] = useScopeState<TxFee>();
  const [apy, setApy] = useState<number>();

  const nativeTokenUsdPrice = useMemo(
    () =>
      assetsPriceSource && nativeAssetInfo && assetsPriceSource[nativeAssetInfo.assetId]
        ? assetsPriceSource[nativeAssetInfo.assetId]
        : 0,
    [assetsPriceSource, nativeAssetInfo]
  );

  const hasAmountAndToken = useMemo(
    () =>
      !!(
        fromTokenValue?.amount &&
        fromTokenValue?.token &&
        toTokenValue?.amount &&
        toTokenValue?.token
      ),

    [fromTokenValue?.amount, fromTokenValue?.token, toTokenValue?.amount, toTokenValue?.token]
  );

  const isLiquidityDetailReady = useMemo(
    () => !!(fromTokenValue?.amount && toTokenValue?.amount && tokenPair),
    [fromTokenValue, toTokenValue, tokenPair]
  );

  const addLiquidityTx = useCallback(() => {
    const { amount: fromAmount, token: fromToken } = fromTokenValue!;
    const { amount: toAmount, token: toToken } = toTokenValue!;

    return api.tx.amm.addLiquidity(
      [fromToken.assetId, toToken.assetId],
      [
        amountToBalanceByDecimals<BN>(fromAmount!, fromToken.decimals, 'bn'),
        amountToBalanceByDecimals<BN>(toAmount!, toToken.decimals, 'bn')
      ],
      [
        amountToBalanceByDecimals<BN>(fromAmount! * (1 - slippage / 100), fromToken.decimals, 'bn'),
        amountToBalanceByDecimals<BN>(toAmount! * (1 - slippage / 100), toToken.decimals, 'bn')
      ]
    );
  }, [api.tx.amm, fromTokenValue, slippage, toTokenValue]);

  const executeTrade = useCallback(() => {
    if (account && hasAmountAndToken) {
      try {
        setTrading(true);
        signAndSend({
          api,
          tx: addLiquidityTx(),
          account,
          txSuccessCb: () => {
            setTrading(false);
            onTxSuccess?.();
          },
          txProcessingCb: () => {},
          txFailedCb: err => {
            setTrading(false);
            onTxFailed?.();
            console.error('Error executing trade:', err);
          }
        });
      } catch (err) {
        setTrading(false);
        onTxFailed?.();
        console.error('Error executing trade', err);
      }
    }
  }, [account, hasAmountAndToken, setTrading, api, addLiquidityTx, onTxSuccess, onTxFailed]);

  useDebounce(
    () => {
      const getFee = async () => {
        setTransactionFee(null);
        const fee = await txFee({
          tx: addLiquidityTx(),
          account
        });
        setTransactionFee(fee);
      };
      if (account && hasAmountAndToken) {
        getFee();
      }
    },
    500,
    [account, hasAmountAndToken, txFee]
  );

  useEffect(() => {
    if (fromTokenValue && toTokenValue && tokenPair) {
      setApy(calculateApyByAssetId(tokenPair));
    }
  }, [calculateApyByAssetId, fromTokenValue, toTokenValue, tokenPair]);

  return (
    <Stack>
      <DownSlideAnimation isReady={isLiquidityDetailReady}>
        <LiquidityDetail
          fromTokenValue={fromTokenValue!}
          toTokenValue={toTokenValue!}
          tokenPair={tokenPair!}
          apy={apy}
          slippage={slippage}
        />
      </DownSlideAnimation>

      <Stack gap="0.8rem">
        {account ? (
          <Button
            block
            size="large"
            disabled={
              trading ||
              isInsufficient ||
              invalidSlippage ||
              !fromTokenValue?.amount ||
              !toTokenValue?.amount
            }
            onClick={executeTrade}
          >
            {isInsufficient ? 'Insufficient Balance' : 'Add Liquidity'}
          </Button>
        ) : (
          <ConnectToWallet
            size="large"
            skin="secondary"
            block
            action={() =>
              GAEventTrackerWalletConnect(
                'Connect Wallet In Liquidity Component',
                `Liquidity connectWallet, Add Liquidity Card`
              )
            }
          >
            Connect Wallet
          </ConnectToWallet>
        )}

        <DownSlideAnimation isReady={hasAmountAndToken}>
          <Inline alignItems="center" gap="0" justifyContent="center">
            {slippageHolder}
            <TxFeeTips
              txFee={transactionFee!}
              feeUsdPrice={(transactionFee ?? 0) * nativeTokenUsdPrice}
            />
          </Inline>
        </DownSlideAnimation>
      </Stack>
    </Stack>
  );
};
