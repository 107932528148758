import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';

import { ParaChainId } from '../../constants';
import { USDT_ASSET_ID } from '../../statemint/constants';

import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';

export const createWithDrawXcm = (address: string) => ({
  V1: {
    parents: 1,
    interior: {
      X2: [
        {
          Parachain: ParaChainId.Statemint
        },
        {
          AccountId32: {
            network: 'Any',
            id: addressToHex(address)
          }
        }
      ]
    }
  }
});

const generateToStatemineTxV3 = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number,
  _toChainNativeAsset?: AssetDetailInfo
) => {
  const xcmV3MultiAsset = {
    V3: {
      id: {
        Concrete: {
          parents: 1,
          interior: {
            X3: [
              { Parachain: ParaChainId.Statemint },
              { PalletInstance: 50 },
              { GeneralIndex: USDT_ASSET_ID }
            ]
          }
        }
      },
      fun: { Fungible: amountToBalanceByDecimals<BN>(amount, asset.decimals, 'bn') }
    }
  };

  const xcmV3MultiLocation = {
    V3: {
      parents: 1,
      interior: {
        X2: [
          { Parachain: ParaChainId.Statemint },
          {
            AccountId32: {
              network: null,
              id: addressToHex(account.address)
            }
          }
        ]
      }
    }
  };

  return api.tx.xTokens.transferMultiasset(xcmV3MultiAsset, xcmV3MultiLocation, {
    Unlimited: ''
  });
};

export const generateToStatemintTx = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number,
  _toChainNativeAsset?: AssetDetailInfo
) => {
  return generateToStatemineTxV3(api, account, asset, amount, _toChainNativeAsset);
};
