import { useEffect, useState, FC, useCallback } from 'react';
import { Inline, SmallText, Text, Tooltip } from '@parallel-mono/components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { isNumber, isNull, get } from 'lodash';

import { AssetInfo } from './types';

import {
  MAX_TRANSACTION_FEE,
  MAX_DOT_TRANSACTION_FEE,
  MAX_KUSAMA_TRANSACTION_FEE
} from '@/utils/constants';
import { Link } from '@/components';
import config from '@/config';
import { balanceFormatter } from '@/utils/format';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

export type TxFee = number | null | undefined;

type TxFeeTipsProps = {
  txFee: TxFee;
  toolTip?: string;
  feeUsdPrice?: number;
};

type TxFeeValidation = {
  isSufficientBalance: boolean;
  TxFeeTips: FC<TxFeeTipsProps>;
};

type MaxTransactionFeeMapKeys = 'KSM' | 'DOT' | 'PARA' | 'HKO';

const MAX_TRANSACTION_FEE_DICTIONARY: Record<MaxTransactionFeeMapKeys, number> = {
  KSM: MAX_KUSAMA_TRANSACTION_FEE,
  DOT: MAX_DOT_TRANSACTION_FEE,
  PARA: MAX_TRANSACTION_FEE,
  HKO: MAX_TRANSACTION_FEE
};

const TxFeeDisplay = styled(Inline)`
  width: 100%;
  margin-right: 2rem;
  text-align: center;
`;

export const useTxFeeValidation = (assetInfo?: AssetInfo): TxFeeValidation => {
  const [isSufficientBalance, setIsSufficientBalance] = useState(false);

  const { isReady, nativeAssetInfo } = useCurrentAccountNativeAssetInfo();
  const validationToken = assetInfo || nativeAssetInfo;
  const { availableBalance, existentialDeposit, symbol } = validationToken;

  useEffect(() => {
    setIsSufficientBalance(
      isReady && availableBalance < get(MAX_TRANSACTION_FEE_DICTIONARY, symbol, 0)
    );
  }, [availableBalance, existentialDeposit, isReady, symbol]);

  const TxFeeTips = useCallback(
    ({ txFee, toolTip, feeUsdPrice }: TxFeeTipsProps) => {
      if (!isReady) {
        return <TxFeeDisplay />;
      }
      if (!(isNumber(txFee) || isNull(txFee))) {
        return <TxFeeDisplay />;
      }
      const showFaucetUrl =
        validationToken?.isNative && validationToken.symbol === config.nativeToken;
      return (
        <TxFeeDisplay justifyContent="center" alignItems="center" gap="0.2rem">
          {isSufficientBalance ? (
            <Text skin="error">
              {showFaucetUrl ? (
                <>
                  Get {symbol} from{' '}
                  <Link href={config.faucetUrl} target="_blank">
                    faucet
                  </Link>
                </>
              ) : (
                `You have insufficient ${symbol} to pay the transaction fees.`
              )}
            </Text>
          ) : (
            <>
              <SmallText skin="secondary">
                {`Transaction fee: ${
                  txFee === null || txFee === 0
                    ? 'Loading...'
                    : `${balanceFormatter(txFee!)} ${symbol}`
                }`}
                {feeUsdPrice
                  ? ` (${formatNumber(feeUsdPrice, { output: 'currency', decimal: 4 })})`
                  : ''}
              </SmallText>
              {toolTip && <Tooltip content={toolTip} />}
            </>
          )}
        </TxFeeDisplay>
      );
    },
    [isSufficientBalance, validationToken, symbol, isReady]
  );

  return {
    isSufficientBalance,
    TxFeeTips
  };
};
