import styled from 'styled-components';
import { Icon } from '@parallel-mono/components';

import Menu from '../Header/Menu';

const MenuDropdown = styled(Menu.Dropdown)`
  > div:nth-child(2) {
    top: 2rem;
    z-index: 9997;
  }
`;
const ImageCursor = styled(Icon)`
  cursor: pointer;
`;

interface MenuItem {
  key: string;
  text: string;
  disable?: boolean;
  href?: string;
  target?: string;
  onClick?: () => void;
}

interface IProps {
  menus: MenuItem[];
}

const ExternalLinkStyled = styled.a`
  color: var(--clr-text01);
  white-space: nowrap;
  &.selected,
  &:hover {
    color: var(--clr-blue);
  }
`;

const StyledMenuItem = styled.div<{ disable?: boolean }>`
  padding: 1rem;
  cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
  color: ${({ disable }) => (disable ? 'var(--clr-text03);' : 'var(--clr-text01)')};
`;

// Three dot menu of table row
const TableRowMenu = ({ menus }: IProps) => {
  const menu = menus.map(({ key, text, href, target, ...props }) => {
    return (
      <StyledMenuItem key={key} {...props}>
        {href ? (
          <ExternalLinkStyled href={href} target={target}>
            {text}
          </ExternalLinkStyled>
        ) : (
          text
        )}
      </StyledMenuItem>
    );
  });
  return (
    <MenuDropdown trigger="click" menus={menu}>
      <ImageCursor name="moreVertical" />
    </MenuDropdown>
  );
};

export default TableRowMenu;
