import { useMemo } from 'react';
import { KeyringPair } from '@polkadot/keyring/types';
import { Button } from '@parallel-mono/components';

type ButtonProps = {
  handleTransfer: () => void;
  transferLoading: boolean;
  error: boolean;
  transferAmount: number | null;
  txFee: number;
  account: KeyringPair;
};

export const TransferButton = ({
  handleTransfer,
  transferLoading,
  txFee,
  error,
  transferAmount,
  account
}: ButtonProps) => {
  const isTxFeeReturned = useMemo(() => txFee !== 0, [txFee]);

  const transferButtonDisabled =
    !isTxFeeReturned || Number(transferAmount) === 0 || error || !account || transferLoading;

  return (
    <Button
      size="large"
      onClick={handleTransfer}
      block
      skin="primary"
      disabled={transferButtonDisabled}
    >
      Transfer {transferLoading && 'in Progress'}
    </Button>
  );
};
