import { useCallback } from 'react';

export const useCallbackMerge = <T extends (...args: any) => any>(callback1?: T, callback2?: T) => {
  return useCallback(
    (...args: Parameters<T>) => {
      callback1 && callback1.apply(this, args);
      callback2 && callback2.apply(this, args);
    },
    [callback1, callback2]
  );
};
