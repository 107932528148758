import { memo } from 'react';
import styled from 'styled-components';
import { Inline, Stack, H3, H5, Card, Skeleton, Button, Icon } from '@parallel-mono/components';

import { useDevice } from '@/hooks';

const Wrapper = styled(Card)`
  max-width: 30rem;
  width: 100%;
  margin: 2.5rem auto 0 auto;
`;
const GrayH5 = styled(H5).attrs({
  skin: 'secondary',
  fontWeight: 'medium'
})``;

const SkeletonButton = styled(Skeleton.Button).attrs({
  variant: 'round',
  width: '10rem',
  height: '2.75rem'
})``;

const StyledInline = styled(Inline).attrs({
  justifyContent: 'space-between',
  alignItems: 'flex-end'
})``;

const StyledStack = styled(Stack).attrs({
  gap: '0.5rem'
})``;

const LoadingStatus = () => {
  const { isMobile } = useDevice();
  return (
    <Wrapper>
      <Stack gap="1.75rem">
        <H3>Cross Chain</H3>
        <StyledInline grow>
          <StyledStack>
            <GrayH5>From</GrayH5>
            <SkeletonButton fullWidth={isMobile} />
          </StyledStack>
          <Inline inset="0 0 0.75rem">
            <Icon name="arrowRight" size="medium" />
          </Inline>
          <Stack gap="0.5rem">
            <GrayH5>To</GrayH5>
            <SkeletonButton fullWidth={isMobile} />
          </Stack>
        </StyledInline>
        <StyledInline>
          <StyledStack width="100%">
            <GrayH5>Asset</GrayH5>
            <Inline justifyContent="space-between" alignItems="flex-end">
              <SkeletonButton fullWidth={isMobile} />
              <Skeleton.Button variant="round" width="8rem" height="2rem" />
            </Inline>
          </StyledStack>
        </StyledInline>
        <SkeletonButton fullWidth />
        <Inline inset="3rem 0 0 0">
          <Button skin="primary" size="large" block disabled>
            Connecting...
          </Button>
        </Inline>
      </Stack>
    </Wrapper>
  );
};

export default memo(LoadingStatus);
