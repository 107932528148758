import { css, DefaultTheme, ThemedCssFunction } from 'styled-components';

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

const getObjectKeys = <T>(value: T) => Object.keys(value) as (keyof T)[];

export type MediaWidth = Record<keyof typeof MEDIA_WIDTHS, ThemedCssFunction<DefaultTheme>>;

const mediaWidthTemplates = getObjectKeys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  accumulator[size] = ((...args: Parameters<typeof css>) =>
    css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(...args)}
      }
    `) as ThemedCssFunction<DefaultTheme>;

  return accumulator;
}, {} as MediaWidth);

export default mediaWidthTemplates;
