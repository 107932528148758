import { FC, HTMLAttributes, ReactElement } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #5e6d7c;
`;
const Value = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2f2f2f;
`;
type TextLableProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
  label: string | ReactElement;
  value: string | ReactElement;
};

const TextLabel: FC<TextLableProps> = ({ label, value, ...others }) => {
  return (
    <Wrapper {...others}>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Wrapper>
  );
};

export { TextLabel, Label, Value };
