import { memo, useMemo } from 'react';
import { Button, ButtonProps } from '@parallel-mono/components';

import { DepositModal } from '@/components';
import { Chains, useModal, useConnectChains } from '@/hooks';
import { AssetInfo } from '@/hooks/types';

interface DepositTokenProps extends ButtonProps {
  assetInfo: AssetInfo;
}

const chains = [Chains.ParaChain, Chains.RelayChain];

export const DepositToken = memo<DepositTokenProps>(({ assetInfo, children, ...props }) => {
  const { isChainsReady } = useConnectChains(chains);
  const isRelayChainReady = useMemo(() => isChainsReady(chains), [isChainsReady]);

  const { openModal: openDepositModal, holder: depositModalHolder } = useModal(
    DepositModal,
    {
      title: 'Deposit to Parallel Wallet',
      size: '500px'
    },
    {
      asset: assetInfo
    }
  );

  return (
    <>
      {isRelayChainReady && (
        <Button variant="link" onClick={openDepositModal} {...props}>
          {children}
        </Button>
      )}
      {depositModalHolder}
    </>
  );
});
