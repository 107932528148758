import { CurrencyId } from '@parallel-finance/types/interfaces';

import { AbstractChain } from '../AbstractChain';
import { Chains, TxCallback } from '../types';
import { TxFee } from '../../types';

import { TO_CHAINS, CURRENCIES, MINIMUM_TRANSFER_AMOUNT, USDT_ASSET_ID } from './constants';
import { ChainMetadata, StatemintChainCurrency } from './types';
import { calculateAssetsInfo } from './helpers/calculateAssetsInfo';
import { generateTxs } from './helpers/generateTxs';

import { AssetDetailInfo } from '@/hooks/types';
import { signAndSend, txFee } from '@/utils/txCall';

export class StatemintChain extends AbstractChain<ChainMetadata> {
  static toChains = TO_CHAINS;

  static currencies = CURRENCIES;

  static minimumTransferAmount = MINIMUM_TRANSFER_AMOUNT;

  metadataSubscribe = async () => {
    const { api, account, subscribeValue } = this;
    subscribeValue(api.rpc.system.properties, [], 'chainProperties');
    subscribeValue(account && api.query.system.account, [account?.address], 'accountInfo');

    this.metadata.assetsMetadata = await api.query.assets.metadata.entries();

    const assetIds: CurrencyId[] =
      this.metadata.assetsMetadata
        ?.flatMap((m: any) => m[0])
        .map(({ args: [id] }) => id.toString()) || [];
    subscribeValue(
      api.query.assets.account.multi,
      [assetIds?.map(id => [id, account.address])],
      'assetsBalance'
    );
  };

  calculateAssetsInfo = async () => {
    const { api, metadata } = this;
    const assetsInfo = await calculateAssetsInfo(api, metadata);
    const nativeAssetInfo = assetsInfo?.filter(asset => asset.isNative) || [];

    const filteredAssets = StatemintChain.currencies[Chains.Parallel]
      .map(currency => {
        // there are two asset that symbols are "USDt" and "USDT", and we need the one that assetId is 1984.
        if (currency === StatemintChainCurrency.USDT) {
          const usdtAsset = assetsInfo?.find(asset => Number(asset.assetId) === USDT_ASSET_ID);
          if (usdtAsset) {
            usdtAsset.symbol = StatemintChainCurrency.USDT;
          }
          return usdtAsset;
        }
        return assetsInfo?.find(asset => asset.symbol === currency);
      })
      .filter(asset => asset);
    return [...filteredAssets, ...nativeAssetInfo!];
  };

  getTxFee = async (
    amount: number,
    asset: AssetDetailInfo,
    nativeAsset: AssetDetailInfo
  ): Promise<TxFee> => {
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount);
    const fee = amount ? await txFee({ tx, account }) : 0;

    return { currency: nativeAsset.symbol, fee };
  };

  transfer = (
    amount: number,
    asset: AssetDetailInfo,
    _toChain: Chains,
    options?: TxCallback
  ): void => {
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount);

    signAndSend({ api, tx, account, ...(options || {}) });
  };
}
