import { FC, memo, useMemo, useContext } from 'react';
import { map } from 'lodash';
import { BN } from '@polkadot/util';

import usePendingUnstakeList from './hooks/usePendingUnstakeList';
import { StakingScopeContext } from './Context';

import { balanceFormatter, formatMillisecondsToLeftTime } from '@/utils/format';
import { Collapse, StyledDataGrid, StyledDataGridColumn } from '@/components';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { RELAY_CHAIN_BLOCK_TIME } from '@/utils/constants';

type Row = {
  amount: number;
  time: string;
};

const PendingUnstake: FC<{}> = () => {
  const { currencies } = useContext(StakingScopeContext);
  const { stakingAsset } = currencies;

  const pendingUnstakeList = usePendingUnstakeList();

  const rows = useMemo(() => {
    if (stakingAsset && pendingUnstakeList) {
      const { decimals } = stakingAsset;
      return map(pendingUnstakeList, ([amount, blockNums]) => ({
        amount: balanceToAmountByDecimal<number>(amount, decimals, 'number'),
        time: formatMillisecondsToLeftTime(
          blockNums.mul(new BN(RELAY_CHAIN_BLOCK_TIME * 1000)).toNumber()
        )
      }));
    }
    return [];
  }, [stakingAsset, pendingUnstakeList]);

  const columns = useMemo<StyledDataGridColumn<Row>[]>(
    () => [
      {
        name: 'amount',
        title: 'Amount',
        width: '1fr',
        render: ({ data: { amount } }) =>
          `${balanceFormatter(amount)} ${currencies.stakingAsset?.symbol}`
      },
      {
        name: 'time',
        width: '1fr',
        title: 'Time to Unbond',
        render: ({ data: { time } }) => time
      }
    ],
    [currencies]
  );

  return rows.length > 0 ? (
    <Collapse header={`${rows.length} Pending Unstake`}>
      <StyledDataGrid<Row>
        columns={columns}
        data={rows}
        noDataMessage={<p>There are no pending unstake transactions</p>}
      />
    </Collapse>
  ) : null;
};

export default memo(PendingUnstake);
