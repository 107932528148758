import { FC } from 'react';
import styled from 'styled-components';
import { Inline, H5, Icon } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import cx from 'classnames';

import config, { Chain } from '@/config';
import { NavigationBar } from '@/components';
import { useDevice, useModal } from '@/hooks';

const Wrapper = styled(Inline).attrs({
  alignItems: 'center'
})`
  padding-left: 0.5rem;
  ${({ theme }) => theme.breakpoints.down('md')`
    padding-left: 0.25rem;
  `};
  cursor: pointer;
`;

const RoundIcon = styled(Icon)`
  border-radius: 50%;
`;

const chainIconMapping: Record<string, string> = {
  [Chain.HEIKO]: 'KSM',
  [Chain.PARALLEL]: 'DOT'
};

interface SwitchSlotsProps {}

const NavigationWrapper = styled.div`
  width: 50rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
    width: 100%;
  `};
`;

const NavigationModal = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <NavigationWrapper>
      <NavigationBar callback={closeModal} />
    </NavigationWrapper>
  );
};

const ChainMenus: FC<SwitchSlotsProps> = () => {
  const { isMobile, isDesktop } = useDevice();
  const { openModal, holder } = useModal(NavigationModal, {
    size: isDesktop ? 'max-content' : '500px',
    closable: false,
    classNames: {
      modal: cx('top'),
      header: cx('compact')
    }
  });

  return (
    <>
      <Wrapper gap={isMobile ? '0.25rem' : '0.5rem'} onClick={openModal}>
        {isMobile ? <RoundIcon name="apps" width="1.5rem" /> : <H5>Apps</H5>}
        <CryptoAsset symbol={chainIconMapping[config.chain]} symbolSize="small" />
      </Wrapper>
      {holder}
    </>
  );
};

export default ChainMenus;
