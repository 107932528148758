import { memo, forwardRef, HTMLAttributes, useCallback, ReactNode } from 'react';
import styled from 'styled-components';
import { useDualModeState } from '../../hooks/useDualModeState';
import { Skin } from '../ThemeProvider';

export type PaginationProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & {
  total: number;
  page?: number;
  defaultPage?: number;
  onChange?: (page: number) => void;
  skin?: Skin;
  renderPaginationItems?: (args: {
    page: number;
    total: number;
    goto: (page: number) => void;
  }) => ReactNode;
  disabled?: boolean;
};

const Container = styled.div<{ disabled: boolean; skin?: Skin }>`
  display: flex;
  align-items: center;
  user-select: none;
  opacity: ${({ theme, disabled }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  justify-content: center;
  color: ${({ theme, skin = 'primary' }) => theme.skin[skin].main};
`;

const PageControlSvg = styled.svg<{ skin?: Skin; disabled: boolean }>`
  width: 1em;
  height: 1em;
  stroke: ${({ theme, skin = 'primary' }) => theme.skin[skin].main};
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  margin: 14px;
  cursor: pointer;
  opacity: ${({ theme, disabled }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
`;

const Label = styled.span``;

const defaultRenderLabel = ({ page, total }) => (
  <Label>
    {page} / {total}
  </Label>
);

export const Pagination = memo(
  forwardRef<HTMLDivElement, PaginationProps>((props, ref) => {
    const {
      total,
      page,
      defaultPage,
      renderPaginationItems = defaultRenderLabel,
      onChange,
      skin,
      disabled = false,
      ...others
    } = props;
    const [finalPage, setInternalPage] = useDualModeState(defaultPage ?? 1, page);

    const disablePrev = finalPage <= 1;
    const handlePrev = useCallback(() => {
      if (disablePrev || disabled) return;
      setInternalPage(finalPage - 1);
      onChange?.(finalPage - 1);
    }, [finalPage, onChange, setInternalPage, disablePrev, disabled]);

    const disableNext = finalPage >= total;
    const handleNext = useCallback(() => {
      if (disableNext || disabled) return;
      setInternalPage(finalPage + 1);
      onChange?.(finalPage + 1);
    }, [finalPage, onChange, setInternalPage, disableNext, disabled]);

    const goto = useCallback(
      (target: number) => {
        setInternalPage(target);
        onChange?.(target);
      },
      [setInternalPage, onChange]
    );

    return (
      <Container ref={ref} disabled={disabled} skin={skin} {...others}>
        <PageControlSvg
          data-control-type="prev_page"
          onClick={handlePrev}
          viewBox="0 0 8 14"
          fill="none"
          skin={skin}
          disabled={disablePrev}
        >
          <path d="M7 1L1 7L7 13" />
        </PageControlSvg>
        {renderPaginationItems({ page: finalPage, total, goto })}
        <PageControlSvg
          data-control-type="next_page"
          onClick={handleNext}
          viewBox="0 0 8 14"
          fill="none"
          skin={skin}
          disabled={disableNext}
        >
          <path d="M1 13L7 7L1 1" />
        </PageControlSvg>
      </Container>
    );
  })
);
