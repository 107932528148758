import { isNaN } from 'lodash';

import { NonEmptyArray } from '@/typings/basic';

// Check whether value can be converted to a usable number
export const isNumeric = (obj: any) => {
  return !Number.isNaN(parseFloat(obj)) && Number.isFinite(Number(obj));
};

export const isNonEmptyArray = <T>(arr: T[]): arr is NonEmptyArray<T> => {
  return arr.length > 0;
};

export const isValidAmountInput = (
  amount: string,
  integerLength = 10,
  decimalLength = 4
): boolean => {
  const validNumberReg = new RegExp(
    `^(0|[1-9]\\d{0,${integerLength - 1}})(\\.\\d{0,${decimalLength}})?$`
  );
  if (!validNumberReg.test(amount)) {
    return false;
  }
  const AmountNumber = parseFloat(amount);
  const isValidAmount = !isNaN(amount) && AmountNumber >= 0;

  return isValidAmount;
};
