import { Card, Stack } from '@parallel-mono/components';
import { memo } from 'react';

// import Banner from '../OneClickBanner/Banner';

import LoadingParallelAssetsTable from './LoadingParallelAssetsTable';
import LoadingRelayChainAssetsTable from './LoadingRelayChainAssetsTable';

const ContentsLoading = () => {
  return (
    <Stack>
      {/* <Banner isLoadingAPY /> */}
      <Card>
        <LoadingRelayChainAssetsTable />
      </Card>
      <Card>
        <LoadingParallelAssetsTable />
      </Card>
    </Stack>
  );
};

export default memo(ContentsLoading);
