import { isValidElement, memo, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { find, isEmpty, isNil } from 'lodash';
import {
  Inline,
  Stack,
  H3,
  H5,
  Text,
  Card,
  Select,
  SelectBaseOption,
  Icon,
  Skeleton,
  Alert
} from '@parallel-mono/components';
import { CryptoAsset, TokenInput, InfoPanel } from '@parallel-mono/business-components';
import { useDebounce } from 'react-use';
import BigNumber from 'bignumber.js';

import { Currency, TxFee } from './types';
import { CHAIN_DETAILS, CURRENCY_DETAILS } from './constants';
import { useCrossChain } from './hooks/useCrossChain';
import { Chains } from './chains/types';
import { TransferButton } from './components/TransferButton';
import { ALL_CHAINS } from './chains/constants';
import ChainLoading from './components/ChainLoading';

import { useDevice, useAccount, useTxFeeValidation } from '@/hooks';
import { toFloorFixed, balanceFormatter } from '@/utils/format';
import { AssetDetailInfo, AssetInfo } from '@/hooks/types';

const OuterWrapper = styled(Stack)`
  max-width: 30rem;
  width: 100%;
  margin: 2.5rem auto 0 auto;
`;
const FormWrapper = styled(Card)`
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: 1.25rem;
  `};
`;
const DropdownLabelStack = styled(Stack).attrs({
  gap: '0.5rem'
})``;
const DropdownLabel = styled(H5)`
  font-weight: 500;
  color: var(--clr-gray01);
`;

const InputOutWrapper = styled(Stack)`
  & input {
    padding: 0.5rem 0;
  }
`;

const StyledSelect = styled(Select)`
  border-radius: 6.25rem;
  height: 2.75rem;
  padding: 0.25rem;
`;

export interface SelectOption extends SelectBaseOption {
  icon?: ReactNode;
}

const renderDropdownItem = (option: (SelectOption & { showPadding?: boolean }) | null) => {
  if (option) {
    const { label, icon, showPadding } = option;
    return (
      <Inline gap="0.5rem" alignItems="center" inset={showPadding ? '0.75rem 1.5rem' : '0'}>
        {isValidElement(icon) ? icon : <CryptoAsset symbol={icon as string} symbolSize="small" />}
        <Text>{label}</Text>
      </Inline>
    );
  }
  return null;
};

const currencyToOption = (currency: Currency): SelectOption => {
  const { name, icon } = CURRENCY_DETAILS[currency];
  return { label: name, value: currency, icon };
};

const generateCurrenciesOptions = (currencies: Currency[]): SelectOption[] =>
  currencies.map(currency => currencyToOption(currency));

const chainToOption = (chain: Chains): SelectOption => {
  const { name, icon, key } = CHAIN_DETAILS[chain];
  return { value: key, label: name, icon };
};

const generateChainOptions = (allChains: Chains[], selectedChain?: Chains): SelectOption[] =>
  allChains.filter(chain => chain !== selectedChain).map(chain => chainToOption(chain));

const CrossChainPage = () => {
  const [txFee, setTxFee] = useState<TxFee>();
  const [transferAmount, setTransferAmount] = useState<number | null>(null);
  const [fromChain, setFromChain] = useState(ALL_CHAINS[0]);
  const [transferLoading, setTransferLoading] = useState(false);
  const [extraDisplayInfos, setExtraDisplayInfos] = useState<Record<string, any>[]>();
  const [toChain, setToChain] = useState<Chains>();
  const [currentChainCurrencies, setCurrentChainCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(null);

  const [errorMessage, setErrorMessage] = useState<ReactNode>(null);
  const [warningMessage, setWarningMessage] = useState<ReactNode>(null);

  const { isMobile } = useDevice();
  const {
    chain: {
      transfer,
      getTxFee,
      getExtraInfos,
      validate,
      validateWarning,
      assetsInfo: fromChainAssetsInfo
    },
    toChains,
    toChainAssetsInfo,
    chainLoading,
    currencies,
    minimumTransferAmount: minimumTransferCurrenciesAmount
  } = useCrossChain(fromChain, toChain);

  useEffect(() => {
    setToChain(toChains[0]);
  }, [toChains]);

  useEffect(() => {
    setTransferAmount(null);
    setTxFee({ fee: 0 } as TxFee);
  }, [fromChain, toChain]);

  useEffect(() => {
    const chainCurrencies = toChain ? currencies?.[toChain] : undefined;
    if (chainCurrencies) {
      setCurrentChainCurrencies(chainCurrencies);
      setSelectedCurrency(chainCurrencies[0]);
    }
  }, [currencies, toChain]);

  useEffect(() => {
    if (getExtraInfos && toChain) {
      const extraInfos = getExtraInfos(toChain);
      setExtraDisplayInfos(extraInfos);
    } else {
      setExtraDisplayInfos([]);
    }
  }, [fromChain, getExtraInfos, toChain, toChainAssetsInfo]);

  const { account } = useAccount();

  const selectedAsset = useMemo(
    () => find(fromChainAssetsInfo, (asset: AssetDetailInfo) => asset.symbol === selectedCurrency),
    [fromChainAssetsInfo, selectedCurrency]
  );
  const fromChainNativeAsset = useMemo(
    () => find(fromChainAssetsInfo, (asset: AssetDetailInfo) => asset.isNative),
    [fromChainAssetsInfo]
  );

  const { TxFeeTips } = useTxFeeValidation({
    ...fromChainNativeAsset,
    balance: fromChainNativeAsset?.balance.toNumber(),
    lockedBalance: fromChainNativeAsset?.lockedBalance.toNumber(),
    availableBalance: fromChainNativeAsset?.availableBalance.toNumber(),
    existentialDeposit: fromChainNativeAsset?.existentialDeposit.toNumber()
  } as unknown as AssetInfo);

  const minimumTransferAmount = useMemo(
    () =>
      minimumTransferCurrenciesAmount && selectedCurrency
        ? minimumTransferCurrenciesAmount[selectedCurrency]
        : 0,
    [minimumTransferCurrenciesAmount, selectedCurrency]
  );
  const handleGetTxFee = useCallback(async () => {
    if (selectedAsset && transferAmount && fromChainNativeAsset && toChain) {
      const txFeeInfo = await getTxFee(
        transferAmount,
        selectedAsset,
        fromChainNativeAsset,
        toChain,
        { toChainAssetsInfo }
      );
      setTxFee(txFeeInfo as TxFee);
    }
  }, [selectedAsset, transferAmount, fromChainNativeAsset, toChain, getTxFee, toChainAssetsInfo]);

  useDebounce(handleGetTxFee, 300, [transferAmount, handleGetTxFee]);

  const handleFromChainChange = useCallback((option: SelectOption | null) => {
    if (option) {
      setFromChain(option.value as Chains);
      setSelectedCurrency(null);
    }
  }, []);

  const handleToChainChange = useCallback((option: SelectOption | null) => {
    if (option) {
      setToChain(option.value as Chains);
    }
  }, []);

  const handleTransferInputChange = useCallback((value: number | null) => {
    setTransferAmount(value);
  }, []);

  const handleTransfer = useCallback(() => {
    if (selectedAsset && transferAmount && toChain) {
      try {
        setTransferLoading(true);
        transfer(
          transferAmount,
          selectedAsset,
          toChain,
          {
            txSuccessCb: () => {
              setTransferAmount(null);
              setTransferLoading(false);
            },
            txFailedCb: () => {
              setTransferLoading(false);
            }
          },
          { toChainAssetsInfo }
        );
      } catch (e) {
        setTransferLoading(false);
        throw e;
      }
    }
  }, [selectedAsset, toChain, toChainAssetsInfo, transfer, transferAmount]);

  const handleCurrencyChange = useCallback((option: SelectBaseOption | null) => {
    if (option) {
      setSelectedCurrency(option.value as Currency);
    }
  }, []);

  const maximumTransferAmount = useMemo(() => {
    return (
      selectedAsset?.maxAvailableBalance
        ?.decimalPlaces(selectedAsset?.decimals?.toNumber(), BigNumber.ROUND_DOWN)
        ?.toNumber() || 0
    );
  }, [selectedAsset]);

  const handleClickMax = useCallback(() => {
    setTransferAmount(maximumTransferAmount);
  }, [maximumTransferAmount]);

  const balance = useMemo(() => selectedAsset?.availableBalance.toNumber(), [selectedAsset]);

  const fromChainDetail = useMemo(() => chainToOption(fromChain), [fromChain]);

  const toChainDetail = useMemo(() => toChain && chainToOption(toChain), [toChain]);

  const inputValue = useMemo(
    () => transferAmount && toFloorFixed(transferAmount, 4),
    [transferAmount]
  );

  const displayInfos = useMemo(() => {
    if (isEmpty(extraDisplayInfos)) return [];

    return extraDisplayInfos!.map(({ label, value, tooltip }) => ({
      title: label,
      tip: tooltip,
      value
    }));
  }, [extraDisplayInfos]);

  useEffect(() => {
    setErrorMessage(null);
    setWarningMessage(null);
  }, [fromChain, toChain]);

  useEffect(() => {
    if (
      selectedCurrency &&
      !isNil(transferAmount) &&
      toChain &&
      !isEmpty(fromChainAssetsInfo) &&
      !isEmpty(toChainAssetsInfo)
    ) {
      const validateParams = {
        fromChain: { name: fromChainDetail.value, label: fromChainDetail.label as string },
        toChain: { name: toChainDetail!.value, label: toChainDetail!.label as string },
        transferAmount,
        currency: selectedCurrency,
        maximumTransferAmount,
        minimumTransferAmount,
        fromChainAssets: fromChainAssetsInfo,
        toChainAssets: toChainAssetsInfo
      };
      setErrorMessage(validate(validateParams));
      setWarningMessage(validateWarning(validateParams));
    }
  }, [
    fromChainAssetsInfo,
    fromChainDetail.label,
    fromChainDetail.value,
    maximumTransferAmount,
    minimumTransferAmount,
    selectedCurrency,
    toChain,
    toChainAssetsInfo,
    toChainDetail,
    transferAmount,
    validate,
    validateWarning
  ]);

  const balanceText = useMemo(() => {
    if (isEmpty(fromChainAssetsInfo) && !chainLoading) {
      return <Skeleton.Button variant="round" width="8rem" height="2rem" />;
    }
    if (!isNil(balance)) {
      return (
        <>
          <Text>Balance: </Text>
          <Text fontWeight="bold">
            {balanceFormatter(balance)} {!isMobile && selectedCurrency}
          </Text>
        </>
      );
    }
    return null;
  }, [balance, chainLoading, fromChainAssetsInfo, isMobile, selectedCurrency]);

  return (
    <OuterWrapper>
      <FormWrapper>
        <Stack gap="1rem">
          <Stack gap="1.5rem">
            <Inline justifyContent="space-between">
              <H3>Cross Chain</H3>{' '}
            </Inline>
            <Inline
              gap={isMobile ? '0.5rem' : '1.5rem'}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <DropdownLabelStack>
                <DropdownLabel>From</DropdownLabel>
                <StyledSelect
                  options={generateChainOptions(ALL_CHAINS)}
                  value={chainToOption(fromChain).value}
                  onChange={handleFromChainChange}
                  renderItem={option => renderDropdownItem({ ...option, showPadding: true })}
                  renderDisplay={renderDropdownItem}
                />
              </DropdownLabelStack>

              <Inline justifyContent="center" inset="0 0 0.75rem 0">
                <Icon name="arrowRight" />
              </Inline>

              <DropdownLabelStack>
                <DropdownLabel>To</DropdownLabel>
                <StyledSelect
                  options={generateChainOptions(toChains)}
                  value={toChain ? chainToOption(toChain).value : null}
                  onChange={handleToChainChange}
                  renderItem={option => renderDropdownItem({ ...option, showPadding: true })}
                  renderDisplay={renderDropdownItem}
                />
              </DropdownLabelStack>
            </Inline>
            {chainLoading ? (
              <ChainLoading />
            ) : (
              <Stack gap="1rem" inset="0.5rem 0 0 0">
                <Inline justifyContent="space-between">
                  <DropdownLabelStack>
                    <DropdownLabel>Asset</DropdownLabel>
                    <StyledSelect
                      onChange={handleCurrencyChange}
                      options={generateCurrenciesOptions(
                        currentChainCurrencies as unknown as Currency[]
                      )}
                      value={selectedCurrency && currencyToOption(selectedCurrency).value}
                      renderItem={option => renderDropdownItem({ ...option, showPadding: true })}
                      renderDisplay={renderDropdownItem}
                    />
                  </DropdownLabelStack>
                  <Inline alignItems="flex-end" gap="0.25rem">
                    {balanceText}
                  </Inline>
                </Inline>
                <InputOutWrapper gap="0.5rem">
                  <TokenInput
                    placeholder="Enter an amount…"
                    value={inputValue}
                    onChange={handleTransferInputChange}
                    error={errorMessage}
                    actionButtonText="Max"
                    onAction={handleClickMax}
                  />
                </InputOutWrapper>
                {!errorMessage && warningMessage && <Alert type="warning">{warningMessage}</Alert>}
                {displayInfos.length > 0 ? <InfoPanel infos={displayInfos} /> : null}
                <Inline inset="0.5rem 0 0 0">
                  <TransferButton
                    handleTransfer={handleTransfer}
                    transferLoading={transferLoading}
                    txFee={txFee?.fee || 0}
                    error={Boolean(errorMessage)}
                    transferAmount={transferAmount}
                    account={account}
                  />
                </Inline>
                <Inline justifyContent="center">
                  {Boolean(transferAmount) && !errorMessage && (
                    <TxFeeTips
                      txFee={txFee?.fee}
                      toolTip={`This is the est. transaction fee and the max that you will be charged, when you transfer assets from ${fromChainDetail.label} to ${toChainDetail?.label}.`}
                    />
                  )}
                </Inline>
              </Stack>
            )}
          </Stack>
        </Stack>
      </FormWrapper>
    </OuterWrapper>
  );
};

export default memo(CrossChainPage);
