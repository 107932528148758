import { FC, memo, useMemo } from 'react';
import { useSpring, animated } from 'react-spring';
import { useLocation, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Inline } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';

import AccountArea from './Account';
import TVLBanner from './TVLBanner';
import bgImage from './images/nav-bg.svg';

import { ChainMenus } from '@/components';
import { useDevice } from '@/hooks';
import { useSearchParamsNavigate } from '@/hooks/useSearchParamsNavigate';
import config from '@/config';

const Container = styled.div`
  position: sticky;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 99;
  backdrop-filter: blur(6px);
  padding: 1rem 1.5rem;

  ${({ theme }) => theme.breakpoints.down('lg')`
    box-shadow: none;
  `};
`;
const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  background-image: url(${bgImage});
  background-size: cover;
  z-index: -1;
`;

const Content = styled(Inline).attrs({
  justifyContent: 'space-between'
})`
  margin: 0px auto;
  max-width: 1280px;
  ${({ theme }) => theme.breakpoints.down('lg')`
    & > * + * {
      margin-left: 0;
    }
  `};
`;

const CustomWrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border: 2px solid var(--clr-white);
  border-radius: 100px;
  padding: 0.25rem;
  place-self: end;
  ${({ theme }) => theme.breakpoints.down('lg')`
    grid-column: 1 / span 2;
    place-self: stretch;
  `};
`;

type Props = {
  bgOpacity?: number;
};

const Header: FC<Props> = ({ bgOpacity = 1 }) => {
  const { isDesktop } = useDevice();
  const location = useLocation();

  const { getNavigateInfo } = useSearchParamsNavigate();

  const animation = useSpring({
    opacity: bgOpacity
  });

  const isHomePage = useMemo(
    () => location.pathname === '/' || location.pathname === '/overview',
    [location.pathname]
  );

  return (
    <Container>
      <BackgroundWrapper as={animated.div} style={animation} />
      <Content>
        <NavLink to={getNavigateInfo('/overview')}>
          <HostedImage name={`design/PDS_V3/logo/${config.logo}`} height="40px" />
        </NavLink>
        <Inline alignItems="center" justifyContent="flex-end" gap="0.75rem">
          {isDesktop ? (
            <>
              <CustomWrapper>
                <TVLBanner transparent size="small" width="14rem" />
              </CustomWrapper>
              {!isHomePage && (
                <CustomWrapper>
                  <ChainMenus />
                </CustomWrapper>
              )}
              <AccountArea />
            </>
          ) : (
            <>
              {!isHomePage && (
                <CustomWrapper>
                  <ChainMenus />
                </CustomWrapper>
              )}
            </>
          )}
        </Inline>
      </Content>
      {!isDesktop && (
        <CustomWrapper style={{ marginTop: '1rem' }}>
          <TVLBanner transparent size="small" width="100%" />
        </CustomWrapper>
      )}
    </Container>
  );
};

export default memo(Header);
