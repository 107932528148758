import { OracleValue, Rate, Ratio } from '@parallel-finance/types/interfaces';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';
import { BN, BN_SIX, hexToU8a, isHex, stringToU8a, u8aConcat, u8aToHex } from '@polkadot/util';

import { balanceToAmountByDecimal } from './calculations';

const EMPTY_U8A_32 = new Uint8Array(32);
const BN_EIGHTEEN = new BN(18);

const createAddress = (id: string) =>
  encodeAddress(u8aConcat(stringToU8a(`modl${id}`), EMPTY_U8A_32).subarray(0, 32));

const addressToHex = (address: string) => u8aToHex(decodeAddress(address));

export const isValidAddress = (address: string) => {
  try {
    encodeAddress(isHex(address) ? hexToU8a(address) : decodeAddress(address));
    return true;
  } catch (error) {
    return false;
  }
};

const rateToNumber = (value: Rate | BN): number =>
  balanceToAmountByDecimal<number>(value, BN_EIGHTEEN, 'number');
const ratioToNumber = (value: Ratio | BN): number =>
  balanceToAmountByDecimal<number>(value, BN_SIX, 'number');
const oracleToNumber = (value: OracleValue): number =>
  balanceToAmountByDecimal<number>(value, BN_EIGHTEEN, 'number');

const toSubstrateAddress = (account: string): string => {
  return encodeAddress(decodeAddress(account, false), 42);
};

export {
  createAddress,
  addressToHex,
  rateToNumber,
  ratioToNumber,
  oracleToNumber,
  toSubstrateAddress
};
