export enum DateUnits {
  Daily = 'D',
  Weekly = 'W',
  Monthly = 'M'
}

export type TokenPrice = { asset: number; price: string };

export type TokenPricesData = {
  blockHash: string;
  blockHeight: string;
  blockTimestamp: string;
  data: TokenPrice[];
};
export enum TransactionType {
  Swap = 'swap',
  Liquidity = 'liquidity'
}

export type TransactionData = {
  trader: string;
  assetIdIn: number;
  assetIdOut: number;
  amountIn: string;
  amountOut: string;
  lpTokenId: number;
  newQuoteAmount: string;
  newBaseAmount: string;
};

export type TransactionHistory = {
  id: string;
  idx: string;
  blockHeight: number;
  blockHash: string;
  extrinsicHash: string;
  data: TransactionData;
  timestamp: string;
};
