import { createGlobalStyle } from 'styled-components';

export const CustomToastStyles = createGlobalStyle`
  :root {
    --toastify-color-error: var(--clr-red);
    --toastify-color-success: var(--clr-green);
    --toastify-font-family: var(--font-family);
    --toastify-z-index: 10001;
  }

  .Toastify__toast-icon  {
    /* width: auto; */
  }

  .Toastify__toast-container {
    margin-top: 3rem;
  }
`;
