import { ReactComponent as accountBg } from './account-bg.svg';
import { ReactComponent as accountIcon0 } from './account-icon0.svg';
import { ReactComponent as accountIcon1 } from './account-icon1.svg';
import { ReactComponent as accountIcon2 } from './account-icon2.svg';
import { ReactComponent as accountIcon3 } from './account-icon3.svg';
import { ReactComponent as accountIcon4 } from './account-icon4.svg';
import { ReactComponent as angelRight } from './angel-right.svg';
import { ReactComponent as alertWarning } from './alert-warning.svg';
import { ReactComponent as alertWarningThinRed } from './alert-warning-thin-red.svg';
import { ReactComponent as alertWarningThinBlack } from './alert-warning-thin-black.svg';
import { ReactComponent as alertInfo } from './alert-info.svg';
import { ReactComponent as ammBg } from './amm-bg.svg';
import { ReactComponent as apps } from './apps.svg';
import { ReactComponent as auctionHeaderBg } from './auction-header-bg.svg';
import { ReactComponent as arrowLeft } from './arrow-left.svg';
import { ReactComponent as arrowRight } from './arrow-right.svg';
import { ReactComponent as arrowDown } from './arrow-down.svg';
import { ReactComponent as auctionBanner } from './auction-banner.svg';
import { ReactComponent as award } from './award.svg';
import { ReactComponent as blackWarning } from './blackWarning.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as closetip } from './close-tip.svg';
import { ReactComponent as check } from './check.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as checkMark } from './check-mark.svg';
import { ReactComponent as cog } from './cog.svg';
import { ReactComponent as connectWallet } from './connect-wallet.svg';
import { ReactComponent as crossChainKsmBanner } from './cross-chain-ksm-banner.svg';
import { ReactComponent as crossChainDotBanner } from './cross-chain-dot-banner.svg';
import { ReactComponent as crowdloanBanner } from './crowdloan-banner.svg';
import { ReactComponent as depositDotToParallelWallet } from './deposit-dot-to-parallel-wallet.svg';
import { ReactComponent as depositKsmToParallelWallet } from './deposit-ksm-to-parallel-wallet.svg';
import { ReactComponent as doneTip } from './done-tip.svg';
import { ReactComponent as depositToParallelWallet } from './deposit-to-parallel-wallet.svg';
import { ReactComponent as dropdown } from './dropdown.svg';
import { ReactComponent as dropdownArrow } from './dropdown-arrow.svg';
import { ReactComponent as down } from './down.svg';
import { ReactComponent as earn } from './earn.svg';
import { ReactComponent as empty } from './empty.svg';
import { ReactComponent as externalLink } from './external-link.svg';
import { ReactComponent as fallback } from './fallback.svg';
import { ReactComponent as gradientbg } from './gradient-bg.svg';
import { ReactComponent as heart } from './heart.svg';
import { ReactComponent as heart1 } from './heart-1.svg';
import { ReactComponent as heikoLogo } from './heiko-logo.svg';
import { ReactComponent as hint } from './hint.svg';
import { ReactComponent as logo } from './logo.svg';
import { ReactComponent as expand } from './expand.svg';
import { ReactComponent as metamask } from './meta-mask.svg';
import { ReactComponent as migrateDollar } from './migrate-dollar.svg';
import { ReactComponent as moneyverse } from './moneyverse.svg';
import { ReactComponent as moonbeamSign } from './moonbeam-sign.svg';
import { ReactComponent as moreVertical } from './more-vertical.svg';
import { ReactComponent as parallel } from './parallel.svg';
import { ReactComponent as paraLogo } from './para-logo.svg';
import { ReactComponent as omniLogo } from './omni-logo.svg';
import { ReactComponent as penSign } from './pen-sign.svg';
import { ReactComponent as plus } from './plus.svg';
import { ReactComponent as rewardRule } from './reward-rule.svg';
import { ReactComponent as right } from './right.svg';
import { ReactComponent as referral } from './referral.svg';
import { ReactComponent as security } from './security.svg';
import { ReactComponent as share } from './share.svg';
import { ReactComponent as shield } from './shield.svg';
import { ReactComponent as spinner } from './spinner.svg';
import { ReactComponent as stack } from './stack.svg';
import { ReactComponent as streamInfo } from './stream-info.svg';
import { ReactComponent as transfer } from './transfer.svg';
import { ReactComponent as trendingUp } from './trending-up.svg';
import { ReactComponent as tooltip } from './tooltip.svg';
import { ReactComponent as up } from './up.svg';
import { ReactComponent as wallet } from './wallet.svg';
import { ReactComponent as warning } from './warning.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as kusama } from './kusama.svg';
import { ReactComponent as polkadot } from './polkadot.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as filter } from './filter.svg';

export const regularIcons = {
  accountBg: accountBg,
  accountIcon0: accountIcon0,
  accountIcon1: accountIcon1,
  accountIcon2: accountIcon2,
  accountIcon3: accountIcon3,
  accountIcon4: accountIcon4,
  angelRight: angelRight,
  alertWarning: alertWarning,
  alertWarningThinRed: alertWarningThinRed,
  alertWarningThinBlack: alertWarningThinBlack,
  alertInfo: alertInfo,
  ammBg: ammBg,
  apps: apps,
  auctionBanner: auctionBanner,
  auctionHeaderBg: auctionHeaderBg,
  arrowLeft: arrowLeft,
  arrowRight: arrowRight,
  arrowDown: arrowDown,
  award: award,
  blackWarning: blackWarning,
  calendar: calendar,
  check: check,
  checkMark: checkMark,
  clock: clock,
  close: close,
  closetip: closetip,
  cog: cog,
  connectWallet: connectWallet,
  copy,
  expand,
  crossChainDotBanner: crossChainDotBanner,
  crossChainKsmBanner: crossChainKsmBanner,
  crowdloanBanner: crowdloanBanner,
  down: down,
  depositToParallelWallet: depositToParallelWallet,
  depositDotToParallelWallet: depositDotToParallelWallet,
  depositKsmToParallelWallet: depositKsmToParallelWallet,
  doneTip: doneTip,
  dropdown: dropdown,
  dropdownArrow: dropdownArrow,
  earn: earn,
  empty: empty,
  externalLink: externalLink,
  fallback: fallback,
  gradientbg: gradientbg,
  heart: heart,
  heart1: heart1,
  heikoLogo: heikoLogo,
  hint: hint,
  logo: logo,
  metamask: metamask,
  migrateDollar: migrateDollar,
  moneyverse: moneyverse,
  moonbeamSign: moonbeamSign,
  moreVertical: moreVertical,
  parallel: parallel,
  paraLogo: paraLogo,
  omniLogo: omniLogo,
  penSign: penSign,
  plus: plus,
  referral: referral,
  right: right,
  rewardRule: rewardRule,
  security: security,
  share: share,
  shield: shield,
  spinner: spinner,
  stack: stack,
  streamInfo: streamInfo,
  tooltip: tooltip,
  transfer: transfer,
  trendingUp: trendingUp,
  up: up,
  wallet: wallet,
  warning: warning,
  polkadot,
  kusama,
  search,
  filter,
};
