import { memo, forwardRef } from 'react';
import styled from 'styled-components';

import { CheckboxBase, CheckboxProps, SIZE_TO_WIDTH_MAP } from './CheckboxBase';

const StyledSpan = styled.span<CheckboxProps>`
  user-select: none;
  padding: 0 ${({ theme }) => theme.spacing(1)};
`;
const StyledLabel = styled.label<CheckboxProps>`
  display: inline-flex;
  align-items: center;
  &:hover {
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
    > label {
        border: ${({ theme, skin = 'primary', disabled }) =>
          disabled ? `2px solid ${theme.skin.grey?.[300]}` : `2px solid ${theme.skin[skin]?.main}`}}
      }
    }
  }
`;
const StyledCheckboxBase = styled(CheckboxBase)<Pick<CheckboxProps, 'size'>>`
  flex: ${({ size = 'medium' }) => `0 0 ${SIZE_TO_WIDTH_MAP[size] ?? size}`};
`;

export const CheckboxWithLabel = memo(
  forwardRef<HTMLLabelElement, CheckboxProps>(
    (
      {
        children,
        value,
        checked,
        checkedIcon,
        disabled,
        onChange,
        skin = 'primary',
        size = 'medium',
        inputRef,
        ...restProps
      },
      ref
    ) => {
      return (
        <StyledLabel disabled={disabled} skin={skin} ref={ref} {...restProps}>
          <StyledCheckboxBase
            value={value}
            checked={checked}
            checkedIcon={checkedIcon}
            disabled={disabled}
            onChange={onChange}
            skin={skin}
            size={size}
            inputRef={inputRef}
          />
          {children && <StyledSpan>{children}</StyledSpan>}
        </StyledLabel>
      );
    }
  )
);
