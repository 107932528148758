import { ComponentType, HTMLAttributes } from 'react';
import { BasicTypographyProps, Typography } from './Typography';
import { withDeterminedVariant } from '../../HOC/withDeterminedVariant';
import { TypographyVariant } from '../ThemeProvider/types';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';

type PropsWithoutVariant = Omit<BasicTypographyProps, 'variant'>;

type DisplayType = StyledComponent<
  ComponentType<HTMLAttributes<HTMLDivElement>>,
  DefaultTheme,
  PropsWithoutVariant,
  never
>;

const DivTypography = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'div' }) => ({
  forwardedAs,
}))``;

export const Display1 = withDeterminedVariant(
  DivTypography,
  TypographyVariant.display1
) as unknown as DisplayType;

export const Display2 = withDeterminedVariant(
  DivTypography,
  TypographyVariant.display2
) as unknown as DisplayType;
