import { FC, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { Inline, Stack, SmallText, H5, Card, Tooltip } from '@parallel-mono/components';
import { BlockNumber } from '@polkadot/types/interfaces';
import { BN_ZERO } from '@polkadot/util';

import { StakingScopeContext } from '../Context';

import { useApiCall, useChainConnections } from '@/hooks';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { RELAY_CHAIN_BLOCK_TIME } from '@/utils/constants';

const CountDownText = styled(H5)`
  padding: 0.25rem;
  text-align: center;
  background-color: var(--clr-gray04);
  border-radius: 2.5rem;
`;

const CountDown: FC = () => {
  const [nextRewardSeconds, setNextRewardSeconds] = useState(0);
  const [countDown, setCountDown] = useState({ h: 0, m: 0, s: 0 });
  const [showRewardDistribution, setShowRewardDistribution] = useState(false);
  const { currencies } = useContext(StakingScopeContext);

  const {
    relaychain: { api: relayApi },
    parachain: { api: paraApi }
  } = useChainConnections();

  const eraStartBlock = useApiCall<BlockNumber>(paraApi.query.liquidStaking.eraStartBlock);

  useAsyncEffect(async () => {
    const relayChainBlockHeader = await relayApi.rpc.chain.getHeader();
    const relayChainBlockNumber = relayChainBlockHeader?.number.toBn();
    const eraLength = (await paraApi.consts.liquidStaking.eraLength) as unknown as BlockNumber;
    if (eraStartBlock) {
      setShowRewardDistribution(true);
    }

    const eraProgress = (
      eraStartBlock ? relayChainBlockNumber.sub(eraStartBlock) : BN_ZERO
    ).toNumber();

    const seconds = (eraLength.toNumber() - eraProgress) * RELAY_CHAIN_BLOCK_TIME;
    setNextRewardSeconds(seconds);
  }, [relayApi, paraApi, eraStartBlock]);

  useEffect(() => {
    const timer = setInterval(() => {
      setNextRewardSeconds(prev => prev - 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useAsyncEffect(async () => {
    if (nextRewardSeconds <= 0) {
      setCountDown({ h: 0, m: 0, s: 0 });
    } else {
      const hours = Math.floor(nextRewardSeconds / (60 * 60));
      const minute = Math.floor((nextRewardSeconds % (60 * 60)) / 60);
      const second = nextRewardSeconds % 60;
      setCountDown({ h: hours, m: minute, s: second });
    }
  }, [nextRewardSeconds]);

  return (
    <>
      {showRewardDistribution && (
        <Card style={{ padding: '1.5rem' }}>
          <Stack gap="1rem">
            <Inline alignItems="center" justifyContent="space-between">
              <SmallText skin="secondary">Next Reward Distribution</SmallText>
              <Tooltip
                content={`Distributed via ${currencies.liquidAsset.symbol}:${currencies.stakingAsset.symbol} price increase.`}
              />
            </Inline>
            <CountDownText>
              {countDown.h}h : {countDown.m}m : {countDown.s}s
            </CountDownText>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default CountDown;
