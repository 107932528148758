import { ComponentProps, JSXElementConstructor, ReactNode, ForwardedRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { genericForwardRef } from '../../utils/genericForwardRef';
import { genericMemo } from '../../utils/genericMemo';
import { Inline, Stack } from '../Layout';
import { InputContainer } from '../InputContainer';
import { Skin } from '../ThemeProvider';

export type DecoratedInputProps<
  C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = {
  Component: C;
  inputProps?: ComponentProps<C>;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  skin?: Skin;
} & ComponentProps<typeof Stack>;

const AdornmentContainer = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled(InputContainer)`
  display: inline-flex;
`;

const InputWrapper = styled.div`
  flex: 1;
  height: 100%;
  display: flex;

  & > * {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    box-sizing: border-box;
    background: transparent;
    font-size: inherit;
  }
`;

export const DecoratedInput = genericMemo(
  genericForwardRef(function <C extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>(
    props: DecoratedInputProps<C>,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    const {
      Component,
      inputProps,
      gap,
      skin = 'primary',
      startAdornment,
      endAdornment,
      ...others
    } = props;
    const TypedComponent = Component as any; // TODO fix typing here
    const theme = useTheme();
    const defaultGap = theme.spacing(2);

    return (
      <Container
        as={Inline}
        ref={ref}
        gap={gap ?? defaultGap}
        skin={skin}
        alignItems="center"
        {...others}
      >
        {startAdornment && <AdornmentContainer>{startAdornment}</AdornmentContainer>}
        <InputWrapper>
          <TypedComponent {...inputProps} />
        </InputWrapper>
        {endAdornment && <AdornmentContainer>{endAdornment}</AdornmentContainer>}
      </Container>
    );
  })
);
