import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';

import { WEIGHT_FOR_X_TOKEN_TRANSFER } from '../../constants';

import config from '@/config';
import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';

export const generateTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  const xcmV3MultiAsset = {
    Concrete: {
      interior: 'Here',
      parents: 0
    },
    fun: { Fungible: amountToBalanceByDecimals<BN>(amount, asset.decimals, 'bn') }
  };

  const xcmV3MultiLocation = {
    parents: 1,
    interior: {
      X2: [
        { Parachain: config.paraId },
        {
          AccountId32: {
            network: null,
            id: addressToHex(account.address)
          }
        }
      ]
    }
  };

  return api.tx.xTransfer.transfer(xcmV3MultiAsset, xcmV3MultiLocation, {
    proof_size: 0,
    ref_time: WEIGHT_FOR_X_TOKEN_TRANSFER
  });
};
