import styled from 'styled-components';
import { memo, useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  Inline,
  Stack,
  H6,
  Text,
  SmallText,
  Skeleton,
  Button,
  Select
} from '@parallel-mono/components';

import { NumberInput } from '../Input/NumberInput';

import { useDevice } from '@/hooks';
import { isValidAmountInput } from '@/utils/validate';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';

const InputError = ({ error }: { error: string }) =>
  !error ? null : <Text skin="error">{error}</Text>;

const StyledInline = styled(Inline)`
  align-self: flex-end;
`;
const StyledSelect = styled(Select)`
  border-radius: 6.25rem;
  padding: 0.25rem;
  height: 2.5rem;
  width: 120px;
  z-index: 98;
`;
const menuStyle = { maxHeight: 400, left: 'unset' };

export const TokenInput = ({
  value,
  options,
  onInputChange,
  onSelect,
  renderDisplay,
  renderItem,
  qty,
  checkBalance = false,
  onClickMax = null,
  renderErrorState = '',
  testid = '',
  usdBalance,
  styleDropdown = {},
  max,
  rightBottomInfo
}: any) => {
  const [inputValue, setInputValue] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const { isMobile } = useDevice();

  const onChange = useCallback(
    numberValue => {
      const valueAsStr = numberValue?.toString() ?? '';
      if (isEditing) {
        if (numberValue === null || isValidAmountInput(valueAsStr, 12)) {
          setInputValue(numberValue);
        } else if (valueAsStr.length < (inputValue?.toString() ?? '').length) {
          setInputValue(null);
        }
      }
    },
    [isEditing, inputValue]
  );

  useUpdateEffect(() => {
    if (!isEditing) {
      setInputValue(Number(qty) === 0 ? null : Number(qty));
    }
  }, [qty]);

  useUpdateEffect(() => {
    if (isEditing) {
      onInputChange(inputValue ?? 0);
    }
  }, [inputValue]);

  // current selected token's balance
  const balance = useMemo(
    () => balanceToAmountByDecimal<number>(value?.balance, value?.decimals, 'number'),
    [value]
  );

  if (!options.length) {
    const skeletonButtonWidth = isMobile ? '15rem' : '20rem';
    return (
      <Stack gap="2rem" alignItems="center">
        <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
        <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
      </Stack>
    );
  }

  let errorMessage = null;
  if (checkBalance) {
    if ((max || Number(balance)) < qty) {
      errorMessage = <InputError error="Insufficient balance for this quantity" />;
    } else if (inputValue === 0) {
      errorMessage = <InputError error="Please enter a value greater than zero" />;
    } else if (renderErrorState) {
      errorMessage = renderErrorState;
    }
  }

  return (
    <Stack style={{ marginTop: '1rem' }} gap="0.75rem" data-testid={`${testid}-wrapper`}>
      <NumberInput
        errorMessage={errorMessage}
        value={inputValue}
        onChange={onChange}
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
        placeholder="0"
        usdBalance={usdBalance}
        decimals={4}
      >
        <Stack
          data-testid={`${testid}-dropdown`}
          gap="0.5rem"
          style={styleDropdown}
          alignItems="center"
        >
          <StyledSelect
            options={options}
            onChange={onSelect}
            renderItem={renderItem}
            value={value?.value}
            menuStyle={menuStyle}
            renderDisplay={renderDisplay}
          />
          {(rightBottomInfo || onClickMax) && (
            <StyledInline
              gap="0.25rem"
              alignItems="baseline"
              justifyContent="flex-end"
              width="150%"
            >
              {rightBottomInfo && <SmallText skin="secondary">{rightBottomInfo}</SmallText>}
              {onClickMax && typeof onClickMax === 'function' && (
                <>
                  <SmallText skin="secondary">Balance:</SmallText>
                  <SmallText skin="secondary">{balanceFormatter(balance)}</SmallText>
                  <Button onClick={onClickMax} variant="link">
                    <H6>Max</H6>
                  </Button>
                </>
              )}
            </StyledInline>
          )}
        </Stack>
      </NumberInput>
    </Stack>
  );
};

export default memo(TokenInput);
