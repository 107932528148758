import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  memo,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useCallbackMerge } from '../../hooks/useCallbackMerge';

export type FormattedInputBaseProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onChange' | 'value' | 'size' | 'defaultValue'
> & {
  value?: string;
  onChange?: (value: string) => void;
  format?: (value?: string) => string;
  isValid?: (valueStr: string) => boolean;
  defaultValue?: string;
};

const defaultValidator = () => true;

const defaultFormat = (s?: string) => s ?? '';

interface SelectionInfo {
  selectionStart: number | null;
  selectionEnd: number | null;
  selectionDirection: 'forward' | 'backward' | 'none' | null;
  length: number;
}

const PureInput = memo(
  forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => (
    <input ref={ref} {...props} />
  ))
);

export const FormattedInputBase = memo(
  forwardRef<HTMLInputElement, FormattedInputBaseProps>((props, ref) => {
    const {
      value,
      onChange,
      format = defaultFormat,
      isValid = defaultValidator,
      defaultValue,
      onFocus,
      onClick,
      onKeyUp,
      onTouchEnd,
      ...others
    } = props;

    const [internalValue, setInternalValue] = useState(defaultValue);

    const finalValue = value === undefined ? internalValue : value;

    const selectionInfoRef = useRef<SelectionInfo | null>(null);

    const inputRef = useRef<HTMLInputElement | null>(null);

    const updateRangeCache = useCallback((overrides?: Partial<SelectionInfo>) => {
      selectionInfoRef.current = {
        selectionDirection: inputRef.current?.selectionDirection ?? null,
        selectionStart: inputRef.current?.selectionStart ?? null,
        selectionEnd: inputRef.current?.selectionEnd ?? null,
        length: inputRef.current?.value.length ?? 0,
        ...overrides,
      };
    }, []);

    useLayoutEffect(() => {
      if (finalValue === undefined) return;
      const {
        selectionStart = null,
        selectionEnd = null,
        selectionDirection,
        length = 0,
      } = selectionInfoRef.current ?? {};

      if (inputRef.current?.dir === 'rtl') {
        const headLength = selectionStart ?? 0;
        inputRef.current?.setSelectionRange(
          headLength,
          headLength,
          selectionDirection ?? undefined
        );
      } else {
        const tailLength = length - (selectionEnd ?? 0);
        inputRef.current?.setSelectionRange(
          finalValue.length - tailLength,
          finalValue.length - tailLength,
          selectionDirection ?? undefined
        );
      }
      updateRangeCache();
    }, [finalValue, updateRangeCache]);

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = e.target.value;
        const {
          selectionStart = null,
          selectionEnd = null,
          selectionDirection,
        } = selectionInfoRef.current ?? {};
        if (!isValid(currentValue)) {
          e.preventDefault();
          requestAnimationFrame(() => {
            e.target.setSelectionRange(
              selectionStart,
              selectionEnd,
              selectionDirection ?? undefined
            );
          });
          return;
        }
        const valueToBe = format(currentValue);
        if (onChange) {
          onChange(valueToBe);
        }
        setInternalValue(valueToBe);
        updateRangeCache();
      },
      [isValid, onChange, format, updateRangeCache]
    );

    const handleRef = useCallback(
      (ele: HTMLInputElement) => {
        inputRef.current = ele;
        updateRangeCache();
      },
      [updateRangeCache]
    );

    const updateRangeCacheForSelectionChangingKeys = useCallback(
      (e: KeyboardEvent) => {
        if (['ArrowLeft', 'ArrowRight', 'Home', 'End'].includes(e.key)) {
          updateRangeCache();
        }
      },
      [updateRangeCache]
    );

    const updateRangeCacheForEvent = useCallback(() => updateRangeCache(), [updateRangeCache]);

    const handleFocus = useCallbackMerge(onFocus, updateRangeCacheForEvent);
    const handleClick = useCallbackMerge(onClick, updateRangeCacheForEvent);
    const handleKeyUp = useCallbackMerge(onKeyUp, updateRangeCacheForSelectionChangingKeys);
    const handleTouchEnd = useCallbackMerge(onTouchEnd, updateRangeCacheForEvent);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    return (
      <PureInput
        type="text"
        value={finalValue}
        ref={handleRef}
        {...others}
        onTouchEnd={handleTouchEnd}
        onFocus={handleFocus}
        onClick={handleClick}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
      />
    );
  })
);
