// @ts-nocheck
/* eslint-disable */

import { APYFormula } from '../helpers/formulas';

export const lastPools = (AllSwaps, ammVolume) => {
  if (AllSwaps && ammVolume) {
    const weeklyVolume = ammVolume?.list?.poolVolume?.weekly;
    const dailyVolume = ammVolume?.list?.poolVolume?.daily;
    const poolSnapshot = AllSwaps.map((item, i) => {
      const poolId = item?.assets?.[0]?.pool_id;
      return {
        poolId,
        ticker: item?.ticker?.split(','),
        apy: APYFormula(weeklyVolume?.[poolId]?.val || 0, item.assets[item.assets.length - 1].val),
        tvl: item.assets[item.assets.length - 1].val,
        dVolumen: dailyVolume?.[poolId]?.val || 0,
        wVolumen: weeklyVolume?.[poolId]?.val || 0
      };
    });
    return poolSnapshot;
  }
};
