import { useMemo, useContext, useCallback } from 'react';
import { u128 } from '@parallel-finance/types';
import BigNumber from 'bignumber.js';

import { StakingScopeContext } from '../Context';

import config from '@/config';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';
import { AssetsData, CurrencyId } from '@/hooks/types';
import { balanceToAmountByDecimal, getCompoundInterestApy } from '@/utils/calculations';
import { useApiCall, useAssetMarketStatus, useAssetPrices, useChainConnections } from '@/hooks';
import { NonEmptyArray } from '@/typings/basic';

const useStakingExtraApy = (): number | null => {
  const { nativeAssetInfo: nativeToken } = useCurrentAccountNativeAssetInfo();
  const assetsPrice = useAssetPrices();

  const {
    parachain: { api }
  } = useChainConnections();

  const { currencies } = useContext(StakingScopeContext);

  const stakingAssetId = useMemo(
    () => currencies.liquidAsset.assetId,
    [currencies.liquidAsset.assetId]
  );

  const marketIds = useMemo(() => [stakingAssetId] as NonEmptyArray<CurrencyId>, [stakingAssetId]);
  const marketStatus = useAssetMarketStatus(marketIds);

  const supplyRewardPerBlock = useApiCall<u128>(
    stakingAssetId ? api.query.loans.rewardSupplySpeed : undefined,
    [stakingAssetId]
  );

  const calculatedApy = useCallback(
    (id: string, rewardPerBlock: u128, totalBalance: number, prices: AssetsData) => {
      const value = new BigNumber(totalBalance).multipliedBy(prices[id]);
      const rewardPerBlockValue = balanceToAmountByDecimal<BigNumber>(
        rewardPerBlock,
        nativeToken.decimals,
        'bigNumber'
      ).multipliedBy(prices[nativeToken.assetId]);

      const ratePerDay = rewardPerBlockValue.dividedBy(value).multipliedBy(config.blockPerDay);
      const compoundInterestApy = getCompoundInterestApy(ratePerDay);
      return Number.isFinite(compoundInterestApy) && compoundInterestApy > 0
        ? compoundInterestApy
        : 0;
    },
    [nativeToken.assetId, nativeToken.decimals]
  );

  const lendAndBorrowApy = useMemo(() => {
    if (assetsPrice && marketStatus && supplyRewardPerBlock && stakingAssetId) {
      const supplyApy = calculatedApy(
        stakingAssetId,
        supplyRewardPerBlock,
        marketStatus[stakingAssetId].totalSupply,
        assetsPrice
      );

      return supplyApy;
    }
    return null;
  }, [assetsPrice, calculatedApy, marketStatus, stakingAssetId, supplyRewardPerBlock]);

  return lendAndBorrowApy;
};

export default useStakingExtraApy;
