import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Stack } from '@parallel-mono/components';

import { MenuItemElement } from './index';

interface DropdownProps {
  className?: string;
  menus: MenuItemElement[];
  trigger?: 'click' | 'hover';
  children: ReactNode;
}

const DropdownWrapper = styled.div`
  position: relative;
`;

const DropdownBox = styled.div`
  padding-top: 5px;
  position: absolute;
  z-index: 10000;
  right: 0;
  top: 44px;
`;

const DropdownMenus = styled(Stack)`
  background: var(--clr-white);
  width: max-content;
  width: 200px;
  max-width: 280px;
  border-radius: 12px;
  padding: 8px;
  box-shadow: var(--box-shadow01);
`;

const dropdownClassName = 'dropdown-menus';
const triggerClassName = 'trigger';

const Dropdown: FC<DropdownProps> = ({ className, menus, trigger = 'hover', children }) => {
  const [visible, setVisible] = useState(false);

  const triggerEvents = useMemo(
    () => ({
      hover: {
        onMouseOver: () => setVisible(true),
        onMouseLeave: () => setVisible(false)
      },
      click: {
        onClick: (e: React.MouseEvent) => {
          if ((e.target as HTMLElement)?.closest(`.${triggerClassName}`)) setVisible(!visible);
        }
      }
    }),
    [visible]
  );

  useEffect(() => {
    const closeDropdown = (e: MouseEvent) => {
      if (!(e.target as HTMLElement)?.closest(`.${dropdownClassName}`)) {
        setVisible(false);
      }
    };

    document.body.addEventListener('click', closeDropdown, false);
    return () => document.body.removeEventListener('click', closeDropdown);
  }, []);

  return (
    <DropdownWrapper
      className={classnames(dropdownClassName, className)}
      {...triggerEvents[trigger]}
    >
      <div className={triggerClassName}>{children}</div>
      {visible && (
        <DropdownBox>
          <DropdownMenus gap="0">{menus}</DropdownMenus>
        </DropdownBox>
      )}
    </DropdownWrapper>
  );
};

export type { DropdownProps };
export default Dropdown;
