import { BasicTypographyProps, Typography } from './Typography';
import { TypographyVariant } from '../ThemeProvider/types';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import { ComponentType, HTMLAttributes } from 'react';

type PropsWithoutVariant = Omit<BasicTypographyProps, 'variant'>;

type HeaderType = StyledComponent<
  ComponentType<HTMLAttributes<HTMLHeadingElement>>,
  DefaultTheme,
  PropsWithoutVariant,
  never
>;

export const H1 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h1' }) => ({
  forwardedAs,
  variant: TypographyVariant.header1,
}))`` as HeaderType;

export const H2 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h2' }) => ({
  forwardedAs,
  variant: TypographyVariant.header2,
}))`` as HeaderType;

export const H3 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h3' }) => ({
  forwardedAs,
  variant: TypographyVariant.header3,
}))`` as HeaderType;

export const H4 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h4' }) => ({
  forwardedAs,
  variant: TypographyVariant.header4,
}))`` as HeaderType;

export const H5 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h5' }) => ({
  forwardedAs,
  variant: TypographyVariant.header5,
}))`` as HeaderType;

export const H6 = styled(Typography).attrs<{ forwardedAs: any }>(({ forwardedAs = 'h6' }) => ({
  forwardedAs,
  variant: TypographyVariant.header6,
}))`` as HeaderType;
