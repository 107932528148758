import { Stack, Inline, BigText, H3, Button, Modal } from '@parallel-mono/components';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';

import { signAndSend } from '@/utils/txCall';

const MarginInline = styled(Inline)`
  margin-top: 2rem;
`;

interface ModalProps {
  api: ApiPromise;
  account: KeyringPair;
  cancelingIds: string[];
  cancelRowData: { id: string; remaining: string };
  handleClose: () => void;
}

const CancelModal = ({ api, account, cancelingIds, cancelRowData, handleClose }: ModalProps) => {
  const [opened, setOpened] = useState(cancelingIds.includes(cancelRowData.id));

  useEffect(() => {
    setOpened(cancelingIds.includes(cancelRowData.id));
  }, [cancelingIds, cancelRowData]);

  const handleCancel = () => {
    setOpened(false);
    signAndSend({
      api,
      tx: api.tx.streaming.cancel(cancelRowData.id),
      account,
      txSuccessCb: () => {
        handleClose();
      },
      txFailedCb: () => {
        handleClose();
      }
    });
  };

  return (
    <Modal title="Cancel Stream" isOpen={opened} onClose={handleClose} size="500px">
      <Stack gap="1.5rem">
        <BigText>Are you sure? The recipient will no longer receive:</BigText>
        <Stack gap="2px">
          <BigText skin="secondary">Remaining Stream</BigText>
          <H3>{cancelRowData.remaining}</H3>
        </Stack>
      </Stack>
      <MarginInline justifyContent="space-between">
        <Button skin="secondary" onClick={handleClose}>
          Do Not Cancel
        </Button>
        <Button skin="secondary" onClick={handleCancel}>
          Confirm Cancel
        </Button>
      </MarginInline>
    </Modal>
  );
};

export default CancelModal;
