import styled from 'styled-components';
import { Stack, H2, H3, Text, Card } from '@parallel-mono/components';

import useFetchList from '../useFetchList';

import CreateForm from './CreateForm';
import SendTable from './SendTable';
import { ReactComponent as StreamInfo } from './images/stream-info.svg';

import { useAccount, useChainConnections } from '@/hooks';
import { NotConnected } from '@/components';

const Line = styled.p`
  height: 2px;
  background-color: var(--clr-gray05);
`;

const DefaultInfo = () => {
  return (
    <Stack gap="3rem">
      <StreamInfo width="3.75rem" />
      <Stack gap="1rem">
        <H2>No Outgoing Streams</H2>
        <Text skin="secondary">This is where your outgoing stream will appear.</Text>
      </Stack>
      <Line />
      <H3>How this works?</H3>
      <Stack gap="1.5rem" inset="0 0 0.75rem 0">
        <Text skin="secondary">
          1. When you stream a payment, you send the payment over a period of time by each block.
        </Text>
        <Text skin="secondary">2. This is commonly used for payroll or vesting shares.</Text>
        <Text skin="secondary">
          3. Stream recipients will start receiving the payments on the start date and time
          indicated.
        </Text>
      </Stack>
    </Stack>
  );
};

const SendWrapper = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3rem;
  > :first-child {
    width: 43.75rem;
    align-self: flex-start;
  }
  > div:last-child {
    width: 23rem;
  }
`;
const Send = () => {
  const { account } = useAccount();
  const {
    parachain: { api }
  } = useChainConnections();
  const { isReady, list } = useFetchList(api, account?.address, 'Send');

  return account ? (
    <SendWrapper>
      {isReady === true && list.length === 0 ? (
        <DefaultInfo />
      ) : (
        <SendTable api={api} isReady={isReady} rows={list} />
      )}
      <CreateForm api={api} />
    </SendWrapper>
  ) : (
    <NotConnected title="Recent Streams" />
  );
};

export default Send;
