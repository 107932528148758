import { useState, createContext, FC, useMemo, useCallback, useEffect } from 'react';
import { get, isEmpty } from 'lodash';
import { KeyringPair } from '@polkadot/keyring/types';

import { Wallet, ExtendedWallet, ExtensionWallets, WalletType } from './types';
import { walletList } from './constants';

import usePolkadotWallet from '@/hooks/useWallet/usePolkadotWallet';
import useParallelWallet from '@/hooks/useWallet/useParallelWallet';
import useLoadAccounts from '@/hooks/useAccount/useLoadAccount';
import useSubWallet from '@/hooks/useWallet/useSubWallet';

type WalletObject = Record<WalletType, Wallet>;

type WalletContextProps = {
  walletList: WalletObject;
  wallet?: Wallet;
  handleSwitchWallet: (type: WalletType) => void;
};

const SELECTED_WALLET_STORAGE_KEY = 'wallet:selected';
const APP_WALLET_OPENED_KEY = 'wallet:app-opened';

export const WalletContext = createContext({} as WalletContextProps);

const WalletContextProvider: FC = ({ children }) => {
  const selectedWallet = useMemo(
    () => window.localStorage.getItem(SELECTED_WALLET_STORAGE_KEY),
    []
  );
  const appOpened = useMemo(() => window.sessionStorage.getItem(APP_WALLET_OPENED_KEY), []);
  const [autoSwitched, setAutoSwitched] = useState(false);
  const [wallets, setWallets] = useState<WalletObject>({} as WalletObject);
  const [activeWalletType, setActiveWalletType] = useState<WalletType>(
    (selectedWallet || WalletType.Polkadot) as WalletType
  );
  const { connectAccounts } = useLoadAccounts(ExtensionWallets.Polkadot);
  const polkadotWallet = usePolkadotWallet();
  const parallelWallet = useParallelWallet();
  const subWallet = useSubWallet();
  const { Provider } = WalletContext;

  const walletMapping: Record<WalletType, ExtendedWallet> = useMemo(
    () => ({
      polkadot: polkadotWallet,
      parallel: parallelWallet,
      subWallet
      // metamask: {} as ExtendedWallet,
      // phantom: {} as ExtendedWallet
    }),
    [parallelWallet, polkadotWallet, subWallet]
  );

  const handleSwitchWallet = useCallback((type: WalletType) => {
    window.localStorage.setItem(SELECTED_WALLET_STORAGE_KEY, type);
    setActiveWalletType(type);
  }, []);
  useEffect(() => {
    if (appOpened === null) {
      window.sessionStorage.setItem(APP_WALLET_OPENED_KEY, 'true');
    }
  }, [appOpened]);

  useEffect(() => {
    const parallelAccount = get(wallets, 'parallel.accounts', []).find(
      (accountItem: KeyringPair) =>
        accountItem.address ===
        get<WalletObject, string>(wallets, 'polkadot.selectedAccount')?.address
    );
    if (
      !appOpened &&
      !autoSwitched &&
      !isEmpty(wallets) &&
      selectedWallet === WalletType.Polkadot &&
      parallelAccount
    ) {
      setAutoSwitched(true);
      wallets.parallel.switchAccount!(parallelAccount);
      handleSwitchWallet(WalletType.Parallel);
    }
  }, [appOpened, autoSwitched, handleSwitchWallet, selectedWallet, wallets]);

  useEffect(() => {
    const walletMap = walletList.reduce((result, wallet) => {
      const { walletInstalled, accounts, selectedAccount, switchAccount } =
        walletMapping[wallet.type];
      result[wallet.type] = {
        ...wallet,
        walletInstalled,
        accounts,
        selectedAccount,
        switchAccount,
        loadAccounts: connectAccounts
      };
      return result;
    }, {} as WalletObject);
    setWallets(walletMap);
  }, [connectAccounts, walletMapping]);

  const value = useMemo(
    () => ({
      walletList: wallets,
      wallet: wallets[activeWalletType],
      handleSwitchWallet
    }),
    [activeWalletType, handleSwitchWallet, wallets]
  );
  return <Provider value={value}>{children}</Provider>;
};

export * from './types';
export * from './constants';

export default WalletContextProvider;
