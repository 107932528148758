import styled from 'styled-components';
import type { CSSProperties, ReactNode, HTMLAttributes } from 'react';

interface StackProps extends HTMLAttributes<HTMLDivElement> {
  width?: CSSProperties['width'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  inset?: CSSProperties['padding'];
  gap?: CSSProperties['marginTop'];
  block?: boolean;
  children: ReactNode;
}

const Stack = styled.div<StackProps>`
  width: ${props => props.width};
  display: ${props => (props.block ?? true ? 'flex' : 'inline-flex')};
  flex-direction: column;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  padding: ${props => props.inset || '0'};
  > * + * {
    margin-top: ${props => props.gap || '1.5rem'};
  }
`;

interface InlineProps extends HTMLAttributes<HTMLDivElement> {
  width?: CSSProperties['width'];
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  inset?: CSSProperties['padding'];
  gap?: CSSProperties['marginLeft'];
  grow?: boolean;
  block?: boolean;
}

const Inline = styled.div<InlineProps>`
  width: ${props => props.width};
  display: ${props => (props.block ?? true ? 'flex' : 'inline-flex')};
  flex-direction: row;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  padding: ${props => props.inset || '0'};
  > * {
    flex-grow: ${props => (props.grow ? 1 : 0)};
  }
  > * + * {
    margin-left: ${props => props.gap || '1.5rem'};
  }
`;

const Responsive = styled(Inline)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * + * {
      margin-left: 0;
      margin-top: 1rem;
    }
  `};
`;

export type { StackProps, InlineProps };
export { Stack, Inline, Responsive };
