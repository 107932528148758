import { FC, useState } from 'react';
import { BN } from '@polkadot/util';

import { Pool } from '@/pages/Farm/types';
import { useChainConnections } from '@/hooks';
import InputModal from '@/pages/Farm/Modal/InputModal';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { AssetInfo } from '@/hooks/types';
import { NativeToken } from '@/config';
import { balanceFormatter } from '@/utils/format';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

type Props = {
  closeModal: () => void;
  data: Pool;
};

const DepositModal: FC<Props> = ({ closeModal, data }) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const assetInfos = useCurrentAccountAssetInfos();
  const assets: AssetInfo[] = assetInfos?.assetInfos || [];
  const assetBalance = assets.find(asset => asset.assetId === data.asset.id)?.availableBalance ?? 0;

  const generateDepositTransaction = (depositAmount: number) =>
    api.tx.farming.deposit(
      data.asset.id,
      data.rewardAsset.id,
      data.lockDuration,
      amountToBalanceByDecimals<BN>(depositAmount, data.asset.decimals, 'bn')
    );

  const [estRewardsPerDay, setEstRewardsPerDay] = useState<number>(0);

  return (
    <InputModal
      closeModal={() => {
        setEstRewardsPerDay(0);
        closeModal();
      }}
      availableTag="Balance"
      availableAmount={assetBalance}
      excludeTransactionFee={data.asset.name in NativeToken}
      assetName={data.asset.name}
      onChange={amount => setEstRewardsPerDay(data.calculateEstReward(amount))}
      information={[
        {
          key: 'estReward',
          title: 'Est. Rewards per day',
          content: `${balanceFormatter(estRewardsPerDay)} ${data.rewardAsset.name}`
        }
      ]}
      generateTx={generateDepositTransaction}
      insufficientText="Insufficient balance on Parallel."
    />
  );
};

export default DepositModal;
