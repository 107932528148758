import { memo } from 'react';

import PageWrapper from '../PageWrapper';

import { Pool } from './types';
import useFarming from './hooks/useFarming';
import RedeemModal from './Modal/RedeemModal';
import FarmingPoolTable from './FarmingPoolTable';
import DepositModal from './Modal/DepositModal';
import ClaimModal from './Modal/ClaimModal';
import WithdrawModal from './Modal/WithdrawModal';
import LoadingStatus from './LoadingStatus';

import { Chains, useAccount, useModal } from '@/hooks';

const Farm = () => {
  const { isReady, pools } = useFarming();
  const { account } = useAccount();

  const onClickButton = (openModal: (data: Pool) => void) => (pool: Pool) => {
    openModal(pool);
  };

  const { openModal: openClaimModal, holder: claimModalHolder } = useModal(ClaimModal, {
    title: 'Claim Rewards',
    size: '500px'
  });

  const { openModal: openDepositModal, holder: depositModalHolder } = useModal(DepositModal, {
    title: 'Deposit',
    size: '500px'
  });

  const { openModal: openWithdrawModal, holder: withdrewModalHolder } = useModal(WithdrawModal, {
    title: 'Withdraw',
    size: '500px'
  });

  const { openModal: openRedeemModal, holder: redeemModalHolder } = useModal(RedeemModal, {
    title: 'Redeem',
    size: '500px'
  });

  return (
    <>
      <FarmingPoolTable
        loading={!isReady}
        pools={pools}
        connected={account !== undefined}
        onDeposit={onClickButton(openDepositModal)}
        onClaim={onClickButton(openClaimModal)}
        onWithdraw={onClickButton(openWithdrawModal)}
        onRedeem={onClickButton(openRedeemModal)}
      />
      {claimModalHolder}
      {depositModalHolder}
      {withdrewModalHolder}
      {redeemModalHolder}
    </>
  );
};

export default memo(() => (
  <PageWrapper initialChains={[Chains.ParaChain]} loadingComponent={<LoadingStatus />}>
    <Farm />
  </PageWrapper>
));
