import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { NavigationType } from '../..';
import { MenuItem } from '../Menu';
import ComingSoon from '../ComingSoon';

import config, { Chain } from '@/config';
import { useSearchParamsNavigate } from '@/hooks/useSearchParamsNavigate';

export type Menu = {
  name: string;
  title?: string;
  description?: string;
  icon: string;
  host?: string;
  path?: string;
  url?: string;
  enabled?: boolean;
  display?: NavigationType[];
};

type Navigations = {
  menus: Menu[];
  chain: Chain;
  iconBgColor?: string;
  callback?: () => void;
  active?: boolean;
};

export type MenuLocation = {
  host?: string;
  path?: string;
  url?: string;
};

const MenusWrapper = styled.div`
  display: grid;
  justify-content: space-around;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 0);
  grid-column-gap: 4rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
    grid-template-columns: repeat(auto-fill, 5rem);
    grid-column-gap: 5rem;
  `};
  ${({ theme }) => theme.breakpoints.down('md')`
    grid-template-columns: repeat(auto-fill, 3.5rem);
    grid-column-gap: 3rem;
  `};
  grid-row-gap: 1.5rem;
  padding-top: 0.5rem;
`;

const Menus: FC<Navigations> = ({ menus, chain, iconBgColor, callback, active }) => {
  const { navigate } = useSearchParamsNavigate();
  const [comingSoonShown, setComingSoonShown] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(null);

  useEffect(() => {
    if (!active) {
      setComingSoonShown(false);
    }
  }, [active]);

  const navigateTo = useCallback(
    ({ host, path, url }: MenuLocation) => {
      const isRedirect = config.chain !== chain;
      if (url) {
        window.location.href = url;
      } else if (isRedirect) {
        window.location.href = `${host}${path}`;
      } else if (path) {
        navigate(path);
      }
      if (callback) {
        callback();
      }
    },
    [callback, chain, navigate]
  );

  return (
    <>
      {comingSoonShown && selectedMenu && (
        <ComingSoon
          {...selectedMenu}
          iconBgColor={iconBgColor}
          onBack={() => {
            setComingSoonShown(false);
            setSelectedMenu(null);
          }}
        />
      )}
      {!comingSoonShown && (
        <MenusWrapper>
          {menus.map(menu => (
            <MenuItem
              showComingSoonDetail={() => {
                setSelectedMenu(menu);
                setComingSoonShown(true);
              }}
              key={menu.name}
              iconBgColor={iconBgColor}
              menu={menu}
              navigateTo={navigateTo}
            />
          ))}
        </MenusWrapper>
      )}
    </>
  );
};

export default Menus;
