import { Skeleton, Inline, Stack, H5, Button } from '@parallel-mono/components';
import { memo } from 'react';
import styled from 'styled-components';

import { useDevice } from '@/hooks';

const SkeletonButton = styled(Skeleton.Button).attrs({
  variant: 'round',
  width: '10rem',
  height: '2.75rem'
})``;

const ChainLoading = () => {
  const { isMobile } = useDevice();

  return (
    <>
      <Inline justifyContent="space-between" alignItems="flex-end">
        <Stack gap="0.5rem" inset="0.5rem 0 0 0" width="100%">
          <H5 skin="secondary" fontWeight="medium">
            Asset
          </H5>
          <Inline justifyContent="space-between" alignItems="flex-end">
            <SkeletonButton fullWidth={isMobile} />
            <Skeleton.Button variant="round" width="8rem" height="2rem" />
          </Inline>
        </Stack>
      </Inline>
      <SkeletonButton fullWidth />
      <Inline inset="2rem 0 0 0">
        <Button skin="primary" size="large" block disabled>
          Connecting...
        </Button>
      </Inline>
    </>
  );
};

export default memo(ChainLoading);
