import { useEffect, useState } from 'react';
import { BorrowSnapshot, Deposits, Rate } from '@parallel-finance/types/interfaces';
import { zipObject, zipWith } from 'lodash';
import { BN } from '@polkadot/util';
import BigNumber from 'bignumber.js';

import { useChainConnections, useAccount, useApiCall } from '@/hooks';
import { CurrencyId, MarketData } from '@/hooks/types';
import { rateToNumber } from '@/utils/utils';
import { NonEmptyArray } from '@/typings/basic';

interface UserMarketDetails {
  isCollateral: boolean;
  voucherBalance: string;
  supplyBalance: string;
  borrowBalance: string;
}

const computedBorrows = (index: BN, borrow: BorrowSnapshot) => {
  const { principal, borrowIndex } = borrow;
  if (principal.isEmpty || borrowIndex.isEmpty) {
    return '0';
  }
  return new BigNumber(principal.toString())
    .multipliedBy(index.toString())
    .dividedBy(borrowIndex.toString())
    .toString();
};

const computedSupply = (value: BN, rate: Rate) =>
  new BigNumber(value.toString()).multipliedBy(rateToNumber(rate)).toString();

const useUserMarketDetails = (ids?: NonEmptyArray<CurrencyId> | undefined) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();

  const [state, setState] = useState<MarketData<UserMarketDetails>>();

  const exchangeRates = useApiCall<Rate[]>(ids && api.query.loans.exchangeRate.multi, [ids]);

  const accountDeposits = useApiCall<Deposits[]>(
    account && ids && api.query.loans.accountDeposits.multi,
    [ids?.map(id => [id, account?.address])]
  );

  const borrowIndexes = useApiCall<BN[]>(ids && api.query.loans.borrowIndex.multi, [ids]);

  const accountBorrows = useApiCall<BorrowSnapshot[]>(
    account && ids && api.query.loans.accountBorrows.multi,
    [ids?.map(id => [id, account?.address])]
  );

  useEffect(() => {
    if (exchangeRates && accountDeposits && accountBorrows && borrowIndexes && ids) {
      const values = zipWith(
        accountDeposits,
        exchangeRates,
        borrowIndexes,
        accountBorrows,
        (deposit, rate, index, borrow) => ({
          isCollateral: deposit.isCollateral.isTrue,
          voucherBalance: deposit.voucherBalance.toString(),
          supplyBalance: computedSupply(deposit.voucherBalance, rate),
          borrowBalance: computedBorrows(index, borrow)
        })
      );

      setState(zipObject(ids, values));
    }
  }, [accountBorrows, accountDeposits, borrowIndexes, exchangeRates, ids]);

  return state;
};

export default useUserMarketDetails;
