import { FC } from 'react';
import styled from 'styled-components';
import { Inline, H5, Card, Icon } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { useTotalValue } from '@/hooks';

const sizeMapping = {
  small: '0.25rem 0.5rem',
  large: '1rem'
};

const Wrapper = styled(Card)<{ width?: string; size: 'small' | 'large' }>`
  padding: ${({ size }) => sizeMapping[size]};
  width: ${({ width }) => width || '17.25rem'};

  hover: {
    cursor: pointer;
  }
`;

type TVLProps = {
  width?: string;
  transparent?: boolean;
  size?: 'small' | 'large';
};

const TVLBanner: FC<TVLProps> = ({ width, transparent = false, size = 'large' }) => {
  const totalTVL = useTotalValue();
  return (
    <Wrapper
      size={size}
      width={width}
      style={{
        backgroundColor: transparent ? 'transparent' : 'var(--clr-white)',
        boxShadow: transparent ? 'none' : 'var(--box-shadow02)'
      }}
    >
      <Inline justifyContent="space-between" alignItems="center" gap="0.5rem">
        <Inline gap="0.5rem" alignItems="center">
          <Icon name="trendingUp" size="medium" />
          <H5 skin="primary">
            {formatNumber(totalTVL || 0, { output: 'currency', decimal: 0, average: false })}{' '}
          </H5>
          <H5 skin="secondary">TVL</H5>
        </Inline>
      </Inline>
    </Wrapper>
  );
};

export default TVLBanner;
