import PageWrapper from '../PageWrapper';

import { LoadingStatus } from './components/LoadingStatus';
import { Main } from './Main';

import { Chains } from '@/hooks';

export default () => {
  return (
    <PageWrapper
      initialChains={[Chains.ParaChain, Chains.RelayChain]}
      loadingComponent={<LoadingStatus />}
    >
      <Main />
    </PageWrapper>
  );
};
