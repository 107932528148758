import { useState, useCallback, useMemo, useRef } from 'react';
import BigNumber from 'bignumber.js';
import { BN, BN_ZERO } from '@polkadot/util';
import { u128 } from '@polkadot/types';
import { Permill, Rate } from '@parallel-finance/types/interfaces';

import { UnstakeType } from '../subComponents/ProcessUnstake';
import { ROUTE_SLIPPAGE } from '../constants';
import { RouteType } from '../types';

import { useApiCall, useChainConnections } from '@/hooks';
import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import { AssetInfo } from '@/hooks/types';
import { rateToNumber, ratioToNumber } from '@/utils/utils';
import { zero } from '@/utils/values';

export const useUnstakeBestRoutes = () => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [unstakeFee, setUnstakeFee] = useState<number>();
  const [route, setRoute] = useState(RouteType.Staking);
  const [unstakeIsReady, setUnstakeIsReady] = useState(false);
  const instantUnstakeRef = useRef<boolean>();

  const exchangeRate = useApiCall<number>(api.query.liquidStaking.exchangeRate, [], {
    transform: (rate: Rate) => rateToNumber(rate)
  });

  const generateSwapTx = useCallback(
    (routes, amountIn, amountOut) => {
      return api.tx.ammRoute.swapExactTokensForTokens(routes, amountIn, amountOut);
    },
    [api]
  );

  const generateStakeTx = useCallback(
    (amount: number, decimal, instantUnstake: boolean) => {
      const { unstake } = api.tx.liquidStaking;

      const unstakeAmount = amountToBalanceByDecimals<BN>(amount, decimal, 'bn');
      const unstakeTx = instantUnstake
        ? unstake(unstakeAmount, UnstakeType.FastUnstake)
        : unstake(unstakeAmount, UnstakeType.NormalUnstake);
      return unstakeTx;
    },
    [api.tx.liquidStaking]
  );

  const swapFee = useMemo(() => ratioToNumber(api.consts.amm.lpFee), [api]);

  const platformUnstakeFee =
    useApiCall<BigNumber>(api.query.liquidStaking.unstakeReserveFactor, [], {
      transform: (value: Permill) =>
        balanceToAmountByDecimal<BigNumber>(value, 'million', 'bigNumber')
    }) || zero;

  const generateBestRoutes = useCallback(
    async (
      amount: number,
      unstakeMintVal: number,
      stakingToken: AssetInfo,
      liquidToken: AssetInfo,
      instantUnstake: boolean
    ) => {
      instantUnstakeRef.current = instantUnstake;
      setUnstakeIsReady(false);
      const platformUnstakeFeeAmount = BigNumber(amount)
        .times(platformUnstakeFee)
        .times(exchangeRate || 0)
        .toNumber();
      const [routes, amountOut] = await api.rpc.router.getBestRoute(
        amountToBalanceByDecimals<BN>(amount, stakingToken.decimals, 'bn'),
        new BN(liquidToken.assetId),
        new BN(stakingToken.assetId),
        false
      );
      const poolLiquidity = await api.query.amm.pools(liquidToken.assetId, stakingToken.assetId);
      const liquidAmountInPool = poolLiquidity.isSome
        ? balanceToAmountByDecimal<BigNumber>(
            poolLiquidity.unwrap().quoteAmount,
            stakingToken.decimals,
            'bigNumber'
          )
        : new BigNumber(0);

      const swapMintAmount = BigNumber.min(
        balanceToAmountByDecimal<BigNumber>(amountOut, liquidToken.decimals, 'bigNumber').times(
          1 - ROUTE_SLIPPAGE
        ),
        liquidAmountInPool
      );

      const swapRoute = {
        tx: generateSwapTx(
          routes,
          amountToBalanceByDecimals<BN>(amount, stakingToken.decimals, 'bn'),
          amountToBalanceByDecimals<BN>(swapMintAmount, liquidToken.decimals, 'bn')
        ),
        route: RouteType.Amm,
        receiveAmount: swapMintAmount.toNumber(),
        fee: amount * swapFee
      };

      if (instantUnstake) {
        const fastUnstakeFee = (api.consts.liquidStaking.loansInstantUnstakeFee || BN_ZERO) as u128;
        const instantUnstakeFee = new BigNumber(rateToNumber(fastUnstakeFee))
          .times(amount)
          .times(exchangeRate || 0);
        const stakingUnstakeFee = instantUnstakeFee.plus(platformUnstakeFeeAmount);

        const shouldUseSwap = swapMintAmount.gt(
          new BigNumber(unstakeMintVal).minus(stakingUnstakeFee)
        );
        const bestRoute = shouldUseSwap
          ? swapRoute
          : {
              tx: generateStakeTx(amount, stakingToken.decimals, instantUnstake),
              route: RouteType.Staking,
              receiveAmount: new BigNumber(unstakeMintVal).minus(stakingUnstakeFee).toNumber(),
              fee: stakingUnstakeFee.toNumber()
            };
        if (!instantUnstakeRef.current) return null;

        setRoute(bestRoute.route);
        setUnstakeFee(bestRoute.fee);
        setReceiveAmount(bestRoute.receiveAmount);
        setUnstakeIsReady(true);
        return bestRoute.tx;
      }

      const shouldUseSwap = swapMintAmount.gt(
        BigNumber(unstakeMintVal).minus(platformUnstakeFeeAmount)
      );
      const bestRoute = shouldUseSwap
        ? swapRoute
        : {
            tx: generateStakeTx(amount, stakingToken.decimals, false),
            route: RouteType.Staking,
            receiveAmount: BigNumber(unstakeMintVal).minus(platformUnstakeFeeAmount).toNumber(),
            fee: platformUnstakeFeeAmount
          };

      setUnstakeFee(bestRoute.fee);
      setReceiveAmount(bestRoute.receiveAmount);
      setUnstakeIsReady(true);
      return bestRoute.tx;
    },
    [
      api.consts.liquidStaking.loansInstantUnstakeFee,
      api.query.amm,
      api.rpc.router,
      exchangeRate,
      generateStakeTx,
      generateSwapTx,
      platformUnstakeFee,
      swapFee
    ]
  );
  return {
    generateBestRoutes,
    receiveAmount,
    unstakeIsReady,
    unstakeFee,
    route
  };
};
