import { isFunction } from 'lodash';
import {
  memo,
  ReactNode,
  useCallback,
  useMemo,
  HTMLAttributes,
  useEffect,
  useState,
  TransitionEvent,
  AnimationEvent,
} from 'react';
import ReactDOM from 'react-dom';
import styled, { CSSProperties } from 'styled-components';
import { useCallbackMerge } from '../../hooks/useCallbackMerge';
import { fadeInAnimation } from '../Animations/fadeInAnimation';

export type PopupProps = {
  isOpen: boolean;
  onClose?: () => void;
  container?: HTMLElement | (() => HTMLElement);
  children?: ReactNode;
  disablePortal?: boolean;
  closeOnEscapeKey?: boolean;
  keepMounted?: boolean;
  closeOnBackdropClick?: boolean;
  backdropProps?: HTMLAttributes<HTMLDivElement>;
  transitionDuration?: CSSProperties['transitionDuration'];
  transitionTimingFunction?: CSSProperties['transitionTimingFunction'];
} & HTMLAttributes<HTMLDivElement>;

const Root = styled.div<{ visible: boolean }>`
  position: fixed;
  z-index: 10000;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  inset: 0;
`;

const Backdrop = styled.div<{
  visible: boolean;
  duration: CSSProperties['transitionDuration'];
  timingFunction: CSSProperties['transitionTimingFunction'];
}>`
  position: fixed;
  inset: 0;
  z-index: -1;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity ${p => p.duration} ${p => p.timingFunction};
  animation: ${fadeInAnimation} ${p => p.duration} ${p => p.timingFunction};
`;

export const Popup = memo((props: PopupProps) => {
  const {
    isOpen,
    onClose,
    container = document.body,
    children,
    disablePortal = false,
    closeOnEscapeKey = true,
    keepMounted = false,
    closeOnBackdropClick = true,
    backdropProps = {},
    transitionDuration = '0.2s',
    transitionTimingFunction = 'ease-in-out',
    ...others
  } = props;

  const [internalIsOpen, setInternalIsOpen] = useState(isOpen);

  const rootContainer = useMemo(
    () => (isFunction(container) ? container() : container),
    [container]
  );

  const handleClickBackdrop = useCallback(() => {
    closeOnBackdropClick && onClose?.();
  }, [onClose, closeOnBackdropClick]);

  const mergedHandleClickBackdrop = useCallbackMerge(backdropProps.onClick, handleClickBackdrop);

  useEffect(() => {
    if (closeOnEscapeKey) {
      const handleKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          onClose?.();
        }
      };
      document.addEventListener('keydown', handleKeydown);
      return () => {
        document.removeEventListener('keydown', handleKeydown);
      };
    }
    return;
  }, [closeOnEscapeKey, onClose]);

  const handleTransitionEnd = useCallback((e: TransitionEvent | AnimationEvent) => {
    setInternalIsOpen((e.target as HTMLDivElement).getAttribute('data-visible') === 'true');
  }, []);

  const isRootVisible = isOpen || internalIsOpen;

  const content =
    keepMounted || isRootVisible ? (
      <Root visible={isRootVisible} {...others}>
        <Backdrop
          {...backdropProps}
          data-visible={isOpen}
          visible={isOpen}
          onClick={mergedHandleClickBackdrop}
          onAnimationEnd={handleTransitionEnd}
          onTransitionEnd={handleTransitionEnd}
          duration={transitionDuration}
          timingFunction={transitionTimingFunction}
        />
        {children}
      </Root>
    ) : null;

  if (disablePortal) return content;

  return ReactDOM.createPortal(content, rootContainer);
});
