import { FC } from 'react';
import styled from 'styled-components';
import { Inline, Stack, SmallText } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';

import { Menu, MenuLocation } from '../Menus';

const MenuWrapper = styled(Stack).attrs({
  alignItems: 'center',
  gap: '0.5rem'
})`
  &:hover {
    > div:first-child {
      box-shadow: var(--box-shadow01);
    }
  }
  cursor: pointer;
`;

const ImageWrapper = styled(Inline).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: var(--clr-white);
  box-shadow: 0px 2px 6px rgba(58, 78, 88, 0.1);
`;

const MenuText = styled(SmallText).attrs({ type: 'secondary' })`
  white-space: nowrap;
  text-align: center;
`;

const CommingSoon = styled(SmallText)`
  width: 7rem;
  margin-top: 0.75rem;
  padding: 2px 0;
  text-align: center;
  border-radius: 1rem;
  background-color: var(--clr-gray04);
  color: var(--clr-black);
  font-weight: bold;
  ${({ theme }) => theme.breakpoints.down('md')`
    width: 5.5rem;
    font-size: 0.75rem;
  `};
`;

type MenuItemProps = {
  menu: Menu;
  iconBgColor?: string;
  navigateTo: (location: MenuLocation) => void;
  showComingSoonDetail: () => void;
};

const MenuIcon = styled(HostedImage)`
  width: 2rem;
`;

export const MenuItem: FC<MenuItemProps> = ({
  menu: { icon, name, path, url, host, enabled = true },
  navigateTo,
  showComingSoonDetail,
  iconBgColor = 'var(--clr-white)'
}) => {
  return (
    <MenuWrapper
      onClick={() => {
        if (enabled) {
          navigateTo({ host, path, url });
        } else {
          showComingSoonDetail();
        }
      }}
    >
      <ImageWrapper style={{ backgroundColor: iconBgColor }}>
        <MenuIcon name={icon} />
      </ImageWrapper>
      <MenuText>{name}</MenuText>
      {!enabled && <CommingSoon>Coming Soon</CommingSoon>}
    </MenuWrapper>
  );
};
