import { useEffect, useState, useMemo } from 'react';
import { AssetBalance, AssetId, AssetMetadata } from '@polkadot/types/interfaces';
import { isEmpty, isEqual, zipWith } from 'lodash';
import { Option, StorageKey, Vec, Text } from '@polkadot/types';
import { BN_ZERO } from '@polkadot/util';

import useApiCall from './useApiCall';
import { AssetInfo, CurrencyId } from './types';
import { useAccount } from './useAccount';
import { useChainConnections } from './useChainConnection';
import useNativeAssetInfo from './useNativeAssetInfo';

import { balanceToAmountByDecimal } from '@/utils/calculations';

const useAssetInfos = ({ address }: { address: string }) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();
  const { nativeAssetInfo } = useNativeAssetInfo(address);
  const [isReady, setIsReady] = useState(false);

  // If the result is an array, we should return empty array rather undefined
  const [assetInfos, setAssetInfos] = useState<AssetInfo[]>([]);

  const assetsMetadata = useApiCall<[StorageKey<[AssetId]>, AssetMetadata][]>(
    api?.query?.assets?.metadata?.entries
  );
  const collateralCurrency = useMemo(() => api.consts.liquidStaking.collateralCurrency, [api]);

  const chainName = useApiCall<Text>(api.rpc.system.chain);

  const assetIds: CurrencyId[] = useMemo(() => {
    return assetsMetadata?.flatMap(m => m[0]).map(({ args: [id] }) => id.toString()) || [];
  }, [assetsMetadata]);

  const balances = useApiCall<Vec<Option<AssetBalance>>>(
    assetIds.length > 0 && account ? api.query.assets.account.multi : undefined,
    [assetIds?.map(id => [id, address ?? account?.address])]
  );

  useEffect(() => {
    const metadatas = assetsMetadata?.flatMap(m => m[1]) ?? [];
    if (chainName && !isEmpty(balances) && !isEmpty(metadatas) && !isEmpty(assetIds)) {
      const infos = zipWith(assetIds, metadatas, balances || [], (key, metadata, balanceObj) => {
        const balanceBn =
          balanceObj && balanceObj.isSome ? balanceObj.unwrap().balance.toBn() : BN_ZERO;
        const balance = balanceToAmountByDecimal<number>(balanceBn, metadata.decimals, 'number');

        return {
          assetId: key,
          network: chainName,
          name: metadata.name.toHuman() as string,
          symbol: metadata.symbol.toHuman() as string,
          decimals: metadata.decimals,
          existentialDeposit: 0,
          balance,
          lockedBalance: 0,
          availableBalance: balance,
          maxAvailableBalance: balance,
          isNative: false
        };
      });

      const assetWithNativeTokens = [...infos, nativeAssetInfo];
      setAssetInfos(prev => (isEqual(prev, assetWithNativeTokens) ? prev : assetWithNativeTokens));
      if (!isReady) setIsReady(true);
    }
  }, [assetsMetadata, balances, assetIds, chainName, nativeAssetInfo, collateralCurrency, isReady]);

  const assetInfo = useMemo(
    () => ({ isReady, balances, assetIds: [...assetIds, nativeAssetInfo.assetId], assetInfos }),
    [assetIds, assetInfos, balances, isReady, nativeAssetInfo.assetId]
  );

  return assetInfo;
};

export default useAssetInfos;
