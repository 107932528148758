import { useState, useCallback, useMemo } from 'react';
import { find } from 'lodash';
import { KeyringPair } from '@polkadot/keyring/types';
import { createTestKeyring } from '@polkadot/keyring';
import { useLocation } from 'react-router-dom';

import config from '@/config';
import useLoadAccounts from '@/hooks/useAccount/useLoadAccount';
import { ContextData } from '@/hooks/useAccount';
import { isWalletInstalled } from '@/utils/extension';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { ExtensionWallets, WalletType } from '@/contexts/WalletsContext/types';
import { isValidAddress } from '@/utils/utils';

const SELECTED_ACCOUNT_STORAGE_KEY = 'polkadot:account:selected';

const usePolkadotWallet = () => {
  const [walletInstalled, setWalletInstalled] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<ContextData['account']>();
  const [accountList, setAccountList] = useState<ContextData['accountList']>();
  const { keyring } = useLoadAccounts(ExtensionWallets.Polkadot);

  const handleSwitchAccount: ContextData['handleSwitchAccount'] = useCallback((value, callback) => {
    setSelectedAccount(value);
    window.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, value.address);
    if (typeof callback === 'function') callback(value);
  }, []);

  const { search } = useLocation();

  useAsyncEffect(async () => {
    const keyringPairs = keyring?.getPairs();
    if (keyringPairs) {
      // Only for the development env
      const testKeyring = createTestKeyring();
      const testingAccounts = config.DEBUG ? testKeyring.getPairs() : [];

      const allAccounts = [...keyringPairs, ...testingAccounts] as KeyringPair[];
      const prevSelected = find(allAccounts, [
        'address',
        window.localStorage.getItem(SELECTED_ACCOUNT_STORAGE_KEY)
      ]);
      setAccountList(allAccounts);
      setSelectedAccount(prevSelected || allAccounts?.[0]);
    }
  }, [keyring]);

  useAsyncEffect(async () => {
    const installed = await isWalletInstalled(WalletType.Polkadot);
    setWalletInstalled(installed as boolean);
  }, []);

  const walletValue = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const viewAddress = searchParams.get('view_address');

    const value = {
      accounts: accountList,
      selectedAccount,
      switchAccount: handleSwitchAccount,
      walletInstalled
    };

    if (viewAddress && isValidAddress(viewAddress)) {
      return {
        ...value,
        selectedAccount: {
          ...selectedAccount,
          address: viewAddress
        } as KeyringPair
      };
    }

    return value;
  }, [search, accountList, selectedAccount, handleSwitchAccount, walletInstalled]);

  return walletValue;
};

export default usePolkadotWallet;
