import { AbstractChain } from '../AbstractChain';
import { Chains, TxCallback } from '../types';
import { TxFee } from '../../types';

import { generateTx } from './helpers/generateTx';
import {
  TO_CHAINS,
  CURRENCIES,
  MINIMUM_TRANSFER_AMOUNT,
  LIQUID_CROWDLOAN_CURRENCY_ID
} from './constants';
import { calculateAssetsInfo } from './helpers/calculateAssetsInfo';
import { AcalaChainCurrency, ChainMetadata } from './types';

import { AssetDetailInfo } from '@/hooks/types';
import { signAndSend, txFee } from '@/utils/txCall';

export class AcalaChain extends AbstractChain<ChainMetadata> {
  static toChains = TO_CHAINS;

  static currencies = CURRENCIES;

  static minimumTransferAmount = MINIMUM_TRANSFER_AMOUNT;

  metadataSubscribe = async () => {
    const { api, account, subscribeValue, paraApi } = this;

    // the `api.query.assets.metadata.entries` will not trigger the subscribe callback, so put it to the metadata manually
    this.metadata.assetsMetadata = await paraApi.query.assets.metadata.entries();
    subscribeValue(account && api.query.system.account, [account?.address], 'accountInfo');

    const otherTokenParams = [
      ...CURRENCIES.Parallel.filter(tokenName => tokenName !== AcalaChainCurrency.LCDOT).map(
        tokenName => ({
          Token: tokenName
        })
      ),
      { LiquidCrowdloan: LIQUID_CROWDLOAN_CURRENCY_ID }
    ];
    subscribeValue(
      api.query.tokens.accounts.multi,
      [otherTokenParams.map(param => [account?.address, param])],
      'tokensInfo'
    );
  };

  calculateAssetsInfo = async (): Promise<AssetDetailInfo[]> => {
    const assetsInfo = await calculateAssetsInfo(this.api, this.metadata);
    return assetsInfo;
  };

  getTxFee = async (
    amount: number,
    asset: AssetDetailInfo,
    nativeAsset: AssetDetailInfo
  ): Promise<TxFee> => {
    const { api, account } = this;
    const tx = generateTx(api, account, asset, amount);
    const fee = amount ? await txFee({ tx, account }) : 0;

    return { currency: nativeAsset.symbol, fee };
  };

  transfer = (
    amount: number,
    asset: AssetDetailInfo,
    _toChain: Chains,
    options?: TxCallback
  ): void => {
    const { api, account } = this;
    const tx = generateTx(api, account, asset, amount);
    signAndSend({
      api,
      tx,
      account,
      ...(options || {})
    });
  };
}
