import { Alert, Button, H5, Icon, Inline, Stack, Tooltip, Text } from '@parallel-mono/components';
import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { FC, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { BN, BN_ZERO } from '@polkadot/util';
import { TokenInput, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { formatNumber } from '@parallel-mono/utils';

import { AssetRow } from '../../LendAndBorrowTable';
import {
  useBorrowLimit,
  useLiquidationFreeLimit,
  useMarketAssets,
  useRewardValues
} from '../../hooks';
import { HfProgressBar } from '../../components/HfProgressBar';

import { amountToBalanceByDecimals, isFloatLessThan } from '@/utils/calculations';
import { useTransactionFee, useModal } from '@/hooks';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import config, { RelayChainToken } from '@/config';
import { ConnectToWallet, DepositModal } from '@/components';
import { toFloorFixed, balanceFormatter } from '@/utils/format';
import { signAndSend } from '@/utils/txCall';
import { ratioToNumber } from '@/utils/utils';
import { isNumeric, isValidAmountInput } from '@/utils/validate';

interface ISupplyPaneProps {
  token: AssetRow;
  handleClose: Function;
  api: ApiPromise;
  account: KeyringPair;
}

const SupplyPane: FC<ISupplyPaneProps> = ({ token, handleClose, api, account }) => {
  const [supplyAmount, setSupplyAmount] = useState<number | null>(null);
  const [errorInputMessage, setErrorInputMessage] = useState<ReactNode>();
  const [processing, setProcessing] = useState(false);

  const availableAmount = token.availableBalance;
  const threshold = 1e-4;
  const isLendAll = isFloatLessThan(availableAmount - Number(supplyAmount), threshold);
  const toTXAmount = amountToBalanceByDecimals<BN>(
    isLendAll ? availableAmount : supplyAmount || 0,
    token.decimals,
    'bn'
  );

  const { assetsMarket } = useMarketAssets();
  const { supplyRewardApys } = useRewardValues();
  const { TxFeeTips } = useTxFeeValidation();
  const { usedRegularBorrow } = useBorrowLimit();

  const { openModal: openDepositModal, holder: DepositModalHolder } = useModal(
    DepositModal,
    {
      title: `Deposit ${config.relayChainToken} to Parallel`,
      size: '500px'
    },
    {
      asset: token
    }
  );

  const transactionFee = useTransactionFee(
    {
      api,
      tx: api.tx.loans.mint(token.assetId, toTXAmount)
    },
    [toTXAmount.toString()]
  );

  const { checkIsCollateralLiquidationFree, lfLiquidityLimit, getBorrowLimit } =
    useLiquidationFreeLimit();

  const isLiquidationFreeAsset = checkIsCollateralLiquidationFree(token.assetId);
  const borrowLimit = getBorrowLimit(isLiquidationFreeAsset);

  const marketInfo = assetsMarket?.[token.assetId];

  const canDepositToken = useMemo(
    () => token && (token.symbol === RelayChainToken.KSM || token.symbol === RelayChainToken.DOT),
    [token]
  );

  const handleConfirm = useCallback(() => {
    setProcessing(true);
    signAndSend({
      api,
      account,
      tx: api.tx.loans.mint(token.assetId, toTXAmount),
      txSuccessCb: () => {
        handleClose();
      },
      txProcessingCb: () => {
        handleClose();
      },
      txFailedCb: () => {
        setProcessing(false);
      }
    });
  }, [account, api, handleClose, toTXAmount, token.assetId]);

  const inputChangeHandler = useCallback(
    (value: number | null) => {
      setErrorInputMessage('');

      if (value && !isNumeric(value)) {
        value = supplyAmount;
      }

      // check if entered amount is greater than max allowed amount
      if (Number(value) > token.maxAvailableBalance) {
        setErrorInputMessage(
          <Inline gap="0.25rem">
            <Text data-testid="deposit-alert" skin="error">
              Insufficient balance on Parallel.{' '}
            </Text>
            {canDepositToken ? (
              <Button variant="link" onClick={() => openDepositModal()}>
                Deposit here.
              </Button>
            ) : null}
          </Inline>
        );
      }

      // check for negative numbers
      if (value !== null && Number(value) <= 0) {
        setErrorInputMessage('Please enter a value greater than zero');
      }

      if (
        isNumeric(value) &&
        marketInfo?.supplyCap &&
        amountToBalanceByDecimals<BigNumber>(
          new BigNumber(value?.toString() ?? '').plus(token.supplyMarket),
          token.decimals,
          'bigNumber'
        ).isGreaterThan(marketInfo.supplyCap.toString())
      ) {
        setErrorInputMessage('The supply amount has exceeded cap capped.');
      }

      if (isValidAmountInput(value?.toString() ?? '', 12, 6) || value === null) {
        setSupplyAmount(value);
      }
    },
    [canDepositToken, marketInfo, openDepositModal, supplyAmount, token]
  );

  const handleClickMax = useCallback(() => {
    if (token.maxAvailableBalance === 0) return;
    inputChangeHandler(toFloorFixed(token.maxAvailableBalance, 6));
  }, [inputChangeHandler, token]);

  const getSupplyCollateralValue = useCallback(
    value => {
      return (
        ratioToNumber(assetsMarket?.[token.assetId]?.collateralFactor ?? BN_ZERO) *
        token.price *
        Number(value)
      );
    },
    [assetsMarket, token.assetId, token.price]
  );

  const supplyValue = useMemo(
    () => getSupplyCollateralValue(supplyAmount),
    [getSupplyCollateralValue, supplyAmount]
  );

  const calcRegularBorrowLimit = useCallback(
    (changedValue: number) => {
      return isLiquidationFreeAsset
        ? borrowLimit - lfLiquidityLimit + Math.min(usedRegularBorrow, changedValue)
        : borrowLimit + changedValue;
    },
    [isLiquidationFreeAsset, lfLiquidityLimit, borrowLimit, usedRegularBorrow]
  );

  const newLfLiquidityLimit = useMemo(() => {
    return lfLiquidityLimit + Math.max(supplyValue - usedRegularBorrow, 0);
  }, [lfLiquidityLimit, supplyValue, usedRegularBorrow]);

  const newRegularBorrowLimit = useMemo(
    () => calcRegularBorrowLimit(supplyValue),
    [calcRegularBorrowLimit, supplyValue]
  );

  const infos = useMemo(
    () =>
      [
        {
          title: 'APY',
          value: formatNumber(token.supplyRate, { output: 'percent' })
        },
        supplyRewardApys[token.assetId]
          ? {
              title: `${config.nativeToken} Rewards`,
              tip: `You will earn an estimated ${config.nativeToken} farm reward for supplying this asset. Rewards can be claimed from your dashboard.`,
              value: formatNumber(supplyRewardApys[token.assetId] || 0, {
                output: 'percent',
                decimal: 1,
                threshold: { min: 0.01, max: 100 }
              })
            }
          : null,
        isLiquidationFreeAsset
          ? {
              title: 'Liquidation Free Limit',
              tip: 'This is the maximum amount you can borrow for a liquidation free loan with no risk of liquidation, after you add collateral. It depends on the value you have deposited and the available liquidity.',
              value: (
                <Inline gap="0.2rem">
                  <H5> {formatNumber(lfLiquidityLimit, { output: 'currency' })} </H5>
                  {!!supplyAmount && (
                    <>
                      <Icon name="arrowRight" />
                      <H5>{formatNumber(newLfLiquidityLimit, { output: 'currency' })}</H5>
                    </>
                  )}
                </Inline>
              )
            }
          : null,
        {
          title: 'Borrow Limit',
          tip: 'This is the maximum amount you can borrow, after you add collateral. It depends on the value you have deposited and the available liquidity.',
          value: (
            <Inline gap="0.2rem">
              <H5> {formatNumber(calcRegularBorrowLimit(0), { output: 'currency' })} </H5>
              {!!supplyAmount && (
                <>
                  <Icon name="arrowRight" />
                  <H5>{formatNumber(newRegularBorrowLimit, { output: 'currency' })}</H5>
                </>
              )}
            </Inline>
          )
        }
      ].filter(info => info) as InfoPanelProps['infos'],
    [
      calcRegularBorrowLimit,
      isLiquidationFreeAsset,
      lfLiquidityLimit,
      newLfLiquidityLimit,
      newRegularBorrowLimit,
      supplyAmount,
      supplyRewardApys,
      token
    ]
  );

  useAsyncEffect(() => {
    inputChangeHandler(supplyAmount);
  }, [token.maxAvailableBalance]);

  return (
    <Stack gap="1.5rem">
      <Stack gap="0.5rem">
        <TokenInput
          label={<H5>{token.symbol} Amount</H5>}
          hint={
            <Inline gap="0.2rem" alignItems="center">
              <Inline gap="0.5rem" alignItems="center">
                <Text skin="secondary">Available:</Text>
                <H5>{balanceFormatter(availableAmount)}</H5>
              </Inline>
              <Tooltip content="This is your available balance that can be supplied." />
            </Inline>
          }
          token={token.name.toUpperCase()}
          value={supplyAmount}
          onChange={inputChangeHandler}
          placeholder="0"
          onAction={handleClickMax}
          actionButtonText="Max"
          error={errorInputMessage}
        />
        {canDepositToken && !token.balance && account?.address && !errorInputMessage ? (
          <Alert type="info">
            <span data-testid="balance-zero-alert">
              You have 0 {config.relayChainToken} on Parallel.{' '}
              <Button variant="link" onClick={() => openDepositModal()}>
                deposit here.
              </Button>
            </span>
          </Alert>
        ) : null}
      </Stack>
      {Number(supplyAmount) < availableAmount && (
        <HfProgressBar
          isLiquidationFreeAsset={isLiquidationFreeAsset}
          addAccSupply={
            Number(supplyAmount) *
            token.price *
            ratioToNumber(marketInfo?.liquidationThreshold ?? BN_ZERO)
          }
        />
      )}

      <InfoPanel infos={infos} />

      {account ? (
        <Button
          size="large"
          onClick={() => handleConfirm()}
          block
          skin="primary"
          disabled={processing || !supplyAmount || Boolean(errorInputMessage)}
        >
          {!supplyAmount ? 'Enter an Amount' : 'Lend'}
        </Button>
      ) : (
        <ConnectToWallet block skin="secondary">
          Connect Wallet
        </ConnectToWallet>
      )}
      <TxFeeTips txFee={transactionFee} />
      {DepositModalHolder}
    </Stack>
  );
};

export default memo(SupplyPane);
