import { Chains } from '../types';

import { PhalaChainCurrency } from './types';

import config, { Chain } from '@/config';

export const TO_CHAINS = [Chains.Parallel];

export const CURRENCIES = {
  [Chains.Parallel]: [PhalaChainCurrency.PHA]
};

export const MINIMUM_TRANSFER_AMOUNT = {
  [Chain.PARALLEL]: {
    [PhalaChainCurrency.PHA]: 0.5
  },
  [Chain.HEIKO]: {
    [PhalaChainCurrency.PHA]: 0.5
  }
}[config.chain];

export const MAXIMUM_TX_FEE = {
  [Chain.PARALLEL]: 0.1,
  [Chain.HEIKO]: 0.1
}[config.chain];
