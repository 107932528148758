import { useCallback, useMemo, useState } from 'react';
import { find } from 'lodash';
import { createTestKeyring } from '@polkadot/keyring';

import { ContextData } from '../useAccount';
import useLoadAccounts from '../useAccount/useLoadAccount';

import useAsyncEffect from '@/hooks/useAsyncEffect';
import { isWalletInstalled } from '@/utils/extension';
import { ExtensionWallets, WalletType } from '@/contexts/WalletsContext/types';
import config from '@/config';

const SELECTED_ACCOUNT_STORAGE_KEY = 'parallel:account:selected';

const useParallelWallet = () => {
  const [walletInstalled, setWalletInstalled] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<ContextData['account']>();
  const [accountList, setAccountList] = useState<ContextData['accountList']>();
  const { keyring } = useLoadAccounts(ExtensionWallets.Parallel);

  const handleSwitchAccount: ContextData['handleSwitchAccount'] = useCallback((value, callback) => {
    setSelectedAccount(value);
    window.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, value.address);
    if (typeof callback === 'function') callback(value);
  }, []);

  useAsyncEffect(async () => {
    const installed = await isWalletInstalled(WalletType.Parallel);
    setWalletInstalled(installed);
  }, []);

  useAsyncEffect(async () => {
    const keyringPairs = keyring?.getPairs();
    if (keyringPairs) {
      // Only for the development env
      const testKeyring = createTestKeyring();
      const testingAccounts = config.DEBUG ? testKeyring.getPairs() : [];

      const allAccounts = [...keyringPairs, ...testingAccounts];
      const prevSelected = find(allAccounts, [
        'address',
        window.localStorage.getItem(SELECTED_ACCOUNT_STORAGE_KEY)
      ]);
      setAccountList(allAccounts);
      setSelectedAccount(prevSelected || allAccounts?.[0]);
    }
  }, [keyring]);

  const walletValue = useMemo(
    () => ({
      accounts: accountList,
      selectedAccount,
      switchAccount: handleSwitchAccount,
      walletInstalled
    }),
    [selectedAccount, accountList, handleSwitchAccount, walletInstalled]
  );

  return walletValue;
};

export default useParallelWallet;
