// @ts-nocheck
/* eslint-disable */

export const APYFormula = (weeklyVolume, tvl) => {
  const period = 52.1429;
  const fee = 0.003;
  const apy = ((weeklyVolume * period * fee) / tvl) * 100;
  return apy;
};

export const uilizationRatio = (totalBorrow, totalLiquidity) => {
  return (totalBorrow / totalLiquidity) * 100;
};

type lpRateType = {
  baseTokenPrice: number;
  quoteTokenPrice: number;
};

export const lpRate = ({ baseTokenPrice, quoteTokenPrice }: lpRateType): number => {
  return baseTokenPrice + quoteTokenPrice / 2;
};
