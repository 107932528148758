import dayjs from 'dayjs';

import { TOKEN_META } from '../constant';
import TableRowMenu from '../TableRowMenu';
import useCalculatedValue from '../useCalculatedValue';
import { StreamListItem } from '../useFetchList';

interface IProps {
  rowData: StreamListItem;
  cancelingIds: string[];
  onCancelClick: (id: string, value: number, symbol: string) => void;
}

const SendTableMenu = ({ rowData, cancelingIds, onCancelClick }: IProps) => {
  const { timestamp, remainingAfterCancel } = useCalculatedValue(rowData);

  const { id, endTime, assetId, status } = rowData;
  const isCancelDisable =
    !!status.Completed ||
    !timestamp ||
    dayjs(endTime).isBefore(dayjs(Number(timestamp))) ||
    cancelingIds.includes(id);
  const menus = [
    {
      key: 'cancel',
      text: 'Cancel Stream',
      onClick: () => {
        if (isCancelDisable) {
          return;
        }
        const { symbol } = TOKEN_META[assetId];
        onCancelClick(id, remainingAfterCancel.toNumber(), symbol);
      },
      disable: isCancelDisable
    }
  ];
  return <TableRowMenu menus={menus} />;
};

export default SendTableMenu;
