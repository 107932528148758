import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import { find, isFunction } from 'lodash';
import { Stack, Inline, SmallText, BigText, H5, Modal, Tag } from '@parallel-mono/components';
import { KeyringPair } from '@polkadot/keyring/types';

import { ToastBody, Link, AccountImage } from '@/components';
import { truncateTextMid } from '@/utils/format';
import { useAccount } from '@/hooks/useAccount';
import { WalletType, walletList } from '@/contexts/WalletsContext';

const AccountListWrapper = styled(Stack)`
  max-height: 36rem;
  overflow: auto;
  padding-top: 0.5rem;
`;

const AccountImageWrapper = styled(Stack).attrs({ justifyContent: 'center' })`
  cursor: pointer;
  padding-right: 1rem;
  padding: 0.125rem;
  border-radius: 50%;
  box-shadow: var(--box-shadow01);
`;

const AccountItem = styled(Inline).attrs({
  inset: '1rem 1.5rem',
  justifyContent: 'space-between'
})<{ selected: boolean }>`
  border-radius: 1rem;
  cursor: pointer;
  border: 1px solid ${({ selected }) => `${selected ? 'var(--clr-green)' : 'var(--clr-gray06)'}`};
`;

const AccountDetail = styled(Inline)`
  > a {
    &:hover {
      &:after {
        content: '';
        display: block;
        height: 1px;
        margin: -3px auto 0px auto;
        transition: all 0.2s ease;
      }
    }
  }
`;

const AccountName = styled(H5)`
  max-width: 8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Disclaimer = () => {
  return (
    <SmallText skin="secondary">
      {'Join our '}
      <Link href="https://discord.com/invite/buKKx4dySW" target="_blank" rel="noreferrer">
        Discord
      </Link>
      {' and '}
      <Link href="https://t.me/parallelfi" target="_blank" rel="noreferrer">
        Telegram
      </Link>
      {' to stay updated'}
    </SmallText>
  );
};

interface SelectAccountProps {
  walletType: WalletType;
  opened?: boolean;
  closeCallback: () => void;
}

const SelectAccount = memo<SelectAccountProps>(({ opened = false, closeCallback, walletType }) => {
  const { account, accountList, handleSwitchAccount } = useAccount();
  const [copyState, copyToClipboard] = useCopyToClipboard();

  const handleClosed = () => {
    if (isFunction(closeCallback)) {
      closeCallback();
    }
  };
  const switchAccount = (selectedAccount: KeyringPair) => {
    handleSwitchAccount(selectedAccount);
  };

  const walletMapping = useMemo(() => find(walletList, ['type', walletType])!, [walletType]);

  useEffect(() => {
    const { value, error } = copyState;
    if (value) {
      toast(<ToastBody type="success" text="Address copied!" />, { autoClose: 3000 });
    }
    if (error) {
      toast.error(<ToastBody type="error" text={error.message} />, { autoClose: 3000 });
    }
  }, [copyState]);

  const sortedAccountList = useMemo(
    () => accountList.sort(item => (item.address === account.address ? -1 : 1)),
    [account, accountList]
  );

  return (
    <Modal title="Switch" isOpen={opened} onClose={handleClosed} size="500px">
      <AccountListWrapper gap="1rem">
        <Inline justifyContent="space-between" inset="0 0.25rem">
          <Inline gap="0.5rem" alignItems="center">
            {walletMapping.icon}
            <H5>{walletMapping.walletName}</H5>
          </Inline>
          <BigText skin="secondary">{accountList.length} Accounts</BigText>
        </Inline>
        {sortedAccountList.map((item, index) => {
          const {
            meta: { name, isTesting },
            address
          } = item;
          return (
            <AccountItem
              key={address}
              selected={account.address === address}
              onClick={() => switchAccount(item)}
            >
              <Inline alignItems="center" gap="1rem">
                <AccountImageWrapper onClick={() => copyToClipboard(address)}>
                  <AccountImage type={walletType} address={address} index={index} />
                </AccountImageWrapper>
                <AccountDetail alignItems="center" gap="0.25rem">
                  <AccountName>{name}</AccountName>
                  <BigText skin="secondary">{truncateTextMid(address)}</BigText>
                  {isTesting && <SmallText skin="secondary">Test Account</SmallText>}
                </AccountDetail>
              </Inline>
              <Stack justifyContent="center">
                {account.address === address && (
                  <Tag skin="success" size="small">
                    Active
                  </Tag>
                )}
              </Stack>
            </AccountItem>
          );
        })}
      </AccountListWrapper>
      <Inline alignItems="center" justifyContent="center" inset="25px 0 15px 0">
        <Disclaimer />
      </Inline>
    </Modal>
  );
});
export default SelectAccount;
