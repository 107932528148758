import { useState, useMemo, useEffect, useCallback, memo, FC, useContext } from 'react';
import { toNumber, get } from 'lodash';
import styled from 'styled-components';
import { Rate, Balance, Market } from '@parallel-finance/types/interfaces';
import { BN_ZERO, stringLowerFirst } from '@polkadot/util';
import { useDebounce } from 'react-use';
import { Inline, Stack, H5, Button } from '@parallel-mono/components';
import { TokenInput } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';
import { Option } from '@polkadot/types';
import { formatNumber } from '@parallel-mono/utils';

import { StakingScopeContext } from '../Context';
import { useUnstakeBestRoutes } from '../hooks/useUnstakeBestRoutes';

import StakingDetails from './StakingDetails';
import UnStakeReminder from './UnStakeRemainder';
import InstantUnstakeToggle from './InstantUnstakeToggle';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import { rateToNumber } from '@/utils/utils';
import { useAccount, useApiCall, useChainConnections, useModal } from '@/hooks';
import useMarketAssets from '@/pages/MoneyMarket/hooks/useMarketAssets';
import useAccountAssetsDeposit from '@/pages/MoneyMarket/hooks/useAccountAssetsDeposit';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { getBondingDuration, getEraLength, getMinUnstake } from '@/pages/Staking/apis/staking';
import { signAndSend, txFee } from '@/utils/txCall';
import { ConnectToWallet, DepositModal } from '@/components';
import { RelayChainToken } from '@/config';
import { isValidAmountInput } from '@/utils/validate';
import { toFloorFixed } from '@/utils/format';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

const Wrapper = styled(Stack)`
  width: 100%;
  > * {
    width: 100%;
  }
  .shallow {
    color: var(--clr-gray01);
    font-weight: normal;
  }
`;

export enum UnstakeType {
  FastUnstake = 'Loans',
  NormalUnstake = 'RelayChain'
}

const DEBOUNCE_TIME = 200;

const ProcessUnstake: FC = memo(() => {
  const [inputValue, setInputValue] = useState<number | null>(null);
  const [debouncedInputValue, setDebouncedValue] = useState('');
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const { TxFeeTips } = useTxFeeValidation();
  const [enterToStake, setEnterToStake] = useState(false);
  const [debouncedEnterToStake, setDebouncedEnterToStake] = useState(false);
  const { currencies } = useContext(StakingScopeContext);
  const {
    parachain: { api }
  } = useChainConnections();

  const {
    generateBestRoutes,
    receiveAmount,
    unstakeIsReady,
    unstakeFee,
    route: routeType
  } = useUnstakeBestRoutes();

  const [instantUnstake, setInstantUnstake] = useState(false);

  const fastUnstakeFee = useMemo(
    () => api.consts.liquidStaking.loansInstantUnstakeFee || BN_ZERO,
    [api]
  );
  const { nativeAssetInfo: nativeAsset } = useCurrentAccountNativeAssetInfo();

  const stakingAssetTotalBorrows =
    useApiCall<number>(api.query.loans.totalBorrows, [currencies.stakingAsset.assetId], {
      transform: (value: Balance) =>
        balanceToAmountByDecimal(value, nativeAsset.decimals, 'number') || 0
    }) || 0;

  const borrowCap = useApiCall<number>(api.query.loans.markets, [currencies.stakingAsset.assetId], {
    transform: (market: Option<Market>) => {
      if (market.unwrap().borrowCap && nativeAsset.decimals) {
        return balanceToAmountByDecimal(
          market.unwrap().borrowCap || 0,
          nativeAsset.decimals,
          'number'
        );
      }
      return 0;
    }
  });

  const exchangeRate = useApiCall<number>(api.query.liquidStaking.exchangeRate, [], {
    transform: (rate: Rate) => rateToNumber(rate)
  });

  const eraLengthVal = getEraLength(api);
  const bondingDurationVal = getBondingDuration(api);
  const minInput = balanceToAmountByDecimal<number>(
    getMinUnstake(api),
    currencies.stakingAsset.decimals,
    'number'
  );
  const minDeposit = Math.max(
    minInput,
    currencies.stakingAsset?.symbol === RelayChainToken.DOT ? 5 : 0.1
  );
  const stakingToken = currencies.stakingAsset;
  const liquidToken = currencies.liquidAsset;
  const liquidTokenSymbol = stringLowerFirst(liquidToken.symbol);
  const token = useMemo(() => liquidToken, [liquidToken]);

  const balanceAmount = useMemo(() => get(token, 'balance', 0), [token]);
  const debouncedAmount = useMemo(
    () => (Number.isNaN(toNumber(debouncedInputValue)) ? 0 : toNumber(debouncedInputValue)),
    [debouncedInputValue]
  );
  const unstakeMintVal = useMemo(
    () => (exchangeRate ? debouncedAmount * exchangeRate : 0),
    [debouncedAmount, exchangeRate]
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const { account } = useAccount();

  useEffect(() => {
    setInputValue(null);
  }, [account]);

  const generateTxs = useCallback(async () => {
    const unstakeTx = await generateBestRoutes(
      debouncedAmount,
      unstakeMintVal,
      stakingToken,
      liquidToken,
      instantUnstake
    );
    return unstakeTx;
  }, [
    generateBestRoutes,
    debouncedAmount,
    unstakeMintVal,
    stakingToken,
    liquidToken,
    instantUnstake
  ]);

  const getFee = useCallback(async () => {
    const tx = await generateTxs();

    if (tx) {
      const fee = await txFee({
        tx,
        account
      });

      setTransactionFee(fee);
    }
  }, [account, generateTxs]);

  useDebounce(
    () => {
      if (account) {
        getFee();
      }
    },
    DEBOUNCE_TIME,
    [account, generateTxs]
  );

  const maxAmount = useMemo(() => balanceAmount, [balanceAmount]);

  const showMaximumError = useMemo(
    () => debouncedInputValue !== '' && debouncedAmount > balanceAmount,
    [debouncedInputValue, debouncedAmount, balanceAmount]
  );

  const showMinimumError = useMemo(
    () => debouncedInputValue !== '' && !showMaximumError && debouncedAmount < minInput,
    [debouncedInputValue, debouncedAmount, minInput, showMaximumError]
  );
  const showBorrowCapError = useMemo(() => {
    if (exchangeRate && debouncedAmount) {
      const totalStakingAmount = new BigNumber(stakingAssetTotalBorrows)
        .plus(
          new BigNumber(1)
            .minus(new BigNumber(rateToNumber(fastUnstakeFee)))
            .times(debouncedAmount)
            .times(exchangeRate)
        )
        .toNumber();
      return borrowCap! <= totalStakingAmount;
    }
    return false;
  }, [borrowCap, debouncedAmount, exchangeRate, fastUnstakeFee, stakingAssetTotalBorrows]);

  const isDisabled = useMemo(
    () =>
      debouncedInputValue === '' ||
      showMaximumError ||
      showMinimumError ||
      showBorrowCapError ||
      isProcessing ||
      !unstakeIsReady ||
      (instantUnstake && !unstakeFee),
    [
      debouncedInputValue,
      instantUnstake,
      isProcessing,
      showBorrowCapError,
      showMaximumError,
      showMinimumError,
      unstakeFee,
      unstakeIsReady
    ]
  );

  useDebounce(
    () => {
      setDebouncedValue(inputValue?.toString() ?? '');
    },
    DEBOUNCE_TIME,
    [inputValue]
  );

  useDebounce(
    () => {
      setDebouncedEnterToStake(enterToStake);
    },
    DEBOUNCE_TIME,
    [enterToStake]
  );

  const { openModal, holder } = useModal(
    DepositModal,
    {
      title: `Deposit ${stakingToken.symbol} to Parallel`,
      size: '500px'
    },
    {
      minDeposit,
      asset: stakingToken
    }
  );

  const inputValueChanged = (value: number | null) => {
    setEnterToStake(false);
    if (isValidAmountInput(value?.toString() ?? '', 10, 4) || value === null) {
      setInputValue(value);
    }
  };

  const handleMaxButton = () => {
    setInputValue(toFloorFixed(balanceAmount, 4));
  };

  const handleUnstake = useCallback(async () => {
    setEnterToStake(false);
    if (!token || isDisabled) {
      return;
    }
    setIsProcessing(true);
    const tx = await generateTxs();
    if (tx)
      signAndSend({
        api,
        account,
        tx,
        txSuccessCb: () => {
          setIsProcessing(false);
        },
        txProcessingCb: () => {
          setInputValue(null);
        },
        txFailedCb: () => {
          setIsProcessing(false);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isDisabled, api, account, generateTxs]);

  useEffect(() => {
    if (debouncedEnterToStake) {
      handleUnstake();
    }
  }, [debouncedEnterToStake, handleUnstake]);

  const OpenDepositModalBtn = useMemo(
    () => (
      <Button
        variant="link"
        onClick={() => {
          openModal();
        }}
      >
        Deposit
      </Button>
    ),
    [openModal]
  );

  const renderErrorMsg = useCallback(() => {
    if (showMaximumError) {
      return <>Insufficient {token.symbol} balance.&nbsp;</>;
    }
    if (showMinimumError) {
      return (
        <>
          Minimum unstake amount is {minInput} {token.symbol}.&nbsp;
          {maxAmount < minInput ? OpenDepositModalBtn : null}
        </>
      );
    }
    if (showBorrowCapError) {
      return 'Exceed max instant unstake amount';
    }
    return '';
  }, [
    OpenDepositModalBtn,
    maxAmount,
    minInput,
    showBorrowCapError,
    showMaximumError,
    showMinimumError,
    token
  ]);

  const { marketAssetIds } = useMarketAssets();
  const { supplies } = useAccountAssetsDeposit(marketAssetIds);

  const showReminder = useMemo(
    () => supplies && supplies[currencies.liquidAsset.assetId],
    [supplies, currencies]
  );

  return (
    <Stack gap="1.5rem">
      <Wrapper alignItems="center">
        <Stack gap="0.75rem">
          {showReminder && <UnStakeReminder token={currencies.liquidAsset.symbol} />}
          <TokenInput
            label={<H5>Amount</H5>}
            hint={
              <Inline gap="0.25rem" alignItems="center">
                <H5>
                  <span className="shallow">Available: </span>
                  {`${formatNumber(balanceAmount, { decimal: 4 })} ${liquidTokenSymbol}`}
                </H5>
              </Inline>
            }
            placeholder="0"
            inputProps={{ autoFocus: true }}
            value={inputValue}
            token={token.symbol.toUpperCase()}
            onChange={inputValueChanged}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setEnterToStake(true);
              }
            }}
            actionButtonText="Max"
            onAction={handleMaxButton}
            error={renderErrorMsg()}
            actionButtonProps={{ disabled: isProcessing }}
          />
        </Stack>
      </Wrapper>
      <InstantUnstakeToggle
        onChange={checked => setInstantUnstake(checked)}
        checked={instantUnstake}
      />
      <StakingDetails
        details={{
          type: 'unStaking',
          stakingToken: stakingToken.symbol,
          unstakeMintVal: receiveAmount,
          eraLengthVal,
          bondingDurationVal: instantUnstake ? BN_ZERO : bondingDurationVal,
          unstakeFee,
          instantUnstake,
          routeType
        }}
      />
      {account ? (
        <Button block skin="primary" size="large" disabled={isDisabled} onClick={handleUnstake}>
          {debouncedInputValue ? 'Unstake' : 'Enter an amount'}
        </Button>
      ) : (
        <ConnectToWallet size="large" block skin="secondary">
          Connect Wallet to Unstake
        </ConnectToWallet>
      )}
      {!isDisabled && <TxFeeTips txFee={transactionFee} />}
      {holder}
    </Stack>
  );
});

export default ProcessUnstake;
