import { FC } from 'react';
import styled from 'styled-components';
import { Stack, Inline, H3, H5, H6, Text } from '@parallel-mono/components';
import { HostedImage } from '@parallel-mono/business-components';

import { Menu } from '../Menus';

import { Link } from '@/components';
import { useDevice } from '@/hooks';

const ComingSoonText = styled(Inline).attrs({
  inset: '2px 0.75rem'
})`
  background-color: var(--clr-gray04);
  border-radius: 2rem;
`;
const IconWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--clr-white);
  box-shadow: 0px 2px 6px rgba(58, 78, 88, 0.1);
`;

const Wrapper = styled(Stack).attrs({
  alignItems: 'center',
  gap: '1rem'
})`
  position: relative;
  margin-top: 1rem;
`;
const BackImageWrapper = styled(Inline)`
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;

type Props = Menu & {
  iconBgColor?: string;
  onBack: () => void;
};

const Icon = styled(HostedImage)`
  width: 1.5rem;
  height: 1.5rem;
`;

const CommingSoon: FC<Props> = ({
  onBack,
  icon,
  title,
  name,
  iconBgColor = 'var(--clr-white)',
  description
}) => {
  const { isMobile } = useDevice();
  return (
    <Wrapper inset={isMobile ? '1rem 0 0' : '1rem 4rem 0 4rem'}>
      <BackImageWrapper gap={isMobile ? '0.5rem' : '1.5rem'} onClick={onBack}>
        <Icon name="arrowLeft" />
        <H5>Back</H5>
      </BackImageWrapper>
      <ComingSoonText>
        <H6>Coming Soon</H6>
      </ComingSoonText>
      <IconWrapper style={{ backgroundColor: iconBgColor }}>
        <Icon name={icon} />
      </IconWrapper>
      <H3>{title || name}</H3>
      {description && (
        <Text skin="secondary" style={{ textAlign: 'center', marginTop: '0.5rem' }}>
          {description}
        </Text>
      )}
      <Link
        style={{ marginTop: '1.5rem' }}
        href="https://twitter.com/ParallelFi"
        size="medium"
        variant="button-secondary"
      >
        Join Community
      </Link>
    </Wrapper>
  );
};

export default CommingSoon;
