import { isNil, chunk, isNaN } from 'lodash';

export const parseStringToNumber = (str: string): number | null => {
  const stringWithoutComma = str.split(',').join('');
  const result = parseFloat(stringWithoutComma);
  return isNaN(result) ? null : result;
};

export const parseNumberToString = (num?: number | null, withThousandSeparator = false): string => {
  if (isNil(num) || isNaN(num)) return '';
  const raw = JSON.stringify(num);
  if (!withThousandSeparator) {
    return raw;
  }
  const [integer, decimals = ''] = raw.split('.');
  const groups = chunk(integer.split('').reverse(), 3)
    .map(it => it.reverse())
    .reverse();
  return `${groups.map(it => it.join('')).join(',')}${decimals ? '.' : ''}${decimals}`;
};

export const formatNumberStr = (str?: string, withThousandSeparator = false) => {
  if (!str) return '';
  const minus = str?.startsWith('-');
  const absoluteNumberStr = formatAbsoluteNumberStr(
    minus ? str.substring(1) : str,
    withThousandSeparator
  );
  return `${minus ? '-' : ''}${absoluteNumberStr}`;
};

const formatAbsoluteNumberStr = (str: string, withThousandSeparator = false) => {
  if (str === '.') {
    return '0.';
  }
  const [integerPart, decimalPart] = str.split('.');
  const intergerValue = parseStringToNumber(integerPart);
  return `${parseNumberToString(intergerValue, withThousandSeparator)}${
    decimalPart === undefined ? '' : '.'
  }${decimalPart ?? ''}`;
};
