import { mergeWith } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Tab, TabsBase, TabsBaseProps } from './TabsBase';
import { genericMemo } from '../../utils/genericMemo';

export type TabsProps<T extends Tab> = TabsBaseProps<T>;

const tabsClassNames = {
  slot: 'tabs-slot',
  slider: 'tabs-slider',
  tab: 'tabs-tab',
  activeTab: 'tabs-active-tab',
};

const StyledTabsBase = styled(TabsBase)`
  .${tabsClassNames.slot} {
    background-color: ${({ theme }) => theme.skin.background.slot};
    padding: ${({ theme }) => theme.spacing(1)};
  }
  .${tabsClassNames.slider} {
    background-color: ${({ theme }) => theme.skin.background.main};
  }
  .${tabsClassNames.tab} {
    color: ${({ theme }) => theme.skin.text.secondary};
    padding: ${({ theme }) => theme.spacing(2)};
    font-weight: ${({ theme }) => theme.typography.fontWeight.bold};
  }
  .${tabsClassNames.activeTab} {
    color: ${({ theme }) => theme.skin.text.primary};
  }
` as typeof TabsBase;

export const Tabs = genericMemo(function <T extends Tab = Tab>({
  classNames,
  ...otherProps
}: TabsProps<T>) {
  const mergedClassNames = useMemo(
    () => mergeWith(tabsClassNames, classNames, (c1, c2) => [c1, c2].filter(Boolean).join(' ')),
    [classNames]
  );
  return <StyledTabsBase classNames={mergedClassNames} {...otherProps} />;
});
