import { menuIcons } from './icons/menu';
import { regularIcons } from './icons/regular';

export * from './icons/menu';
export * from './icons/regular';
export default { ...menuIcons, ...regularIcons };

export const imagePaths = {
  apps: 'apps',
  closeTip: 'close-tip',
  doneTip: 'done-tip',
  dropdown: 'dropdown',
  paraLogo: 'para-logo',
  omniLogo: 'omni-logo',
  auctionHeaderBg: 'auction-header-bg',
  gradientBg: 'gradient-bg',
  angelRight: 'angel-right',
  parallel: 'parallel',
  metaMask: 'meta-mask',
  crossChainDotBanner: 'cross-chain-dot-banner',
  crossChainKsmBanner: 'cross-chain-ksm-banner',
  wallet: 'wallet',
  warning: 'warning',
  blackWarning: 'black-warning',
  ammBG: 'amm-bg',
  cog: 'cog',
  hint: 'hint',
  streamInfo: 'steam-info',
  moreVertical: 'more-vertical',
  right: 'right',
  up: 'up',
  down: 'down',
  arrowLeft: 'arrow-left',
  arrowRight: 'arrow-right',
  arrowDown: 'arrow-down',
  calendar: 'calendar',
  check: 'check',
  clock: 'clock',
  close: 'close',
  polkadot: 'polkadot',
  kusama: 'kusama',
  ASTR: 'ASTR',
  HKO: 'HKO',
  GLMR: 'GLMR',
  KSM: 'KSM',
  USDT: 'USDT',
  USDC: 'USDC',
  XKSM: 'XKSM',
  DOT: 'DOT',
  XDOT: 'XDOT',
  CDOT: 'CDOT',
  PARA: 'PARA',
  SOL: 'SOL',
  tooltip: 'tooltip',
  shield: 'shield',
  heart: 'heart',
  heart1: 'heart-1',
  auctionBanner: 'auction-banner',
  hyaldot: 'hyaldot',
  hyalksm: 'hyalksm',
  heikoLogo: 'heiko-logo',
  dollar: 'dollar',
  stack: 'stack',
  empty: 'empty',
  accountIcon0: 'account-icon0',
  accountIcon1: 'account-icon1',
  accountIcon2: 'account-icon2',
  accountIcon3: 'account-icon3',
  accountIcon4: 'account-icon4',
  spinner: 'spinner',
  alertWarning: 'alert-warning',
  alertInfo: 'alert-info',
  referral: 'referral',
  award: 'award',
  externalLink: 'external-link',
  rewardRule: 'reward-rule',
  crowdloanBanner: 'crowdloan-banner',
  security: 'security',
  moonbeamSign: 'moonbeam-sign',
  penSign: 'pen-sign',
  connectWallet: 'connect-wallet',
  earn: 'earn',
  share: 'share',
  trendingUp: 'trending-up',
  moneyverse: 'moneyverse',
  depositToParallelWallet: 'deposit-to-parallel-wallet',
  plus: 'plus',
  fallback: 'fallback',
  checkMark: 'check-mark',
  dropdownArrow: 'dropdown-arrow',
  logo: 'logo',
  transfer: 'transfer',
  migrateDollar: 'migrate-dollar',
  depositDotToParallel: 'deposit-dot-to-parallel',
  depositKsmToParallel: 'deposit-ksm-to-parallel',
  accountBg: 'account-bg',
};
