import { TypographyTheme } from '../types';

export const defaultTypography: TypographyTheme = {
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
  },
  display1: {
    fontSize: '48px',
    lineHeight: '62px',
  },
  display2: {
    fontSize: '36px',
    lineHeight: '48px',
  },
  header1: {
    fontSize: '32px',
    lineHeight: '48px',
  },
  header2: {
    fontSize: '24px',
    lineHeight: '36px',
  },
  header3: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  header4: {
    fontSize: '18px',
    lineHeight: '28px',
  },
  header5: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  header6: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  bodyBig: {
    fontSize: '18px',
    lineHeight: '28px',
  },
  body: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  bodySmall: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  button: {
    lineHeight: '24px',
    fontWeight: 500,
  },
};
