import { FC } from 'react';
import { BN } from '@polkadot/util';

import { Pool } from '@/pages/Farm/types';
import { useChainConnections } from '@/hooks';
import InputModal from '@/pages/Farm/Modal/InputModal';
import { amountToBalanceByDecimals } from '@/utils/calculations';

type Props = {
  closeModal: () => void;
  data: Pool;
};

const WithdrawModal: FC<Props> = ({ data, closeModal }) => {
  const {
    parachain: { api }
  } = useChainConnections();

  const generateTransactions = (withdrawAmount: number) => {
    return api.tx.farming.withdraw(
      data.asset.id,
      data.rewardAsset.id,
      data.lockDuration,
      amountToBalanceByDecimals<BN>(withdrawAmount, data.asset.decimals, 'bn')
    );
  };

  return (
    <InputModal
      closeModal={closeModal}
      availableTag="Available"
      availableAmount={data.user.deposited}
      assetName={data.asset.name}
      information={[]}
      generateTx={generateTransactions}
      insufficientText="Your withdraw amount is greater than your deposit balance."
    />
  );
};

export default WithdrawModal;
