// @ts-nocheck
/* eslint-disable */

import axios from 'axios';

import { fixDateGapsInTVLData, notInBlackList, sortArrayByDate } from './helpers/others';
import { getAssets } from './asset';
import { getPricingData } from './pricing';
import { calculateSwaps } from './selectors/swaps';
import { AmmTvl } from './selectors/ammTvl';
import { calculateVolume } from './selectors/volume';
import { lastPools } from './selectors/lastPools';
import { lastTokens } from './selectors/lastTokens';
import { lastTransactions } from './selectors/lastTransactions';
import { tokenSnapshots } from './selectors/tokenSnapsshoots';
import { dailyVolume } from './selectors/dailyVolume';
import { poolName } from './selectors/poolName';
import { AmmApiType, GetAmmResponseType, GetAmmResponse } from './types';

import config from '@/config';

const ammApi = async ({ tokenId, selectedNetwork }: AmmApiType) => {
  const getAMM: GetAmmResponseType = async () => {
    const { data } = await axios.get<GetAmmResponse>(
      `${config.ANALYSIS_SERVICE_URL}/api/amm?network=${selectedNetwork}`
    );
    return data;
  };

  const assets = await getAssets();
  const ammAPI = await getAMM();
  const pricingData = await getPricingData();

  const ammVolume = ammAPI?.volume;
  const poolInfo = ammAPI?.poolMetadatas;

  const poolNames = await poolName(poolInfo, assets);

  const poolList = ammAPI?.poolSnapshots;
  const allTrades = ammAPI?.allTrades?.list?.trades;
  const allVolumes = ammAPI?.allTrades?.list?.volumes;

  const AllSwaps = await calculateSwaps(poolList, poolInfo, assets, pricingData, tokenId);
  const dailyVolumes = await dailyVolume(allVolumes, assets, pricingData, poolNames, AllSwaps);
  const ammTVL = await AmmTvl(AllSwaps);
  const poolsSnapshot = await lastPools(AllSwaps, ammVolume);
  const historicalToken = await lastTokens(AllSwaps);
  const tokenSnapshot = await tokenSnapshots(historicalToken, pricingData, dailyVolumes);
  const volume = await calculateVolume(AllSwaps, dailyVolumes, poolInfo);
  const transactionsSnapshoot = await lastTransactions(allVolumes, assets, pricingData);
  return {
    pools: AllSwaps,
    amm_tvl: fixDateGapsInTVLData(
      sortArrayByDate(notInBlackList(ammTVL, selectedNetwork)),
      true,
      false
    ),
    volume: fixDateGapsInTVLData(
      sortArrayByDate(notInBlackList(volume, selectedNetwork)),
      true,
      false
    ),
    snapshots: {
      pools: poolsSnapshot,
      tokens: tokenSnapshot
    },
    allTrades,
    assets,
    pricingData,
    poolInfo,
    dailyVolume: dailyVolumes,
    tokensHistorical: historicalToken,
    transactions: transactionsSnapshoot
  };
};

export default ammApi;
