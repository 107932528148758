import { memo, useRef, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Stack } from '@parallel-mono/components';

import PageWrapper from '../PageWrapper';

import Validators from './Validators';
import UpdateStake from './UpdateStake';
import PendingUnstake from './PendingUnstake';
import FAQ from './FAQ';
import StakingContextWraper from './Context';
import CountDown from './subComponents/CountDown';
import TVLBar from './subComponents/TVLBar';
import { getElementTop } from './helpers/getElementTop';

import LoadingStatus from '@/pages/Staking/LoadingStatus';
import { Chains } from '@/hooks';

const StakingContainer = styled.div`
  position: relative;
`;

const Wrapper = styled(Stack)`
  position: relative;
  max-width: 480px;
  margin: 0 auto;
`;

export const RightSidebar = styled(Stack)<{ top: number }>`
  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: calc(50% + 20rem);
  width: 20rem;
  overflow-y: auto;
  margin-top: 0;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const Staking = () => {
  const stakingRef = useRef<HTMLDivElement | null>(null);
  const [top, setTop] = useState(120);
  useLayoutEffect(() => {
    if (stakingRef?.current) {
      setTop(getElementTop(stakingRef.current));
    }
  }, [stakingRef]);

  return (
    <StakingContextWraper>
      <StakingContainer ref={stakingRef}>
        <Wrapper>
          <UpdateStake />
          <PendingUnstake />
          <Validators />
          <FAQ />
        </Wrapper>
      </StakingContainer>
      <RightSidebar top={top}>
        <TVLBar />
        <CountDown />
      </RightSidebar>
    </StakingContextWraper>
  );
};

export default memo(() => (
  <PageWrapper
    initialChains={[Chains.ParaChain, Chains.RelayChain]}
    loadingComponent={<LoadingStatus />}
  >
    <Staking />
  </PageWrapper>
));
