import { ChainIcon } from '@parallel-mono/business-components';

import { StatemintChainCurrency } from './chains/statemint/types';
import { AcalaChainCurrency } from './chains/acala/types';
import { InterlayChainCurrency } from './chains/interlay/types';
import { Currency } from './types';
import { ChainDetail, Chains } from './chains/types';
import { PhalaChainCurrency } from './chains/phala/types';
import { PolkadotChainCurrency } from './chains/polkadot/types';

import config, { Chain, RelayChainToken } from '@/config';

export const CURRENCY_DETAILS: {
  [key in Currency]: {
    name: string;
    icon: string;
    key: Currency;
  };
} = {
  [RelayChainToken.KSM]: {
    name: 'KSM',
    key: PolkadotChainCurrency.KSM,
    icon: 'KSM'
  },
  [RelayChainToken.DOT]: {
    name: 'DOT',
    key: PolkadotChainCurrency.DOT,
    icon: 'DOT'
  },
  [AcalaChainCurrency.KAR]: {
    name: 'KAR',
    key: AcalaChainCurrency.KAR,
    icon: 'KAR'
  },
  [AcalaChainCurrency.LKSM]: {
    name: 'LKSM',
    key: AcalaChainCurrency.LKSM,
    icon: 'LKSM'
  },
  [PhalaChainCurrency.PHA]: {
    name: 'PHA',
    key: PhalaChainCurrency.PHA,
    icon: 'PHA'
  },
  [AcalaChainCurrency.ACA]: {
    name: 'ACA',
    key: AcalaChainCurrency.ACA,
    icon: 'ACA'
  },
  [AcalaChainCurrency.LDOT]: {
    name: 'LDOT',
    key: AcalaChainCurrency.LDOT,
    icon: 'LDOT'
  },
  [AcalaChainCurrency.AUSD]: {
    name: 'aUSD',
    key: AcalaChainCurrency.AUSD,
    icon: 'AUSD'
  },
  [AcalaChainCurrency.LCDOT]: {
    name: 'lcDOT',
    key: AcalaChainCurrency.LCDOT,
    icon: 'LCDOT'
  },
  [StatemintChainCurrency.USDT]: {
    name: 'USDT',
    key: StatemintChainCurrency.USDT,
    icon: 'USDT'
  },
  [InterlayChainCurrency.INTR]: {
    name: 'INTR',
    key: InterlayChainCurrency.INTR,
    icon: 'INTR'
  },
  [InterlayChainCurrency.IBTC]: {
    name: 'IBTC',
    key: InterlayChainCurrency.IBTC,
    icon: 'IBTC'
  },
  [InterlayChainCurrency.KINT]: {
    name: 'KINT',
    key: InterlayChainCurrency.KINT,
    icon: 'KINT'
  },
  [InterlayChainCurrency.KBTC]: {
    name: 'KBTC',
    key: InterlayChainCurrency.KBTC,
    icon: 'KBTC'
  }
};

export const CHAIN_DETAILS: Record<Chains, ChainDetail> = {
  [Chain.HEIKO]: {
    [Chains.Acala]: {
      key: Chains.Acala,
      name: 'Karura',
      icon: 'KAR'
    },
    [Chains.Parallel]: {
      key: Chains.Parallel,
      name: 'Heiko',
      icon: 'HKO'
    },
    [Chains.Polkadot]: {
      key: Chains.Polkadot,
      name: 'Kusama',
      icon: 'KSM'
    },
    [Chains.Phala]: {
      key: Chains.Phala,
      name: 'Khala',
      icon: 'PHA'
    },
    [Chains.Statemint]: {
      key: Chains.Statemint,
      name: 'AssetHub',
      icon: <ChainIcon name="statemine" size="small" />
    },
    [Chains.Interlay]: {
      key: Chains.Interlay,
      name: 'Kintsugi',
      icon: 'KINT'
    }
  },
  [Chain.PARALLEL]: {
    [Chains.Acala]: {
      key: Chains.Acala,
      name: 'Acala',
      icon: 'ACA'
    },
    [Chains.Parallel]: {
      key: Chains.Parallel,
      name: 'Parallel',
      icon: 'PARA'
    },
    [Chains.Polkadot]: {
      key: Chains.Polkadot,
      name: 'Polkadot',
      icon: 'DOT'
    },
    [Chains.Phala]: {
      key: Chains.Phala,
      name: 'Phala',
      icon: 'PHA' // TODO should use the correct chain icon
    },
    [Chains.Statemint]: {
      key: Chains.Statemint,
      name: 'AssetHub',
      icon: <ChainIcon name="statemine" size="small" />
    },
    [Chains.Interlay]: {
      key: Chains.Interlay,
      name: 'Interlay',
      icon: 'INTR'
    }
  }
}[config.chain];
