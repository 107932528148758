// Polkadot
import { ReactComponent as polkadotCrossChainMenu } from './polkadot/polkadot-cross-chain.svg';
import { ReactComponent as polkadotDaoFiMenu } from './polkadot/polkadot-dao-fi.svg';
import { ReactComponent as polkadotMoneyMarketMenu } from './polkadot/polkadot-money-market.svg';
import { ReactComponent as polkadotStakingMenu } from './polkadot/polkadot-staking.svg';
import { ReactComponent as polkadotSwapMenu } from './polkadot/polkadot-swap.svg';
import { ReactComponent as polkadotCrowdloanMenu } from './polkadot/polkadot-crowdloan.svg';
import { ReactComponent as polkadotOverviewMenu } from './polkadot/polkadot-overview.svg';
import { ReactComponent as polkadotFarmMenu } from './polkadot/polkadot-farm.svg';
// Kusama
import { ReactComponent as kusamaOverviewMenu } from './kusama/kusama-overview.svg';
import { ReactComponent as kusamaCrossChainMenu } from './kusama/kusama-cross-chain.svg';
import { ReactComponent as kusamaDaoFiMenu } from './kusama/kusama-dao-fi.svg';
import { ReactComponent as kusamaMoneyMarketMenu } from './kusama/kusama-money-market.svg';
import { ReactComponent as kusamaStakingMenu } from './kusama/kusama-staking.svg';
import { ReactComponent as kusamaSwapMenu } from './kusama/kusama-swap.svg';
import { ReactComponent as kusamaCrowdloanMenu } from './kusama/kusama-crowdloan.svg';
import { ReactComponent as kusamaFarmMenu } from './kusama/kusama-farm.svg';
// Ethereum
import { ReactComponent as ethereumMoneyMarketMenu } from './ethereum/ethereum-money-market.svg';
import { ReactComponent as ethereumNftFiMenu } from './ethereum/ethereum-nft-fi.svg';
import { ReactComponent as ethereumStakingMenu } from './ethereum/ethereum-staking.svg';
import { ReactComponent as ethereumSwapMenu } from './ethereum/ethereum-swap.svg';
// Solana
import { ReactComponent as solanaMoneyMarketMenu } from './solana/solana-money-market.svg';
import { ReactComponent as solanaNftFiMenu } from './solana/solana-nft-fi.svg';
import { ReactComponent as solanaStakingMenu } from './solana/solana-staking.svg';
import { ReactComponent as solanaSwapMenu } from './solana/solana-swap.svg';

export const menuIcons = {
  polkadotCrossChainMenu,
  polkadotDaoFiMenu,
  polkadotMoneyMarketMenu,
  polkadotStakingMenu,
  polkadotSwapMenu,
  polkadotCrowdloanMenu,
  polkadotOverviewMenu,
  polkadotFarmMenu,
  kusamaOverviewMenu,
  kusamaCrossChainMenu,
  kusamaDaoFiMenu,
  kusamaMoneyMarketMenu,
  kusamaStakingMenu,
  kusamaSwapMenu,
  kusamaCrowdloanMenu,
  kusamaFarmMenu,
  ethereumMoneyMarketMenu,
  ethereumNftFiMenu,
  ethereumStakingMenu,
  ethereumSwapMenu,
  solanaMoneyMarketMenu,
  solanaNftFiMenu,
  solanaStakingMenu,
  solanaSwapMenu,
};

export type MenuIcons = keyof typeof menuIcons;
