// @ts-nocheck
/* eslint-disable */

import { groupBy } from 'lodash';

import { toNumber, fixDate } from '../helpers/others';

import { DailyVolumeResponseType as DailyVolumeResponseType, DailyVolumeType } from './models';

export const dailyVolume: DailyVolumeType = (
  allTrades,
  assets,
  rates,
  poolNames,
  AllSwaps
) => {
  if (allTrades && assets && rates && poolNames && AllSwaps) {
    const availableSwaps = AllSwaps.map(item => {
      return {
        ...item,
        ticker: item.ticker.replace(',', '-')
      };
    });
    const transactionsMassage = allTrades.map(item => {
      const initialPair = assets?.[item?.first_asset_id];
      const secondPair = assets?.[item?.second_asset_id];
      const InitialPairRate = rates[initialPair?.symbol];
      const SecondPairRate = rates[secondPair?.symbol];

      const initialRate = InitialPairRate?.[InitialPairRate?.length - 1]?.val;
      const secondRate = SecondPairRate?.[SecondPairRate?.length - 1]?.val;

      const initialValue = toNumber(item?.first_amount, initialPair?.decimals) * initialRate;
      const secondValue = toNumber(item?.second_amount, secondPair?.decimals) * secondRate;

      const currentDate = fixDate(item?.block_timestamp);
      const poolTicker = poolNames?.filter(x => x.id === item?.pool_id)[0]?.ticker;
      const hasSwap = availableSwaps?.filter(t => t.ticker === poolTicker).length > 0;
      return hasSwap
        ? {
            id: item?.pool_id,
            usdValue: initialValue + secondValue,
            poolTicker,
            tokenValues: [
              {
                date: currentDate,
                ticker: initialPair?.symbol,
                tickerVal: toNumber(item?.first_amount, initialPair?.decimals)
              },
              {
                date: currentDate,
                ticker: secondPair?.symbol,
                tickerVal: toNumber(item?.second_amount, secondPair?.decimals)
              }
            ],
            date: currentDate
          }
        : undefined;
    });
    const notUndefinedTransactions = transactionsMassage.filter(x => x);
    const dailyVolumes = groupBy(notUndefinedTransactions, 'date');
    const mapDailyVolume = Object.entries(dailyVolumes).map((item): DailyVolumeResponseType => {
      const poolReducer: Record<string, number> = item[1].reduce(
        // eslint-disable-next-line no-sequences
        (a, { poolTicker, usdValue }) => ((a[poolTicker] = (a[poolTicker] || 0) + usdValue), a),
        {}
      );
      const reduceTokenByDay: Record<string, number>[] = item[1].map(token => {
        const tokens = token.tokenValues;
        const tokenReducer = tokens.reduce(
          // eslint-disable-next-line no-sequences
          (a, { ticker, tickerVal }) => ((a[ticker] = (a[ticker] || 0) + tickerVal), a),
          {}
        );
        return tokenReducer;
      });
      const secondTokerReducer = reduceTokenByDay.reduce((a, { ...rest }) => {
        Object.entries(rest).forEach(([key, val]) => {
          a[key] = (a[key] || 0) + val;
        });
        return a;
      }, {});
      return {
        date: item[0],
        poolVolume: poolReducer,
        tokenVolume: secondTokerReducer
      };
    });
    return mapDailyVolume;
  }
};
