import { useState, useCallback, memo, FocusEvent, useLayoutEffect, useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  Icon,
  Popover,
  Stack,
  Inline,
  H5,
  SmallText,
  Card,
  Button,
  NumericInputBase,
  Text
} from '@parallel-mono/components';

import { isValidAmountInput } from '@/utils/validate';

const SlippageContainer = styled.div`
  border: none;
  border-radius: 100%;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    background-color: var(--clr-bg02);
  }

  & > svg {
    display: block;
  }
`;

const SlippageCard = styled(Card)`
  max-width: 22rem;
`;

const AutoButton = styled(Button)`
  height: 40px;
  margin: 5px 0;
`;

const SlippageInput = styled(NumericInputBase)`
  border-radius: 2rem;
  color: var(--clr-text01);
  border: 1px solid var(--clr-divide);
  height: 2.5rem;
  width: 8.75rem;
  padding: 0 1rem;
  font-weight: normal;
  font-size: 1rem;
`;

const ErrorText = styled(Text)`
  white-space: nowrap;
`;

interface SlippageProps {
  defaultSlippage: number;
  slippage: number;
  decimals?: number;
  onChange: (slippage: number | null) => void;
  error?: string | null;
  invalidSlippage: boolean;
}

const SlippageModal = memo<SlippageProps>(
  ({ defaultSlippage, slippage: rawSlippage, decimals, onChange, error, invalidSlippage }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [slippage, setSlippage] = useState<number | null>(rawSlippage);
    const updateSlippage = useCallback(
      (newSlippage: number | null) => {
        onChange?.(newSlippage);
        setSlippage(newSlippage);
      },
      [onChange]
    );
    const handleClickAuto = useCallback(() => {
      updateSlippage(defaultSlippage);
    }, [defaultSlippage, updateSlippage]);

    const handleBlur = useCallback(
      ({ target }: FocusEvent<HTMLInputElement>) => {
        if (!target.value || invalidSlippage) {
          updateSlippage(defaultSlippage);
        }
      },
      [defaultSlippage, invalidSlippage, updateSlippage]
    );

    const handleChange = useCallback(
      (newSlippage: number | null) => {
        if (!newSlippage || isValidAmountInput(`${newSlippage}`, 2, 4)) updateSlippage(newSlippage);
        if (invalidSlippage) updateSlippage(defaultSlippage);
      },
      [defaultSlippage, invalidSlippage, updateSlippage]
    );

    useEffect(() => {
      if (invalidSlippage) {
        setTimeout(() => updateSlippage(defaultSlippage), 1e3);
      }
    }, [defaultSlippage, invalidSlippage, updateSlippage]);

    useLayoutEffect(() => {
      if (ref.current) {
        setTimeout(() => {
          ref.current?.scrollIntoView(false);
        });
      }
    }, []);

    return (
      <SlippageCard ref={ref}>
        <Stack>
          <H5>Price Slippage</H5>
          <SmallText skin="secondary">
            Your transaction will revert if price changes unfavorably by more than this percentage.
          </SmallText>
          <Stack gap="0.5rem">
            <Inline alignItems="center" gap="1rem">
              <AutoButton onClick={handleClickAuto}>Auto</AutoButton>
              <SlippageInput
                min={0}
                value={slippage}
                decimals={decimals}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Inline>
            {error && <ErrorText skin="error">{error}</ErrorText>}
          </Stack>
        </Stack>
      </SlippageCard>
    );
  }
);

export const Slippage = memo<SlippageProps>(props => {
  return (
    <Popover trigger="click" popup={<SlippageModal {...props} />} placement="bottom-start">
      <SlippageContainer>
        <Icon name="setting" />
      </SlippageContainer>
    </Popover>
  );
});
