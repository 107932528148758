import { useMemo, useState, useCallback } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {
  Inline,
  Stack,
  SmallText,
  BigText,
  H3,
  Card,
  Button,
  Modal
} from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { BN } from '@polkadot/util';
import BigNumberJs from 'bignumber.js';

import { TOKEN_META } from '../constant';
import TableRowProcess from '../TableRowProcess';
import useFetchList, { StreamListItem } from '../useFetchList';

import { ClaimButton } from './ClaimButton';

import { NotConnected, StyledDataGrid, StyledDataGridColumn } from '@/components';
import { useAccount, useAssetPrices, useChainConnections, useTransactionFee } from '@/hooks';
import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { balanceToAmountByDecimal, amountToBalanceByDecimals } from '@/utils/calculations';
import { signAndSend } from '@/utils/txCall';
import { balanceFormatter } from '@/utils/format';
import { zero } from '@/utils/values';

const TitleStack = styled(Stack)`
  padding-left: 0.5rem;
`;
const InlineWrapper = styled(Inline)`
  > div {
    flex: 1;
  }
`;

const ButtonWithMargin = styled(Button)`
  margin-top: 1.5rem;
`;

export interface IClaimRowData {
  isShowClaimModal: boolean;
  claimed: BigNumberJs;
  claimableNow: BigNumberJs;
  lockedBalance: BigNumberJs;
  assetId: number;
  streamId: string;
}

const Receive = () => {
  const assetsPrice = useAssetPrices();
  const { account } = useAccount();
  const {
    parachain: { api }
  } = useChainConnections();

  const { isReady, list } = useFetchList(api, account?.address, 'Receive');
  const [claimRowData, setClaimRowData] = useState({
    claimed: zero,
    claimableNow: zero,
    lockedBalance: zero
  } as IClaimRowData);
  const [claimingIds, setClaimingIds] = useState<string[]>([]);

  const onClaimClick = (data: IClaimRowData) => {
    setClaimRowData(data);
  };

  const columns = useMemo<StyledDataGridColumn<StreamListItem>[]>(() => {
    const calculateValue = (amount: number, assetId: number) => {
      if (assetsPrice && assetsPrice[assetId]) {
        return amount * assetsPrice[assetId];
      }
      return 0;
    };

    return [
      {
        name: 'deposit',
        title: 'Amount',
        width: '1fr',
        render: ({ data: { deposit, assetId } }) => {
          const { decimals, symbol } = TOKEN_META[assetId];
          const amount = balanceToAmountByDecimal<number>(deposit, decimals, 'number');
          return (
            <Stack gap="0">
              <BigText>
                {balanceFormatter(amount)} {symbol}
              </BigText>
              <SmallText skin="secondary">
                {formatNumber(calculateValue(amount, assetId), { output: 'currency', decimal: 2 })}
              </SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'received',
        title: 'Received',
        width: '1fr',
        render: ({ data }) => {
          return <TableRowProcess data={data} />;
        }
      },
      {
        name: 'start',
        title: 'Start',
        width: '1fr',
        render: ({ data: { startTime } }) => {
          return <BigText>{dayjs(startTime).format('MM/DD/YYYY hh:mma')}</BigText>;
        }
      },
      {
        name: 'end',
        title: 'End',
        render: ({ data: { endTime } }) => {
          return <BigText>{dayjs(endTime).format('MM/DD/YYYY hh:mma')}</BigText>;
        }
      },
      {
        name: 'claimed',
        title: 'Claimed',
        width: '1fr',
        render: ({ data: { assetId, deposit, remainingBalance } }) => {
          const { decimals, symbol } = TOKEN_META[assetId];
          const amount = balanceToAmountByDecimal<number>(deposit, decimals, 'number');
          const remaining = balanceToAmountByDecimal<number>(remainingBalance, decimals, 'number');
          return (
            <Stack gap="0">
              <BigText>
                {balanceFormatter(amount - remaining)} {symbol}
              </BigText>
              <SmallText skin="secondary">
                {formatNumber(calculateValue(amount - remaining, assetId), {
                  output: 'currency',
                  decimal: 2
                })}
              </SmallText>
            </Stack>
          );
        }
      },
      {
        name: 'claim',
        title: '',
        justifyContent: 'center',
        width: '0.6fr',
        render: ({ data }) => {
          return <ClaimButton rowData={data} onClick={onClaimClick} claimingIds={claimingIds} />;
        }
      }
    ];
  }, [assetsPrice, claimingIds]);

  const handleClaimModalClose = () => {
    setClaimRowData(data => ({ ...data, isShowClaimModal: false } as IClaimRowData));
  };

  const {
    isShowClaimModal,
    claimed,
    claimableNow,
    lockedBalance,
    assetId: claimAssetId
  } = claimRowData;
  const { symbol: claimSymbol } = TOKEN_META[claimAssetId] || {};

  const withdrawTx = useCallback(() => {
    const { streamId } = claimRowData;
    if (!streamId || claimableNow.lte(0)) {
      return null;
    }
    const amount = amountToBalanceByDecimals<BN>(
      claimableNow,
      TOKEN_META[claimAssetId].decimals,
      'bn'
    );

    return api.tx.streaming.withdraw(streamId, amount);
  }, [api.tx.streaming, claimAssetId, claimRowData, claimableNow]);

  const onClaimButtonClick = () => {
    const { streamId } = claimRowData;
    // update claiming stream ids
    setClaimingIds(ids => ids.concat(streamId));
    // clear claim modal data, hide current claim modal
    setClaimRowData(data => ({ ...data, isShowClaimModal: false }));
    signAndSend({
      api,
      tx: withdrawTx(),
      account,
      txSuccessCb: () => {
        setClaimingIds(ids => ids.filter(id => id !== streamId));
      },
      txFailedCb: () => {
        setClaimingIds(ids => ids.filter(id => id !== streamId));
      }
    });
  };

  const transactionFee = useTransactionFee(
    {
      api,
      tx: withdrawTx()
    },
    []
  );

  const { TxFeeTips } = useTxFeeValidation();

  return account ? (
    <Card>
      <StyledDataGrid<StreamListItem>
        title={
          <TitleStack gap=".25rem">
            <H3>Incoming Payments</H3>
            <BigText skin="secondary">Payments sent by block over a period of time.</BigText>
          </TitleStack>
        }
        loading={!isReady}
        data={list}
        columns={columns}
      />
      <Modal
        title={
          <Inline gap=".75rem" alignItems="center">
            <CryptoAsset symbol={claimSymbol} />
            <H3>Claim {claimSymbol}</H3>
          </Inline>
        }
        isOpen={isShowClaimModal}
        closable
        onClose={handleClaimModalClose}
      >
        <InlineWrapper gap="0">
          <Stack gap="2px">
            <SmallText skin="secondary">Claimable Balance</SmallText>
            <H3>{balanceFormatter(claimableNow.toNumber())}</H3>
          </Stack>
          <Stack gap="2px">
            <SmallText skin="secondary">Total Claimed</SmallText>
            <H3>{balanceFormatter(claimed.toNumber())}</H3>
          </Stack>
        </InlineWrapper>
        <Stack gap="2px">
          <SmallText skin="secondary">Locked Balance</SmallText>
          <H3>{balanceFormatter(lockedBalance.toNumber())}</H3>
        </Stack>
        <Stack gap="1rem">
          <ButtonWithMargin skin="primary" block onClick={onClaimButtonClick}>
            {`Claim ${balanceFormatter(claimableNow.toNumber())} ${claimSymbol}`}
          </ButtonWithMargin>
          <TxFeeTips txFee={transactionFee} />
        </Stack>
      </Modal>
    </Card>
  ) : (
    <NotConnected title="Incoming Payments" />
  );
};

export default Receive;
