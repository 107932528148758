import { HTMLAttributes, memo } from 'react';
import styled from 'styled-components';

const StyledSVG = styled.svg<{ open: boolean }>`
  transform: rotate(${({ open }) => (open ? '-90deg' : '90deg')});
  stroke: currentColor;
  width: 0.5em;
  transition: transform 0.2s;
  padding: 0.5em;

  &:hover {
    cursor: pointer;
  }
`;

type ExpandToggleProps = { open: boolean } & HTMLAttributes<HTMLOrSVGElement>;

export const ExpandToggle = memo((props: ExpandToggleProps) => (
  <StyledSVG viewBox="0 0 8 14" fill="none" {...props}>
    <path d="M1 13L7 7L1 1" strokeLinecap="round" strokeLinejoin="round" />
  </StyledSVG>
));
