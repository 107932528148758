import { SelectableTokenValue } from '@parallel-mono/business-components';
import BigNumber from 'bignumber.js';

import { TransactionToken } from './hooks/useTransactionTokens';
import { FullPool } from './hooks/usePools';

import { CurrencyId } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';

export const getPriceImpact = (
  route: CurrencyId[],
  fromTokenValue: SelectableTokenValue<TransactionToken>,
  poolInfos: FullPool[]
) => {
  if (!fromTokenValue.amount || route.length < 2) return 0;
  const pools: [CurrencyId, CurrencyId][] = [];
  const fromAmount = amountToBalanceByDecimals<BigNumber>(
    fromTokenValue.amount,
    fromTokenValue?.token.decimals,
    'bigNumber'
  );

  const getPoolAmount = ([fromAssetId, toAssetId]: [CurrencyId, CurrencyId]) => {
    const builtInPool = poolInfos.find(
      item => item.fromAssetId.toString() === fromAssetId && item.toAssetId.toString() === toAssetId
    );

    if (builtInPool) return [builtInPool.baseAmount, builtInPool.quoteAmount];

    const reversePool = poolInfos.find(
      item => item.fromAssetId.toString() === toAssetId && item.toAssetId.toString() === fromAssetId
    );

    if (reversePool) {
      return [reversePool.quoteAmount, reversePool.baseAmount];
    }

    return null;
  };

  route.reduce((prevAssetId, nextAssetId) => {
    pools.push([prevAssetId, nextAssetId]);
    return nextAssetId;
  });

  const oldPrice = pools.reduce((price, pool) => {
    const poolAmount = getPoolAmount(pool);
    if (!poolAmount) return price;
    const fromPoolAmount = new BigNumber(poolAmount[0].toString());
    const toPoolAmount = new BigNumber(poolAmount[1].toString());
    return price.multipliedBy(fromPoolAmount.dividedBy(toPoolAmount));
  }, new BigNumber(1));

  const [newPrice] = pools.reduce(
    ([price, amountIn], pool) => {
      const poolAmount = getPoolAmount(pool);
      if (!poolAmount) return [price, amountIn];
      const fromPoolAmount = new BigNumber(poolAmount[0].toString());
      const toPoolAmount = new BigNumber(poolAmount[1].toString());
      const amountOut = toPoolAmount.minus(
        fromPoolAmount.multipliedBy(toPoolAmount).dividedBy(fromPoolAmount.plus(amountIn))
      );

      const nextPrice = price.multipliedBy(
        fromPoolAmount.plus(amountIn).dividedBy(toPoolAmount.minus(amountOut))
      );
      return [nextPrice, amountOut];
    },
    [new BigNumber(1), fromAmount]
  );

  return new BigNumber(1).minus(oldPrice.dividedBy(newPrice)).toNumber();
};
