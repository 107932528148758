import { isFunction } from 'lodash';
import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { useDualModeState } from '../../hooks/useDualModeState';
import { Skin } from '../ThemeProvider/types';

export type ToggleBaseClassNames = {
  slider: string;
};

export interface ToggleBaseProps {
  defaultChecked?: boolean;
  checked?: boolean;
  skin?: Skin;
  disabled?: boolean;
  sliderContent?: ReactNode | ((checked: boolean) => ReactNode);
  onChange?: (checked: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  classNames?: ToggleBaseClassNames;
  inputRef?: ((instance: HTMLInputElement | null) => void) | RefObject<HTMLInputElement> | null;
}

const StyledLabel = styled.label<{ disabled?: boolean; checked: boolean; skin: Skin }>`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 10000px;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled = false, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  background: ${({ theme, checked, skin }) =>
    checked ? theme.skin[skin].main : theme.skin.grey[200]};
  padding: ${({ theme }) => theme.spacing(1)};
  transition: all 0.2s ease-in-out;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledSlider = styled.span<{ checked: boolean }>`
  border-radius: 10000px;
  transition: all 0.2s ease-in-out;
  background: ${({ theme }) => theme.skin?.background?.main};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, checked }) => (checked ? theme.skin.text.primary : theme.skin.text.secondary)};
  padding: ${({ theme }) => theme.spacing(1)};
  position: relative;
  left: ${({ checked }) => (checked ? '100%' : '0')};
  min-width: 1rem;
  min-height: 1rem;
  box-sizing: border-box;
`;

export type ToggleProps = Omit<InputHTMLAttributes<HTMLLabelElement>, keyof ToggleBaseProps> &
  ToggleBaseProps;

export const ToggleBase = memo(
  forwardRef<HTMLLabelElement, ToggleProps>(
    (
      {
        defaultChecked,
        checked,
        onChange,
        skin = 'primary',
        disabled,
        sliderContent,
        classNames,
        inputRef,
        ...otherProps
      },
      ref
    ) => {
      const [finalChecked, setInternalChecked] = useDualModeState(defaultChecked ?? false, checked);
      const handleChange = useCallback(
        e => {
          setInternalChecked(e.target.checked);
          onChange?.(e.target.checked, e);
        },
        [onChange, setInternalChecked]
      );

      const content = useMemo(
        () => (isFunction(sliderContent) ? sliderContent(finalChecked) : sliderContent),
        [sliderContent, finalChecked]
      );

      return (
        <StyledLabel
          skin={skin}
          checked={finalChecked}
          disabled={disabled}
          {...otherProps}
          ref={ref}
        >
          <StyledSlider checked={finalChecked} className={classNames?.slider}>
            <StyledInput
              type="checkbox"
              checked={finalChecked}
              onChange={handleChange}
              disabled={disabled}
              ref={inputRef}
            />
            {content}
          </StyledSlider>
        </StyledLabel>
      );
    }
  )
);
