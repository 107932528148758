import {
  HTMLAttributes,
  memo,
  forwardRef,
  useRef,
  useState,
  useMemo,
  useImperativeHandle,
  useEffect,
} from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import { nextTick } from '../../utils/schedu';

type CollapseBasePropOverrides = {
  open?: boolean;
  duration?: CSSProperties['transitionDuration'];
  timing?: CSSProperties['transitionTimingFunction'];
  delay?: CSSProperties['transitionDelay'];
  direction?: 'horizontal' | 'vertical';
};

type CollapseBaseProps = Omit<HTMLAttributes<HTMLDivElement>, keyof CollapseBasePropOverrides> &
  CollapseBasePropOverrides;

const CollapseContainer = styled.div<CollapseBaseProps & { $direction: 'horizontal' | 'vertical' }>`
  height: 0;
  overflow: hidden;
  transition-property: height, width;
  transition-delay: ${props => props.delay ?? '0'};
  transition-duration: ${props => props.duration ?? '0.2s'};
  transition-timing-function: ${props => props.timing ?? 'linear'};
  will-change: height, width;
  ${({ $direction }) =>
    $direction === 'horizontal'
      ? css`
          display: inline-block;
        `
      : ''}
`;

const ChildrenContainer = styled.div<{ $direction: 'horizontal' | 'vertical' }>`
  overflow: hidden;
  ${({ $direction }) =>
    $direction === 'horizontal'
      ? css`
          display: inline-block;
        `
      : ''}
`;

export const CollapseBase = memo(
  forwardRef<HTMLDivElement, CollapseBaseProps>((props, ref) => {
    const { children, open, style, direction = 'vertical', ...others } = props;

    const [collapseContainerSize, setCollapseContainerSize] = useState(open ? 'auto' : '0px');
    const childrenContainerRef = useRef<HTMLDivElement>(null);
    const collapseContainerRef = useRef<HTMLDivElement>(null);

    const CollapseContainerStyle = useMemo(() => {
      const width = style?.width ?? 'auto';
      const height = style?.height ?? 'auto';

      return {
        ...style,
        width: direction === 'horizontal' ? collapseContainerSize : width,
        height: direction === 'vertical' ? collapseContainerSize : height,
      };
    }, [collapseContainerSize, style, direction]);

    useEffect(() => {
      const childrenContainer = childrenContainerRef.current;
      const collapseContainer = collapseContainerRef.current;
      const childrenContainerSize =
        direction === 'vertical' ? childrenContainer?.offsetHeight : childrenContainer?.offsetWidth;

      const collapseContainerSize =
        direction === 'vertical' ? collapseContainer?.style.height : collapseContainer?.style.width;
      if (open) {
        if (childrenContainerSize === 0 || collapseContainerSize === 'auto') {
          return;
        }
        const handleTransitionEnd = () => {
          setCollapseContainerSize('auto');
        };
        setCollapseContainerSize('0');
        collapseContainer?.addEventListener('transitionend', handleTransitionEnd);
        nextTick(() => {
          setCollapseContainerSize(`${childrenContainerSize}px`);
        });
        return () => {
          collapseContainer?.removeEventListener('transitionend', handleTransitionEnd);
        };
      }
      if (['0', '0px'].includes(collapseContainerSize ?? '')) {
        return;
      }
      setCollapseContainerSize(`${childrenContainerSize}px`);
      nextTick(() => {
        setCollapseContainerSize('0');
      });
      return;
    }, [open, direction]);

    useImperativeHandle(ref, () => collapseContainerRef.current as HTMLDivElement);

    return (
      <CollapseContainer
        $direction={direction}
        ref={collapseContainerRef}
        style={CollapseContainerStyle}
        {...others}
      >
        <ChildrenContainer $direction={direction} ref={childrenContainerRef}>
          {children}
        </ChildrenContainer>
      </CollapseContainer>
    );
  })
);
