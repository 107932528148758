import { useMemo, FC } from 'react';
import styled from 'styled-components';
import { Stack, H3, H6 } from '@parallel-mono/components';
import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { AssetRow } from '../LendAndBorrowTable';

import { InterestRateModelChart } from './InterestRateModelChart';

import { ModalFunc } from '@/hooks/useModal';
import { AssetMarket } from '@/hooks/types';
import { rateToNumber, ratioToNumber } from '@/utils/utils';

const Title = styled(H6)`
  color: ${({ theme }) => theme.skin.primary.main};
`;

interface TokenDetailedModalProps extends ModalFunc {
  data: AssetRow;
  assetsMarket?: AssetMarket;
}

export const TokenDetailedModal: FC<TokenDetailedModalProps> = ({ data, assetsMarket }) => {
  const isLpToken = useMemo(() => /^LP-/.test(data.symbol), [data.symbol]);
  const jumpModel = useMemo(() => {
    const rateModel = assetsMarket?.[data.assetId]?.rateModel;
    return rateModel?.['asJump' as 'asJumpModel'] ?? rateModel?.asJumpModel;
  }, [assetsMarket, data.assetId]);

  const jumpRate = useMemo(() => {
    if (jumpModel) return rateToNumber(jumpModel.jumpRate);

    return 0;
  }, [jumpModel]);

  const fullRate = useMemo(() => {
    if (jumpModel) return rateToNumber(jumpModel.fullRate);

    return 0;
  }, [jumpModel]);

  const utilizationRatio = useMemo(() => {
    if (jumpModel) return ratioToNumber(jumpModel.jumpUtilization);
    return 0;
  }, [jumpModel]);

  const infos = useMemo<InfoPanelProps['infos']>(
    () => [
      {
        title: 'Asset price',
        value: isLpToken ? '-' : formatNumber(data.price, { output: 'currency' })
      },
      {
        title: 'Total supply',
        value:
          data.supplyMarket <= 0
            ? '-'
            : formatNumber(data.supplyMarket * data.price, { output: 'currency' })
      },
      {
        title: 'Total borrow',
        value:
          data.supplyMarket <= 0
            ? '-'
            : formatNumber(data.borrowMarket * data.price, { output: 'currency' })
      },
      {
        title: 'Available Liquidity',
        value: formatNumber(Math.max((data.supplyMarket - data.borrowMarket) * data.price, 0)!, {
          output: 'currency'
        })
      },
      {
        title: 'Utilization rate',
        value: formatNumber(utilizationRatio, { output: 'percent' })
      }
    ],
    [data, isLpToken, utilizationRatio]
  );
  return (
    <Stack>
      <H3>{data.symbol}</H3>
      <Title>Utilization vs APY</Title>
      <InterestRateModelChart
        jumpRate={jumpRate}
        fullRate={fullRate}
        utilizationRatio={utilizationRatio}
      />
      <InfoPanel panelTitle="Market Details" infos={infos} />
    </Stack>
  );
};
