import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, defaultThemeConfig } from '@parallel-mono/components';
import '@polkadot/api-augment';
import { merge } from 'lodash';
import '@parallel-finance/types/interfaces/augment-api';
import '@parallel-finance/types/interfaces/augment-types';

import GlobalStyle from '@/theme/globalStyles';
import { App } from '@/pages/App';
import { theme } from '@/theme';
import './app.css';

const mergedThemeConfig = merge(defaultThemeConfig, theme(false));

console.log(process.env);
ReactDOM.render(
  <ThemeProvider themeConfig={mergedThemeConfig}>
    <GlobalStyle />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root')
);
