import { ReactNode, useCallback, useState, MouseEvent, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import { genericMemo } from '../../../utils/genericMemo';
import { Card, CardProps } from '../../Card';
import { Collapse } from '../../Collapse';
import { Inline, Stack } from '../../Layout';
import { ExpandToggle } from '../DesktopGrid/ExpandToggle';
import {
  DataCardHeaderRenderContext,
  DataGridClassNames,
  DataGridColumn,
  ExpandableRenderContext,
  RowClickEventContext,
} from '../types';
import cx from 'classnames/bind';

export type DataCardProps<R = object> = Omit<CardProps, 'children' | 'onClick'> & {
  columns: DataGridColumn<R>[];
  data: R;
  classNames?: DataGridClassNames;
  expandable: boolean;
  renderExpandable?: (context: ExpandableRenderContext<R>) => ReactNode;
  renderDataCardHeader?: (context: DataCardHeaderRenderContext<R>) => ReactNode;
  onClick?: (row: RowClickEventContext<R>, event: MouseEvent) => void;
};

const CellTitle = styled.div`
  color: ${({ theme }) => theme.skin.text.secondary};
  ${({ theme }) => theme.typography.header6}
`;

const CellValue = styled.div`
  color: ${({ theme }) => theme.skin.text.primary};
  ${({ theme }) => theme.typography.header5}
`;

export const DataCard = genericMemo(function <R>(props: DataCardProps<R>) {
  const {
    columns,
    data,
    renderDataCardHeader,
    renderExpandable,
    expandable,
    onClick,
    classNames,
    className,
    ...others
  } = props;
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const bindedCx = useMemo(() => cx.bind(classNames), [classNames]);

  const toggleExpandable = useCallback(
    (expand?: boolean) => {
      setExpanded(curr => expand ?? !curr);
    },
    [setExpanded]
  );

  const context = useMemo(
    () => ({
      data,
      expandable,
      expanded,
      toggleExpandable,
    }),
    [data, expandable, expanded, toggleExpandable]
  );
  const handleClick = useCallback(
    (e: MouseEvent) => {
      onClick?.(context, e);
    },
    [onClick, context]
  );
  return (
    <Card
      border
      onClick={handleClick}
      className={bindedCx({ expandableCard: expandable, expandedCard: expanded }, className)}
      {...others}
    >
      {renderDataCardHeader ? (
        <Inline className={classNames?.dataCardHeader}>{renderDataCardHeader(context)}</Inline>
      ) : (
        <>
          {expandable && (
            <Inline className={classNames?.dataCardHeader} justifyContent="flex-end">
              <ExpandToggle open={expanded} onClick={() => toggleExpandable?.()} />
            </Inline>
          )}
        </>
      )}
      <Stack gap={theme.spacing(2.5)}>
        {columns.map(column => (
          <Inline justifyContent="space-between">
            <CellTitle className={bindedCx(classNames?.dataCardItemTitleCell, column.className)}>
              {column.title}
            </CellTitle>
            <CellValue className={bindedCx(classNames?.dataCardItemValueCell, column.className)}>
              {column.render
                ? column.render({
                    column,
                    ...context,
                  })
                : data[column.name]}
            </CellValue>
          </Inline>
        ))}
      </Stack>
      {expandable && renderExpandable && (
        <Collapse className={classNames?.expandableContainer} open={expanded}>
          {renderExpandable({ data, expandable, expanded, toggleExpandable })}
        </Collapse>
      )}
    </Card>
  );
});
