import { InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { useMemo } from 'react';
import { BN } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

import { RouteType } from '../types';

import { balanceFormatter, formatMillisecondsToLeftTime } from '@/utils/format';
import { RELAY_CHAIN_BLOCK_TIME } from '@/utils/constants';

export type StakeData = {
  type: 'staking';
  liquidToken: string;
  stakeMintVal: number;
  monthlyEarnVal: number;
  stakingFee: number;
  platformFee: number;
  routeType: RouteType;
};

export type UnStakeData = {
  type: 'unStaking';
  stakingToken: string;
  unstakeMintVal: number;
  eraLengthVal: BN;
  bondingDurationVal: BN;
  unstakeFee?: number;
  instantUnstake?: boolean;
  routeType: RouteType;
};

type Props = {
  details: StakeData | UnStakeData;
};

const StakingDetails = ({ details }: Props) => {
  const isMinimumReceived = useMemo(() => details.routeType === RouteType.Amm, [details.routeType]);
  const detailItems = useMemo<InfoPanelProps['infos']>(() => {
    if (details.type === 'staking') {
      const { liquidToken, stakeMintVal, monthlyEarnVal, stakingFee } = details;
      return [
        {
          title: 'Est. Monthly Earnings',
          value: `${formatNumber(monthlyEarnVal || 0, { output: 'currency', decimal: 4 })}`
        },
        {
          title: `${isMinimumReceived ? 'Minimum ' : ''}Receive`,
          value: `${balanceFormatter(stakeMintVal)} ${liquidToken}`
        },
        {
          title: 'Staking Fee',
          tip: 'Small staking fee for the transaction fees required for staking on relay chain',
          value: formatNumber(stakingFee, { output: 'percent' })
        }
        // {
        //   title: 'Platform Fee',
        //   tip: 'This fee only applies to staking rewards only. It does NOT apply to your staked amount, it is a fee on earnings only.',
        //   value: formatNumber(platformFee, { output: 'percent' })
        // }
      ];
    }

    const {
      stakingToken,
      unstakeMintVal,
      eraLengthVal,
      bondingDurationVal,
      unstakeFee,
      instantUnstake
    } = details as UnStakeData;
    const formatToWaitTime = bondingDurationVal.toNumber()
      ? formatMillisecondsToLeftTime(
          eraLengthVal
            .mul(bondingDurationVal.addn(1))
            .mul(new BN(RELAY_CHAIN_BLOCK_TIME * 1000)) // RELAY_CHAIN_BLOCK_TIME s => ms
            .toNumber()
        )
      : '-';
    const unStakeItems = [
      {
        title: `${isMinimumReceived ? 'Minimum ' : ''}Receive`,
        value: `${balanceFormatter(unstakeMintVal)} ${stakingToken}`
      },
      {
        title: 'Wait Time',
        value: `${instantUnstake ? '-' : formatToWaitTime}`
      }
    ];
    return unstakeFee
      ? [
          ...unStakeItems,
          {
            title: 'Unstake Fee',
            value: `${formatNumber(unstakeFee, {
              decimal: 6,
              threshold: { min: 0.000001 }
            })} ${stakingToken}`
          }
        ]
      : unStakeItems;
  }, [details, isMinimumReceived]);

  return <InfoPanel infos={detailItems} />;
};

export default StakingDetails;
