import { FC, useCallback, useMemo, useContext, Dispatch, SetStateAction } from 'react';
import { Stack, Button, Inline } from '@parallel-mono/components';
import { SelectableTokenValue } from '@parallel-mono/business-components';
import { BN } from '@polkadot/util';
import type { Permill } from '@polkadot/types/interfaces';
import { useDebounce } from 'react-use';

import { TransactionToken } from '../hooks/useTransactionTokens';
import { DownSlideAnimation } from '../components/DownSlideAnimation';
import { useSlippage } from '../hooks/useSlippage';
import { getPriceImpact } from '../utils';
import { DataContext } from '../DataContext';
import { BestRoute } from '../hooks/useBestRoute';

import { SwapDetail } from './SwapDetail';

import {
  useApiCall,
  useAccount,
  useGAEventTracker,
  useScopeState,
  useChainConnections
} from '@/hooks';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { ConnectToWallet } from '@/components';
import { signAndSend, txFee } from '@/utils/txCall';
import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';

interface SwapTransactionProps {
  fromTokenValue: SelectableTokenValue<TransactionToken> | undefined;
  toTokenValue: SelectableTokenValue<TransactionToken> | undefined;
  bestRoute: BestRoute | null;
  isInsufficient: boolean;
  trading: boolean;
  setTrading: Dispatch<SetStateAction<boolean>>;
  onTxSuccess?: () => void;
  onTxFailed?: () => void;
}

export const SwapTransaction: FC<SwapTransactionProps> = ({
  fromTokenValue,
  toTokenValue,
  bestRoute,
  isInsufficient,
  trading,
  setTrading,
  onTxSuccess,
  onTxFailed
}) => {
  const { account } = useAccount();
  const GAEventTrackerWalletConnect = useGAEventTracker('Wallet Connect Button');
  const {
    parachain: { api }
  } = useChainConnections();

  const { TxFeeTips } = useTxFeeValidation();

  const { holder: slippageHolder, slippage, invalidSlippage } = useSlippage();

  const feeMultiplier =
    useApiCall<number>(api.query.amm.protocolFee, null, {
      transform: (value: Permill) =>
        balanceToAmountByDecimal<number>(api.consts.amm.lpFee.add(value), 'million', 'number')
    }) ?? 0;

  const { assetsPriceSource, assetInfoSource, poolSource } = useContext(DataContext);
  const { assetInfos } = assetInfoSource;
  const { pools } = poolSource;

  const nativeAssetInfo = useMemo(() => assetInfos.find(({ isNative }) => isNative), [assetInfos]);

  const [transactionFee, setTransactionFee] = useScopeState<TxFee>();

  const priceImpact = useMemo(() => {
    if (bestRoute?.route && fromTokenValue?.amount) {
      return getPriceImpact(bestRoute.route, fromTokenValue, pools);
    }

    return 0;
  }, [bestRoute, fromTokenValue, pools]);

  const nativeTokenUsdPrice = useMemo(
    () =>
      assetsPriceSource && nativeAssetInfo && assetsPriceSource[nativeAssetInfo.assetId]
        ? assetsPriceSource[nativeAssetInfo.assetId]
        : 0,
    [assetsPriceSource, nativeAssetInfo]
  );

  const hasAmountAndToken = useMemo(
    () =>
      !!(
        fromTokenValue?.amount &&
        fromTokenValue?.token &&
        toTokenValue?.amount &&
        toTokenValue?.token
      ),

    [fromTokenValue?.amount, fromTokenValue?.token, toTokenValue?.amount, toTokenValue?.token]
  );

  const isSwapDetailReady = useMemo(
    () => !!(fromTokenValue?.amount && toTokenValue?.amount),
    [fromTokenValue?.amount, toTokenValue?.amount]
  );

  const swapTx = useCallback(() => {
    const { amount: fromAmount, token: fromToken } = fromTokenValue!;
    const { amount: toAmount, token: toToken } = toTokenValue!;
    const { route, reversed, fromAmount: amountIn, toAmount: amountOut } = bestRoute!;

    return reversed
      ? api.tx.ammRoute.swapTokensForExactTokens(
          route,
          amountOut!,
          amountToBalanceByDecimals<BN>(
            fromAmount! * (1 + slippage / 100),
            fromToken.decimals,
            'bn'
          )
        )
      : api.tx.ammRoute.swapExactTokensForTokens(
          route,
          amountIn!,
          amountToBalanceByDecimals<BN>(toAmount! * (1 - slippage / 100), toToken.decimals, 'bn')
        );
  }, [api.tx.ammRoute, fromTokenValue, bestRoute, slippage, toTokenValue]);

  const executeTrade = useCallback(() => {
    if (account && bestRoute?.route && hasAmountAndToken) {
      try {
        setTrading(true);
        signAndSend({
          api,
          tx: swapTx(),
          account,
          txSuccessCb: () => {
            setTrading(false);
            onTxSuccess?.();
          },
          txFailedCb: err => {
            setTrading(false);
            onTxFailed?.();
            console.error('Error executing trade:', err);
          }
        });
      } catch (err) {
        setTrading(false);
        onTxFailed?.();
        console.error('Error executing trade', err);
      }
    }
  }, [account, bestRoute, hasAmountAndToken, setTrading, api, swapTx, onTxSuccess, onTxFailed]);

  useDebounce(
    () => {
      const getFee = async () => {
        setTransactionFee(null);
        const fee = await txFee({
          tx: swapTx(),
          account
        });
        setTransactionFee(fee);
      };
      if (account && bestRoute?.route && hasAmountAndToken) {
        getFee();
      }
    },
    500,
    [account, bestRoute, hasAmountAndToken]
  );

  return (
    <Stack>
      <DownSlideAnimation isReady={isSwapDetailReady}>
        <SwapDetail
          fromTokenValue={fromTokenValue!}
          toTokenValue={toTokenValue!}
          priceImpact={priceImpact}
          slippage={slippage}
          feeMultiplier={feeMultiplier}
        />
      </DownSlideAnimation>

      <Stack gap="0.8rem">
        {account ? (
          <Button
            block
            size="large"
            disabled={
              trading ||
              isInsufficient ||
              invalidSlippage ||
              !bestRoute?.route ||
              !fromTokenValue?.amount ||
              !toTokenValue?.amount
            }
            onClick={executeTrade}
          >
            {isInsufficient ? 'Insufficient Balance' : 'Swap'}
          </Button>
        ) : (
          <ConnectToWallet
            size="large"
            skin="secondary"
            block
            action={() =>
              GAEventTrackerWalletConnect(
                'Connect Wallet In Swap Component',
                `Swap connectWallet, Swap Card`
              )
            }
          >
            Connect Wallet to Swap
          </ConnectToWallet>
        )}

        <DownSlideAnimation isReady={hasAmountAndToken}>
          <Inline alignItems="center" gap="0" justifyContent="center">
            {slippageHolder}
            <TxFeeTips
              txFee={transactionFee!}
              feeUsdPrice={(transactionFee ?? 0) * nativeTokenUsdPrice}
            />
          </Inline>
        </DownSlideAnimation>
      </Stack>
    </Stack>
  );
};
