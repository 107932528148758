import { FC } from 'react';

import Element, { Variant, SkeletonElementProps } from './Element';

interface SkeletonAvatarProps extends SkeletonElementProps {}
const Avatar: FC<SkeletonAvatarProps> = props => {
  return <Element variant={Variant.circle} width="32px" height="32px" {...props} />;
};

export type { SkeletonAvatarProps };
export default Avatar;
