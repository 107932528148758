import { memo, SVGAttributes } from 'react';
import { IconSizes } from '../static/types/icons';
import { icons } from './svgIcons';
import styled, { CSSProperties } from 'styled-components';

const sizeMap: IconSizes = {
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '60px',
};

export type IconName = keyof typeof icons;

export type IconProps = {
  color?: string;
  name: IconName;
  size?: keyof IconSizes | CSSProperties['width'];
} & Omit<SVGAttributes<SVGElement>, 'name'>;

const SVGComponent = styled.svg<{ $color: string }>`
  color: ${({ $color }) => $color};
`;

export const Icon = memo((props: IconProps) => {
  const { name, size = 'medium', color = 'inherit', ...others } = props;
  const Component = icons[name];
  const domSize = sizeMap[size] ?? size;
  return (
    <SVGComponent as={Component} width={domSize} height={domSize} $color={color} {...others} />
  );
});
