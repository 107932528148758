import { KeyringPair } from '@polkadot/keyring/types';
import { ReactNode } from 'react';

export enum WalletType {
  Polkadot = 'polkadot',
  Parallel = 'parallel',
  SubWallet = 'subWallet'
  // Phantom = 'phantom',
  // Metamask = 'metamask'
}
export interface BaseWallet {
  type: WalletType;
  walletName: string;
  label: string;
  enable: boolean;
  icon: ReactNode;
  description?: string;
  showComingSoonDetail?: boolean;
  extensionLink: string;
  bgImage: string;
}

export interface ExtendedWallet {
  accounts?: KeyringPair[];
  selectedAccount?: KeyringPair;
  walletInstalled?: boolean;
  switchAccount?: <T extends KeyringPair>(value: T, callback?: (val: T) => void) => void;
  loadAccounts?: () => void;
}

export interface Wallet extends BaseWallet, ExtendedWallet {}

export enum ExtensionWallets {
  Polkadot = 'polkadot-js',
  Parallel = 'parallel-wallet',
  SubWallet = 'subwallet-js'
}
