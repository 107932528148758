import { useState, useMemo } from 'react';
import { StorageKey, Option } from '@polkadot/types';
import { Pool, AssetId } from '@parallel-finance/types/interfaces';

import { useApiCall, useChainConnections } from '@/hooks';

export interface FullPool extends Pool {
  fromAssetId: AssetId;
  toAssetId: AssetId;
}

export const usePools = () => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [isReady, setIsReady] = useState(false);

  const poolIds = useApiCall<[StorageKey<[AssetId, AssetId]>][]>(api.query.amm.pools.entries)?.map<
    [AssetId, AssetId]
  >(
    ([
      {
        args: [fromAssetId, toAssetId]
      }
    ]) => [fromAssetId, toAssetId]
  );

  const poolsOpt = useMemo(
    () => ({
      transform: (values: Option<Pool>[]) => {
        if (!isReady) setIsReady(true);
        return values.reduce<FullPool[]>((result, option, index) => {
          if (option.isSome && poolIds) {
            const pool = option.unwrap() as FullPool;
            const [fromAssetId, toAssetId] = poolIds[index];
            pool.fromAssetId = fromAssetId;
            pool.toAssetId = toAssetId;

            result.push(pool);
          }
          return result;
        }, []);
      }
    }),
    [isReady, poolIds]
  );

  const pools =
    useApiCall<FullPool[]>(poolIds && api.query.amm.pools.multi, [poolIds], poolsOpt) || [];

  return {
    isReady,
    pools
  };
};
