import { motion, AnimatePresence } from 'framer-motion';
import { FC } from 'react';

interface DownSlideAnimationProps {
  isReady: boolean;
}

export const DownSlideAnimation: FC<DownSlideAnimationProps> = ({ children, isReady }) => {
  return (
    <AnimatePresence initial={false}>
      {isReady ? (
        <motion.section
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={{
            visible: { opacity: 1, height: 'auto' },
            hidden: { opacity: 0, height: 0 }
          }}
          transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {children}
        </motion.section>
      ) : null}
    </AnimatePresence>
  );
};
