import { useCallback, useState } from 'react';
import { ITuple } from '@polkadot/types/types';
import { Balance, FixedU128, Rate, Ratio } from '@parallel-finance/types/interfaces';
import { isEmpty, isEqual, isNil, zipObject, zipWith } from 'lodash';
import { BN_ZERO } from '@polkadot/util';
import BigNumber from 'bignumber.js';

import { useApiCall, useChainConnections, useAsyncEffect } from '@/hooks';
import { AssetMarketStatus, CurrencyId, MarketData } from '@/hooks/types';
import { NonEmptyArray } from '@/typings/basic';
import { rateToNumber, ratioToNumber } from '@/utils/utils';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { getStakingApy } from '@/utils/stakingHelper/getStakingApy';
import config from '@/config';
import { zero } from '@/utils/values';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

const getEffectiveAPY = (stakingAPY: BigNumber, interestRate: Rate) => {
  return (
    stakingAPY
      ?.plus(1)
      ?.times(BigNumber(rateToNumber(interestRate)).plus(1))
      ?.minus(1)
      ?.toNumber() ?? 0
  );
};

export const useAssetMarketStatus = (ids?: NonEmptyArray<CurrencyId>) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [state, setState] = useState<MarketData<AssetMarketStatus>>();
  const [stakingAPY, setStakingAPY] = useState<BigNumber | null>(null);

  const { assetInfos } = useCurrentAccountAssetInfos();
  const currentBlock = useApiCall(api.query.system.number);

  const marketSupplies = useApiCall<Balance[]>(ids && api.query.loans.totalSupply.multi, [ids]);

  useAsyncEffect(async () => {
    const apy = await getStakingApy(api);
    setStakingAPY(BigNumber(apy));
  }, []);

  const calcMarketStatus = useCallback(
    (
      marketStatus: Record<string, ITuple<[Rate, Rate, Rate, Ratio, Balance, Balance, FixedU128]>>
    ) => {
      if (
        ids &&
        marketSupplies &&
        !isEmpty(ids) &&
        !isEmpty(marketSupplies) &&
        !isEmpty(assetInfos)
      ) {
        const status = zipWith(ids, marketSupplies, (id, totalSupply) => {
          const [
            borrowRate,
            supplyRate,
            exchangeRate,
            util,
            totalBorrow,
            totalReserves,
            borrowIndex
          ] = marketStatus[id];
          const decimal = assetInfos.find(asset => asset.assetId === id)?.decimals ?? BN_ZERO;

          const isSRelayToken = id === config.sReplayAssetId;
          const supplyAPY = isSRelayToken
            ? getEffectiveAPY(stakingAPY ?? zero, supplyRate)
            : rateToNumber(supplyRate);
          const borrowAPY = isSRelayToken
            ? getEffectiveAPY(stakingAPY ?? zero, borrowRate)
            : rateToNumber(borrowRate);
          return {
            effectiveAPYLoading: isSRelayToken ? isNil(stakingAPY) : false,
            borrowRate: borrowAPY,
            supplyRate: supplyAPY,
            exchangeRate: rateToNumber(exchangeRate),
            util: ratioToNumber(util),
            totalBorrow: balanceToAmountByDecimal<number>(totalBorrow, decimal, 'number'),
            totalReserves: balanceToAmountByDecimal<number>(totalReserves, decimal, 'number'),
            totalSupply:
              balanceToAmountByDecimal<number>(totalSupply, decimal, 'number') *
              rateToNumber(exchangeRate),
            borrowIndex: balanceToAmountByDecimal<number>(borrowIndex, decimal, 'number')
          };
        });
        return zipObject(ids, status);
      }
      return undefined;
    },
    [assetInfos, ids, marketSupplies, stakingAPY]
  );

  const getBackupMarketAssetsInfo = useCallback(
    async (assetIds: string[]) => {
      const marketAssetsInfo = await Promise.all(
        assetIds.map(assetId =>
          Promise.all([
            api.query.loans.borrowRate(assetId),
            api.query.loans.supplyRate(assetId),
            api.query.loans.exchangeRate(assetId),
            api.query.loans.totalBorrows(assetId),
            api.query.loans.totalReserves(assetId),
            api.query.loans.borrowIndex(assetId)
          ])
        )
      );
      return marketAssetsInfo.map(
        ([borrowRate, supplyRate, exchangeRate, totalBorrow, totalReserves, borrowIndex]) => {
          return [
            borrowRate,
            supplyRate,
            exchangeRate,
            BN_ZERO as unknown as Ratio,
            totalBorrow,
            totalReserves,
            borrowIndex
          ] as unknown as ITuple<[Rate, Rate, Rate, Ratio, Balance, Balance, FixedU128]>;
        }
      );
    },
    [api.query.loans]
  );

  useAsyncEffect(async () => {
    if (ids) {
      const marketStatus = await Promise.allSettled<
        ITuple<[Rate, Rate, Rate, Ratio, Balance, Balance, FixedU128]>
      >(ids?.map(id => api.rpc.loans.getMarketStatus(id)) ?? []);

      const failedAssetsIds = ids.filter(
        (_, index) => marketStatus[index].status === 'rejected',
        []
      );

      const marketStatusMap = zipObject(
        ids,
        marketStatus.map(v => (v.status === 'fulfilled' ? v.value : null))
      );
      if (isEmpty(failedAssetsIds)) {
        const result = calcMarketStatus(
          marketStatusMap as Record<
            string,
            ITuple<[Rate, Rate, Rate, Ratio, Balance, Balance, FixedU128]>
          >
        );
        setState(prev => (isEqual(prev, result) ? prev : result));
      } else {
        const backupMarketAssetsInfo = await getBackupMarketAssetsInfo(failedAssetsIds);
        const backupMarketStatusMap = zipObject(failedAssetsIds, backupMarketAssetsInfo);

        const result = calcMarketStatus({ ...marketStatusMap, ...backupMarketStatusMap } as Record<
          string,
          ITuple<[Rate, Rate, Rate, Ratio, Balance, Balance, FixedU128]>
        >);
        setState(prev => (isEqual(prev, result) ? prev : result));
      }
    }
  }, [currentBlock, calcMarketStatus, api.rpc.loans, ids]);

  return state;
};

export default useAssetMarketStatus;
