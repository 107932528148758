import { BN } from '@polkadot/util';
import { AccountData, AccountInfo, AssetId, AssetMetadata } from '@polkadot/types/interfaces';
import { StorageKey } from '@polkadot/types';

export enum AcalaChainCurrency {
  ACA = 'ACA',
  AUSD = 'AUSD',
  LDOT = 'LDOT',
  LCDOT = 'lcDOT',
  KAR = 'KAR',
  LKSM = 'LKSM'
}

export type ChainMetadata = {
  assetsMetadata: [StorageKey<[AssetId]>, AssetMetadata][];
  accountInfo: AccountInfo;
  tokensInfo: any[];
};

export type BalanceInfo = AccountData & { frozen?: BN };

export type AssetsBaseInfo = Record<AcalaChainCurrency, { decimals: BN; assetId: string }>;

export type AcalaTokensInfo = Record<AcalaChainCurrency, BalanceInfo>;
