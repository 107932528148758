import {
  ReactNode,
  memo,
  useCallback,
  ChangeEvent,
  forwardRef,
  LabelHTMLAttributes,
  RefObject,
} from 'react';
import styled from 'styled-components';
import { useDualModeState } from '../../hooks/useDualModeState';

import { Size, Skin } from '../ThemeProvider/types';
import CheckedIcon from './check.svg';

export interface CheckboxCustProps {
  disabled?: boolean;
  value?: any;
  icon?: ReactNode;
  checkedIcon?: ReactNode;
  checked?: boolean;
  defaultChecked?: boolean;
  size?: Size;
  skin?: Skin;
  onChange?: (target: InputChangeTarget, e: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
}
export type CheckboxProps = Omit<LabelHTMLAttributes<HTMLLabelElement>, keyof CheckboxCustProps> &
  CheckboxCustProps;

export interface InputChangeTarget {
  checked: boolean;
  value?: any;
}

export const SIZE_TO_WIDTH_MAP: Record<Size, string> = {
  small: '13px',
  medium: '16px',
  large: '19px',
};
const StyledCheckbox = styled.label<CheckboxProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  border: ${({ checked, theme, skin = 'primary' }) =>
    checked ? `2px solid ${theme.skin[skin]?.main}` : `2px solid ${theme.skin.grey?.[300]}`};
  border-radius: 4px;
  width: ${({ size = 'medium' }) => SIZE_TO_WIDTH_MAP[size] ?? size};
  height: ${({ size = 'medium' }) => SIZE_TO_WIDTH_MAP[size] ?? size};
  opacity: ${({ disabled = false, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  box-sizing: border-box;
  & input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &:hover {
    border: ${({ theme, skin = 'primary', disabled }) =>
      disabled ? '' : `2px solid ${theme.skin[skin]?.main}`}}
  }
  ${({ checked, theme, skin = 'primary' }) =>
    checked ? `background: ${theme.skin[skin]?.main}` : ''}
`;

const DefaultIcon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const DefaultCheckedIcon = styled.img<CheckboxProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ skin = 'primary', theme }) => theme.skin[skin]?.main};
`;
export const CheckboxBase = memo(
  forwardRef<HTMLLabelElement, CheckboxProps>(
    (
      {
        checked,
        defaultChecked = false,
        disabled = false,
        size = 'medium',
        skin = 'primary',
        checkedIcon = <DefaultCheckedIcon src={CheckedIcon} skin={skin} size={size} />,
        icon = <DefaultIcon />,
        onChange,
        value,
        id,
        inputRef,
        ...otherProps
      },
      ref
    ) => {
      const [finalChecked, toggleInternalChecked] = useDualModeState(
        defaultChecked ?? false,
        checked
      );
      const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          toggleInternalChecked(e.target.checked);
          onChange?.(
            {
              checked: e.target.checked,
              value,
            },
            e
          );
        },
        [onChange, toggleInternalChecked, value]
      );
      return (
        <StyledCheckbox
          disabled={disabled}
          checked={finalChecked}
          skin={skin}
          size={size}
          ref={ref}
          {...otherProps}
        >
          <input
            type="checkbox"
            checked={finalChecked}
            disabled={disabled}
            onChange={handleChange}
            id={id}
            ref={inputRef}
          />
          {finalChecked ? checkedIcon : icon}
        </StyledCheckbox>
      );
    }
  )
);
