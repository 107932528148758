import { FC, ReactNode, useMemo } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { isFunction, isNil, mapValues, omit, values } from 'lodash';

import { DefaultTheme, useTheme } from 'styled-components';

import { ColorMode, RecursivePartial, ThemeConfig } from './types';
import { defaultThemeConfig } from './defaultThemeConfig';
import { Breakpoints, creatBreakpointFunctions } from './breakpoints';

export interface ThemeProviderProps {
  children?: ReactNode;
  themeConfig?: ThemeConfig | ((outerTheme: DefaultTheme) => DefaultTheme);
  mode?: ColorMode;
}

export const extractTheme = (config: RecursivePartial<ThemeConfig>, mode: ColorMode) => {
  const targetSkin = config[`${mode}Skin`];
  const { spacing: { base } = {}, components = {} } = config;

  const strippedTheme = omit(
    config,
    values(ColorMode).map(it => `${it}Skin`)
  );

  const spacingFunction = isNil(base) ? undefined : (num: number) => `calc(${base} * ${num})`;

  const extractedComponentsThemeOverrides = mapValues(components, it =>
    it ? extractTheme(it, mode) : it
  );

  return {
    mode,
    ...strippedTheme,
    skin: targetSkin,
    spacing: spacingFunction,
    breakpoints: config.breakpoints
      ? creatBreakpointFunctions(config.breakpoints as Breakpoints)
      : undefined,
    breakpointsConfig: config.breakpoints,
    components: extractedComponentsThemeOverrides,
  } as DefaultTheme;
};

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  themeConfig = defaultThemeConfig,
  mode = ColorMode.light,
}) => {
  const contextTheme = useTheme();

  const theme: DefaultTheme = useMemo(
    () => (isFunction(themeConfig) ? themeConfig(contextTheme) : extractTheme(themeConfig, mode)),
    [themeConfig, mode, contextTheme]
  );

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>;
};
