import { FC, useMemo } from 'react';
import { Inline, Text, Button, Tooltip, H5, Card } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';
import { chain } from 'lodash';

import { StyledDataGrid, StyledDataGridColumn } from '@/components';
import { Pool } from '@/pages/Farm/types';
import { useDevice } from '@/hooks';
import { balanceFormatter } from '@/utils/format';

export const TableWrapper = styled(Card)`
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: 0;
  `};
`;

export const GreenTag = styled(H5).attrs({ fontWeight: 'bold' })`
  color: var(--clr-green-pantone);
`;

type Props = {
  pools: Pool[];
  connected: boolean;
  onDeposit: (pool: Pool) => void;
  onClaim: (pool: Pool) => void;
  onWithdraw: (pool: Pool) => void;
  onRedeem: (pool: Pool) => void;
  loading?: boolean;
};

const formatApy = (apy: number) => {
  return formatNumber(apy, { output: 'percent', decimal: 2, threshold: { max: 100 } });
};

const FarmingPoolTable: FC<Props> = ({
  pools,
  connected,
  onDeposit,
  onClaim,
  onWithdraw,
  onRedeem,
  loading = false
}) => {
  const availablePools = chain(pools)
    .filter(pool => pool.active || pool.user.rewards > 0 || pool.user.deposited > 0)
    .orderBy(pool => pool.tradingFeeApy * 100 + pool.farmingApy * 100, ['desc'])
    .value();
  const claimAvailable = (pool: Pool) =>
    pool.user.rewards > 0 && pool.user.pendingWithdrawals.length <= 100;
  const withdrawAvailable = (pool: Pool) =>
    pool.lockUpLeft <= 0 && pool.user.deposited > 0 && pool.user.pendingWithdrawals.length <= 100;
  const { isMobile } = useDevice();

  const columns = useMemo<StyledDataGridColumn<Pool>[]>(
    () => [
      {
        name: 'pool',
        title: 'Pool',
        width: '3fr',
        isAvatar: true,
        render: ({
          data: {
            asset: { name }
          }
        }) => (
          <Inline gap="0.75rem" alignItems="center">
            <CryptoAsset symbol={name} />
            <Text>{name}</Text>
          </Inline>
        )
      },
      {
        name: 'apy',
        title: (
          <Inline gap="0.25rem">
            <Text>Total APY</Text>
            <Tooltip content="Total yield (trading fees + farming) with daily compounding." />
          </Inline>
        ),
        width: '2fr',
        render: ({ data: { farmingApy, tradingFeeApy } }) => {
          const apys =
            tradingFeeApy > 0
              ? [formatApy(tradingFeeApy), formatApy(farmingApy)]
              : [formatApy(farmingApy)];
          return (
            <H5 skin="success" fontWeight="medium">
              {apys.join(' + ')}
            </H5>
          );
        }
      },
      {
        name: 'tvl',
        title: 'TVL',
        width: '2fr',
        render: ({ data: { tvl } }) => <Text>{formatNumber(tvl, { output: 'currency' })}</Text>
      },
      {
        name: 'deposited',
        width: '2fr',
        title: 'Deposited',
        render: ({
          data: {
            asset: { name },
            user: { deposited }
          }
        }) => (
          <Text>
            {balanceFormatter(deposited)} {name}
          </Text>
        )
      },
      {
        name: 'earned',
        width: '2fr',
        title: 'Earned',
        render: ({
          data: {
            rewardAsset: { name },
            user: { rewards }
          }
        }) => (
          <Text>
            {balanceFormatter(rewards)} {name}
          </Text>
        )
      },
      {
        name: 'actions',
        title: '',
        width: '3fr',
        hidden: isMobile,
        render: ({ data: pool }) => {
          const redeemActive = pool.user.pendingWithdrawals.some(pending => pending.timeLeft <= 0);
          return (
            <Inline alignItems="center" gap="2rem">
              {pool.coolDownDuration > 0 ? (
                <Tooltip
                  content="You can redeem after cool down period ends."
                  disabled={redeemActive}
                >
                  <Button skin="primary" disabled={!redeemActive} onClick={() => onRedeem(pool)}>
                    Redeem
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  content={
                    !connected
                      ? 'Connect wallet to deposit.'
                      : 'You can no longer deposit because this pool is closed. You can still claim or withdraw from the pool.'
                  }
                  disabled={pool.active && connected}
                >
                  <Button
                    skin="primary"
                    disabled={!pool.active || !connected}
                    onClick={() => onDeposit(pool)}
                  >
                    Deposit
                  </Button>
                </Tooltip>
              )}

              <Tooltip content="No claimable balance." disabled={pool.user.rewards > 0}>
                <Button
                  variant="link"
                  disabled={!claimAvailable(pool)}
                  onClick={() => {
                    if (claimAvailable(pool)) onClaim(pool);
                  }}
                >
                  Claim
                </Button>
              </Tooltip>
              <Tooltip content="No balance deposited." disabled={pool.user.deposited > 0}>
                <Button
                  variant="link"
                  disabled={!withdrawAvailable(pool)}
                  onClick={() => {
                    if (withdrawAvailable(pool)) onWithdraw(pool);
                  }}
                >
                  Withdraw
                </Button>
              </Tooltip>
            </Inline>
          );
        }
      }
    ],
    [connected, isMobile, onClaim, onDeposit, onRedeem, onWithdraw]
  );
  return (
    <TableWrapper>
      <StyledDataGrid<Pool>
        title="Farming"
        columns={columns}
        data={availablePools}
        loading={loading}
      />
    </TableWrapper>
  );
};

export default FarmingPoolTable;
