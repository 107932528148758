import { useState, FC, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { isBoolean } from 'lodash';
import { Inline, H5, Collapse as RawCollapse, Icon, Card } from '@parallel-mono/components';

interface CollapseProps {
  header: ReactNode;
  expand?: boolean;
  containerPadding?: string;
}

const Container = styled(Card)<{ padding?: string }>`
  padding: ${({ padding }) => padding || '1.5rem 2rem'};
`;

const Header = styled(Inline)`
  cursor: pointer;
`;

const FullWidthH5 = styled(H5)`
  width: 100%;
`;

const AnimateRotateImage = styled(Icon)<{ $expand: boolean }>`
  transform: ${({ $expand }) => ($expand ? 'matrix(1, 0, 0, -1, 0, 0)' : 0)};
  transition: all 0.2s;
`;

const Collapse: FC<CollapseProps> = ({ header, children, expand, containerPadding, ...props }) => {
  const [showContent, setShowContent] = useState<boolean>(expand ?? false);

  useEffect(() => {
    if (isBoolean(expand)) setShowContent(expand);
  }, [expand]);

  return (
    <Container padding={containerPadding} {...props}>
      <Header
        justifyContent="space-between"
        alignItems="center"
        onClick={() => {
          setShowContent(!showContent);
        }}
        gap="1rem"
      >
        <FullWidthH5 forwardedAs="div">{header}</FullWidthH5>
        <AnimateRotateImage name="down" $expand={showContent} />
      </Header>
      <RawCollapse open={showContent}>{children}</RawCollapse>
    </Container>
  );
};

export default Collapse;
