import { useState } from 'react';

import ammApi from '../apis';

import config from '@/config';
import useAsyncEffect from '@/hooks/useAsyncEffect';

export const useAmmDetails = () => {
  const [isReady, setIsReady] = useState(false);
  const [ammDetails, setAmmDetails] = useState<Record<string, any>>();

  useAsyncEffect(async () => {
    const details = await ammApi({
      selectedNetwork: config.relayChain
    });
    setAmmDetails(details);
    setIsReady(true);
  }, []);

  return {
    isReady,
    ammDetails
  };
};
