import { has, find } from 'lodash';
import qs from 'qs';

import config from '@/config';

type TogglesName = keyof typeof config.TOGGLES;

const getFeatureToggle = (name: string): boolean => {
  const search = window.location.search || window.location.hash.split('?')[1];
  const toggles = config.TOGGLES;
  if (has(toggles, name)) {
    const localToggleEnabled = toggles[name as TogglesName];
    const queryStringToggleValue = find(
      qs.parse(search, { ignoreQueryPrefix: true }),
      (_, key) => key === name
    );
    const urlToggleEnabled =
      queryStringToggleValue !== undefined && queryStringToggleValue !== 'false';
    if (config.PROD) {
      return localToggleEnabled || urlToggleEnabled;
    }
    return queryStringToggleValue === undefined ? Boolean(localToggleEnabled) : urlToggleEnabled;
  }
  return false;
};

export default getFeatureToggle;
