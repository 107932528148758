import { merge } from 'lodash';
import { forwardRef, memo, useMemo, createElement, ComponentType } from 'react';
import { useTheme, ThemeProvider } from 'styled-components';
import { OverridableComponents } from '../common/ThemeProvider';

export const withComponentThemeOverride = <C>(Component: C, name: OverridableComponents): C => {
  return memo(
    forwardRef((props, ref) => {
      const contextTheme = useTheme();
      const themeOverrides = contextTheme?.components?.[name];
      const mergedTheme = useMemo(
        () => merge({}, contextTheme, themeOverrides),
        [contextTheme, themeOverrides]
      );

      // TODO fix typing
      const originalContent = createElement(
        Component as unknown as ComponentType,
        {
          ...props,
          ref,
        } as any
      );

      if (!themeOverrides) {
        return originalContent;
      }

      return createElement(ThemeProvider, { theme: mergedTheme }, originalContent);
    })
  ) as unknown as C;
};
