import { Stack, H2, H3, Text, Card, Icon } from '@parallel-mono/components';

import { ConnectToWallet } from '@/components';

const NotConnected = ({ title }: { title?: string }) => (
  <Card inset="0">
    <Stack inset="2rem" gap="4rem">
      {title && <H2>{title}</H2>}
      <Stack gap="1.5rem" alignItems="center">
        <Icon name="wallet" size="xlarge" />
        <H3>Connect Wallet</H3>
        <Text>Connect wallet to view your assets in Parallel.</Text>
        <ConnectToWallet skin="secondary">Connect Wallet</ConnectToWallet>
      </Stack>
    </Stack>
  </Card>
);

export default NotConnected;
