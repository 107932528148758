import { AbstractChain } from '../AbstractChain';
import { Chains, TxCallback } from '../types';
import { TxFee } from '../../types';

import { calculateAssetsInfo } from './helpers/calculateAssetsInfo';
import { TO_CHAINS, CURRENCIES, MINIMUM_TRANSFER_AMOUNT } from './constants';
import { generateTxs } from './helpers/generateTxs';
import { ChainMetadata } from './types';

import { AssetDetailInfo } from '@/hooks/types';
import { signAndSend, txFee } from '@/utils/txCall';

export class PolkadotChain extends AbstractChain<ChainMetadata> {
  static toChains = TO_CHAINS;

  static currencies = CURRENCIES;

  static minimumTransferAmount = MINIMUM_TRANSFER_AMOUNT;

  metadataSubscribe = () => {
    const { api, account, subscribeValue } = this;
    subscribeValue(api.rpc.system.properties, [], 'chainProperties');
    subscribeValue(account && api.query.system.account, [account?.address], 'accountInfo');
  };

  calculateAssetsInfo = async () => {
    const { api, metadata } = this;
    const assetsInfo = await calculateAssetsInfo(api, metadata);
    return assetsInfo;
  };

  getTxFee = async (
    amount: number,
    asset: AssetDetailInfo,
    nativeAsset: AssetDetailInfo
  ): Promise<TxFee> => {
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount);
    const fee = amount ? await txFee({ tx, account }) : 0;

    return { currency: nativeAsset.symbol, fee };
  };

  transfer = (
    amount: number,
    asset: AssetDetailInfo,
    _toChain: Chains,
    options?: TxCallback
  ): void => {
    const { api, account } = this;
    const tx = generateTxs(api, account, asset, amount);

    signAndSend({ api, tx, account, ...(options || {}) });
  };
}
