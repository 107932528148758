import {
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useState,
  useImperativeHandle,
  useRef,
  KeyboardEventHandler,
  useMemo,
  useEffect,
} from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import cls from 'classnames';
import { useDualModeState } from '../../hooks/useDualModeState';
import { Collapse } from '../Collapse';
import { useClickAway } from 'react-use';
import { genericMemo } from '../../utils/genericMemo';
import { genericForwardRef } from '../../utils/genericForwardRef';
import { useCallbackMerge } from '../../hooks/useCallbackMerge';
import { Skin } from '../ThemeProvider';
import { Icon } from '../icon';
import { InputContainer } from '../InputContainer';
import { isArray, sortBy } from 'lodash';

const Container = styled(InputContainer)`
  position: relative;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  outline: none;
`;

const DisplayContentWrap = styled.div<{ autocomplete: boolean }>`
  display: flex;
  flex-grow: 1;
  user-select: none;
  margin-right: ${({ theme }) => theme.spacing(1)};
  position: relative;
  ${({ autocomplete }) => autocomplete && 'cursor: text'};
  overflow: auto;
`;

const StyledArrow = styled(Icon)<{ $arrowUp: boolean }>`
  flex-shrink: 0;
  transform: ${({ $arrowUp }) => ($arrowUp ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: transform 0.2s ease-in-out;
`;
const StyledCollapse = styled(Collapse)`
  box-shadow: ${({ theme }) => theme.shadows.secondary};
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 100%;
  z-index: 9999;
  border-radius: 12px;
  background-color: ${({ theme }) => theme?.skin.background.main};
  margin-top: ${({ theme }) => theme.spacing(1)};
  width: fit-content;

  > div {
    overflow: auto;
  }
`;
const OptionsWrap = styled.div`
  max-height: 400px;
`;

const PlaceholderContainer = styled.span`
  color: ${({ theme }) => theme.skin.text.secondary};
`;

const Option = styled.div<{ disabled?: boolean; focused: boolean }>`
  ${({ disabled = false }) =>
    disabled
      ? css`
          opacity: ${({ theme }) => theme.skin.action.disabledOpacity};
        `
      : ''}
  ${({ focused }) =>
    focused
      ? css`
          background: ${({ theme }) => theme.skin.action.hoverMask};
        `
      : ''}
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
`;

const Empty = styled.div`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
`;

const StyledLabel = styled.div<{ selected?: boolean; focused: boolean; skin: Skin }>`
  padding: ${({ theme }) => `${theme.spacing(4)} ${theme.spacing(6)}`};
  ${({ selected = false, focused, skin }) =>
    selected
      ? css`
          background: ${({ theme }) =>
            focused ? theme.skin.action.hoverMask : theme?.skin[skin].contrastBackground};
          color: ${({ theme }) => theme?.skin[skin].main};
        `
      : ''}
`;

const SelectInput = styled.input<{ hideToBack: boolean; fixedPosition: boolean }>`
  outline: none;
  border: none;
  ${({ hideToBack }) => hideToBack && 'z-index: -999;'}
  ${({ fixedPosition }) => fixedPosition && 'position: absolute;'}
  width: 100%;
  min-width: 10%;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-family: ${({ theme }) => theme.fontFamily};
`;

const MultiDisplayContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const ClosableContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

const CloseButton = styled(Icon).attrs({ name: 'close', size: 'small' })`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(1)};
  padding: 1px;
  &:hover {
    background: ${({ theme }) => theme.skin.action.hoverMask};
  }
`;

export interface SelectBaseOption {
  label: ReactNode;
  value: any;
  disabled?: boolean;
}

export interface SelectOwnProps<P extends SelectBaseOption = SelectBaseOption> {
  options: P[];
  value?: P['value'] | null | P['value'][];
  defaultValue?: P['value'];
  disabled?: boolean;
  renderItem?: (option: P, index: number, selectedOption: P | null | P[]) => ReactNode;
  renderDisplay?: (option: P | null) => ReactNode;
  onChange?: ((option: P | null) => void) | ((options: P[]) => void);
  emptyMenu?: ReactNode;
  classNames?: Partial<{
    menu: string;
    item: string;
    dropdownIcon: string;
    display: string;
    itemBlock: string;
  }>;
  showArrow?: boolean;
  skin?: Skin;
  displayStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  clearable?: boolean;
  autocomplete?: boolean;
  rankingFunc?: (option: P, input: string) => number;
  multiselect?: boolean;
}

const defaultRankingFunc = <P extends SelectBaseOption>(option: P, input: string) =>
  option.label?.toString().toLowerCase().includes(input.toLowerCase()) ? 1 : 0;

export type SelectProps<P extends SelectBaseOption = SelectBaseOption> = Omit<
  HTMLAttributes<HTMLDivElement>,
  keyof SelectOwnProps<P> | 'children'
> &
  SelectOwnProps<P>;

export const Select = genericMemo(
  genericForwardRef(function <P extends SelectBaseOption = SelectBaseOption>(
    props: SelectProps<P>,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    const {
      options,
      onChange,
      renderDisplay,
      disabled = false,
      renderItem,
      classNames,
      value,
      defaultValue,
      emptyMenu,
      onClick,
      showArrow = true,
      skin = 'primary',
      displayStyle,
      menuStyle,
      clearable,
      placeholder = 'Select item',
      autocomplete = false,
      rankingFunc = defaultRankingFunc,
      multiselect = false,
      ...otherProps
    } = props;
    const [showOptions, setShowOptions] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const defaultOption = options.filter(option => option.value === defaultValue);
    const initialOptions = options.filter(option => {
      if (isArray(value)) {
        return value.includes(option.value);
      }
      return value === option.value;
    });
    const [selectedOptions, setInnerSelectedOptions] = useDualModeState<P[]>(
      defaultOption,
      value !== undefined ? initialOptions : undefined
    );
    const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);
    const optionsRefs = useRef<(HTMLDivElement | null)[]>([]);
    const menuRef = useRef<HTMLDivElement>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [availableOptions, setAvailableOptions] = useState<P[]>(options);
    const visibleOptions = useMemo(
      () =>
        multiselect ? availableOptions.filter(o => !selectedOptions.includes(o)) : availableOptions,
      [availableOptions, multiselect, selectedOptions]
    );

    useEffect(() => {
      setAvailableOptions(options);
    }, [options]);

    const renderDisplayContent = useCallback(() => {
      const selectedOption = selectedOptions[0] ?? null;
      if (renderDisplay) {
        return renderDisplay(selectedOption);
      }
      return <span>{selectedOption?.label}</span>;
    }, [renderDisplay, selectedOptions]);

    const renderMultiDisplayContent = useCallback(
      () => (
        <MultiDisplayContentWrapper>
          {selectedOptions.map((option, index) => {
            const content = renderDisplay ? renderDisplay(option) : <span>{option.label}</span>;
            return (
              <ClosableContentWrapper className={classNames?.itemBlock}>
                {content}
                <CloseButton
                  onClick={e => {
                    const updatedOptions = [
                      ...selectedOptions.slice(0, index),
                      ...selectedOptions.slice(index + 1, selectedOptions.length),
                    ];
                    setInnerSelectedOptions(updatedOptions);
                    (onChange as (options: P[]) => void)?.(updatedOptions);
                    e.stopPropagation();
                  }}
                />
              </ClosableContentWrapper>
            );
          })}
        </MultiDisplayContentWrapper>
      ),
      [classNames?.itemBlock, renderDisplay, selectedOptions, setInnerSelectedOptions, onChange]
    );

    const renderEmpty = useCallback(() => {
      if (emptyMenu) {
        return emptyMenu;
      }
      return <Empty>No Option Data</Empty>;
    }, [emptyMenu]);

    const focusToIndex = useCallback(
      (index: number | null) => {
        if (index !== null) {
          const nextIndex =
            ((index % visibleOptions.length) + visibleOptions.length) % visibleOptions.length;
          setFocusedOptionIndex(nextIndex);
          optionsRefs.current[nextIndex]?.scrollIntoView({ block: 'nearest' });
        }
      },
      [visibleOptions.length]
    );

    const moveFocusedIndex = useCallback(
      ({ absolute, relative }: { absolute?: number; relative?: number }) => {
        if (absolute !== undefined && absolute >= 0) {
          focusToIndex(absolute);
        } else if (relative) {
          if (focusedOptionIndex === null) {
            focusToIndex(relative > 0 ? 0 : options.length - 1);
          } else {
            focusToIndex(focusedOptionIndex + relative);
          }
        }
      },
      [focusToIndex, focusedOptionIndex, options.length]
    );

    const closeOptions = useCallback(() => {
      setShowOptions(false);
      setFocusedOptionIndex(null);
      setInputValue('');
      setAvailableOptions(options);
    }, [options]);

    const openOptions = useCallback(() => {
      setShowOptions(true);
      if (!multiselect) {
        moveFocusedIndex({
          absolute: selectedOptions.length ? visibleOptions.indexOf(selectedOptions[0]) : undefined,
        });
      }
    }, [visibleOptions, moveFocusedIndex, multiselect, selectedOptions]);

    const clickDisplay = useCallback(() => {
      if (showOptions) {
        closeOptions();
      } else {
        openOptions();
      }
    }, [closeOptions, openOptions, showOptions]);

    const handleDisplayClick = useCallbackMerge(onClick, clickDisplay);

    const handleOptionClick = useCallback(
      (option: P) => {
        if (!option.disabled) {
          closeOptions();
          if (!selectedOptions.includes(option)) {
            const newOptions = multiselect ? [...selectedOptions, option] : [option];
            setInnerSelectedOptions(newOptions);
            onChange?.((multiselect ? newOptions : option) as any);
          }
        }
      },
      [closeOptions, selectedOptions, multiselect, setInnerSelectedOptions, onChange]
    );

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
      e => {
        if (
          (clearable || multiselect) &&
          (e.key === 'Backspace' || e.key === 'Delete') &&
          !inputValue
        ) {
          const remainedOptions = selectedOptions.slice(0, selectedOptions.length - 1);
          setInnerSelectedOptions(remainedOptions);
          onChange?.((multiselect ? remainedOptions : remainedOptions[0] ?? null) as any);
        } else if (showOptions) {
          switch (e.key) {
            case ' ':
              if (!autocomplete) {
                if (focusedOptionIndex === null) {
                  closeOptions();
                } else {
                  handleOptionClick(visibleOptions[focusedOptionIndex]);
                }
              }
              break;
            case 'Tab':
              e.preventDefault();
              break;
            case 'Enter':
              if (focusedOptionIndex !== null) {
                handleOptionClick(visibleOptions[focusedOptionIndex]);
              }
              break;
            case 'Escape':
              closeOptions();
              break;
            case 'ArrowDown':
              e.preventDefault();
              moveFocusedIndex({ relative: 1 });
              break;
            case 'ArrowUp':
              e.preventDefault();
              moveFocusedIndex({ relative: -1 });
              break;
            case 'Home':
              e.preventDefault();
              moveFocusedIndex({ absolute: 0 });
              break;
            case 'End':
              e.preventDefault();
              moveFocusedIndex({ absolute: visibleOptions.length - 1 });
              break;
            case 'PageUp':
              if (menuRef.current?.firstElementChild) {
                menuRef.current.firstElementChild.scrollTop -=
                  menuRef.current.firstElementChild.clientHeight;
              }
              break;
            case 'PageDown':
              if (menuRef.current?.firstElementChild) {
                menuRef.current.firstElementChild.scrollTop +=
                  menuRef.current.firstElementChild.clientHeight;
              }
              break;
            default:
              return;
          }
        } else if (e.key === ' ' && !autocomplete) {
          openOptions();
        }
      },
      [
        autocomplete,
        visibleOptions,
        clearable,
        closeOptions,
        focusedOptionIndex,
        handleOptionClick,
        inputValue,
        moveFocusedIndex,
        multiselect,
        onChange,
        openOptions,
        selectedOptions,
        setInnerSelectedOptions,
        showOptions,
      ]
    );

    const searchAvailableOptions = useCallback(
      (input: string) => {
        setFocusedOptionIndex(null);
        if (!showOptions) {
          setShowOptions(true);
        }
        if (input) {
          const ranked = options
            .map(o => ({ ranking: rankingFunc(o, input), option: o }))
            .filter(({ ranking }) => ranking > 0);
          const matchedOptions = sortBy(ranked, 'ranking').map(({ option }) => option);
          setAvailableOptions(matchedOptions);
        } else {
          setAvailableOptions(options);
        }
      },
      [options, rankingFunc, showOptions]
    );

    useImperativeHandle(ref, () => selectRef.current as HTMLDivElement);
    useClickAway(selectRef, closeOptions);
    return (
      <Container
        onClick={disabled ? undefined : handleDisplayClick}
        onKeyDown={disabled ? undefined : handleKeyDown}
        onFocus={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        disabled={disabled}
        ref={selectRef}
        skin={skin}
        tabIndex={0}
        {...otherProps}
      >
        <DisplayContentWrap
          className={cls(classNames?.display)}
          style={displayStyle}
          autocomplete={autocomplete}
        >
          {!selectedOptions.length && <PlaceholderContainer>{placeholder}</PlaceholderContainer>}
          {multiselect ? renderMultiDisplayContent() : renderDisplayContent()}
          {autocomplete && (
            <SelectInput
              ref={inputRef}
              value={inputValue}
              hideToBack={!inputValue}
              fixedPosition={!multiselect || !selectedOptions.length}
              onChange={({ target: { value } }) => {
                setInputValue(value);
                searchAvailableOptions(value);
              }}
            />
          )}
        </DisplayContentWrap>
        {showArrow && (
          <StyledArrow
            name="down"
            $arrowUp={showOptions}
            className={cls(classNames?.dropdownIcon)}
          />
        )}
        <StyledCollapse
          open={showOptions}
          style={menuStyle}
          className={cls(classNames?.menu)}
          ref={menuRef}
        >
          <OptionsWrap>
            {visibleOptions.map((option, index) => {
              const { label, value, disabled = false, ...otherOptionProps } = option;
              return (
                <Option
                  onClick={() => handleOptionClick(option)}
                  onMouseOver={() => setFocusedOptionIndex(index)}
                  className={cls(classNames?.item)}
                  disabled={disabled}
                  focused={focusedOptionIndex === index}
                  {...otherOptionProps}
                  key={option.value}
                  ref={c => (optionsRefs.current[index] = c)}
                >
                  {renderItem ? (
                    renderItem(
                      option,
                      index,
                      multiselect ? selectedOptions : selectedOptions[0] ?? null
                    )
                  ) : (
                    <StyledLabel
                      selected={selectedOptions.includes(option)}
                      focused={focusedOptionIndex === index}
                      skin={skin}
                    >
                      {label}
                    </StyledLabel>
                  )}
                </Option>
              );
            })}
            {!visibleOptions.length && renderEmpty()}
          </OptionsWrap>
        </StyledCollapse>
      </Container>
    );
  })
);
