import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';

import config from '@/config';
import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';

export const generateTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  const { xcmVersion, paraId } = config;
  const params = [
    {
      [xcmVersion]: {
        parents: 0,
        interior: {
          X1: {
            Parachain: paraId
          }
        }
      }
    },
    {
      [xcmVersion]: {
        parents: 0,
        interior: {
          X1: {
            AccountId32: {
              network: 'Any',
              id: addressToHex(account.address)
            }
          }
        }
      }
    },
    {
      [xcmVersion]: [
        {
          id: {
            Concrete: {
              parents: 0,
              interior: 'Here'
            }
          },
          fun: {
            Fungible: amountToBalanceByDecimals<BN>(amount, asset.decimals, 'bn')
          }
        }
      ]
    },
    0
  ];

  return api.tx.xcmPallet.reserveTransferAssets(...params);
};
