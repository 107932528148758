import { useEffect, useMemo, useState } from 'react';
import { Deposits, Rate } from '@parallel-finance/types/interfaces';
import { pickBy, zipObject, zipWith } from 'lodash';
import { BN_ZERO } from '@polkadot/util';

import { useChainConnections, useAccount, useApiCall } from '@/hooks';
import { AssetsData, CurrencyId } from '@/hooks/types';
import { rateToNumber } from '@/utils/utils';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { NonEmptyArray } from '@/typings/basic';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

const useAccountAssetsDeposit = (ids?: NonEmptyArray<CurrencyId>) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();
  const { assetInfos } = useCurrentAccountAssetInfos();

  const [collaterals, setCollaterals] = useState<CurrencyId[] | undefined>();
  const [supplies, setSupplies] = useState<AssetsData | undefined>();

  const exchangeRates = useApiCall<Rate[]>(ids && api.query.loans.exchangeRate.multi, [ids]);

  const accountDeposits = useApiCall<Deposits[]>(
    account && ids && api.query.loans.accountDeposits.multi,
    [ids?.map(id => [id, account?.address])]
  );

  useEffect(() => {
    if (ids && accountDeposits) {
      setCollaterals(ids.filter((_, index) => accountDeposits[index].isCollateral.isTrue));
    }
  }, [accountDeposits, ids]);

  useEffect(() => {
    if (exchangeRates && accountDeposits && assetInfos && ids) {
      const values = zipWith(ids, exchangeRates, accountDeposits, (id, rate, deposit) => {
        const decimals = assetInfos.find(asset => asset.assetId === id)?.decimals ?? BN_ZERO;
        return (
          balanceToAmountByDecimal<number>(deposit.voucherBalance, decimals, 'number') *
          rateToNumber(rate)
        );
      });

      setSupplies(pickBy(zipObject(ids, values), value => value > 0));
    }
  }, [accountDeposits, exchangeRates, assetInfos, ids]);

  return useMemo(() => ({ collaterals, supplies }), [collaterals, supplies]);
};

export default useAccountAssetsDeposit;
