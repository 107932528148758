import { memo, FC, ReactNode } from 'react';

import useConnectChains from '@/hooks/useConnectChains';
import { Chains } from '@/hooks';

type Props = {
  initialChains?: Chains[];
  loadingComponent: ReactNode;
  children: ReactNode;
};

const PageWrapper: FC<Props> = ({ initialChains, loadingComponent, children }) => {
  const { isChainsReady } = useConnectChains(initialChains);
  const isReady = initialChains ? isChainsReady(initialChains) : true;
  return <>{isReady ? children : loadingComponent}</>;
};

export default memo(PageWrapper);
