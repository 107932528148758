import { Chains } from '../types';

import { InterlayChainCurrency } from './types';

import config, { Chain } from '@/config';

export const TO_CHAINS = [Chains.Parallel];

export const CURRENCIES: Record<Chains.Parallel, InterlayChainCurrency[]> = {
  [Chain.PARALLEL]: {
    [Chains.Parallel]: [InterlayChainCurrency.INTR, InterlayChainCurrency.IBTC]
  },
  [Chain.HEIKO]: {
    [Chains.Parallel]: [InterlayChainCurrency.KINT, InterlayChainCurrency.KBTC]
  }
}[config.chain];

export const INTERLAY_NATIVE_TOKEN = {
  [Chain.PARALLEL]: InterlayChainCurrency.INTR,
  [Chain.HEIKO]: InterlayChainCurrency.KINT
}[config.chain];

export const MINIMUM_TRANSFER_AMOUNT = {
  [Chain.PARALLEL]: {
    [InterlayChainCurrency.INTR]: 1.5,
    [InterlayChainCurrency.IBTC]: 0.0001
  },
  [Chain.HEIKO]: {
    [InterlayChainCurrency.KINT]: 1.5,
    [InterlayChainCurrency.KBTC]: 0.0001
  }
}[config.chain];

export const MAXIMUM_TX_FEE = {
  [Chain.PARALLEL]: 0.02,
  [Chain.HEIKO]: 0.02
}[config.chain];
