import { ApiPromise } from '@polkadot/api';
import { zipWith } from 'lodash';
import { BN_ZERO } from '@polkadot/util';
import BigNumber from 'bignumber.js';

import { ChainMetadata } from '../types';
import { MAXIMUM_TX_FEE } from '../constants';

import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import config from '@/config';
import { AssetDetailInfo, CurrencyId } from '@/hooks/types';

export const calculateAssetsInfo = async (api: ApiPromise, chainMetadata: ChainMetadata) => {
  const { assetsMetadata, assetsBalance, chainProperties, accountInfo } = chainMetadata;

  const chainName = await api.rpc.system.chain();

  const assetIds: CurrencyId[] =
    assetsMetadata?.flatMap(m => m[0]).map(({ args: [id] }) => id.toString()) || [];

  const nativeCurrencyId = api.consts.currencyAdapter.getNativeCurrencyId.toString();
  const { existentialDeposit } = api.consts.balances;

  if (assetsMetadata && chainName && chainProperties && accountInfo) {
    const { tokenSymbol, tokenDecimals } = chainProperties;
    const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[0] : BN_ZERO;
    const symbol = tokenSymbol.isSome ? tokenSymbol.unwrap()[0].toString() : '';
    const {
      data: { free, miscFrozen, feeFrozen }
    } = accountInfo;
    const lockedBalance = BigNumber.max(miscFrozen.toString(), feeFrozen.toString());
    const availableNativeBalance = BigNumber.max(
      BigNumber(free.toString()).minus(lockedBalance).minus(existentialDeposit.toString()),
      0
    );
    const maxTxFee = amountToBalanceByDecimals<BigNumber>(MAXIMUM_TX_FEE, decimals, 'bigNumber');
    const maxAvailableNativeBalance = BigNumber.max(availableNativeBalance.minus(maxTxFee), 0);
    const nativeAssetInfo: AssetDetailInfo = {
      assetId: nativeCurrencyId,
      network: chainName,
      name: chainName.toString(),
      symbol,
      decimals,
      existentialDeposit: balanceToAmountByDecimal<BigNumber>(
        existentialDeposit,
        decimals,
        'bigNumber'
      ),
      balance: balanceToAmountByDecimal<BigNumber>(free, decimals, 'bigNumber'),
      lockedBalance: balanceToAmountByDecimal<BigNumber>(lockedBalance, decimals, 'bigNumber'),
      availableBalance: balanceToAmountByDecimal<BigNumber>(
        availableNativeBalance,
        decimals,
        'bigNumber'
      ),
      maxAvailableBalance: balanceToAmountByDecimal<BigNumber>(
        maxAvailableNativeBalance,
        decimals,
        'bigNumber'
      ),
      isNative: true
    };

    const metadatas = assetsMetadata.flatMap(m => m[1]);
    const infos = zipWith(assetIds, metadatas, assetsBalance || [], (key, metadata, balanceObj) => {
      const balanceBn =
        balanceObj && balanceObj.isSome ? balanceObj.unwrap().balance.toBn() : BN_ZERO;
      const balance = balanceToAmountByDecimal<BigNumber>(
        balanceBn,
        metadata.decimals,
        'bigNumber'
      );

      return {
        assetId: key,
        network: chainName || config.relayChain,
        name: metadata.name.toHuman() as string,
        symbol: metadata.symbol.toHuman() as string,
        decimals: metadata.decimals,
        existentialDeposit: BigNumber(0),
        balance,
        lockedBalance: BigNumber(0),
        availableBalance: balance,
        maxAvailableBalance: balance,
        isNative: false
      };
    });
    return [...infos, nativeAssetInfo];
  }
  return undefined;
};
