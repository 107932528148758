import { useMemo, createContext, FC, useState } from 'react';
import { union } from 'lodash';
import { BN } from '@polkadot/util';
import { Option, Text } from '@polkadot/types';
// removed due to breaking change to codec type
// import { PalletStakingNominations } from '@polkadot/types/lookup';

import { getStakingApy } from '../../utils/stakingHelper/getStakingApy';

import { useChainConnections, useDerivativeAccount, useApiCall } from '@/hooks';
import { getStakingCurrencyId, getLiquidCurrencyId } from '@/pages/Staking/apis/staking';
import { AssetInfo } from '@/hooks/types';
import config from '@/config';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

export interface Currencies {
  stakingAsset: AssetInfo;
  liquidAsset: AssetInfo;
}

const baseDefaultCurrency = {
  network: undefined,
  balance: 0,
  existentialDeposit: 0,
  lockedBalance: 0,
  availableBalance: 0,
  maxAvailableBalance: 0,
  isNative: false
};
const defaultStakingCurrency: AssetInfo = {
  ...baseDefaultCurrency,
  symbol: config.isParallel ? 'DOT' : 'KSM',
  decimals: new BN(config.isParallel ? 10 : 12),
  assetId: config.isParallel ? '101' : '100'
};

const defaultLiquidCurrency: AssetInfo = {
  ...baseDefaultCurrency,
  symbol: config.isParallel ? 'SDOT' : 'SKSM',
  decimals: new BN(config.isParallel ? 10 : 12),
  assetId: config.isParallel ? '1001' : '1000'
};

export const StakingScopeContext = createContext<{
  currencies: Currencies;
  nominationLength: number;
  chainName: string;
  stakingAPY: number;
  stakingAPYLoaded: boolean;
}>({
  currencies: { stakingAsset: defaultStakingCurrency, liquidAsset: defaultLiquidCurrency },
  nominationLength: 0,
  chainName: '',
  stakingAPY: 0,
  stakingAPYLoaded: false
});

const StakingContextWraper: FC<{}> = ({ children }) => {
  const [stakingAPY, setStakingAPY] = useState(0);
  const [stakingAPYLoaded, setStakingAPYLoaded] = useState(false);
  const {
    parachain: { api },
    relaychain: { api: relayApi }
  } = useChainConnections();
  const { assetInfos } = useCurrentAccountAssetInfos();
  const derivativeAccount = useDerivativeAccount();
  const chainName = useApiCall<Text>(api.rpc.system.chain)?.toHuman() ?? 'Parallel';

  const stakingAssetId = getStakingCurrencyId(api).toString();
  const liquidAssetId = getLiquidCurrencyId(api).toString();

  const currencies: Currencies = useMemo(() => {
    return {
      stakingAsset:
        assetInfos?.find(info => info.assetId === stakingAssetId) ?? defaultStakingCurrency,
      liquidAsset: assetInfos?.find(info => info.assetId === liquidAssetId) ?? defaultLiquidCurrency
    };
  }, [stakingAssetId, liquidAssetId, assetInfos]);

  const nominationLength =
    useApiCall<number>(relayApi.query.staking.nominators.multi, [derivativeAccount], {
      transform: (nominations: Option<any>[]) => {
        const nominationsTargets = nominations.map(nomination =>
          nomination.unwrapOrDefault().targets.map((target: any) => target.toString())
        );
        const validators = union(...nominationsTargets);
        return validators.length;
      }
    }) ?? 0;

  useAsyncEffect(async () => {
    const apy = await getStakingApy(api);
    setStakingAPY(apy);
    setStakingAPYLoaded(true);
  }, [api]);

  const value = useMemo(
    () => ({
      stakingAPY,
      stakingAPYLoaded,
      nominationLength,
      currencies,
      chainName
    }),
    [chainName, currencies, nominationLength, stakingAPY, stakingAPYLoaded]
  );

  return <StakingScopeContext.Provider value={value}>{children}</StakingScopeContext.Provider>;
};

export default StakingContextWraper;
