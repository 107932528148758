import { useState, useEffect } from 'react';
import { AssetBalance } from '@polkadot/types/interfaces';
import { Option } from '@polkadot/types';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import { AssetInfo } from '@/hooks/types';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

export interface ExtendAssetInfo extends Omit<AssetInfo, 'balance' | 'availableBalance'> {
  balance: number | null;
  availableBalance: number | null;
}

export const useExtendAssetInfos = () => {
  const [isBalanceReady, setIsBalanceReady] = useState(false);
  const [assetInfos, setAssetInfos] = useState<ExtendAssetInfo[]>([]);
  const assetInfoSource = useCurrentAccountAssetInfos({ filterCollateralAsset: false });

  useEffect(() => {
    if (assetInfoSource.balances && !isBalanceReady) {
      const assetInfosReady = assetInfoSource.assetInfos.every((assetInfo, index) => {
        const balance = assetInfoSource.balances![index] as Option<AssetBalance>;
        if (balance?.isSome) {
          return (
            balanceToAmountByDecimal<number>(
              balance.unwrap().balance,
              assetInfo.decimals,
              'number'
            ) === assetInfo.balance
          );
        }
        return true;
      });

      if (assetInfosReady) {
        setTimeout(() => setIsBalanceReady(true));
      }
    }
  }, [assetInfoSource, assetInfos, isBalanceReady]);

  useEffect(() => {
    setAssetInfos(
      assetInfoSource.assetInfos.map(assetInfo => ({
        ...assetInfo,
        balance: isBalanceReady ? assetInfo.balance : null,
        availableBalance: isBalanceReady ? assetInfo.availableBalance : null
      }))
    );
  }, [assetInfoSource.assetInfos, isBalanceReady]);

  return {
    isBalanceReady,
    assetInfos,
    isReady: assetInfoSource.isReady,
    assetIds: assetInfoSource.assetIds
  };
};
