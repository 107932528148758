import { TokenInput, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { Stack, H5, Inline, Icon, Button, Text, Tooltip } from '@parallel-mono/components';
import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN, BN_ZERO } from '@polkadot/util';
import { useState, ReactElement, useCallback, useMemo, FC, memo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { HfProgressBar } from '../../components/HfProgressBar';
import {
  useMarketAssets,
  useBorrowLimit,
  useAccountAssetsDeposit,
  useLiquidationFreeLimit
} from '../../hooks';
import { AssetRow } from '../../LendAndBorrowTable';

import { isValidAmountInput } from '@/utils/validate';
import { ratioToNumber } from '@/utils/utils';
import { signAndSend } from '@/utils/txCall';
import { amountToBalanceByDecimals, isFloatLessThan } from '@/utils/calculations';
import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { useTransactionFee } from '@/hooks';
import { ConnectToWallet } from '@/components';
import { balanceFormatter, toFloorFixed } from '@/utils/format';

interface IWithdrawPaneProps {
  token: AssetRow;
  handleClose: Function;
  api: ApiPromise;
  account: KeyringPair;
}

const WithdrawPane: FC<IWithdrawPaneProps> = ({ token, handleClose, api, account }) => {
  const [withdrawAmount, setWithdrawAmount] = useState<number | null>(null);
  const [errorInputMessage, setErrorInputMessage] = useState<ReactElement | string>('');
  const [processing, setProcessing] = useState(false);
  const toTXAmount = amountToBalanceByDecimals<BN>(withdrawAmount || 0, token.decimals, 'bn');

  const { assetsMarket, marketAssetIds } = useMarketAssets();
  const { totalAccountBorrowValue } = useBorrowLimit();
  const { supplies, collaterals } = useAccountAssetsDeposit(marketAssetIds);
  const threshold = 1e-4;
  const supplyAmount = supplies?.[token.assetId] ?? 0;
  const hasBorrow = totalAccountBorrowValue > 0;

  const isCollateralAsset = useMemo(
    () => !!collaterals?.includes(token.assetId),
    [collaterals, token.assetId]
  );

  const isWithdrawAll =
    supplyAmount > 0 ? isFloatLessThan(supplyAmount - Number(withdrawAmount), threshold) : false;
  const { TxFeeTips } = useTxFeeValidation();

  const transactionFee = useTransactionFee(
    {
      api,
      tx:
        supplies && isWithdrawAll
          ? api.tx.loans.redeemAll(token.assetId)
          : api.tx.loans.redeem(token.assetId, toTXAmount)
    },
    [toTXAmount.toString()]
  );

  const { checkIsCollateralLiquidationFree, getBorrowLimit, lfLiquidityLimit } =
    useLiquidationFreeLimit();

  const isLiquidationFreeAsset = checkIsCollateralLiquidationFree(token.assetId);
  const borrowLimit = getBorrowLimit(isLiquidationFreeAsset);

  const getWithdrawCollateralValue = useCallback(
    (value, key: 'collateralFactor' | 'liquidationThreshold') => {
      if (isCollateralAsset) {
        return (
          ratioToNumber(assetsMarket?.[token.assetId]?.[key] ?? BN_ZERO) *
          token.price *
          Number(value)
        );
      }
      return 0;
    },
    [assetsMarket, isCollateralAsset, token.assetId, token.price]
  );
  const withdrawCollateralValue = useMemo(
    () => getWithdrawCollateralValue(withdrawAmount, 'collateralFactor'),
    [getWithdrawCollateralValue, withdrawAmount]
  );

  const handleConfirm = () => {
    setProcessing(true);
    signAndSend({
      api,
      account,
      tx:
        supplies && isWithdrawAll
          ? api.tx.loans.redeemAll(token.assetId)
          : api.tx.loans.redeem(token.assetId, toTXAmount),
      txSuccessCb: () => {
        handleClose();
      },
      txProcessingCb: () => {
        handleClose();
      },
      txFailedCb: () => {
        setProcessing(false);
      }
    });
  };
  const newLfLiquidityLimit = useMemo(() => {
    return Math.max(lfLiquidityLimit - withdrawCollateralValue, 0);
  }, [lfLiquidityLimit, withdrawCollateralValue]);

  const calcRegularBorrowLimit = useCallback(
    (changedValue: number) => {
      return isLiquidationFreeAsset
        ? Math.min(borrowLimit - changedValue, borrowLimit - lfLiquidityLimit)
        : borrowLimit - changedValue;
    },
    [isLiquidationFreeAsset, lfLiquidityLimit, borrowLimit]
  );

  const inputChangeHandler = useCallback(
    (value: number | null) => {
      const withdrawValue = getWithdrawCollateralValue(value, 'collateralFactor');
      const newBorrowLimit = calcRegularBorrowLimit(withdrawValue);

      setErrorInputMessage('');
      // check if entered amount is greater than max allowed amount
      if (Number(value) > token.supplyMarket) {
        setErrorInputMessage(<>Insufficient market liquidity to withdraw.</>);
      } else if (supplies && Number(value) > supplies[token.assetId]) {
        setErrorInputMessage(<>Insufficient balance.</>);
      }
      // check for negative numbers
      if (value !== null && Number(value) <= 0) {
        setErrorInputMessage('Please enter a value greater than zero');
      } else if (
        hasBorrow &&
        collaterals &&
        collaterals.includes(token.assetId) &&
        newBorrowLimit < 0
      ) {
        const errorMessage = isLiquidationFreeAsset
          ? 'Unstable to withdraw this much collateral. Please repay your outstanding loan first.'
          : 'Withdrawing this much collateral would make you eligible for liquidation.';

        setErrorInputMessage(errorMessage);
      }
      if (isValidAmountInput(value?.toString() ?? '', 12, 6) || value === null) {
        setWithdrawAmount(value);
      }
    },
    [
      getWithdrawCollateralValue,
      calcRegularBorrowLimit,
      token.supplyMarket,
      token.assetId,
      supplies,
      hasBorrow,
      collaterals,
      isLiquidationFreeAsset
    ]
  );

  const supplying = useMemo(() => supplies?.[token.assetId] || 0, [supplies, token.assetId]);

  const handleClickMax = useCallback(() => {
    inputChangeHandler(toFloorFixed(supplying, 6));
  }, [inputChangeHandler, supplying]);

  const newRegularBorrowLimit = calcRegularBorrowLimit(withdrawCollateralValue);

  const apyEarned = useMemo(() => token.earned! / (token.accountSupplied! * token.price), [token]);

  const infos = useMemo(
    () =>
      [
        {
          title: 'APY Earned',
          value: formatNumber(apyEarned, {
            output: 'percent',
            threshold: {
              min: 1e-4
            }
          })
        },
        isLiquidationFreeAsset
          ? {
              title: 'Liquidation Free Limit',
              tip: 'This is the maximum amount you can borrow for a liquidation free loan with no risk of liquidation, after you add collateral. It depends on the value you have deposited and the available liquidity.',
              value: (
                <Inline gap="0.2rem">
                  <H5> {formatNumber(lfLiquidityLimit, { output: 'currency' })} </H5>
                  {!!withdrawAmount && (
                    <>
                      <Icon name="arrowRight" />
                      <H5>{formatNumber(newLfLiquidityLimit, { output: 'currency' })}</H5>
                    </>
                  )}
                </Inline>
              )
            }
          : null,
        {
          title: 'Borrow Limit',
          tip: 'This is the maximum amount you can borrow, after you add collateral. It depends on the value you have deposited and the available liquidity.',
          value: (
            <Inline gap="0.2rem">
              <H5> {formatNumber(calcRegularBorrowLimit(0), { output: 'currency' })} </H5>
              {!!withdrawAmount && (
                <>
                  <Icon name="arrowRight" />
                  <H5>{formatNumber(newRegularBorrowLimit, { output: 'currency' })}</H5>
                </>
              )}
            </Inline>
          )
        }
      ].filter(info => info) as InfoPanelProps['infos'],
    [
      calcRegularBorrowLimit,
      isLiquidationFreeAsset,
      lfLiquidityLimit,
      newLfLiquidityLimit,
      newRegularBorrowLimit,
      withdrawAmount,
      apyEarned
    ]
  );

  return (
    <Stack gap="1.5rem">
      <TokenInput
        label={<H5>{token.symbol} Amount</H5>}
        hint={
          <Inline gap="0.2rem" alignItems="center">
            <Inline gap="0.5rem" alignItems="center">
              <Text skin="secondary">Supplying:</Text>
              <H5>{supplies ? balanceFormatter(supplying) : 'Loading...'}</H5>
            </Inline>
            <Tooltip content="This is the amount of the asset that you have supplied." />
          </Inline>
        }
        token={token.name.toUpperCase()}
        value={withdrawAmount}
        onChange={inputChangeHandler}
        placeholder="0"
        onAction={handleClickMax}
        error={errorInputMessage}
        actionButtonText="Max"
      />
      <HfProgressBar
        isLiquidationFreeAsset={isLiquidationFreeAsset}
        addAccSupply={isCollateralAsset ? -Number(withdrawAmount) * token.price : 0}
        assetsMarket={assetsMarket?.[token.assetId]}
      />

      <InfoPanel infos={infos} />

      {account ? (
        <Button
          size="large"
          onClick={() => handleConfirm()}
          block
          skin="primary"
          disabled={processing || !withdrawAmount || Boolean(errorInputMessage)}
        >
          {!withdrawAmount ? 'Enter an Amount' : 'Withdraw'}
        </Button>
      ) : (
        <ConnectToWallet block skin="secondary">
          Connect Wallet
        </ConnectToWallet>
      )}
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default memo(WithdrawPane);
