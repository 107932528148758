import { FC, useCallback } from 'react';
import { Inline, Button } from '@parallel-mono/components';

import { TabHeaderProps } from '@/components';

export const LiquidityTabs: FC<TabHeaderProps> = ({ tabs, activeTab, updateTabKey }) => {
  const handleChange = useCallback(
    (key: string) => {
      updateTabKey(key);
    },
    [updateTabKey]
  );

  return (
    <Inline>
      {tabs.map(({ title, key }) => (
        <Button
          variant="link"
          key={key}
          skin={activeTab === key ? 'primary' : 'neutral'}
          onClick={() => {
            if (activeTab !== key) handleChange(key);
          }}
        >
          {title}
        </Button>
      ))}
    </Inline>
  );
};
