import { createContext, memo, useContext } from 'react';

import { useRelayAssetInfo } from '@/hooks';
import { AssetInfo } from '@/hooks/types';

type ContextValue = { isReady: boolean; relayAssetInfo: AssetInfo };

const RelayAssetInfoContext = createContext<ContextValue>({
  isReady: false,
  relayAssetInfo: {} as AssetInfo
});

export const RelayAssetInfoContextProvider = memo(({ children }) => {
  const relayAssetInfo = useRelayAssetInfo();

  return (
    <RelayAssetInfoContext.Provider value={relayAssetInfo}>
      {children}
    </RelayAssetInfoContext.Provider>
  );
});

export const useCurrentAccountRelayAssetInfo = () => useContext(RelayAssetInfoContext);
