import { truncate } from 'lodash';
import numbro from 'numbro';
import { extractTime } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

export const MIN_BALANCE_THRESHOLD = 0.0001;
export const MIN_PERCENTAGE_THRESHOLD = 0.0001;
const NOT_APPLICABLE = '-';

const balanceFormatter = (number: number) =>
  formatNumber(number, { decimal: 4, threshold: { min: MIN_BALANCE_THRESHOLD } });

const truncateTextMid = (text: string, start = 4, end = 4) =>
  text.length > start + end ? text.replace(text.substring(start, text.length - end), '...') : text;

const truncateTextEnd = (text: string, length = 16) => truncate(text, { length: length + 3 });

const formatMillisecondToDuration = (millisecond: number) => {
  const mins = Math.floor((millisecond / 1000 / 60) % 60);
  const hours = Math.floor((millisecond / 1000 / 60 / 60) % 24);
  const days = Math.floor(millisecond / 1000 / 60 / 60 / 24);

  const unit = ['d', 'h', 'm'];

  return days
    ? `${days} ${unit[0]} ${hours} ${unit[1]} ${mins} ${unit[2]}`
    : `${hours} ${unit[1]} ${mins} ${unit[2]}`;
};

const formatSecondsToDuration = (
  seconds: number,
  ignoreZero: boolean,
  ignoreThreshold?: number,
  compact = true
) => {
  const mins = Math.floor((seconds / 60) % 60);
  const hours = Math.floor((seconds / 60 / 60) % 24);
  const days = Math.floor(seconds / 60 / 60 / 24);

  if (ignoreThreshold !== undefined && days > ignoreThreshold) {
    return NOT_APPLICABLE;
  }

  if (ignoreZero && seconds === 0) {
    return NOT_APPLICABLE;
  }

  const unit = compact ? ['d', 'h', 'm'] : [' Days', ' Hours', ' Minutes'];

  const durations = [
    [days, unit[0]],
    [hours, unit[1]],
    [mins, unit[2]]
  ];
  const firstNonZeroIndex = durations.findIndex(([n]) => n > 0);
  const mkString = durations
    .slice(firstNonZeroIndex, firstNonZeroIndex + 2)
    .filter(([n]) => n > 0)
    .map(([n, u]) => `${n}${u}`)
    .join(' ');
  return mkString.length > 0 ? mkString : `${mins + 1} ${unit[2]}`;
};

const formatMillisecondsToLeftTime = (milliseconds: number): string => {
  const getUnit = (val: number, unit: string) => {
    return val > 1 ? `${unit}s` : unit;
  };
  const { days, hours, minutes } = extractTime(milliseconds);
  if (days > 0) return `${days} ${getUnit(days, 'day')}`;
  if (hours > 0) return `${hours} ${getUnit(hours, 'hour')}`;
  if (minutes > 0) return `${minutes} ${getUnit(minutes, 'min')}`;
  return 'Pending...';
};

const toFloorFixed = (value: number, decimal: number): number => {
  return numbro.unformat(
    numbro(value).format({
      mantissa: decimal,
      trimMantissa: true,
      roundingFunction: Math.floor
    })
  );
};

export {
  balanceFormatter,
  truncateTextMid,
  truncateTextEnd,
  formatMillisecondToDuration,
  formatSecondsToDuration,
  formatMillisecondsToLeftTime,
  toFloorFixed
};
