import { useState, useCallback, useEffect, Dispatch, SetStateAction, useRef } from 'react';

const useScopeState = <S = undefined>(initialState?: S | (() => S)) => {
  const [state, setState] = useState<S>(initialState as S);
  const unmountRef = useRef(false);

  const updateState = useCallback<Dispatch<SetStateAction<S>>>(value => {
    if (!unmountRef.current) setState(value);
  }, []);

  useEffect(
    () => () => {
      unmountRef.current = true;
    },
    []
  );

  return [state, updateState] as [S, Dispatch<SetStateAction<S>>];
};

export default useScopeState;
