import { useState, useCallback, useRef } from 'react';
import { BN } from '@polkadot/util';

import { CurrencyId } from '@/hooks/types';
import { useChainConnections } from '@/hooks';

export interface BestRoute {
  route: CurrencyId[];
  fromAmount: BN | null;
  toAmount: BN | null;
  reversed: boolean;
}

export const useBestRoute = () => {
  const {
    parachain: { api }
  } = useChainConnections();

  const [bestRoute, setBestRoute] = useState<BestRoute | null>(null);
  const userInputRef = useRef<{ amount: BN | null; reversed: boolean }>();

  const fetchBestRoute = useCallback(
    (newAmountIn: BN, newFromAssetId: CurrencyId, newToAssetId: CurrencyId, reversed) => {
      return api.rpc.router.getBestRoute(newAmountIn, newFromAssetId, newToAssetId, reversed);
    },
    [api.rpc.router]
  );

  const updateBestRoute = useCallback(
    async (
      amount: BN | null,
      newFromAssetId?: CurrencyId,
      newToAssetId?: CurrencyId,
      reversed = false
    ) => {
      userInputRef.current = { amount, reversed };
      if (!amount || !newFromAssetId || !newToAssetId) {
        setBestRoute(null);
        return null;
      }
      const result = await fetchBestRoute(amount, newFromAssetId, newToAssetId, reversed).catch(
        () => []
      );

      if (userInputRef.current.amount && reversed === userInputRef.current.reversed) {
        const newAmountOut = result?.[1] ?? null;
        setBestRoute({
          route: newAmountOut ? result[0].map(route => route.toString()) : [],
          fromAmount: reversed ? newAmountOut : amount,
          toAmount: reversed ? amount : newAmountOut,
          reversed
        });
      }

      return result;
    },
    [fetchBestRoute]
  );

  return {
    bestRoute,
    updateBestRoute,
    fetchBestRoute
  };
};
