import { Inline, Stack, Card, H3, SmallText, Icon } from '@parallel-mono/components';
import { FC, useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { balanceFormatter } from '@/utils/format';
import config, { RelayChainToken } from '@/config';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { useAssetPrices } from '@/hooks';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { useCurrentAccountRelayAssetInfo } from '@/contexts/AssetsInfoContext';

const GrayTokenName = styled.span`
  color: var(--clr--gray-01);
`;

const buildApiUrl = (token: RelayChainToken) => {
  const tokenChainMap = {
    [RelayChainToken.KSM]: 'heiko',
    [RelayChainToken.DOT]: 'para'
  };
  return `${config.PARALLEL_SERVICE}/${tokenChainMap[token]}/staking/v1/metadatas`;
};

// actually we only used the "totalLocked" field
type StakedDate = {
  blockHash: string;
  blockHeight: string;
  exchangeRate: string;
  id: string;
  liquidAssetId: string;
  marketCap: string;
  reserveFactor: string;
  stakingAssetId: string;
  timestamp: string;
  totalLocked: string;
  totalReserves: string;
  totalStakers: number;
};

const TVLBar: FC = () => {
  const prices = useAssetPrices();
  const { relayAssetInfo: relayToken } = useCurrentAccountRelayAssetInfo();
  const [stakedData, setStakedDate] = useState<StakedDate>();
  const fetchTotalValue = useCallback(async () => {
    try {
      const { data } = await axios.get<any>(buildApiUrl(relayToken.symbol as RelayChainToken));
      if (!data?.data || data?.msg !== 'ok') {
        return;
      }
      setStakedDate(data.data);
    } catch (error) {
      console.log(error);
    }
  }, [relayToken]);

  useAsyncEffect(async () => {
    await fetchTotalValue();
  }, []);

  const stakedAmount = useMemo(
    () =>
      stakedData
        ? balanceToAmountByDecimal<number>(
            stakedData.totalLocked,
            relayToken.decimals.toNumber(),
            'number'
          )
        : 0,
    [relayToken.decimals, stakedData]
  );

  const stakedValue = useMemo(() => {
    const price = prices?.[relayToken.assetId] ?? 0;
    return stakedAmount * price;
  }, [prices, relayToken.assetId, stakedAmount]);

  return (
    <>
      {!isEmpty(stakedData) && (
        <Card inset="0">
          <Stack inset="1.5rem" gap="0.25rem">
            <Inline justifyContent="space-between">
              <SmallText skin="secondary">Total Locked</SmallText>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.ANALYSIS_SERVICE_URL}/staking`}
              >
                <Icon name="externalLink" size="medium" />
              </a>
            </Inline>
            <Inline justifyContent="space-between">
              <H3>
                {balanceFormatter(stakedAmount)} <GrayTokenName>{relayToken.symbol}</GrayTokenName>
              </H3>
              <SmallText skin="secondary">
                ≈{' '}
                {formatNumber(stakedValue, {
                  output: 'currency'
                })}
              </SmallText>
            </Inline>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default TVLBar;
