import { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { Stack, Inline, Icon } from '@parallel-mono/components';
import { SelectableTokenValue } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { BN } from '@polkadot/util';
import { isNil, debounce } from 'lodash';
import { useDebounce } from 'react-use';

import { TokenInput } from '../components/TokenInput';
import { useTransactionTokens, TransactionToken } from '../hooks/useTransactionTokens';
import { useBestRoute } from '../hooks/useBestRoute';
import { useCheckBalance } from '../hooks/useCheckBalance';
import { DataContext } from '../DataContext';

import { SwapTransaction } from './SwapTransaction';

import config from '@/config';
import { useAccount } from '@/hooks';
import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import { toFloorFixed } from '@/utils/format';

const SwitchToken = styled(Inline)`
  cursor: pointer;
`;

enum InputType {
  FROM,
  TO
}

interface UserInput {
  amount: number | null;
  inputType: InputType;
  decimals: number;
}

export const Swap = () => {
  const { account } = useAccount();
  const { handleSelectTokens } = useContext(DataContext);

  const { isReady, fromTokens, toTokens, getToTokens } = useTransactionTokens();
  const [fromTokenValue, setFromTokenValue] = useState<SelectableTokenValue<TransactionToken>>();
  const [toTokenValue, setToTokenValue] = useState<SelectableTokenValue<TransactionToken>>();
  const [userInput, setUserInput] = useState<UserInput | null>(null);

  const [trading, setTrading] = useState(false);

  const fromAssetId = useMemo(() => fromTokenValue?.token?.assetId, [fromTokenValue]);
  const toAssetId = useMemo(() => toTokenValue?.token?.assetId, [toTokenValue]);

  const { bestRoute, updateBestRoute } = useBestRoute();

  const fromAssetCheckBalance = useCheckBalance(fromTokenValue);

  const handleFromTokenChange = useCallback(
    async ({ amount, token }: SelectableTokenValue<TransactionToken>) => {
      setFromTokenValue(tokenValue => {
        const isTokenChanged = token.assetId !== tokenValue?.token?.assetId;
        const newAmount = isNil(amount) && isTokenChanged ? tokenValue?.amount! : amount;

        // TODO: Optimize later
        if (toTokens.length > 0) {
          const defaultToAssetId =
            token.assetId === config.relayAssetId ? config.assetId : config.relayAssetId;

          setUserInput(prevUserInput => {
            const newDecimals =
              !prevUserInput ||
              !isTokenChanged ||
              (isTokenChanged && prevUserInput.inputType === InputType.FROM)
                ? token.decimals
                : prevUserInput?.decimals;

            const newUserInput = isTokenChanged
              ? { ...prevUserInput!, decimals: newDecimals }
              : { amount: newAmount, inputType: InputType.FROM, decimals: newDecimals };

            updateBestRoute(
              newUserInput?.amount
                ? amountToBalanceByDecimals<BN>(newUserInput.amount, newUserInput.decimals, 'bn')
                : null,
              token.assetId,
              toAssetId === token.assetId ? defaultToAssetId : toAssetId,
              newUserInput?.inputType === InputType.TO
            );

            return newUserInput;
          });
        }

        return {
          token,
          amount: newAmount
        };
      });
    },
    [toAssetId, toTokens, updateBestRoute]
  );

  const handleToTokenChange = useCallback(
    async ({ amount, token }: SelectableTokenValue<TransactionToken>) => {
      setToTokenValue(tokenValue => {
        const isTokenChanged = token.assetId !== tokenValue?.token?.assetId;
        const newAmount = isNil(amount) && isTokenChanged ? tokenValue?.amount! : amount;

        if (fromTokens.length > 0) {
          setUserInput(prevUserInput => {
            const newDecimals =
              !prevUserInput ||
              !isTokenChanged ||
              (isTokenChanged && prevUserInput.inputType === InputType.TO)
                ? token.decimals
                : prevUserInput?.decimals;

            const newUserInput = isTokenChanged
              ? { ...prevUserInput!, decimals: newDecimals }
              : { amount: newAmount, inputType: InputType.TO, decimals: newDecimals };

            updateBestRoute(
              newUserInput?.amount
                ? amountToBalanceByDecimals<BN>(newUserInput?.amount, newUserInput.decimals, 'bn')
                : null,
              fromAssetId,
              token.assetId,
              newUserInput?.inputType === InputType.TO
            );

            return newUserInput;
          });
        }

        return {
          token,
          amount: newAmount
        };
      });
    },
    [fromAssetId, fromTokens, updateBestRoute]
  );

  const handleSwitchToken = useMemo(
    () =>
      debounce(() => {
        setFromTokenValue(() => {
          if (!userInput) return toTokenValue;
          if (userInput.inputType === InputType.FROM) {
            return { ...toTokenValue!, amount: null };
          }

          if (userInput.inputType === InputType.TO) {
            return { ...toTokenValue!, amount: userInput.amount };
          }
          return toTokenValue;
        });

        setToTokenValue(() => {
          if (!userInput) return fromTokenValue;
          if (userInput.inputType === InputType.FROM) {
            return { ...fromTokenValue!, amount: userInput.amount };
          }

          if (userInput.inputType === InputType.TO) {
            return { ...fromTokenValue!, amount: null };
          }
          return fromTokenValue;
        });

        if (
          userInput?.inputType === InputType.FROM &&
          fromTokenValue &&
          toTokenValue &&
          userInput.amount
        ) {
          setUserInput({
            ...userInput,
            inputType: InputType.TO
          });
          const amountIn = amountToBalanceByDecimals<BN>(
            userInput.amount,
            fromTokenValue.token.decimals,
            'bn'
          );
          updateBestRoute(amountIn, toTokenValue.token.assetId, fromTokenValue.token.assetId, true);
        }

        if (
          userInput?.inputType === InputType.TO &&
          fromTokenValue &&
          toTokenValue &&
          userInput.amount
        ) {
          setUserInput({
            ...userInput,
            inputType: InputType.FROM
          });

          const amountIn = amountToBalanceByDecimals<BN>(
            userInput.amount,
            toTokenValue.token.decimals,
            'bn'
          );
          updateBestRoute(amountIn, toTokenValue.token.assetId, fromTokenValue.token.assetId);
        }
      }, 250),
    [fromTokenValue, toTokenValue, updateBestRoute, userInput]
  );

  const handleTxSuccess = useCallback(() => {
    setFromTokenValue(tokenValue => ({
      ...tokenValue!,
      amount: null
    }));
    setToTokenValue(tokenValue => ({
      ...tokenValue!,
      amount: null
    }));

    setUserInput(null);
    updateBestRoute(null);
  }, [updateBestRoute]);

  useEffect(() => {
    if (fromTokens.length > 0) {
      const defaultFromToken = fromTokens.find(token => token.assetId === config.relayAssetId);
      if (defaultFromToken)
        setFromTokenValue(tokenValue => ({
          amount: tokenValue?.amount ?? null,
          token: tokenValue?.token
            ? fromTokens.find(({ assetId }) => assetId === tokenValue.token.assetId)!
            : defaultFromToken
        }));
    }
  }, [fromTokens, account]);

  useEffect(() => {
    if (toTokens.length > 0) {
      const defaultToToken = toTokens.find(token => token.assetId === config.assetId);

      if (defaultToToken)
        setToTokenValue(tokenValue => ({
          amount: tokenValue?.amount ?? null,
          token: tokenValue?.token
            ? toTokens.find(({ assetId }) => assetId === tokenValue.token.assetId)!
            : defaultToToken
        }));
    }
  }, [toTokens, account]);

  useDebounce(
    () => {
      if (fromAssetId) {
        const newToTokens = getToTokens(fromAssetId);

        setToTokenValue(tokenValue => {
          const newToToken = newToTokens.find(
            ({ assetId }) => assetId === tokenValue?.token?.assetId
          );
          return {
            ...tokenValue!,
            token: toAssetId === fromAssetId || !newToToken ? newToTokens[0] : newToToken!
          };
        });
      }
    },
    50,
    [fromAssetId, fromTokens, getToTokens, toAssetId]
  );

  useEffect(() => {
    if (!bestRoute) {
      setFromTokenValue(tokenValue => ({ ...tokenValue!, amount: null }));
      setToTokenValue(tokenValue => ({ ...tokenValue!, amount: null }));
      return;
    }

    if (bestRoute.reversed) {
      setFromTokenValue(tokenValue => {
        if (!tokenValue || !tokenValue?.token) return tokenValue;

        const amountIn = bestRoute.fromAmount
          ? toFloorFixed(
              balanceToAmountByDecimal<number>(
                bestRoute.fromAmount,
                tokenValue.token.decimals,
                'number'
              ),
              6
            )
          : null;

        return {
          ...tokenValue!,
          amount: amountIn
        };
      });
    } else {
      setToTokenValue(tokenValue => {
        if (!tokenValue || !tokenValue?.token) return tokenValue;

        const amountOut = bestRoute.toAmount
          ? toFloorFixed(
              balanceToAmountByDecimal<number>(
                bestRoute.toAmount,
                tokenValue.token.decimals,
                'number'
              ),
              6
            )
          : null;

        return {
          ...tokenValue!,
          amount: amountOut
        };
      });
    }
  }, [bestRoute]);

  useEffect(() => {
    if (
      fromTokenValue?.token &&
      toTokenValue?.token &&
      fromTokenValue.token.symbol !== toTokenValue.token.symbol
    ) {
      handleSelectTokens(fromTokenValue.token, toTokenValue.token);
    }
  }, [fromTokenValue, handleSelectTokens, toTokenValue]);

  return (
    <Stack inset="1rem 0 0 0">
      <TokenInput
        isReady={isReady}
        trading={trading}
        tokens={fromTokens}
        value={fromTokenValue}
        onChange={handleFromTokenChange}
        isEmptyBalanceAlert
        {...fromAssetCheckBalance}
      />

      <SwitchToken justifyContent="center" alignItems="center" onClick={handleSwitchToken}>
        <Icon name="down" />
      </SwitchToken>
      <TokenInput
        isReady={isReady}
        trading={trading}
        tokens={toTokens}
        value={toTokenValue}
        checkBalance={false}
        maxAvailableBalance={toTokenValue?.token?.maxAvailableBalance}
        onChange={handleToTokenChange}
      />

      <SwapTransaction
        fromTokenValue={fromTokenValue}
        toTokenValue={toTokenValue}
        bestRoute={bestRoute}
        isInsufficient={fromAssetCheckBalance.isInsufficient}
        trading={trading}
        setTrading={setTrading}
        onTxSuccess={handleTxSuccess}
      />
    </Stack>
  );
};
