import { ApiPromise } from '@polkadot/api';
import { zipWith } from 'lodash';
import { BN_ZERO } from '@polkadot/util';
import BigNumber from 'bignumber.js';

import { ChainMetadata } from '../types';
import { MAX_STATEMINE_TRANSACTION_FEE } from '../constants';

import { calculateBalances } from './calculateBalances';

import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import config from '@/config';
import { AssetDetailInfo, CurrencyId } from '@/hooks/types';

export const calculateAssetsInfo = async (api: ApiPromise, chainMetadata: ChainMetadata) => {
  const { assetsMetadata, assetsBalance, chainProperties, accountInfo } = chainMetadata;

  const chainName = await api.rpc.system.chain();

  const assetIds: CurrencyId[] =
    assetsMetadata?.flatMap(m => m[0]).map(({ args: [id] }) => id.toString()) || [];

  const { existentialDeposit } = api.consts.balances;

  if (assetsMetadata && assetsBalance && chainProperties && accountInfo) {
    const { tokenSymbol, tokenDecimals } = chainProperties;
    const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[0] : BN_ZERO;
    const symbol = tokenSymbol.isSome ? tokenSymbol.unwrap()[0].toString() : '';
    const ED = BigNumber(existentialDeposit.toString());
    const { lockedBalance, availableBalance } = calculateBalances(accountInfo.data, ED);

    const maxTxFee = amountToBalanceByDecimals<BigNumber>(
      MAX_STATEMINE_TRANSACTION_FEE,
      decimals,
      'bigNumber'
    );
    const maxAvailableNativeBalance = BigNumber.max(availableBalance.minus(maxTxFee), 0);
    const nativeAssetInfo = {
      network: chainName,
      name: chainName.toString(),
      symbol,
      decimals,
      existentialDeposit: balanceToAmountByDecimal<BigNumber>(ED, decimals, 'bigNumber'),
      balance: balanceToAmountByDecimal<BigNumber>(accountInfo.data.free, decimals, 'bigNumber'),
      lockedBalance: balanceToAmountByDecimal<BigNumber>(lockedBalance, decimals, 'bigNumber'),
      availableBalance: balanceToAmountByDecimal<BigNumber>(
        availableBalance,
        decimals,
        'bigNumber'
      ),
      maxAvailableBalance: balanceToAmountByDecimal<BigNumber>(
        maxAvailableNativeBalance,
        decimals,
        'bigNumber'
      ),
      isNative: true
    } as AssetDetailInfo;
    const assetsDetails = await api.query.assets.asset.multi(assetIds);

    const metadatas = assetsMetadata.flatMap(m => m[1]);
    const infos = zipWith(
      assetIds,
      metadatas,
      assetsDetails,
      assetsBalance || [],
      (key, metadata, assetDetail, balanceObj) => {
        const balanceBn =
          balanceObj && balanceObj.isSome ? balanceObj.unwrap().balance.toString() : 0;
        const minBalanceBn =
          assetDetail && assetDetail.isSome ? assetDetail.unwrap().minBalance.toString() : 0;

        const balance = balanceToAmountByDecimal<BigNumber>(
          balanceBn,
          metadata.decimals,
          'bigNumber'
        );

        // for the statemint chain, the availableBalance is balance - minBalance
        const maxAvailableBalance = balanceToAmountByDecimal<BigNumber>(
          BigNumber(balanceBn).minus(minBalanceBn),
          metadata.decimals,
          'bigNumber'
        );

        return {
          assetId: key,
          network: chainName || config.relayChain,
          name: metadata.name.toHuman() as string,
          symbol: metadata.symbol.toHuman() as string,
          decimals: metadata.decimals,
          existentialDeposit: BigNumber(0),
          balance,
          lockedBalance: BigNumber(0),
          availableBalance: balance,
          maxAvailableBalance: BigNumber.max(maxAvailableBalance, 0),
          isNative: false
        };
      }
    );
    return [...infos, nativeAssetInfo];
  }
  return undefined;
};
