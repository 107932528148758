import { addressToHex } from '@/utils/utils';

export const createXcm = (address: string, paraChain: number) => ({
  V1: {
    parents: 1,
    interior: {
      X2: [
        {
          Parachain: paraChain
        },
        {
          AccountId32: {
            network: 'Any',
            id: addressToHex(address)
          }
        }
      ]
    }
  }
});

export const createXcmV3 = (address: string, paraChain: number) => ({
  V3: {
    parents: 1,
    interior: {
      X2: [
        {
          Parachain: paraChain
        },
        {
          AccountId32: {
            network: null,
            id: addressToHex(address)
          }
        }
      ]
    }
  }
});
