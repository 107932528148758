import { FC } from 'react';
import styled from 'styled-components';
import { Size, Skin } from '../ThemeProvider/types';

const Padding: Record<Size, string> = {
  small: '2px 12px',
  medium: '4px 16px',
  large: '6px 20px',
};

const FontSize: Record<Size, string> = {
  small: '14px',
  medium: '16px',
  large: '18px',
};

const LineHeight: Record<Size, string> = {
  small: '20px',
  medium: '24px',
  large: '28px',
};

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  skin: Skin;
  size: Size;
}

export const Tag: FC<TagProps> = ({ skin, size, children, ...props }) => {
  return (
    <TagWrapper skin={skin} size={size} {...props}>
      <Span size={size}>{children}</Span>
    </TagWrapper>
  );
};

const TagWrapper = styled.span<{ skin: Skin; size: Size }>`
  background: ${({ skin, theme }) => theme.skin[skin].contrastBackground};
  color: ${({ skin, theme }) => theme.skin[skin].main};
  border-radius: 39px;
  padding: ${({ size }) => Padding[size]};
`;

const Span = styled.span<{ size: Size }>`
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0;
  font-size: ${({ size }) => FontSize[size]};
  line-height: ${({ size }) => LineHeight[size]};
`;
