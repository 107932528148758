import { createContext, useContext, useState, useEffect, FC, ReactNode, useMemo } from 'react';
import { orderBy, find } from 'lodash';
import { KeyringPair } from '@polkadot/keyring/types';

import useLoadAccounts from './useLoadAccount';

import { WalletContext } from '@/contexts/WalletsContext';
import { ExtensionWallets } from '@/contexts/WalletsContext/types';

const STORAGE_KEY = 'account:selected';

export enum ExtensionState {
  NotReady,
  Ready,
  Abandoned,
  Error
}

export enum KeyringState {
  NotReady,
  Ready,
  Error
}

export interface ContextData {
  account: KeyringPair;
  accountList: KeyringPair[];
  loadAccounts: () => void;
  handleSwitchAccount: <T extends KeyringPair>(value: T, callback?: (val: T) => void) => void;
}

const AccountContext = createContext<ContextData | object>({});

interface AccountContextProviderProps {
  children: ReactNode;
}

const AccountContextProvider: FC<AccountContextProviderProps> = ({ children }) => {
  const { keyring, connectAccounts: loadAccounts } = useLoadAccounts(ExtensionWallets.Polkadot);

  const { Provider } = AccountContext;
  const [account, setAccount] = useState<ContextData['account']>();
  const [accountList, setAccountList] = useState<ContextData['accountList']>();

  const handleSwitchAccount: ContextData['handleSwitchAccount'] = (value, callback) => {
    setAccount(value);
    window.localStorage.setItem(STORAGE_KEY, value.address);
    if (typeof callback === 'function') callback(value);
  };

  useEffect(() => {
    const accounts = orderBy(keyring?.getPairs(), ['meta.isTesting'], ['desc']);
    const prevSelected = find(accounts, ['address', window.localStorage.getItem(STORAGE_KEY)]);

    setAccountList(accounts);
    setAccount(prevSelected || accounts?.[0]);
  }, [keyring, setAccountList]);

  return (
    <Provider value={{ account, accountList, handleSwitchAccount, loadAccounts }}>
      {children}
    </Provider>
  );
};

const useAccount = () => {
  const { wallet } = useContext(WalletContext);
  const accountValue = useMemo(
    () => ({
      account: wallet?.selectedAccount,
      accountList: wallet?.accounts,
      handleSwitchAccount: wallet?.switchAccount,
      loadAccounts: wallet?.loadAccounts
    }),
    [wallet]
  );
  return accountValue as ContextData;
};

export { useAccount, AccountContextProvider, AccountContext };
