import { useMemo } from 'react';
import ReactGA, { Tracker } from 'react-ga';
import { useLocation } from 'react-router-dom';

import config from '@/config';

type GAMap = Record<string, Tracker>;
export const GA_MAP: GAMap = {
  '/staking': {
    trackingId: 'UA-212480889-6',
    gaOptions: {
      name: 'staking'
    }
  },
  '/swap': {
    trackingId: 'UA-212480889-4',
    gaOptions: {
      name: 'swap'
    }
  },
  '/lendAndBorrow': {
    trackingId: 'UA-212480889-5',
    gaOptions: {
      name: 'lendAndBorrow'
    }
  },
  '/overview': {
    trackingId: 'UA-212480889-9',
    gaOptions: {
      name: 'overview'
    }
  },
  '/': {
    trackingId: 'UA-212480889-9',
    gaOptions: {
      name: 'overview'
    }
  },
  '/stream': {
    trackingId: 'UA-212480889-12',
    gaOptions: {
      name: 'stream'
    }
  },
  '/cross-chain': {
    trackingId: 'UA-212480889-13',
    gaOptions: {
      name: 'crosschain'
    }
  },
  '/omni': {
    trackingId: 'UA-212480889-10',
    gaOptions: {
      name: 'nftloan'
    }
  }
};
export const getTrackerNameByPath = (pathname: string): string => {
  // solve the nested url problem
  const firstPath = pathname.match(/\/[^/]*/)?.[0];
  if (firstPath) {
    return GA_MAP[firstPath]?.gaOptions?.name || '';
  }
  return '';
};

export const useGAEventTracker = (category = 'dapp') => {
  const location = useLocation();
  const trackerName = useMemo(() => {
    return getTrackerNameByPath(location.pathname);
  }, [location.pathname]);

  const trackEvent = (action = 'action', label = 'label') => {
    if (!trackerName) {
      return;
    }
    ReactGA.event({ category, action, label }, [trackerName]);
  };

  return trackEvent;
};

const CATEGORY_MAP: Record<string, string> = {
  parallel: 'dapp',
  heiko: 'heiko'
};

export const useGAEventTrackerByPlatform = () => {
  const category = CATEGORY_MAP[config.chain];
  return useGAEventTracker(category);
};

export default useGAEventTracker;
