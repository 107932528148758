import { Chains } from './types';

import config, { Chain } from '@/config';

export const WEIGHT_FOR_X_TOKEN_TRANSFER = 1e10;

const chains = {
  [Chain.HEIKO]: [
    Chains.Polkadot,
    Chains.Phala,
    Chains.Acala,
    Chains.Statemint,
    Chains.Interlay,
    Chains.Parallel
  ],
  [Chain.PARALLEL]: [
    Chains.Polkadot,
    Chains.Phala,
    Chains.Acala,
    Chains.Statemint,
    Chains.Interlay,
    Chains.Parallel
  ]
};

export const ALL_CHAINS = chains[config.chain];

export const ParaChainId: Record<Exclude<Chains, Chains.Polkadot | Chains.Parallel>, number> = {
  [Chain.PARALLEL]: {
    [Chains.Phala]: 2035,
    [Chains.Acala]: 2000,
    [Chains.Statemint]: 1000,
    [Chains.Interlay]: 2032
  },
  [Chain.HEIKO]: {
    [Chains.Phala]: 2004,
    [Chains.Acala]: 2000,
    [Chains.Statemint]: 1000,
    [Chains.Interlay]: 2092
  }
}[config.chain];
