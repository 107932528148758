import { Chains } from '../types';

import { AcalaChainCurrency } from './types';

import config, { Chain } from '@/config';

export const CURRENCIES = {
  [Chain.PARALLEL]: {
    [Chains.Parallel]: [
      AcalaChainCurrency.ACA,
      // AcalaChainCurrency.AUSD,
      AcalaChainCurrency.LDOT,
      AcalaChainCurrency.LCDOT
    ]
  },
  [Chain.HEIKO]: {
    [Chains.Parallel]: [AcalaChainCurrency.KAR, AcalaChainCurrency.LKSM]
  }
}[config.chain];

export const MINIMUM_TRANSFER_AMOUNT = {
  [Chain.PARALLEL]: {
    [AcalaChainCurrency.ACA]: 0.5,
    [AcalaChainCurrency.AUSD]: 0.11,
    [AcalaChainCurrency.LDOT]: 0.1,
    [AcalaChainCurrency.LCDOT]: 0.1
  },
  [Chain.HEIKO]: {
    [AcalaChainCurrency.KAR]: 0.11,
    [AcalaChainCurrency.LKSM]: 0.1
  }
}[config.chain];

export const TO_CHAINS = [Chains.Parallel];

export const ACALA_NATIVE_TOKEN = {
  [Chain.HEIKO]: AcalaChainCurrency.KAR,
  [Chain.PARALLEL]: AcalaChainCurrency.ACA
}[config.chain];

// hard code the CURRENCY_ID, and can be queried via 'acalaApi.query.tokens.accounts'(don't select the option)
export const LIQUID_CROWDLOAN_CURRENCY_ID = 13;

// TODO need to confirm the value, it depends on the acala chain.
export const MAXIMUM_TX_FEE = {
  [Chain.PARALLEL]: 0.02,
  [Chain.HEIKO]: 0.02
}[config.chain];
