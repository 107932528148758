import { memo, useMemo } from 'react';
import { Tooltip, ProgressBar, Stack, Inline, Text, H5 } from '@parallel-mono/components';
import { useTheme } from 'styled-components';
import { Market } from '@parallel-finance/types/interfaces';
import { BN_ZERO } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

import { useBorrowLimit } from '../../hooks';
import { TOOLTIP } from '../../constants';

import { ratioToNumber } from '@/utils/utils';

interface ProgressBarProps {
  isLiquidationFreeAsset?: boolean;
  addAccSupply?: number;
  addBorrow?: number;
  assetsMarket?: Market;
}

const computeSuppliedValue = (
  value: number,
  supply: number,
  percentage: number,
  isLiquidationFreeAsset: boolean
) => {
  if (isLiquidationFreeAsset) return value;
  return Math.max(value + supply * percentage, 0);
};

export const HfProgressBar = memo(
  ({
    isLiquidationFreeAsset = false,
    addAccSupply = 0,
    addBorrow = 0,
    assetsMarket
  }: ProgressBarProps) => {
    const { skin } = useTheme();

    const {
      totalSuppliedValue,
      collateralFactorLimit,
      liquidationPointLimit,
      totalAccountBorrowValue,
      lfAccountBorrowValue,
      lfCollateralLendTotal
    } = useBorrowLimit();

    const newTotalSuppliedValue = useMemo(
      () => computeSuppliedValue(totalSuppliedValue, addAccSupply, 1, isLiquidationFreeAsset),
      [addAccSupply, isLiquidationFreeAsset, totalSuppliedValue]
    );

    const newTotalBorrowLimit = useMemo(
      () =>
        computeSuppliedValue(
          collateralFactorLimit,
          addAccSupply,
          ratioToNumber(assetsMarket?.collateralFactor ?? BN_ZERO),
          isLiquidationFreeAsset
        ),
      [addAccSupply, assetsMarket, isLiquidationFreeAsset, collateralFactorLimit]
    );

    const newLiquidationPointLimit = useMemo(
      () =>
        computeSuppliedValue(
          liquidationPointLimit,
          addAccSupply,
          ratioToNumber(assetsMarket?.liquidationThreshold ?? BN_ZERO),
          isLiquidationFreeAsset
        ),
      [addAccSupply, assetsMarket, isLiquidationFreeAsset, liquidationPointLimit]
    );

    const newTotalBorrow = useMemo(
      () => totalAccountBorrowValue + addBorrow,
      [addBorrow, totalAccountBorrowValue]
    );

    const nonLfAccountBorrowValue = useMemo(() => {
      if (isLiquidationFreeAsset) {
        return (
          newTotalBorrow -
          Math.min(
            lfCollateralLendTotal +
              addAccSupply * ratioToNumber(assetsMarket?.liquidationThreshold ?? BN_ZERO),
            lfAccountBorrowValue + addBorrow
          )
        );
      }
      return newTotalBorrow - Math.min(lfCollateralLendTotal, lfAccountBorrowValue);
    }, [
      addAccSupply,
      addBorrow,
      assetsMarket,
      isLiquidationFreeAsset,
      lfAccountBorrowValue,
      lfCollateralLendTotal,
      newTotalBorrow
    ]);

    const progressBarSkin = useMemo(() => {
      if (
        nonLfAccountBorrowValue > newTotalBorrowLimit &&
        nonLfAccountBorrowValue < newLiquidationPointLimit
      )
        return 'warning';

      if (nonLfAccountBorrowValue > newLiquidationPointLimit) return 'error';

      return 'success';
    }, [newLiquidationPointLimit, newTotalBorrowLimit, nonLfAccountBorrowValue]);

    const barMarkers = useMemo(() => {
      const borrowLimitPercentage = newTotalBorrowLimit / newTotalSuppliedValue;
      const liquidationLimitPercentage = newLiquidationPointLimit / newTotalSuppliedValue;

      if (
        Number.isNaN(borrowLimitPercentage) ||
        borrowLimitPercentage <= 0 ||
        Number.isNaN(liquidationLimitPercentage) ||
        liquidationLimitPercentage <= 0
      )
        return [];
      return [
        {
          value: (newTotalBorrowLimit / newTotalSuppliedValue) * 100,
          color: skin.warning.main,
          tip: {
            content: (
              <>
                <Text>Your borrow limit is at</Text>
                <H5>{formatNumber(newTotalBorrowLimit, { output: 'currency' })}</H5>
              </>
            )
          }
        },
        {
          value: (newLiquidationPointLimit / newTotalSuppliedValue) * 100,
          color: skin.error.main,
          tip: {
            content: (
              <>
                <Text>Your liquidation point is at</Text>
                <H5> {formatNumber(newLiquidationPointLimit, { output: 'currency' })}</H5>
              </>
            )
          }
        }
      ];
    }, [newTotalSuppliedValue, skin, newTotalBorrowLimit, newLiquidationPointLimit]);

    return (
      <Stack gap="0.8rem">
        <Inline gap="0.5rem">
          <Text>New Health Factor</Text>
          <Tooltip content={TOOLTIP.HEALTH_FACTOR} />
        </Inline>
        <ProgressBar
          value={(nonLfAccountBorrowValue / newTotalSuppliedValue) * 100}
          skin={progressBarSkin}
          markers={barMarkers}
        />
      </Stack>
    );
  }
);
