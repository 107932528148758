import { useState, useMemo, useEffect, useCallback, memo, FC, useContext } from 'react';
import { floor, toNumber, get } from 'lodash';
import styled from 'styled-components';
import { Rate, Ratio } from '@parallel-finance/types/interfaces';
import { BN_ZERO, stringLowerFirst } from '@polkadot/util';
import { useDebounce } from 'react-use';
import { Inline, Stack, H5, Button, Tooltip, Alert } from '@parallel-mono/components';
import { TokenInput } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { StakingScopeContext } from '../Context';
import useStakingExtraApy from '../hooks/useStakingExtraApy';
import { useStakeBestRoutes } from '../hooks/useStakeBestRoutes';

import LendToMoneyMarketToggle from './LendToMoneyMarketToggle';
import StakingDetails from './StakingDetails';

import { rateToNumber, ratioToNumber } from '@/utils/utils';
import {
  useAccount,
  useApiCall,
  useAssetPrices,
  useChainConnections,
  useGAEventTrackerByPlatform,
  useModal
} from '@/hooks';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { getMinStake, getStakingXcmFee } from '@/pages/Staking/apis/staking';
import { signAndSend, txFee } from '@/utils/txCall';
import { ConnectToWallet, DepositModal } from '@/components';
import { RelayChainToken } from '@/config';
import { isValidAmountInput } from '@/utils/validate';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';
import {
  useCurrentAccountNativeAssetInfo,
  useCurrentAccountRelayAssetInfo
} from '@/contexts/AssetsInfoContext';

const Wrapper = styled(Stack)`
  width: 100%;
  > * {
    width: 100%;
  }
  .shallow {
    color: var(--clr-gray01);
    font-weight: normal;
  }
`;

const DEBOUNCE_TIME = 200;

const ProcessStake: FC = memo(() => {
  const calculateMonthlyEarns = (principle: number, APY: number): number => {
    return (principle * APY) / 12;
  };

  const [inputValue, setInputValue] = useState<number | null>(null);
  const [debouncedInputValue, setDebouncedValue] = useState('');
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const { TxFeeTips } = useTxFeeValidation();
  const [enterToStake, setEnterToStake] = useState(false);
  const [debouncedEnterToStake, setDebouncedEnterToStake] = useState(false);
  const { currencies, chainName, stakingAPY } = useContext(StakingScopeContext);
  const [stakingToMM, setStakingToMM] = useState(true);
  const {
    parachain: { api }
  } = useChainConnections();
  const prices = useAssetPrices();
  const platformFee =
    useApiCall(api.query.liquidStaking.commissionRate, [], {
      transform: (rate: Rate) => rateToNumber(rate)
    }) || 0;

  const { generateBestRoutes, receiveAmount, stakingFee, route: routeType } = useStakeBestRoutes();

  const extraApy = useStakingExtraApy() || 0;
  const { nativeAssetInfo: nativeAsset } = useCurrentAccountNativeAssetInfo();
  const { relayAssetInfo: relayAsset } = useCurrentAccountRelayAssetInfo();

  const GAEventTrackerByPlatform = useGAEventTrackerByPlatform();

  const exchangeRate = useApiCall<number>(api.query.liquidStaking.exchangeRate, [], {
    transform: (rate: Rate) => rateToNumber(rate)
  });

  const stakingReserveFactor = useApiCall<Ratio>(api.query.liquidStaking.reserveFactor) ?? BN_ZERO;
  const xcmFees = getStakingXcmFee(api);
  const minInput = balanceToAmountByDecimal<number>(
    getMinStake(api),
    currencies.stakingAsset.decimals,
    'number'
  );
  const minDeposit = Math.max(
    minInput,
    currencies.stakingAsset?.symbol === RelayChainToken.DOT ? 5 : 0.1
  );
  const stakingToken = currencies.stakingAsset;
  const liquidToken = currencies.liquidAsset;
  const liquidTokenSymbol = stringLowerFirst(liquidToken.symbol);
  const token = useMemo(() => stakingToken, [stakingToken]);

  const balanceAmount = useMemo(() => get(token, 'balance', 0), [token]);
  const debouncedAmount = useMemo(
    () => (Number.isNaN(toNumber(debouncedInputValue)) ? 0 : toNumber(debouncedInputValue)),
    [debouncedInputValue]
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const { account } = useAccount();

  useEffect(() => {
    setInputValue(null);
  }, [account]);

  const dynamicStakingFee = useMemo(
    () =>
      balanceToAmountByDecimal<number>(xcmFees, stakingToken.decimals, 'number') +
      ratioToNumber(stakingReserveFactor) * debouncedAmount,
    [debouncedAmount, stakingReserveFactor, stakingToken.decimals, xcmFees]
  );

  const maxStakingFee = useMemo(
    () =>
      balanceToAmountByDecimal<number>(xcmFees, stakingToken.decimals, 'number') +
      ratioToNumber(stakingReserveFactor) * balanceAmount,
    [balanceAmount, stakingReserveFactor, stakingToken.decimals, xcmFees]
  );

  const stakeMintVal = useMemo(() => {
    if (debouncedAmount && dynamicStakingFee && exchangeRate) {
      return Math.max(debouncedAmount - dynamicStakingFee, 0) / exchangeRate;
    }
    return 0;
  }, [debouncedAmount, dynamicStakingFee, exchangeRate]);

  const monthlyStakingEarns = calculateMonthlyEarns(
    Math.max(debouncedAmount - dynamicStakingFee, 0),
    stakingAPY + extraApy
  );
  const monthlyStakingEarnsValue =
    monthlyStakingEarns * (prices ? prices[stakingToken.assetId] : 0);

  const generateTxs = useCallback(async () => {
    const txs = await generateBestRoutes(
      debouncedAmount,
      stakeMintVal,
      stakingToken,
      liquidToken,
      dynamicStakingFee,
      stakingToMM
    );
    return txs;
  }, [
    generateBestRoutes,
    debouncedAmount,
    stakeMintVal,
    stakingToken,
    liquidToken,
    dynamicStakingFee,
    stakingToMM
  ]);

  const getFee = useCallback(async () => {
    setTransactionFee(null);
    const txs = await generateTxs();
    const fee = await txFee({
      tx: api.tx.utility.batchAll(txs),
      account
    });

    setTransactionFee(fee);
  }, [account, api.tx.utility, generateTxs]);

  useDebounce(
    async () => {
      if (account) {
        getFee();
      }
    },
    DEBOUNCE_TIME,
    [account, getFee]
  );

  const maxAmount = useMemo(
    () => Math.max(balanceAmount - maxStakingFee, 0),
    [balanceAmount, maxStakingFee]
  );

  const showTooltip = useMemo(
    () => floor(balanceAmount, 4) === 0 && Boolean(account),
    [balanceAmount, account]
  );

  const showMaxAmountTips = useMemo(
    () =>
      debouncedAmount !== 0 &&
      formatNumber(balanceAmount, { decimal: 4 }) === formatNumber(debouncedAmount, { decimal: 4 }),
    [debouncedAmount, balanceAmount]
  );

  const showMaximumError = useMemo(
    () => !showTooltip && debouncedInputValue !== '' && debouncedAmount > balanceAmount,
    [showTooltip, debouncedInputValue, debouncedAmount, balanceAmount]
  );

  const showMinimumError = useMemo(
    () =>
      !showTooltip && debouncedInputValue !== '' && !showMaximumError && debouncedAmount < minInput,
    [showTooltip, debouncedInputValue, debouncedAmount, minInput, showMaximumError]
  );

  const isDisabled = useMemo(
    () =>
      showTooltip ||
      debouncedInputValue === '' ||
      showMaximumError ||
      showMinimumError ||
      isProcessing,
    [debouncedInputValue, isProcessing, showMaximumError, showMinimumError, showTooltip]
  );

  useDebounce(
    () => {
      setDebouncedValue(inputValue?.toString() ?? '');
    },
    DEBOUNCE_TIME,
    [inputValue]
  );

  useDebounce(
    () => {
      setDebouncedEnterToStake(enterToStake);
    },
    DEBOUNCE_TIME,
    [enterToStake]
  );

  const { openModal, holder } = useModal(
    DepositModal,
    {
      title: `Deposit ${stakingToken.symbol} to Parallel`,
      size: '500px'
    },
    {
      minDeposit,
      asset: stakingToken
    }
  );

  const inputValueChanged = (value: number | null) => {
    setEnterToStake(false);
    if (isValidAmountInput(value?.toString() ?? '', 10, 4) || value === null) {
      setInputValue(value);
    }
  };

  const handleMaxButton = () => {
    const result = floor(balanceAmount, 4);

    if (result === 0) {
      setInputValue(null);
      return;
    }
    setInputValue(result);
  };

  const handleStake = useCallback(async () => {
    setEnterToStake(false);
    if (!token || isDisabled) {
      return;
    }
    GAEventTrackerByPlatform('stake');
    setIsProcessing(true);
    const txs = await generateTxs();
    signAndSend({
      api,
      account,
      tx: api.tx.utility.batchAll(txs),
      txSuccessCb: () => {
        setIsProcessing(false);
      },
      txProcessingCb: () => {
        setInputValue(null);
      },
      txFailedCb: () => {
        setIsProcessing(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isDisabled, api, account, generateTxs]);

  useEffect(() => {
    if (debouncedEnterToStake) {
      handleStake();
    }
  }, [debouncedEnterToStake, handleStake]);

  const renderDepositModalBtn = useMemo(
    () => (
      <Button
        variant="link"
        onClick={() => {
          openModal();
        }}
      >
        Deposit
      </Button>
    ),
    [openModal]
  );

  const renderErrorMsg = () => {
    if (showMaximumError) {
      return (
        <>
          Insufficient {token.symbol} balance.&nbsp;
          {renderDepositModalBtn}
        </>
      );
    }
    if (showMinimumError) {
      return (
        <>
          Minimum stake amount is {minInput} {token.symbol}.&nbsp;
          {maxAmount < minInput ? renderDepositModalBtn : null}
        </>
      );
    }
    return '';
  };

  return (
    <Stack gap="1.5rem">
      <Wrapper alignItems="center">
        <Stack gap="0.75rem">
          <TokenInput
            label={<H5>Amount</H5>}
            hint={
              <Inline gap="0.25rem" alignItems="center">
                <H5>
                  <span className="shallow">Available: </span>
                  {`${balanceFormatter(balanceAmount)} ${stakingToken.symbol}`}
                </H5>
                <Tooltip content="This is your available balance that can be staked." />
              </Inline>
            }
            placeholder="0"
            inputProps={{ autoFocus: true }}
            value={inputValue}
            token={token.symbol.toUpperCase()}
            onChange={inputValueChanged}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setEnterToStake(true);
              }
            }}
            actionButtonText="Max"
            onAction={handleMaxButton}
            error={renderErrorMsg()}
            actionButtonProps={{ disabled: isProcessing }}
          />
          {showTooltip ? (
            <Alert type="info">
              You have 0 {stakingToken.symbol} on {chainName}. {renderDepositModalBtn}
            </Alert>
          ) : null}
          {showMaxAmountTips ? (
            <Alert type="info">
              Maximum stake amount is {formatNumber(maxAmount, { decimal: 4 })} (Excluding{' '}
              {formatNumber(maxStakingFee, { decimal: 4 })} {token.symbol} staking fee).{' '}
              {renderDepositModalBtn}
            </Alert>
          ) : null}
        </Stack>
      </Wrapper>
      <StakingDetails
        details={{
          type: 'staking',
          liquidToken: liquidTokenSymbol,
          stakingFee: debouncedAmount && stakingFee,
          stakeMintVal: receiveAmount,
          monthlyEarnVal: monthlyStakingEarnsValue,
          platformFee,
          routeType
        }}
      />
      <LendToMoneyMarketToggle
        checked={stakingToMM}
        onChange={(checked: boolean) => {
          GAEventTrackerByPlatform('stake_supply_toggle', checked ? 'on' : 'off');
          setStakingToMM(checked);
        }}
        supplyStakingAPY={extraApy}
        totalAPY={extraApy + stakingAPY}
        nativeToken={nativeAsset.symbol}
        relayToken={relayAsset?.symbol}
      />
      {account ? (
        <Button block skin="primary" size="large" disabled={isDisabled} onClick={handleStake}>
          {debouncedInputValue ? 'Stake' : 'Enter an amount'}
        </Button>
      ) : (
        <ConnectToWallet size="large" block skin="secondary">
          Connect Wallet to Stake
        </ConnectToWallet>
      )}
      {!isDisabled && <TxFeeTips txFee={transactionFee} />}
      {holder}
    </Stack>
  );
});

export default ProcessStake;
