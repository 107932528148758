import { ApiPromise } from '@polkadot/api';
import { Text } from '@polkadot/types';
import { BN_ZERO } from '@polkadot/util/bn/consts';
import BigNumber from 'bignumber.js';

import { CURRENCIES, INTERLAY_NATIVE_TOKEN, MAXIMUM_TX_FEE } from '../constants';
import { ChainMetadata } from '../types';
import { Chains } from '../../types';

import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import { AssetDetailInfo } from '@/hooks/types';

export const calculateAssetsInfo = async (
  api: ApiPromise,
  metadata: ChainMetadata
): Promise<AssetDetailInfo[]> => {
  const { tokensInfo, chainProperties } = metadata;

  const interlayCurrencies = CURRENCIES[Chains.Parallel];

  const chainName: Text = await api.rpc.system.chain();
  if (chainProperties && tokensInfo) {
    const { tokenSymbol, tokenDecimals } = chainProperties;

    const assetsInfos = interlayCurrencies.map((tokenName, index) => {
      const symbolIndex = tokenSymbol.isSome
        ? tokenSymbol.unwrap().findIndex(symbol => symbol.toString() === tokenName)
        : -1;

      const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[symbolIndex] : BN_ZERO;
      const tokenInfo = tokensInfo[index];
      return {
        decimals,
        symbol: tokenName,
        free: tokenInfo.free,
        frozen: tokenInfo.frozen
      };
    });

    const assetsInfo = assetsInfos.map(({ symbol, decimals, free = BN_ZERO, frozen = BN_ZERO }) => {
      const isNative = symbol === INTERLAY_NATIVE_TOKEN;
      const balance = BigNumber(free.toString());
      const lockedBalance = BigNumber(frozen.toString());
      const ED = BN_ZERO;
      const availableBalance = BigNumber.max(0, balance.minus(lockedBalance).minus(ED.toString()));
      const maxTxFee = amountToBalanceByDecimals<BigNumber>(
        isNative ? MAXIMUM_TX_FEE : 0,
        decimals,
        'bigNumber'
      );
      const maxAvailableBalance = BigNumber.max(0, availableBalance.minus(maxTxFee));
      return {
        network: chainName,
        symbol,
        decimals,
        balance: balanceToAmountByDecimal<BigNumber>(balance, decimals, 'bigNumber'),
        existentialDeposit: balanceToAmountByDecimal<BigNumber>(ED, decimals, 'bigNumber'),
        lockedBalance: balanceToAmountByDecimal<BigNumber>(lockedBalance, decimals, 'bigNumber'),
        availableBalance: balanceToAmountByDecimal<BigNumber>(
          availableBalance,
          decimals,
          'bigNumber'
        ),
        maxAvailableBalance: balanceToAmountByDecimal<BigNumber>(
          maxAvailableBalance,
          decimals,
          'bigNumber'
        ),
        isNative
      };
    });

    return assetsInfo.filter(asset => asset) as AssetDetailInfo[];
  }
  return [];
};
