import styled from 'styled-components';
import { Stack, Card, Skeleton, Button } from '@parallel-mono/components';

import { useDevice } from '@/hooks';

const Wrapper = styled(Card)`
  width: 30rem;
  ${({ theme }) => theme.breakpoints.down('sm')`
    width: calc(100vw - 3rem);
  `};
`;

export const LoadingStatus = () => {
  const { isMobile } = useDevice();
  const skeletonButtonWidth = isMobile ? '15rem' : '20rem';
  return (
    <Stack alignItems="center">
      <Wrapper>
        <Stack gap="2rem" alignItems="center">
          <Skeleton.Title width="100%" height="3rem" variant="round" />
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
          <Skeleton.Button width="1rem" height="1.5rem" variant="round" />
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
          <Button size="large" block skin="primary" disabled>
            Swap
          </Button>
        </Stack>
      </Wrapper>
    </Stack>
  );
};
