import { SubmittableExtrinsic } from '@polkadot/api/types';
import { web3FromSource } from '@polkadot/extension-dapp';
import { ApiPromise, SubmittableResult } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { DispatchError } from '@polkadot/types/interfaces';
import type { ITuple, RegistryError } from '@polkadot/types/types';
import { isFunction, noop } from 'lodash';

import { balanceToAmountByDecimal } from '../calculations';

import showTxStatus, { ErrorType } from './txStatus';

import config from '@/config';

interface SignAndSend {
  api: ApiPromise;
  tx: SubmittableExtrinsic<'promise'> | null;
  account: KeyringPair;
  txSuccessCb?: (result?: SubmittableResult) => void;
  txProcessingCb?: (result?: SubmittableResult) => void;
  txFailedCb?: (error?: RegistryError | DispatchError | Error, result?: SubmittableResult) => void;
}

const signAndSend = async ({
  api,
  tx,
  account,
  txSuccessCb = noop,
  txProcessingCb = noop,
  txFailedCb = noop
}: SignAndSend) => {
  if (account && api && tx) {
    const {
      address,
      meta: { source, isInjected }
    } = account;

    const nonce = await api.rpc.system.accountNextIndex(address);
    const txAccount = isInjected ? address : account;
    const injected = isInjected ? await web3FromSource(source as string) : null;
    const options = injected ? { nonce, signer: injected.signer } : { nonce };

    const txStatus = showTxStatus();

    const unsub = await tx
      .signAndSend(txAccount, options, result => {
        if (result.status.isReady) {
          txStatus({
            status: 'processing'
          });
          txProcessingCb();
        }
        if (result.status.isInBlock) {
          result.events
            .filter(({ event: { section } }) => section === 'system')
            .forEach(({ event: { method, data } }): void => {
              if (method === 'ExtrinsicFailed') {
                const [dispatchError] = data as unknown as ITuple<[DispatchError]>;
                let error;

                if (dispatchError.isModule) {
                  try {
                    const mod = dispatchError.asModule;
                    error = dispatchError.registry.findMetaError(mod);
                  } catch (err) {
                    console.error(err);
                  }
                }

                console.error('Transaction error', dispatchError.toHuman());
                const errorType = (error?.name || dispatchError.type) as ErrorType;
                txStatus({
                  status: 'complete',
                  error: errorType,
                  txHash: result.txHash.toString()
                });
                txFailedCb();
              } else if (method === 'ExtrinsicSuccess') {
                txStatus({
                  status: 'complete',
                  txHash: result.txHash.toString()
                });
                txSuccessCb(result);
              }
            });
        } else if (result.status.isFinalized) {
          if (isFunction(unsub)) unsub();
        } else if (result.isError) {
          txStatus({
            status: 'complete',
            error: result.dispatchError?.type as ErrorType,
            txHash: result.txHash.toString()
          });
          txFailedCb(result.dispatchError, result);
          if (isFunction(unsub)) unsub();
        }
      })
      .catch((error: Error) => {
        console.error('Transaction error', error);
        txStatus({
          status: 'complete',
          error: error.message as ErrorType
        });
        txFailedCb(error);
      });
  }
};

interface TxFee {
  tx: SubmittableExtrinsic<'promise'>;
  account: KeyringPair;
}

const txFee = async (
  { tx, account }: TxFee,
  unit: string = config.nativeToken
): Promise<number> => {
  try {
    const { partialFee } = await tx.paymentInfo(account?.address);
    // TODO get dynamically decimal
    return balanceToAmountByDecimal<number>(partialFee.toJSON(), unit, 'number');
  } catch (e) {
    console.error('Get transaction fee error', e);
    return Promise.reject();
  }
};

const downwardTransfer = (api: ApiPromise, paraId: number, account: string, amount: string) => {
  try {
    return api.tx.xcmPallet.reserveTransferAssets(
      api.createType('XcmVersionedMultiLocation', {
        V1: api.createType('MultiLocationV1', {
          parents: 0,
          interior: api.createType('JunctionsV1', {
            X1: api.createType('JunctionV1', {
              Parachain: api.createType('Compact<u32>', paraId)
            })
          })
        })
      }),
      api.createType('XcmVersionedMultiLocation', {
        V1: api.createType('MultiLocationV1', {
          parents: 0,
          interior: api.createType('JunctionsV1', {
            X1: api.createType('JunctionV1', {
              AccountId32: {
                network: api.createType('NetworkId', 'Any'),
                id: account
              }
            })
          })
        })
      }),
      api.createType('XcmVersionedMultiAssets', {
        V1: [
          api.createType('XcmV1MultiAsset', {
            id: api.createType('XcmAssetId', {
              Concrete: api.createType('MultiLocationV1', {
                parents: 0,
                interior: api.createType('JunctionsV1', 'Here')
              })
            }),
            fun: api.createType('FungibilityV1', {
              Fungible: amount
            })
          })
        ]
      }),
      0
    );
  } catch (err) {
    console.error(err);
    return null;
  }
};

export { signAndSend, txFee, downwardTransfer };
