import { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Rate, EarnedSnapshot, Deposits } from '@parallel-finance/types/interfaces';
import { BN_ZERO } from '@polkadot/util';

import { useAccount, useChainConnections, useApiCall } from '@/hooks';
import { AssetsData, CurrencyId } from '@/hooks/types';
import { NonEmptyArray } from '@/typings/basic';
import { rateToNumber } from '@/utils/utils';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

const useAccountAssetsEarned = (ids?: NonEmptyArray<CurrencyId>) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [state, setState] = useState<AssetsData | undefined>();
  const { account } = useAccount();
  const { assetInfos } = useCurrentAccountAssetInfos();

  const exchangeRates = useApiCall<Rate[]>(ids && api.query.loans.exchangeRate.multi, [ids]);

  const accountDeposits = useApiCall<Deposits[]>(
    account && ids && api.query.loans.accountDeposits.multi,
    [ids?.map(id => [id, account?.address])]
  );

  const earned = useApiCall<EarnedSnapshot[]>(
    ids && account && api.query.loans.accountEarned.multi,
    [ids?.map(id => [id, account?.address])]
  );

  useEffect(() => {
    if (ids && accountDeposits && exchangeRates && earned && assetInfos) {
      const value = ids.reduce((items, id, index) => {
        const { exchangeRatePrior, totalEarnedPrior } = get(earned, index);
        const rate = get(exchangeRates, index).toBn() || BN_ZERO;
        const { voucherBalance } = get(accountDeposits, index);
        const decimals = assetInfos.find(asset => asset.assetId === id)?.decimals || BN_ZERO;

        const computedEarnValue =
          rateToNumber(rate.sub(exchangeRatePrior)) *
            balanceToAmountByDecimal<number>(voucherBalance, decimals, 'number') +
          balanceToAmountByDecimal<number>(totalEarnedPrior, decimals, 'number');

        return { ...items, [id]: computedEarnValue };
      }, {});
      setState(value);
    }
  }, [ids, earned, accountDeposits, exchangeRates, assetInfos]);
  return state;
};

export default useAccountAssetsEarned;
