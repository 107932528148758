import { upperFirst } from 'lodash';

import { Chains } from '../chains/types';
import { Currency } from '../types';
import { ChainName } from '../chains/AbstractChain';

import config from '@/config';
import { AssetDetailInfo } from '@/hooks/types';
import getFeatureToggle from '@/utils/toggles/getFeatureToggle';

type Params = {
  fromChain: ChainName;
  toChain: ChainName;
  transferAmount: number;
  maximumTransferAmount: number;
  fromChainNativeAsset: AssetDetailInfo;
  toChainNativeAsset: AssetDetailInfo;
  minimumTransferAmount: number;
  transferCurrency: Currency;
};
export const calculateCommonErrorMessage = ({
  fromChain,
  toChain,
  transferAmount,
  maximumTransferAmount,
  fromChainNativeAsset,
  toChainNativeAsset,
  minimumTransferAmount,
  transferCurrency
}: Params): string | null => {
  const depositAndWithdrawDisabled = getFeatureToggle('TOGGLE_RELAY_CHAIN_DEPOSIT_DISABLED');
  if (
    depositAndWithdrawDisabled &&
    (fromChain.name === Chains.Polkadot ||
      (fromChain.name === Chains.Parallel && toChain.name === Chains.Polkadot))
  ) {
    return `${upperFirst(config.relayChain)} Cross-Chain is currently not enabled for this asset.`;
  }
  if (transferAmount === null) {
    return null;
  }
  if (transferAmount === 0) {
    return 'Please input a valid amount > 0';
  }
  if (transferAmount > maximumTransferAmount) {
    return `Insufficient balance on ${fromChain.label}`;
  }
  if (transferAmount < minimumTransferAmount) {
    return `Minimum transfer amount is ${minimumTransferAmount}`;
  }
  if (fromChainNativeAsset.maxAvailableBalance.lte(0)) {
    return `Insufficient ${fromChainNativeAsset?.symbol} to pay transaction fees.`;
  }
  if (
    toChainNativeAsset.availableBalance.lte(0) &&
    transferCurrency !== toChainNativeAsset.symbol
  ) {
    return `Insufficient ${toChainNativeAsset?.symbol} balance on ${toChain.label} would result in funds loss.`;
  }
  return null;
};
