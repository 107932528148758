import { useMemo } from 'react';
import { HostedImage } from '@parallel-mono/business-components';
import {
  DataGrid,
  DataGridProps,
  DataGridColumn,
  Inline,
  Stack,
  Text,
  Skeleton
} from '@parallel-mono/components';
import cx from 'classnames';
import styled from 'styled-components';
import { range } from 'lodash';

import { useDevice } from '@/hooks';

const EmptyStateWrapper = styled(Stack)`
  width: 100%;
  padding: 1.25rem 1.875rem;
`;

const SkeletonCell = styled(Inline)`
  width: 100%;
`;

export type StyledDataGridColumn<R = object> = DataGridColumn<R> & { isAvatar?: boolean };
export interface DataGridComponentProps<R = object> extends DataGridProps<R> {
  columns: StyledDataGridColumn<R>[];
}

const DataGridComponent = <R extends object>({
  classNames,
  noDataMessage,
  loading,
  columns,
  data,
  ...props
}: DataGridComponentProps<R>) => {
  const { isMobile } = useDevice();

  const newClassNames = useMemo(
    () => ({
      ...classNames,
      cell: cx('styled-datagrid-cell', classNames?.cell),
      headerCell: cx('styled-datagrid-header-cell', classNames?.headerCell),
      title: cx('styled-datagrid-title', classNames?.title),
      row: cx('styled-datagrid-row', classNames?.row)
    }),
    [classNames]
  );

  const newNoDataMessage = useMemo(
    () => (
      <EmptyStateWrapper alignItems="center" justifyContent="center">
        <Text forwardedAs="div">{noDataMessage}</Text>
        <HostedImage name="design/PDS_V3/empty-grid" />
      </EmptyStateWrapper>
    ),
    [noDataMessage]
  );

  const newColumns = useMemo<DataGridColumn<R>[]>(
    () =>
      columns.map(({ isAvatar, render, ...others }) => ({
        ...others,
        render: loading
          ? () => (
              <SkeletonCell alignItems="center" gap="0.8rem">
                {!isMobile && isAvatar && <Skeleton.Avatar width="2.5rem" height="2.5rem" />}
                <Skeleton.Button
                  variant="round"
                  height="1.5rem"
                  width={`calc(100% - ${isAvatar ? 5.5 : 2}rem)`}
                />
              </SkeletonCell>
            )
          : render
      })),
    [columns, isMobile, loading]
  );

  const newData = useMemo<R[]>(() => {
    const skeletonRows: R[] = range(3).map(() => ({} as R));
    return loading ? skeletonRows : data;
  }, [data, loading]);

  return (
    <DataGrid
      classNames={newClassNames}
      noDataMessage={newNoDataMessage}
      loading={false}
      columns={newColumns}
      data={newData}
      {...props}
    />
  );
};

export const StyledDataGrid = styled(DataGridComponent)`
  .styled-datagrid-cell {
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
  }

  .styled-datagrid-header-cell {
    font-size: 16px;
    line-height: 24px;
    font-weight: var(--font-weight-regular);
  }

  .styled-datagrid-title {
    padding: 0 0.5rem;
    font-size: 20px;
    line-height: 30px;
  }

  .styled-datagrid-row.clickable:hover > * {
    cursor: pointer;
    background: var(--clr-bg02);
  }
` as typeof DataGridComponent;
