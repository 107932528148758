import styled from 'styled-components';
import { H5, Stack, Tabs } from '@parallel-mono/components';
import { ReactElement, useState } from 'react';

import Receive from './Receive';
import Send from './Send';
import { BatchSend } from './BatchSend';

import getFeatureToggle from '@/utils/toggles/getFeatureToggle';

const Header = styled(H5)`
  width: 12rem;
  text-align: center;
  ${({ theme }) => theme.breakpoints.down('md')`
    width: 7rem;
  `};
`;

const StyledTabs = styled(Tabs)`
  justify-content: left;
  .tab {
    padding: 0.75rem;
  }
`;

const Stream = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = [
    { title: <Header fontWeight="bold">Receive</Header>, content: <Receive /> },
    { title: <Header fontWeight="bold">Send</Header>, content: <Send /> },
    getFeatureToggle('REWARD_BATCH_SEND_PAGE')
      ? { title: <Header fontWeight="bold">Batch Send</Header>, content: <BatchSend /> }
      : null
  ].filter(v => v) as {
    title: ReactElement;
    content: ReactElement;
  }[];
  return (
    <Stack gap="1.5rem">
      <StyledTabs
        classNames={{ tab: 'tab' }}
        tabs={tabs}
        activeTab={tabs[selectedIndex]}
        onActiveTabChange={(_, index) => setSelectedIndex(index)}
      />
      {tabs[selectedIndex].content}
    </Stack>
  );
};

export default Stream;
