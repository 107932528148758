import { memo } from 'react';

import PageWrapper from '../PageWrapper';

import CrossChainPage from './CrossChainPage';
import LoadingStatus from './components/LoadingStatus';

import { Chains } from '@/hooks';

export default memo(() => (
  <PageWrapper
    initialChains={[Chains.ParaChain, Chains.RelayChain]}
    loadingComponent={<LoadingStatus />}
  >
    <CrossChainPage />
  </PageWrapper>
));
