import { ReactNode } from 'react';
import { AccountInfo, ChainProperties, Balance } from '@polkadot/types/interfaces';

declare module '@polkadot/types/interfaces' {
  interface AccountData {
    frozen?: Balance;
  }
}

export enum Chains {
  Parallel = 'Parallel',
  Acala = 'Acala',
  Polkadot = 'Polkadot',
  Phala = 'Phala',
  Statemint = 'Statemint',
  Interlay = 'Interlay'
}

export type TxCallback = {
  txSuccessCb?: () => void;
  txFailedCb?: () => void;
  txProcessingCb?: () => void;
};

export type CommonChainMetadata = {
  chainProperties: ChainProperties;
  accountInfo: AccountInfo;
};

export type ChainDetail = { key: Chains; name: string; icon: ReactNode };
