import { useState, useEffect } from 'react';
import { AccountInfo, ChainProperties } from '@polkadot/types/interfaces';
import { Text } from '@polkadot/types';
import { BN, BN_ZERO } from '@polkadot/util';

import useApiCall from './useApiCall';
import { AssetInfo } from './types';
import { useChainConnections } from './useChainConnection';

import config, { NativeToken } from '@/config';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { MAX_TRANSACTION_FEE } from '@/utils/constants';

export const NATIVE_TOKENS: Record<NativeToken, AssetInfo> = {
  HKO: {
    symbol: 'HKO',
    name: 'Parallel Heiko',
    assetId: '0',
    decimals: new BN(config.rawDecimals.HKO),
    balance: 0,
    existentialDeposit: config.defaultEdValue,
    lockedBalance: 0,
    availableBalance: 0,
    maxAvailableBalance: 0,
    isNative: true
  },
  PARA: {
    symbol: 'PARA',
    name: 'Parallel',
    assetId: '1',
    decimals: new BN(config.rawDecimals.PARA),
    balance: 0,
    existentialDeposit: config.defaultEdValue,
    lockedBalance: 0,
    availableBalance: 0,
    maxAvailableBalance: 0,
    isNative: true
  }
};

const useNativeAssetInfo = (address: string) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [nativeAssetInfo, setNativeAssetInfo] = useState<AssetInfo>(
    NATIVE_TOKENS[config.nativeToken]
  );
  const [isReady, setIsReady] = useState(false);

  const nativeCurrencyId = api.consts.currencyAdapter.getNativeCurrencyId.toString();

  const tokenProperties = useApiCall<ChainProperties>(api.rpc.system.properties, null);
  // The accountInfo always returns. If the account is just created and it will an object with every data is 0.
  const accountInfo = useApiCall<AccountInfo>(address ? api.query.system.account : undefined, [
    address
  ]);
  const chainName = useApiCall<Text>(api.rpc.system.chain);

  useEffect(() => {
    if (tokenProperties && accountInfo && chainName) {
      const { tokenSymbol, tokenDecimals } = tokenProperties;
      const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[0] : BN_ZERO;
      const symbol = tokenSymbol.isSome ? tokenSymbol.unwrap()[0].toString() : '';
      const {
        data: { free, miscFrozen, feeFrozen }
      } = accountInfo;

      const existentialDeposit = api.consts.balances.existentialDeposit
        ? balanceToAmountByDecimal<number>(
            api.consts.balances.existentialDeposit,
            decimals,
            'number'
          )
        : config.defaultEdValue;

      const balance = balanceToAmountByDecimal<number>(free, decimals, 'number');
      const lockedBalance = balanceToAmountByDecimal<number>(
        BN.max(miscFrozen, feeFrozen),
        decimals,
        'number'
      );
      const availableBalance = Math.max(balance - lockedBalance - existentialDeposit, 0);
      const maxAvailableBalance = Math.max(availableBalance - MAX_TRANSACTION_FEE, 0);

      setNativeAssetInfo({
        ...NATIVE_TOKENS[config.nativeToken],
        assetId: nativeCurrencyId,
        network: chainName,
        name: chainName.toString(),
        symbol,
        decimals,
        existentialDeposit,
        balance,
        lockedBalance,
        availableBalance,
        maxAvailableBalance
      });

      setIsReady(true);
    }
  }, [tokenProperties, accountInfo, chainName, nativeCurrencyId, api]);

  return { isReady, nativeAssetInfo };
};

export default useNativeAssetInfo;
