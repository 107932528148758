import { FC, memo } from 'react';
import styled from 'styled-components';
import { Stack, H3, Display2 } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

const NetWorthWrapper = styled(Stack)`
  max-width: 400px;

  ${({ theme }) => theme.breakpoints.down('sm')`
    width: 100%;
  `};
  ${({ theme }) => theme.breakpoints.down('md')`
    padding:0px 15px;
  `};
`;

interface NetWorthProps {
  title: string;
  asset: number;
  debt: number;
}

const NetWorth: FC<NetWorthProps> = ({ asset, debt, title }) => {
  const worth = asset - debt;
  return (
    <NetWorthWrapper gap="1rem">
      <H3 skin="secondary" fontWeight="medium">
        {title}
      </H3>
      <Display2>{asset ? formatNumber(worth, { output: 'currency' }) : '$--'}</Display2>
    </NetWorthWrapper>
  );
};

export default memo(NetWorth);
