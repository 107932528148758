import { useCallback, useEffect, FC, useState, createContext, useMemo } from 'react';
import { web3Accounts, web3Enable, web3EnablePromise } from '@polkadot/extension-dapp';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';

import config from '@/config';

export enum ExtensionState {
  NotReady,
  Ready,
  Error
}

type AccountsContextProps = {
  accounts: InjectedAccountWithMeta[] | undefined;
  connectAccounts: () => void;
};

export const AccountsContext = createContext({} as AccountsContextProps);
const MAX_CHECKING_TIMES = 5;

export const AccountsContextProvider: FC = ({ children }) => {
  const [extensionState, setExtensionState] = useState<ExtensionState>(ExtensionState.NotReady);
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>();

  const { Provider } = AccountsContext;

  const checkExtension = useCallback(async (): Promise<ExtensionState> => {
    try {
      const extensions = await (web3EnablePromise ?? web3Enable(config.APP_NAME));
      if (extensions.length > 0) {
        setExtensionState(ExtensionState.Ready);
        return ExtensionState.Ready;
      }
    } catch (e) {
      console.error('check extension error: ', JSON.stringify(e));
      setExtensionState(ExtensionState.Error);
      return ExtensionState.Error;
    }
    return ExtensionState.NotReady;
  }, []);

  const loadAccount = useCallback(async () => {
    const injectedAccounts = await web3Accounts();
    setAccounts(injectedAccounts);
  }, []);

  useEffect(() => {
    let checkingTimes = 0;
    // wait the extension loaded.
    const timer = setInterval(async () => {
      const state = await checkExtension();
      if (state === ExtensionState.Ready || checkingTimes >= MAX_CHECKING_TIMES) {
        clearInterval(timer);
      }
      checkingTimes += 1;
    }, 1000);
    return () => clearTimeout(timer);
  }, [checkExtension]);

  const connectAccounts = useCallback(() => {
    loadAccount();
  }, [loadAccount]);

  useEffect(() => {
    if (extensionState !== ExtensionState.NotReady) {
      connectAccounts();
    }
  }, [connectAccounts, extensionState]);

  const value = useMemo(
    () => ({
      accounts,
      connectAccounts
    }),
    [accounts, connectAccounts]
  );

  return <Provider value={value}>{children}</Provider>;
};
