import { FC, memo, useCallback, useState, useMemo } from 'react';
import { Inline, H3, Text, Button, Tooltip } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import ClaimModal from '../ClaimModal';
import TransactionModal, { ActionType } from '../TransactionModal';
import TransferModal from '../TransferModal';
import { AssetRow, TransactionStatus } from '../types';
import NetWorth from '../NetWorth';

import { sumAssetsValue } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';
import { Link, StyledDataGrid, StyledDataGridColumn } from '@/components';
import { AssetInfo, AssetsData } from '@/hooks/types';
import { useDevice, useGAEventTrackerByPlatform, useModal, useVestingData } from '@/hooks';
import config from '@/config';

const generateEmptyText = (token: string) => (
  <>
    <Text>You have no assets in Parallel.</Text>
    <Text forwardedAs="div">
      {'Join our '}
      <Link href="https://discord.com/invite/buKKx4dySW" target="_blank" rel="noreferrer">
        Discord
      </Link>
      {' and '}
      <Link href="https://t.me/parallelfi" target="_blank" rel="noreferrer">
        Telegram
      </Link>
      {' to stay updated on next steps.'}
      {!config.isParallel && (
        <Text>
          Start by depositing {config.relayChainToken} via the ‘Transfer’ button to start using
          Parallel products. We will send {token} from our faucet to those who do not currently have
          a {token} balance, to cover transaction fees.
        </Text>
      )}
    </Text>
  </>
);

interface ParallelAssetsTableProps {
  isReady: boolean;
  parallelAssets?: AssetInfo[];
  nativeAsset?: AssetInfo;
  xcmAssets?: AssetInfo[];
  assetsPrice?: AssetsData;
}

const ParallelAssetsTable: FC<ParallelAssetsTableProps> = ({
  isReady,
  parallelAssets = [],
  nativeAsset,
  xcmAssets = [],
  assetsPrice
}) => {
  const [transferAsset, setTransferAsset] = useState<AssetRow | null>(null);
  const [transferModalOpened, setTransferModalOpened] = useState<boolean>(false);
  const [processingClaim, setProcessingClaim] = useState(false);
  const [transactionStatusList, setTransactionStatusList] = useState<TransactionStatus[]>([]);
  const claimData = useVestingData();
  const { isMobile } = useDevice();

  const GAEventTrackerByPlatform = useGAEventTrackerByPlatform();

  const { openModal: openClaimModal, holder: claimModalHolder } = useModal(
    ClaimModal,
    {
      title: (
        <Inline gap="12px" alignItems="center">
          <CryptoAsset symbol={config.nativeToken} symbolSize="medium" />
          <CryptoAsset symbol={config.nativeToken} symbolSize="medium" />
          <H3 fontWeight="medium">Claim {config.nativeToken}</H3>
        </Inline>
      ),
      size: '410px'
    },
    {
      claimData,
      handleProcessing: status => {
        setProcessingClaim(status);
      }
    }
  );

  const { openModal: openWithdrawModal, holder: withdrawModalHolder } = useModal(
    TransactionModal,
    { size: '500px', title: 'Withdraw' },
    { action: ActionType.WITHDRAW }
  );

  const dispatchTransactionStatus = useCallback(
    (transactionStatus: TransactionStatus) =>
      setTransactionStatusList(list => {
        if (list.find(status => status.token === transactionStatus.token)) {
          return list.map(status =>
            status.token === transactionStatus.token ? transactionStatus : status
          );
        }
        return [...list, transactionStatus];
      }),
    [setTransactionStatusList]
  );

  const columns = useMemo<StyledDataGridColumn<AssetRow>[]>(
    () => [
      {
        name: 'name',
        title: 'Asset',
        width: '1fr',
        isAvatar: true,
        render: ({ data: { name } }) => <CryptoAsset symbol={name} showName wrap={isMobile} />
      },
      {
        name: 'balance',
        title: 'Balance',
        width: '1fr',
        render: ({ data: { availableBalance } }) => {
          return <Text>{balanceFormatter(availableBalance)}</Text>;
        }
      },
      {
        name: 'value',
        title: 'Value',
        width: '1fr',
        render: ({ data: { price, availableBalance } }) =>
          formatNumber(availableBalance * price, { output: 'currency' }),
        hidden: isMobile
      },
      {
        name: 'action',
        title: 'Action',
        hidden: isMobile,
        width: '1fr',
        render: ({ data: row }) => {
          const sendButtonDisabled =
            row.balance <= 0 ||
            transactionStatusList.find(status => status.token === row.symbol)?.pending;
          return (
            <Inline gap="1.5rem" justifyContent="space-between">
              <Tooltip
                disabled={!sendButtonDisabled}
                content="Transfer is currently not enabled for this asset."
              >
                <Button
                  disabled={sendButtonDisabled}
                  skin="secondary"
                  onClick={() => {
                    setTransferAsset(row);
                    setTransferModalOpened(true);
                  }}
                >
                  Send
                </Button>
              </Tooltip>
              {row.isNative && (
                <Button
                  variant="link"
                  onClick={openClaimModal}
                  disabled={claimData.availableToClaim === 0 || processingClaim}
                >
                  Claim
                </Button>
              )}
              {['DOT', 'KSM'].includes(row.symbol) && (
                <Button
                  variant="link"
                  onClick={() => {
                    GAEventTrackerByPlatform('withdraw_to_relay', row.symbol);
                    openWithdrawModal();
                  }}
                >
                  Withdraw
                </Button>
              )}
            </Inline>
          );
        }
      }
    ],
    [
      GAEventTrackerByPlatform,
      claimData.availableToClaim,
      isMobile,
      openClaimModal,
      openWithdrawModal,
      processingClaim,
      transactionStatusList
    ]
  );

  const aggregatedAssets: AssetRow[] = parallelAssets
    ? parallelAssets.map<AssetRow>(asset => ({
        ...asset,
        name: asset.symbol,
        price: assetsPrice?.[asset.assetId] || 0,
        enableXcm: !!xcmAssets?.find(v => asset.symbol === v.symbol)
      }))
    : [];

  const rows: AssetRow[] = aggregatedAssets.filter(({ balance }) => balance > 0);

  const wallet = useMemo(() => {
    const walletBalance = parallelAssets
      .filter(asset => asset)
      .reduce(
        (items, value) => ({
          ...items,
          [value!.assetId]: value!.balance - value!.lockedBalance
        }),
        {}
      );
    return sumAssetsValue(walletBalance, assetsPrice);
  }, [parallelAssets, assetsPrice]);

  return (
    <>
      <NetWorth title="Parallel Balance" asset={wallet} debt={0} />
      <StyledDataGrid
        loading={!isReady}
        columns={columns}
        data={rows}
        noDataMessage={generateEmptyText(nativeAsset?.symbol ?? config.nativeToken)}
      />
      <TransferModal
        opened={transferModalOpened}
        asset={transferAsset as AssetRow}
        dispatchTransactionStatus={dispatchTransactionStatus}
        onClose={() => setTransferModalOpened(false)}
      />
      {claimModalHolder}
      {withdrawModalHolder}
    </>
  );
};

export default memo(ParallelAssetsTable);
