export const ADDRESS_MAX_LENGTH = 66;
export const MAX_TRANSACTION_FEE = 1.5;
export const MAX_KARURA_TRANSACTION_FEE = 0.1;
export const MAX_KUSAMA_TRANSACTION_FEE = 0.05;
export const MAX_DOT_TRANSACTION_FEE = 0.1;

export const MIN_NATIVE_TOKEN_TRANSFER_AMOUNT = 0.1;

export const RELAY_CHAIN_BLOCK_TIME = 6; // 6s

export const chainAssetIds = {
  heiko: {
    relayAssetId: '100',
    nativeAssetId: '0'
  },
  parallel: {
    relayAssetId: '101',
    nativeAssetId: '1'
  }
};
