import { useCallback } from 'react';
import { zipWith } from 'lodash';
import { Option, StorageKey, u32 } from '@polkadot/types';
import { AssetId, BlockNumber, PoolInfo, UserPosition } from '@parallel-finance/types/interfaces';
import { AssetMetadata, ChainProperties } from '@polkadot/types/interfaces';
import BigNumber from 'bignumber.js';

import { useAccount, useApiCall, useAssetPrices, useChainConnections } from '@/hooks';
import { useAmmDetails } from '@/pages/Swap/hooks/useAmmDetails';
import { balanceToAmountByDecimal, getCompoundInterestApy } from '@/utils/calculations';
import { chainAssetIds } from '@/utils/constants';
import config from '@/config';

export default () => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();

  const infos = useApiCall<[StorageKey<[AssetId]>, AssetMetadata][]>(
    api.query.assets.metadata.entries
  );
  const nativeInfo = useApiCall<ChainProperties>(api.rpc.system.properties, null);
  const prices = useAssetPrices();
  const currentBlock = useApiCall<BlockNumber>(api.query.system.number);

  const { ammDetails } = useAmmDetails();
  const snapshotsPools = ammDetails?.snapshots?.pools ?? [];

  // here we must first use entires query to fetch the ids and then use multi query to fetch pool infos,
  // otherwise the pool infos won't be updated immediately after any changes on the chain.
  const farmingPools = useApiCall<[StorageKey<[u32, u32, u32]>][]>(api.query.farming.pools.entries);
  const poolIds = farmingPools
    ? farmingPools.map(
        ([
          {
            args: [assetId, rewardAssetId, lockDuration]
          }
        ]) => [assetId, rewardAssetId, lockDuration]
      )
    : [];

  const poolInfos = useApiCall<Option<PoolInfo>[]>(farmingPools && api.query.farming.pools.multi, [
    poolIds
  ]);

  const positions =
    useApiCall<UserPosition[]>(account && api.query.farming.positions.multi, [
      poolIds.map(([assetId, rewardAssetId, lockDuration]) => [
        assetId,
        rewardAssetId,
        lockDuration,
        account?.address
      ])
    ]) || [];
  const assetInfos =
    infos &&
    infos.map(
      ([
        {
          args: [id]
        },
        metadata
      ]) => ({
        assetId: id.toString(),
        symbol: metadata.symbol.toHuman() as string,
        decimals: metadata.decimals.toString()
      })
    );
  const generateAssetInfo = useCallback(
    (assetId: AssetId) => {
      if (nativeInfo && assetId.toString() === chainAssetIds[config.chain].nativeAssetId) {
        const { tokenSymbol, tokenDecimals } = nativeInfo;
        const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[0].toString() : '0';
        const symbol = tokenSymbol.isSome ? tokenSymbol.unwrap()[0].toString() : '';
        return {
          id: assetId.toString(),
          name: symbol,
          decimals
        };
      }
      const asset = assetInfos?.find(info => info.assetId === assetId.toString());
      return {
        id: assetId.toString(),
        name: asset?.symbol || '',
        decimals: asset?.decimals || '0'
      };
    },
    [assetInfos, nativeInfo]
  );

  const pools =
    assetInfos && prices && currentBlock && poolInfos && poolInfos.length > 0
      ? zipWith(
          poolIds,
          poolInfos,
          positions,
          ([assetId, rewardAssetId, lockDuration], rawPool, position) => {
            const price = prices[assetId.toString()] || 0;
            const rewardPrice = prices[rewardAssetId.toString()] || 0;
            const pool: PoolInfo = rawPool.unwrap();
            const isActive = pool.isActive.isTrue;
            const asset = generateAssetInfo(assetId);
            const rewardAsset = generateAssetInfo(rewardAssetId);
            const positive = (number: number) => (number >= 0 ? number : 0);
            const { rewardRate, rewardPerShareStored, totalDeposited } = pool;
            const rewardRateBn = new BigNumber(rewardRate.toString());
            const rewardPerShareStoredBn = new BigNumber(rewardPerShareStored.toString());
            const totalDepositedBn = new BigNumber(totalDeposited.toString());
            const coolDownDuration = pool.coolDownDuration.toNumber();
            const periodFinish = pool.periodFinish.toNumber();
            const rewardDuration = pool.rewardDuration.toNumber();
            const lastUpdateBlock = pool.lastUpdateBlock.toNumber();
            const unlockHeight = pool.unlockHeight.toNumber();
            const current = currentBlock.toNumber();
            const deposited = position?.depositBalance.toString() || '0';
            const rewardAmount = position?.rewardAmount.toString() || '0';
            const rewardPerSharePaid = position?.rewardPerSharePaid.toString() || '0';
            const availableBlock = current > periodFinish ? periodFinish : current;
            const { blockPerDay } = config;
            const blockPeriod = (24 * 3600) / blockPerDay;
            const tvl =
              price * balanceToAmountByDecimal<number>(totalDepositedBn, asset.decimals, 'number');

            const tradingFeeApy =
              snapshotsPools.find(
                ({ poolId }: { poolId: number }) => poolId.toString() === asset.id
              )?.apy ?? 0;
            const totalDepositValue =
              balanceToAmountByDecimal<number>(totalDepositedBn, asset.decimals, 'number') * price;
            const timeLeft = positive((periodFinish - current) * blockPeriod);

            const getFarmingApy = () => {
              const ratePerDay = balanceToAmountByDecimal<BigNumber>(
                rewardRateBn,
                rewardAsset.decimals,
                'bigNumber'
              )
                .multipliedBy(blockPerDay)
                .multipliedBy(rewardPrice)
                .dividedBy(totalDepositValue);

              return getCompoundInterestApy(ratePerDay);
            };

            const farmingApy = totalDepositValue && timeLeft > 0 ? getFarmingApy() : 0;

            return {
              active: isActive,
              asset,
              rewardAsset,
              lockDuration: lockDuration.toNumber(),
              lockUpLeft: positive((unlockHeight - current) * blockPeriod),
              totalRewards: balanceToAmountByDecimal<number>(
                rewardRateBn.multipliedBy(rewardDuration),
                rewardAsset.decimals,
                'number'
              ),
              farmingApy,
              tradingFeeApy: tradingFeeApy / 100,
              tvl,
              timeLeft,
              withdrawCoolDown: coolDownDuration * blockPeriod,
              coolDownDuration,
              calculateEstReward: (amount: number) => {
                if (amount === 0) {
                  return 0;
                }
                const amountBn = new BigNumber(amount).multipliedBy(
                  new BigNumber(10).pow(asset.decimals)
                );
                return balanceToAmountByDecimal<number>(
                  amountBn
                    .multipliedBy(rewardRateBn)
                    .multipliedBy(blockPerDay)
                    .dividedBy(amountBn.plus(totalDepositedBn)),
                  rewardAsset.decimals,
                  'number'
                );
              },
              user: {
                deposited: balanceToAmountByDecimal<number>(deposited, asset.decimals, 'number'),
                rewards:
                  balanceToAmountByDecimal<number>(rewardAmount, rewardAsset.decimals, 'number') +
                  balanceToAmountByDecimal<number>(
                    rewardPerShareStoredBn
                      .minus(rewardPerSharePaid)
                      .multipliedBy(deposited)
                      .dividedBy(new BigNumber(10).pow(12)),
                    rewardAsset.decimals,
                    'number'
                  ) +
                  (totalDepositedBn.gt(0)
                    ? balanceToAmountByDecimal<number>(
                        new BigNumber(availableBlock - lastUpdateBlock)
                          .multipliedBy(rewardRateBn)
                          .multipliedBy(deposited)
                          .dividedBy(totalDepositedBn),
                        rewardAsset.decimals,
                        'number'
                      )
                    : 0),
                pendingWithdrawals:
                  (position &&
                    position.lockBalanceItems.map(([withdrawBalance, withdrawBlockNum]) => ({
                      amount: balanceToAmountByDecimal<number>(
                        withdrawBalance,
                        asset.decimals,
                        'number'
                      ),
                      timeLeft: positive(
                        (withdrawBlockNum.toNumber() + coolDownDuration - current) * blockPeriod
                      )
                    }))) ||
                  []
              }
            };
          }
        )
      : [];

  const isReady = !!(assetInfos && prices && currentBlock && poolInfos);
  return { isReady, pools };
};
