import styled from 'styled-components';
import { useMemo } from 'react';
import {
  Card,
  Skeleton,
  Inline,
  Stack,
  H5,
  Button,
  Checkbox,
  SmallText
} from '@parallel-mono/components';

import { StyledDataGrid, StyledDataGridColumn } from '@/components';
import config from '@/config';

const SkeletonWrapper = styled(Skeleton.Button)`
  margin-top: 0;
  border-radius: 2.5rem;
`;

const DataGrid = styled(StyledDataGrid)`
  width: 43.75rem;
  align-self: flex-start;
`;
const SendFormWrapper = styled(Card)`
  width: 23rem;
`;

const SendFormSkeleton = () => {
  return (
    <SendFormWrapper>
      <Stack gap="1.5rem">
        <Stack gap=".5rem">
          <H5>Send</H5>
          <Skeleton.Button variant="round" height="4rem" />
        </Stack>
        <Stack gap=".5rem">
          <H5>To</H5>
          <Skeleton.Button variant="round" height="2rem" />
        </Stack>
        <Stack gap=".5rem">
          <H5>Start Date</H5>
          <Skeleton.Button variant="round" height="2rem" />
        </Stack>
        <Stack gap=".5rem">
          <H5>End Date</H5>
          <Skeleton.Button variant="round" height="2rem" />
        </Stack>
        <Checkbox disabled>
          <SmallText skin="secondary">
            {`The payment rate will be 0 ${config.relayChainToken} per second and the stream will end on --.`}
          </SmallText>
        </Checkbox>
        <Button disabled block skin="primary">
          Stream
        </Button>
      </Stack>
    </SendFormWrapper>
  );
};

const LoadingStatus = () => {
  const columns = useMemo<StyledDataGridColumn[]>(
    () => [
      {
        name: 'deposit',
        title: 'Amount',
        width: '1fr'
      },
      {
        name: 'sent',
        title: 'Sent',
        width: '1fr'
      },
      {
        name: 'to',
        title: 'To',
        width: '1fr'
      },
      {
        name: 'start',
        title: 'Start',
        width: '1fr'
      },
      {
        name: 'end',
        title: 'End',
        width: '1fr'
      },
      {
        name: 'menu',
        title: '',
        width: '0.1fr'
      }
    ],
    []
  );

  return (
    <>
      <SkeletonWrapper height="3.6rem" width="27.5rem" variant="round" />
      <Card>
        <Inline justifyContent="space-between">
          <DataGrid title="Recent Streams" loading columns={columns} data={[]} />
          <SendFormSkeleton />
        </Inline>
      </Card>
    </>
  );
};

export default LoadingStatus;
