import { Breakpoints } from './types';

export const defaultBreakPoints: Breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  tablet: 640,
  desktop: 1024,
};
