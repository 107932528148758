import { BN } from '@polkadot/util';
import { Balance } from '@parallel-finance/types/interfaces';
import BigNumberJs from 'bignumber.js';

type StatemintAccountInfo = { free: Balance; miscFrozen: Balance; feeFrozen: Balance | BN };
type StatemineAccountInfo = {
  free: Balance;
  reserved: Balance;
  frozen: Balance | BN;
};
export const calculateBalances = (
  accountInfo: StatemintAccountInfo | StatemineAccountInfo,
  ED: BigNumberJs
) => {
  const { free, reserved, frozen } = accountInfo as StatemineAccountInfo;
  const nativeBalance = BigNumberJs(free.toString());
  const lockedBalance = BigNumberJs.max(0, frozen.sub(reserved).toString());
  const availableBalance = BigNumberJs.max(
    0,
    nativeBalance.minus(BigNumberJs.max(lockedBalance, ED))
  );

  return {
    lockedBalance,
    availableBalance
  };
};
