import styled from 'styled-components';
import { FC, useCallback, useState } from 'react';
import { Card, Stack, Inline, Responsive, H5, Button } from '@parallel-mono/components';
import { CryptoAsset, InfoPanel } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { useMyLiquidityPairs, LiquidityPair } from '../hooks/useMyLiquidityPairs';
import { LoadingDashboard } from '../components/LoadingDashboard';

import { EmptyDashboard } from './EmptyDashboard';
import { WithdrawLiquidity } from './WithdrawLiquidity';

import { Collapse } from '@/components';
import { balanceFormatter, MIN_PERCENTAGE_THRESHOLD } from '@/utils/format';

const LiquidityCard = styled(Card).attrs({ inset: '0' })`
  border: 1px solid var(--clr-gray03);
  border-radius: 0.5rem;
`;

const InfoPanelStyled = styled(InfoPanel)`
  background-color: var(--clr-white);
`;

interface MyDashboardProps {
  switchTab: (key: string) => void;
}

export const MyDashboard: FC<MyDashboardProps> = ({ switchTab }) => {
  const { myLiquidityPairs, isReady } = useMyLiquidityPairs();
  const [displayWithdraw, setDisplayWithdraw] = useState(false);
  const [activeLiquidityPair, setActiveLiquidityPair] = useState<LiquidityPair | null>(null);

  const gotoAddLiquidity = useCallback(() => {
    switchTab('addLiquidity');
  }, [switchTab]);

  const openWithdrawLiquidity = useCallback(
    (liquidityPair: LiquidityPair) => {
      setActiveLiquidityPair(liquidityPair);
      setDisplayWithdraw(true);
    },
    [setActiveLiquidityPair]
  );

  const closeWithdrawLiquidity = useCallback(() => {
    setDisplayWithdraw(false);
  }, []);

  if (!isReady) {
    return <LoadingDashboard />;
  }

  if (displayWithdraw && activeLiquidityPair) {
    return (
      <WithdrawLiquidity {...activeLiquidityPair} closeWithdrawLiquidity={closeWithdrawLiquidity} />
    );
  }

  return (
    <Stack>
      {myLiquidityPairs.length > 0 ? (
        myLiquidityPairs.map(myLiquidityPair => {
          const {
            lpAssetId,
            symbol,
            apy,
            balance,
            pooledBaseAmount,
            pooledQuoteAmount,
            poolShareRatio,
            tokenPair: [fromToken, toToken]
          } = myLiquidityPair;

          return (
            <LiquidityCard key={lpAssetId}>
              <Collapse
                containerPadding="1rem"
                header={
                  <Inline alignItems="center" gap="0.5rem">
                    <CryptoAsset
                      symbol={symbol}
                      showName={false}
                      symbolSize="small"
                      symbolGap="0.3rem"
                    />
                    <H5>{symbol}</H5>
                  </Inline>
                }
              >
                <Stack inset="1rem 0 0 0" gap="1rem">
                  <InfoPanelStyled
                    gap="1.5rem"
                    infos={[
                      {
                        title: 'Est. APY',
                        value: (
                          <H5 skin="success">
                            {apy ? formatNumber(apy, { output: 'percent' }) : '-'}
                          </H5>
                        )
                      },
                      {
                        title: 'Your LP Token',
                        value: balanceFormatter(balance)
                      },
                      {
                        title: `Pooled ${fromToken.symbol}`,
                        value: balanceFormatter(pooledBaseAmount)
                      },
                      {
                        title: `Pooled ${toToken.symbol}`,
                        value: balanceFormatter(pooledQuoteAmount)
                      },
                      {
                        title: 'Your Pool Share',
                        value: formatNumber(poolShareRatio, {
                          output: 'percent',
                          decimal: 4,
                          threshold: { min: MIN_PERCENTAGE_THRESHOLD }
                        })
                      }
                    ]}
                  />

                  <Responsive justifyContent="center">
                    <Button
                      skin="secondary"
                      block
                      onClick={() => openWithdrawLiquidity(myLiquidityPair)}
                    >
                      Withdraw
                    </Button>
                    <Button block onClick={gotoAddLiquidity}>
                      Add More
                    </Button>
                  </Responsive>
                </Stack>
              </Collapse>
            </LiquidityCard>
          );
        })
      ) : (
        <EmptyDashboard gotoAddLiquidity={gotoAddLiquidity} />
      )}
    </Stack>
  );
};
