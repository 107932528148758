import { memo, ReactNode, isValidElement, forwardRef } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { Text } from '../Typography/Text';
import { Card } from '../Card';

import { Popover, PopoverProps } from '../Popover';
import { Icon } from '../icon';
const TooltipIcon = styled(Icon)`
  cursor: pointer;
`;

export interface TooltipProps extends Omit<PopoverProps, 'popup' | 'children'> {
  content?: ReactNode;
  disabled?: boolean;
  children?: ReactNode;
  classNames?: Partial<{
    tipContainer: string;
    popupContainer: string;
  }>;
}

const TooltipPopup = styled(Card)`
  width: max-content;
`;

export const Tooltip = memo(
  forwardRef<HTMLElement, TooltipProps>(
    ({ content, disabled = false, children, classNames, ...props }, ref) => {
      return (
        <Popover
          ref={ref}
          classNames={{ popupContainer: classNames?.popupContainer }}
          popup={
            disabled || isEmpty(content) ? null : (
              <TooltipPopup
                shadowType="secondary"
                inset="1.5rem"
                className={classNames?.tipContainer}
              >
                {isValidElement(content) ? content : <Text>{content}</Text>}
              </TooltipPopup>
            )
          }
          {...props}
        >
          {children ? children : <TooltipIcon name="info" size="small" color="#9BA4AE" />}
        </Popover>
      );
    }
  )
);
