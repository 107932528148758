import { FC } from 'react';

import Element, { SkeletonElementProps } from './Element';

interface SkeletonTitleProps extends SkeletonElementProps {}

const Title: FC<SkeletonTitleProps> = ({ width = '50%', ...props }) => (
  <Element height="1rem" {...props} width={width} />
);

export type { SkeletonTitleProps };
export default Title;
