import { useContext, useState, useEffect } from 'react';

import { DataContext } from '../DataContext';

import { useTransactionTokens, TokenPair } from './useTransactionTokens';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import { CurrencyId } from '@/hooks/types';

export interface LiquidityPair {
  tokenPair: TokenPair;
  lpAssetId: CurrencyId | undefined;
  balance: number;
  decimals: number;
  symbol: string;
  apy: number | undefined;
  poolShareRatio: number;
  pooledBaseAmount: number;
  pooledQuoteAmount: number;
}

export const useMyLiquidityPairs = () => {
  const { assetInfoSource, assetDetailSource, lpAssetsApySource } = useContext(DataContext);
  const { assetInfos, isReady: assetInfosIsReady } = assetInfoSource;
  const { assetDetails, isReady: assetDetailsIsReady } = assetDetailSource;
  const { calculateApyByAssetId } = lpAssetsApySource;

  const { tokenPairs, isReady: transactionTokensIsReady } = useTransactionTokens(true);
  const [myLiquidityPairs, setMyLiquidityPairs] = useState<LiquidityPair[]>([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (assetInfosIsReady && assetDetailsIsReady && transactionTokensIsReady) {
      const newMyLiquidityPairs = tokenPairs.reduce<LiquidityPair[]>((result, tokenPair) => {
        const [fromTokenPair, toTokenPair] = tokenPair;
        const assetInfo = assetInfos.find(({ assetId }) => assetId === fromTokenPair.lpAssetId);
        const balance = assetInfo?.balance ?? 0;

        if (assetInfo && balance > 0) {
          const apy = calculateApyByAssetId(tokenPair);

          const assetDetail = assetDetails.find(
            ({ assetId }) => assetId.toString() === fromTokenPair.lpAssetId
          );

          const poolShareRatio =
            balance /
            balanceToAmountByDecimal<number>(assetDetail!.supply, assetInfo.decimals, 'number');

          const pooledBaseAmount = fromTokenPair.guideAmount! * poolShareRatio;
          const pooledQuoteAmount = toTokenPair.guideAmount! * poolShareRatio;

          result.push({
            tokenPair,
            lpAssetId: fromTokenPair.lpAssetId,
            balance,
            decimals: assetInfo.decimals.toNumber(),
            symbol: assetInfo.symbol,
            apy,
            poolShareRatio,
            pooledBaseAmount,
            pooledQuoteAmount
          });
        }

        return result;
      }, [] as LiquidityPair[]);
      if (!isReady) setIsReady(true);
      setMyLiquidityPairs(newMyLiquidityPairs.sort((a, b) => +a.lpAssetId! - +b.lpAssetId!));
    }
  }, [
    assetDetails,
    assetDetailsIsReady,
    assetInfos,
    assetInfosIsReady,
    calculateApyByAssetId,
    isReady,
    tokenPairs,
    transactionTokensIsReady
  ]);

  return {
    isReady,
    myLiquidityPairs
  };
};
