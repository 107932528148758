import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useEffect, useMemo, useState, useCallback, memo } from 'react';
import dayjs from 'dayjs';
import {
  Stack,
  Inline,
  H5,
  SmallText,
  Text,
  BigText,
  Card,
  Button,
  InputChangeTarget
} from '@parallel-mono/components';
import { u64, Option, StorageKey, u128 } from '@polkadot/types';
import { AssetId } from '@parallel-finance/types/interfaces';
import { ApiPromise } from '@polkadot/api';
import { CryptoAsset } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';
import { BN } from '@polkadot/util';

import { TOKEN_META } from '../constant';
import { isCurrentAddress } from '../utils';

import { TextFieldDateTimePicker } from './DateSelect';

import { Checkbox, InputText } from '@/components';
import TokenInput from '@/components/TokenInput';
import { isValidAddress } from '@/utils/utils';
import { balanceFormatter, truncateTextMid } from '@/utils/format';
import { useDevice, useAssetPrices, useAccount, useApiCall } from '@/hooks';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { signAndSend } from '@/utils/txCall';
import config from '@/config';
import { balanceToAmountByDecimal, amountToBalanceByDecimals } from '@/utils/calculations';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

const TokenWrapper = styled(Inline)`
  height: 40px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
`;

interface TokenInfo {
  name: string;
  symbol: string;
  decimals: number;
  tokenId: number;
  balance: number;
  maxAvailableBalance: number;
  value: string;
}

const InputSmall = styled(Stack)`
  > div {
    height: 3rem;
  }
  input::placeholder {
    font-weight: normal;
  }
`;

// KSM, HKO, DOT, PARA
const formToken = config.isParallel ? ['DOT', 'PARA'] : ['KSM', 'HKO'];

const CreateForm = ({ api }: { api: ApiPromise }) => {
  const { isMobile } = useDevice();
  const { assetInfos } = useCurrentAccountAssetInfos();
  const assetsPrice = useAssetPrices();
  const { account } = useAccount();

  // needed token information
  const formTokenList = useMemo(() => {
    return assetInfos
      .filter(info => formToken.includes(info.symbol))
      .map(({ assetId, symbol, availableBalance, maxAvailableBalance }) => ({
        tokenId: Number(assetId),
        name: TOKEN_META[Number(assetId)]?.name,
        symbol,
        balance: availableBalance,
        maxAvailableBalance,
        value: symbol
      }));
  }, [assetInfos]);

  // block timestamp
  const blockTimestamp = useApiCall(api.query.timestamp.now, null, {
    transform: (value: Option<u64>) => {
      return value.toString();
    }
  });

  // form selected token meta
  const [selectedTokenMeta, setSelectedTokenMeta] = useState(
    {} as {
      tokenId: number;
      symbol: string;
      value: string;
      balance: number;
      maxAvailableBalance: number;
    }
  );

  const minimumDepositsOpt = useMemo(
    () => ({
      transform: (values: [StorageKey<[AssetId]>, Option<u128>][]) => {
        return values.reduce<Record<string, number>>((result, [keys, option]) => {
          const {
            args: [assetId]
          } = keys;

          const tokenId = assetId.toString();
          const assetInfo = assetInfos.find(info => info.assetId === tokenId);

          if (option.isSome && assetInfo) {
            result[tokenId] = balanceToAmountByDecimal<number>(
              option.unwrap(),
              assetInfo.decimals,
              'number'
            );
          }
          return result;
        }, {});
      }
    }),
    [assetInfos]
  );

  const minimumDeposits = useApiCall(
    assetInfos.length > 0 ? api.query.streaming.minimumDeposits.entries : undefined,
    null,
    minimumDepositsOpt
  );

  const [tokenInput, setTokenInput] = useState(0);
  const [addressDisplay, setAddressDisplay] = useState('');
  const [address, setAddress] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // address error state
  const [addressError, setAddressError] = useState('');
  // date input error state
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  // state of if stream button can click
  const [isDisabled, setIsDisabled] = useState(true);
  const [pending, setPending] = useState(false);

  const minimumDepositWarning = useMemo(() => {
    if (minimumDeposits?.[selectedTokenMeta.tokenId] && tokenInput > 0) {
      return tokenInput < minimumDeposits[selectedTokenMeta.tokenId];
    }
    return false;
  }, [minimumDeposits, selectedTokenMeta, tokenInput]);

  // update the default selected token info
  useEffect(() => {
    if (formTokenList.length) {
      setSelectedTokenMeta(currentSelectedTokenMeta => {
        // first execution
        if (!currentSelectedTokenMeta.symbol) {
          return formTokenList[0];
        }
        // update selectedTokenMeta, e.g. balance changed, switch account
        return (
          formTokenList.find(value => value.symbol === currentSelectedTokenMeta.symbol) ||
          formTokenList[0]
        );
      });
    }
  }, [formTokenList]);

  // control of whether the button is disabled
  useEffect(() => {
    if (
      !pending &&
      tokenInput &&
      tokenInput <= selectedTokenMeta.balance &&
      address &&
      !addressError &&
      startDate &&
      !startDateError &&
      endDate &&
      !endDateError &&
      isChecked &&
      !minimumDepositWarning
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    return () => {};
  }, [
    tokenInput,
    address,
    addressError,
    isChecked,
    selectedTokenMeta,
    startDate,
    endDate,
    startDateError,
    endDateError,
    pending,
    minimumDepositWarning
  ]);
  // show address error when switch account
  useEffect(() => {
    if (address === account.address) {
      setAddressError('You cannot stream to your own address.');
    }
  }, [address, account]);

  const renderSelectedToken = (option: TokenInfo | null) => {
    const { symbol } = option || {};
    if (!option || !symbol) {
      return null;
    }
    return (
      <TokenWrapper
        inset="0.1rem 1rem 0.1rem 0.1rem"
        gap="0.5rem"
        justifyContent="flex-start"
        alignItems="center"
      >
        <CryptoAsset symbol={symbol} symbolSize="small" />
        <Text>{symbol}</Text>
      </TokenWrapper>
    );
  };

  const renderTokenOption = ({ symbol, name, balance }: TokenInfo) => {
    return (
      <Inline
        gap={isMobile ? '0' : '1.5rem'}
        justifyContent="space-between"
        alignItems="center"
        inset="0.75rem 1.5rem"
      >
        <Inline
          inset={`0 ${isMobile ? 0 : 4}rem 0 0`}
          gap="0.75rem"
          justifyContent="flex-start"
          alignItems="center"
        >
          <CryptoAsset symbol={symbol} />
          <Stack gap="0">
            <Text>{symbol}</Text>
            <SmallText
              skin="secondary"
              style={{
                width: isMobile ? '80px' : '100px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {name}
            </SmallText>
          </Stack>
        </Inline>
        <Stack style={{ width: '5rem', textAlign: 'right' }}>
          <BigText>{formatNumber(balance)}</BigText>
        </Stack>
      </Inline>
    );
  };

  const onTokenSelectedChange = (tokenMeta: TokenInfo) => {
    setSelectedTokenMeta(tokenMeta);
    setTokenInput(0);
  };

  const onTokenInputChange = (value: number) => {
    setTokenInput(value);
  };

  const calcFromUsdQty = () => {
    if (assetsPrice && assetsPrice[selectedTokenMeta.tokenId]) {
      return tokenInput * assetsPrice[selectedTokenMeta.tokenId];
    }
    return 0;
  };

  const onAddressChange = useCallback((value: string) => {
    setAddressError('');
    setAddress(value);
    setAddressDisplay(value);
  }, []);
  const onAddressBlur = useCallback(
    ({ target: { value } }) => {
      if (!value) {
        return;
      }
      let errorTip = '';
      if (!isValidAddress(value)) {
        errorTip = 'Enter a valid Polkadot/ Parallel address.';
      } else if (isCurrentAddress(value, account.address)) {
        errorTip = 'You cannot stream to your own address.';
      } else {
        setAddressDisplay(truncateTextMid(value, 8, 8));
      }
      setAddressError(errorTip);
    },
    [account]
  );
  const onAddressFocus = () => {
    setAddressDisplay(address);
  };

  const onStartDateChange = (value: string) => {
    setStartDate(value);
  };

  const onEndDateChange = (value: string) => {
    setEndDate(value);
  };

  const startTimestamp = useMemo(() => {
    return startDate ? Number(dayjs(startDate)?.valueOf()) : 0;
  }, [startDate]);

  const endTimestamp = useMemo(() => {
    return endDate ? Number(dayjs(endDate)?.valueOf()) : 0;
  }, [endDate]);

  useAsyncEffect(() => {
    if (startTimestamp > 0 && startTimestamp < Number(blockTimestamp)) {
      setStartDateError('Enter a valid start date and time.');
    } else if (endTimestamp && startTimestamp >= endTimestamp) {
      setStartDateError('The start date need to be before the end date.');
    } else {
      setStartDateError('');
    }
    setEndDateError('');
  }, [blockTimestamp, startTimestamp]);

  useAsyncEffect(() => {
    if (startTimestamp && endTimestamp <= startTimestamp) {
      setEndDateError('Enter a valid end date that is after the start date.');
    } else {
      setEndDateError('');
      if (
        startTimestamp &&
        startTimestamp > Number(blockTimestamp) &&
        startTimestamp < endTimestamp
      ) {
        setStartDateError('');
      }
    }
  }, [blockTimestamp, endTimestamp]);

  const onCheckboxChange = (target: InputChangeTarget) => {
    setIsChecked(target.checked);
  };

  const onCreateStream = () => {
    // startDate field validation
    if (startTimestamp < Number(blockTimestamp)) {
      setStartDateError('Enter a valid start date and time.');
      return;
    }
    setPending(true);
    const amount = amountToBalanceByDecimals<BN>(
      tokenInput,
      TOKEN_META[selectedTokenMeta.tokenId].decimals,
      'bn'
    );
    signAndSend({
      api,
      tx: api.tx.streaming.create(
        address,
        amount,
        selectedTokenMeta.tokenId,
        dayjs(startTimestamp).unix(),
        dayjs(endTimestamp).unix(),
        true
      ),
      account,
      txSuccessCb: () => {
        ReactDOM.unstable_batchedUpdates(() => {
          setAddress('');
          setAddressDisplay('');
          setTokenInput(0);
          setStartDate('');
          setEndDate('');
          setIsChecked(false);
          setPending(false);
        });
      },
      txFailedCb: () => {
        setPending(false);
      }
    });
  };

  const { symbol, maxAvailableBalance } = selectedTokenMeta;

  const paymentRate =
    startTimestamp - endTimestamp ? (tokenInput / (startTimestamp - endTimestamp)) * 1000 : 0;

  return (
    <Card>
      <Stack gap="1.5rem">
        <Stack gap=".5rem">
          <H5>Send</H5>
          <TokenInput
            value={selectedTokenMeta}
            options={formTokenList}
            onSelect={onTokenSelectedChange}
            renderItem={renderTokenOption}
            renderDisplay={renderSelectedToken}
            onInputChange={onTokenInputChange}
            qty={tokenInput}
            usdBalance={calcFromUsdQty()}
            checkBalance
            max={maxAvailableBalance}
            rightBottomInfo={`Max: ${balanceFormatter(maxAvailableBalance || 0)} ${symbol}`}
            renderErrorState={
              minimumDepositWarning && (
                <Text skin="error">
                  Send amount cannot be less than {minimumDeposits?.[selectedTokenMeta.tokenId]}{' '}
                  {selectedTokenMeta.symbol}
                </Text>
              )
            }
          />
        </Stack>
        <Stack gap=".5rem">
          <H5>To</H5>
          <InputSmall gap="0.5rem">
            <InputText
              value={addressDisplay}
              placeholder="Address"
              onChange={onAddressChange}
              onBlur={onAddressBlur}
              onFocus={onAddressFocus}
              errorMessage={addressError}
              autoFocus={false}
            />
          </InputSmall>
        </Stack>
        <Stack gap=".5rem">
          <H5>Start Date</H5>
          <TextFieldDateTimePicker
            value={startDate}
            onChange={onStartDateChange}
            error={startDateError}
          />
        </Stack>
        <Stack gap=".5rem">
          <H5>End Date</H5>
          <TextFieldDateTimePicker
            value={endDate}
            onChange={onEndDateChange}
            error={endDateError}
          />
        </Stack>
        <Checkbox checked={isChecked} onChange={onCheckboxChange}>
          <SmallText skin="secondary">
            {`The payment rate will be ${balanceFormatter(
              paymentRate
            )} ${symbol} per second and the stream will end on ${
              endDate ? dayjs(endDate).format('DD MMM YYYY @hh:mma') : '--'
            }.`}
          </SmallText>
        </Checkbox>
        <Button disabled={isDisabled} block skin="primary" onClick={onCreateStream}>
          Stream
        </Button>
      </Stack>
    </Card>
  );
};

export default memo(CreateForm);
