import { FunctionComponent, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { Popup, PopupProps } from '../Popup';
import { Icon } from '../icon/icon';
import { ModalClassNames } from './types';
import cx from 'classnames';

export type ModalProps = Omit<PopupProps, 'title'> & {
  title?: ReactNode;
  closable?: boolean;
  children: ReactNode | ReactNode[];
  size?: string;
  classNames?: ModalClassNames;
};

const StyledPopup = styled(Popup)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.skin.text.primary};
  padding: ${({ theme }) => theme.spacing(8)};
`;

const ModalContent = styled.div<{ size: string }>`
  box-sizing: border-box;
  background: white;
  width: ${({ size }) => size};
  max-height: calc(100% - ${({ theme }) => theme.spacing(16)});
  box-shadow: 0px 0px 16px 0px #3a4e580d;
  border-radius: 12px;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  overflow: hidden;
`;

const ModalBody = styled.div<{ fullPadding: boolean }>`
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(8)};
  padding-top: ${({ fullPadding, theme }) => (fullPadding ? theme.spacing(8) : 0)};
  box-sizing: border-box;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  position: absolute;
  top: ${({ theme }) => theme.spacing(4)};
  right: ${({ theme }) => theme.spacing(4)};
`;

export const Modal: FunctionComponent<ModalProps> = ({
  title,
  children,
  onClose,
  closable = true,
  size = '480px',
  classNames,
  backdropProps,
  ...others
}) => {
  const mergedBackdropProps = useMemo(
    () => ({
      ...backdropProps,
      className: cx(backdropProps?.className, classNames?.backdrop),
    }),
    [classNames?.backdrop, backdropProps]
  );
  return (
    <StyledPopup {...others} backdropProps={mergedBackdropProps} onClose={onClose}>
      <ModalContent size={size} className={cx(classNames?.modal)}>
        {title && <ModalHeader className={cx(classNames?.header)}>{title}</ModalHeader>}
        {closable && (
          <CloseButton
            data-testid="close-button"
            className={cx(classNames?.closeButton)}
            onClick={onClose}
          >
            <Icon name="close"></Icon>
          </CloseButton>
        )}
        <ModalBody fullPadding={!title} className={cx(classNames?.body)}>
          {children}
        </ModalBody>
      </ModalContent>
    </StyledPopup>
  );
};
