import { DefaultTheme } from 'styled-components';

export const blueLight: DefaultTheme['skin'] = {
  primary: {
    main: '#0C3EE3',
    contrastText: '#FFFFFF',
    secondaryContrastText: '#5E6D7C', //TODO: set proper color for secondaryContrastText
    contrastBackground: '#F0F3FB',
  },
  secondary: {
    main: '#F0F3FB',
    contrastText: '#0C3EE3',
    secondaryContrastText: '#5E6D7C', //TODO: set proper color for secondaryContrastText
    contrastBackground: '#0B2C9A',
  },
  neutral: {
    main: '#2F2F2F',
    contrastText: '#FFFFFF',
    secondaryContrastText: '#5E6D7C',
    contrastBackground: '#F0F3FB',
  },
  success: {
    main: '#11BC91',
    contrastText: '#E7F8F4',
    secondaryContrastText: '#5E6D7C', //TODO: set proper color for secondaryContrastText
    contrastBackground: '#E7F8F4',
  },
  warning: {
    main: '#FFBC0E',
    contrastText: '#FFF8E7',
    secondaryContrastText: '#5E6D7C', //TODO: set proper color for secondaryContrastText
    contrastBackground: '#FFF8E7',
  },
  error: {
    main: '#EB5757',
    contrastText: '#FDEEEE',
    secondaryContrastText: '#5E6D7C', //TODO: set proper color for secondaryContrastText
    contrastBackground: '#FDEEEE',
  },
  grey: {
    100: '#F8F8F8',
    200: '#EEEEEE',
    300: '#D7D9E1',
    500: '#9BA4AE',
    700: '#5E6D7C',
    900: '#2F2F2F',
    white: '#FFFFFF',
  },
  text: {
    primary: '#2F2F2F',
    secondary: '#5E6D7C',
  },
  action: {
    disabledOpacity: 0.6,
    hoverMask: 'rgba(0, 0, 0, 0.1)',
  },
  background: {
    main: '#FFFFFF',
    slot: '#F8F8F8',
  },
};
