import { forwardRef, LabelHTMLAttributes, memo, ReactNode, RefObject } from 'react';
import styled, { css } from 'styled-components';
import { ToggleBase, ToggleBaseProps } from './ToggleBase';

type LabelPlacement = 'start' | 'end' | 'top' | 'bottom';

export type ToggleProps = Omit<
  LabelHTMLAttributes<HTMLLabelElement>,
  keyof ToggleBaseProps | 'labelPlacement'
> & {
  label?: ReactNode;
  labelPlacement?: LabelPlacement;
  inputRef?: RefObject<HTMLInputElement>;
} & ToggleBaseProps;

const StyledLabel = styled.label<ToggleProps>`
  display: inline-flex;
  cursor: ${({ disabled = false }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled = false, theme }) => (disabled ? theme.skin.action.disabledOpacity : 1)};
  ${({ labelPlacement = 'end' }) => {
    switch (labelPlacement) {
      case 'start': {
        return css`
          flex-direction: row-reverse;
          align-items: center;
        `;
      }
      case 'top': {
        return css`
          flex-direction: column-reverse;
          justify-content: center;
        `;
      }
      case 'bottom': {
        return css`
          flex-direction: column;
          justify-content: center;
        `;
      }
      case 'end': {
        return css`
          flex-direction: row;
          align-items: center;
        `;
      }
      default: {
        return '';
      }
    }
  }}
`;
const LabelContainer = styled.span<Pick<ToggleProps, 'labelPlacement'>>`
  ${({ labelPlacement = 'end' }) => {
    switch (labelPlacement) {
      case 'start':
      case 'end': {
        return css`
        padding: 0 ${({ theme }) => theme.spacing(1)}};
      `;
      }
      case 'top':
      case 'bottom': {
        return css`
          padding: ${({ theme }) => `${theme.spacing(1)} 0`};
        `;
      }
    }
  }}
  user-select: none;
`;
export const ToggleWithLabel = memo(
  forwardRef<HTMLLabelElement, ToggleProps>(
    (
      {
        skin,
        disabled,
        checked,
        onChange,
        defaultChecked,
        label,
        labelPlacement = 'end',
        inputRef,
        sliderContent,
        ...otherProps
      },
      ref
    ) => {
      return (
        <StyledLabel ref={ref} {...otherProps} labelPlacement={labelPlacement} disabled={disabled}>
          <ToggleBase
            skin={skin}
            onChange={onChange}
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            sliderContent={sliderContent}
            inputRef={inputRef}
          />
          {label && <LabelContainer labelPlacement={labelPlacement}>{label}</LabelContainer>}
        </StyledLabel>
      );
    }
  )
);
