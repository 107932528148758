import { isNil, isNumber } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedInputBase, FormattedInputBaseProps } from '../FormattedInput';
import { formatNumberStr, parseNumberToString, parseStringToNumber } from './utils';

export type NumericInputBaseCustomProps = {
  value?: number | null;
  max?: number;
  min?: number;
  decimals?: number;
  defaultValue?: number;
  onChange?: (value: number | null) => void;
  thousandSeparator?: boolean;
};

export type NumericInputBaseProps = NumericInputBaseCustomProps &
  Omit<FormattedInputBaseProps, keyof NumericInputBaseCustomProps | 'format' | 'isValid'>;

export const NumericInputBase = memo(
  forwardRef<HTMLInputElement, NumericInputBaseProps>((props, ref) => {
    const {
      value,
      onChange,
      decimals,
      defaultValue,
      min,
      max,
      thousandSeparator = false,
      ...others
    } = props;

    const finalValue = value === undefined ? defaultValue : value;
    const [strValue, setStrValue] = useState(parseNumberToString(finalValue, thousandSeparator));

    const validRegExp = useMemo(() => {
      const calculateDecimalPart = decimals => {
        if (isNil(decimals)) {
          return '(?:\\.[0-9]*)?';
        }
        if (decimals === 0) {
          return '';
        }
        return `(?:\\.[0-9]{0,${decimals}})?`;
      };
      const decimalPart = calculateDecimalPart(decimals);
      const negativeSignPart = isNumber(min) && min < 0 ? '[-]?' : '';
      const integerPart = thousandSeparator ? '[0-9]{0,3}(?:,?[0-9]{0,3})*' : '[0-9]*';
      return new RegExp(`^${negativeSignPart}${integerPart}${decimalPart}$`);
    }, [decimals, thousandSeparator, min]);

    const isValid = useCallback(
      (str: string) => {
        const currentValue = parseStringToNumber(str);
        if (isNumber(max) && isNumber(currentValue) && currentValue > max) return false;
        if (isNumber(min) && isNumber(currentValue) && currentValue < min) return false;
        return validRegExp.test(str);
      },
      [min, max, validRegExp]
    );

    const handleChange = useCallback(
      (str: string) => {
        const numberValue = parseStringToNumber(str);
        setStrValue(str);
        onChange && onChange(numberValue);
      },
      [onChange]
    );

    const formatStringValue = useCallback(
      (str?: string) => formatNumberStr(str, thousandSeparator),
      [thousandSeparator]
    );

    useEffect(() => {
      const valueChanged = value !== undefined && value !== parseStringToNumber(strValue);
      const formatChanged = strValue !== formatStringValue(strValue);
      if (valueChanged || formatChanged) {
        setStrValue(parseNumberToString(value, thousandSeparator));
      }
    }, [value, thousandSeparator, formatStringValue, strValue]);

    return (
      <FormattedInputBase
        ref={ref}
        {...others}
        isValid={isValid}
        format={formatStringValue}
        value={strValue}
        onChange={handleChange}
      />
    );
  })
);
