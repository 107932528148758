import { FC } from 'react';
import { Inline, Stack, H4, BigText, Button } from '@parallel-mono/components';

import { ReactComponent as MoneyverseLogo } from './images/moneyverse.svg';

import { ConnectToWallet } from '@/components';
import { useAccount, useGAEventTracker } from '@/hooks';

interface EmptyDashboardProps {
  gotoAddLiquidity: () => void;
}
export const EmptyDashboard: FC<EmptyDashboardProps> = ({ gotoAddLiquidity }) => {
  const { account } = useAccount();
  const GAEventTrackerWalletConnect = useGAEventTracker('Wallet Connect Button');

  return (
    <Stack>
      <Stack justifyContent="center" alignItems="center" gap="0.3rem">
        <MoneyverseLogo width="3.75rem" />
        <H4> No Position </H4>
        <BigText skin="secondary"> Earn rewards by adding a position </BigText>
        <Inline inset="1rem 0 0 0">
          {account ? (
            <Button skin="primary" onClick={gotoAddLiquidity}>
              Add Liquidity
            </Button>
          ) : (
            <ConnectToWallet
              skin="secondary"
              action={() =>
                GAEventTrackerWalletConnect(
                  'Connect Wallet In Liquidity Component',
                  `Liquidity connectWallet, Empty Pools Dashboard`
                )
              }
            >
              Connect Wallet
            </ConnectToWallet>
          )}
        </Inline>
      </Stack>
    </Stack>
  );
};
