import { FC, useContext, useCallback } from 'react';
import { SmallText, Stack, Inline } from '@parallel-mono/components';

import WalletItem from './WalletItem';

import { WalletContext } from '@/contexts/WalletsContext';
import { WalletType } from '@/contexts/WalletsContext/types';
import { useAccount } from '@/hooks';

const WalletConnectionModal: FC = () => {
  const { loadAccounts } = useAccount();
  const { wallet: activeWallet, walletList, handleSwitchWallet } = useContext(WalletContext);
  const handleConnect = useCallback(
    (type: WalletType) => {
      handleSwitchWallet(type);
      loadAccounts();
    },
    [handleSwitchWallet, loadAccounts]
  );
  return (
    <Stack inset="2rem 1rem" gap="1rem">
      <Inline inset="0 0 0 0.5rem">
        <SmallText skin="secondary">Wallets</SmallText>
      </Inline>
      <Stack gap="0.5rem">
        {Object.values(walletList)
          .filter(wallet => wallet.enable)
          .sort(wallet => (wallet.type === 'parallel' ? -1 : 1))
          .map(wallet => {
            return (
              <WalletItem
                wallet={wallet}
                activeWallet={activeWallet}
                handleConnect={handleConnect}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

export default WalletConnectionModal;
