import axios from 'axios';

import { TokenPrice, TokenPricesData } from '../types';

import config from '@/config';

export const getPricingData = async () => {
  const { data } = await axios.get(`${config.ANALYSIS_SERVICE_URL}/api/pricing`);
  return data;
};

export const getTokenPricesByUnit = async (
  unit: 'hour' | 'day',
  pageSize: number
): Promise<TokenPricesData[]> => {
  const { data } = await axios.get<{ data: TokenPricesData[] }>(
    `${config.parallelServiceBaseUrl}/assets/v2/price/snapshot?pageSize=${pageSize}&unit=${unit}`
  );
  return data.data;
};

export const getAssetPriceByBlockHeight = async (blockHeight: number): Promise<TokenPrice[]> => {
  const {
    data: { data }
  } = await axios.get<{ data: TokenPricesData }>(
    `${config.parallelServiceBaseUrl}/assets/v2/price?blockHeight=${blockHeight}`
  );
  return data.data;
};
