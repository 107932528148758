import { useEffect, useState } from 'react';
import { AssetId } from '@polkadot/types/interfaces';
import { StorageKey, Option } from '@polkadot/types';
import { Market } from '@parallel-finance/types/interfaces';
import { zipObject } from 'lodash';

import { useChainConnections, useApiCall } from '@/hooks';
import { AssetMarket, CurrencyId } from '@/hooks/types';
import { NonEmptyArray } from '@/typings/basic';
import { isNonEmptyArray } from '@/utils/validate';

const useMarketAssets = () => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [marketAssetIds, setMarketAssetIds] = useState<NonEmptyArray<CurrencyId> | undefined>();
  const [assetsMarket, setAssetsMarket] = useState<AssetMarket | undefined>();

  const marketAssets = useApiCall<[StorageKey<[AssetId]>, Option<Market>][]>(
    api.query.loans.markets.entries
  );

  useEffect(() => {
    if (marketAssets) {
      const keys = marketAssets.flatMap(m => m[0]).map(({ args: [id] }) => id.toString());
      const markets = marketAssets.flatMap(m => (m[1].isSome ? m[1].unwrap() : undefined));
      if (isNonEmptyArray(keys)) {
        setMarketAssetIds(keys);
        setAssetsMarket(zipObject(keys, markets));
      }
    }
  }, [marketAssets]);

  return { marketAssetIds, assetsMarket };
};

export default useMarketAssets;
