import { memo } from 'react';
import styled from 'styled-components';
import { Inline, Stack, Card, Button, Skeleton } from '@parallel-mono/components';

import gradient from './images/auction-header-bg.svg';

import { useDevice } from '@/hooks';

const Wrapper = styled(Card).attrs({})`
  width: 30rem;
  background-image: url(${gradient});
  padding: 8rem 0 0 0;
  ${({ theme }) => theme.breakpoints.down('sm')`
    width: calc(100vw - 3rem);
  `};
`;
const Content = styled(Stack).attrs({
  inset: '2rem'
})`
  border-radius: 12px;
  box-shadow: var(--box-shadow02);
  background-color: var(--clr-white);
`;

const LoadingStatus = () => {
  const { isMobile } = useDevice();
  return (
    <Stack inset="2rem 0 0 0" alignItems="center">
      <Wrapper>
        <Content>
          <Skeleton.Button height="3rem" fullWidth variant="round" />
          <Skeleton.Button width="5rem" height="1.5rem" variant="round" />
          <Skeleton.Button width="20rem" fullWidth={isMobile} height="1.5rem" variant="round" />
          <Skeleton.Button width="10rem" height="1.5rem" variant="round" />
          <Skeleton.Button width="10rem" height="1.5rem" variant="round" />
          <Skeleton.Button width="10rem" height="1.5rem" variant="round" />
          <Skeleton.Button width="10rem" height="1.5rem" variant="round" />
          <Button size="large" block skin="primary" disabled>
            Stake
          </Button>
          <Inline justifyContent="center">
            <Skeleton.Button width="15rem" height="1.5rem" variant="round" />
          </Inline>
        </Content>
      </Wrapper>
    </Stack>
  );
};

export default memo(LoadingStatus);
