import { useState, memo, useCallback, useMemo, FC } from 'react';
import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { Stack, Inline, H5, Text, Button, Tooltip, Icon, Alert } from '@parallel-mono/components';
import { TokenInput, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { BigNumber } from 'bignumber.js';
import { BN } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

import { AssetRow } from '../../LendAndBorrowTable';
import { HfProgressBar } from '../../components/HfProgressBar';
import { useMarketAssets, useRewardValues, useLiquidationFreeLimit } from '../../hooks';

import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { ConnectToWallet } from '@/components';
import { useTransactionFee } from '@/hooks';
import { toFloorFixed, balanceFormatter } from '@/utils/format';
import { signAndSend } from '@/utils/txCall';
import { isValidAmountInput, isNumeric } from '@/utils/validate';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import config from '@/config';

interface IBorrowPaneProps {
  token: AssetRow;
  availableAmount?: number;
  handleClose: Function;
  api: ApiPromise;
  account: KeyringPair;
}

const BorrowPane: FC<IBorrowPaneProps> = ({
  token,
  availableAmount = 0,
  handleClose,
  api,
  account
}) => {
  const { assetsMarket } = useMarketAssets();
  const [borrowAmount, setBorrowAmount] = useState<number | null>(null);
  const [errorInputMessage, setErrorInputMessage] = useState('');
  const [processing, setProcessing] = useState(false);

  const toTXAmount = amountToBalanceByDecimals<BN>(borrowAmount || 0, token.decimals, 'bn');

  const { borrowRewardApys } = useRewardValues();
  const { TxFeeTips } = useTxFeeValidation();

  const transactionFee = useTransactionFee(
    {
      api,
      tx: api.tx.loans.borrow(token.assetId, toTXAmount)
    },
    [toTXAmount.toString()]
  );
  const { checkBorrowLiquidationFree, lfLiquidityLimit, getRemainingBorrowLimit } =
    useLiquidationFreeLimit();

  const isLiquidationFreeAsset = checkBorrowLiquidationFree(token.assetId);

  const remainingBorrowLimit = getRemainingBorrowLimit(token.assetId);

  const marketInfo = assetsMarket?.[token.assetId];

  const confirmBorrow = () => {
    setProcessing(true);
    signAndSend({
      api,
      account,
      tx: api.tx.loans.borrow(token.assetId, toTXAmount),
      txSuccessCb: () => {
        handleClose();
      },
      txProcessingCb: () => {
        handleClose();
      },
      txFailedCb: () => {
        setProcessing(false);
      }
    });
  };

  const inputChangeHandler = useCallback(
    (value: number | null) => {
      setErrorInputMessage('');

      if (value && !isNumeric(value)) {
        value = borrowAmount;
      }

      // check if entered amount is greater than max allowed amount
      if (Number(value) > Number(availableAmount)) {
        setErrorInputMessage('Cannot exceed borrow limit / insufficient collateral.');
      }
      // check for negative numbers
      if (value !== null && Number(value) <= 0) {
        setErrorInputMessage('Please enter a value greater than zero');
      }

      if (
        isNumeric(value) &&
        marketInfo?.borrowCap &&
        amountToBalanceByDecimals<BigNumber>(
          new BigNumber(value?.toString() ?? '').plus(token.borrowMarket),
          token.decimals,
          'bigNumber'
        ).isGreaterThan(marketInfo.borrowCap.toString())
      ) {
        setErrorInputMessage('The borrow amount has exceeded cap capped.');
      }

      if (isValidAmountInput(value?.toString() ?? '', 12, 6) || value === null) {
        setBorrowAmount(value);
      }
    },
    [availableAmount, borrowAmount, marketInfo, token]
  );

  const handleClickMax = useCallback(() => {
    const value = toFloorFixed(availableAmount * 0.5, 6);
    inputChangeHandler(value);
  }, [inputChangeHandler, availableAmount]);
  const borrowValue = useMemo(() => {
    return Number(borrowAmount) * token.price;
  }, [borrowAmount, token.price]);

  const newLfLiquidityLimit = useMemo(() => {
    const ret = lfLiquidityLimit - borrowValue;
    return Math.max(ret, 0);
  }, [lfLiquidityLimit, borrowValue]);

  const newBorrowLimit = useMemo(() => {
    if (!isLiquidationFreeAsset) {
      return remainingBorrowLimit - borrowValue;
    }
    if (newLfLiquidityLimit <= 0) {
      return remainingBorrowLimit - borrowValue + lfLiquidityLimit;
    }
    return remainingBorrowLimit;
  }, [
    remainingBorrowLimit,
    borrowValue,
    newLfLiquidityLimit,
    lfLiquidityLimit,
    isLiquidationFreeAsset
  ]);

  const renderWarnInfo = () => {
    const isOverBorrowWarning =
      Number(borrowAmount) > availableAmount * 0.9 && newBorrowLimit !== remainingBorrowLimit;
    if (Number(borrowAmount) > availableAmount) {
      return null;
    }

    return (
      <>
        {isOverBorrowWarning && (
          <Alert type="error">
            You are borrowing above 90% of your limit, which increases the risk of liquidation.
          </Alert>
        )}
        <HfProgressBar isLiquidationFreeAsset={isLiquidationFreeAsset} addBorrow={borrowValue} />
      </>
    );
  };

  const infos = useMemo(
    () =>
      [
        {
          title: 'Borrow APY',
          value: formatNumber(token.borrowRate, { output: 'percent' })
        },
        borrowRewardApys[token.assetId]
          ? {
              title: `${config.nativeToken} Rewards`,
              tip: `You will earn an estimated ${config.nativeToken} farm reward for borrowing this asset. Rewards can be claimed from your dashboard.`,
              value: formatNumber(borrowRewardApys[token.assetId] || 0, { output: 'percent' })
            }
          : null,
        isLiquidationFreeAsset
          ? {
              title: 'Liquidation Free Limit',
              tip: 'This is the maximum amount you can borrow for a liquidation free loan with no risk of liquidation, after you add collateral. It depends on the value you have deposited and the available liquidity.',
              value: (
                <Inline gap="0.2rem">
                  <H5> {formatNumber(lfLiquidityLimit, { output: 'currency' })} </H5>
                  {!!borrowAmount && (
                    <>
                      <Icon name="arrowRight" />
                      <H5>{formatNumber(newLfLiquidityLimit, { output: 'currency' })}</H5>
                    </>
                  )}
                </Inline>
              )
            }
          : null,
        {
          title: 'Borrow Limit',
          tip: 'This is your current borrow limit, and it will update to a new limit after you successfully submit this transaction. The limit is based on your collateralized amount and the available liquidity.',
          value: (
            <Inline gap="0.2rem">
              <H5> {formatNumber(remainingBorrowLimit, { output: 'currency' })} </H5>
              {!!borrowAmount && (
                <>
                  <Icon name="arrowRight" />
                  <H5>{formatNumber(newBorrowLimit, { output: 'currency' })}</H5>
                </>
              )}
            </Inline>
          )
        }
      ].filter(info => info) as InfoPanelProps['infos'],
    [
      borrowAmount,
      borrowRewardApys,
      isLiquidationFreeAsset,
      lfLiquidityLimit,
      newBorrowLimit,
      newLfLiquidityLimit,
      remainingBorrowLimit,
      token
    ]
  );

  return (
    <Stack gap="1.5rem">
      <TokenInput
        label={<H5>{token.symbol} Amount</H5>}
        hint={
          <Inline gap="0.2rem" alignItems="center">
            <Inline gap="0.5rem" alignItems="center">
              <Text skin="secondary">Limit:</Text>
              <H5>{balanceFormatter(availableAmount)}</H5>
            </Inline>
            <Inline>
              <Tooltip content="This is the maximum amount you can borrow. It depends on the amount that you have deposited and the available liquidity." />
            </Inline>
          </Inline>
        }
        token={token.name.toUpperCase()}
        value={borrowAmount}
        onChange={inputChangeHandler}
        placeholder="0"
        onAction={handleClickMax}
        error={errorInputMessage}
        actionButtonText="50% Limit"
      />
      {renderWarnInfo()}

      <InfoPanel infos={infos} />

      <Tooltip
        disabled={!!availableAmount}
        content="No borrow limit. To increase your borrow limit, supply assets and make them collaterals."
      >
        {account ? (
          <Button
            skin="primary"
            size="large"
            onClick={() => confirmBorrow()}
            block
            disabled={processing || !borrowAmount || Boolean(errorInputMessage)}
          >
            {!borrowAmount ? 'Enter an Amount' : 'Borrow'}
          </Button>
        ) : (
          <ConnectToWallet block skin="secondary">
            Connect Wallet
          </ConnectToWallet>
        )}
      </Tooltip>
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default memo(BorrowPane);
