import { StorageEntryTypeLatest } from '@polkadot/types/interfaces';
import { PromiseResult } from '@polkadot/api/types';
import { AnyFunction } from '@polkadot/types/types';
import { isUndefined } from 'lodash';

import { ChainEvents } from '../chains/AbstractChain';

type TrackFnResult<T> = Promise<T>;
interface QueryTrackFn {
  (...params: any[]): TrackFnResult<unknown>;
  meta?: {
    type?: StorageEntryTypeLatest;
  };
}

interface QueryMapFn extends QueryTrackFn {
  meta: {
    type: StorageEntryTypeLatest;
  };
}

export type TrackFn = PromiseResult<AnyFunction> | QueryTrackFn;

export type SubscribeFn = <T>(
  fn: TrackFn,
  params: any[],
  subscribeKey?: string,
  eventName?: ChainEvents
) => Promise<T>;

const isMapFn = (fn: unknown): fn is QueryMapFn => {
  return !!(fn as QueryTrackFn).meta?.type?.isMap;
};

export const subscribe = <T>(
  fn: TrackFn,
  params: any[],
  callback: (data?: T) => void
): Promise<T> | undefined => {
  const validParams = params.filter(p => !isUndefined(p));

  const canQuery =
    !!fn && (isMapFn(fn) ? validParams.length === fn.meta.type.asMap.hashers.length : true);

  if (!canQuery) {
    return undefined;
  }
  return fn(...validParams, callback) as TrackFnResult<T>;
};
