import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';
import { AnyNumber } from '@polkadot/types/types';

import config from '@/config';
import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';

const createXcm = (address: string) => ({
  V3: {
    parents: 1,
    interior: {
      X2: [
        {
          Parachain: config.paraId
        },
        {
          AccountId32: {
            network: null,
            id: addressToHex(address)
          }
        }
      ]
    }
  }
});

export const generateTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  return api.tx.xTokens.transfer(
    { Token: asset.symbol } as unknown as AnyNumber,
    amountToBalanceByDecimals<BN>(amount, asset.decimals, 'bn'),
    createXcm(account?.address),
    { Unlimited: '' }
  );
};
