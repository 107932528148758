import React, { memo, useState, useEffect } from 'react';
import { BN } from '@polkadot/util';
import { AccountInfo } from '@polkadot/types/interfaces';
import { BigNumber } from 'bignumber.js';
import { Stack, H3, SmallText, Button, Tooltip } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import { isNumber } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import { useRewardValues } from '../hooks';
import { loansFarmingRewardAccountId } from '../helper/loansFarmingRewardAccountId';

import config from '@/config';
import {
  useChainConnections,
  useTransactionFee,
  useAssetPrices,
  useAccount,
  useApiCall
} from '@/hooks';
import { ModalFunc } from '@/hooks/useModal';
import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { signAndSend } from '@/utils/txCall';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

interface IRewardsClaimModalProps extends ModalFunc {}

const RewardsClaimModal: React.FC<IRewardsClaimModalProps> = ({ closeModal }) => {
  const [txnStarted, setTxnStarted] = useState(false);
  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();
  const { userUnclaimedRewardValue } = useRewardValues();
  const assetsPrice = useAssetPrices();
  const { nativeAssetInfo } = useCurrentAccountNativeAssetInfo();
  const { TxFeeTips } = useTxFeeValidation();
  const [availableBalance, setAvailableBalance] = useState<BigNumber>();
  const [claimErrorMessage, setClaimErrorMessage] = useState<string>();

  const accountInfo = useApiCall<AccountInfo>(account && api.query.system.account, [
    loansFarmingRewardAccountId()
  ]);

  useEffect(() => {
    if (accountInfo?.data) {
      const {
        data: { free, miscFrozen, feeFrozen }
      } = accountInfo;

      const lockedBalance = BN.max(miscFrozen, feeFrozen);

      const newAvailableBalance = balanceToAmountByDecimal<BigNumber>(
        free.sub(lockedBalance),
        config.nativeToken,
        'bigNumber'
      );

      setAvailableBalance(newAvailableBalance);
    }
  }, [accountInfo]);

  useEffect(() => {
    if (availableBalance && userUnclaimedRewardValue) {
      if (new BigNumber(userUnclaimedRewardValue).isGreaterThan(availableBalance)) {
        setClaimErrorMessage(
          'There’s an internal error in our reward pool. We’re currently fixing - please check back later.'
        );
      }
    }
  }, [availableBalance, userUnclaimedRewardValue]);

  const transactionFee = useTransactionFee({
    api,
    tx: api.tx.loans.claimReward()
  });

  const claimReward = () => {
    if (api) {
      try {
        setTxnStarted(true);
        signAndSend({
          api,
          tx: api.tx.loans.claimReward(),
          account,
          txSuccessCb: () => {
            setTxnStarted(false);
            closeModal();
          },
          txProcessingCb: () => {},
          txFailedCb: err => {
            setTxnStarted(false);
            closeModal();
            console.error(err);
          }
        });
      } catch (err) {
        setTxnStarted(false);
        console.error(err);
      }
    }
  };

  return (
    <Stack gap="1rem" justifyContent="center" alignItems="center">
      <CryptoAsset symbol={config.nativeToken.toUpperCase()} symbolSize="xlarge" />
      <Stack style={{ width: '100%' }} gap="1.5rem" justifyContent="center" alignItems="center">
        {isNumber(userUnclaimedRewardValue) && (
          <Stack gap="0rem" justifyContent="center" alignItems="center">
            <H3>{`${balanceFormatter(userUnclaimedRewardValue)} ${config.nativeToken}`}</H3>
            <SmallText skin="secondary">{`~${formatNumber(
              userUnclaimedRewardValue * (assetsPrice?.[nativeAssetInfo?.assetId] || 0),
              { output: 'currency' }
            )}`}</SmallText>
          </Stack>
        )}

        <Stack style={{ width: '100%' }} gap="1rem" justifyContent="center" alignItems="center">
          <Tooltip content={claimErrorMessage}>
            <Button
              block
              skin="primary"
              size="large"
              disabled={!userUnclaimedRewardValue || txnStarted || !!claimErrorMessage}
              onClick={claimReward}
            >
              Claim {`${balanceFormatter(userUnclaimedRewardValue || 0)} ${config.nativeToken}`}
            </Button>
          </Tooltip>

          <TxFeeTips txFee={transactionFee} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(RewardsClaimModal);
