import styled from 'styled-components';
import { Skin } from '../ThemeProvider';

export const InputContainer = styled.div<{ disabled: boolean; skin: Skin }>`
  border: 2px solid ${({ theme }) => theme?.skin?.grey?.[300]};
  border-radius: ${({ theme }) => theme.border.radius.medium};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: ${({ theme }) => theme.spacing(2)};
  width: 160px;
  display: inline-block;
  opacity: ${({ theme, disabled }) => (disabled ? theme?.skin?.action?.disabledOpacity : 1)};
  :hover,
  &:focus-within {
    border: 2px solid
      ${({ theme, disabled, skin }) =>
        disabled ? theme?.skin?.grey?.[300] : theme.skin[skin].main};
  }
`;
