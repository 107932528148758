import styled from 'styled-components';
import dayjs from 'dayjs';
import { Stack, SmallText, ProgressBar } from '@parallel-mono/components';
import { useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import useCalculatedValue from './useCalculatedValue';
import { StreamListItem } from './useFetchList';

import { balanceFormatter } from '@/utils/format';
import { zero } from '@/utils/values';

const ProgressWrapper = styled(Stack)`
  width: 80%;
`;

const processTypeToColorMap = {
  normal: 'var(--clr-blue)',
  warning: 'var(--clr-yellow)',
  danger: 'var(--clr-red)',
  safe: 'var(--clr-green)',
  inactive: 'var(--clr-gray01)'
};

const TableRowProcess = ({ data }: { data: StreamListItem }) => {
  const { timestamp, remainingNumber, claimableTotal, depositNumber } = useCalculatedValue(data);

  const {
    displayValue = 0,
    processType = 'normal',
    processValue = 0,
    statusText = ''
  } = useMemo(() => {
    if (!timestamp) {
      return {};
    }
    const { startTime, endTime, status } = data;
    const startTimeUnix = dayjs(startTime).unix();
    const endTimeUnix = dayjs(endTime).unix();
    const nowTimeUnix = dayjs(Number(timestamp)).unix();

    let value = zero;
    let type: 'normal' | 'safe' | 'inactive' = 'normal';
    let processbarValue = 0;
    let text = '';

    if (status.Ongoing) {
      type = 'normal';
      // Not Started
      if (startTimeUnix > nowTimeUnix) {
        value = zero;
        processbarValue = 0;
        text = 'Not Started';
      }
      // Time end but not withdraw
      else if (nowTimeUnix > endTimeUnix) {
        type = 'safe';
        value = depositNumber;
        processbarValue = 1;
        text = `${formatNumber(processbarValue, { output: 'percent', decimal: 0 })} Complete`;
      }
      // in progress
      else if (!status.Ongoing.asCollateral) {
        value = claimableTotal;
        processbarValue = claimableTotal.div(depositNumber).toNumber();
        text = `${formatNumber(processbarValue, { output: 'percent', decimal: 0 })}`;
      }
    } else if (status.Completed) {
      // Cancelled
      if (status.Completed?.cancelled) {
        value = depositNumber.minus(remainingNumber);
        type = 'inactive';
        processbarValue = depositNumber.minus(remainingNumber).div(depositNumber).toNumber();
        text = `${formatNumber(processbarValue, { output: 'percent', decimal: 0 })} Cancelled`;
      } else {
        // Normal Completed
        value = depositNumber;
        type = 'safe';
        processbarValue = 1;
        text = `${formatNumber(processbarValue, {
          output: 'percent',
          decimal: 0
        })} Complete`;
      }
    }
    return {
      displayValue: value.toNumber(),
      processType: type,
      processValue: processbarValue,
      statusText: text
    };
  }, [timestamp, data, remainingNumber, claimableTotal, depositNumber]);

  return (
    <ProgressWrapper gap="0.5rem">
      <SmallText>{balanceFormatter(displayValue)}</SmallText>
      <ProgressBar
        background="var(--clr-gray03)"
        value={processValue * 100}
        color={processTypeToColorMap[processType]}
      />
      <SmallText skin="secondary">{statusText}</SmallText>
    </ProgressWrapper>
  );
};

export default TableRowProcess;
