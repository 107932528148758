import { useCallback } from 'react';
import { BN } from '@polkadot/util';

import { useChainConnections } from '@/hooks';
import { addressToHex } from '@/utils/utils';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import config from '@/config';

const useXcmTx = (value: number, decimals: BN, address: string) => {
  const {
    relaychain: { api: relayApi }
  } = useChainConnections();

  return useCallback(() => {
    const amount = amountToBalanceByDecimals<BN>(value, decimals, 'bn');
    // Kusama should use V2, but Polkadot need to use V1
    const { xcmVersion, paraId } = config;
    const params = [
      {
        [xcmVersion]: {
          parents: 0,
          interior: {
            X1: {
              Parachain: paraId
            }
          }
        }
      },
      {
        [xcmVersion]: {
          parents: 0,
          interior: {
            X1: {
              AccountId32: {
                network: 'Any',
                id: addressToHex(address)
              }
            }
          }
        }
      },
      {
        [xcmVersion]: [
          {
            id: {
              Concrete: {
                parents: 0,
                interior: 'Here'
              }
            },
            fun: {
              Fungible: amount
            }
          }
        ]
      },
      0
    ];
    return relayApi.tx.xcmPallet.reserveTransferAssets(...params);
  }, [address, decimals, relayApi.tx.xcmPallet, value]);
};

export default useXcmTx;
