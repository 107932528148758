import { useState, useCallback, useMemo } from 'react';
import { find } from 'lodash';
import { KeyringPair } from '@polkadot/keyring/types';
import { createTestKeyring } from '@polkadot/keyring';

import config from '@/config';
import useLoadAccounts from '@/hooks/useAccount/useLoadAccount';
import { ContextData } from '@/hooks/useAccount';
import { isWalletInstalled } from '@/utils/extension';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { ExtensionWallets, WalletType } from '@/contexts/WalletsContext/types';

const SELECTED_ACCOUNT_STORAGE_KEY = 'subWallet:account:selected';

const useSubWallet = () => {
  const [walletInstalled, setWalletInstalled] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState<ContextData['account']>();
  const [accountList, setAccountList] = useState<ContextData['accountList']>();
  const { keyring } = useLoadAccounts(ExtensionWallets.SubWallet);

  const handleSwitchAccount: ContextData['handleSwitchAccount'] = useCallback((value, callback) => {
    setSelectedAccount(value);
    window.localStorage.setItem(SELECTED_ACCOUNT_STORAGE_KEY, value.address);
    if (typeof callback === 'function') callback(value);
  }, []);

  useAsyncEffect(async () => {
    const keyringPairs = keyring?.getPairs();
    if (keyringPairs) {
      // Only for the development env
      const testKeyring = createTestKeyring();
      const testingAccounts = config.DEBUG ? testKeyring.getPairs() : [];

      const allAccounts = [...keyringPairs, ...testingAccounts] as KeyringPair[];
      const prevSelected = find(allAccounts, [
        'address',
        window.localStorage.getItem(SELECTED_ACCOUNT_STORAGE_KEY)
      ]);
      setAccountList(allAccounts);
      setSelectedAccount(prevSelected || allAccounts?.[0]);
    }
  }, [keyring]);

  useAsyncEffect(async () => {
    const installed = await isWalletInstalled(WalletType.SubWallet);
    setWalletInstalled(installed as boolean);
  }, []);

  const walletValue = useMemo(
    () => ({
      accounts: accountList,
      selectedAccount,
      switchAccount: handleSwitchAccount,
      walletInstalled
    }),
    [selectedAccount, accountList, handleSwitchAccount, walletInstalled]
  );

  return walletValue;
};

export default useSubWallet;
