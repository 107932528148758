import { memo, FC, useMemo, useCallback } from 'react';
import { map, min } from 'lodash';
import {
  Stack,
  Text,
  SmallText,
  Tooltip,
  Inline,
  H3,
  H5,
  Button,
  ProgressBar,
  Skeleton
} from '@parallel-mono/components';
import styled, { useTheme } from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { useLiquidationFreeLimit, useBorrowLimit } from '../hooks';
import AssetColumn from '../components/AssetColumn';
import { AssetRow } from '../LendAndBorrowTable';

import BorrowRepayModal, { BorrowedActionType } from './BorrowRepayModal';

import config from '@/config';
import {
  TokenPill,
  StaticPill,
  StyledDataGrid,
  StyledDataGridColumn,
  Collapse
} from '@/components';
import { useModal } from '@/hooks';
import { AssetsData, AssetInfo, AssetMarket, MarketData, AssetMarketStatus } from '@/hooks/types';
import { balanceFormatter } from '@/utils/format';

export interface BorrowPositionAssetRow extends AssetRow {
  borrowedAmount: number;
}

const ProgressBarStyled = styled(ProgressBar)`
  width: 17.5rem;
`;

interface BorrowPositionsTableProps {
  dataIsReady: boolean;
  assetInfos?: AssetInfo[];
  assetsPrice?: AssetsData;
  accountBorrow?: AssetsData;
  assetsMarket?: AssetMarket;
  marketStatus?: MarketData<AssetMarketStatus>;
  borrowRewardApys?: AssetsData;
}

const BorrowPositionsTable: FC<BorrowPositionsTableProps> = ({
  dataIsReady,
  assetInfos,
  assetsMarket,
  assetsPrice,
  marketStatus,
  accountBorrow,
  borrowRewardApys
}) => {
  const { skin } = useTheme();
  const { checkBorrowLiquidationFree, getBorrowLimit } = useLiquidationFreeLimit();
  const {
    totalSuppliedValue,
    collateralFactorLimit,
    liquidationPointLimit,
    totalAccountBorrowValue,
    liquidationFreeBorrow,
    lfCollateralLendTotal,
    lfAccountBorrowValue
  } = useBorrowLimit();

  const usedBorrowedValue = useMemo(
    () => Math.max(totalAccountBorrowValue - liquidationFreeBorrow, 0),
    [liquidationFreeBorrow, totalAccountBorrowValue]
  );

  const availableAmountForBorrow = useCallback(
    (token: BorrowPositionAssetRow) => {
      const { supplyMarket, borrowMarket, price, assetId } = token;
      const isLiquidationFreeAsset = checkBorrowLiquidationFree(assetId);
      const borrowLimit = getBorrowLimit(isLiquidationFreeAsset);
      return min([supplyMarket - borrowMarket, borrowLimit / price]);
    },
    [checkBorrowLiquidationFree, getBorrowLimit]
  );

  const rows: BorrowPositionAssetRow[] = map(assetInfos, ({ assetId, ...info }) => ({
    ...info,
    assetId,
    name: info.symbol,
    price: assetsPrice?.[assetId] ?? 0,
    supplyMarket: marketStatus?.[assetId]?.totalSupply ?? 0,
    supplyRate: marketStatus?.[assetId]?.supplyRate ?? 0,
    effectiveAPYLoading: marketStatus?.[assetId]?.effectiveAPYLoading ?? false,
    borrowMarket: marketStatus?.[assetId]?.totalBorrow ?? 0,
    borrowRate: marketStatus?.[assetId]?.borrowRate ?? 0,
    borrowedAmount: accountBorrow?.[assetId] ?? 0,
    borrowRewardApy: borrowRewardApys?.[assetId] ?? 0
  })).filter(a => {
    return assetsMarket?.[a.assetId]?.toJSON()?.state === 'Active' && a.borrowedAmount > 0;
  });

  const { openModal, holder } = useModal(BorrowRepayModal, { size: '500px' }, { assetInfos: rows });

  const handleClickBorrow = useCallback(
    (token: BorrowPositionAssetRow) => {
      openModal({
        tabKey: BorrowedActionType.Borrow,
        assetId: token.assetId,
        availableAmount: availableAmountForBorrow(token)
      });
    },
    [availableAmountForBorrow, openModal]
  );

  const handleClickRepay = useCallback(
    (token: BorrowPositionAssetRow) => {
      openModal({
        tabKey: BorrowedActionType.Repay,
        assetId: token.assetId,
        availableAmount: availableAmountForBorrow(token)
      });
    },
    [availableAmountForBorrow, openModal]
  );

  const progressBarSkin = useMemo(() => {
    if (usedBorrowedValue > collateralFactorLimit && usedBorrowedValue < liquidationPointLimit)
      return 'warning';

    if (usedBorrowedValue > liquidationPointLimit) return 'error';

    return 'success';
  }, [liquidationPointLimit, usedBorrowedValue, collateralFactorLimit]);

  const barMarkers = useMemo(() => {
    const borrowLimitPercentage = collateralFactorLimit / totalSuppliedValue;
    const liquidationLimitPercentage = liquidationPointLimit / totalSuppliedValue;
    if (
      Number.isNaN(borrowLimitPercentage) ||
      borrowLimitPercentage <= 0 ||
      Number.isNaN(liquidationLimitPercentage) ||
      liquidationLimitPercentage <= 0
    )
      return [];
    return [
      {
        value: (collateralFactorLimit / totalSuppliedValue) * 100,
        color: skin.warning.main,
        tip: {
          content: (
            <>
              <Text>Your borrow limit is at</Text>
              <H5>{formatNumber(collateralFactorLimit, { output: 'currency' })}</H5>
            </>
          )
        }
      },
      {
        value: (liquidationPointLimit / totalSuppliedValue) * 100,
        color: skin.error.main,
        tip: {
          content: (
            <>
              <Text>Your liquidation point is at</Text>
              <H5> {formatNumber(liquidationPointLimit, { output: 'currency' })}</H5>
            </>
          )
        }
      }
    ];
  }, [skin, liquidationPointLimit, collateralFactorLimit, totalSuppliedValue]);

  const healthFactor = useMemo(() => {
    const healthFactorValue =
      liquidationPointLimit /
      Math.max(totalAccountBorrowValue - Math.min(lfCollateralLendTotal, lfAccountBorrowValue), 0);

    if (Number.isNaN(healthFactorValue)) return null;
    if (healthFactorValue === Infinity) return <H5>-</H5>;

    if (healthFactorValue > 10) return <H5 skin="success">{'>10'}</H5>;

    if (healthFactorValue <= 10 && healthFactorValue > 1.2)
      return <H5 skin="success">{formatNumber(healthFactorValue)}</H5>;
    if (healthFactorValue <= 1.2 && healthFactorValue > 1)
      return <H5 skin="warning">{formatNumber(healthFactorValue)}</H5>;

    return <H5 skin="error">{formatNumber(healthFactorValue)}</H5>;
  }, [lfAccountBorrowValue, lfCollateralLendTotal, liquidationPointLimit, totalAccountBorrowValue]);

  const columns = useMemo<StyledDataGridColumn<BorrowPositionAssetRow>[]>(
    () => [
      {
        name: 'name',
        title: 'Assets',
        width: '1.5fr',
        isAvatar: true,
        render: ({ data: { name, assetId } }) => (
          <AssetColumn symbol={name} isLiquidationFree={checkBorrowLiquidationFree(assetId)} />
        )
      },
      {
        name: 'BorrowAmount',
        title: 'Borrow Amount',
        width: '1.5fr',
        render: ({ data: { borrowedAmount, price } }) => (
          <Stack gap="0.2rem">
            {balanceFormatter(borrowedAmount)}
            <SmallText skin="secondary">
              {formatNumber(borrowedAmount * price, { output: 'currency' })}
            </SmallText>
          </Stack>
        )
      },
      {
        name: 'apy',
        title: 'APY',
        width: '1.5fr',
        render: ({ data: { name, borrowRate, borrowRewardApy, effectiveAPYLoading, assetId } }) =>
          effectiveAPYLoading ? (
            <Skeleton.Button variant="round" />
          ) : (
            <Stack gap="0">
              <Inline gap="0.25rem">
                <Text>
                  {borrowRate > 0 ? '-' : ''}
                  {formatNumber(borrowRate, { output: 'percent' })}
                </Text>
                {assetId === config.sReplayAssetId && <Tooltip content="Effective APY" />}
              </Inline>
              {borrowRewardApy && borrowRewardApy > 0 ? (
                <Tooltip
                  content={`This is farming rewards for borrowing ${name}. It is calculated by per day compounding.`}
                >
                  <TokenPill
                    name={config.nativeToken.toUpperCase()}
                    content={formatNumber(borrowRewardApy, {
                      output: 'percent',
                      decimal: 1,
                      threshold: { min: 0.01, max: 100 }
                    })}
                  />
                </Tooltip>
              ) : null}
            </Stack>
          )
      },
      {
        name: 'action',
        width: '2fr',
        title: '',
        justifyContent: 'flex-end',
        render: ({ data }) => (
          <Inline gap="0.5rem">
            <Button skin="secondary" onClick={() => handleClickBorrow(data)}>
              Borrow More
            </Button>
            <Button skin="secondary" onClick={() => handleClickRepay(data)}>
              Repay
            </Button>
          </Inline>
        )
      }
    ],
    [checkBorrowLiquidationFree, handleClickBorrow, handleClickRepay]
  );

  return (
    <>
      <Collapse
        expand
        header={
          <Inline justifyContent="space-between" alignItems="center" inset="0 0 0 0.5rem">
            <H3>My Borrowed Positions</H3>
            <Inline>
              <StaticPill>
                <Inline alignItems="center">
                  <Inline gap="0.5rem" alignItems="center">
                    <SmallText as="span" skin="secondary">
                      Borrowed
                    </SmallText>
                    <H5 as="span">{formatNumber(usedBorrowedValue, { output: 'currency' })}</H5>
                  </Inline>
                  <ProgressBarStyled
                    value={(usedBorrowedValue / totalSuppliedValue) * 100}
                    skin={progressBarSkin}
                    markers={barMarkers}
                  />
                </Inline>
              </StaticPill>

              {healthFactor && (
                <StaticPill>
                  <Inline gap="0.5rem" alignItems="center">
                    <SmallText as="span" skin="secondary">
                      Health
                    </SmallText>
                    {healthFactor}
                  </Inline>
                </StaticPill>
              )}

              <StaticPill>
                <Inline gap="0.5rem" alignItems="center">
                  <SmallText as="span" skin="secondary">
                    Borrowed Liq. Free
                  </SmallText>
                  <H5 as="span">
                    {formatNumber(liquidationFreeBorrow, {
                      output: 'currency'
                    })}
                  </H5>
                </Inline>
              </StaticPill>
            </Inline>
          </Inline>
        }
      >
        <StyledDataGrid<BorrowPositionAssetRow>
          loading={!dataIsReady}
          columns={columns}
          data={rows}
          classNames={{ row: 'clickable' }}
        />
      </Collapse>
      {holder}
    </>
  );
};

export default memo(BorrowPositionsTable);
