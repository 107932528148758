import { ChangeEventHandler, FC, InputHTMLAttributes } from 'react';
import { noop } from 'lodash';
import { Inline } from '@parallel-mono/components';

import { Wrapper, Input, ErrorText } from '.';

interface InputWrapperProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  value?: string;
  errorMessage?: string | null;
  onChange: (value: string) => void;
}

const InputText: FC<InputWrapperProps> = ({
  value,
  placeholder,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  autoFocus = true,
  errorMessage
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(e.target.value);
  };

  return (
    <>
      <Wrapper withError={!!errorMessage} alignItems="center" justifyContent="space-between">
        <Inline gap="0.75rem" alignItems="center" width="100%">
          <Input
            type="text"
            placeholder={placeholder}
            value={value || ''}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            autoFocus={autoFocus}
          />
        </Inline>
      </Wrapper>
      {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
    </>
  );
};

export default InputText;
