import { memo, FC, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

const StyledSlider = styled.div<{ selectedPercent: number }>`
  width: 100%;

  > input[type='range'] {
    -webkit-appearance: none !important;
    width: 100%;
    height: 5px;
    background: linear-gradient(
      to right,
      #0c3ee3 ${({ selectedPercent }) => selectedPercent}%,
      #f0f3fb ${({ selectedPercent }) => selectedPercent}%
    );
    border: none;
    border-radius: 2px;
    outline: none;
    ::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      width: 20px;
      height: 20px;
      background: #fcfcfc;
      border: 2px solid #0c3ee3;
      border-radius: 50%;
      cursor: pointer;
    }
    ::-webkit-slider-thumb:hover {
      background: #0c3ee3;
    }
  }
`;

interface RangeSliderProps {
  value?: number;
  setValue: Dispatch<SetStateAction<number>>;
  minValue?: number;
  maxValue?: number;
}

const RangeSlider: FC<RangeSliderProps> = ({
  value = 0,
  setValue,
  minValue = 0,
  maxValue = 100
}) => {
  return (
    <StyledSlider selectedPercent={(value / (maxValue ?? 100 - minValue ?? 0)) * 100}>
      <input
        type="range"
        min={minValue}
        max={maxValue}
        value={value}
        onInput={e => setValue(parseInt(e.currentTarget.value, 10))}
      />
    </StyledSlider>
  );
};

export default memo(RangeSlider);
