import { memo } from 'react';
import { Stack } from '@parallel-mono/components';

import FarmingPoolTable from '@/pages/Farm/FarmingPoolTable';

const LoadingStatus = () => {
  return (
    <Stack>
      <FarmingPoolTable
        pools={[]}
        connected={false}
        onDeposit={() => {}}
        onClaim={() => {}}
        onWithdraw={() => {}}
        onRedeem={() => {}}
        loading
      />
    </Stack>
  );
};

export default memo(LoadingStatus);
