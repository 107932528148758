import { useDebounce } from 'react-use';
import { ApiPromise } from '@polkadot/api';
import { SubmittableExtrinsic } from '@polkadot/api/types';

import { TxFee } from './useTxFeeValidation';
import { useAccount } from './useAccount';
import useScopeState from './useScopeState';

import { txFee } from '@/utils/txCall';

interface ITxFee {
  api: ApiPromise;
  tx: SubmittableExtrinsic<'promise'> | null;
}

const useTransactionFee = ({ api, tx }: ITxFee, reFetchDeps: (string | number | null)[] = []) => {
  const [transactionFee, setTransactionFee] = useScopeState<TxFee>();
  const { account } = useAccount();

  useDebounce(
    () => {
      try {
        const getFee = async () => {
          if (account?.address && api && tx) {
            setTransactionFee(null);
            const fee = await txFee({
              tx,
              account
            });
            setTransactionFee(fee);
          }
        };
        getFee();
      } catch (err) {
        console.error(err);
      }
    },
    500,
    [account, ...reFetchDeps]
  );

  return transactionFee;
};

export default useTransactionFee;
