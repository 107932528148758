// @ts-nocheck
/* eslint-disable */
import { isArray } from 'lodash';

import { fixDate, toNumber, sortArrayByDate, groupArray } from '../helpers/others';
import { TokenPricesType, HtvlUnitType } from '../types';

import { CalculateSwapsType } from './models';

export const calculateSwaps: CalculateSwapsType = (
  poolList,
  poolInfo,
  assets,
  pricingData: TokenPricesType,
  tokenId,
  grouped = true
) => {
  if (poolInfo && poolList && assets && pricingData) {
    const { obtainedData } = poolList?.list?.reduce(
      (acc, item) => {
        const [currentPool] = poolInfo.filter(x => x.id === item.poolId);
        const initialPairToken = assets?.[currentPool?.base_asset_id];
        const quotePairToken = assets?.[currentPool?.quote_asset_id];
        const baseVolume = toNumber(item?.baseVolume, initialPairToken?.decimals);
        const quoteVolume = toNumber(item?.quoteVolume, quotePairToken?.decimals);
        const historicDate = fixDate(item?.block_timestamp);

        const { name: baseAssetName } = assets?.[initialPairToken?.id];
        const { name: quoteAssetName } = assets?.[quotePairToken?.id];

        const assetNameForPriceComps =
          pricingData?.[baseAssetName] || pricingData?.[quoteAssetName];
        const { val: usdValueOnHistoricDate } = (assetNameForPriceComps as HtvlUnitType[])?.find(
          token => {
            return token.date === historicDate;
          }
        ) || { val: assetNameForPriceComps[assetNameForPriceComps.length - 1].val };

        const exchangeRate = isArray(pricingData?.[baseAssetName])
          ? quoteVolume / baseVolume
          : baseVolume / quoteVolume;

        const secondPairAmount = quoteVolume / exchangeRate;
        const baseTokenId = currentPool?.base_asset_id === tokenId;
        const pairToken = baseTokenId ? quoteAssetName : baseAssetName;
        const pairValue = baseTokenId ? quoteVolume : baseVolume;
        const baseToken = baseTokenId ? baseAssetName : quoteAssetName;
        const baseTokenValue = baseTokenId ? baseVolume : quoteVolume;
        acc.obtainedData.push({
          pool_id: item.poolId,
          ticker: [baseAssetName, quoteAssetName],
          date: historicDate,
          exchangeRate,
          rateInUSD: usdValueOnHistoricDate / exchangeRate,
          baseToken: {
            ticker: baseToken,
            val: baseTokenValue
          },
          token: {
            ticker: pairToken,
            val: pairValue
          },
          val: (baseVolume + secondPairAmount) * usdValueOnHistoricDate
        });
        return acc;
      },
      { obtainedData: [] }
    );

    if (obtainedData) {
      const orderedAndPricingArray = sortArrayByDate(obtainedData);

      if (!grouped) {
        return orderedAndPricingArray;
      }

      const finalResult = groupArray(orderedAndPricingArray, 'ticker', 'assets');
      return finalResult;
    }
  }
};
