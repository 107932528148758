import { isNumber, isObject } from 'lodash';
import { ForwardedRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from '../../Spinner';
import { Pagination } from '../../Pagination';
import { ExpandToggle } from './ExpandToggle';
import { GridRow } from './GridRow';
import { DataGridColumn } from '../types';
import { genericMemo } from '../../../utils/genericMemo';
import cx from 'classnames';
import { genericForwardRef } from '../../../utils/genericForwardRef';
import { DataGridProps } from '../DataGrid';
import { GridHeader } from './GridHeader';

export type DesktopGridProps<R = object> = DataGridProps<R>;

const Table = styled.table<{ columnWidths: string[] }>`
  display: grid;
  grid-template-columns: ${({ columnWidths }) => columnWidths.join(' ')};
  text-align: left;
`;

const Body = styled.tbody`
  display: contents;
`;

const InfoRow = styled.tr`
  display: contents;
`;

const Footer = styled.tfoot`
  display: contents;
`;

const StyledPagination = styled.td`
  padding: 0;
  grid-column: 1 / -1;
`;

const LoadingMessage = styled.td`
  padding: ${({ theme }) => theme.spacing(4)};
  grid-column: 1 / -1;
  text-align: center;
`;

const EmptyDataMessage = styled.td`
  padding: ${({ theme }) => theme.spacing(4)};
  grid-column: 1 / -1;
  text-align: center;
`;

const expanderColumn: DataGridColumn & { expandToggleColumn: boolean } = {
  title: '',
  name: '',
  expandToggleColumn: true,
  render: ({ toggleExpandable, expandable, expanded }) =>
    expandable ? (
      <ExpandToggle open={expanded ?? false} onClick={() => toggleExpandable?.()} />
    ) : null,
};

export const DesktopGrid = genericMemo(
  genericForwardRef(function <R = object>(
    props: DesktopGridProps<R>,
    ref: ForwardedRef<HTMLTableElement>
  ) {
    const {
      columns,
      data,
      expandable,
      classNames,
      pageSize,
      loading = false,
      loadingMessage,
      noDataMessage,
      headerProps,
      titleProps,
      renderPaginationItems,
      title,
      hideColumnsTitle,
      onRowClick,
      ...others
    } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const totalPage = pageSize && data?.length ? Math.ceil(data.length / pageSize) : 0;
    const pageData = useMemo(
      () => (pageSize ? data?.slice(pageSize * (currentPage - 1), pageSize * currentPage) : data),
      [currentPage, data, pageSize]
    );

    const visibleColumns = useMemo(() => columns.filter(col => !col.hidden), [columns]);

    const hasExpandableRow =
      expandable?.rowExpandable && data.some(row => expandable.rowExpandable(row));

    const finalColumns = useMemo(
      () => (hasExpandableRow ? [expanderColumn, ...visibleColumns] : visibleColumns),
      [visibleColumns, hasExpandableRow]
    ) as (DataGridColumn<R> & { expandToggleColumn?: boolean })[];

    const columnWidths = finalColumns.map(({ width = 'auto' }) => {
      if (!isObject(width)) {
        return isNumber(width) ? `${width}px` : width;
      }
      const { min = 'max-content', max = 'max-content' } = width;
      return `minmax(${isNumber(min) ? `${min}px` : min}, ${isNumber(max) ? `${max}px` : max})`;
    });

    return (
      <Table ref={ref} {...others} columnWidths={columnWidths}>
        <GridHeader<R>
          classNames={classNames}
          columns={finalColumns}
          title={title}
          hideColumnsTitle={hideColumnsTitle}
          headerRowProps={headerProps}
          titleProps={titleProps}
        />
        <Body className={cx(classNames?.body)}>
          {!loading &&
            pageData.map((row, index) => (
              <GridRow<R>
                key={index}
                classNames={classNames}
                columns={finalColumns}
                data={row}
                expandable={expandable?.rowExpandable?.(row)}
                renderExpandable={expandable?.renderExpandable}
                onClick={onRowClick}
              />
            ))}
          <InfoRow>
            {loading && (
              <LoadingMessage className={cx(classNames?.loadingMessage)}>
                {loadingMessage || <Spinner />}
              </LoadingMessage>
            )}
            {!loading && data.length === 0 && (
              <EmptyDataMessage className={cx(classNames?.noDataMessage)}>
                {noDataMessage || 'no data presented'}
              </EmptyDataMessage>
            )}
          </InfoRow>
        </Body>
        <Footer>
          <InfoRow>
            {totalPage > 1 && !loading && (
              <StyledPagination className={cx(classNames?.pagination)}>
                <Pagination
                  total={totalPage}
                  page={currentPage}
                  onChange={p => setCurrentPage(p)}
                  renderPaginationItems={renderPaginationItems}
                />
              </StyledPagination>
            )}
          </InfoRow>
        </Footer>
      </Table>
    );
  })
);
