import { FC } from 'react';
import styled from 'styled-components';

import Element, { SkeletonElementProps } from './Element';

interface SkeletonButtonProps extends SkeletonElementProps {
  fullWidth?: boolean;
}

const ButtonElement = styled(Element)<Pick<SkeletonButtonProps, 'fullWidth'>>`
  min-width: 64px;
  width: ${({ fullWidth, width }) => (fullWidth ? '100%' : width)};
`;

const Button: FC<SkeletonButtonProps> = props => {
  return <ButtonElement height="32px" {...props} />;
};

export type { SkeletonButtonProps };
export default Button;
