import { FC } from 'react';
import { Inline, Stack, H3, SmallText, Text, Button, Icon } from '@parallel-mono/components';
import styled from 'styled-components';

import penImg from './images/pen.svg';
import lockImg from './images/lock.svg';
import boxImg from './images/box.svg';
import bg from './images/coming-soon-detail-bg.svg';

import { Link, Image } from '@/components';
import { BaseWallet } from '@/contexts/WalletsContext/types';

const Wrapper = styled.div`
  background-image: url(${bg});
  background-size: cover;
`;
const CenterText = styled(SmallText)`
  text-align: center;
`;
const IconStack = styled(Stack).attrs({
  alignItems: 'center'
})`
  flex: 1;
`;
const IconWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center'
})`
  width: 3rem;
  height: 3rem;
  padding: 0.75rem;
  border-radius: 50%;
  box-shadow: var(--box-shadow01);
`;

type Props = {
  closeModal: () => void;
  data: BaseWallet;
};

const ComingSoon: FC<Props> = ({ closeModal, data }) => {
  return (
    <Wrapper>
      <Stack inset="2rem">
        <Inline justifyContent="space-between">
          <H3>Coming soon</H3>
          <Button variant="link" skin="neutral" onClick={() => closeModal()}>
            <Icon name="close" />
          </Button>
        </Inline>
        <Stack alignItems="center" gap="0.5rem">
          {data.icon}
          <H3>{data.walletName}</H3>
          <Text style={{ textAlign: 'center' }}>{data.description}</Text>
        </Stack>
        <Inline inset="1rem 0 0" gap="0">
          <IconStack>
            <IconWrapper>
              <Image width="1.5rem" src={penImg} />
            </IconWrapper>
            <CenterText skin="secondary">
              Simple and easy to use with a great user experience
            </CenterText>
          </IconStack>
          <IconStack>
            <IconWrapper>
              <Image width="1.5rem" src={lockImg} />
            </IconWrapper>
            <CenterText skin="secondary">Your private key secure and safely guarded </CenterText>
          </IconStack>
          <IconStack>
            <IconWrapper>
              <Image width="1.5rem" src={boxImg} />
            </IconWrapper>
            <CenterText skin="secondary">For Ethereum, Polkadot, Solana & more</CenterText>
          </IconStack>
        </Inline>
        <Inline justifyContent="center">
          <Link
            style={{ marginTop: '1.5rem' }}
            href="https://twitter.com/ParallelFi"
            size="medium"
            variant="button-secondary"
          >
            <Button size="small" skin="secondary">
              Join Community
            </Button>
          </Link>
        </Inline>
      </Stack>
    </Wrapper>
  );
};

export default ComingSoon;
