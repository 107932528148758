import { H1, Image, Inline, Modal, Stack, Text, useBreakpoints } from '@parallel-mono/components';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';

import wechat from './assets/wechat.png';
import twitter from './assets/twitter.svg';
import discord from './assets/discord.svg';
import tool from './assets/tool.svg';

import { Link } from '@/components';

const Wrapper = styled(Stack)`
  height: 100vh;
  ${({ theme }) => theme.breakpoints.only('mobile')`
    padding: 0 1rem;
  `}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.skin.text.primary};
`;
const CenterText = styled(Text)`
  text-align: center;
`;

const Circle = styled(Inline).attrs({
  justifyContent: 'center',
  alignItems: 'center'
})`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.skin.grey[500]};
  cursor: pointer;
`;

export const Maintenance = memo(() => {
  const [isWeChatOpen, setIsWeChatOpen] = useState(false);

  const helpingList = useMemo(
    () => [
      { icon: twitter, name: 'X', link: 'https://twitter.com/ParaX_ai' },
      { icon: discord, name: 'Discord', link: 'https://discord.com/invite/parax' },
      {
        icon: wechat,
        name: 'WeChat',
        onClick: () => {
          setIsWeChatOpen(true);
        }
      }
    ],
    []
  );

  const { mobile } = useBreakpoints();

  return (
    <Wrapper justifyContent="center" alignItems="center">
      <Stack alignItems="center" gap="2.5rem" width={mobile ? 'auto' : '45rem'}>
        <Image name="tool" width="7.5rem" src={tool} />
        <Stack gap="0.5rem">
          <H1 style={{ textAlign: 'center' }}>Parallel Finance is under maintenance</H1>
          <Text style={{ textAlign: 'center' }}>
            Parallel Finance is undergoing slot replacement, and the replacement is expected to be
            completed within 24 hours. Your assets are still very safe, please stay tuned to our
            Twitter and Discord for the latest news. Thank you for your understanding and support.
          </Text>
        </Stack>

        <Inline width="100%" justifyContent="center" gap="3.75rem">
          {helpingList.map(({ name, onClick, icon, link }) => {
            const item = (
              <Stack
                onClick={onClick ?? noop}
                key={name}
                alignItems="center"
                justifyContent="center"
                gap="1rem"
              >
                <Circle>
                  <Image name={name} width="1.5rem" height="1.5rem" src={icon} />
                </Circle>
                {!mobile && <CenterText fontWeight="medium">{name}</CenterText>}
              </Stack>
            );
            return link ? (
              <StyledLink key={name} href={link} target="_blank">
                {item}
              </StyledLink>
            ) : (
              item
            );
          })}
        </Inline>
      </Stack>
      <Modal isOpen={isWeChatOpen} onClose={() => setIsWeChatOpen(false)}>
        <Image name="wechat" width="100%" height="100%" src={wechat} />
      </Modal>
    </Wrapper>
  );
});
