import { Button } from '@parallel-mono/components';

import useCalculatedValue from '../useCalculatedValue';
import { StreamListItem } from '../useFetchList';

import { IClaimRowData } from '.';

export const ClaimButton = ({
  rowData,
  onClick,
  claimingIds
}: {
  rowData: StreamListItem;
  onClick: (row: IClaimRowData) => void;
  claimingIds: string[];
}) => {
  const { id, assetId, status } = rowData;
  const { claimed, claimableNow, claimableTotal, depositNumber } = useCalculatedValue(rowData);

  const onButtonClick = () => {
    onClick({
      isShowClaimModal: !claimingIds.includes(id),
      claimed,
      claimableNow,
      lockedBalance: depositNumber.minus(claimableTotal),
      assetId,
      streamId: id
    });
  };

  return (
    <Button
      skin="secondary"
      onClick={onButtonClick}
      disabled={!!status.Completed || claimableNow.lte(0) || claimingIds.includes(id)}
    >
      Claim
    </Button>
  );
};
