import { useEffect, useState } from 'react';
import { u32, Vec } from '@polkadot/types';
import { AccountInfo } from '@polkadot/types/interfaces';
import type { OrmlVestingSchedule } from '@open-web3/orml-types/interfaces';
import { BN, BN_ZERO } from '@polkadot/util';

import { useAccount } from './useAccount';
import useApiCall from './useApiCall';
import { useChainConnections } from './useChainConnection';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import config from '@/config';

export interface ClaimData {
  totalToken: number;
  totalVested: number;
  totalClaimed: number;
  availableToClaim: number;
}

const unitValue = (value: BN) =>
  balanceToAmountByDecimal<number>(value, config.nativeToken, 'number');

const useVestingData = () => {
  const { account } = useAccount();
  const [claimData, setClaimData] = useState({
    totalToken: 0,
    totalVested: 0,
    totalClaimed: 0,
    availableToClaim: 0
  });

  const {
    parachain: { api: paraApi }
  } = useChainConnections();

  const vestingSchedule = useApiCall<Vec<OrmlVestingSchedule>>(
    paraApi.query.vesting.vestingSchedules,
    [account?.address]
  );

  const accountInfo = useApiCall<AccountInfo>(paraApi.query.system.account, [account?.address]);
  const currBlockNumber = useApiCall<u32>(paraApi.query.system.number, []);

  useEffect(() => {
    if (vestingSchedule && accountInfo && currBlockNumber) {
      const vestingScheduleArr = vestingSchedule.toArray();

      const totalToken = vestingScheduleArr.reduce(
        (acc, curr) => acc.add(curr.periodCount.mul(curr.perPeriod.toBn())),
        BN_ZERO
      );

      const totalVested = vestingScheduleArr.reduce((acc, curr) => {
        const period = curr.start.add(curr.periodCount.mul(curr.period)).gte(currBlockNumber)
          ? currBlockNumber.sub(curr.start).div(curr.period)
          : curr.periodCount;
        return acc.add(period.mul(curr.perPeriod.toBn()));
      }, BN_ZERO);

      const totalClaimed = totalToken.sub(
        BN.min(accountInfo.data.miscFrozen, accountInfo.data.feeFrozen)
      );

      const availableToClaim = totalVested.sub(totalClaimed);

      setClaimData({
        totalToken: unitValue(totalToken),
        totalVested: unitValue(totalVested),
        totalClaimed: unitValue(totalClaimed),
        availableToClaim: unitValue(availableToClaim)
      });
    }
  }, [currBlockNumber, accountInfo, vestingSchedule]);

  return claimData;
};

export default useVestingData;
