import {
  CSSProperties,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
  useState,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { Collapse } from '../../Collapse';
import {
  DataGridClassNames,
  DataGridColumn,
  ExpandableRenderContext,
  RowClickEventContext,
} from '../types';
import cx from 'classnames/bind';
import { genericMemo } from '../../../utils/genericMemo';
import { isFunction } from 'lodash';
import { DataAttributes } from '../../types/DataAttributes';

export type GridRowProps<R = object> = Omit<HTMLAttributes<HTMLTableRowElement>, 'onClick'> & {
  data: R;
  columns: (DataGridColumn<R> & { expandToggleColumn?: boolean })[];
  expandable?: boolean;
  renderExpandable?: (context: ExpandableRenderContext<R>) => ReactNode;
  classNames?: DataGridClassNames;
  onClick?: (row: RowClickEventContext<R>, event: MouseEvent) => void;
};

const Row = styled.tr`
  display: contents;
`;

export type CellProps = Omit<HTMLAttributes<HTMLTableCellElement>, 'children'> & DataAttributes;

export const Cell = styled.td<{
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  expandToggleCell: boolean;
}>`
  padding: ${({ theme }) => theme.spacing(4)};
  color: ${({ theme }) => theme.skin.text.primary};
  display: flex;
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  word-break: break-word;
  ${({ expandToggleCell }) =>
    expandToggleCell
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          text-align: center;
          width: 3em;
        `
      : ''}
`;

const Expand = styled.td`
  padding: 0;
  grid-column: 1 / -1;
`;

export const GridRow = genericMemo(function <R = object>(props: GridRowProps<R>) {
  const {
    data,
    columns,
    expandable = false,
    renderExpandable,
    classNames,
    className = '',
    onClick,
    ...others
  } = props;
  const [expanded, setExpanded] = useState(false);
  const toggleExpandable = useCallback((expand?: boolean) => {
    setExpanded(curr => expand ?? !curr);
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(
        { data, toggleExpandable: expandable ? toggleExpandable : null, expanded, expandable },
        event
      );
    },
    [data, onClick, toggleExpandable, expanded, expandable]
  );

  const bindedCx = useMemo(() => cx.bind(classNames), [classNames]);
  return (
    <Row
      className={bindedCx(className, classNames?.row, {
        expandableRow: expandable,
        expandedRow: expandable && expanded,
      })}
      onClick={handleClick}
      {...others}
    >
      {columns.map((column, index) => {
        const { cellProps = {}, className } = column;
        const normalizedCellProps: CellProps = isFunction(cellProps)
          ? cellProps('body', data)
          : cellProps;
        return (
          <Cell
            {...normalizedCellProps}
            key={index}
            alignItems={column.alignItems}
            justifyContent={column.justifyContent}
            expandToggleCell={column.expandToggleColumn ?? false}
            className={bindedCx(
              className,
              classNames?.cell,
              column.expandToggleColumn ? classNames?.expandToggleCell : null,
              normalizedCellProps?.className
            )}
          >
            {column.render
              ? column.render({ data, column, toggleExpandable, expanded, expandable })
              : data[column.name]}
          </Cell>
        );
      })}
      {expandable && (
        <Expand>
          <Collapse
            open={expanded}
            className={bindedCx(classNames?.expandableContainer)}
            duration="0.3s"
          >
            {renderExpandable?.({ data, toggleExpandable, expanded, expandable })}
          </Collapse>
        </Expand>
      )}
    </Row>
  );
});
