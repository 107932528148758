import { FC, memo, useMemo } from 'react';

import NetWorth from '../NetWorth';

import { StyledDataGrid, StyledDataGridColumn } from '@/components';
import { useDevice } from '@/hooks';

const Index: FC = () => {
  const { isMobile } = useDevice();
  const columns = useMemo<StyledDataGridColumn[]>(
    () => [
      {
        name: 'name',
        title: 'Asset',
        width: '1fr',
        isAvatar: true
      },
      {
        name: 'balance',
        title: 'Balance',
        width: '1fr'
      },
      {
        name: 'value',
        title: 'Value',
        hidden: isMobile,
        width: '1fr'
      },
      {
        name: 'action',
        title: 'Action',
        hidden: isMobile,
        width: '1fr'
      }
    ],
    [isMobile]
  );

  const rows = [{}];

  return (
    <>
      <NetWorth title="Relaychain Balance" asset={0} debt={0} />
      <StyledDataGrid columns={columns} data={rows} loading />
    </>
  );
};

export default memo(Index);
