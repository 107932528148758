import { FC } from 'react';
import { Icon, Inline, SmallText } from '@parallel-mono/components';

import { parseTokenSymbol } from '../utils';

import config from '@/config';
import { Link } from '@/components';

const getAssetAnalyticsUrl = (token: string) => {
  const symbolInfo = parseTokenSymbol(token);
  const slices = [];

  if (symbolInfo.prefix) {
    slices.push(symbolInfo.prefix);
  }

  if (symbolInfo.primaryToken) {
    slices.push(symbolInfo.primaryToken);
  }

  if (symbolInfo.secondaryToken) {
    slices.push(symbolInfo.secondaryToken);
  }

  if (symbolInfo.leases) {
    slices[slices.length - 1] = `${slices[slices.length - 1]}${symbolInfo.leases.replace('/', '')}`;
  }

  return `${config.ANALYSIS_SERVICE_URL}/moneymarket/${slices.join('-')}`;
};

interface ViewAssetAnalyticsProps {
  token: string;
}

const ViewAssetAnalytics: FC<ViewAssetAnalyticsProps> = ({ token }) => {
  return (
    <Link href={getAssetAnalyticsUrl(token)} target="_blank">
      <Inline alignItems="center" gap="0.3rem">
        <SmallText skin="secondary">Asset analytics</SmallText>
        <Icon name="externalLink" size="small" />
      </Inline>
    </Link>
  );
};

export default ViewAssetAnalytics;
