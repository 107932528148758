import { memo, useMemo } from 'react';
import {
  Card,
  Stack,
  Responsive,
  Inline,
  Tooltip,
  Text,
  H3,
  H5,
  Toggle,
  DecoratedInput,
  Icon
} from '@parallel-mono/components';
import { Pill } from '@parallel-mono/business-components';
import styled, { useTheme } from 'styled-components';
import { noop } from 'lodash';

import { TOOLTIP } from '../constants';

import { Summary } from './Summary';

import { useDevice } from '@/hooks';
import { StyledDataGrid, StyledDataGridColumn } from '@/components';

const PillStyled = styled(Pill)<{ active: boolean }>`
  border: none;
  background-color: ${({ theme }) => theme.skin.primary.contrastBackground};
  ${({ active }) => (active ? null : { backgroundColor: 'transparent', boxShadow: 'none' })};
`;

const LoadingStatus = () => {
  const { isMobile } = useDevice();
  const theme = useTheme();

  const lendTableColumns = useMemo<StyledDataGridColumn[]>(
    () => [
      {
        name: 'name',
        title: 'Assets',
        width: '1.4fr',
        isAvatar: true
      },
      {
        name: 'balance',
        title: (
          <Stack gap="0">
            <Text>Wallet</Text>
            <Text>Balance</Text>
          </Stack>
        ),
        hidden: isMobile,
        width: '1fr'
      },
      {
        name: 'supplyRate',
        title: (
          <Stack gap="0">
            <Text>Supply</Text>
            <Text>APY</Text>
          </Stack>
        ),
        width: '1fr'
      },
      {
        name: 'tvl',
        title: 'TVL',
        width: '1fr'
      },
      {
        name: 'availableAmount',
        title: (
          <Stack gap="0">
            <Text>Available</Text>
            <Inline gap="0.3rem" alignItems="center">
              <Text>Liquidity</Text>
              <Tooltip content={TOOLTIP.AVAILABLE_BALANCE} />
            </Inline>
          </Stack>
        ),
        width: '1fr',
        hidden: isMobile
      },
      {
        name: 'borrowRate',
        title: (
          <Stack gap="0">
            <Text>Borrow</Text>
            <Text>APY</Text>
          </Stack>
        ),
        width: '1fr'
      },
      {
        name: 'action',
        width: '2fr',
        title: '',
        justifyContent: 'flex-start',
        render: () => <Inline gap="0.5rem" />
      }
    ],
    [isMobile]
  );

  return (
    <Stack>
      <Summary />
      <Card>
        <Stack gap="0.8rem">
          <Responsive justifyContent="space-between">
            <Responsive alignItems="center">
              <H3>Lend and Borrow</H3>
              <Responsive gap="1rem">
                <PillStyled label="All Assets" active skin="neutral" onToggle={noop} />
                <PillStyled
                  label="Liquidation Free"
                  active={false}
                  skin="neutral"
                  onToggle={noop}
                />
              </Responsive>
            </Responsive>
            <Responsive>
              <Inline alignItems="center" gap="1rem">
                <H5 fontWeight="medium">Hide 0 wallet balance</H5>
                <Toggle />
              </Inline>
              <DecoratedInput
                Component="input"
                startAdornment={<Icon name="search" color={theme.skin.grey[300]} />}
                inputProps={{
                  placeholder: 'Search token'
                }}
              />
            </Responsive>
          </Responsive>
          <StyledDataGrid loading columns={lendTableColumns} data={[]} />
        </Stack>
      </Card>
    </Stack>
  );
};

export default memo(LoadingStatus);
