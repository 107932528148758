import { memo, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Inline, Spinner, Stack, Text, Icon } from '@parallel-mono/components';

interface ToastBodyProps {
  type: 'processing' | 'success' | 'error' | 'default';
  text?: string;
  renderer?: () => ReactNode;
}

const MessageText = styled(Text)<{ status: ToastBodyProps['type'] }>`
  color: ${({ skin }) => (skin === 'error' ? 'var(--clr-black)' : 'var(--clr-text01)')};
`;

const ToastBody: FC<ToastBodyProps> = ({ type = 'default', text, renderer }) => (
  <Stack gap="0" alignItems="flex-start">
    <Inline alignItems="center" gap="1rem">
      {type === 'processing' && <Spinner />}
      {type === 'error' && <Icon name="warning" color="var(--clr-white)" />}
      {type === 'success' && <Icon name="containedCheck" color="var(--clr-green)" />}
      {text && <MessageText status={type}>{text}</MessageText>}
    </Inline>
    {renderer && renderer()}
  </Stack>
);

export default memo(ToastBody);
