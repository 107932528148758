import { useContext, FC, useMemo } from 'react';
import { Stack, H3, Text } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { StakingScopeContext } from './Context';

import { Collapse, Link } from '@/components';
import { useChainConnections } from '@/hooks';
import { getEraLength, getBondingDuration } from '@/pages/Staking/apis/staking';
import { formatMillisecondsToLeftTime } from '@/utils/format';

const FAQ: FC = () => {
  const { nominationLength, currencies, chainName, stakingAPY } = useContext(StakingScopeContext);
  const {
    parachain: { api }
  } = useChainConnections();

  const symbol = currencies.stakingAsset?.symbol;
  const liquidSymbol = currencies.liquidAsset?.symbol;

  const bondingDuration = getBondingDuration(api);
  const eraLength = getEraLength(api);
  const leftTime = formatMillisecondsToLeftTime(
    eraLength.mul(bondingDuration.addn(1)).muln(6000).toNumber()
  );

  const leftEraTime = formatMillisecondsToLeftTime(eraLength.muln(6000).toNumber());
  const formattedAPY = useMemo(() => formatNumber(stakingAPY, { output: 'percent' }), [stakingAPY]);
  return (
    <Stack gap="12px">
      <H3 className="title">FAQ</H3>
      <Collapse header={`What is liquid staking on ${chainName}?`}>
        <Text skin="secondary">
          Parallel offers a liquid staking product that allows users to earn staking rewards without
          locking assets or maintaining staking infrastructure. Users can deposit {symbol} and
          receive liquid tokens ({liquidSymbol}) in return. Learn more on how we elect the best
          validators to minimize staking risk&nbsp;
          <Link href="https://docs.parallel.fi/dev/staking/liquid-staking" target="_blank">
            here
          </Link>
          . You can also see the list of current validators by clicking the{' '}
          <em>{nominationLength} Validators</em>
          &nbsp;section above.
        </Text>
      </Collapse>
      <Collapse header="How are staking rewards distributed?">
        <Text skin="secondary">
          {liquidSymbol} represents both your deposit and the rewards you earn on your {symbol} each
          era (every {leftEraTime}). There is no need to claim your rewards. Instead, {liquidSymbol}
          &nbsp; acts as a share of the total pool of staked {symbol} in Parallel. Since this pool
          accumulates staking rewards, your share (represented by your {liquidSymbol}) enables you
          to withdraw more {symbol} each era (~{leftEraTime}) at an APY of approximately&nbsp;
          {formattedAPY}.
        </Text>
      </Collapse>

      <Collapse header={`How can I use ${liquidSymbol}?`}>
        <Text skin="secondary">
          {liquidSymbol} holders can participate in different defi activities and enjoy different
          yields according to risk preferences.
          <br />
          <br />
          Risk-averse holders can hold {liquidSymbol} after staking, and get around {formattedAPY}{' '}
          risk-free APY. Risk-taking holders can get more than {formattedAPY} APY by farming or
          leverage staking. Users who receive LP token after adding {symbol} and {liquidSymbol} to
          AMM can enjoy a higher farming apy after depositing LP token on the Money Market page.
          &nbsp;{liquidSymbol} holders can also choose to lend {liquidSymbol} on Money Market to
          leverage up {symbol} position.
          <br />
          <br />
          Also keep in mind that {liquidSymbol} can be traded at its current value for some other
          cryptocurrency on AMM without the need to unstake first.
        </Text>
      </Collapse>

      <Collapse header={`How do I unstake my ${liquidSymbol}?`}>
        <Text skin="secondary">
          Withdrawals of {liquidSymbol} can be done through the <em>Unstake</em> tab, which takes
          roughly {leftTime}. Immediate withdrawal options will be available in the open market
          through liquidity pools on AMM protocols where one will be able to immediately exchange
          &nbsp;{liquidSymbol} for {symbol}.
        </Text>
      </Collapse>

      <Collapse header={`What are the risks of liquid staking on ${chainName}?`}>
        <Text skin="secondary">
          Users that utilize liquid staking are subject to slash risk. This could result in users
          losing a portion of their staked tokens if their validator gets slashed. But if this
          happens, we will pay for the loss.
        </Text>
      </Collapse>
      <Collapse header="What is instant unstake?">
        <Text skin="secondary">
          There is typically a waiting period when an unstake is initiated. Instant unstake allows
          you to convert your {liquidSymbol} back to {symbol} instantly without the mandatory
          waiting period, by utilising Money Market to borrow {symbol}. There is however a fee
          involved in order to pay back the interests accrued on Money Market to receive the{' '}
          {symbol} instantly.
        </Text>
      </Collapse>
    </Stack>
  );
};

export default FAQ;
