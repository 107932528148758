import { AssetBalance, AssetId, AssetMetadata } from '@polkadot/types/interfaces';
import { Option, StorageKey, Vec } from '@polkadot/types';

import { CommonChainMetadata } from '../types';

export enum StatemintChainCurrency {
  USDT = 'USDT'
}

export interface ChainMetadata extends CommonChainMetadata {
  assetsMetadata: [StorageKey<[AssetId]>, AssetMetadata][];
  assetsBalance: Vec<Option<AssetBalance>>;
}
