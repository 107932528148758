import { ApiPromise } from '@polkadot/api';
import { BN } from '@polkadot/util';
import { KeyringPair } from '@polkadot/keyring/types';

import config from '@/config';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';
import { AssetDetailInfo } from '@/hooks/types';

export const createDepositDestXcm = (parachain: number, xcmVersion: 'V2' | 'V3') => ({
  [xcmVersion]: {
    parents: 1,
    interior: {
      X1: {
        Parachain: parachain
      }
    }
  }
});

export const createDepositAssetsXcm = (
  assetId: number,
  amount: number,
  decimals: BN,
  xcmVersion: 'V2' | 'V3'
) => ({
  [xcmVersion]: [
    {
      id: {
        Concrete: {
          parents: 0,
          interior: {
            X2: [
              {
                PalletInstance: 50
              },
              {
                GeneralIndex: assetId
              }
            ]
          }
        }
      },
      fun: {
        Fungible: amountToBalanceByDecimals<BN>(amount, decimals, 'bn')
      }
    }
  ]
});

export const createDepositBeneficiaryXcm = (address: string, xcmVersion: 'V2' | 'V3') => ({
  [xcmVersion]: {
    parents: 0,
    interior: {
      X1: {
        AccountId32: {
          network: null,
          id: addressToHex(address)
        }
      }
    }
  }
});

export const generateCommonTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number,
  xcmVersion: 'V2' | 'V3'
) => {
  const destXcmVersionedMultiLocation = createDepositDestXcm(config.paraId, xcmVersion);
  const beneficiaryXcmVersionedMultiLocation = createDepositBeneficiaryXcm(
    account.address,
    xcmVersion
  );
  const xcmVersionedMultiAssets = createDepositAssetsXcm(
    Number(asset.assetId),
    amount,
    asset.decimals,
    xcmVersion
  );

  return api.tx.polkadotXcm.limitedReserveTransferAssets(
    // @ts-ignore the kusama and polkadot use the different xcmVersion, but the @parallel-finance/type can only adopt one of them
    destXcmVersionedMultiLocation,
    beneficiaryXcmVersionedMultiLocation,
    xcmVersionedMultiAssets,
    0,
    { Unlimited: '' }
  );
};

export const generateTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  return config.isParallel
    ? generateCommonTxs(api, account, asset, amount, 'V2')
    : generateCommonTxs(api, account, asset, amount, 'V3');
};
