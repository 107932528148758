import { Inline, SmallText, Toggle } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';
import { FC } from 'react';
import styled from 'styled-components';

import { ReactComponent as Heart } from './images/heart.svg';

const ToggleWrapper = styled.div`
  flex: 0 0 2.5rem;
`;

type ToggleProps = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  supplyStakingAPY: number;
  totalAPY: number;
  nativeToken: string;
  relayToken?: string;
};

const LendToMoneyMarketToggle: FC<ToggleProps> = ({
  onChange,
  checked,
  supplyStakingAPY,
  totalAPY,
  nativeToken,
  relayToken
}) => {
  return (
    <Inline gap="0.875rem">
      <Heart width="1.5rem" />
      <SmallText skin="secondary">
        Supply staked {relayToken} to Money Market to earn{' '}
        {formatNumber(supplyStakingAPY, { output: 'percent' })} APY in {nativeToken} to achieve{' '}
        {formatNumber(totalAPY, { output: 'percent' })} APY.
      </SmallText>
      <ToggleWrapper>
        <Toggle checked={checked} onChange={onChange} />
      </ToggleWrapper>
    </Inline>
  );
};

export default LendToMoneyMarketToggle;
