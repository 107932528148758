import { genericMemo } from '../../../utils/genericMemo';
import { Stack, StackProps } from '../../Layout';
import { DataGridProps } from '../DataGrid';
import { DataCard } from './DataCard';
import cx from 'classnames';
import { Pagination } from '../../Pagination';
import { useMemo, useState } from 'react';
import { Spinner } from '../../Spinner';

type MobileGridProps<R = object> = Omit<
  DataGridProps<R>,
  'headerProps' | 'titleProps' | 'title' | 'hideColumnsTitle'
> &
  Omit<StackProps, 'children'>;

export const MobileGrid = genericMemo(function <R = object>(props: MobileGridProps<R>) {
  const {
    columns,
    data,
    expandable: { rowExpandable, renderExpandable } = {},
    classNames,
    onRowClick,
    loading,
    loadingMessage,
    noDataMessage,
    pageSize,
    renderPaginationItems,
    ...others
  } = props;

  const totalPage = pageSize && data?.length ? Math.ceil(data.length / pageSize) : 0;

  const [currentPage, setCurrentPage] = useState(1);

  const pageData = useMemo(
    () => (pageSize ? data?.slice(pageSize * (currentPage - 1), pageSize * currentPage) : data),
    [currentPage, data, pageSize]
  );

  if (loading) {
    return (
      <Stack alignItems="center" className={classNames?.loadingMessage} {...others}>
        {loadingMessage ?? <Spinner size="medium" />}
      </Stack>
    );
  }

  if (data.length < 1) {
    return (
      <Stack alignItems="center" className={classNames?.noDataMessage} {...others}>
        {noDataMessage ?? 'No data presented'}
      </Stack>
    );
  }

  return (
    <Stack {...others}>
      {pageData.map(rowData => {
        const expandable = rowExpandable?.(rowData) ?? false;
        return (
          <DataCard
            className={classNames?.dataCard}
            data={rowData}
            columns={columns}
            expandable={expandable}
            onClick={onRowClick}
            renderExpandable={renderExpandable}
            classNames={classNames}
          />
        );
      })}
      {totalPage > 1 && !loading && (
        <Pagination
          className={cx(classNames?.pagination)}
          total={totalPage}
          page={currentPage}
          onChange={p => setCurrentPage(p)}
          renderPaginationItems={renderPaginationItems}
        />
      )}
    </Stack>
  );
});
