import { useContext, createContext, FC, useMemo, useState, useCallback } from 'react';

import { ApiSocket, ApiSocketReady } from '@/utils/apiSocket/initApiSocket';

export enum Chains {
  RelayChain = 'relaychain',
  ParaChain = 'parachain',
  AcalaChain = 'acalaChain',
  PhalaChain = 'phalaChain',
  StatemineChain = 'statemineChain',
  InterlayChain = 'interlayChain'
}
type Connection = ApiSocket | ApiSocketReady;

export type ChainMap = Record<Chains, Connection>;

interface ContextValue {
  relaychain?: ApiSocketReady;
  parachain?: ApiSocketReady;
  acalaChain?: ApiSocketReady;
  phalaChain?: ApiSocketReady;
  statemineChain?: ApiSocketReady;
  interlayChain?: ApiSocketReady;
  updateChains: (object: ChainMap) => void;
}

const ConnectChainContext = createContext<ContextValue>(undefined!);

const ChainConnectionProvider: FC = ({ children }) => {
  const [chains, setChains] = useState<ChainMap>({} as ChainMap);

  const updateChains = useCallback((chain: Record<Chains, Connection>) => {
    setChains(prevState => ({ ...prevState, ...chain }));
  }, []);

  const chainsValue = useMemo(
    () => ({
      relaychain: chains.relaychain,
      parachain: chains.parachain,
      acalaChain: chains.acalaChain,
      phalaChain: chains.phalaChain,
      statemineChain: chains.statemineChain,
      interlayChain: chains.interlayChain,
      updateChains
    }),
    [chains, updateChains]
  );
  return (
    <ConnectChainContext.Provider value={chainsValue as ContextValue}>
      {children}
    </ConnectChainContext.Provider>
  );
};

const useChainConnections = () => {
  return useContext(ConnectChainContext) as Required<ContextValue>;
};

export { ChainConnectionProvider, useChainConnections };
