import { ApiPromise } from '@polkadot/api';
import { Text } from '@polkadot/types';
import { BN_ZERO } from '@polkadot/util/bn/consts';
import BigNumber from 'bignumber.js';

import { ACALA_NATIVE_TOKEN, CURRENCIES, MAXIMUM_TX_FEE } from '../constants';
import { AcalaChainCurrency, AcalaTokensInfo, AssetsBaseInfo, ChainMetadata } from '../types';

import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import { AssetDetailInfo } from '@/hooks/types';

export const calculateAssetsInfo = async (
  api: ApiPromise,
  metadata: ChainMetadata
): Promise<AssetDetailInfo[]> => {
  const { accountInfo: nativeAssetInfo, assetsMetadata, tokensInfo: otherAssetsInfo } = metadata;

  const acalaCurrencies = CURRENCIES.Parallel;

  const { existentialDeposit } = api.consts.balances;

  const chainName: Text = await api.rpc.system.chain();
  if (nativeAssetInfo && assetsMetadata && otherAssetsInfo) {
    const assetsBaseInfos = assetsMetadata
      .filter(metaData =>
        acalaCurrencies.find(item => item === (metaData[1].symbol.toHuman() as AcalaChainCurrency))
      )
      .reduce((result, item) => {
        const symbol = item[1].symbol.toHuman() as AcalaChainCurrency;
        const { decimals } = item[1];
        if (symbol) {
          result[symbol] = {
            assetId: item[0].args.toString(),
            decimals
          };
        }
        return result;
      }, {} as AssetsBaseInfo);

    const accountInfo = acalaCurrencies.reduce((result, token, index) => {
      result[token] = token === ACALA_NATIVE_TOKEN ? nativeAssetInfo.data : otherAssetsInfo[index];
      return result;
    }, {} as AcalaTokensInfo);

    const assetsInfo = acalaCurrencies.map(tokenName => {
      if (assetsBaseInfos[tokenName] && accountInfo[tokenName]) {
        const { decimals, assetId } = assetsBaseInfos[tokenName];
        const {
          free,
          miscFrozen = BN_ZERO,
          feeFrozen = BN_ZERO,
          frozen = BN_ZERO
        } = accountInfo[tokenName];
        const isNative = tokenName === ACALA_NATIVE_TOKEN;
        const balance = BigNumber(free.toString());
        const lockedBalance = isNative
          ? BigNumber.max(miscFrozen.toString(), feeFrozen.toString())
          : BigNumber(frozen.toString());
        const ED = isNative ? existentialDeposit : BN_ZERO;
        const availableBalance = BigNumber.max(
          0,
          balance.minus(lockedBalance).minus(ED.toString())
        );
        const maxTxFee = amountToBalanceByDecimals<BigNumber>(
          isNative ? MAXIMUM_TX_FEE : 0,
          decimals,
          'bigNumber'
        );
        const maxAvailableBalance = BigNumber.max(0, availableBalance.minus(maxTxFee));
        return {
          assetId,
          network: chainName,
          symbol: tokenName,
          decimals,
          balance: balanceToAmountByDecimal<BigNumber>(balance, decimals, 'bigNumber'),
          existentialDeposit: balanceToAmountByDecimal<BigNumber>(ED, decimals, 'bigNumber'),
          lockedBalance: balanceToAmountByDecimal<BigNumber>(lockedBalance, decimals, 'bigNumber'),
          availableBalance: balanceToAmountByDecimal<BigNumber>(
            availableBalance,
            decimals,
            'bigNumber'
          ),
          maxAvailableBalance: balanceToAmountByDecimal<BigNumber>(
            maxAvailableBalance,
            decimals,
            'bigNumber'
          ),
          isNative
        };
      }
      return null;
    });

    return assetsInfo.filter(asset => asset) as AssetDetailInfo[];
  }
  return [];
};
