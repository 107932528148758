import { ApiPromise } from '@polkadot/api';
import { u16, u32, u128, Vec } from '@polkadot/types';

const getStakingCurrencyId = (api: ApiPromise) => {
  return api.consts.liquidStaking.stakingCurrency as u32;
};

const getLiquidCurrencyId = (api: ApiPromise) => {
  return api.consts.liquidStaking.liquidCurrency as u32;
};

const getEraLength = (api: ApiPromise) => {
  return api.consts.liquidStaking.eraLength as u32;
};

const getBondingDuration = (api: ApiPromise) => {
  return api.consts.liquidStaking.bondingDuration as u32;
};

const getMinStake = (api: ApiPromise) => {
  return api.consts.liquidStaking.minStake as u128;
};

const getMinUnstake = (api: ApiPromise) => {
  return api.consts.liquidStaking.minUnstake as u128;
};

const getStakingXcmFee = (api: ApiPromise) => {
  return api.consts.liquidStaking.xcmFees as u128;
};

const getDerivativeList = (api: ApiPromise) => {
  return api.consts.liquidStaking.derivativeIndexList as Vec<u16>;
};
export {
  getStakingCurrencyId,
  getLiquidCurrencyId,
  getEraLength,
  getBondingDuration,
  getMinStake,
  getMinUnstake,
  getStakingXcmFee,
  getDerivativeList
};
