import { FC, useCallback, memo, ReactElement, isValidElement, useMemo, useContext } from 'react';
import {
  SelectableTokenInput,
  SelectableTokenValue,
  SelectableTokenInputProps
} from '@parallel-mono/business-components';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import { Alert, Skeleton, Stack, Text, Inline } from '@parallel-mono/components';
import { formatNumber } from '@parallel-mono/utils';

import { TransactionToken } from '../hooks/useTransactionTokens';
import { DataContext } from '../DataContext';

import { DepositToken } from './DepositToken';

import { AssetInfo } from '@/hooks/types';
import { useDevice } from '@/hooks';
import { toFloorFixed } from '@/utils/format';
import { isValidAmountInput } from '@/utils/validate';
import config from '@/config';

const SelectableTokenInputStyled: typeof SelectableTokenInput = styled(SelectableTokenInput)`
  width: 100% !important;
`;

interface TokenInputProps extends SelectableTokenInputProps<TransactionToken> {
  isReady: boolean;
  trading: boolean;
  checkBalance?: boolean;
  canMaximum?: boolean;
  isEmptyBalanceAlert?: boolean;
  isInsufficient?: boolean;
  maxAvailableBalance?: number;
  errorMessage?: string | ReactElement | null;
}

export const TokenInput: FC<TokenInputProps> = memo(
  ({
    isReady,
    trading,
    tokens,
    canMaximum = true,
    checkBalance = true,
    isEmptyBalanceAlert = false,
    isInsufficient,
    maxAvailableBalance,
    errorMessage,
    value,
    onChange,
    ...props
  }) => {
    const { isMobile } = useDevice();
    const skeletonButtonWidth = useMemo(() => (isMobile ? '15rem' : '20rem'), [isMobile]);

    const {
      assetInfoSource: { assetInfos, isBalanceReady }
    } = useContext(DataContext);

    const assetInfo = useMemo(
      () => assetInfos.find(asset => asset.assetId === value?.token?.assetId),
      [assetInfos, value?.token?.assetId]
    );

    const handleChange = useCallback(
      (newValue: SelectableTokenValue<TransactionToken>) => {
        if (!newValue?.amount || isValidAmountInput(`${newValue.amount}`, 10, 6)) {
          onChange?.(newValue);
        }
      },
      [onChange]
    );

    const canDepositToken = useMemo(() => {
      return value?.token?.symbol === config.relayChainToken;
    }, [value]);

    const finalErrorMessage = useMemo(() => {
      if (!isBalanceReady || trading) return null;
      if (errorMessage) {
        return isValidElement(errorMessage) ? (
          errorMessage
        ) : (
          <Text skin="error">{errorMessage}</Text>
        );
      }

      if (checkBalance && assetInfo && value?.amount) {
        if (canDepositToken && isBalanceReady && isInsufficient && value.token.balance! > 0) {
          return (
            <Inline gap="0.25rem">
              <Text skin="error" as="span">
                Insufficient balance on Parallel.
              </Text>
              {isNumber(assetInfo.balance) && (
                <DepositToken assetInfo={assetInfo as AssetInfo}>Deposit here.</DepositToken>
              )}
            </Inline>
          );
        }

        if (!canDepositToken && isInsufficient) {
          return <Text skin="error">Insufficient balance for this quantity</Text>;
        }
      }

      return null;
    }, [
      assetInfo,
      canDepositToken,
      checkBalance,
      errorMessage,
      isBalanceReady,
      isInsufficient,
      trading,
      value
    ]);

    const handleMaxAction = useCallback(() => {
      if (maxAvailableBalance) {
        handleChange?.({
          ...value!,
          amount: toFloorFixed(maxAvailableBalance, 6) ?? null
        });
      }
    }, [handleChange, value, maxAvailableBalance]);

    const maximumProps = useMemo(() => {
      return canMaximum && isBalanceReady
        ? {
            actionButtonText: 'Max',
            onActionButtonClicked: handleMaxAction
          }
        : {};
    }, [canMaximum, handleMaxAction, isBalanceReady]);

    if (!isReady || tokens.length === 0) {
      return (
        <Stack alignItems="center">
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
          <Skeleton.Button width={skeletonButtonWidth} height="1.5rem" variant="round" />
        </Stack>
      );
    }

    return (
      <Stack gap="0.75rem">
        <SelectableTokenInputStyled<TransactionToken>
          tokens={tokens}
          hasError={!!finalErrorMessage}
          value={value}
          onChange={handleChange}
          inputProps={{ disabled: trading }}
          priceFormatter={v => `~${formatNumber(v || 0, { decimal: 4, output: 'currency' })}`}
          {...maximumProps}
          {...props}
        />
        {finalErrorMessage}

        {isEmptyBalanceAlert &&
          canDepositToken &&
          isBalanceReady &&
          !trading &&
          assetInfo &&
          value?.token?.balance === 0 && (
            <Alert type="info">
              You have 0 {config.relayChainToken} on Parallel. You may want to{' '}
              {isNumber(assetInfo.balance) && (
                <DepositToken assetInfo={assetInfo as AssetInfo}>deposit here.</DepositToken>
              )}
            </Alert>
          )}
      </Stack>
    );
  }
);
