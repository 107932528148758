import { ReactElement, HTMLAttributes, memo, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Size } from '../ThemeProvider/types';

import { spinnerAnimation } from '../Animations/spinnerAnimation';
import { Icon } from '../icon';

export const sizeMap: Record<Size, string> = {
  small: '20',
  medium: '24',
  large: '60',
};

export interface SpinnerProps extends HTMLAttributes<HTMLSpanElement> {
  visible?: boolean;
  size?: Size;
  color?: string;
  /**
   * The time required for the animation to complete a cycle, in seconds
   */
  duration?: number;
  /**
   * Customize spinner icon
   */
  indicator?: ReactElement;
}

const SpinnerWrapper = styled.span<{ size: Size; duration: number }>`
  display: inline-block;
  animation: ${({ duration }) => `${duration}s`} linear infinite ${spinnerAnimation};
  width: ${({ size }) => `${sizeMap[size]}px`};
  height: ${({ size }) => `${sizeMap[size]}px`};
  & svg {
    width: 100%;
    height: 100%;
  }
`;

export const Spinner = memo(
  forwardRef<HTMLSpanElement, SpinnerProps>(
    ({ visible = true, indicator, size = 'medium', duration = 1, color, ...props }, ref) => {
      const theme = useTheme();
      if (!visible) return null;

      return (
        <SpinnerWrapper size={size} duration={duration} ref={ref} {...props}>
          {indicator ? (
            indicator
          ) : (
            <Icon name="spinner" size={60} color={color ?? theme.skin.primary.main} />
          )}
        </SpinnerWrapper>
      );
    }
  )
);
