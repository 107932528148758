import { ForwardedRef, HTMLAttributes, ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { useDualModeState } from '../../hooks/useDualModeState';
import { genericForwardRef } from '../../utils/genericForwardRef';
import { genericMemo } from '../../utils/genericMemo';

export type Tab = {
  title: ReactNode;
};

export type TabsBaseProps<T extends Tab> = {
  tabs: T[];
  onActiveTabChange?: (tab: T, index: number) => void;
  activeTab?: T;
  defaultActiveTab?: T;
  block?: boolean;
  classNames?: Partial<{
    slot: string;
    slider: string;
    tab: string;
    activeTab: string;
  }>;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const TabsWrapper = styled.div<{ block: boolean; count: number }>`
  display: ${({ block }) => (block ? 'grid' : 'inline-grid')};
  width: ${({ block }) => (block ? 'auto' : '100%')};
  grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
  padding: 4px;
  border-radius: 10000px;
  * {
    z-index: 2;
  }
`;

const Slider = styled.span<{ position: number }>`
  grid-row: 1;
  grid-column: 1;
  transform: ${({ position }) => `translateX(${position * 100}%)`};
  transition: 0.25s ease-out;
  z-index: 1;
  border-radius: 10000px;
`;

const Tab = styled.div<{ index: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1;
  grid-column: ${({ index }) => index + 1};
  border-radius: 10000px;
  * {
    z-index: 2;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const TabsBase = genericMemo(
  genericForwardRef(function <T extends Tab = Tab>(
    {
      tabs,
      onActiveTabChange,
      activeTab,
      defaultActiveTab,
      block = true,
      classNames,
      ...otherProps
    }: TabsBaseProps<T>,
    ref: ForwardedRef<HTMLDivElement>
  ) {
    const [finalActiveTab, setInternalActiveTab] = useDualModeState<T>(
      defaultActiveTab ?? tabs[0],
      activeTab
    );

    const handleChange = useCallback(
      (tab: T, index: number) => {
        setInternalActiveTab(tab);
        onActiveTabChange?.(tab, index);
      },
      [onActiveTabChange, setInternalActiveTab]
    );

    const tabsList = useMemo(
      () =>
        tabs.map((tab, index) => (
          <Tab
            key={index}
            index={index}
            className={
              tab === finalActiveTab ? cx(classNames?.tab, classNames?.activeTab) : classNames?.tab
            }
            onClick={() => handleChange(tab, index)}
          >
            {tab.title}
          </Tab>
        )),
      [classNames?.activeTab, classNames?.tab, finalActiveTab, handleChange, tabs]
    );

    return (
      <Container ref={ref} {...otherProps}>
        <TabsWrapper block={block} count={tabs.length} className={classNames?.slot}>
          {tabsList}
          <Slider position={tabs.indexOf(finalActiveTab)} className={classNames?.slider} />
        </TabsWrapper>
      </Container>
    );
  })
);
