import { useState, useCallback, useEffect, useMemo } from 'react';
import { Stack, Inline, Icon } from '@parallel-mono/components';
import { SelectableTokenValue } from '@parallel-mono/business-components';

import { useTransactionTokens, TransactionToken, TokenPair } from '../hooks/useTransactionTokens';
import { useCheckBalance } from '../hooks/useCheckBalance';
import { TokenInput } from '../components/TokenInput';

import { LiquidityTransaction } from './LiquidityTransaction';

import config from '@/config';
import { toFloorFixed } from '@/utils/format';
import { useAccount } from '@/hooks';

export const AddLiquidity = () => {
  const { account } = useAccount();
  const { isReady, fromTokens, toTokens, getToTokens, tokenPairs } = useTransactionTokens(true);
  const [fromTokenValue, setFromTokenValue] = useState<SelectableTokenValue<TransactionToken>>();
  const [toTokenValue, setToTokenValue] = useState<SelectableTokenValue<TransactionToken>>();

  const [trading, setTrading] = useState(false);

  const fromAssetId = useMemo(() => fromTokenValue?.token?.assetId, [fromTokenValue]);
  const toAssetId = useMemo(() => toTokenValue?.token?.assetId, [toTokenValue]);

  const tokenPair = useMemo(
    () =>
      tokenPairs.reduce<TokenPair | null>((result, [fromPair, toPair]) => {
        if (fromPair.assetId === fromAssetId && toPair.assetId === toAssetId) {
          return [fromPair, toPair];
        }

        if (fromPair.assetId === toAssetId && toPair.assetId === fromAssetId) {
          return [toPair, fromPair];
        }
        return result;
      }, null),
    [fromAssetId, toAssetId, tokenPairs]
  );

  const fromAssetCheckBalance = useCheckBalance(fromTokenValue);
  const toAssetCheckBalance = useCheckBalance(toTokenValue);

  const isInsufficient = useMemo(() => {
    return fromAssetCheckBalance.isInsufficient || toAssetCheckBalance.isInsufficient;
  }, [fromAssetCheckBalance, toAssetCheckBalance]);

  const handleFromTokenChange = useCallback(
    ({ amount, token }: SelectableTokenValue<TransactionToken>) => {
      setFromTokenValue({
        token,
        amount
      });

      if (!amount) {
        setToTokenValue(tokenValue => ({
          ...tokenValue!,
          amount
        }));
      }

      if (toTokenValue && tokenPair && amount) {
        const amountOut = (tokenPair[1].guideAmount! / tokenPair[0].guideAmount!) * amount;
        setToTokenValue({
          ...toTokenValue,
          amount: toFloorFixed(amountOut, 6)
        });
      }
    },
    [toTokenValue, tokenPair]
  );

  const handleToTokenChange = useCallback(
    async ({ amount, token }: SelectableTokenValue<TransactionToken>) => {
      setToTokenValue({
        token,
        amount
      });

      if (!amount) {
        setFromTokenValue(tokenValue => ({
          ...tokenValue!,
          amount
        }));
      }

      if (tokenPair && amount) {
        const amountOut = (tokenPair[0].guideAmount! / tokenPair[1].guideAmount!) * amount;
        setFromTokenValue(tokenValue => {
          return {
            ...tokenValue!,
            amount: toFloorFixed(amountOut, 6)
          };
        });
      }
    },
    [tokenPair]
  );

  const handleTxSuccess = useCallback(() => {
    setFromTokenValue(tokenValue => ({
      ...tokenValue!,
      amount: null
    }));
    setToTokenValue(tokenValue => ({
      ...tokenValue!,
      amount: null
    }));
  }, []);

  useEffect(() => {
    if (fromTokens.length > 0) {
      const defaultFromToken = fromTokens.find(token => token.assetId === config.relayAssetId);
      if (defaultFromToken)
        setFromTokenValue(tokenValue => ({
          amount: tokenValue?.amount ?? null,
          token: tokenValue?.token
            ? fromTokens.find(({ assetId }) => assetId === tokenValue.token.assetId)!
            : defaultFromToken
        }));
    }
  }, [fromTokens, account]);

  useEffect(() => {
    if (toTokens.length > 0) {
      const defaultToToken = toTokens.find(token => token.assetId === config.assetId);
      if (defaultToToken)
        setToTokenValue(tokenValue => ({
          amount: tokenValue?.amount ?? null,
          token: tokenValue?.token
            ? toTokens.find(({ assetId }) => assetId === tokenValue.token.assetId)!
            : defaultToToken
        }));
    }
  }, [toTokens, account]);

  useEffect(() => {
    if (fromAssetId) {
      const newToTokens = getToTokens(fromAssetId);

      setToTokenValue(tokenValue => {
        const newToToken = newToTokens.find(
          ({ assetId }) => assetId === tokenValue?.token?.assetId
        );
        return {
          ...tokenValue!,
          token: toAssetId === fromAssetId || !newToToken ? newToTokens[0] : newToToken!
        };
      });
    }
  }, [fromAssetId, getToTokens, toAssetId]);

  return (
    <Stack>
      <TokenInput
        isReady={isReady}
        trading={trading}
        tokens={fromTokens}
        value={fromTokenValue}
        isEmptyBalanceAlert
        onChange={handleFromTokenChange}
        {...fromAssetCheckBalance}
      />

      <Inline justifyContent="center" alignItems="center">
        <Icon name="plus" />
      </Inline>

      <TokenInput
        isReady={isReady}
        trading={trading}
        tokens={toTokens}
        value={toTokenValue}
        onChange={handleToTokenChange}
        {...toAssetCheckBalance}
      />

      <LiquidityTransaction
        fromTokenValue={fromTokenValue}
        toTokenValue={toTokenValue}
        tokenPair={tokenPair}
        isInsufficient={isInsufficient}
        trading={trading}
        setTrading={setTrading}
        onTxSuccess={handleTxSuccess}
      />
    </Stack>
  );
};
