import { CryptoAsset, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import { Stack, H4, Inline, H5, Icon, Alert, Button, H3 } from '@parallel-mono/components';
import { BN_ZERO } from '@polkadot/util';
import { FC, useRef, useMemo, useCallback } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import { useMarketAssets, useLiquidationFreeLimit, useBorrowLimit } from '../hooks';

import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { useChainConnections, useAccount, useTransactionFee } from '@/hooks';
import { signAndSend } from '@/utils/txCall';
import { ratioToNumber } from '@/utils/utils';
import { balanceFormatter } from '@/utils/format';

interface CollateralSwitchProps {
  closeModal: () => void;
  data: {
    tokenId: string;
    tokenName: string;
    tokenPrice: number;
    accountSupplied: number;
    isCollateral: boolean;
  };
}

const CollateralModal: FC<CollateralSwitchProps> = ({
  data: { tokenId, tokenName, accountSupplied, tokenPrice, isCollateral },
  closeModal
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();
  const { assetsMarket } = useMarketAssets();

  const transactionFee = useTransactionFee({
    api,
    tx: api.tx.loans.collateralAsset(tokenId, !isCollateral)
  });

  const { TxFeeTips } = useTxFeeValidation();

  const confirmTransaction = () => {
    closeModal();
    signAndSend({
      api,
      account,
      tx: api.tx.loans.collateralAsset(tokenId, !isCollateral)
    });
  };

  const { checkIsCollateralLiquidationFree, lfLiquidityLimit, getBorrowLimit } =
    useLiquidationFreeLimit();

  const isLiquidationFreeAsset = checkIsCollateralLiquidationFree(tokenId);
  const borrowLimit = getBorrowLimit(isLiquidationFreeAsset);
  const { usedRegularBorrow } = useBorrowLimit();

  const collateralValue = useMemo(() => {
    return (
      ratioToNumber(assetsMarket?.[tokenId]?.collateralFactor ?? BN_ZERO) *
      tokenPrice *
      accountSupplied
    );
  }, [assetsMarket, tokenPrice, accountSupplied, tokenId]);

  const newLfLiquidityLimit = useMemo(() => {
    return isCollateral
      ? Math.max(lfLiquidityLimit - collateralValue, 0)
      : lfLiquidityLimit + collateralValue - usedRegularBorrow;
  }, [isCollateral, lfLiquidityLimit, collateralValue, usedRegularBorrow]);

  const calcRegularBorrowLimit = useCallback(
    changedValue => {
      if (isCollateral) {
        return isLiquidationFreeAsset
          ? Math.min(borrowLimit - changedValue, borrowLimit - lfLiquidityLimit)
          : borrowLimit - changedValue;
      }
      return isLiquidationFreeAsset
        ? borrowLimit - lfLiquidityLimit + Math.min(usedRegularBorrow, changedValue)
        : borrowLimit + changedValue;
    },
    [isCollateral, isLiquidationFreeAsset, borrowLimit, lfLiquidityLimit, usedRegularBorrow]
  );

  const newRegularBorrowLimit = useMemo(
    () => calcRegularBorrowLimit(collateralValue),
    [calcRegularBorrowLimit, collateralValue]
  );

  const canRemoveCollateral = useMemo(() => {
    return isCollateral && newRegularBorrowLimit < 0;
  }, [isCollateral, newRegularBorrowLimit]);

  const infos = useMemo(
    () =>
      [
        isLiquidationFreeAsset
          ? {
              title: 'Liquidation Free Limit',
              tip: 'This is the maximum amount you can borrow for a liquidation free loan with no risk of liquidation, after you add collateral. It depends on the value you have deposited and the available liquidity.',
              value: (
                <Inline gap="0.5rem">
                  <H5> {formatNumber(lfLiquidityLimit, { output: 'currency' })} </H5>
                  <Icon name="arrowRight" />
                  <H5>{formatNumber(newLfLiquidityLimit, { output: 'currency' })}</H5>
                </Inline>
              )
            }
          : null,
        {
          title: 'Borrow Limit',
          tip: 'This is the maximum amount you can borrow, after you add collateral. It depends on the value you have deposited and the available liquidity.',
          value: (
            <Inline gap="0.5rem">
              <H5> {formatNumber(calcRegularBorrowLimit(0), { output: 'currency' })} </H5>
              <Icon name="arrowRight" />
              <H5>{formatNumber(newRegularBorrowLimit, { output: 'currency' })}</H5>
            </Inline>
          )
        }
      ].filter(info => info) as InfoPanelProps['infos'],
    [
      calcRegularBorrowLimit,
      isLiquidationFreeAsset,
      lfLiquidityLimit,
      newLfLiquidityLimit,
      newRegularBorrowLimit
    ]
  );

  return (
    <Stack gap="2rem">
      <H3>{isCollateral ? 'Remove Collateral' : 'Use as Collateral'}</H3>
      <Stack ref={ref} alignItems="center" gap="0.5rem">
        <CryptoAsset symbol={tokenName} symbolSize="large" />
        <H4>
          {balanceFormatter(accountSupplied)} {tokenName}
        </H4>
      </Stack>
      <InfoPanel infos={infos} />
      {canRemoveCollateral && (
        <Alert type="error">
          You cannot remove this collateral now because you have a borrow balance. Repay your debt
          first to proceed.
        </Alert>
      )}
      <Stack alignItems="center" gap="1rem">
        <Button
          disabled={canRemoveCollateral}
          skin="primary"
          size="large"
          block
          onClick={confirmTransaction}
        >
          {canRemoveCollateral ? 'Cannot Remove Collateral' : 'Confirm'}
        </Button>
        <TxFeeTips txFee={transactionFee} />
      </Stack>
    </Stack>
  );
};

export default CollateralModal;
