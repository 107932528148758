import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useMedia } from 'react-use';
import { useTheme } from 'styled-components';

const valueToString = (value: number | string) => (isNumber(value) ? `${value}px` : value);

export const useBreakpoints = () => {
  const theme = useTheme();
  const xs = useMedia(`(max-width: calc(${valueToString(theme.breakpointsConfig.sm)} - 1px))`);
  const sm = useMedia(`(max-width: calc(${valueToString(theme.breakpointsConfig.md)} - 1px))`);
  const md = useMedia(`(max-width: calc(${valueToString(theme.breakpointsConfig.lg)} - 1px))`);
  const lg = useMedia(`(max-width: calc(${valueToString(theme.breakpointsConfig.xl)} - 1px))`);
  const xl = useMedia(`(max-width: calc(${valueToString(theme.breakpointsConfig.xxl)} - 1px))`);
  const xxl = useMedia(`(min-width: ${valueToString(theme.breakpointsConfig.xxl)})`);
  const mobile = useMedia(
    `(max-width: calc(${valueToString(theme.breakpointsConfig.tablet)} - 1px))`
  );
  const tablet = useMedia(
    `(max-width: calc(${valueToString(theme.breakpointsConfig.desktop)} - 1px))`
  );
  const desktop = useMedia(`(min-width: ${valueToString(theme.breakpointsConfig.desktop)})`);

  const breakpoints = useMemo(
    () => ({
      xs,
      sm: sm && !xs,
      md: md && !sm,
      lg: lg && !md,
      xl: xl && !lg,
      xxl: xxl && !xl,
      mobile,
      tablet: tablet && !mobile,
      desktop: desktop && !tablet,
    }),
    [xs, sm, md, lg, xl, xxl, mobile, tablet, desktop]
  );

  return breakpoints;
};
