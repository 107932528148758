import { CSSProperties, FC } from 'react';
import styled, { keyframes, css } from 'styled-components';

export enum Variant {
  square = 'square',
  circle = 'circle',
  round = 'round',
}

export interface SkeletonElementProps {
  active?: boolean;
  variant?: `${Variant}`;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
}

const radiusHelper = ({ variant = Variant.square }: SkeletonElementProps) => {
  const styles = {
    [Variant.square]: '0',
    [Variant.circle]: '50%',
    [Variant.round]: '24px',
  };
  return styles[variant];
};

const skeletonLoading = keyframes`
  from {
    background-position: 100% 50%
  }

  to {
    background-position: 0 50%
  }
`;

const SkeletonElement = styled.div<SkeletonElementProps>`
  border-radius: ${radiusHelper};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: ${({ theme }) => theme.skin.grey['100']};
  ${({ active, theme }) => {
    return active
      ? css`
          background: linear-gradient(
            90deg,
            ${theme.skin.grey['100']} 25%,
            #f0f0f0 37%,
            ${theme.skin.grey['100']} 63%
          );
          background-size: 400% 100%;
          animation: ${skeletonLoading} 1.4s ease infinite;
        `
      : '';
  }};
`;

const Element: FC<SkeletonElementProps> = ({ active = true, ...props }) => {
  return <SkeletonElement {...props} active={active} />;
};

export default Element;
