import { Alert, H3, Inline, Text } from '@parallel-mono/components';
import styled from 'styled-components';
import { Box } from '@mui/material';

import { Collapse, Link } from '@/components';

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: var(--clr-black);
  font-weight: bold;
`;

const MyPositions = () => {
  return (
    <Collapse
      expand
      header={
        <Inline justifyContent="space-between" alignItems="center" inset="0 0 0 0.5rem">
          <H3>My Positions</H3>
        </Inline>
      }
    >
      <Box
        sx={{
          marginTop: '1rem'
        }}
      >
        <Alert>
          <Inline gap="0.25rem">
            <Text>
              Since August 12th, Heiko's Money Market product has been
              <StyledLink href="https://medium.com/@ParallelFi/successfully-conclusion-of-heiko-s-money-market-business-related-pages-to-be-closed-on-august-12th-45de75cb4c23">
                {' '}
                discontinued.{' '}
              </StyledLink>
              Your lending position has been automatically transferred to your wallet. If you
              encounter any encounter any issues, please reach out to us on Discord.
            </Text>
          </Inline>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default MyPositions;
