import { ComponentType, createElement, forwardRef, memo } from 'react';

export const withDeterminedVariant = <P extends { variant: string }>(
  Component: ComponentType<P>,
  variant: P['variant']
) =>
  memo(
    forwardRef<unknown, Omit<P, 'variant'>>((props, ref) => {
      const forgedProps = {
        ...props,
        ref,
        variant,
      } as P & { ref: unknown };
      return createElement(Component, forgedProps);
    })
  );
