import { useEffect, useState } from 'react';
import { pickBy, zipObject, zipWith } from 'lodash';
import { BorrowSnapshot } from '@parallel-finance/types/interfaces';
import { u128 } from '@polkadot/types';
import { BN_ZERO } from '@polkadot/util';

import { AssetInfo, AssetsData, CurrencyId } from '@/hooks/types';
import { useApiCall, useAccount, useChainConnections } from '@/hooks';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { NonEmptyArray } from '@/typings/basic';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';

const computedBorrows = (
  id: CurrencyId,
  index: u128,
  borrow: BorrowSnapshot,
  assets: AssetInfo[]
) => {
  const decimals = assets.find(asset => asset.assetId === id)?.decimals ?? BN_ZERO;
  const { principal, borrowIndex } = borrow;
  const amount =
    principal.isEmpty || borrowIndex.isEmpty ? BN_ZERO : principal.mul(index).div(borrowIndex);
  return balanceToAmountByDecimal<number>(amount, decimals, 'number');
};

const useAccountAssetsBorrow = (ids?: NonEmptyArray<CurrencyId>) => {
  const {
    parachain: { api }
  } = useChainConnections();

  const { assetInfos } = useCurrentAccountAssetInfos();
  const { account } = useAccount();
  const [state, setState] = useState<AssetsData | undefined>();

  const borrowIndexes = useApiCall<u128[]>(ids && api.query.loans.borrowIndex.multi, [ids]);

  const accountBorrows = useApiCall<BorrowSnapshot[]>(
    account && ids && api.query.loans.accountBorrows.multi,
    [ids?.map(id => [id, account?.address])]
  );

  useEffect(() => {
    if (ids && borrowIndexes && accountBorrows && assetInfos) {
      const values = zipWith(ids, borrowIndexes, accountBorrows, (id, index, borrow) =>
        computedBorrows(id, index, borrow, assetInfos)
      );
      setState(pickBy(zipObject(ids, values), value => value > 0));
    }
  }, [ids, accountBorrows, borrowIndexes, assetInfos]);

  return state;
};

export default useAccountAssetsBorrow;
