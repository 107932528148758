import { useMemo, FC, useContext, memo, useState } from 'react';
import styled from 'styled-components';
import { isNil } from 'lodash';
import {
  Inline,
  Stack,
  H2,
  H5,
  H6,
  SmallText,
  Skeleton,
  Tooltip,
  Tabs
} from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import gradient from './images/auction-header-bg.svg';
import useStakingExtraApy from './hooks/useStakingExtraApy';
import { StakingScopeContext } from './Context';
import ProcessStake from './subComponents/ProcessStake';
import ProcessUnStake from './subComponents/ProcessUnstake';

import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

export type Action = 'stake' | 'unstake';

const StakeContainer = styled(Stack)`
  border-radius: 12px;
  box-shadow: var(--box-shadow02);
  overflow: hidden;
  background: var(--clr-white);
  .card {
    background-image: url(${gradient});
  }
  .gray01 {
    color: var(--clr-gray01);
  }
  .apy-val {
    color: var(--clr-green-dark);
  }
  .unit {
    display: inline;
    padding-left: 4px;
    color: var(--clr-gray02);
  }
  .transfer {
    border-radius: 12px;
    background-color: var(--clr-white);
    z-index: 9;
  }
`;

const APYWrapper = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  inset: '0.25rem 0.25rem 0.25rem 1rem'
})`
  border: 1px solid var(--clr-gray04);
  background-color: var(--clr-white);
  border-radius: 2.5rem;
`;

const StyledTabs = styled(Tabs)`
  .tab {
    height: 3rem;
  }
`;

const UpdateStake: FC<{}> = () => {
  const { currencies, stakingAPY, stakingAPYLoaded } = useContext(StakingScopeContext);
  const extraApy = useStakingExtraApy();
  const { nativeAssetInfo: nativeAsset } = useCurrentAccountNativeAssetInfo();

  const stakingExtraAPY = useMemo(() => extraApy || 0, [extraApy]);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabs = [
    { title: <H5>Stake</H5>, content: <ProcessStake /> },
    { title: <H5>Unstake</H5>, content: <ProcessUnStake /> }
  ];

  return (
    <StakeContainer gap="0">
      <Stack className="card" inset="2rem" gap="0">
        <Inline inset="0" grow gap="0">
          <Stack gap="0.25rem">
            <Inline gap="0.25rem" alignItems="center">
              <H6 fontWeight="medium" skin="secondary">
                Total APY
              </H6>
              <Tooltip
                placement="right-start"
                content={`In addition to the ${formatNumber(stakingAPY, {
                  output: 'percent'
                })} (past 14 days average) staking APY, earn an extra ${formatNumber(
                  stakingExtraAPY,
                  {
                    output: 'percent'
                  }
                )} ${nativeAsset.symbol} APY by lending your ${
                  currencies.liquidAsset.symbol
                } in our money market.`}
              />
            </Inline>
            <Inline alignItems="center">
              {stakingAPYLoaded && !isNil(extraApy) ? (
                <H2 className="apy-val">
                  ≈{' '}
                  {formatNumber(stakingAPY + extraApy!, {
                    output: 'percent'
                  })}
                </H2>
              ) : (
                <Skeleton.Button width="6rem" height="2rem" variant="round" />
              )}
              <APYWrapper>
                <SmallText skin="secondary">
                  {formatNumber(stakingAPY, {
                    output: 'percent'
                  })}{' '}
                  +{' '}
                  {formatNumber(stakingExtraAPY, {
                    output: 'percent'
                  })}{' '}
                  lending
                </SmallText>
                <CryptoAsset symbol={nativeAsset.symbol} symbolSize="small" />
              </APYWrapper>
            </Inline>
          </Stack>
        </Inline>
      </Stack>
      <Stack className="transfer" inset="2rem">
        <StyledTabs
          classNames={{ tab: 'tab' }}
          block={false}
          tabs={tabs}
          activeTab={tabs[selectedIndex]}
          onActiveTabChange={(_, index) => setSelectedIndex(index)}
        />
        {tabs[selectedIndex].content}
      </Stack>
    </StakeContainer>
  );
};

export default memo(UpdateStake);
