import { ReactComponent as apps } from './apps.svg';
import { ReactComponent as arrowLeft } from './arrow-left.svg';
import { ReactComponent as arrowRight } from './arrow-right.svg';
import { ReactComponent as arrowDown } from './arrow-down.svg';
import { ReactComponent as arrowUp } from './arrow-up.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as close } from './close.svg';
import { ReactComponent as containedClose } from './contained-close.svg';
import { ReactComponent as check } from './check.svg';
import { ReactComponent as clock } from './clock.svg';
import { ReactComponent as containedCheck } from './contained-check.svg';
import { ReactComponent as setting } from './setting.svg';
import { ReactComponent as down } from './down.svg';
import { ReactComponent as externalLink } from './external-link.svg';
import { ReactComponent as fallback } from './fallback.svg';
import { ReactComponent as heart } from './heart.svg';
import { ReactComponent as expand } from './expand.svg';
import { ReactComponent as penSign } from './pen-sign.svg';
import { ReactComponent as plus } from './plus.svg';
import { ReactComponent as right } from './right.svg';
import { ReactComponent as spinner } from './spinner.svg';
import { ReactComponent as stack } from './stack.svg';
import { ReactComponent as transfer } from './transfer.svg';
import { ReactComponent as trendingUp } from './trending-up.svg';
import { ReactComponent as info } from './info.svg';
import { ReactComponent as up } from './up.svg';
import { ReactComponent as wallet } from './wallet.svg';
import { ReactComponent as warning } from './warning.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as left } from './left.svg';
import { ReactComponent as search } from './search.svg';
import { ReactComponent as filter } from './filter.svg';
import { ReactComponent as moreVertical } from './more-vertical.svg';
import { ReactComponent as hint } from './hint.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as coins } from './coins.svg';
import { ReactComponent as moneyBag } from './money-bag.svg';
import { ReactComponent as waterDrop } from './water-drop.svg';
import { ReactComponent as box } from './box.svg';
import { ReactComponent as boxOpen } from './box-open.svg';
import { ReactComponent as dollar } from './dollar.svg';
import { ReactComponent as shield } from './shield.svg';
import { ReactComponent as medal } from './medal.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as circledPlus } from './circledPlus.svg';
import { ReactComponent as smile } from './smile.svg';
import { ReactComponent as chart } from './chart.svg';

export const icons = {
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  info,
  warning,
  up,
  down,
  left,
  right,
  apps,
  calendar,
  check,
  close,
  containedCheck,
  containedClose,
  clock,
  setting,
  copy,
  expand,
  externalLink,
  fallback,
  heart,
  penSign,
  plus,
  spinner,
  stack,
  transfer,
  trendingUp,
  wallet,
  search,
  filter,
  moreVertical,
  hint,
  trash,
  coins,
  moneyBag,
  waterDrop,
  box,
  boxOpen,
  dollar,
  shield,
  medal,
  download,
  circledPlus,
  smile,
  chart,
};
