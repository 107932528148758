import { FC } from 'react';
import { Button, H2, Stack } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';

import { Pool } from '@/pages/Farm/types';
import { useAccount, useChainConnections, useTransactionFee } from '@/hooks';
import { balanceFormatter } from '@/utils/format';
import { useTxFeeValidation } from '@/hooks/useTxFeeValidation';
import { signAndSend } from '@/utils/txCall';

type Props = {
  closeModal: () => void;
  data: Pool;
};

const ClaimModal: FC<Props> = ({ closeModal, data }) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const claimTransaction = api.tx.farming.claim(
    data.asset.id,
    data.rewardAsset.id,
    data.lockDuration
  );
  const { account } = useAccount();
  const transactionFee = useTransactionFee({
    api,
    tx: claimTransaction
  });

  const { TxFeeTips } = useTxFeeValidation();

  const handleClick = () => {
    closeModal();
    signAndSend({
      api,
      tx: claimTransaction,
      account
    });
  };
  return (
    <Stack justifyContent="center">
      <Stack alignItems="center" gap="0.5rem">
        <CryptoAsset symbol={data.rewardAsset.name} />
        <H2 fontWeight="bold">{balanceFormatter(data.user.rewards)}</H2>
      </Stack>
      <Button skin="primary" block onClick={handleClick}>
        {`Claim ${balanceFormatter(data.user.rewards)} ${data.rewardAsset.name}`}
      </Button>
      <TxFeeTips txFee={transactionFee} />
    </Stack>
  );
};

export default ClaimModal;
