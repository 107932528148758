import { FC, ReactElement, JSXElementConstructor, Children, useMemo } from 'react';
import styled from 'styled-components';
import { Inline, H5, Icon } from '@parallel-mono/components';

import MenuItem, { MenuItemProps } from './Item';
import Dropdown, { DropdownProps } from './Dropdown';

const DropdownMenus = styled(Inline)`
  border: none;
  border-radius: 100px;
  box-shadow: none;
  cursor: pointer;
  padding: 10px 16px;
  cursor: pointer;

  &:hover {
    background-color: var(--clr-bg02);
  }
  ${({ theme }) => theme.breakpoints.down('lg')`
    padding: 0;
  `};
`;

const AppsButton = styled.div`
  border: 1px solid var(--clr-gray03);
  box-sizing: border-box;
  border-radius: 100px;
`;

export type MenuItemElement = ReactElement<MenuItemProps, JSXElementConstructor<MenuItemProps>>;

interface MenuProps {
  className?: string;
  maxMenuCount?: number;
  children: MenuItemElement | MenuItemElement[];
  miniMode?: boolean;
}

interface MenuType extends FC<MenuProps> {
  Item: FC<MenuItemProps>;
  Dropdown: FC<DropdownProps>;
}

const Menu: MenuType = ({ className, maxMenuCount, children, miniMode = false }) => {
  const hasDropdownMenus = miniMode || (maxMenuCount && Children.count(children) > maxMenuCount);
  const allMenus = Children.toArray(children);
  const menus = useMemo(() => {
    if (miniMode) return [];
    return hasDropdownMenus ? allMenus.slice(0, maxMenuCount) : allMenus;
  }, [allMenus, hasDropdownMenus, maxMenuCount, miniMode]);

  const dropdownMenus = useMemo(() => {
    if (miniMode) return allMenus;

    return hasDropdownMenus ? allMenus.slice(maxMenuCount) : [];
  }, [allMenus, hasDropdownMenus, maxMenuCount, miniMode]);

  return (
    <Inline className={className} gap="0" alignItems="center">
      {menus}
      {hasDropdownMenus && (
        <Dropdown menus={dropdownMenus as MenuItemElement[]} trigger="click">
          <DropdownMenus gap="0.3rem">
            {!miniMode ? (
              <>
                <H5 fontWeight="medium">More</H5>
                <Icon name="down" size="small" />
              </>
            ) : (
              <AppsButton>
                <Inline gap="0.5rem" inset="0.5rem 1rem" alignItems="center">
                  <H5 fontWeight="medium">Apps</H5> <Icon name="apps" size="medium" />
                </Inline>
              </AppsButton>
            )}
          </DropdownMenus>
        </Dropdown>
      )}
    </Inline>
  );
};

Menu.Item = MenuItem;
Menu.Dropdown = Dropdown;
export default Menu;
