import { forwardRef, ForwardRefRenderFunction, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

export const randomPickAccImage = (index: number, prefix?: string) => {
  const icon = prefix || 'accountIcon';
  return `${icon}${Math.floor(index % 5)}`;
};

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  width?: string;
  height?: string;
}

const StyledImage = styled.img`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
`;

const Image: ForwardRefRenderFunction<HTMLImageElement, ImageProps> = ({ src, ...props }, ref) => {
  return <StyledImage ref={ref} src={src} alt={`${src} image`} {...props} />;
};

const ImageWithRef = forwardRef(Image);

export const RoundImage = styled(ImageWithRef)`
  border-radius: 50%;
`;

export default ImageWithRef;
