import { memo, FC, useMemo, useCallback, useState, ChangeEvent } from 'react';
import { map, min, max, debounce } from 'lodash';
import styled, { useTheme } from 'styled-components';
import {
  Stack,
  Responsive,
  Card,
  Inline,
  H3,
  H5,
  Text,
  Button,
  Toggle,
  DecoratedInput,
  Icon,
  RowClickEventContext,
  Skeleton,
  Tooltip
} from '@parallel-mono/components';
import { Pill } from '@parallel-mono/business-components';
import { BN_ZERO } from '@polkadot/util';
import { formatNumber } from '@parallel-mono/utils';

import LendWithdrawModal, { SupplyActionType } from './Lend/LendWithdrawModal';
import BorrowRepayModal, { BorrowedActionType } from './Borrowed/BorrowRepayModal';
import { TokenDetailedModal } from './Modal/TokenDetailedModal';
import AssetColumn from './components/AssetColumn';
import { MarketAssetRow } from './types';
import { useLiquidationFreeLimit, LoanAssets } from './hooks';
import { TOOLTIP } from './constants';

import config from '@/config';
import { TokenPill, StyledDataGrid, StyledDataGridColumn } from '@/components';
import { useDevice, useAccount, useModal } from '@/hooks';
import { AssetsData, AssetInfo, AssetMarket, MarketData, AssetMarketStatus } from '@/hooks/types';
import { balanceFormatter } from '@/utils/format';
import getFeatureToggle from '@/utils/toggles/getFeatureToggle';

const TableWrapper = styled(Card)`
  width: 100%;
  white-space: nowrap;
  ${({ theme }) => theme.breakpoints.down('md')`
  padding: 0rem 0.5rem 0rem 0.5rem;
  `};
`;

const PillStyled = styled(Pill)<{ active: boolean }>`
  border: none;
  background-color: ${({ theme }) => theme.skin.primary.contrastBackground};
  ${({ active }) => (active ? null : { backgroundColor: 'transparent', boxShadow: 'none' })};
`;

export interface AssetRow extends MarketAssetRow {
  earned?: number;
  supplyRewardApy?: number;
  borrowRewardApy?: number;
  borrowedAmount?: number;
  availableLiquidity?: number;
  accountSupplied?: number;
  isBorrowedAsset?: boolean;
}

interface LendAndBorrowTableProps {
  dataIsReady: boolean;
  assetInfos?: AssetInfo[];
  assetsPrice?: AssetsData;
  accountBorrow?: AssetsData;
  assetsMarket?: AssetMarket;
  loanAssets?: LoanAssets;
  marketStatus?: MarketData<AssetMarketStatus>;
  accountEarned?: AssetsData;
  accountSupplies?: AssetsData;
  supplyRewardApys?: AssetsData;
  borrowRewardApys?: AssetsData;
}

const LendAndBorrowTable: FC<LendAndBorrowTableProps> = ({
  dataIsReady,
  assetInfos,
  assetsPrice,
  accountBorrow,
  assetsMarket,
  loanAssets,
  marketStatus,
  accountEarned,
  accountSupplies,
  supplyRewardApys,
  borrowRewardApys
}) => {
  const theme = useTheme();
  const [liquidationFreeFilterChecked, toggleLfFilterCheck] = useState(false);
  const [hideZeroBalance, setHideZeroBalance] = useState(false);
  const [searchToken, setSearchToken] = useState('');

  const { checkBorrowLiquidationFree, checkIsCollateralLiquidationFree, getBorrowLimit } =
    useLiquidationFreeLimit();

  const { account } = useAccount();
  const { isDesktop } = useDevice();

  const availableAmountForBorrow = useCallback(
    (token: AssetRow) => {
      const { availableLiquidity, price, assetId } = token;
      const isLiquidationFreeAsset = checkBorrowLiquidationFree(assetId);
      const borrowLimit = getBorrowLimit(isLiquidationFreeAsset);
      return max([min([availableLiquidity, borrowLimit / price]) ?? 0, 0]);
    },
    [checkBorrowLiquidationFree, getBorrowLimit]
  );

  const handleInputChange = useMemo(
    () =>
      debounce((value: ChangeEvent<HTMLInputElement>) => {
        setSearchToken(value.target.value);
      }, 150),
    []
  );

  const rows = useMemo<AssetRow[]>(() => {
    return map(assetInfos, ({ assetId, ...info }) => ({
      ...info,
      assetId,
      name: info.symbol,
      price: assetsPrice?.[assetId] ?? 0,
      borrowedAmount: accountBorrow?.[assetId] ?? 0,
      availableLiquidity: loanAssets?.[assetId]?.availableLiquidity ?? 0,
      accountSupplied: accountSupplies?.[assetId] ?? 0,
      supplyMarket: marketStatus?.[assetId]?.totalSupply ?? 0,
      supplyRate: marketStatus?.[assetId]?.supplyRate ?? 0,
      effectiveAPYLoading: marketStatus?.[assetId]?.effectiveAPYLoading ?? false,
      borrowMarket: marketStatus?.[assetId]?.totalBorrow ?? 0,
      borrowRate: marketStatus?.[assetId]?.borrowRate ?? 0,
      earned: accountEarned?.[assetId] ?? 0,
      supplyRewardApy: supplyRewardApys?.[assetId] ?? 0,
      borrowRewardApy: borrowRewardApys?.[assetId] ?? 0,
      isBorrowedAsset: assetsMarket?.[assetId]?.borrowCap?.gt(BN_ZERO)
    })).filter(({ name, assetId, availableBalance }) => {
      const active = assetsMarket?.[assetId]?.state?.isActive;
      if (hideZeroBalance && availableBalance < 0.001) return false;
      if (searchToken && !new RegExp(searchToken, 'i').test(name)) return false;

      return liquidationFreeFilterChecked
        ? active &&
            (checkIsCollateralLiquidationFree(assetId) || checkBorrowLiquidationFree(assetId))
        : active;
    });
  }, [
    accountBorrow,
    accountEarned,
    accountSupplies,
    assetInfos,
    assetsMarket,
    assetsPrice,
    borrowRewardApys,
    checkBorrowLiquidationFree,
    checkIsCollateralLiquidationFree,
    hideZeroBalance,
    liquidationFreeFilterChecked,
    loanAssets,
    marketStatus,
    searchToken,
    supplyRewardApys
  ]);

  const { openModal: openLendWithdrawModal, holder: supplyWithdrawModalHolder } = useModal(
    LendWithdrawModal,
    {
      size: '500px'
    },
    { assetInfos: rows }
  );

  const { openModal: openBorrowRepayModal, holder: borrowRepayModalHolder } = useModal(
    BorrowRepayModal,
    {
      size: '500px'
    },
    { assetInfos: rows }
  );

  const { openModal: openTokenDetailedModal, holder: tokenDetailedModalHolder } = useModal(
    TokenDetailedModal,
    {
      size: '520px'
    },
    {
      assetsMarket
    }
  );

  const handleClickLend = useCallback(
    (token: AssetRow) => {
      openLendWithdrawModal({
        tabKey: SupplyActionType.Lend,
        assetId: token.assetId
      });
    },
    [openLendWithdrawModal]
  );

  const handleClickBorrow = useCallback(
    (token: AssetRow) => {
      openBorrowRepayModal({
        tabKey: BorrowedActionType.Borrow,
        assetId: token.assetId,
        availableAmount: availableAmountForBorrow(token)
      });
    },
    [availableAmountForBorrow, openBorrowRepayModal]
  );

  const handleRowClick = useCallback(
    ({ data }: RowClickEventContext<AssetRow>) => {
      if (data.assetId) openTokenDetailedModal(data);
    },
    [openTokenDetailedModal]
  );

  const columns = useMemo<StyledDataGridColumn<AssetRow>[]>(
    () => [
      {
        name: 'name',
        title: 'Assets',
        width: '1.4fr',
        isAvatar: true,
        render: ({ data: { name, assetId } }) => (
          <AssetColumn
            symbol={name}
            isLiquidationFree={
              checkIsCollateralLiquidationFree(assetId) || checkBorrowLiquidationFree(assetId)
            }
          />
        )
      },
      {
        name: 'balance',
        title: (
          <Stack gap="0">
            <Text>Wallet</Text>
            <Text>Balance</Text>
          </Stack>
        ),
        hidden: !account || !isDesktop,
        width: '1fr',
        render: ({ data: { availableBalance } }) => balanceFormatter(availableBalance)
      },
      {
        name: 'supplyRate',
        title: (
          <Stack gap="0">
            <Text>Supply</Text>
            <Text>APY</Text>
          </Stack>
        ),
        width: '1fr',
        render: ({ data: { name, supplyRate, supplyRewardApy, effectiveAPYLoading, assetId } }) =>
          effectiveAPYLoading ? (
            <Skeleton.Button variant="round" />
          ) : (
            <Stack gap="0">
              <Inline gap="0.25rem">
                <Text>{formatNumber(supplyRate, { output: 'percent' })}</Text>
                {assetId === config.sReplayAssetId && <Tooltip content="Effective APY" />}
              </Inline>

              {supplyRewardApy && supplyRewardApy > 0 ? (
                <Tooltip
                  content={`This is farming rewards for supplying ${name}. It is calculated by per day compounding.`}
                >
                  <TokenPill
                    name={config.nativeToken.toUpperCase()}
                    content={formatNumber(supplyRewardApy, {
                      output: 'percent',
                      decimal: 1,
                      threshold: { min: 0.01, max: 100 }
                    })}
                  />
                </Tooltip>
              ) : null}
            </Stack>
          )
      },
      {
        name: 'tvl',
        title: 'TVL',
        width: '1fr',
        render: ({ data: { supplyMarket, price } }) =>
          formatNumber(supplyMarket * price, { output: 'currency' })
      },
      {
        name: 'availableAmount',
        title: (
          <Stack gap="0">
            <Text>Available</Text>
            <Inline gap="0.3rem" alignItems="center">
              <Text>Liquidity</Text>
              <Tooltip content={TOOLTIP.AVAILABLE_BALANCE} />
            </Inline>
          </Stack>
        ),
        width: '1fr',
        hidden: !account || !isDesktop,
        render: ({ data: row }) => {
          const { supplyMarket, borrowMarket, price } = row;
          return formatNumber(max([(supplyMarket - borrowMarket) * price, 0])!, {
            output: 'currency'
          });
        }
      },
      {
        name: 'borrowRate',
        title: (
          <Stack gap="0">
            <Text>Borrow</Text>
            <Text>APY</Text>
          </Stack>
        ),
        width: '1fr',
        render: ({
          data: { name, borrowRate, borrowRewardApy, isBorrowedAsset, effectiveAPYLoading, assetId }
        }) => {
          if (!isBorrowedAsset) return '-';
          return effectiveAPYLoading ? (
            <Skeleton.Button variant="round" />
          ) : (
            <Stack gap="0">
              <Inline gap="0.25rem">
                <Text>
                  {borrowRate > 0 ? '-' : ''}
                  {formatNumber(borrowRate, { output: 'percent' })}
                </Text>
                {assetId === config.sReplayAssetId && <Tooltip content="Effective APY" />}
              </Inline>

              {borrowRewardApy && borrowRewardApy > 0 ? (
                <Tooltip
                  content={`This is farming rewards for borrowing ${name}. It is calculated by per day compounding.`}
                >
                  <TokenPill
                    name={config.nativeToken.toUpperCase()}
                    content={formatNumber(borrowRewardApy, {
                      output: 'percent',
                      decimal: 1,
                      threshold: { min: 0.01, max: 100 }
                    })}
                  />
                </Tooltip>
              ) : null}
            </Stack>
          );
        }
      },
      {
        name: 'action',
        width: '2fr',
        title: '',
        justifyContent: 'flex-start',
        render: ({ data }) => (
          <Inline gap="0.5rem">
            <Button
              skin="secondary"
              onClick={e => {
                e.stopPropagation();
                handleClickLend(data);
              }}
            >
              Lend
            </Button>
            {data.isBorrowedAsset && (
              <Button
                skin="secondary"
                onClick={e => {
                  e.stopPropagation();
                  handleClickBorrow(data);
                }}
              >
                Borrow
              </Button>
            )}
          </Inline>
        )
      }
    ],
    [
      account,
      checkBorrowLiquidationFree,
      checkIsCollateralLiquidationFree,
      handleClickBorrow,
      handleClickLend,
      isDesktop
    ]
  );

  return (
    <>
      <TableWrapper>
        <Stack gap="0.8rem">
          <Responsive justifyContent="space-between">
            <Responsive alignItems="center">
              <H3>Lend and Borrow</H3>
              <Responsive gap="1rem">
                <PillStyled
                  label="All Assets"
                  active={!liquidationFreeFilterChecked}
                  onToggle={() => toggleLfFilterCheck(false)}
                  skin="neutral"
                />
                <PillStyled
                  label="Liquidation Free"
                  active={liquidationFreeFilterChecked}
                  onToggle={() => toggleLfFilterCheck(true)}
                  skin="neutral"
                />
              </Responsive>
            </Responsive>
            <Responsive>
              <Inline alignItems="center" gap="1rem">
                <H5 fontWeight="medium">Hide 0 wallet balance</H5>
                <Toggle
                  checked={hideZeroBalance}
                  onChange={newChecked => setHideZeroBalance(newChecked)}
                />
              </Inline>
              <DecoratedInput
                Component="input"
                startAdornment={<Icon name="search" color={theme.skin.grey[300]} />}
                inputProps={{
                  placeholder: 'Search token',
                  onChange: handleInputChange
                }}
              />
            </Responsive>
          </Responsive>
          <StyledDataGrid<AssetRow>
            loading={!dataIsReady}
            columns={columns}
            data={rows}
            onRowClick={handleRowClick}
            classNames={{ row: 'clickable' }}
            noDataMessage="You have no available asset."
          />
        </Stack>
      </TableWrapper>
      {supplyWithdrawModalHolder}
      {borrowRepayModalHolder}
      {getFeatureToggle('TOGGLE_TOKEN_DETAILED') && tokenDetailedModalHolder}
    </>
  );
};

export default memo(LendAndBorrowTable);
