import { memo, useState, useMemo, useCallback } from 'react';
import { Stack, Inline, Icon, H2, H3, Button } from '@parallel-mono/components';
import { CryptoAsset, InfoPanel, InfoPanelProps } from '@parallel-mono/business-components';
import styled from 'styled-components';
import { BN } from '@polkadot/util';
import { useDebounce } from 'react-use';

import { LiquidityPair } from '../hooks/useMyLiquidityPairs';
import { DownSlideAnimation } from '../components/DownSlideAnimation';

import { RangeSlider, ConnectToWallet } from '@/components';
import { useAccount, useChainConnections, useScopeState } from '@/hooks';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { signAndSend, txFee } from '@/utils/txCall';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { balanceFormatter } from '@/utils/format';

const Title = styled(H3)`
  width: 100%;
  text-align: center;
  margin-left: auto;
`;

const BackButton = styled.span`
  cursor: pointer;
`;

interface WithdrawLiquidityProps extends LiquidityPair {
  closeWithdrawLiquidity: () => void;
}

const withdrawPercents = [20, 50, 75, 100];

export const WithdrawLiquidity = memo<WithdrawLiquidityProps>(
  ({
    tokenPair: [fromToken, toToken],
    balance,
    decimals,
    pooledBaseAmount,
    pooledQuoteAmount,
    closeWithdrawLiquidity
  }) => {
    const { account } = useAccount();
    const { TxFeeTips } = useTxFeeValidation();

    const {
      parachain: { api }
    } = useChainConnections();

    const [percentage, setPercentage] = useState(20);
    const [trading, setTrading] = useState(false);
    const [transactionFee, setTransactionFee] = useScopeState<TxFee>();

    const baseTokenWithdrawAmount = useMemo(
      () => pooledBaseAmount * (percentage / 100),
      [pooledBaseAmount, percentage]
    );

    const quoteTokenWithdrawAmount = useMemo(
      () => pooledQuoteAmount * (percentage / 100),
      [percentage, pooledQuoteAmount]
    );

    const withdrawLiquidityTx = useCallback(() => {
      return api.tx.amm.removeLiquidity(
        [fromToken.assetId, toToken.assetId],
        amountToBalanceByDecimals<BN>(balance * (percentage / 100), decimals, 'bn')
      );
    }, [api.tx.amm, balance, decimals, fromToken.assetId, percentage, toToken.assetId]);

    const executeTrade = useCallback(() => {
      if (account) {
        try {
          setTrading(true);
          signAndSend({
            api,
            tx: withdrawLiquidityTx(),
            account,
            txSuccessCb: () => {
              setTrading(false);
              closeWithdrawLiquidity();
            },
            txProcessingCb: () => {},
            txFailedCb: err => {
              setTrading(false);
              console.error('Error executing trade:', err);
            }
          });
        } catch (err) {
          setTrading(false);
          console.error('Error executing trade', err);
        }
      }
    }, [account, api, closeWithdrawLiquidity, withdrawLiquidityTx]);

    const infos = useMemo<InfoPanelProps['infos']>(
      () => [
        {
          title: `Receive ${fromToken.symbol}`,
          value: `${balanceFormatter(baseTokenWithdrawAmount)} ${fromToken.symbol}`
        },
        {
          title: `Receive ${toToken.symbol}`,
          value: `${balanceFormatter(quoteTokenWithdrawAmount)} ${toToken.symbol}`
        }
      ],
      [baseTokenWithdrawAmount, fromToken, quoteTokenWithdrawAmount, toToken]
    );

    useDebounce(
      () => {
        const getFee = async () => {
          const fee = await txFee({
            tx: withdrawLiquidityTx(),
            account
          });
          setTransactionFee(fee);
        };
        if (account) {
          setTransactionFee(null);
          getFee();
        }
      },
      500,
      [account]
    );

    return (
      <Stack>
        <Inline justifyContent="space-between">
          <BackButton onClick={closeWithdrawLiquidity}>
            <Icon name="arrowLeft" size="large" />
          </BackButton>
          <Title>Withdraw Liquidity</Title>
        </Inline>

        <Inline justifyContent="center" gap="0.5rem">
          <CryptoAsset symbol={fromToken.symbol} symbolSize="large" showName={false} />
          <CryptoAsset symbol={toToken.symbol} symbolSize="large" showName={false} />
        </Inline>

        <Stack justifyContent="center" alignItems="center" gap="0.3rem">
          <H2>{percentage}%</H2>
          <RangeSlider value={percentage} setValue={setPercentage} />
        </Stack>

        <Inline justifyContent="space-around" alignItems="center" gap="0.3rem">
          {withdrawPercents.map(withdrawPercent => (
            <Button
              key={withdrawPercent}
              size="small"
              skin={percentage === withdrawPercent ? 'primary' : 'secondary'}
              onClick={() => setPercentage(withdrawPercent)}
            >
              {withdrawPercent}%
            </Button>
          ))}
        </Inline>

        <InfoPanel infos={infos} />

        <Stack gap="1rem" justifyContent="center" alignItems="center">
          {account ? (
            <Button
              size="large"
              disabled={trading || percentage <= 0}
              block
              skin="primary"
              onClick={executeTrade}
            >
              Withdraw
            </Button>
          ) : (
            <ConnectToWallet size="large" skin="secondary" block>
              Connect Wallet
            </ConnectToWallet>
          )}

          <DownSlideAnimation isReady={transactionFee !== undefined}>
            <TxFeeTips txFee={transactionFee!} />
          </DownSlideAnimation>
        </Stack>
      </Stack>
    );
  }
);
