import { Inline, Stack, H6, SmallText, Toggle } from '@parallel-mono/components';
import { FC } from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
  flex: 0 0 2.5rem;
`;

type ToggleProps = {
  onChange: (checked: boolean) => void;
  checked: boolean;
};

const InstantUnstakeToggle: FC<ToggleProps> = ({ onChange, checked }) => {
  return (
    <Inline gap="0.875rem" justifyContent="space-between" alignItems="center">
      <Stack gap="0.5rem">
        <H6>Instant Unstake</H6>
        <SmallText skin="secondary">Lets you skip the waiting period at a fee</SmallText>
      </Stack>

      <ToggleWrapper>
        <Toggle checked={checked} onChange={onChange} />
      </ToggleWrapper>
    </Inline>
  );
};

export default InstantUnstakeToggle;
