import { Chains } from '../types';
import { PolkadotChainCurrency } from '../polkadot/types';
import { PhalaChainCurrency } from '../phala/types';
import { StatemintChainCurrency } from '../statemint/types';
import { InterlayChainCurrency } from '../interlay/types';
import { MINIMUM_TRANSFER_AMOUNT as statemintMinimumTransferAmount } from '../statemint/constants';
import { MINIMUM_TRANSFER_AMOUNT as acalaMinimumTransferAmount } from '../acala/constants';
import { MINIMUM_TRANSFER_AMOUNT as polkadotMinimumTransferAmount } from '../polkadot/constants';
import { MINIMUM_TRANSFER_AMOUNT as phalaMinimumTransferAmount } from '../phala/constants';
import { MINIMUM_TRANSFER_AMOUNT as interlayMinimumTransferAmount } from '../interlay/constants';
import { AcalaChainCurrency } from '../acala/types';
import { ALL_CHAINS } from '../constants';

import config, { Chain } from '@/config';
import { MAX_TRANSACTION_FEE } from '@/utils/constants';

export const CURRENCIES = {
  [Chain.PARALLEL]: {
    [Chains.Acala]: [
      AcalaChainCurrency.ACA,
      // AcalaChainCurrency.AUSD,
      AcalaChainCurrency.LDOT,
      AcalaChainCurrency.LCDOT
    ],
    [Chains.Polkadot]: [PolkadotChainCurrency.DOT],
    [Chains.Phala]: [PhalaChainCurrency.PHA],
    [Chains.Statemint]: [StatemintChainCurrency.USDT],
    [Chains.Interlay]: [InterlayChainCurrency.INTR, InterlayChainCurrency.IBTC]
  },
  [Chain.HEIKO]: {
    [Chains.Acala]: [AcalaChainCurrency.KAR, AcalaChainCurrency.LKSM],
    [Chains.Polkadot]: [PolkadotChainCurrency.KSM],
    [Chains.Phala]: [PhalaChainCurrency.PHA],
    [Chains.Statemint]: [StatemintChainCurrency.USDT],
    [Chains.Interlay]: [InterlayChainCurrency.KINT, InterlayChainCurrency.KBTC]
  }
}[config.chain];

export const MINIMUM_TRANSFER_AMOUNT = {
  ...acalaMinimumTransferAmount,
  ...phalaMinimumTransferAmount,
  ...statemintMinimumTransferAmount,
  ...polkadotMinimumTransferAmount,
  ...interlayMinimumTransferAmount
};

export const TO_CHAINS = ALL_CHAINS.filter(chain => chain !== Chains.Parallel);

export const PARALLRL_NATIVE_TOKEN = config.nativeToken;

export const MAXIMUM_TX_FEE = {
  [Chain.PARALLEL]: MAX_TRANSACTION_FEE,
  [Chain.HEIKO]: MAX_TRANSACTION_FEE
}[config.chain];
