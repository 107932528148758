import { useState, useMemo } from 'react';
import { AssetDetails } from '@polkadot/types/interfaces';
import { StorageKey, Option } from '@polkadot/types';
import { AssetId } from '@parallel-finance/types/interfaces';

import { useApiCall, useChainConnections } from '@/hooks';

export interface ExtendAssetDetail extends AssetDetails {
  assetId: AssetId;
}

export const useAssetDetails = () => {
  const {
    parachain: { api }
  } = useChainConnections();

  const [isReady, setIsReady] = useState(false);

  const assetIds = useApiCall<[StorageKey<[AssetId]>][]>(
    api.query.assets.asset.entries
  )?.map<AssetId>(
    ([
      {
        args: [assetId]
      }
    ]) => assetId
  );

  const assetDetailsOpt = useMemo(
    () => ({
      transform: (values: Option<AssetDetails>[]) => {
        if (!isReady) setIsReady(true);

        return values.reduce<ExtendAssetDetail[]>((result, option, index) => {
          if (option.isSome && assetIds) {
            const detail = option.unwrap() as ExtendAssetDetail;
            detail.assetId = assetIds[index];
            result.push(detail);
          }
          return result;
        }, []);
      }
    }),
    [assetIds, isReady]
  );

  const assetDetails = useApiCall<ExtendAssetDetail[]>(
    assetIds && api.query.assets.asset.multi,
    [assetIds],
    assetDetailsOpt
  );

  return {
    isReady,
    assetDetails: assetDetails || []
  };
};
