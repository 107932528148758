import { ApiPromise } from '@polkadot/api';
import { clamp } from 'lodash';

import config from '@/config';
import { rateToNumber } from '@/utils/utils';

const LAST_N_DAYS = 14;
const TIME_PER_BLOCK = (60 * 60 * 24) / config.blockPerDay;

const apyRange: [number, number] = config.isParallel ? [0.15, 0.23] : [0.17, 0.25];

export const getStakingApy = async (api: ApiPromise): Promise<number> => {
  const currentBlockNumber = await api.query.system.number();
  const nDaysAgoBlockNumber = currentBlockNumber
    ?.toBn()
    .subn(config.blockPerDay * LAST_N_DAYS)
    .toNumber();
  const nDaysAgoBlockHash = await api.rpc.chain.getBlockHash(nDaysAgoBlockNumber);
  const nDaysAgoApi = await api.at(nDaysAgoBlockHash);

  const startExchangeRate = await nDaysAgoApi.query.liquidStaking.exchangeRate();
  const currentChangeRate = await api.query.liquidStaking.exchangeRate();

  const millisElapsed = LAST_N_DAYS * config.blockPerDay * TIME_PER_BLOCK * 1000;
  const millisPerYear = 365 * 24 * 60 * 60000;
  const result =
    (rateToNumber(currentChangeRate) / rateToNumber(startExchangeRate)) **
      (millisPerYear / millisElapsed) -
    1;

  return clamp(result, ...apyRange);
};
