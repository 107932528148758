import { FC, memo, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Tooltip, Inline, Text } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import TransactionModal, { ActionType } from '../TransactionModal';
import { AssetRow } from '../types';
import NetWorth from '../NetWorth';

import { balanceFormatter } from '@/utils/format';
import { StyledDataGrid, StyledDataGridColumn } from '@/components';
import { AssetInfo, AssetsData } from '@/hooks/types';
import { sumAssetsValue } from '@/utils/calculations';
import { useDevice, useGAEventTrackerByPlatform, useModal } from '@/hooks';

const ActionButton = styled(Button)`
  ${({ theme }) => theme.breakpoints.down('md')`
    width: 100%;
  `};
`;

interface Props {
  isReady: boolean;
  xcmAssets?: AssetInfo[];
  relayChainAssets?: AssetInfo[];
  assetsPrice?: AssetsData;
}

const RelayChainAssetsTable: FC<Props> = ({
  isReady,
  xcmAssets = [],
  relayChainAssets = [],
  assetsPrice
}) => {
  const { isMobile } = useDevice();
  const { openModal: openDepositModal, holder: depositModalHolder } = useModal(
    TransactionModal,
    { size: '500px', title: `Deposit ${relayChainAssets[0]?.symbol} to Parallel` },
    { action: ActionType.DEPOSIT }
  );

  const GAEventTrackerByPlatform = useGAEventTrackerByPlatform();
  const transferToParallelClick = useCallback(
    (asset: string | undefined) => {
      GAEventTrackerByPlatform('transfer_to_parallel', asset);
      openDepositModal();
    },
    [GAEventTrackerByPlatform, openDepositModal]
  );

  const columns = useMemo<StyledDataGridColumn<AssetRow>[]>(
    () => [
      {
        name: 'name',
        title: 'Asset',
        width: '1fr',
        isAvatar: true,
        render: ({ data: { name } }) => <CryptoAsset symbol={name} showName />
      },
      {
        name: 'balance',
        title: (
          <Inline gap="0.3rem" alignItems="center">
            <Text>Balance</Text>
            <Tooltip content="This is your available balance that can used for transfer" />
          </Inline>
        ),
        width: '1fr',
        render: ({ data: { availableBalance } }) => balanceFormatter(availableBalance)
      },
      {
        name: 'value',
        title: 'Value',
        width: '1fr',
        render: ({ data: { availableBalance, price } }) =>
          formatNumber(availableBalance * price, { output: 'currency' }),
        hidden: isMobile
      },
      {
        name: 'action',
        title: 'Action',
        hidden: isMobile,
        width: '1fr',
        render: ({ data: row }) => {
          const disableButton = !(row.availableBalance > 0 && row.enableXcm);
          const tokenDisabled = !['DOT', 'KSM'].includes(row.symbol);
          return (
            <Tooltip
              disabled={!(disableButton || tokenDisabled)}
              content="Transfer is currently not enabled for this asset."
            >
              <ActionButton
                skin="secondary"
                onClick={() => transferToParallelClick(row.symbol)}
                disabled={disableButton || tokenDisabled}
              >
                Transfer to Parallel
              </ActionButton>
            </Tooltip>
          );
        }
      }
    ],
    [isMobile, transferToParallelClick]
  );

  const rows: AssetRow[] = relayChainAssets
    .map(asset => {
      const xcmAsset = xcmAssets.find(v => v.symbol === asset.symbol);
      return {
        ...asset,
        assetId: xcmAsset?.assetId,
        name: asset.symbol,
        price: (xcmAsset && assetsPrice?.[xcmAsset.assetId]) || 0,
        enableXcm: !!xcmAsset
      } as AssetRow;
    })
    .filter(({ availableBalance }) => availableBalance > 0);

  const relayChainBalance = useMemo(() => {
    const walletBalance = relayChainAssets.reduce((items, value) => {
      const xcmAsset = xcmAssets.find(v => v.symbol === value.symbol);
      return xcmAsset
        ? {
            ...items,
            [xcmAsset.assetId]: value.availableBalance
          }
        : items;
    }, {});
    return sumAssetsValue(walletBalance, assetsPrice);
  }, [assetsPrice, relayChainAssets, xcmAssets]);

  return (
    <>
      <NetWorth title="Relaychain Balance" asset={relayChainBalance} debt={0} />
      <StyledDataGrid<AssetRow>
        loading={!isReady}
        columns={columns}
        data={rows}
        noDataMessage="There are no assets on relay chain."
      />
      {depositModalHolder}
    </>
  );
};

export default memo(RelayChainAssetsTable);
