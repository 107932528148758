import { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { chain, isEmpty, isEqual, set, zipWith } from 'lodash';
import BigNumber from 'bignumber.js';
import { useInterval } from 'react-use';
import { Pool } from '@parallel-finance/types/interfaces';
import { Option } from '@parallel-finance/types';

import { useCurrentAccountAssetInfos } from './AccountAssetInfosContext';

import config from '@/config';
import { useChainConnections } from '@/hooks/useChainConnection';
import { AssetInfo, AssetsData } from '@/hooks/types';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { chainAssetIds } from '@/utils/constants';
import { oracleToNumber } from '@/utils/utils';
import { useApiCall } from '@/hooks';

type ContextValue = AssetsData | undefined;

export const AssetPriceContext = createContext<ContextValue>(undefined);

export const AssetPriceContextProvider = memo(({ children }) => {
  const {
    parachain: { api }
  } = useChainConnections();
  const { assetIds, assetInfos } = useCurrentAccountAssetInfos();
  const [state, setState] = useState<AssetsData>();

  const { relayAssetId, nativeAssetId } = chainAssetIds[config.chain];

  const getPriceRatio = useCallback(
    async (otherAssetId: string) => {
      if (relayAssetId === otherAssetId) {
        return 1;
      }
      const [baseAssetId, quoteAssetId] =
        parseInt(relayAssetId, 10) > parseInt(otherAssetId, 10)
          ? [relayAssetId, otherAssetId]
          : [otherAssetId, relayAssetId];
      const isQuoteAsset = otherAssetId === quoteAssetId;
      const pool = await api.query.amm.pools(baseAssetId, quoteAssetId);

      // extract decimals from asset information - used to scale pool depth
      const baseDecimals = assetInfos.find(asset => asset.assetId === baseAssetId)?.decimals!;
      const quoteDecimals = assetInfos.find(asset => asset.assetId === quoteAssetId)?.decimals!;

      // eslint-disable-next-line no-nested-ternary
      return pool?.isSome && baseDecimals && quoteDecimals
        ? isQuoteAsset
          ? balanceToAmountByDecimal<BigNumber>(pool.unwrap().baseAmount, baseDecimals, 'bigNumber')
              .div(
                balanceToAmountByDecimal<BigNumber>(
                  pool.unwrap().quoteAmount,
                  quoteDecimals,
                  'bigNumber'
                )
              )
              .toNumber()
          : balanceToAmountByDecimal<BigNumber>(
              pool.unwrap().quoteAmount,
              quoteDecimals,
              'bigNumber'
            )
              .div(
                balanceToAmountByDecimal<BigNumber>(
                  pool.unwrap().baseAmount,
                  baseDecimals,
                  'bigNumber'
                )
              )
              .toNumber()
        : 0;
    },
    [api.query.amm, relayAssetId, assetInfos]
  );

  const lpTokens = useMemo(
    () => assetInfos?.filter(asset => asset.symbol.startsWith('LP-')) || [],
    [assetInfos]
  );

  const lpTokenMappings = useMemo(
    () =>
      lpTokens
        .map(token => {
          const symbols = token.symbol.replace('LP-', '').split(/\/(.*)/s);
          const assets = symbols.map(symbol => assetInfos.find(asset => asset.symbol === symbol));
          const baseAsset =
            assets.find(asset => asset?.assetId === relayAssetId) ??
            assets.find(asset => asset?.assetId === nativeAssetId);
          const otherAsset = assets.find(asset => asset?.assetId !== baseAsset?.assetId);
          return (
            baseAsset &&
            otherAsset && {
              token,
              baseAsset,
              otherAsset
            }
          );
        })
        .filter(Boolean) as { token: AssetInfo; baseAsset: AssetInfo; otherAsset: AssetInfo }[],
    [assetInfos, lpTokens, nativeAssetId, relayAssetId]
  );

  const lpTokenDetails = useApiCall<Option<any>[]>(api?.query?.assets?.asset?.multi, [
    lpTokenMappings.map(mapping => mapping.token.assetId)
  ]);
  const lpTokenPools = useApiCall<Option<Pool>[]>(api?.query?.amm?.pools?.multi, [
    lpTokenMappings.map(mapping => [mapping.baseAsset.assetId, mapping.otherAsset.assetId])
  ]);
  const lpTokenPoolsReverse = useApiCall<Option<Pool>[]>(api?.query?.amm?.pools?.multi, [
    lpTokenMappings.map(mapping => [mapping.otherAsset.assetId, mapping.baseAsset.assetId])
  ]);

  const lpTokenInfos = useMemo(() => {
    if (lpTokenDetails && lpTokenPools && lpTokenPoolsReverse) {
      return zipWith(
        lpTokenMappings,
        lpTokenDetails,
        lpTokenPools,
        lpTokenPoolsReverse,
        ({ token, baseAsset, otherAsset }, tokenDetail, tokenPool, reverseTokenPool) => {
          const supply = tokenDetail?.isSome ? tokenDetail.unwrap().supply : undefined;
          const definedPool = [tokenPool, reverseTokenPool].find(p => p?.isSome);
          const amount =
            definedPool &&
            (parseInt(baseAsset.assetId, 10) > parseInt(otherAsset.assetId, 10)
              ? definedPool.unwrap().baseAmount
              : definedPool.unwrap().quoteAmount);
          return (
            supply &&
            amount && {
              id: token.assetId,
              supply: balanceToAmountByDecimal<number>(supply, token.decimals, 'number'),
              baseAssetAmount: balanceToAmountByDecimal<number>(
                amount,
                baseAsset.decimals,
                'number'
              ),
              baseAssetId: baseAsset.assetId
            }
          );
        }
      ).filter(Boolean) as {
        id: string;
        supply: number;
        baseAssetAmount: number;
        baseAssetId: string;
      }[];
    }
    return [];
  }, [lpTokenDetails, lpTokenMappings, lpTokenPools, lpTokenPoolsReverse]);

  const fetchPrices = useCallback(async () => {
    if (assetIds && !isEmpty(lpTokenInfos)) {
      try {
        const prices = await api.rpc.oracle.getAllValues('Aggregated');
        const result = chain(prices)
          .map(v => ({
            key: v[0].toString(),
            value: v[1].isSome ? oracleToNumber(v[1].unwrap().value) : 0
          }))
          .keyBy(v => v.key)
          .mapValues(v => v.value)
          .value();

        await Promise.all(
          assetIds.map(async assetId => {
            if (
              !result[assetId] &&
              lpTokenInfos.every(({ id: lpTokenId }) => lpTokenId !== assetId)
            )
              result[assetId] = (result[relayAssetId] ?? 0) * (await getPriceRatio(assetId));
          })
        );

        lpTokenInfos.forEach(token =>
          set(
            result,
            token.id,
            (token.baseAssetAmount * (result[token.baseAssetId] ?? 0) * 2) / token.supply
          )
        );

        setState(prevState => (isEqual(prevState, result) ? prevState : result));
      } catch (e) {
        setState({});
        console.error('Fetch price error:', e);
      }
    }
  }, [assetIds, lpTokenInfos, api.rpc.oracle, relayAssetId, getPriceRatio]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  useInterval(fetchPrices, 20 * 1000);

  return <AssetPriceContext.Provider value={state}>{children}</AssetPriceContext.Provider>;
});
