import { KeyringPair } from '@polkadot/keyring/types';
import { ApiPromise } from '@polkadot/api';
import { ReactNode } from 'react';

import { subscribe, TrackFn } from '../helpers/subscribe';
import { Observer } from '../helpers/Observer';
import { calculateCommonErrorMessage } from '../helpers/calculateErrorMessage';
import { Currency } from '../types';

import { AssetDetailInfo } from '@/hooks/types';

export type ChainEvents = 'metadataChange';

export type ChainName = { name: string; label: string };

export type ValidationParams = {
  fromChain: ChainName;
  toChain: ChainName;
  transferAmount: number;
  maximumTransferAmount: number;
  minimumTransferAmount: number;
  currency: Currency;
  fromChainAssets: AssetDetailInfo[];
  toChainAssets: AssetDetailInfo[];
};
export abstract class AbstractChain<K extends Record<string, any>> {
  api: ApiPromise;

  paraApi: ApiPromise;

  account: KeyringPair;

  observer: Observer<ChainEvents>;

  metadata: K;

  private metadataSubscriber;

  constructor(api: ApiPromise, account: KeyringPair, paraApi: ApiPromise) {
    this.api = api;
    this.paraApi = paraApi;
    this.account = account;
    this.observer = new Observer<ChainEvents>();
    this.metadataSubscriber = new Map();
    this.metadata = {} as K;
  }

  abstract getTxFee(...args: any): Promise<{ fee: number; currency: string }>;

  abstract calculateAssetsInfo(...args: any): Promise<any>;

  abstract transfer(...args: any): void;

  // eslint-disable-next-line class-methods-use-this
  getExtraInfos = (..._args: any): Record<string, any>[] => {
    return [];
  };

  // eslint-disable-next-line class-methods-use-this
  validate = ({
    fromChain,
    toChain,
    transferAmount,
    maximumTransferAmount,
    minimumTransferAmount,
    fromChainAssets,
    toChainAssets,
    currency
  }: ValidationParams): ReactNode => {
    const fromChainNativeAsset = fromChainAssets.find(asset => asset.isNative);
    const toChainNativeAsset = toChainAssets.find(asset => asset.isNative);
    return calculateCommonErrorMessage({
      fromChain,
      toChain,
      transferAmount,
      maximumTransferAmount,
      minimumTransferAmount,
      fromChainNativeAsset: fromChainNativeAsset!,
      toChainNativeAsset: toChainNativeAsset!,
      transferCurrency: currency
    });
  };

  // eslint-disable-next-line class-methods-use-this
  validateWarning = (_validateParams: ValidationParams): ReactNode => {
    return null;
  };

  subscribeValue = (
    fn: TrackFn,
    fnParams: any[],
    subscribeKey: keyof K,
    eventName: ChainEvents = 'metadataChange'
  ) => {
    const callback = (data: any) => {
      this.metadata[subscribeKey] = data;
      this.observer.emit(eventName);
    };
    const serialized = JSON.stringify({ f: fn.toString(), p: fnParams });
    if (!this.metadataSubscriber.has(serialized)) {
      const subscriber = subscribe(fn, fnParams, callback);
      this.metadataSubscriber.set(serialized, subscriber);
    }
  };

  unsubscribeValue = () => {
    this.metadataSubscriber.forEach(value => {
      value
        .then((unsubFn: () => void) => {
          unsubFn();
        })
        .catch(console.error);
    });
  };
}
