// @ts-nocheck
/* eslint-disable */

export const tokenSnapshots = (historicalToken, rates, dailyVolumes) => {
  if (historicalToken && rates && dailyVolumes) {
    const snapshotMassage = Object.entries(historicalToken).map((item, i) => {
      const activeVolumes = dailyVolumes.filter(x => x?.tokenVolume?.[item[0]]);
      const rate = rates[item[0]];
      const days = <any>item[1];
      const dailyVolume = activeVolumes[0]?.tokenVolume?.[item[0]];
      const currentPrice = rate?.[rate?.length - 1]?.val;
      const previousPrice = rate?.[rate?.length - 2]?.val;
      return {
        ticker: [item[0]],
        price: rate?.[rate?.length - 1]?.val,
        previousPrice: previousPrice || currentPrice,
        dVolumen: dailyVolume * currentPrice,
        tvl: days?.[days?.length - 1].val * rate?.[rate?.length - 1]?.val
      };
    });
    return snapshotMassage;
  }
}
