import { Inline, Card, Text, Button, Icon } from '@parallel-mono/components';
import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ReminderWrapper = styled(Card).attrs({ inset: '0' })`
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--clr-gray04);
`;

type Props = {
  token: string;
};
const UnStakeReminder: FC<Props> = ({ token }) => {
  return (
    <ReminderWrapper>
      <Inline gap="1.125rem" inset="1.125rem" alignItems="flex-start">
        <Icon name="info" size="medium" style={{ flex: '0 0 20px' }} />
        <Text>
          You may not be seeing your full staked balance because you have supplied {token} to Money
          Market to earn farming rewards. Withdraw from{' '}
          <NavLink to="/lendAndBorrow">
            <Button variant="link">Money Market</Button>
          </NavLink>{' '}
          first to unstake.
        </Text>
      </Inline>
    </ReminderWrapper>
  );
};

export default UnStakeReminder;
