import { createContext, memo, useContext } from 'react';

import { useAccount, useNativeAssetInfo } from '@/hooks';
import { AssetInfo } from '@/hooks/types';

type ContextValue = { isReady: boolean; nativeAssetInfo: AssetInfo };

const NativeAssetInfoContext = createContext<ContextValue>({
  isReady: false,
  nativeAssetInfo: {} as AssetInfo
});

export const NativeAssetInfoContextProvider = memo(({ children }) => {
  const { account } = useAccount();
  const nativeAssetInfo = useNativeAssetInfo(account?.address ?? '');

  return (
    <NativeAssetInfoContext.Provider value={nativeAssetInfo}>
      {children}
    </NativeAssetInfoContext.Provider>
  );
});

export const useCurrentAccountNativeAssetInfo = () => useContext(NativeAssetInfoContext);
