import { useContext, memo, FC } from 'react';
import styled from 'styled-components';
import { Inline, H5, Button } from '@parallel-mono/components';

import { StakingScopeContext } from './Context';

import config from '@/config';

const ValidatorBar = styled(Inline)`
  background: #fff;
  border-radius: 12px;
  box-shadow: var(--box-shadow02);
  padding: 24px 32px;
  .light {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--clr-green-dark);
  }
`;

const relayChainName = config.isParallel ? 'polkadot' : 'kusama';

const Validators: FC<{}> = () => {
  const { nominationLength } = useContext(StakingScopeContext);

  return nominationLength > 0 ? (
    <ValidatorBar justifyContent="space-between">
      <Inline alignItems="center" gap="4px">
        <i className="light" />
        <H5>{nominationLength} Validators</H5>
      </Inline>
      <Button
        variant="link"
        onClick={() => {
          window.open(`https://analytics.parallel.fi/${relayChainName}/staking`, '_blank');
        }}
      >
        View
      </Button>
    </ValidatorBar>
  ) : null;
};

export default memo(Validators);
