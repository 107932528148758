import { FC } from 'react';
import { Button, ButtonProps } from '@parallel-mono/components';
import cx from 'classnames';

import WalletConnectionModal from './WalletConnectionModal';

import { useModal } from '@/hooks';

interface ConnectToWalletProps extends ButtonProps {
  action?: () => void;
}

const ConnectToWallet: FC<ConnectToWalletProps> = ({ action, children, ...props }) => {
  const { openModal, holder } = useModal(WalletConnectionModal, {
    closable: false,
    size: '410px',
    classNames: {
      modal: cx('compact'),
      header: cx('compact')
    }
  });

  const startConnect = async () => {
    action?.();
    return openModal();
  };

  return (
    <>
      <Button onClick={startConnect} {...props}>
        {children}
      </Button>
      {holder}
    </>
  );
};

export default ConnectToWallet;
