// @ts-nocheck
/* eslint-disable */
import { sum, values } from 'lodash';

import { massageArrayToGetTvl, sortArrayByDate } from '../helpers/others';

export const calculateVolume = (AllSwaps, dailyVolumes, poolInfo) => {
  if (AllSwaps && dailyVolumes && poolInfo) {
    const volumes = sortArrayByDate(dailyVolumes);
    const currentTvl = massageArrayToGetTvl(AllSwaps, 'assets', 'val');
    const VolumeData = sortArrayByDate(currentTvl)?.map((item, i) => {
      return {
        ...item,
        originalValue: item.val,
        val: sum(values(volumes[i]?.poolVolume))
      };
    });
    return VolumeData;
  }
};
