import { get } from 'lodash';

import { WalletType } from '@/contexts/WalletsContext';

const walletExtensionNameMapping: Record<WalletType, string> = {
  [WalletType.Polkadot]: 'polkadot-js',
  [WalletType.Parallel]: 'parallel-wallet',
  [WalletType.SubWallet]: 'subwallet-js'
};

export const isWalletInstalled = async (walletType: WalletType): Promise<boolean> => {
  const extensionName = walletExtensionNameMapping[walletType];
  const maxRetryTimes = 10;
  const getInjectedWeb3 = (retry: number): Promise<boolean> =>
    new Promise(resolve => {
      if (get(window, `injectedWeb3.${extensionName}`)) {
        resolve(true);
      } else if (retry > 0) {
        setTimeout(() => {
          resolve(getInjectedWeb3(retry - 1));
        }, 500);
      } else {
        resolve(false);
      }
    });
  return getInjectedWeb3(maxRetryTimes);
};
