import { memo, forwardRef, ReactNode, HTMLAttributes, CSSProperties } from 'react';
import styled from 'styled-components';

const Container = styled.div<CardProps>`
  background-color: ${({ theme }) => theme.skin.background.main};
  border-radius: ${({ theme }) => theme.border.radius.large};
  padding: ${({ inset }) => inset ?? '2rem'};
  box-shadow: ${({ shadowType, theme }) => theme.shadows[shadowType ?? 'primary']};
  ${({ theme }) => theme.breakpoints.down('md')`
    padding: ${theme.spacing(4)};
  `};
  border: ${({ theme, border }) =>
    border ? `${theme.border.width.small} solid ${theme.skin.grey[200]}` : 'none'};
`;

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  shadowType?: 'primary' | 'secondary';
  inset?: CSSProperties['padding'];
  children: ReactNode;
  border?: boolean;
}

const CardComponent = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  return <Container ref={ref} {...props} />;
});

export const Card = memo(CardComponent);
