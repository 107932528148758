import { omit } from 'lodash';
import { HTMLAttributes, ReactNode, MouseEvent } from 'react';
import { withComponentThemeOverride } from '../../HOC/withComponentThemeOverride';
import { genericMemo } from '../../utils/genericMemo';
import { PaginationProps } from '../Pagination';
import { useBreakpoints } from '../ThemeProvider';
import { DesktopGrid } from './DesktopGrid';
import { HeaderRowProps, TitleProps } from './DesktopGrid/GridHeader';
import { MobileGrid } from './MobileGrid';
import {
  DataGridColumn,
  DataGridClassNames,
  ExpandableRenderContext,
  RowClickEventContext,
  DataCardHeaderRenderContext,
} from './types';

export type DataGridProps<R = object> = Omit<HTMLAttributes<HTMLTableElement>, 'title'> & {
  columns: DataGridColumn<R>[];
  data: R[];
  classNames?: DataGridClassNames;
  expandable?: {
    renderExpandable: (context: ExpandableRenderContext<R>) => ReactNode;
    rowExpandable: (row: R) => boolean;
  };
  onRowClick?: (row: RowClickEventContext<R>, event: MouseEvent) => void;
  loading?: boolean;
  loadingMessage?: ReactNode;
  noDataMessage?: ReactNode;
  pageSize?: number;
  headerProps?: HeaderRowProps;
  titleProps?: TitleProps;
  renderPaginationItems?: PaginationProps['renderPaginationItems'];
  renderDataCardHeader?: (context: DataCardHeaderRenderContext<R>) => ReactNode;
  title?: ReactNode;
  hideColumnsTitle?: boolean;
};

const DataGridRaw = genericMemo(function <R = object>(props: DataGridProps<R>) {
  const { mobile } = useBreakpoints();

  if (mobile) {
    const mobileGridProps = omit(props, 'headerProps', 'titleProps', 'title', 'hideColumnsTitle');
    return <MobileGrid {...mobileGridProps} />;
  }

  return <DesktopGrid {...props} />;
});

export const DataGrid = withComponentThemeOverride(DataGridRaw, 'DataGrid');
