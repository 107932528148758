import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { u128, u32 } from '@polkadot/types';
import { Balance, RewardMarketState } from '@parallel-finance/types/interfaces';
import { BN } from '@polkadot/util';

import { useMarketAssetsStatusContext } from '../context';

import useUserMarketDetails from './useUserMarketDetails';
import useMarketAssets from './useMarketAssets';

import { AssetsData, CurrencyId } from '@/hooks/types';
import { useAccount, useApiCall, useAssetPrices, useChainConnections } from '@/hooks';
import config from '@/config';
import { balanceToAmountByDecimal, getCompoundInterestApy } from '@/utils/calculations';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

const REWARD_SCALE = 1e12;

const unCalRewardFormula = (
  currentBlock: BN,
  rewardMarketState: RewardMarketState,
  rewardPerBlock: u128,
  totalValue: string,
  userRewardIndex: u128,
  value: string
) => {
  const { block, index } = rewardMarketState;
  const deltaBlock = currentBlock.sub(block).toNumber();
  const deltaIndex = new BigNumber(deltaBlock)
    .multipliedBy(rewardPerBlock.toString())
    .multipliedBy(REWARD_SCALE)
    .dividedBy(totalValue.toString());
  const newDeltaIndex = deltaIndex.plus(index.toString()).minus(userRewardIndex.toString());
  const result = newDeltaIndex.multipliedBy(value.toString()).dividedBy(REWARD_SCALE);
  return result.isFinite() && result.isGreaterThan(0) ? result : new BigNumber(0);
};

type UserUnclaimedEachRewards = Record<CurrencyId, { supply: string; borrow: string }>;

const useRewardValues = () => {
  const [isReady, setIsReady] = useState(false);
  const [userUnclaimedRewardValue, setUserUnclaimedRewardValue] = useState<number | null>(null);
  const [supplyRewardApys, setSupplyRewardApys] = useState<AssetsData>({});
  const [borrowRewardApys, setBorrowRewardApys] = useState<AssetsData>({});
  const [userUnclaimedEachRewards, setUserUnclaimedEachRewards] =
    useState<UserUnclaimedEachRewards>({});
  const {
    parachain: { api }
  } = useChainConnections();
  const { marketAssetIds } = useMarketAssets();
  const assetsPrice = useAssetPrices();
  const { nativeAssetInfo: nativeToken } = useCurrentAccountNativeAssetInfo();
  const { account } = useAccount();
  const userMarketDetails = useUserMarketDetails(marketAssetIds);
  const marketStatus = useMarketAssetsStatusContext();

  const rewardAssetDecimals = useMemo(
    () => (nativeToken && nativeToken.decimals ? nativeToken.decimals : 0),
    [nativeToken]
  );

  const currBlockNumber = useApiCall<u32>(api.query.system.number, []);

  // supply storage

  const totalSupplies = useApiCall<Balance[]>(marketAssetIds && api.query.loans.totalSupply.multi, [
    marketAssetIds
  ]);
  const supplyRewardPerBlock = useApiCall<u128[]>(
    marketAssetIds && api.query.loans.rewardSupplySpeed.multi,
    [marketAssetIds]
  );

  const userRewardSupplyIndex = useApiCall<u128[]>(
    marketAssetIds && account && api.query.loans.rewardSupplierIndex.multi,
    [marketAssetIds?.map(assetId => [assetId, account?.address])]
  );

  const supplyMarketState = useApiCall<RewardMarketState[]>(
    marketAssetIds && api.query.loans.rewardSupplyState.multi,
    [marketAssetIds]
  );

  // borrow storage
  const totalBorrows = useApiCall<Balance[]>(marketAssetIds && api.query.loans.totalBorrows.multi, [
    marketAssetIds
  ]);
  const borrowRewardPerBlock = useApiCall<u128[]>(
    marketAssetIds && api.query.loans.rewardBorrowSpeed.multi,
    [marketAssetIds]
  );

  const userRewardBorrowIndex = useApiCall<u128[]>(
    marketAssetIds && account && api.query.loans.rewardBorrowerIndex.multi,
    [marketAssetIds?.map(assetId => [assetId, account?.address])]
  );

  const borrowMarketState = useApiCall<RewardMarketState[]>(
    marketAssetIds && api.query.loans.rewardBorrowState.multi,
    [marketAssetIds]
  );

  const calculatedReward = useApiCall<u128>(account && api.query.loans.rewardAccured, [
    account?.address
  ]);

  const calculatedApy = useCallback(
    (id: string, rewardPerBlock: u128, totalBalance: number, prices: AssetsData) => {
      const value = new BigNumber(totalBalance).multipliedBy(prices[id]);
      const rewardPerBlockValue = balanceToAmountByDecimal<BigNumber>(
        rewardPerBlock,
        nativeToken.decimals,
        'bigNumber'
      ).multipliedBy(prices[nativeToken.assetId]);

      const ratePerDay = rewardPerBlockValue.dividedBy(value).multipliedBy(config.blockPerDay);
      const compoundInterestApy = getCompoundInterestApy(ratePerDay);
      return Number.isFinite(compoundInterestApy) && compoundInterestApy > 0
        ? compoundInterestApy
        : null;
    },
    [nativeToken.assetId, nativeToken.decimals]
  );

  useEffect(() => {
    if (assetsPrice && !isEmpty(marketStatus) && supplyRewardPerBlock && marketAssetIds) {
      const supplyApys = marketAssetIds.reduce((set, id, index) => {
        const supplyApy = calculatedApy(
          id,
          supplyRewardPerBlock[index],
          marketStatus[id].totalSupply,
          assetsPrice
        );
        return {
          ...set,
          [id]: supplyApy
        };
      }, {});
      setSupplyRewardApys(supplyApys);
    }
  }, [assetsPrice, calculatedApy, marketAssetIds, marketStatus, supplyRewardPerBlock]);

  useEffect(() => {
    if (assetsPrice && marketStatus && marketAssetIds && borrowRewardPerBlock) {
      const borrowApys = marketAssetIds.reduce((set, id, index) => {
        const rewardPerBlock = borrowRewardPerBlock[index];
        const borrowApy = calculatedApy(
          id,
          rewardPerBlock,
          marketStatus[id].totalBorrow,
          assetsPrice
        );
        return {
          ...set,
          [id]: borrowApy
        };
      }, {});
      setBorrowRewardApys(borrowApys);
    }
  }, [assetsPrice, calculatedApy, borrowRewardPerBlock, marketAssetIds, marketStatus]);

  useEffect(() => {
    if (marketAssetIds && !isEmpty(userUnclaimedEachRewards)) {
      const unCalcTotalRewards = marketAssetIds.reduce((sum, id) => {
        sum = sum
          .plus(userUnclaimedEachRewards[id].borrow)
          .plus(userUnclaimedEachRewards[id].supply);
        return sum;
      }, new BigNumber(0));
      // User Unclaimed Reward Value
      const totalUnclaimedReward = balanceToAmountByDecimal<number>(
        unCalcTotalRewards.plus(calculatedReward?.toString() || '0'),
        rewardAssetDecimals,
        'number'
      );
      setUserUnclaimedRewardValue(totalUnclaimedReward);
    }
  }, [calculatedReward, marketAssetIds, rewardAssetDecimals, userUnclaimedEachRewards]);

  useEffect(() => {
    if (
      marketAssetIds &&
      currBlockNumber &&
      supplyMarketState &&
      supplyRewardPerBlock &&
      userRewardSupplyIndex &&
      borrowMarketState &&
      borrowRewardPerBlock &&
      userRewardBorrowIndex &&
      totalSupplies &&
      totalBorrows &&
      userMarketDetails
    ) {
      const userAssetsRewards = marketAssetIds.reduce((result, assetId, index) => {
        const unCalcSupplyReward = unCalRewardFormula(
          currBlockNumber,
          supplyMarketState[index],
          supplyRewardPerBlock[index],
          totalSupplies[index].toString(),
          userRewardSupplyIndex[index],
          userMarketDetails[assetId].voucherBalance
        );

        const unCalcBorrowReward = unCalRewardFormula(
          currBlockNumber,
          borrowMarketState[index],
          borrowRewardPerBlock[index],
          totalBorrows[index].toString(),
          userRewardBorrowIndex[index],
          userMarketDetails[assetId]?.borrowBalance
        );
        result[assetId] = {
          supply: unCalcSupplyReward.toString(),
          borrow: unCalcBorrowReward.toString()
        };
        return result;
      }, {} as UserUnclaimedEachRewards);

      setIsReady(true);
      setUserUnclaimedEachRewards(userAssetsRewards);
    }
  }, [
    borrowMarketState,
    borrowRewardPerBlock,
    calculatedReward,
    currBlockNumber,
    marketAssetIds,
    rewardAssetDecimals,
    supplyMarketState,
    supplyRewardPerBlock,
    totalBorrows,
    totalSupplies,
    userMarketDetails,
    userRewardBorrowIndex,
    userRewardSupplyIndex
  ]);

  return useMemo(
    () => ({
      isReady,
      userUnclaimedRewardValue,
      supplyRewardApys,
      borrowRewardApys,
      userUnclaimedEachRewards
    }),
    [
      isReady,
      userUnclaimedRewardValue,
      supplyRewardApys,
      borrowRewardApys,
      userUnclaimedEachRewards
    ]
  );
};

export default useRewardValues;
