import { Chains } from '../types';

import { PolkadotChainCurrency } from './types';

import config, { Chain } from '@/config';

export const CURRENCIES = {
  [Chain.HEIKO]: { [Chains.Parallel]: [PolkadotChainCurrency.KSM] },
  [Chain.PARALLEL]: { [Chains.Parallel]: [PolkadotChainCurrency.DOT] }
}[config.chain];

export const TO_CHAINS = [Chains.Parallel];

export const MINIMUM_TRANSFER_AMOUNT = {
  [Chain.PARALLEL]: {
    [PolkadotChainCurrency.DOT]: 1.5
  },
  [Chain.HEIKO]: {
    [PolkadotChainCurrency.KSM]: 0.015
  }
}[config.chain];

export const MAXIMUM_TX_FEE = config.relayChainMaxTxFee;
