import { memo, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Stack } from '@parallel-mono/components';

import LendAndBorrowTable from './LendAndBorrowTable';
import { sortAssetsBySpecificRule } from './utils';
import { Summary } from './components/Summary';
import {
  useAccountAssetsBorrow,
  useAccountAssetsDeposit,
  useAccountAssetsEarned,
  useLoanAssets,
  useMarketAssets,
  useMarketTVL,
  useRewardValues
} from './hooks';
import LoadingStatus from './components/LoadingStatus';
import MyLendPositions from './Lend/MyLendPositions';
import MyBorrowedPositions from './Borrowed/MyBorrowedPositions';
import { MarketStatusContextProvider, useMarketAssetsStatusContext } from './context';

import config, { Chain } from '@/config';
import { sumAssetsValue } from '@/utils/calculations';
import { Chains, useAssetPrices, useDevice } from '@/hooks';
import PageWrapper from '@/pages/PageWrapper';
import { useCurrentAccountAssetInfos } from '@/contexts/AssetsInfoContext';
import MyPositions from '@/pages/MoneyMarket/MyPositions';

const MoneyMarket = () => {
  const { marketAssetIds, assetsMarket } = useMarketAssets();

  const { assetInfos, isReady: assetInfosIsReady } = useCurrentAccountAssetInfos();
  const assetsPrice = useAssetPrices();
  const accountEarned = useAccountAssetsEarned(marketAssetIds);
  const { supplies, collaterals } = useAccountAssetsDeposit(marketAssetIds);
  const accountBorrow = useAccountAssetsBorrow(marketAssetIds);
  const { isDesktop } = useDevice();
  const { loanAssets } = useLoanAssets(marketAssetIds);
  const marketStatus = useMarketAssetsStatusContext();
  const {
    isReady: RewardValuesIsReady,
    supplyRewardApys,
    borrowRewardApys,
    userUnclaimedRewardValue
  } = useRewardValues();
  const totalValue = useMarketTVL();

  const earned = useMemo(() => {
    if (accountEarned && assetsPrice) {
      return sumAssetsValue(accountEarned, assetsPrice);
    }
    return null;
  }, [accountEarned, assetsPrice]);

  const tableProps = {
    accountEarned,
    assetInfos: sortAssetsBySpecificRule(assetInfos, config),
    assetsMarket,
    assetsPrice,
    loanAssets,
    marketStatus,
    collaterals,
    accountBorrow,
    supplyRewardApys,
    borrowRewardApys,
    accountSupplies: supplies
  };

  const dataIsReady = useMemo(() => {
    return !!(
      assetInfosIsReady &&
      RewardValuesIsReady &&
      assetsPrice &&
      marketStatus &&
      assetsMarket
    );
  }, [RewardValuesIsReady, assetInfosIsReady, assetsMarket, assetsPrice, marketStatus]);

  const isHeiko = config.chain === Chain.HEIKO;

  return (
    <Stack gap="1.5rem">
      <Summary
        totalValue={totalValue}
        userUnclaimedReward={userUnclaimedRewardValue}
        earned={earned}
      />
      {isHeiko && <MyPositions />}
      {!isHeiko && isDesktop && !isEmpty(supplies) && (
        <MyLendPositions
          key="myLendPosition"
          dataIsReady={dataIsReady && !!supplies}
          {...tableProps}
        />
      )}
      {!isHeiko && isDesktop && !isEmpty(accountBorrow) && (
        <MyBorrowedPositions
          key="MyBorrowedPositions"
          dataIsReady={dataIsReady && !!accountBorrow}
          {...tableProps}
        />
      )}
      <LendAndBorrowTable dataIsReady={dataIsReady} {...tableProps} />
    </Stack>
  );
};

export default memo(() => (
  <PageWrapper
    initialChains={[Chains.ParaChain, Chains.RelayChain]}
    loadingComponent={<LoadingStatus />}
  >
    <MarketStatusContextProvider>
      <MoneyMarket />
    </MarketStatusContextProvider>
  </PageWrapper>
));
