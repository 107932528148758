import { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
import { createMuiTheme, TextField } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { Wrapper, ErrorText } from '@/components';

const theme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiInputBase: {
      input: {
        width: '100%',
        height: '1.625rem',
        'min-width': 0,
        padding: 0,
        outline: 'none',
        background: 'none',
        color: 'var(--clr-text01)',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0
        },
        '&::placeholder': {
          color: 'var(--clr-text03)'
        },
        '-moz-appearance': 'textfield'
      }
    },
    MuiInput: {
      underline: {
        '&:before, &:after': {
          display: 'none'
        }
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200
      }
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: lightBlue.A200
      }
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700
      },
      daySelected: {
        backgroundColor: lightBlue['400']
      },
      dayDisabled: {
        color: lightBlue['100']
      },
      current: {
        color: lightBlue['900']
      }
    },
    // hide error info
    MuiFormHelperText: {
      root: {
        display: 'none'
      }
    }
  }
});

interface DateSelectType {
  onChange: (v: MaterialUiPickersDate) => void;
  error: string;
}

const DateSelect = ({ onChange, error }: DateSelectType) => {
  const [value, setValue] = useState<any>(null);

  const onSelectChange = (newValue: MaterialUiPickersDate) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <>
      <Wrapper withError={!!error}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <KeyboardDateTimePicker
              variant="inline"
              placeholder="MM/DD/YYYY HH:MM"
              value={value}
              onChange={onSelectChange}
              format="MM/DD/YYYY HH:mm"
              disablePast
            />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Wrapper>
      {!!error && <ErrorText>{error}</ErrorText>}
    </>
  );
};

export default DateSelect;

const StyledTextField = withStyles({
  root: {
    width: '100%',
    '& input': {
      width: '100%',
      height: '1.625rem',
      'min-width': 0,
      padding: 0,
      outline: 'none',
      background: 'none',
      fontFamily: 'var(--font-family)',
      color: 'var(--clr-text01)',
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '&::placeholder': {
        color: 'var(--clr-text03)'
      },
      '-moz-appearance': 'textfield'
    },
    '& .MuiInput-underline': {
      '&:before, &:after': {
        display: 'none'
      }
    }
  }
})(TextField);

interface TextFiledProps {
  value?: string;
  onChange: (v: string) => void;
  error: string;
}

export const TextFieldDateTimePicker = ({ value, onChange, error }: TextFiledProps) => {
  const [finalValue, setFinalValue] = useState(value || '');

  const onSelectChange = ({ target: { value: newValue } }: { target: { value: string } }) => {
    setFinalValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setFinalValue(value || '');
  }, [value]);

  return (
    <>
      <Wrapper withError={!!error}>
        <StyledTextField type="datetime-local" value={finalValue} onChange={onSelectChange} />
      </Wrapper>
      {!!error && <ErrorText>{error}</ErrorText>}
    </>
  );
};
