import { reduce, get, isString, isNil } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { BN } from '@polkadot/util';

import { isNumeric } from './validate';

import config from '@/config';
import { AssetsData } from '@/hooks/types';

const getRawCurrencyDecimal = (currency: string) => get(config.rawDecimals, currency, NaN);

const isFloatLessThan = (left: number | string, right: number | string) =>
  new BigNumber(left).isLessThan(right);

const amountToBalanceByDecimals = <T extends BN | string | BigNumber | null>(
  amount: BN | number | string | BigNumber,
  decimals: BN | number | string,
  dataType: 'bn' | 'string' | 'bigNumber'
): T => {
  if (isNil(amount) || isNil(decimals)) return null as T;
  const newDecimals =
    isString(decimals) && !isNumeric(decimals) ? getRawCurrencyDecimal(decimals) : decimals;
  const base = new BigNumber(10).pow(BN.isBN(newDecimals) ? newDecimals.toNumber() : newDecimals);
  const newAmount = new BigNumber(BN.isBN(amount) ? amount.toString() : amount).multipliedBy(base);

  if (dataType === 'bn') {
    return new BN(newAmount.toFixed(0, BigNumber.ROUND_DOWN).toString()) as T;
  }

  if (dataType === 'string') {
    return newAmount.toString(10) as T;
  }

  return newAmount as T;
};

const balanceToAmountByDecimal = <T extends BN | string | number | BigNumber | null>(
  amount: BN | number | string | BigNumber,
  decimals: BN | number | string,
  dataType: 'bn' | 'string' | 'number' | 'bigNumber'
): T => {
  if (isNil(amount) || isNil(decimals)) return null as T;

  const newDecimals =
    isString(decimals) && !isNumeric(decimals) ? getRawCurrencyDecimal(decimals) : decimals;
  const base = new BigNumber(10).pow(BN.isBN(newDecimals) ? newDecimals.toNumber() : newDecimals);
  const newAmount = new BigNumber(BN.isBN(amount) ? amount.toString() : amount).dividedBy(base);

  if (dataType === 'bn') {
    return new BN(newAmount.toFixed(0, BigNumber.ROUND_DOWN).toString()) as T;
  }

  if (dataType === 'string') {
    return newAmount.toString(10) as T;
  }

  if (dataType === 'number') {
    return newAmount.toNumber() as T;
  }

  return newAmount as T;
};

const sumAssetsValue = (assets?: AssetsData, prices?: AssetsData) =>
  reduce(assets, (sum, value, key) => sum + (value || 0) * (prices?.[key] || 0), 0);

const getCompoundInterestApy = (ratePerDay: number | BigNumber) => {
  const daysPerYear = 365;
  if (Number.isFinite(ratePerDay) || (BigNumber.isBigNumber(ratePerDay) && ratePerDay.isFinite()))
    return new BigNumber(1).plus(ratePerDay).pow(daysPerYear).minus(1).toNumber();

  return 0;
};

export {
  isFloatLessThan,
  sumAssetsValue,
  amountToBalanceByDecimals,
  balanceToAmountByDecimal,
  getCompoundInterestApy
};
