import { Dispatch, FC, memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { capitalize, isNil } from 'lodash';
import { Inline, Stack, H5, Text, Button, Modal, Icon } from '@parallel-mono/components';
import { TokenInput } from '@parallel-mono/business-components';
import { BN } from '@polkadot/util';

import { isValidAddress } from '@/utils/utils';
import { signAndSend, txFee } from '@/utils/txCall';
import { useAccount, useChainConnections } from '@/hooks';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { balanceFormatter, toFloorFixed } from '@/utils/format';
import config from '@/config';
import { AssetInfo } from '@/hooks/types';
import { AssetRow, TransactionStatus } from '@/pages/Overview/types';
import { ADDRESS_MAX_LENGTH, MIN_NATIVE_TOKEN_TRANSFER_AMOUNT } from '@/utils/constants';
import { AddressInput } from '@/pages/Overview/AddressInput';
import { isValidAmountInput } from '@/utils/validate';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import { WalletContext } from '@/contexts/WalletsContext';
import { useCurrentAccountNativeAssetInfo } from '@/contexts/AssetsInfoContext';

const ContentWrapper = styled(Stack)`
  width: 100%;
`;

const StyledTokenInput = styled(TokenInput)`
  & input {
    padding: 7px 0;
  }
`;

const InputLabelWrapper = styled(Stack).attrs({
  gap: '0.5rem'
})`
  & input {
    padding: 7px 0;
  }
`;

const WarningTip = styled.div`
  display: flex;
  margin-top: 1rem;
  padding: 1rem;
  background: #f0f3fb;
  border-radius: 0.5rem;
`;

const WarningIcon = styled.div`
  margin-right: 1rem;
`;
const WarningText = styled.div`
  display: flex;
  margin-left: 0.1rem;
  font-family: 'DM Sans';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #2f2f2f;
`;

enum AmountErrorMessages {
  NativeTokenAmountInvalid = 'NATIVE_TOKEN_AMOUNT_INVALID',
  BalanceInsufficient = 'BALANCE_INSUFFICIENT'
}

type Props = {
  opened: boolean;
  asset: AssetRow;
  onClose: () => void;
  dispatchTransactionStatus: Dispatch<TransactionStatus>;
};

const TransferModal: FC<Props> = ({ opened, onClose, asset, dispatchTransactionStatus }) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState<number>();
  const [amountInputValue, setAmountInputValue] = useState<number | null>(null);
  const [maxAmount, setMaxAmount] = useState<number>();
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const { wallet } = useContext(WalletContext);
  const { account, accountList } = useAccount();
  const [amountErrorMessage, setAmountErrorMessage] = useState<AmountErrorMessages | null>(null);
  const navigate = useNavigate();

  const { TxFeeTips } = useTxFeeValidation();
  const lockedBalance = asset?.lockedBalance ?? 0;

  const {
    parachain: { api }
  } = useChainConnections();
  const { nativeAssetInfo: nativeAsset } = useCurrentAccountNativeAssetInfo();
  const addressValid = isValidAddress(address);
  const handleClose = useCallback(() => {
    setAmount(undefined);
    setAddress('');
    setAmountInputValue(null);
    onClose();
  }, [onClose]);
  const handleDepositLinkClick = useCallback(() => {
    handleClose();
    navigate('/cross-chain');
  }, [navigate, handleClose]);

  const amountErrorMessageMapping = useMemo(
    () => ({
      [AmountErrorMessages.NativeTokenAmountInvalid]: `Minimun ${config.nativeToken} amount to send is ${MIN_NATIVE_TOKEN_TRANSFER_AMOUNT}.`,
      [AmountErrorMessages.BalanceInsufficient]: (
        <Text skin="error">
          {'Insufficient balance on Parallel. '}
          {!config.isParallel && (
            <Button variant="link" skin="primary" onClick={handleDepositLinkClick}>
              Deposit
            </Button>
          )}
        </Text>
      )
    }),
    [handleDepositLinkClick]
  );

  useEffect(() => {
    setAmount(Number(amountInputValue));
  }, [amountInputValue]);

  useEffect(() => {
    const amountValue = Number(amount);
    const maxAmountValue = asset?.maxAvailableBalance ?? 0;

    setMaxAmount(maxAmountValue);
    if (amountValue > maxAmountValue) {
      setAmountErrorMessage(AmountErrorMessages.BalanceInsufficient);
    } else if (
      asset?.symbol === nativeAsset?.symbol &&
      amount &&
      amount < MIN_NATIVE_TOKEN_TRANSFER_AMOUNT
    ) {
      setAmountErrorMessage(AmountErrorMessages.NativeTokenAmountInvalid);
    } else {
      setAmountErrorMessage(null);
    }
  }, [amount, asset, lockedBalance, nativeAsset]);
  const initialAccountList = useMemo(
    () =>
      accountList.map(accountItem => ({
        address: accountItem.address,
        name: accountItem.meta.name as string,
        type: wallet!.type
      })),
    [accountList, wallet]
  );

  const sendButtonDisabled = !addressValid || !amount || Boolean(amountErrorMessage);

  const generateTx = useCallback(
    (assetInfo: AssetInfo, target: string, amountValue: number) => {
      return assetInfo.symbol === nativeAsset?.symbol
        ? api.tx.balances.transferKeepAlive(
            target,
            amountToBalanceByDecimals<BN>(amountValue, assetInfo.symbol, 'bn')
          )
        : api.tx.assets.transfer(
            assetInfo.assetId,
            target,
            amountToBalanceByDecimals<BN>(amountValue, assetInfo.decimals, 'bn')
          );
    },
    [api.tx.assets, api.tx.balances, nativeAsset?.symbol]
  );

  const handleAddressChange = useCallback((value: string) => {
    if (value.length <= ADDRESS_MAX_LENGTH) {
      setAddress(value);
    }
  }, []);

  const handleMaxClick = useCallback(() => {
    if (maxAmount && Number(maxAmount) > 0) {
      setAmountInputValue(maxAmount);
    }
  }, [maxAmount]);

  const handleAmountChange = useCallback((value: number | null) => {
    if (isValidAmountInput(value?.toString() ?? '') || value === null) {
      setAmountInputValue(value);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (asset && isValidAddress(address) && Number(amount) && Number(amount) <= asset.balance) {
        setTransactionFee(null);
        const fee = await txFee({
          tx: generateTx(asset, address, Number(amount)),
          account
        });
        setTransactionFee(fee);
      } else {
        setTransactionFee(undefined);
      }
    })();
  }, [account, address, amount, asset, generateTx]);

  const handleSend = () => {
    if (addressValid && !amountErrorMessage) {
      handleClose();
      dispatchTransactionStatus({
        token: asset.symbol,
        pending: true
      });
      signAndSend({
        api,
        account,
        tx: generateTx(asset, address, Number(amount)),
        txSuccessCb: () => {
          dispatchTransactionStatus({
            token: asset.symbol,
            pending: false
          });
        },
        txFailedCb: () => {
          dispatchTransactionStatus({
            token: asset.symbol,
            pending: false
          });
        }
      });
    }
  };

  return (
    <Modal size="500px" isOpen={opened} onClose={handleClose} title={`Send ${asset?.name}`}>
      {asset && (
        <ContentWrapper gap="1rem">
          <Stack>
            <InputLabelWrapper>
              <H5>To</H5>
              <AddressInput
                placeholder={`${capitalize(config.chain)} address`}
                initialOptions={initialAccountList}
                validFn={isValidAddress}
                onChange={handleAddressChange}
              />
            </InputLabelWrapper>
            <WarningTip>
              <WarningIcon>
                <Icon name="warning" size="medium" />
              </WarningIcon>
              <WarningText>
                You can only send in-chain to a {capitalize(config.chain)} address. Do NOT transfer
                to an address from an exchange.
              </WarningText>
            </WarningTip>
            <StyledTokenInput
              label={<H5>{asset.name} Amount</H5>}
              hint={
                <Inline gap="0.25rem">
                  <Text skin="secondary">Available:</Text>
                  <H5>{balanceFormatter(Number(asset?.balance) - lockedBalance)}</H5>
                </Inline>
              }
              token={asset.name.toUpperCase()}
              value={isNil(amountInputValue) ? null : toFloorFixed(amountInputValue, 4)}
              placeholder="Amount"
              onChange={handleAmountChange}
              onAction={handleMaxClick}
              actionButtonText="Max"
              error={amountErrorMessage ? amountErrorMessageMapping[amountErrorMessage] : undefined}
            />
            <Button
              skin="primary"
              size="large"
              block
              disabled={sendButtonDisabled}
              onClick={handleSend}
            >
              Send
            </Button>
            <TxFeeTips txFee={transactionFee} toolTip="This is the protocol fee" />
          </Stack>
        </ContentWrapper>
      )}
    </Modal>
  );
};

export default memo(TransferModal);
