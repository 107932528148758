import { memo, useContext, useMemo } from 'react';
import { H5 } from '@parallel-mono/components';
import {
  SelectableTokenValue,
  InfoPanel,
  InfoPanelProps
} from '@parallel-mono/business-components';
import { formatNumber } from '@parallel-mono/utils';

import { TransactionToken, TokenPair } from '../hooks/useTransactionTokens';
import { DataContext } from '../DataContext';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import { balanceFormatter, MIN_PERCENTAGE_THRESHOLD } from '@/utils/format';

interface LiquidityDetailProps {
  fromTokenValue: SelectableTokenValue<TransactionToken>;
  toTokenValue: SelectableTokenValue<TransactionToken>;
  tokenPair: TokenPair;
  apy: number | undefined;
  slippage: number;
}

export const LiquidityDetail = memo<LiquidityDetailProps>(
  ({ fromTokenValue, toTokenValue, tokenPair, apy, slippage }) => {
    const { assetInfoSource, assetDetailSource } = useContext(DataContext);
    const { assetInfos } = assetInfoSource;
    const { assetDetails } = assetDetailSource;

    const [fromTokenPair, toTokenPair] = tokenPair;
    const { lpAssetId, guideAmount: baseAmount } = fromTokenPair;
    const { guideAmount: quoteAmount } = toTokenPair;

    const poolSupplyBalance = useMemo(() => {
      const assetDetail = assetDetails.find(({ assetId }) => assetId.toString() === lpAssetId);
      const assetInfo = assetInfos.find(({ assetId }) => assetId === lpAssetId);
      if (assetDetail && assetInfo) {
        return balanceToAmountByDecimal<number>(assetDetail.supply, assetInfo.decimals, 'number');
      }
      return 0;
    }, [assetDetails, assetInfos, lpAssetId]);

    const poolShareRatio = useMemo(() => {
      if (fromTokenValue.amount && baseAmount) {
        return fromTokenValue.amount / (fromTokenValue.amount! + baseAmount);
      }
      return 0;
    }, [baseAmount, fromTokenValue.amount]);

    const expectedQtyLPToken = useMemo(
      () => poolSupplyBalance / (1 - poolShareRatio) - poolSupplyBalance,
      [poolShareRatio, poolSupplyBalance]
    );

    const exchangeRatio = useMemo(() => {
      if (baseAmount && quoteAmount) {
        return baseAmount / quoteAmount;
      }
      return 0;
    }, [baseAmount, quoteAmount]);

    const infos = useMemo<InfoPanelProps['infos']>(
      () => [
        {
          title: 'Est. APY',
          value: <H5 skin="success">{apy ? formatNumber(apy, { output: 'percent' }) : '-'}</H5>
        },
        {
          title: 'Receive LP Token',
          tip: 'This is the number of liquidity pool tokens you can expect to receive.',
          value: balanceFormatter(expectedQtyLPToken)
        },
        {
          title: `${fromTokenValue.token.symbol} per ${toTokenValue.token.symbol}`,
          value: formatNumber(exchangeRatio, { decimal: 4 })
        },
        {
          title: `${toTokenValue.token.symbol} per ${fromTokenValue.token.symbol}`,
          value: formatNumber(1 / exchangeRatio, { decimal: 4 })
        },
        {
          title: 'Share of Pool',
          tip: 'Liquidity providers (LPs) earn fees proportional to the percentage of the pool they’ve supplied.',
          value: formatNumber(poolShareRatio, {
            output: 'percent',
            decimal: 4,
            threshold: { min: MIN_PERCENTAGE_THRESHOLD }
          })
        },
        {
          title: 'Slippage',
          tip: 'This is the amount the pool supply changes between when a transaction is submitted and when it is executed.',
          value: `${slippage}%`
        }
      ],
      [
        apy,
        exchangeRatio,
        expectedQtyLPToken,
        fromTokenValue,
        poolShareRatio,
        slippage,
        toTokenValue
      ]
    );

    return <InfoPanel infos={infos} />;
  }
);
