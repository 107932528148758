import { mapValues } from 'lodash';

import configCommon from './common.json';
import heikoEnv from './env/heiko.json';
import parallelEnv from './env/parallel.json';
import rawDecimals from './decimal.json';

import { MAX_DOT_TRANSACTION_FEE, MAX_KUSAMA_TRANSACTION_FEE } from '@/utils/constants';

export enum Chain {
  HEIKO = 'heiko',
  PARALLEL = 'parallel'
}

export enum RelayChain {
  KUSAMA = 'kusama',
  POLKADOT = 'polkadot'
}

export enum NativeToken {
  PARA = 'PARA',
  HKO = 'HKO'
}

export enum RelayChainToken {
  DOT = 'DOT',
  KSM = 'KSM'
}

export enum Environment {
  DEVELOPMENT = 'development',
  REGNET = 'regnet',
  PRODUCTION = 'production'
}

const configEnvs = {
  [Chain.HEIKO]: heikoEnv,
  [Chain.PARALLEL]: parallelEnv
};

const chain = process.env.REACT_APP_CHAIN as Chain;
const env = (process.env.REACT_APP_ENV as Environment) || Environment.DEVELOPMENT;
const isParallel = chain === Chain.PARALLEL;
const configEnv = configEnvs[chain][env];
const DEBUG = env === Environment.DEVELOPMENT;
const PROD = env === Environment.PRODUCTION;
const lastCommit = process.env.REACT_APP_LAST_COMMIT as string;

export const chainPropertiesMapping = {
  [Chain.PARALLEL]: {
    paraId: 2012,
    assetId: '1',
    sReplayAssetId: '1001',
    relayAssetId: '101',
    logo: 'parallel-with-name',
    relayChain: RelayChain.POLKADOT,
    nativeToken: NativeToken.PARA,
    relayChainToken: RelayChainToken.DOT,
    blockPerDay: 7200,
    defaultEdValue: 0.1,
    relayChainMaxTxFee: MAX_DOT_TRANSACTION_FEE,
    loanAddress: 'p8DR3iQJe3tN8RHVgyWeKeRxHKLY2qVmAfKRDMwMwRhh2pfAP',
    rewardPoolAddress: 'p8BLECjRpEyZWZ2PZ57hWphGYuDChMGunarthBAv2zzD5NGWZ',
    mmAssetOrder: {
      primaryRule: [
        RelayChainToken.DOT,
        `s${RelayChainToken.DOT}`,
        'USDT',
        NativeToken.PARA,
        'GLMR',
        'ASTR',
        'ACA',
        'aUSD',
        'LIT',
        'LDOT'
      ],
      secondaryRule: ['LP-', `c${RelayChainToken.DOT}-`]
    },
    subscanBaseUrl: 'https://parallel.statescan.io',
    parallelServiceBaseUrl: `${configEnv.PARALLEL_SERVICE}/para`,
    faucetUrl: 'https://discord.com/channels/830972820846018600/1006524453045407805',
    xcmVersion: 'V2',
    xcmMinimumTransferAmount: 1.5
  },
  [Chain.HEIKO]: {
    paraId: 2085,
    assetId: '0',
    sReplayAssetId: '1000',
    relayAssetId: '100',
    logo: 'parallel-heiko-with-name',
    relayChain: RelayChain.KUSAMA,
    nativeToken: NativeToken.HKO,
    relayChainToken: RelayChainToken.KSM,
    blockPerDay: 7200,
    defaultEdValue: 0.01,
    relayChainMaxTxFee: MAX_KUSAMA_TRANSACTION_FEE,
    loanAddress: 'hJHfe3mtq3Rx4gNEy2uv5Jig8oy7F4HFrB5CE7oTEAkGC6aEP',
    rewardPoolAddress: 'hJFapY721EX9Sp78q8WyGUyzQPqmua4QU6cfhw31Kk2nEeEPm',
    mmAssetOrder: {
      primaryRule: [
        RelayChainToken.KSM,
        `s${RelayChainToken.KSM}`,
        'USDT',
        NativeToken.HKO,
        'MOVR',
        'KAR',
        'aUSD',
        'LKSM'
      ],
      secondaryRule: ['LP-', `c${RelayChainToken.KSM}-`]
    },
    subscanBaseUrl: 'https://heiko.statescan.io',
    parallelServiceBaseUrl: `${configEnv.PARALLEL_SERVICE}/heiko`,
    faucetUrl: 'https://discord.com/channels/830972820846018600/1006524362083537027',
    xcmVersion: 'V2',
    xcmMinimumTransferAmount: 0.015
  }
};

const decimals = mapValues(rawDecimals, value => 10 ** value);

type CommonConfig = typeof configCommon;
type EnvConfig = typeof configEnv;
type ChianProperties = typeof chainPropertiesMapping['heiko'];
type Config = CommonConfig &
  EnvConfig &
  ChianProperties & {
    env: Environment;
    DEBUG: boolean;
    PROD: boolean;
    rawDecimals: typeof rawDecimals;
    decimals: typeof decimals;
    chain: Chain;
    isParallel: boolean;
    lastCommit: string;
  };

const chainProperties = chainPropertiesMapping[chain];
const config: Config = {
  ...configCommon,
  ...configEnv,
  env,
  DEBUG,
  PROD,
  rawDecimals,
  decimals,
  chain,
  isParallel,
  lastCommit,
  ...chainProperties
};

export default config;
