import { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useDebounce } from 'react-use';
import { Stack, Inline, H4, H5, Button, Tooltip } from '@parallel-mono/components';

import { signAndSend, txFee } from '@/utils/txCall';
import { Link } from '@/components';
import { useChainConnections, useAccount } from '@/hooks';
import { ClaimData } from '@/hooks/useVestingData';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import config, { RelayChainToken } from '@/config';
import { balanceFormatter } from '@/utils/format';

const GrayText = styled(H5).attrs({ skin: 'secondary' })``;

const RowStyled = styled(Inline)`
  & > * {
    flex: 1;
  }
`;

interface ClaimRewardModalProps {
  claimData: ClaimData;
  handleProcessing: (status: boolean) => void;
  closeModal: () => void;
}

const vestingSchedule = {
  [RelayChainToken.DOT]: {
    period: 96,
    estimatedDate: '10/20/23'
  },
  [RelayChainToken.KSM]: {
    period: 48,
    estimatedDate: '8/5/22'
  }
};

const ClaimRewardModal: FC<ClaimRewardModalProps> = ({
  claimData,
  handleProcessing,
  closeModal
}) => {
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const { account } = useAccount();
  const {
    parachain: { api: paraApi }
  } = useChainConnections();

  const { TxFeeTips } = useTxFeeValidation();

  const schedule = useMemo(() => vestingSchedule[config.relayChainToken], []);

  useDebounce(
    () => {
      const getFee = async () => {
        setTransactionFee(null);
        const fee = await txFee({
          tx: paraApi.tx.vesting.claim(),
          account
        });
        setTransactionFee(fee);
      };
      if (account) {
        getFee();
      }
    },
    500,
    [account]
  );

  const handleClaim = () => {
    handleProcessing(true);
    closeModal();
    signAndSend({
      api: paraApi,
      tx: paraApi.tx.vesting.claim(),
      account,
      txSuccessCb: () => {
        handleProcessing(false);
      },
      txFailedCb: () => {
        handleProcessing(false);
      }
    });
  };

  return (
    <>
      <Stack gap="32px">
        <RowStyled gap="0">
          <Stack gap="4px">
            <Inline gap="0.2rem" alignItems="center">
              <GrayText>Available to Claim</GrayText>
              <Tooltip
                content={
                  <>
                    This is your {config.nativeToken} balance available for claim. You will see the
                    updated balance in your
                    <Link href="/"> wallet here</Link>.
                  </>
                }
              />
            </Inline>
            <H4>{balanceFormatter(claimData.availableToClaim)}</H4>
          </Stack>
          <Stack gap="4px">
            <Inline gap="0.2rem" alignItems="center">
              <GrayText>Total {config.nativeToken}</GrayText>
              <Tooltip
                content={`This is your total ${config.nativeToken} reward from your ${config.relayChainToken} contributions to our crowdloan and referral bonus.`}
              />
            </Inline>
            <H4>{balanceFormatter(claimData.totalToken)}</H4>
          </Stack>
        </RowStyled>
        <RowStyled gap="0">
          <Stack gap="4px">
            <Inline gap="0.2rem" alignItems="center">
              <GrayText>Vested</GrayText>
              <Tooltip
                content={`The vesting period is ~${schedule.period} weeks, it will be a linear release until the end of our lease period on the estimated date of ${schedule.estimatedDate}.`}
              />
            </Inline>
            <H4>{balanceFormatter(claimData.totalVested)}</H4>
          </Stack>
          <Stack gap="4px">
            <Inline gap="0.2rem" alignItems="center">
              <GrayText>Claimed</GrayText>
              <Tooltip
                content={
                  <>
                    This is the your {config.nativeToken} balance that is claimed and available in
                    your
                    <Link href="/"> wallet</Link>.
                  </>
                }
              />
            </Inline>
            <H4>{balanceFormatter(claimData.totalClaimed)}</H4>
          </Stack>
        </RowStyled>
      </Stack>
      <Stack gap="1rem" inset="1rem 0 0">
        <Button
          block
          skin="primary"
          size="large"
          disabled={!transactionFee || claimData.availableToClaim === 0}
          onClick={handleClaim}
        >
          Claim {balanceFormatter(claimData.availableToClaim)} {config.nativeToken}
        </Button>
        <TxFeeTips txFee={transactionFee} />
      </Stack>
    </>
  );
};

export default ClaimRewardModal;
