import styled, { css, useTheme, StyledComponent, DefaultTheme } from 'styled-components';

import { FONT_BODY_STYLES } from '../theme/typography/typography';
import { stackTwoColors } from '../../utils/blendColors';
import { FC, ButtonHTMLAttributes } from 'react';
import { Skin } from '../ThemeProvider';
import { SpinnerProps, Spinner } from '../Spinner';

const { Body1, Body2 } = FONT_BODY_STYLES;

type ButtonSize = 'small' | 'medium' | 'large';

type Variant = 'button' | 'link';

export const sizeToPadding: Record<ButtonSize, [number, number]> = {
  small: [0.5, 3],
  medium: [2, 8],
  large: [4, 10],
};

export interface ButtonCustProps {
  disabled?: boolean;
  skin?: Skin;
  size?: ButtonSize;
  block?: boolean;
  variant?: Variant;
  loading?: boolean;
  spinnerProps?: SpinnerProps;
}
export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonCustProps> &
  ButtonCustProps;

const StyledButton = styled.button<ButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily};
  display: ${({ variant = 'button' }) => (variant === 'button' ? 'flex' : 'inline-flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: ${({ block }) => (block ? '100%' : 'fit-content')};
  padding: ${({ size = 'medium', variant = 'button', theme }) => {
    if (variant === 'button') {
      const [top, left] = sizeToPadding[size];
      return css`
        ${theme.spacing(top)}
        ${theme.spacing(left)}
      `;
    }
    return css``;
  }};
  font-size: ${({ size = 'medium' }) =>
    size === 'small' ? Body2['font-size'] : Body1['font-size']};
  border-radius: 100px;
  border: none;
  white-space: nowrap;
  ${({ skin = 'primary', theme }) => {
    return css`
      background-color: ${theme.skin[skin].main};
      color: ${theme.skin[skin].contrastText};
    `;
  }}
  ${({ variant = 'button', theme, skin = 'primary' }) => {
    switch (variant) {
      case 'link':
        return css`
          background: none;
          color: ${theme.skin[skin].main};
        `;
      default:
        return '';
    }
  }}
  ${({ theme }) => css(theme.typography.button)}

  :hover {
    cursor: pointer;
    ${({ skin = 'primary', theme, variant = 'button', disabled }) => {
      if (variant === 'link') {
        return css`
          text-decoration: ${disabled ? 'none' : 'underline'};
          color: ${stackTwoColors(theme.skin[skin].main, theme.skin.action.hoverMask)};
        `;
      }
      return css`
        background-color: ${stackTwoColors(theme.skin[skin].main, theme.skin.action.hoverMask)};
        color: ${theme.skin[skin].contrastText};
      `;
    }}
  }

  :disabled {
    ${({ theme, variant = 'button' }) => {
      if (variant === 'link') {
        return css``;
      }
      return css`
        background-color: ${theme.skin.grey[200]};
      `;
    }};
    color: ${({ theme }) => theme.skin.grey[500]};
    pointer-events: none;

    :hover {
      cursor: auto;
    }
  }
`;
const SPINNER_SIZE_MAP = {
  small: '12px',
  medium: '16px',
  large: '20px',
};
const StyledSpinner = styled(Spinner)<{ sizeOverride: ButtonSize }>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  display: inline-flex;
  width: ${({ sizeOverride }) => SPINNER_SIZE_MAP[sizeOverride]};
  height: ${({ sizeOverride }) => SPINNER_SIZE_MAP[sizeOverride]};
`;
const Button: FC<ButtonProps> = ({
  loading = false,
  children,
  skin = 'primary',
  size = 'medium',
  disabled,
  spinnerProps,
  ...otherProps
}) => {
  const theme = useTheme();
  return (
    <StyledButton {...otherProps} skin={skin} size={size} disabled={disabled}>
      <StyledSpinner
        visible={loading}
        color={disabled ? theme.skin.primary.main : theme.skin[skin].contrastText}
        {...spinnerProps}
        sizeOverride={size}
      />
      {children}
    </StyledButton>
  );
};
type ButtonType = StyledComponent<typeof Button, DefaultTheme, ButtonProps, never>;

const TypedButton = Button as ButtonType;

export { TypedButton as Button };
