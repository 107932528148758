import { Button, Inline, Stack, Text, Toggle } from '@parallel-mono/components';
import { memo, useCallback, useState } from 'react';
import { chain, every, isArray, isEmpty, isNil } from 'lodash';
import dayjs from 'dayjs';
import { BN } from '@polkadot/util';

import { useAccount, useChainConnections } from '@/hooks';
import { signAndSend } from '@/utils/txCall';
import { amountToBalanceByDecimals } from '@/utils/calculations';

export const BatchSend = memo(() => {
  const [list, setList] = useState('');
  const [checked, setChecked] = useState(false);

  const {
    parachain: { api }
  } = useChainConnections();
  const { account } = useAccount();

  const handleTextareaChange = useCallback((event: any) => {
    setList(event.target.value);
  }, []);

  const handleBatchSend = useCallback(() => {
    try {
      const value = JSON.parse(list);
      if (!isEmpty(list) && isArray(value) && every((v: any) => v.address && !isNil(v.para))) {
        const batchTxs = chain(value)
          .map(({ address: userAddress, para }) => {
            const PARA_DECIMAL = 12;
            const PARA_ASSET_ID = 1;
            const stage1Rewards = checked ? 0.001 : para.stage1;
            const stage2Rewards = checked ? 0.01 : para.stage2;

            console.log(stage1Rewards, stage2Rewards);
            const tx1 = api.tx.streaming.create(
              userAddress,
              amountToBalanceByDecimals<BN>(stage1Rewards, PARA_DECIMAL, 'bn'),
              PARA_ASSET_ID,
              dayjs().add(1, 'minute').unix(),
              dayjs().add(2, 'minute').unix(),
              true
            );
            const tx2 = api.tx.streaming.create(
              userAddress,
              amountToBalanceByDecimals<BN>(stage2Rewards, PARA_DECIMAL, 'bn'),
              PARA_ASSET_ID,
              dayjs().add(1, 'day').startOf('date').unix(),
              dayjs('2025-11-20').unix(),
              true
            );

            return [tx1, tx2];
          })
          .flatten()
          .value();
        console.log(batchTxs);

        signAndSend({
          api,
          tx: api.tx.utility.batchAll(batchTxs),
          account
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [account, api, checked, list]);

  return (
    <Stack>
      <textarea style={{ height: 300 }} value={list} onChange={handleTextareaChange} />
      <Inline>
        <Text>Test 0.01</Text>
        <Toggle
          skin="neutral"
          onChange={() => {
            setChecked(!checked);
          }}
          checked={checked}
        />
      </Inline>
      <Button onClick={handleBatchSend}>Batch Send</Button>
    </Stack>
  );
});
