import { useNavigate, useLocation, To, Path, NavigateOptions } from 'react-router-dom';
import { isString, uniq } from 'lodash';

const joinSearch = (...args: string[]) => {
  const search = args
    .map(arg => arg.replace(/^\?/, ''))
    .filter(s => s)
    .join('&');

  return uniq(search.split('&')).join('&');
};

const splitPath = (path: string) => {
  const [pathname, search = ''] = path.split('?');
  return {
    pathname,
    search
  };
};

export const useSearchParamsNavigate = () => {
  const navigate = useNavigate();
  const { search = '' } = useLocation();

  const getNavigateInfo = (to: To): Path => {
    const pathname = isString(to) ? to : to.pathname;
    const hash = isString(to) || !to.hash ? '' : to.hash;
    const { pathname: purePathname, search: pureSearch } = splitPath(pathname!);
    const newSearch = joinSearch(search, pureSearch, isString(to) || !to.search ? '' : to.search);

    return {
      pathname: purePathname,
      search: newSearch,
      hash
    };
  };

  const newNavigate = (to: To, options?: NavigateOptions) => {
    return navigate(getNavigateInfo(to), options);
  };

  return {
    navigate: newNavigate,
    getNavigateInfo
  };
};
