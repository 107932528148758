import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface StaticPillProps {
  children: ReactNode;
}

const Wrapper = styled.div`
  border: solid 1px #eeeeee;
  border-radius: 16px;
  padding: 0.5rem 1rem;
`;

const StaticPill: FC<StaticPillProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default StaticPill;
