import { CSSProperties, HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { DataGridColumn } from '../types';
import { DataGridClassNames } from '../types';
import cx from 'classnames';
import { genericMemo } from '../../../utils/genericMemo';
import { isFunction } from 'lodash';
import { DataAttributes } from '../../types/DataAttributes';

const Header = styled.thead`
  display: contents;
`;

const HeaderRow = styled.tr`
  display: contents;
`;

export type HeaderRowProps = Omit<HTMLAttributes<HTMLTableRowElement>, 'children'> & DataAttributes;

export type TitleProps = Omit<HTMLAttributes<HTMLTableCellElement>, 'children'> & DataAttributes;

export type GridHeaderProps<R = object> = Omit<HTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  columns: (DataGridColumn<R> & { expandToggleColumn?: boolean })[];
  classNames?: DataGridClassNames;
  hideColumnsTitle?: boolean;
  headerRowProps?: HeaderRowProps;
  titleProps?: TitleProps;
};

const HeaderCell = styled.th<{
  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  expandToggleCell: boolean;
}>`
  display: flex;
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
  align-items: ${({ alignItems = 'center' }) => alignItems};
  color: ${({ theme }) => theme.skin.text.secondary};
  padding: ${({ theme, expandToggleCell }) => (expandToggleCell ? 0 : theme.spacing(4))};
  width: ${({ expandToggleCell }) => (expandToggleCell ? '3em' : 'auto')};
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
  border-bottom: 1px solid ${({ theme }) => theme.skin.grey[300]};
  ${({ theme }) => css(theme.typography.header4)}
`;

const Title = styled.th`
  grid-column: 1 / -1;
  ${({ theme }) => css(theme.typography.header2)}
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const GridHeader = genericMemo(function <R = object>(props: GridHeaderProps<R>) {
  const {
    columns,
    classNames,
    className = '',
    title,
    hideColumnsTitle = false,
    headerRowProps,
    titleProps,
    ...others
  } = props;
  const normalizedHeaderRowProps = isFunction(headerRowProps) ? headerRowProps() : headerRowProps;
  const normalizedTitleProps = isFunction(titleProps) ? titleProps() : titleProps;
  return (
    <Header className={cx(classNames?.header, className)} {...others}>
      {title && (
        <HeaderRow>
          <Title {...normalizedTitleProps} className={cx(classNames?.title)}>
            {title}
          </Title>
        </HeaderRow>
      )}
      {!hideColumnsTitle && (
        <HeaderRow
          {...normalizedHeaderRowProps}
          className={cx(classNames?.headerRow, normalizedHeaderRowProps?.className)}
        >
          {columns.map(
            (
              {
                title: columnTitle,
                expandToggleColumn,
                className,
                alignItems,
                justifyContent,
                cellProps = {},
              },
              index
            ) => {
              const normalizedCellProps = isFunction(cellProps)
                ? cellProps('header', null)
                : cellProps;
              return (
                <HeaderCell
                  {...normalizedCellProps}
                  key={index}
                  alignItems={alignItems}
                  justifyContent={justifyContent}
                  className={cx(
                    className,
                    classNames?.cell,
                    classNames?.headerCell,
                    expandToggleColumn ? classNames?.expandToggleHeaderCell : null,
                    expandToggleColumn ? classNames?.expandToggleCell : null,
                    normalizedCellProps?.className
                  )}
                  expandToggleCell={expandToggleColumn ?? false}
                >
                  {columnTitle}
                </HeaderCell>
              );
            }
          )}
        </HeaderRow>
      )}
    </Header>
  );
});
