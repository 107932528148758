import { toast } from 'react-toastify';
import { ReactNode } from 'react';
import { Stack } from '@parallel-mono/components';

import { ErrorType, enrichErrorMessage } from './errors';

import ToastBody from '@/components/ToastBody';
import { Link } from '@/components';
import config from '@/config';

let nextId = 0;

type TxStatusType = 'signing' | 'processing' | 'complete';

const showTxStatus = () => {
  // eslint-disable-next-line no-plusplus
  const toastId = `toast-${++nextId}`;

  toast(<ToastBody type="processing" text="Confirming transaction" />, {
    toastId,
    closeButton: false,
    closeOnClick: false
  });

  return ({
    status,
    error,
    renderer,
    txHash
  }: {
    status?: TxStatusType;
    error?: ErrorType | string;
    renderer?: () => ReactNode;
    txHash?: string;
  }) => {
    if (status === 'processing') {
      toast.update(toastId, {
        render: () => <ToastBody type="processing" text="Transaction processing." />
      });
    }

    const completedRenderer = () => {
      if (renderer || txHash) {
        return (
          <Stack inset="0 0 0 2.5rem" gap="1rem">
            {renderer?.()}
            {txHash && (
              <Link href={`${config.subscanBaseUrl}/#/extrinsics/${txHash}`} target="_blank">
                View on block explorer
              </Link>
            )}
          </Stack>
        );
      }
      return null;
    };

    if (status === 'complete') {
      if (error) {
        const { description } = enrichErrorMessage(error);
        toast.update(toastId, {
          render: <ToastBody type="error" text={description} renderer={completedRenderer} />,
          type: toast.TYPE.ERROR,
          icon: false,
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000
        });
      } else {
        toast.update(toastId, {
          render: (
            <ToastBody type="success" text="Transaction success!" renderer={completedRenderer} />
          ),
          closeOnClick: true,
          closeButton: true,
          autoClose: 5000
        });
      }
    }
  };
};

export type { ErrorType };

export default showTxStatus;
