import { Inline, Icon, H3, H6, Tag, Skeleton, Responsive } from '@parallel-mono/components';
import styled from 'styled-components';
import { memo } from 'react';
import { isNumber } from 'lodash';
import { formatNumber } from '@parallel-mono/utils';

import RewardsClaimModal from '../Modal/RewardsClaimModal';

import config from '@/config';
import { useModal } from '@/hooks';

const Panel = styled(Inline).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
  inset: '1.5rem'
})`
  box-shadow: 0px 4px 16px rgba(58, 78, 88, 0.15);
  border-radius: 12px;
  flex: 1;
`;

const TvlPanel = styled(Panel)`
  background: linear-gradient(76.32deg, #597fff 0%, #9ab1ff 96.97%);
`;

const RewardsPanel = styled(Panel)`
  background: linear-gradient(75.75deg, #61c1f5 -2.92%, #8bd5fe 100.39%);
`;

const EarnedPanel = styled(Panel)`
  background: linear-gradient(268.12deg, #1ce2bc 0%, #1aceda 99.72%);
`;

const H3Styled = styled(H3)`
  color: var(--clr-white);
  line-height: 1.5rem;
`;

const H6Styled = styled(H6)`
  color: var(--clr-white);
`;

const ClaimTag = styled(Tag)`
  cursor: pointer;
`;

const ValuePlaceHolder = () => {
  return <Skeleton.Button width="5rem" height="1.5rem" variant="round" />;
};

interface SummaryProps {
  totalValue?: number;
  userUnclaimedReward?: number | null;
  earned?: number | null;
}

export const Summary = memo<SummaryProps>(({ totalValue, userUnclaimedReward, earned }) => {
  const { openModal: openClaimModal, holder: claimModalHolder } = useModal(RewardsClaimModal, {
    title: 'Claim Rewards',
    size: '500px'
  });

  return (
    <Responsive>
      <TvlPanel>
        <Inline gap="0.6rem" alignItems="center">
          <Icon name="trendingUp" color="var(--clr-white)" />
          <H3Styled fontWeight="medium">TVL</H3Styled>
        </Inline>

        {totalValue ? (
          <H3Styled>{formatNumber(totalValue, { output: 'currency' })}</H3Styled>
        ) : (
          <ValuePlaceHolder />
        )}
      </TvlPanel>

      <RewardsPanel>
        <Inline gap="0.6rem" alignItems="center">
          <Icon name="moneyBag" color="var(--clr-white)" />
          <H3Styled fontWeight="medium">Rewards</H3Styled>
        </Inline>
        {isNumber(userUnclaimedReward) ? (
          <Inline gap="0.5rem" alignItems="center">
            <Inline gap="0.3rem" alignItems="flex-end">
              <H3Styled>{formatNumber(userUnclaimedReward, { threshold: { min: 0.01 } })}</H3Styled>
              <H6Styled>{config.nativeToken}</H6Styled>
            </Inline>
            {userUnclaimedReward > 0 && (
              <ClaimTag size="small" skin="primary" onClick={openClaimModal}>
                Claim
              </ClaimTag>
            )}
          </Inline>
        ) : (
          <ValuePlaceHolder />
        )}
      </RewardsPanel>

      <EarnedPanel>
        <Inline gap="0.6rem" alignItems="center">
          <Icon name="coins" color="var(--clr-white)" />
          <H3Styled fontWeight="medium">Net Yield</H3Styled>
        </Inline>
        {isNumber(earned) ? (
          <H3Styled>{formatNumber(earned, { output: 'currency' })}</H3Styled>
        ) : (
          <ValuePlaceHolder />
        )}
      </EarnedPanel>
      {claimModalHolder}
    </Responsive>
  );
});
