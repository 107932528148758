import { CSSObject, DefaultTheme } from 'styled-components';
import { BreakpointFunctions, Breakpoints } from './breakpoints';
import { MediaWidth } from './media';

export enum ColorMode {
  light = 'light',
  dark = 'dark',
}

type Skin = 'primary' | 'secondary' | 'neutral' | 'success' | 'warning' | 'error';

export type { Skin };

export type Size = 'small' | 'medium' | 'large';

export interface SkinPalette {
  main: string; //the main color of a skin.
  contrastText: string; // color for text，when the main color is used as background
  secondaryContrastText: string; // color for secondary text
  contrastBackground: string; // background color, when the main color is used as text color
}

export interface ActionSkin {
  disabledOpacity: number; // all components that can be disabled, should use this opacity when disabled
  hoverMask: string; // the mask applied to component when hovered
}

export interface TextSkin {
  primary: string; // the primary text color of a app.
  secondary: string; // the secondary text color of a app
}

export interface GreyPalette {
  white: string;
  100: string;
  200: string;
  300: string;
  500: string;
  700: string;
  900: string;
}

export interface TypographyVariantTheme extends CSSObject {}

export enum TypographyVariant {
  display1 = 'display1',
  display2 = 'display2',
  header1 = 'header1',
  header2 = 'header2',
  header3 = 'header3',
  header4 = 'header4',
  header5 = 'header5',
  header6 = 'header6',
  body = 'body',
  bodyBig = 'bodyBig',
  bodySmall = 'bodySmall',
}

export type FontWeight = 'regular' | 'medium' | 'bold';

export type TypographyTheme = {
  [variant in TypographyVariant]: TypographyVariantTheme;
} & {
  fontWeight: {
    [fontWeight in FontWeight]: string | number;
  };
  button: TypographyVariantTheme;
};

export interface BorderTheme {
  radius: {
    [size in Size]: string;
  };
  width: {
    [size in Size]: string;
  };
}

export interface ShadowTheme {
  primary: string;
  secondary: string;
}

export type OverridableComponents = 'Typography' | 'DataGrid' | 'Button';

declare module 'styled-components' {
  export interface DefaultTheme<Mode = ColorMode> {
    mode: Mode;
    skin: { [key in Skin]: SkinPalette } & {
      action: ActionSkin; // user interaction related color configs, e.g default disabled style
      text: TextSkin;
      background: {
        slot: string;
        main: string;
      };
      grey: GreyPalette;
    };
    border: BorderTheme;
    shadows: ShadowTheme;
    fontFamily: string;
    typography: TypographyTheme;
    mediaWidth: MediaWidth;
    components?: Partial<{
      [componentName in OverridableComponents]: RecursivePartial<
        Omit<DefaultTheme<Mode>, 'components' | 'mode'>
      >;
    }>;
    spacing: (num: number) => string;
    breakpoints: BreakpointFunctions;
    breakpointsConfig: Breakpoints;
  }
}

export type ThemeConfig = Omit<
  DefaultTheme,
  'skin' | 'mode' | 'spacing' | 'components' | 'breakpoints' | 'breakpointsConfig'
> & {
  [mode in ColorMode as `${mode}Skin`]: DefaultTheme['skin'];
} & {
  spacing: {
    base: string;
  };
  components?: Partial<{
    [componentName in OverridableComponents]: RecursivePartial<
      Omit<ThemeConfig, 'components' | 'breakpoints'>
    >;
  }>;
  breakpoints: Breakpoints;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};
