import { Checkbox as CheckboxBase, CheckboxProps } from '@parallel-mono/components';
import styled from 'styled-components';

const StyledCheckbox = styled(CheckboxBase)`
  align-items: flex-start !important;
  > label {
    margin-top: 0.3rem;
  }
  > span {
    padding-left: 0.5rem;
  }
`;
export const Checkbox = (props: CheckboxProps) => {
  return <StyledCheckbox {...props} />;
};

export default Checkbox;
