import { BN, BN_ZERO, bnMax } from '@polkadot/util';
import { Option, Vec } from '@polkadot/types';
import { BlockNumber, UnlockChunk } from '@polkadot/types/interfaces';
import { useAsync } from 'react-use';

import { getEraLength } from '@/pages/Staking/apis/staking';
import { useAccount, useApiCall, useChainConnections } from '@/hooks';

export type PendingUnstakeList = Array<[BN, BN]>;

const usePendingUnstakeList = () => {
  const {
    parachain: { api },
    relaychain: { api: relayApi }
  } = useChainConnections();
  const { account } = useAccount();
  const currentEra = useApiCall<BlockNumber>(api.query.liquidStaking.currentEra);
  const eraLengthVal = getEraLength(api);
  const eraStartBlock = useApiCall<BlockNumber>(api.query.liquidStaking.eraStartBlock);
  const unlockings = useApiCall<Option<Vec<UnlockChunk>>>(api.query.liquidStaking.unlockings, [
    account?.address
  ]);

  const lists = useAsync(async () => {
    const relayChainBlock = await relayApi.rpc.chain.getBlock();
    const relayChainBlockNumber = relayChainBlock?.block.header.number.toBn();
    return unlockings?.unwrapOrDefault().reduce((acc: PendingUnstakeList, { era, value }) => {
      const blocks = bnMax(
        era
          .toBn()
          .sub(currentEra!)
          .mul(eraLengthVal)
          .sub(relayChainBlockNumber!.sub(eraStartBlock!)),
        BN_ZERO
      );
      acc.push([value.toBn(), blocks]);

      return acc;
    }, []);
  }, [currentEra, eraLengthVal, eraStartBlock, relayApi.rpc.chain, unlockings]).value;

  return lists;
};

export default usePendingUnstakeList;
