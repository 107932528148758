import { forwardRef, memo, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { Skin } from '../ThemeProvider';
import { MakeOptional } from '../types/MakeOptional';
import {
  BaseProgressBarProps,
  Marker,
  BaseProgressBarVariant,
  BaseProgressBar,
} from './BaseProgressBar';

export type ProgressBarProps = Omit<
  MakeOptional<BaseProgressBarProps, 'color' | 'background' | 'cursorBackground' | 'variant'>,
  'markers'
> & {
  skin?: Skin;
  markers?: MakeOptional<Marker, 'color'>[];
};

export const ProgressBar = memo(
  forwardRef<HTMLDivElement, ProgressBarProps>((props, ref) => {
    const {
      color,
      background,
      cursorBackground,
      skin = 'primary',
      variant = 'bar',
      markers = [],
      ...others
    } = props;
    const theme = useTheme();
    const {
      [skin]: { main, contrastBackground },
      background: { main: mainBackground },
    } = theme.skin;
    const finalColor = color ?? main;
    const finalBackground = background ?? contrastBackground;
    const finalCursorBackground = cursorBackground ?? mainBackground;
    const finalMarkers = useMemo(
      () =>
        markers.map(marker => ({
          ...marker,
          color: marker.color ?? main,
        })),
      [markers, main]
    );
    return (
      <BaseProgressBar
        ref={ref}
        color={finalColor}
        background={finalBackground}
        cursorBackground={finalCursorBackground as string}
        markers={finalMarkers}
        variant={variant as BaseProgressBarVariant}
        {...others}
      />
    );
  })
);
