import { FC, memo } from 'react';
import styled from 'styled-components';
import { Inline, SmallText } from '@parallel-mono/components';
import { CryptoAsset } from '@parallel-mono/business-components';

const Container = styled(Inline)`
  border: 1px solid var(--clr-gray03);
  border-radius: 999px;
  width: fit-content;
  background-color: var(--clr-white);
`;

interface TokenPillProps {
  name: string;
  content: string;
}

const TokenPill: FC<TokenPillProps> = ({ name, content }) => {
  return (
    <Container gap="0.25rem" inset=".25rem .25rem .25rem .75rem" alignItems="center">
      <SmallText>{content}</SmallText>
      <CryptoAsset symbol={name} symbolSize="xsmall" />
    </Container>
  );
};

export default memo(TokenPill);
