import { useState, useMemo, useCallback } from 'react';
import { forEach } from 'lodash';
import { useEffectOnce } from 'react-use';

import { AcalaChain } from '../chains/acala';
import { Chains } from '../chains/types';
import { ParallelChain } from '../chains/parallel';
import { PolkadotChain } from '../chains/polkadot/index';
import { PhalaChain } from '../chains/phala/index';
import { StatemintChain } from '../chains/statemint';
import { InterlayChain } from '../chains/interlay';

import { isSocketReady } from '@/utils/apiSocket/initApiSocket';
import { useAccount, useConnectChains, useChainConnections } from '@/hooks';
import { Chains as ChainsApi } from '@/hooks/useChainConnection';
import useAsyncEffect from '@/hooks/useAsyncEffect';
import { AssetDetailInfo } from '@/hooks/types';

type ChainsType = AcalaChain | ParallelChain | PolkadotChain | PhalaChain;
const ChainMap = {
  [Chains.Acala]: AcalaChain,
  [Chains.Parallel]: ParallelChain,
  [Chains.Polkadot]: PolkadotChain,
  [Chains.Phala]: PhalaChain,
  [Chains.Statemint]: StatemintChain,
  [Chains.Interlay]: InterlayChain
} as Record<Chains, any>;

const ChainApiMap = {
  [Chains.Acala]: ChainsApi.AcalaChain,
  [Chains.Parallel]: ChainsApi.ParaChain,
  [Chains.Polkadot]: ChainsApi.RelayChain,
  [Chains.Phala]: ChainsApi.PhalaChain,
  [Chains.Statemint]: ChainsApi.StatemineChain,
  [Chains.Interlay]: ChainsApi.InterlayChain
} as Record<Chains, ChainsApi>;

export const useCrossChain = (fromChain: Chains, toChain?: Chains) => {
  const [chains, setChains] = useState({} as Record<Chains, ChainsType>);
  const [assetsInfos, setAssetsInfos] = useState<Record<string, AssetDetailInfo[]>>();
  const [chainLoading, setChainLoading] = useState(false);
  const { getChainConnection } = useConnectChains();
  const { account } = useAccount();
  const { toChains, currencies, minimumTransferAmount } = useMemo(
    () => ChainMap[fromChain],
    [fromChain]
  );

  const { parachain } = useChainConnections();

  useEffectOnce(() => {
    forEach(ChainApiMap, value => {
      getChainConnection(value);
    });
  });

  const getChainInstance = useCallback(
    async (chainName: Chains) => {
      const apiSocket = await getChainConnection(ChainApiMap[chainName]);
      if (isSocketReady(apiSocket) && isSocketReady(parachain) && account) {
        const chainInstance = new ChainMap[chainName](apiSocket.api, account, parachain.api);
        chainInstance.metadataSubscribe();

        chainInstance.observer.on('metadataChange', async () => {
          const assetInfoResult = await chainInstance.calculateAssetsInfo();
          setAssetsInfos(prevAssetsInfos => ({
            ...prevAssetsInfos,
            [chainName]: assetInfoResult
          }));
        });
        return chainInstance;
      }
      return undefined;
    },
    [account, getChainConnection, parachain]
  );

  useAsyncEffect(async () => {
    setChainLoading(true);
    if (fromChain && !chains[fromChain]) {
      const chain = await getChainInstance(fromChain);
      setChains(prevChains => ({ ...prevChains, [fromChain]: chain }));
    }
    setChainLoading(false);
  }, [fromChain]);

  useAsyncEffect(async () => {
    setChainLoading(true);
    if (toChain && !chains[toChain]) {
      const chain = await getChainInstance(toChain);
      setChains(prevChains => ({ ...prevChains, [toChain]: chain }));
    }
    setChainLoading(false);
  }, [toChain]);

  useAsyncEffect(async () => {
    if (account) {
      forEach(chains, chain => {
        chain.observer.off('metadataChange');
        chain.unsubscribeValue();
      });
      setChainLoading(true);
      const fromChainInstance = await getChainInstance(fromChain);
      const newChains = { [fromChain]: fromChainInstance };
      if (toChain) {
        const toChainInstance = await getChainInstance(toChain);
        newChains[toChain] = toChainInstance;
      }
      setChains(newChains as Record<Chains, ChainsType>);
      setAssetsInfos({});
      setChainLoading(false);
    }
  }, [account]);

  const chain = useMemo(() => chains[fromChain], [chains, fromChain]);

  const fromChainAssetsInfo = useMemo(
    () => assetsInfos?.[fromChain] || [],
    [assetsInfos, fromChain]
  );
  const toChainAssetsInfo = useMemo(
    () => (toChain && assetsInfos?.[toChain]) || [],
    [assetsInfos, toChain]
  );
  return {
    chain: { ...chain, assetsInfo: fromChainAssetsInfo },
    toChainAssetsInfo,
    chainLoading,
    toChains,
    currencies,
    minimumTransferAmount
  };
};
