import { FC, useMemo } from 'react';
import { range } from 'lodash';
import { Stack } from '../Layout';

import Element, { SkeletonElementProps } from './Element';

interface SkeletonParagraphProps extends Pick<SkeletonElementProps, 'active'> {
  /**
   * When width is an Array, it can set the width of each row.
   * Otherwise only set the last row width.
   */
  width?: string | string[];
  rows?: number;
}

const getWidth = (index: number, props: SkeletonParagraphProps) => {
  const { width, rows } = props as Required<SkeletonParagraphProps>;
  if (Array.isArray(width)) {
    return width[index];
  }
  if (rows - 1 === index) {
    return width;
  }

  return '100%';
};

const Paragraph: FC<SkeletonParagraphProps> = ({ rows = 2, width = '60%', active }) => {
  const rowList = useMemo(() => {
    return range(rows).map(row => (
      <Element key={row} active={active} width={getWidth(row, { rows, width })} height="16px" />
    ));
  }, [active, rows, width]);

  return <Stack gap="16px">{rowList}</Stack>;
};

export type { SkeletonParagraphProps };
export default Paragraph;
