import { ApiPromise } from '@polkadot/api';
import { BN_ZERO } from '@polkadot/util';
import BigNumber from 'bignumber.js';

import { ChainMetadata } from '../types';
import { MAXIMUM_TX_FEE } from '../constants';

import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';

export const calculateAssetsInfo = async (api: ApiPromise, metadata: ChainMetadata) => {
  const { chainProperties: tokenProperties, accountInfo } = metadata;
  const chainName = await api.rpc.system.chain();
  const { existentialDeposit } = api.consts.balances;

  if (tokenProperties && accountInfo) {
    const { tokenSymbol, tokenDecimals } = tokenProperties;
    const { free = BN_ZERO, miscFrozen = BN_ZERO, feeFrozen = BN_ZERO } = accountInfo?.data || {};
    const decimals = tokenDecimals.isSome ? tokenDecimals.unwrap()[0] : BN_ZERO;
    const symbol = tokenSymbol.isSome ? tokenSymbol.unwrap()[0].toString() : '';
    const totalBalance = BigNumber(free.toString());
    const lockedBalance = BigNumber.max(miscFrozen.toString(), feeFrozen.toString());
    const availableBalance = BigNumber.max(
      totalBalance.minus(lockedBalance).minus(existentialDeposit.toString()),
      0
    );
    const maxTxFee = amountToBalanceByDecimals<BigNumber>(MAXIMUM_TX_FEE, decimals, 'bigNumber');
    const maxAvailableBalance = BigNumber.max(availableBalance.minus(maxTxFee), 0);
    return [
      {
        network: chainName,
        symbol,
        decimals,
        balance: balanceToAmountByDecimal<BigNumber>(totalBalance, decimals, 'bigNumber'),
        existentialDeposit: balanceToAmountByDecimal<BigNumber>(
          existentialDeposit,
          decimals,
          'bigNumber'
        ),
        lockedBalance: balanceToAmountByDecimal<BigNumber>(lockedBalance, decimals, 'bigNumber'),
        availableBalance: balanceToAmountByDecimal<BigNumber>(
          availableBalance,
          decimals,
          'bigNumber'
        ),
        maxAvailableBalance: balanceToAmountByDecimal<BigNumber>(
          maxAvailableBalance,
          decimals,
          'bigNumber'
        ),
        isNative: true
      }
    ];
  }
  return undefined;
};
