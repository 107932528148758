import tinyColor from 'tinycolor2';

export const stackTwoColors = (baseColor: string, maskColor: string) => {
  const base = tinyColor(baseColor);
  const mask = tinyColor(maskColor);
  const alpha1 = mask.getAlpha();
  const { r: r1, g: g1, b: b1 } = mask.toRgb();
  const alpha2 = base.getAlpha();
  const { r: r2, g: g2, b: b2 } = base.toRgb();
  const A = 1 - (1 - alpha1) * (1 - alpha2);
  const R = (alpha1 * r1 + (1 - alpha1) * alpha2 * r2) / A;
  const G = (alpha1 * g1 + (1 - alpha1) * alpha2 * g2) / A;
  const B = (alpha1 * b1 + (1 - alpha1) * alpha2 * b2) / A;
  return `rgba(
    ${R},${G},${B},${A}
  )`;
};
