import { FC, AnchorHTMLAttributes, ElementType, ReactNode } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@parallel-mono/components';

import LinkBase, { Variant } from './LinkBase';

interface LinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    Pick<ButtonProps, 'block' | 'disabled' | 'size'> {
  variant?: Variant | 'button-primary' | 'button-secondary';
  children: ReactNode;
}

const LinkWithButtonVariant = styled(Button).attrs(() => ({
  as: 'a'
}))`
  text-decoration: none;
`;

const Link: FC<LinkProps> = ({ variant = 'primary', children, ...props }) => {
  const buttonVariantMatches = variant.match(/button-(.*)/);
  const Component = (buttonVariantMatches ? LinkWithButtonVariant : LinkBase) as ElementType;
  const variantName = buttonVariantMatches ? buttonVariantMatches[1] : variant;

  return (
    <Component variant={variantName} {...props}>
      {children}
    </Component>
  );
};

export default Link;
