// @ts-nocheck
/* eslint-disable */

import { zipObject, flatten, toPairs, flow, groupBy, get, isArray, chain } from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFromat from 'dayjs/plugin/advancedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import BigNumber from 'bignumber.js';

import {
  IsSameDayType,
  NoSpecialCharactersType,
  TodayType,
  DiffDaysType,
  TotalDaysType,
  TimeStampType,
  DaysType,
  FixDateType,
  ToNumberType,
  massageArrayToGetTvlType,
  fixDateGapsInTVLDataType
} from './models';

dayjs.extend(isSameOrAfter);
dayjs.extend(advancedFromat);
dayjs.extend(timezone);
dayjs.extend(utc);

export const isSameDay: IsSameDayType = (ts1, ts2) => dayjs(ts1).isSame(ts2, 'day');

export const noSpecialCharacters: NoSpecialCharactersType = string => {
  const rex = /[^a-zA-Z0-9 ]/g;
  return string.replace(rex, '');
};

export const TODAY: TodayType = () => dayjs().utc().format('MMM DD, YYYY');

const diffDays: DiffDaysType = (olderDate, recentDate) =>
  dayjs(recentDate).diff(dayjs(olderDate), 'day');

export const totalDays: TotalDaysType = currentDate => {
  const differentDays = diffDays(dayjs(currentDate), dayjs(new Date()));
  return differentDays;
};

export const timeStamp: TimeStampType = date => dayjs(date).format('X');
export const lastDay: DaysType = date => dayjs(date).isSameOrAfter(dayjs().subtract(24, 'hours'));
export const lastWeek: DaysType = date => dayjs(date).isSameOrAfter(dayjs().subtract(168, 'hours'));

export const fixDate: FixDateType = (date, unit = 'utc') => {
  switch (unit) {
    case 'utc':
      return dayjs(date).utc().startOf('day').format('MMM DD, YYYY');
    // convert to UTC timestamp format
    case 'cUtc':
      return dayjs(date, { utc: true }).utc().startOf('day').format();
    // convert to UTC timestamp format for middle of day
    case 'cUtcF':
      return dayjs(date, { utc: true }).startOf('day').add(18, 'hour').add(30, 'minute').format();
    case 'unix':
      return dayjs
        .unix(date as number)
        .utc()
        .startOf('day')
        .format('MMM DD, YYYY');
    case 'format':
      // treat as UTC timestamp
      return dayjs(date, { utc: true }).startOf('day').format('MMM DD, YYYY');
    default:
      // treat as UTC timestamp
      return dayjs(date, { utc: true }).startOf('day').format('MMM DD, YYYY');
  }
};

export const sortArrayByDate = (array, key = 'date') => {
  if (isArray(array)) {
    return array.sort((a, b) => {
      return +dayjs(a[key]) - +dayjs(b[key]);
    });
  }
};

export const notInBlackList = (array, selectedNetwork: string) => {
  return selectedNetwork === 'polkadot' ? array?.filter(x => x.date !== 'Apr 12, 2022') : array;
};

export const groupArray = (
  array: any[],
  orderByKey: string | Function,
  finalKeyForResult: string
) => {
  const returnable = chain(array)
    .groupBy(orderByKey)
    .toPairs()
    .map(currentData => {
      return zipObject([orderByKey, finalKeyForResult], currentData);
    })
    .reverse()
    .value();
  return returnable;
};

export const toNumber: ToNumberType = (value, decimals = 18) => {
  const bigNumber = new BigNumber(value).div(new BigNumber(10).pow(decimals)).toNumber();
  return bigNumber;
};

export const massageArrayToGetTvl: massageArrayToGetTvlType = (
  array,
  container,
  reducerKey,
  massageCompute = 'add'
) => {
  const initialObject = array?.map(item => {
    const mapper = container ? item[container] : item;
    const massagingForChart = mapper
      ?.filter(x => x[reducerKey])
      ?.map(item => {
        return {
          date: item?.date,
          val: item?.[reducerKey]
        };
      });
    return massagingForChart;
  });

  const initial = flatten(initialObject);
  const tvl = [];
  initial.forEach((x: any) => {
    const obj = tvl.find(o => o.date === x.date);
    if (obj) {
      if (massageCompute === 'add') {
        obj.val += x.val;
      } else if (massageCompute === 'max') {
        obj.val = obj.val >= x.val ? obj.val : x.val;
      }
    } else {
      tvl.push(x);
    }
  });
  return tvl;
};

export const getLatestValuesGroupedByDate = array => {
  const results = array?.reduce((acc: any, cur: any) => {
    const curDate = new Date(cur.timestamp);
    const curDateStr = `${curDate.getFullYear()}-${curDate.getMonth() + 1}-${curDate.getDate()}`;
    const existDateObj = acc.find(f => f.dStr === curDateStr);
    if (existDateObj) {
      const existDate = new Date(existDateObj.date);
      if (curDate.getTime() > existDate.getTime()) {
        acc = acc.filter(f => f.id !== existDateObj.id);
        acc.push(Object.assign(cur, { dStr: curDateStr }));
      }
    } else {
      acc.push(Object.assign(cur, { dStr: curDateStr }));
    }

    return acc;
  }, []);
  return results;
};

export const fixDateGapsInTVLData: fixDateGapsInTVLDataType = (
  tvlData,
  cumulative = true,
  convertToUTC = false
) => {
  const sortedDataTVL = sortArrayByDate(tvlData);
  let currentDateIterator = dayjs(sortedDataTVL[0]?.date).utc();
  const today = dayjs().startOf('day');
  const utcDiff = dayjs.utc().local().utcOffset();

  const diffDays =
    utcDiff <= 0
      ? today.diff(currentDateIterator, 'day')
      : today.diff(currentDateIterator, 'day') + 1;

  const availableKeys = Object.keys(sortedDataTVL[0] || {}).filter(x => x !== 'date');

  if (diffDays <= 0) {
    return tvlData;
  }
  let lastKnownVal = sortedDataTVL[0];

  const gapFilledHTVL = [];
  for (let i = 0; i <= diffDays; i++) {
    const payloadOfDateFound = sortedDataTVL.find(e =>
      dayjs(e?.date).isSame(currentDateIterator, 'day')
    );
    if (payloadOfDateFound) {
      const reduceKeyValues = availableKeys.reduce((keys, key) => {
        const defaultKeys = {
          val: get(payloadOfDateFound, 'val', 0)
        };

        keys = {
          ...keys,
          ...defaultKeys,
          [key]: payloadOfDateFound?.[key]
        };

        return keys;
      }, []);

      lastKnownVal = reduceKeyValues;
    }

    const lastUnknowVals = availableKeys.reduce((keys, key) => {
      const defaultKeys = {
        val: get(payloadOfDateFound, 'val', 0)
      };

      keys = {
        ...keys,
        ...defaultKeys,
        [key]: payloadOfDateFound?.[key] || 0
      };

      return keys;
    }, []);

    const finalObject = cumulative ? lastKnownVal : lastUnknowVals;

    gapFilledHTVL.push({
      date: fixDate(currentDateIterator, convertToUTC ? 'utc' : 'format'),
      ...finalObject
    });
    currentDateIterator = currentDateIterator.add(1, 'day');
  }
  return gapFilledHTVL;
};
