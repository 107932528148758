import { useMemo } from 'react';
import { SelectableTokenValue } from '@parallel-mono/business-components';
import { isNil } from 'lodash';

import { TransactionToken } from './useTransactionTokens';

export const useCheckBalance = (tokenValue: SelectableTokenValue<TransactionToken> | undefined) => {
  const isInsufficient = useMemo(() => {
    if (isNil(tokenValue) || isNil(tokenValue?.amount) || isNil(tokenValue?.token)) return false;
    return tokenValue.amount > tokenValue.token.maxAvailableBalance;
  }, [tokenValue]);

  return {
    isInsufficient,
    maxAvailableBalance: tokenValue?.token?.maxAvailableBalance
  };
};
