import { memo } from 'react';

import CurrentAccount from './CurrentAccount';

import { useAccount } from '@/hooks/useAccount';

export default memo(props => {
  const { account } = useAccount();

  return <CurrentAccount account={account} {...props} />;
});
