import {
  memo,
  ReactNode,
  ReactElement,
  useCallback,
  useState,
  useRef,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  useMemo,
  forwardRef,
  HTMLAttributes,
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { template, isBoolean } from 'lodash';

import { getRelativeElementClientRect } from '../../utils/dom';
import { useDualModeState } from '../../hooks/useDualModeState';
import { fadeInAnimation } from '../Animations/fadeInAnimation';

const PopoverStyled = styled.span`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;

const PopupWrapper = styled.div`
  animation: ${fadeInAnimation} 0.2s ease-in-out;
`;

enum Placement {
  topStart = 'top-start',
  top = 'top',
  topEnd = 'top-end',
  rightStart = 'right-start',
  right = 'right',
  rightEnd = 'right-end',
  bottomStart = 'bottom-start',
  bottom = 'bottom',
  bottomEnd = 'bottom-end',
  leftStart = 'left-start',
  left = 'left',
  leftEnd = 'left-end',
}

export type PlacementType = `${Placement}`;

export interface PopoverProps extends Omit<HTMLAttributes<HTMLSpanElement>, 'placement'> {
  defaultVisible?: boolean;
  visible?: boolean;
  placement?: PlacementType;
  trigger?: 'hover' | 'click';
  zIndex?: number;
  gap?: string;
  popup: ReactElement | null;
  children: ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  onVisibleChange?: (visible: boolean) => void;
  classNames?: Partial<{
    popupContainer: string;
  }>;
}

interface PopoverPosition {
  top: string;
  left: string;
  transform?: string;
  paddingTop?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingLeft?: string;
}

const POPOVER_POSITIONS: { [P in Placement]: PopoverPosition } = {
  [Placement.topStart]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translateY(-100%)',
    paddingBottom: '<%= gap %>',
  },
  [Placement.top]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translate(calc(-50% + <%= width / 2 %>px), -100%)',
    paddingBottom: '<%= gap %>',
  },
  [Placement.topEnd]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translate(calc(-100% + <%= width %>px), -100%)',
    paddingBottom: '<%= gap %>',
  },

  [Placement.rightStart]: {
    top: '<%= top %>px',
    left: '<%= right %>px',
    paddingLeft: '<%= gap %>',
  },
  [Placement.right]: {
    top: '<%= top %>px',
    left: '<%= right %>px',
    transform: 'translateY(calc(-50% + <%= height / 2 %>px))',
    paddingLeft: '<%= gap %>',
  },
  [Placement.rightEnd]: {
    top: '<%= top %>px',
    left: '<%= right %>px',
    transform: 'translateY(calc(-100% + <%= height %>px))',
    paddingLeft: '<%= gap %>',
  },

  [Placement.bottomStart]: {
    top: '<%= bottom %>px',
    left: '<%= left %>px',
    paddingTop: '<%= gap %>',
  },
  [Placement.bottom]: {
    top: '<%= bottom %>px',
    left: '<%= left %>px',
    transform: 'translateX(calc(-50% + <%= width / 2 %>px))',
    paddingTop: '<%= gap %>',
  },
  [Placement.bottomEnd]: {
    top: '<%= bottom %>px',
    left: '<%= left %>px',
    transform: 'translateX(calc(-100% + <%= width %>px))',
    paddingTop: '<%= gap %>',
  },

  [Placement.leftStart]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translateX(calc(-100%))',
    paddingRight: '<%= gap %>',
  },
  [Placement.left]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translate(calc(-100%), calc(-50% + <%= height / 2 %>px))',
    paddingRight: '<%= gap %>',
  },
  [Placement.leftEnd]: {
    top: '<%= top %>px',
    left: '<%= left %>px',
    transform: 'translate(calc(-100%), calc(-100% + <%= height %>px))',
    paddingRight: '<%= gap %>',
  },
};

export const Popover = memo(
  forwardRef<HTMLElement, PopoverProps>(
    (
      {
        defaultVisible = false,
        visible,
        placement = 'top',
        trigger = 'hover',
        zIndex = 10000,
        gap = '10px',
        popup,
        children,
        getPopupContainer = () => document.body,
        onVisibleChange,
        classNames,
        ...props
      },
      ref
    ) => {
      const [realVisible, setRealVisible] = useDualModeState(defaultVisible, visible);
      const popoverRef = useRef<HTMLElement>(null);
      const popoverContentRef = useRef<HTMLDivElement>(null);
      const [position, setPosition] = useState<PopoverPosition | null>(null);
      const [popupContainer, setPopupContainer] = useState<HTMLElement>(
        document.createElement('div')
      );

      const getCurrentPopupContainer = useCallback(() => {
        if (!popoverRef.current) return popupContainer;
        const newPopupContainer = getPopupContainer(popoverRef.current);
        const style = newPopupContainer.style;
        if (
          newPopupContainer !== document.body &&
          (!style?.position ||
            !['absolute', 'relative', 'fixed', 'sticky'].includes(style.position))
        ) {
          newPopupContainer.style.position = 'relative';
        }

        return newPopupContainer;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [popupContainer]);

      const toggleVisible = useCallback(
        (newVisible: boolean) => {
          setRealVisible(newVisible);
          onVisibleChange?.(newVisible);
        },
        [onVisibleChange, setRealVisible]
      );

      const openPopover = useCallback(() => {
        toggleVisible(true);
      }, [toggleVisible]);

      const closePopover = useCallback(() => {
        toggleVisible(false);
      }, [toggleVisible]);

      const triggerEvents = useMemo(() => {
        const triggerEventsMap = {
          hover: {
            onMouseEnter: openPopover,
            onMouseLeave: closePopover,
            onMouseMove: openPopover,
          },
          click: {
            onClick: openPopover,
          },
        };

        return triggerEventsMap[trigger];
      }, [openPopover, closePopover, trigger]);

      const popupWrapper = (
        <PopupWrapper
          ref={popoverContentRef}
          data-placement={placement}
          style={{ position: 'absolute', zIndex, ...position }}
          className={classNames?.popupContainer}
        >
          {popup}
        </PopupWrapper>
      );

      const getPopoverPosition = useCallback(() => {
        if (popoverRef.current && popoverContentRef.current) {
          const rect = getRelativeElementClientRect(
            popoverRef.current,
            popupContainer as HTMLElement
          );

          const { offsetWidth: contentWidth, offsetHeight: contentHeight } =
            popoverContentRef.current;
          const { top, right, bottom, left, width, height } = rect;

          const popoverPosition = Object.entries(POPOVER_POSITIONS[placement]).reduce(
            (result, [name, value]) => {
              result[name] = template(value)({
                top,
                right,
                bottom,
                left,
                width,
                height,
                contentWidth,
                contentHeight,
                gap,
              });
              return result;
            },
            {} as PopoverPosition
          );

          return popoverPosition;
        }
        return null;
      }, [gap, placement, popupContainer]);

      useImperativeHandle(ref, () => popoverRef.current as HTMLElement);

      useEffect(() => {
        if (isBoolean(visible)) setRealVisible(visible);
      }, [setRealVisible, visible]);

      useLayoutEffect(() => {
        const newPosition = getPopoverPosition();
        setPosition(newPosition);
        setPopupContainer(getCurrentPopupContainer());
      }, [getCurrentPopupContainer, getPopoverPosition, realVisible]);

      useEffect(() => {
        const closeCurrentPopover = (e: MouseEvent) => {
          const target = e.target as HTMLElement;
          if (
            e.target &&
            (popoverRef?.current?.contains(target) || popoverContentRef?.current?.contains(target))
          )
            return;
          closePopover();
        };
        document.documentElement.addEventListener('click', closeCurrentPopover, false);
        return () => document.documentElement.removeEventListener('click', closeCurrentPopover);
      }, [closePopover]);

      return (
        <PopoverStyled ref={popoverRef} onTouchStart={openPopover} {...props} {...triggerEvents}>
          {children}
          {popupContainer && realVisible && createPortal(popupWrapper, popupContainer)}
        </PopoverStyled>
      );
    }
  )
);
