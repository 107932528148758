import { Menu } from './components/Menus';

const polkadotChainProductIconPath = 'design/PDS_V3/chain_products/polkadot/';
const kusumaChainProductIconPath = 'design/PDS_V3/chain_products/kusama/';

export const useProductMenus: () => Record<string, Menu[]> = () => {
  return {
    polkadot: [
      { name: 'Overview', path: '/', icon: `${polkadotChainProductIconPath}overview` },
      { name: 'Swap', path: '/swap', icon: `${polkadotChainProductIconPath}swap` },
      {
        name: 'Money Market',
        path: '/lendAndBorrow',
        icon: `${polkadotChainProductIconPath}money-market`
      },
      {
        name: 'Stake',
        path: '/staking',
        icon: `${polkadotChainProductIconPath}staking`,
        enabled: true,
        description: 'Stake your assets, greater capital efficiency for additional profit.'
      },
      {
        name: 'Cross Chain',
        path: '/cross-chain',
        icon: `${polkadotChainProductIconPath}cross-chain`,
        description:
          'Instant and cheap transfer of your asset between chains in merely a few clicks.'
      },
      {
        name: 'Farm',
        path: '/farm',
        icon: `${polkadotChainProductIconPath}farm`
      },
      {
        name: 'Crowdloan',
        icon: `${polkadotChainProductIconPath}crowdloan`,
        url: 'https://crowdloan.parallel.fi'
      },
      {
        name: 'DAO Fi',
        title: 'Dao Fi',
        description:
          'Smart treasury management and efficient tooling such as payment streams. Built for DAOs, companies, and individuals.',
        path: '/stream',
        icon: `${polkadotChainProductIconPath}dao-fi`
      }
    ],
    kusama: [
      { name: 'Overview', path: '/', icon: `${kusumaChainProductIconPath}overview` },
      { name: 'Swap', path: '/swap', icon: `${kusumaChainProductIconPath}swap` },
      {
        name: 'Money Market',
        path: '/lendAndBorrow',
        icon: `${kusumaChainProductIconPath}money-market`
      },
      { name: 'Stake', path: '/staking', icon: `${kusumaChainProductIconPath}staking` },
      {
        name: 'Cross Chain',
        path: '/cross-chain',
        icon: `${kusumaChainProductIconPath}cross-chain`
      },
      {
        name: 'Farm',
        path: '/farm',
        icon: `${kusumaChainProductIconPath}farm`
      },
      {
        name: 'Crowdloan',
        icon: `${kusumaChainProductIconPath}crowdloan`,
        url: 'https://crowdloan-kusama.parallel.fi'
      },
      {
        name: 'DAO Fi',
        title: 'Dao Fi',
        description:
          'Smart treasury management and efficient tooling such as payment streams. Built for DAOs, companies, and individuals.',
        path: '/stream',
        icon: `${kusumaChainProductIconPath}dao-fi`
      }
    ]
  };
};

export const chainMenus = [
  {
    name: 'parallel',
    title: 'Polkadot',
    icon: 'DOT'
  },
  {
    name: 'heiko',
    title: 'Kusama',
    icon: 'KSM'
  }
];
