import BigNumberJS from 'bignumber.js';
import dayjs from 'dayjs';
import { isHex, u8aToHex } from '@polkadot/util';
import { decodeAddress } from '@polkadot/util-crypto';

import { balanceToAmountByDecimal } from '@/utils/calculations';
import { zero } from '@/utils/values';

export interface CalculatedValues {
  claimableTotal: BigNumberJS;
  claimed: BigNumberJS;
  claimableNow: BigNumberJS;
  remainingAfterCancel: BigNumberJS;
  depositNumber: BigNumberJS;
  remainingNumber: BigNumberJS;
}

export const calculatedValue = (
  blockTimestamp: string,
  deposit: string,
  remainingBalance: string,
  decimals: number,
  startTime: number,
  endTime: number
): CalculatedValues => {
  const startTimeUnix = dayjs(startTime).unix();
  const endTimeUnix = dayjs(endTime).unix();
  const nowTimeUnix = dayjs(Number(blockTimestamp)).unix();
  const depositNumber = balanceToAmountByDecimal<BigNumberJS>(deposit, decimals, 'bigNumber');
  const remainingNumber = balanceToAmountByDecimal<BigNumberJS>(
    remainingBalance,
    decimals,
    'bigNumber'
  );

  // received/sent/claimableTotal
  let claimableTotal = zero;
  if (startTimeUnix > nowTimeUnix) {
    // Not started
    claimableTotal = zero;
  } else if (startTimeUnix < nowTimeUnix && nowTimeUnix < endTimeUnix) {
    // Pending
    // rate = deposit / (endTime - startTime)
    // claimableTotal = (nowTime - startTime) * rate
    claimableTotal = depositNumber
      .div(endTimeUnix - startTimeUnix)
      .multipliedBy(nowTimeUnix - startTimeUnix);
  } else {
    // Complete
    claimableTotal = depositNumber;
  }

  const claimed = depositNumber.minus(remainingNumber);
  const claimableNow = claimableTotal.minus(claimed);
  const remainingAfterCancel = depositNumber.minus(claimableTotal);
  return {
    claimableTotal,
    claimed,
    claimableNow,
    remainingAfterCancel,
    depositNumber,
    remainingNumber
  };
};

// Check if address is same as current address
export const isCurrentAddress = (address: string, current: string): boolean => {
  try {
    if (
      (isHex(address) ? address : u8aToHex(decodeAddress(address))) ===
      u8aToHex(decodeAddress(current))
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};
