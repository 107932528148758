import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { uniqBy, isNumber } from 'lodash';
import { AssetId } from '@parallel-finance/types/interfaces';
import { SelectableToken } from '@parallel-mono/business-components';

import { DataContext } from '../DataContext';

import { ExtendAssetInfo } from './useExtendAssetInfos';
import { FullPool } from './usePools';

import { AssetsData, CurrencyId } from '@/hooks/types';
import { balanceToAmountByDecimal } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';

export type TransactionToken = SelectableToken & {
  assetId: CurrencyId;
  decimals: number;
  isNative: boolean;
  lpAssetId?: CurrencyId;
  guideAmount: number;
  maxAvailableBalance: number;
};

export type TokenPair = [TransactionToken, TransactionToken];

const getPossiblePairs = (pools: FullPool[], onlyPoolPairs: boolean) => {
  const pairs = pools.map(({ fromAssetId, toAssetId }) => [fromAssetId, toAssetId]);
  const allAssetsIds = uniqBy(pairs.flat(), (assetId: AssetId) => assetId.toString());
  if (onlyPoolPairs) {
    return pairs;
  }
  const possiblePairs = [];
  for (let index = 0; index < allAssetsIds.length; index += 1) {
    for (let innerIndex = 0; innerIndex < allAssetsIds.length; innerIndex += 1) {
      if (innerIndex !== index) possiblePairs.push([allAssetsIds[index], allAssetsIds[innerIndex]]);
    }
  }

  return possiblePairs;
};

const getAssetInfo = (assetInfos: ExtendAssetInfo[], assetId: AssetId) => {
  const assetInfo = assetInfos.find(token => token.assetId === assetId.toString());

  if (!assetInfo) return null;

  return {
    name: assetInfo.name ?? assetInfo.symbol,
    assetId: assetInfo.assetId,
    balance: assetInfo.availableBalance,
    maxAvailableBalance: assetInfo.maxAvailableBalance,
    decimals: assetInfo.decimals.toNumber(),
    symbol: assetInfo.symbol,
    isNative: assetInfo.isNative
  };
};

const getTokenPairs = (
  possiblePairs: AssetId[][],
  pools: FullPool[],
  assetsPrice: AssetsData,
  assetInfos: ExtendAssetInfo[]
) => {
  const getPairsInfo = (fromAssetId: AssetId, toAssetId: AssetId): TokenPair | null => {
    const builtInPool = pools.find(
      pool =>
        (pool.fromAssetId.eq(fromAssetId) && pool.toAssetId.eq(toAssetId)) ||
        (pool.fromAssetId.eq(toAssetId) && pool.toAssetId.eq(fromAssetId))
    );

    const fromAssetInfo = getAssetInfo(assetInfos, fromAssetId);
    const toAssetInfo = getAssetInfo(assetInfos, toAssetId);
    const lpAssetId = builtInPool?.lpTokenId?.toString();

    if (!fromAssetInfo || !toAssetInfo) return null;

    const isSamePool = builtInPool && builtInPool.fromAssetId.eq(fromAssetId);

    const fromGuideAmount = builtInPool
      ? balanceToAmountByDecimal<number>(
          isSamePool ? builtInPool.baseAmount : builtInPool.quoteAmount,
          isSamePool ? fromAssetInfo.decimals : toAssetInfo.decimals,
          'number'
        )
      : 0;
    const toGuideAmount = builtInPool
      ? balanceToAmountByDecimal<number>(
          isSamePool ? builtInPool.quoteAmount : builtInPool.baseAmount,
          isSamePool ? toAssetInfo.decimals : fromAssetInfo.decimals,
          'number'
        )
      : 0;

    return [
      {
        assetId: fromAssetId.toString(),
        name: fromAssetInfo.symbol,
        symbol: fromAssetInfo.symbol,
        decimals: fromAssetInfo.decimals,
        balance: fromAssetInfo.balance,
        displayBalance: isNumber(fromAssetInfo.balance)
          ? balanceFormatter(fromAssetInfo.balance)
          : '',
        maxAvailableBalance: fromAssetInfo.maxAvailableBalance,
        isNative: fromAssetInfo.isNative,
        priceInUSD: assetsPrice[fromAssetId.toString()] ?? 0,
        description: fromAssetInfo.name,
        lpAssetId,
        guideAmount: fromGuideAmount
      },
      {
        assetId: toAssetId.toString(),
        name: toAssetInfo.symbol,
        symbol: toAssetInfo.symbol,
        decimals: toAssetInfo.decimals,
        balance: toAssetInfo.balance,
        displayBalance: isNumber(toAssetInfo.balance) ? balanceFormatter(toAssetInfo.balance) : '',
        maxAvailableBalance: toAssetInfo.maxAvailableBalance,
        isNative: toAssetInfo.isNative,
        priceInUSD: assetsPrice[toAssetId.toString()] ?? 0,
        description: toAssetInfo.name,
        lpAssetId,
        guideAmount: toGuideAmount
      }
    ];
  };

  return possiblePairs.reduce((result, [fromAssetId, toAssetId]) => {
    const pairInfo = getPairsInfo(fromAssetId, toAssetId);
    if (pairInfo) {
      result.push(pairInfo);
    }
    return result;
  }, [] as TokenPair[]);
};

const getSortedToken = (tokens: TransactionToken[]) => {
  return tokens.sort((a, b) => Number(a.assetId) - Number(b.assetId));
};

export const useTransactionTokens = (onlyPoolPairs: boolean = false) => {
  const { poolSource, assetsPriceSource: assetsPrice, assetInfoSource } = useContext(DataContext);
  const { pools, isReady: poolsIsReady } = poolSource;
  const { assetInfos, isReady: assetInfosIsReady } = assetInfoSource;
  const [tokenPairs, setTokenPairs] = useState<TokenPair[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [fromTokens, setFromTokens] = useState<TransactionToken[]>([]);
  const [toTokens, setToTokens] = useState<TransactionToken[]>([]);

  const possiblePairs = useMemo(
    () => getPossiblePairs(pools, onlyPoolPairs),
    [onlyPoolPairs, pools]
  );

  const getToTokens = useCallback(
    (fromAssetId: string) => {
      const toTokenPairs = tokenPairs.filter(pair =>
        pair.some(token => token.assetId === fromAssetId)
      );

      const newToTokens = uniqBy(
        toTokenPairs.map(
          pair => pair.find(({ assetId }) => assetId !== fromAssetId) as TransactionToken
        ),
        'symbol'
      );

      setToTokens(getSortedToken(newToTokens));

      return newToTokens;
    },
    [tokenPairs]
  );

  useEffect(() => {
    if (poolsIsReady && assetInfosIsReady && assetsPrice) {
      const newTokenPairs = getTokenPairs(possiblePairs, pools, assetsPrice, assetInfos);
      if (!isReady) setIsReady(true);
      setTokenPairs(newTokenPairs);
      setFromTokens(getSortedToken(uniqBy(newTokenPairs.flat(), 'symbol')));
    }
  }, [assetInfos, assetInfosIsReady, assetsPrice, isReady, pools, poolsIsReady, possiblePairs]);

  return { isReady, tokenPairs, fromTokens, toTokens, getToTokens };
};
