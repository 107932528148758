import { useEffect, useCallback } from 'react';
import axios from 'axios';

import useScopeState from './useScopeState';

import config from '@/config';

const useTotalValue = () => {
  const [totalValue, setTotalValue] = useScopeState<number>(0);

  const fetchTotalValue = useCallback(async () => {
    try {
      const { data } = await axios.get<{ slug: string; url: string; tvl: number }[]>(
        `${config.DEFILLAMA_SERVICE_URL}/protocols`
      );
      const protocolsData = data
        .filter(i => i.url === 'https://parallel.fi')
        .reduce((prev, cur) => {
          return prev + cur.tvl;
        }, 0);

      setTotalValue(protocolsData);
    } catch (error) {
      console.log(error);
    }
  }, [setTotalValue]);

  useEffect(() => {
    fetchTotalValue();
  }, [fetchTotalValue]);

  return totalValue;
};

export default useTotalValue;
