import {
  ComponentType,
  useRef,
  memo,
  useImperativeHandle,
  forwardRef,
  cloneElement,
  Children
} from 'react';
import { noop } from 'lodash';
import { Modal, ModalProps } from '@parallel-mono/components';
import styled from 'styled-components';

import usePatchElement from './usePatchElement';

import { AnyPlainObject } from '@/typings/basic';

interface ElementsHolderRef {
  patchElement: ReturnType<typeof usePatchElement>[1];
}

const ElementsHolder = memo(
  forwardRef<ElementsHolderRef>((_props, ref) => {
    const [elements, patchElement] = usePatchElement();
    useImperativeHandle(
      ref,
      () => ({
        patchElement
      }),
      [patchElement]
    );

    return (
      <>
        {elements.map(ele => {
          return cloneElement(ele, {
            children: Children.map(ele.props.children, child => {
              return cloneElement(child, _props);
            })
          });
        })}
      </>
    );
  })
);

const StyledModal = styled(Modal)`
  .compact {
    padding: 0;
  }
  .top {
    align-self: flex-start;
    margin-top: 5rem;
    overflow-y: auto;
  }
`;

interface ModalFunc {
  closeModal: () => void;
}

let uuid = 0;

const useModal = <P extends object>(
  Component: ComponentType<P>,
  modalProps: Omit<ModalProps, 'children' | 'isOpen' | 'onClose'>,
  componentProps?: Omit<P, 'data' | 'closeModal'>
) => {
  const holderRef = useRef<ElementsHolderRef>(null);

  let removeModal = noop;
  const closeModal: ModalFunc['closeModal'] = () => removeModal();

  const openModal = <T extends AnyPlainObject>(data?: T) => {
    uuid += 1;
    const modal = (
      <StyledModal key={`modal-${uuid}`} isOpen onClose={closeModal} {...modalProps}>
        <Component data={data} {...(componentProps as P)} closeModal={closeModal} />
      </StyledModal>
    );

    removeModal = holderRef?.current?.patchElement(modal) || removeModal;
  };

  return {
    openModal,
    closeModal,
    holder: <ElementsHolder ref={holderRef} {...componentProps} />
  };
};

export type { ModalFunc };
export default useModal;
