import { ApiPromise, WsProvider } from '@polkadot/api';
import { cryptoWaitReady } from '@polkadot/util-crypto';
import { types, typesBundle, rpc } from '@parallel-finance/type-definitions';

export enum ApiState {
  Initial,
  Ready,
  Error
}

export interface ApiSocket {
  state: ApiState.Initial | ApiState.Error;
  url: string;
}

export interface ApiSocketReady {
  api: ApiPromise;
  state: ApiState.Ready;
  url: string;
}

export const isSocketReady = (socket: ApiSocket | ApiSocketReady): socket is ApiSocketReady => {
  return socket.state === ApiState.Ready;
};

const initApiSocket = async (url: string): Promise<ApiSocket | ApiSocketReady> => {
  try {
    console.log(`Begin connecting to socket: ${url}`);
    await cryptoWaitReady();
    const instance = await ApiPromise.create({
      provider: new WsProvider(url),
      types,
      typesBundle,
      rpc
    });
    console.log(`Successfully connected to socket: ${url}`);
    return {
      api: instance,
      state: ApiState.Ready,
      url
    };
  } catch (e) {
    console.error(`Connect to ${url} error: ${e}`);
    return {
      state: ApiState.Error,
      url
    };
  }
};

export default initApiSocket;
