import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';
import { BN } from '@polkadot/util';

import { AssetDetailInfo } from '@/hooks/types';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { addressToHex } from '@/utils/utils';

const generateXcmTx = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  const createXcmV3VersionedMultiLocation = (address: string) => ({
    V3: {
      parents: 1,
      interior: {
        X1: {
          AccountId32: {
            network: null,
            id: addressToHex(address)
          }
        }
      }
    }
  });
  return api.tx.xTokens.transfer(
    asset.assetId,
    amountToBalanceByDecimals<BN>(amount || 0, asset.decimals, 'bn'),
    createXcmV3VersionedMultiLocation(account?.address),
    {
      Unlimited: ''
    }
  );
};

export const generateToPolkadotTx = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number
) => {
  return generateXcmTx(api, account, asset, amount);
};
