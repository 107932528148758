import { HTMLAttributes, memo, forwardRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { TooltipProps, Tooltip } from '../Tooltip';
import tinyColor from 'tinycolor2';
import cx from 'classnames';

export type Marker = {
  color: string;
  value: number;
  tip?: Omit<TooltipProps, 'children'>;
};

export type BaseProgressBarClassNames = {
  bar: string;
  cursor: string;
  marker: string;
};

export type BaseProgressBarVariant = 'bar' | 'cursor';

export type BaseProgressBarProps = HTMLAttributes<HTMLDivElement> & {
  background: string;
  color: string;
  markers?: Marker[];
  value: number;
  classNames?: Partial<BaseProgressBarClassNames>;
  transitionDuration?: CSSProperties['transitionDuration'];
  transitionTiming?: CSSProperties['transitionTimingFunction'];
  transitionDelay?: CSSProperties['transitionDelay'];
} & (
    | {
        variant: 'bar';
        cursorBackground?: string;
      }
    | {
        variant: 'cursor';
        cursorBackground: string;
      }
  );

const Slot = styled.div<{ color?: string }>`
  position: relative;
  background: ${({ theme, color }) => color || theme.skin.background.slot};
  height: 1ex;
  display: flex;
  border-radius: 10000px;
  overflow: visible;
`;

const ValueBar = styled.span<{
  $value: number;
  $color: string;
  $delay?: CSSProperties['transitionDelay'];
  $timing?: CSSProperties['transitionTimingFunction'];
  $duration?: CSSProperties['transitionDuration'];
}>`
  width: ${({ $value }) => $value}%;
  background-color: ${({ $color }) => $color};
  height: 100%;
  border-radius: 10000px;
  transition-property: width;
  transition-delay: ${({ $delay = '0' }) => $delay};
  transition-timing-function: ${({ $timing = 'linear' }) => $timing};
  transition-duration: ${({ $duration = '0.2s' }) => $duration};
`;

const ValueCursor = styled.span<{
  $value: number;
  $color: string;
  $background: string;
  $delay?: CSSProperties['transitionDelay'];
  $timing?: CSSProperties['transitionTimingFunction'];
  $duration?: CSSProperties['transitionDuration'];
}>`
  background-color: ${({ $background }) => $background};
  border: 3px solid ${({ $color }) => $color};
  height: calc(100% + 1ex);
  width: 2ex;
  left: ${({ $value }) => $value}%;
  transform: translate(-50%, -50%);
  top: 50%;
  border-radius: 10000px;
  cursor: pointer;
  position: absolute;
  box-shadow: 0px 4px 16px ${({ $color }) => tinyColor($color).setAlpha(0.15).toString()};
  transition-property: left;
  transition-delay: ${({ $delay = '0' }) => $delay};
  transition-timing-function: ${({ $timing = 'linear' }) => $timing};
  transition-duration: ${({ $duration = '0.2s' }) => $duration};
`;

const Marker = styled.div<{ $value: number; $color?: string }>`
  cursor: pointer;
  position: absolute;
  height: calc(100% + 1ex);
  top: 50%;
  display: flex;
  left: ${({ $value }) => $value}%;
  transform: translate(-50%, -50%);
  width: 1ex;
  background-color: ${({ $color }) => $color};
  border-radius: 10000px;
  border: 1px solid ${({ theme }) => theme.skin.background.main};
`;

const MarkerTip = styled(Tooltip).attrs({
  children: <span />,
})`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const BaseProgressBar = memo(
  forwardRef<HTMLDivElement, BaseProgressBarProps>((props, ref) => {
    const {
      background,
      markers,
      value,
      color,
      variant,
      classNames,
      cursorBackground,
      transitionTiming,
      transitionDelay,
      transitionDuration,
      ...others
    } = props;
    return (
      <Slot ref={ref} color={background} {...others}>
        {variant === 'bar' && (
          <ValueBar
            className={cx(classNames?.bar)}
            $value={Math.min(value, 100)}
            $color={color}
            $delay={transitionDelay}
            $timing={transitionTiming}
            $duration={transitionDuration}
          />
        )}
        {variant === 'cursor' && (
          <ValueCursor
            className={cx(classNames?.cursor)}
            $value={Math.min(value, 100)}
            $background={cursorBackground as string}
            $color={color}
            $delay={transitionDelay}
            $timing={transitionTiming}
            $duration={transitionDuration}
          />
        )}
        {markers?.map((marker, index) => (
          <Marker
            key={index}
            $value={Math.min(marker.value, 100)}
            $color={marker.color ?? color}
            className={cx(classNames?.marker)}
          >
            {marker.tip && <MarkerTip placement="bottom" gap="0" {...marker.tip} />}
          </Marker>
        ))}
      </Slot>
    );
  })
);
