// @ts-nocheck
/* eslint-disable */
import { massageArrayToGetTvl, TODAY } from '../helpers/others';

export const AmmTvl = allSwaps => {
  if (allSwaps) {
    const newSwaps = allSwaps.map((item, i) => {
      const currentAssets = item?.assets;
      const today = TODAY();
      const haveDataForToday = currentAssets?.filter(x => x.date === today)[0];
      const lastItemInArray = currentAssets?.[currentAssets?.length - 1];
      if (!haveDataForToday) {
        currentAssets.push({ ...lastItemInArray });
        const modifiedCurrent = currentAssets?.[currentAssets?.length - 1];
        modifiedCurrent.date = today;
      }
      return item;
    });
    return massageArrayToGetTvl(newSwaps, 'assets', 'val');
  }
};
