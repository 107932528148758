import { memo, useContext, useMemo } from 'react';
import { Tooltip, SmallText, Inline, H5, Stack } from '@parallel-mono/components';
import {
  SelectableTokenValue,
  InfoPanel,
  InfoPanelProps
} from '@parallel-mono/business-components';
import styled from 'styled-components';
import { formatNumber } from '@parallel-mono/utils';

import { TransactionToken } from '../hooks/useTransactionTokens';
import { DataContext } from '../DataContext';

import { Collapse } from '@/components';
import { balanceFormatter } from '@/utils/format';

const CollapseStyled = styled(Collapse)`
  box-shadow: none;
`;

interface SwapDetailProps {
  fromTokenValue: SelectableTokenValue<TransactionToken>;
  toTokenValue: SelectableTokenValue<TransactionToken>;
  priceImpact: number;
  slippage: number;
  feeMultiplier: number;
}

export const SwapDetail = memo<SwapDetailProps>(
  ({ fromTokenValue, toTokenValue, priceImpact, slippage, feeMultiplier }) => {
    const { assetsPriceSource } = useContext(DataContext);

    const totalFee = useMemo(
      () => feeMultiplier * fromTokenValue.amount!,
      [feeMultiplier, fromTokenValue.amount]
    );

    const exchangeRate = useMemo(
      () =>
        toTokenValue.amount && fromTokenValue.amount
          ? toTokenValue.amount / fromTokenValue.amount
          : 0,
      [fromTokenValue.amount, toTokenValue.amount]
    );

    const exchangeAmount = useMemo(
      () =>
        formatNumber(exchangeRate, { decimal: exchangeRate > 1 ? 4 : 6, threshold: { min: 1e-6 } }),
      [exchangeRate]
    );

    const fromPerAssetUsdPrice = useMemo(
      () => assetsPriceSource?.[fromTokenValue.token.assetId] ?? 0,
      [assetsPriceSource, fromTokenValue.token.assetId]
    );

    const infos = useMemo<InfoPanelProps['infos']>(
      () => [
        {
          title: 'Minimum Received',
          tip: 'Worst case scenario, this indicates the least amount of tokens you will receive based on slippage tolerance.',
          value: `${balanceFormatter((1 - slippage / 100) * toTokenValue.amount!)} ${
            toTokenValue.token.symbol
          }`
        },
        {
          title: 'Price Impact',
          tip: `This is an estimate of what slippage to actually expect based on the size of the order the user is placing and what's going on in the market. This tells the user how much of the desired token they are likely to actually receive once the trade executes.`,
          value: formatNumber(priceImpact, {
            output: 'percent',
            decimal: 4,
            threshold: { min: 1e-6 }
          })
        },
        {
          title: 'LP Fee',
          tip: 'This is the trading fee for the order which will be subtracted from the total received.',
          value: `${balanceFormatter(totalFee)} ${fromTokenValue.token.symbol}`
        },
        {
          title: 'Slippage',
          tip: 'This is the amount the price moves in a trading pair between when a transaction is submitted and when it is executed.',
          value: `${slippage}%`
        }
      ],
      [fromTokenValue, priceImpact, slippage, toTokenValue, totalFee]
    );

    return (
      <CollapseStyled
        containerPadding="0"
        header={
          <Inline gap="0.25rem" alignItems="center">
            <H5>
              1 {fromTokenValue.token.symbol} = {exchangeAmount} {toTokenValue.token.symbol}
            </H5>
            <SmallText skin="secondary">
              ({formatNumber(fromPerAssetUsdPrice, { output: 'currency' })})
            </SmallText>
            <Tooltip content="Exchange Rate" />
          </Inline>
        }
      >
        <Stack inset="1.2rem 0 0 0">
          <InfoPanel infos={infos} />
        </Stack>
      </CollapseStyled>
    );
  }
);
