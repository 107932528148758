import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useRoutes, Navigate } from 'react-router-dom';
import { memo, ReactElement, useEffect, useMemo, useRef } from 'react';
import { useDebounce, useScroll } from 'react-use';
import ReactGa from 'react-ga';
import { floor } from 'lodash';
import { Stack } from '@parallel-mono/components';

import Overview from '../Overview';
import MoneyMarket from '../MoneyMarket';
import Staking from '../Staking';
import Farm from '../Farm';
import Stream from '../Stream';
import Swap from '../Swap';
import Header from '../Header';
import { Maintenance } from '../Maintenance';

import {
  ChainConnectionProvider,
  Chains,
  GA_MAP,
  getTrackerNameByPath,
  useConnectChains
} from '@/hooks';
import getFeatureToggle from '@/utils/toggles/getFeatureToggle';
import WalletContextProvider from '@/contexts/WalletsContext';
import CrossChain from '@/pages/CrossChain';
import { AppGlobalStyles } from '@/components/AppGlobalStyles';
import { CreateApolloClient } from '@/components/CreateApolloClient';
import { ParallelToastProvider } from '@/components/ParallelToastProvider';
import config from '@/config';
import { AccountsContextProvider } from '@/contexts/AccountsContext';
import { RedirectFromHashRoute } from '@/components/RedirectFromHashRoute';
import {
  AssetInfosContextProvider,
  AssetPriceContextProvider,
  NativeAssetInfoContextProvider,
  RelayAssetInfoContextProvider
} from '@/contexts/AssetsInfoContext';

type RouteItem = {
  path: string;
  element: ReactElement;
  toggle?: string;
};

const MainContainer = styled.div<{ bgUrl?: string }>`
  height: 100vh;
  background-size: 100%;
  min-height: 100vh;
  overflow: scroll;
  background-size: 100%;
  position: relative;
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: ${({ bgUrl }) =>
      bgUrl
        ? `url(${bgUrl})`
        : `radial-gradient(402px 277.5px at 50% 124px, #fad374, transparent),
    radial-gradient(402px 277.5px at 30% 133px, #76e4fe, transparent),
    radial-gradient(402px 277.5px at 70% 90px, #f5818d, transparent)`};
    background-repeat: no-repeat;
    background-size: contain;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    opacity: ${({ bgUrl }) => (bgUrl ? '1' : '0.15')};
    filter: blur(57px);
    z-index: -1;
  }
`;
const ContentWrapper = styled.div`
  padding: 2.5rem;
  ${({ theme }) => theme.breakpoints.down('lg')`
    height: calc(100vh - 9rem);
    padding: 0.5rem 0 0 0;
  `};
`;

const Content = styled(Stack)`
  max-width: 1280px;
  margin: 0 auto;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('lg')`
    padding: 0 1.5rem 120px;
  `};

  ${({ theme }) => theme.breakpoints.down('md')`
    padding-bottom: 75px;
  `};
`;

const allRoutes: RouteItem[] = [
  { path: '/', element: <Overview /> },
  { path: '/lendAndBorrow', element: <MoneyMarket /> },
  { path: '/staking', element: <Staking /> },
  { path: '/cross-chain', element: <CrossChain /> },
  { path: '/farm', element: <Farm /> },
  { path: '/swap', element: <Swap /> },
  { path: '/stream', element: <Stream /> },
  { path: '/overview', element: <Navigate to="/" /> }
];

const DEFAULT_HEADER_OPACITY = 0.85;
const HEADER_HEIGHT = 84; // px

const legacyRoutesSupport = <RedirectFromHashRoute from="*" />;

const Home = () => {
  const openRoutes = useMemo(
    () => allRoutes.filter(route => !route.toggle || getFeatureToggle(route.toggle || '')),
    []
  );
  const ref = useRef(null);
  const { y: scrollY } = useScroll(ref);
  const opacity = floor(DEFAULT_HEADER_OPACITY * (scrollY / HEADER_HEIGHT), 2);
  const headerBgOpacity = opacity > DEFAULT_HEADER_OPACITY ? DEFAULT_HEADER_OPACITY : opacity;

  const routes = useRoutes(openRoutes);

  const location = useLocation();
  useEffect(() => {
    if (config.PROD) {
      const trackers = Object.values(GA_MAP);
      ReactGa.initialize(trackers, {
        debug: false,
        alwaysSendToDefaultTracker: false
      });
    }
  }, []);
  useDebounce(
    () => {
      if (config.PROD) {
        const trackerName = getTrackerNameByPath(location?.pathname);
        if (trackerName) {
          ReactGa.pageview(location.pathname + location.search, [trackerName]);
        }
      }
    },
    500,
    [location.pathname]
  );

  return (
    <MainContainer ref={ref}>
      <Header bgOpacity={headerBgOpacity} />
      <ContentWrapper>
        <Content gap="2.5rem">
          {routes}
          {legacyRoutesSupport}
          {getFeatureToggle('TOGGLE_LAST_COMMIT') && config.lastCommit && (
            <div>Last Commit: {config.lastCommit}</div>
          )}
        </Content>
      </ContentWrapper>
    </MainContainer>
  );
};

const Structure = memo(() => {
  const { isChainsReady } = useConnectChains();

  return isChainsReady([Chains.ParaChain]) ? (
    <NativeAssetInfoContextProvider>
      <AssetInfosContextProvider>
        <AssetPriceContextProvider>
          {isChainsReady([Chains.RelayChain]) ? (
            <RelayAssetInfoContextProvider>
              <Home />
            </RelayAssetInfoContextProvider>
          ) : (
            <Home />
          )}
        </AssetPriceContextProvider>
      </AssetInfosContextProvider>
    </NativeAssetInfoContextProvider>
  ) : (
    <Home />
  );
});

export const App = memo(() => {
  const showMaintenance = useMemo(() => getFeatureToggle('SLOT_SWAP_MAINTENANCE'), []);

  return showMaintenance ? (
    <Maintenance />
  ) : (
    <AppGlobalStyles>
      <CreateApolloClient>
        <ChainConnectionProvider>
          <AccountsContextProvider>
            <WalletContextProvider>
              <ParallelToastProvider>
                <Structure />
              </ParallelToastProvider>
            </WalletContextProvider>
          </AccountsContextProvider>
        </ChainConnectionProvider>
      </CreateApolloClient>
    </AppGlobalStyles>
  );
});
