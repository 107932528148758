// @ts-nocheck
/* eslint-disable */

import { PoolNamesType } from './models';

export const poolName: PoolNamesType = (poolInfo, assets) => {
  if (poolInfo && assets) {
    const poolNames = poolInfo.map(item => {
      const baseId = assets?.[item?.base_asset_id];
      const quoteId = assets?.[item.quote_asset_id];
      return {
        id: item.id,
        ticker: `${baseId?.name}-${quoteId?.name}`
      };
    });
    return poolNames;
  }
};
