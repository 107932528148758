import { ApiPromise } from '@polkadot/api';
import { KeyringPair } from '@polkadot/keyring/types';

import { Chains } from '../../types';

import { generateToPolkadotTx } from './generateToPolkadot';
import { generateToPhalaTx } from './generateToPhalaTx';
import { generateToStatemintTx } from './generateToStatemintTx';
import { generateToAcalaTx } from './generateToAcalaTx';
import { generateToInterlayTx } from './generateToInterlayTx';

import { AssetDetailInfo } from '@/hooks/types';

const txsGenerationMap = {
  [Chains.Polkadot]: generateToPolkadotTx,
  [Chains.Phala]: generateToPhalaTx,
  [Chains.Statemint]: generateToStatemintTx,
  [Chains.Acala]: generateToAcalaTx,
  [Chains.Interlay]: generateToInterlayTx
} as Record<string, Function>;

export const generateTxs = (
  api: ApiPromise,
  account: KeyringPair,
  asset: AssetDetailInfo,
  amount: number,
  toChain: Chains,
  toChainNativeAsset?: AssetDetailInfo
) => {
  const generate = txsGenerationMap[toChain];
  if (!generate) {
    throw new Error('Please add the transaction generating method');
  }
  return generate(api, account, asset, amount, toChainNativeAsset);
};
