import axios from 'axios';

import { AssetsResponse } from './types';

import config from '@/config';

export const getAssets = async () => {
  const { data } = await axios.get<AssetsResponse>(`${config.ANALYSIS_SERVICE_URL}/api/assets`);
  return data;
};
