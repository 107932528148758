import { FC, useCallback, useMemo, useRef, useState, MouseEvent, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Inline, Stack, H3, SmallText, Text, Card } from '@parallel-mono/components';

import { truncateTextEnd, truncateTextMid } from '@/utils/format';
import { Input, Wrapper as InputWrapper, AccountImage } from '@/components';
import { isValidAddress } from '@/utils/utils';
import { WalletType } from '@/contexts/WalletsContext';

const OutWrapper = styled.div`
  position: relative;
`;

const StyledInputWrapper = styled(InputWrapper).attrs({
  gap: 0,
  inset: 0
})`
  position: relative;
`;

const ErrorMessage = styled(Text).attrs({ type: 'error' })`
  margin-top: 0.5rem;
`;

const AddressWrapper = styled(Inline).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
  inset: '0',
  gap: '0'
})`
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 20px);
  margin-left: 0;
`;

const AddressSelectWrapper = styled(Card)`
  position: absolute;
  width: 100%;
  box-shadow: var(--box-shadow01);
  max-height: 16rem;
  padding: 0;
  overflow: scroll;
`;

const AddressName = styled(H3)<{ grayText?: boolean }>`
  color: ${props => props.grayText && `var(--clr-gray02)`};
`;

const AddressOption = styled(Inline).attrs({
  gap: '0.75rem'
})`
  cursor: pointer;
`;

type AccountOption = {
  address: string;
  name: string;
  type: WalletType;
};
type Props = {
  initialOptions: AccountOption[];
  onChange: (address: string) => void;
  validFn?: (address: string) => boolean;
  errorMessage?: string;
  placeholder?: string;
};

export const AddressInput: FC<Props> = ({
  initialOptions,
  onChange,
  validFn,
  errorMessage,
  placeholder
}) => {
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [addressOptions, setAddressOptions] = useState(initialOptions);
  const [inputValue, setInputValue] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState<{ address: string; name: string } | null>(
    null
  );
  const [isInputFocus, setIsInputFocus] = useState(false);

  const addressInputRef = useRef<HTMLInputElement>(null);

  const matchOption = useCallback(
    (value: string) =>
      initialOptions.find(account => account.name === value || account.address === value),
    [initialOptions]
  );

  const handleInputChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setAddressOptions(
        initialOptions.filter(
          account => account.address.includes(value) || account.name.includes(value)
        )
      );
      setInputValue(value);
      const address = matchOption(value)?.address;
      onChange(address || value);
      setSelectedOption(null);
    },
    [initialOptions, onChange, matchOption]
  );

  const handleInputBlur = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setIsInputFocus(false);
      setShowAddressOptions(false);
      setAddressOptions(initialOptions);

      const matchedOption = matchOption(value);
      if (matchedOption) {
        setInputValue('');
        setSelectedOption(matchedOption);
      }
      if (validFn) {
        const isValid = validFn(matchedOption?.address || value);
        setShowErrorMessage(value !== '' ? !isValid : false);
      }
    },
    [matchOption, initialOptions, validFn]
  );

  const handleSelectAddress = useCallback(
    (account: AccountOption) => {
      setSelectedOption(account);
      setInputValue('');
      setShowErrorMessage(false);
      onChange(account.address);
    },
    [onChange]
  );

  const handleAddressInputClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault(); // to prevent the default onblur event;

    // if the input is focused, will not clear the value when click it again
    if (isInputFocus) {
      return;
    }
    if (!isValidAddress(inputValue)) {
      setInputValue('');
    }
    if (addressInputRef && addressInputRef.current) {
      addressInputRef.current.focus();
    }
    setIsInputFocus(true);
  };

  const showErrorState = useMemo(
    () => showErrorMessage && !showAddressOptions,
    [showErrorMessage, showAddressOptions]
  );

  return (
    <OutWrapper>
      <StyledInputWrapper withError={showErrorState} onMouseDown={handleAddressInputClick}>
        <Input
          ref={addressInputRef}
          placeholder={selectedOption ? undefined : placeholder}
          value={inputValue}
          type="string"
          onFocus={() => {
            setIsInputFocus(true);
            setShowAddressOptions(true);
          }}
          onBlur={handleInputBlur}
          onChange={handleInputChange}
        />
        {selectedOption && (
          <AddressWrapper>
            <AddressName grayText={isInputFocus}>
              {truncateTextEnd(selectedOption.name)}
            </AddressName>
            <SmallText skin="secondary">{truncateTextEnd(selectedOption.address)}</SmallText>
          </AddressWrapper>
        )}
      </StyledInputWrapper>
      {showErrorState && <ErrorMessage>{errorMessage || 'Address is not valid.'}</ErrorMessage>}

      {showAddressOptions && addressOptions.length !== 0 && (
        <AddressSelectWrapper>
          <Stack inset="1.5rem" gap="2rem">
            {addressOptions.map((account, index) => (
              <AddressOption
                key={account.address}
                onMouseDown={() => {
                  handleSelectAddress(account);
                }}
              >
                <AccountImage type={account.type} address={account.address} index={index} />
                <Stack gap="0">
                  <Text>{truncateTextEnd(account.name)}</Text>
                  <SmallText skin="secondary">{truncateTextMid(account.address, 18, 18)}</SmallText>
                </Stack>
              </AddressOption>
            ))}
          </Stack>
        </AddressSelectWrapper>
      )}
    </OutWrapper>
  );
};
