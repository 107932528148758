import { Icon } from '@parallel-mono/components';
import { isFunction, isObject, isUndefined, mapValues } from 'lodash';
import { createContext, memo, ReactNode, useContext, useMemo } from 'react';
import {
  ToastContainer,
  Icons,
  IconProps,
  toast,
  ToastPromiseParams,
  ToastOptions,
  UpdateOptions
} from 'react-toastify';

import { CustomToastStyles } from './CustomToastStyles';
import { PromiseToastRender } from './PromiseToastRender';

type ParallelToast = Omit<typeof toast, 'promise'> & {
  promise: <T extends any>(
    promise: Promise<T> | (() => Promise<T>),
    params?: ToastPromiseParams,
    options?: ToastOptions
  ) => Promise<T>;
};

const ParallelToastContext = createContext<ParallelToast>(toast as ParallelToast);

const iconMap: {
  [key: string]: ReactNode;
} = {
  info: Icons.info,
  success: <Icon name="containedCheck" size="medium" color="var(--clr-green)" />,
  error: <Icon name="warning" size="medium" />
};

const renderIcon = (props: IconProps) => {
  const { type } = props;
  const icon = iconMap[type];
  if (isFunction(icon)) {
    return icon(props);
  }
  return icon;
};

const defaultPromiseToastParams: {
  pending: UpdateOptions<any>;
  success: UpdateOptions<any>;
  error: UpdateOptions<any>;
} = {
  pending: {
    /*
      this is necessary because we use "icon" prop of ToastContainer to customize all icon,
      but there is a defect using ToastContainer's icon prop: toast.promise won't be able to
      display loading spinner for pending stage.
    */
    icon: Icons.spinner,
    render: PromiseToastRender
  },
  success: {
    // have to update icon to undefined, otherwise it'll remain old value from the pending stage
    icon: undefined,
    render: PromiseToastRender,
    autoClose: 5000
  },
  error: {
    // have to update icon to undefined, otherwise it'll remain old value from the pending stage
    icon: undefined,
    render: PromiseToastRender
  }
};

export const ParallelToastProvider = memo(({ children }: { children: ReactNode }) => {
  const customizedToast: ParallelToast = useMemo(() => {
    return {
      ...toast,
      promise: <T extends any>(
        promise: Promise<T> | (() => Promise<T>),
        params?: ToastPromiseParams,
        options?: ToastOptions
      ) => {
        const customizedParams = mapValues(defaultPromiseToastParams, (defaultValue, key) => {
          const value = params?.[key as keyof ToastPromiseParams];
          if (isObject(value)) {
            return {
              ...defaultValue,
              ...value
            };
          }
          return {
            ...defaultValue,
            render: isUndefined(value) ? defaultValue.render : value
          };
        });
        return toast.promise<T>(promise, customizedParams, options);
      }
    };
  }, []);
  return (
    <ParallelToastContext.Provider value={customizedToast}>
      <ToastContainer
        position="top-right"
        autoClose={false}
        pauseOnFocusLoss
        pauseOnHover
        hideProgressBar
        icon={renderIcon}
        theme="colored"
        closeButton={false}
      />
      <CustomToastStyles />
      {children}
    </ParallelToastContext.Provider>
  );
});

export const useParallelToast = () => useContext(ParallelToastContext);
