import { BigNumber } from 'bignumber.js';
import { useCallback, useState, useMemo } from 'react';
import { BN } from '@polkadot/util';

import { AssetInfo } from '../../../hooks/types';
import { ROUTE_SLIPPAGE } from '../constants';
import { RouteType } from '../types';

import { useChainConnections } from '@/hooks';
import { amountToBalanceByDecimals, balanceToAmountByDecimal } from '@/utils/calculations';
import { ratioToNumber } from '@/utils/utils';

export const useStakeBestRoutes = () => {
  const {
    parachain: { api }
  } = useChainConnections();
  const [receiveAmount, setReceiveAmount] = useState(0);
  const [stakingFee, setStakingFee] = useState<number>(0);
  const [route, setRoute] = useState(RouteType.Staking);

  const generateSwapTx = useCallback(
    (routes, amountIn, amountOut) => {
      return api.tx.ammRoute.swapExactTokensForTokens(routes, amountIn, amountOut);
    },
    [api]
  );

  const generateStakeTx = useCallback(
    (amount, decimal) => {
      const { stake } = api.tx.liquidStaking;
      const stakingAmount = amountToBalanceByDecimals<BN>(amount, decimal, 'bn');
      return stake(stakingAmount);
    },
    [api.tx.liquidStaking]
  );

  const lpFee = useMemo(() => ratioToNumber(api.consts.amm.lpFee), [api]);

  const updateStakeFee = useCallback(
    (amount: number, dynamicStakingFee, swapFee, shouldUseStaking) => {
      if (!amount) {
        setStakingFee(0);
      } else {
        setStakingFee(shouldUseStaking ? dynamicStakingFee / amount : swapFee);
      }
    },
    []
  );

  const generateBestRoutes = useCallback(
    async (
      amount: number,
      stakeMintVal: number,
      stakingToken: AssetInfo,
      liquidToken: AssetInfo,
      dynamicStakingFee: number,
      lendToMM: boolean
    ) => {
      const [routes, amountOut] = await api.rpc.router.getBestRoute(
        amountToBalanceByDecimals<BN>(amount, stakingToken.decimals, 'bn'),
        new BN(stakingToken.assetId),
        new BN(liquidToken.assetId),
        false
      );
      const poolLiquidity = await api.query.amm.pools(liquidToken.assetId, stakingToken.assetId);
      const liquidAmountInPool = poolLiquidity.isSome
        ? balanceToAmountByDecimal<BigNumber>(
            poolLiquidity.unwrap().baseAmount,
            liquidToken.decimals,
            'bigNumber'
          )
        : new BigNumber(0);
      const swapMintAmount = BigNumber.min(
        balanceToAmountByDecimal<BigNumber>(amountOut, liquidToken.decimals, 'bigNumber').times(
          1 - ROUTE_SLIPPAGE
        ),
        liquidAmountInPool
      ).toNumber();
      const shouldUseStaking = new BigNumber(stakeMintVal).gt(swapMintAmount);

      setRoute(shouldUseStaking ? RouteType.Staking : RouteType.Amm);
      updateStakeFee(amount, dynamicStakingFee, lpFee, shouldUseStaking);
      setReceiveAmount(shouldUseStaking ? stakeMintVal : swapMintAmount);

      const tx = shouldUseStaking
        ? generateStakeTx(amount, stakingToken.decimals)
        : generateSwapTx(
            routes,
            amountToBalanceByDecimals<BN>(amount, stakingToken.decimals, 'bn'),
            amountToBalanceByDecimals<BN>(swapMintAmount, liquidToken.decimals, 'bn')
          );
      if (lendToMM) {
        const lendingTx = api.tx.loans.mint(
          liquidToken.assetId,
          amountToBalanceByDecimals<BN>(
            shouldUseStaking ? stakeMintVal : swapMintAmount,
            liquidToken.decimals,
            'bn'
          )
        );
        return [tx, lendingTx];
      }
      return [tx];
    },
    [
      api.query.amm,
      api.rpc.router,
      api.tx.loans,
      generateStakeTx,
      generateSwapTx,
      lpFee,
      updateStakeFee
    ]
  );
  return {
    generateBestRoutes,
    receiveAmount,
    stakingFee,
    route
  };
};
