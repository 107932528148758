import { FC, useCallback, useMemo, useState, memo } from 'react';
import { upperFirst } from 'lodash';
import { H3, Stack } from '@parallel-mono/components';
import styled from 'styled-components';

import { AssetRow } from '../../LendAndBorrowTable';
import ViewAssetAnalytics from '../../components/ViewAssetAnalytics';

import BorrowPane from './BorrowPane';
import RepayPane from './RepayPane';

import { useAccount, useChainConnections } from '@/hooks';
import { KeepAliveTab, KeepAliveTabs } from '@/components';

const ModalWrapper = styled(Stack)`
  min-height: 420px;
`;

export enum BorrowedActionType {
  Borrow = 'borrow',
  Repay = 'repay'
}

interface IBorrowRepayModalProps {
  data: {
    tabKey: BorrowedActionType;
    assetId: string;
    availableAmount: number;
  };
  assetInfos: AssetRow[];
  closeModal: () => void;
}
const BorrowRepayModal: FC<IBorrowRepayModalProps> = ({
  data: { tabKey, assetId, availableAmount },
  assetInfos,
  closeModal
}) => {
  const { account } = useAccount();
  const {
    parachain: { api }
  } = useChainConnections();

  const [activeTabKey, setActiveTabKey] = useState(tabKey);

  const assetInfo = useMemo(
    () => assetInfos.find(info => info.assetId === assetId),
    [assetInfos, assetId]
  );

  const tabs = useMemo<KeepAliveTab[]>(() => {
    return [
      {
        title: 'Borrow',
        key: BorrowedActionType.Borrow,
        tabContent: (
          <BorrowPane
            token={assetInfo!}
            availableAmount={availableAmount}
            api={api}
            account={account}
            handleClose={closeModal}
          />
        )
      },
      {
        title: 'Repay',
        key: BorrowedActionType.Repay,
        tabContent: (
          <RepayPane token={assetInfo!} api={api} account={account} handleClose={closeModal} />
        )
      }
    ];
  }, [account, api, availableAmount, closeModal, assetInfo]);

  const handleChangeSelectedTab = useCallback((key: string) => {
    setActiveTabKey(key as BorrowedActionType);
  }, []);

  if (!assetInfo) return null;

  return (
    <ModalWrapper gap="2rem">
      <H3>{upperFirst(activeTabKey)}</H3>
      <Stack gap="0.5rem">
        <ViewAssetAnalytics token={assetInfo.symbol} />
        <KeepAliveTabs
          tabs={tabs}
          defaultActiveKey={tabKey}
          onTabChange={handleChangeSelectedTab}
        />
      </Stack>
    </ModalWrapper>
  );
};

export default memo(BorrowRepayModal);
