import config from '@/config';

const ERROR_MESSAGE_MAPPING = {
  InsufficientLiquidity: {
    description:
      'Due to insufficient liquidity, you will need to repay your loan and disable the collateral toggle.',
    title: 'Transaction failed'
  },
  InsufficientDeposit: {
    description:
      'The withdraw amount and fees exceed the supplied amount. Enter an amount equal to or less than the supplied amount.',
    title: 'Transaction Failed'
  },
  TooMuchRepay: {
    description:
      'The repay amount is greater than the loan. Enter a repay amount equal to or less than the loan amount.',
    title: 'Transaction Failed'
  },
  DuplicateOperation: {
    description: 'Duplicate operation on the collateral toggle - Please try again.'
  },
  NoDeposit: {
    description: 'Please deposit your asset first and try enabling the collateral toggle again.',
    title: 'Transaction Failed'
  },
  InsufficientCollateral: {
    description:
      'Your loan amount exceeds your collateral assets value and your wallet does not have sufficient funds to repay your loan amount at this time. Please deposit more asset to repay your loans.',
    title: 'Transaction Failed'
  },
  LiquidatorIsBorrower: {
    description:
      'Your loan amount is greater than your collateral asset value. Please deposit more asset to repay your loan.',
    title: 'Transaction Failed'
  },
  DepositsAreNotCollateral: {
    description: "You cannot liquidate a lender's non-collateralized assets like deposits.",
    title: 'Transaction Failed'
  },
  LiquidateValueOverflow: {
    description:
      'We are currently experiencing an error with liquidate value overflow. Please try again later.'
  },
  InsufficientReserves: {
    description:
      'We are currently experiencing an issue with insufficient reserves. Please try again later.'
  },
  InsufficientShortfall: {
    description: 'You cannot liquidate this account due to insufficient shortfall to repay.',
    title: 'Transaction Failed'
  },
  InvalidRateModelParam: {
    description: 'Invalid rate model parameters. Please try again later.'
  },
  CurrencyNotEnabled: {
    description: "There's currently an issue processing this currency. Please try again later."
  },
  InsufficientBalance: {
    description: 'You have insufficient deposit balance to process this transaction.'
  },
  // TODO Mapping the error in front end temporarily.
  '1010: Invalid Transaction: Inability to pay some fees , e.g. account balance too low': {
    description: `Transaction failed: You have insufficient ${config.nativeToken} to pay for fees.`
  },
  Cancelled: {
    description: 'Transaction Cancelled.'
  },
  CantEstimateGas: {
    description: 'Cannot estimate gas; transaction may fail or may require manual gas limit.'
  },
  DepositBalanceLow: {
    description: 'You have insufficient deposit balance to process this withdrawal.'
  },
  PoolIsNotActive: {
    description: 'This farm is no longer active nor taking new deposits.'
  },
  ExcessMaxUserLockItemsCount: {
    description: 'You exceeded the pending withdraw limit. Please try again later.',
    title: 'Failed'
  },
  NoAccount: {
    description: 'You have insufficient deposit balance to process this transaction.'
  },
  BalanceLow: {
    description: 'You have insufficient deposit balance to process this transaction.'
  },
  NotAnIdealPrice: {
    description:
      'The desired output amount is not within the expected range. Enter a different input amount or increase your slippage % in settings.'
  },
  InsufficientCash: {
    description: 'Insufficient Liquidity. Please try again later'
  }
};

export type ErrorType = keyof typeof ERROR_MESSAGE_MAPPING;

const enrichErrorMessage = (type: ErrorType | string) =>
  ERROR_MESSAGE_MAPPING[type as ErrorType] ?? {
    description: type
  };

export { enrichErrorMessage };
