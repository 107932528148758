import { stringToU8a, bnToU8a, BN } from '@polkadot/util';
import { blake2AsU8a } from '@polkadot/util-crypto';
import { decodeAddress, encodeAddress } from '@polkadot/keyring';

const subAccountId = (signer: string, index: BN): string => {
  const seedBytes = stringToU8a('modlpy/utilisuba');
  const whoBytes = decodeAddress(signer);
  const indexBytes = bnToU8a(index, { bitLength: 16 }).reverse();
  const combinedBytes = new Uint8Array(seedBytes.length + whoBytes.length + indexBytes.length);
  combinedBytes.set(seedBytes);
  combinedBytes.set(whoBytes, seedBytes.length);
  combinedBytes.set(indexBytes, seedBytes.length + whoBytes.length);

  const entropy = blake2AsU8a(combinedBytes, 256);
  return encodeAddress(entropy);
};

export { subAccountId };
