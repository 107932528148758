import { useState, useCallback, useMemo } from 'react';
import { isNumber } from 'lodash';

import { Slippage } from './Slippage';

const SLIPPAGE_ERRORS = {
  invalid: 'Enter a valid slippage percentage (<50%)',
  low: 'Your transactions may fail.',
  high: 'Your transactions may be front run'
};

export const useSlippage = ({ defaultSlippage = 0.5, maxSlippage = 50, decimals = 2 } = {}) => {
  const [slippage, setSlippage] = useState<number>(defaultSlippage);
  const [errmsg, setErrmsg] = useState<string | null>(null);

  const checkSlippage = useCallback(
    (value: number) => {
      if (!value) return null;
      if (value > maxSlippage) {
        return SLIPPAGE_ERRORS.invalid;
      }
      if (value > 1) {
        return SLIPPAGE_ERRORS.high;
      }
      if (value < defaultSlippage) {
        return SLIPPAGE_ERRORS.low;
      }
      return null;
    },
    [defaultSlippage, maxSlippage]
  );

  const invalidSlippage = useMemo(() => {
    return slippage >= maxSlippage;
  }, [maxSlippage, slippage]);

  const handleSlippageChange = useCallback(
    (value: number | null) => {
      if (isNumber(value)) {
        setSlippage(value);
        setErrmsg(checkSlippage(value));
      }
    },
    [checkSlippage]
  );

  const holder = useMemo(
    () => (
      <Slippage
        defaultSlippage={defaultSlippage}
        slippage={slippage}
        decimals={decimals}
        error={errmsg}
        invalidSlippage={invalidSlippage}
        onChange={handleSlippageChange}
      />
    ),
    [defaultSlippage, slippage, decimals, errmsg, invalidSlippage, handleSlippageChange]
  );

  return {
    holder,
    slippage,
    invalidSlippage
  };
};
