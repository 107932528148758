import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { get, isNil, upperFirst } from 'lodash';
import { BN_ZERO, BN } from '@polkadot/util';
import {
  Inline,
  Stack,
  H5,
  Text,
  SmallText,
  Button,
  Tooltip,
  Icon,
  Alert
} from '@parallel-mono/components';
import {
  ChainIcon,
  CryptoAsset,
  TokenInput,
  InfoPanel,
  InfoPanelProps
} from '@parallel-mono/business-components';
import BigNumberJS from 'bignumber.js';

import { createXcm } from './helpers/createXcm';

import { balanceFormatter, toFloorFixed } from '@/utils/format';
import { Checkbox } from '@/components';
import { useAccount, useChainConnections, useGAEventTrackerByPlatform, useXcmTx } from '@/hooks';
import { signAndSend, txFee } from '@/utils/txCall';
import { amountToBalanceByDecimals } from '@/utils/calculations';
import { useTxFeeValidation, TxFee } from '@/hooks/useTxFeeValidation';
import config, { RelayChainToken } from '@/config';
import { isValidAmountInput } from '@/utils/validate';
import {
  useCurrentAccountAssetInfos,
  useCurrentAccountNativeAssetInfo,
  useCurrentAccountRelayAssetInfo
} from '@/contexts/AssetsInfoContext';
import getFeatureToggle from '@/utils/toggles/getFeatureToggle';

const MINIUM_ACCOUNTABLE_NUM = 0.0001;

const ContentWrapper = styled(Stack)`
  width: 100%;
`;

const StyledTokenInput = styled(TokenInput)`
  & input {
    padding: 7px 0;
  }
`;

const AssetInfos = styled(Inline).attrs({
  gap: '0.5rem',
  justifyContent: 'right'
})`
  flex-wrap: wrap;
`;
const TermsText = styled(SmallText).attrs({ type: 'secondary' })`
  padding-top: 2px;
`;

export enum ActionType {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw'
}

type TransactionProps = {
  action: ActionType;
  closeModal: () => void;
};

const networkMapping: Record<RelayChainToken, string> = {
  KSM: 'Kusama',
  DOT: 'Polkadot'
};

const TransactionModal: FC<TransactionProps> = ({ action, closeModal }) => {
  const {
    parachain: { api: paraApi },
    relaychain: { api: relayApi }
  } = useChainConnections();
  const [amount, setAmount] = useState<number>();
  const [amountInput, setAmountInput] = useState<number | null>(null);
  const { account } = useAccount();
  const [transactionFee, setTransactionFee] = useState<TxFee>();
  const { relayAssetInfo: relayChainAsset, isReady: isRelayAssetReady } =
    useCurrentAccountRelayAssetInfo();
  const { assetInfos } = useCurrentAccountAssetInfos();

  const GAEventTrackerByPlatform = useGAEventTrackerByPlatform();

  const { nativeAssetInfo: nativeAsset } = useCurrentAccountNativeAssetInfo();
  const isDeposit = action === ActionType.DEPOSIT;

  const { TxFeeTips } = useTxFeeValidation(isDeposit ? relayChainAsset! : nativeAsset);

  const parallelAsset = useMemo(
    () => assetInfos.find(asset => asset.symbol === relayChainAsset?.symbol),
    [assetInfos, relayChainAsset]
  );
  const [conditionChecked, setConditionChecked] = useState(false);

  useEffect(() => {
    setAmount(Number(amountInput));
  }, [amountInput]);

  const data = useMemo(
    () =>
      (isDeposit ? relayChainAsset : parallelAsset) || {
        balance: 0,
        availableBalance: 0,
        lockedBalance: 0,
        maxAvailableBalance: 0
      },
    [isDeposit, parallelAsset, relayChainAsset]
  );

  const generateXcmTx = useXcmTx(
    amount || 0,
    relayChainAsset?.decimals || BN_ZERO,
    account.address
  );
  const relayChainToken = useMemo(
    () => relayChainAsset.symbol as RelayChainToken,
    [relayChainAsset.symbol]
  );

  const warningMessage = useMemo(() => {
    if (
      action === ActionType.WITHDRAW &&
      isRelayAssetReady &&
      relayChainAsset.availableBalance < relayChainAsset.existentialDeposit
    ) {
      return (
        <Stack gap="0.25rem">
          <Text>
            Insufficient {relayChainAsset.symbol} balance on {networkMapping[relayChainToken]} would
            result in funds loss.
          </Text>
          <Text>
            Please withdraw{' '}
            <b>
              {config.xcmMinimumTransferAmount} {relayChainAsset.symbol}
            </b>{' '}
            first, and then continue to operate the remaining tokens after confirming the arrival.
          </Text>
        </Stack>
      );
    }
    return null;
  }, [
    action,
    isRelayAssetReady,
    relayChainAsset.availableBalance,
    relayChainAsset.existentialDeposit,
    relayChainAsset.symbol,
    relayChainToken
  ]);

  const errorMessage = useMemo(() => {
    const amountValue = amount || 0;
    const balanceValue = data.maxAvailableBalance;
    if (!amountValue) {
      return null;
    }
    if (amountValue > balanceValue) {
      return `Insufficient balance on ${networkMapping[relayChainToken]}.`;
    }

    if (
      action === ActionType.WITHDRAW &&
      isRelayAssetReady &&
      BigNumberJS(amountValue).lt(config.xcmMinimumTransferAmount)
    ) {
      return `Minimum withdraw amount is ${config.xcmMinimumTransferAmount}`;
    }
    return null;
  }, [action, amount, data.maxAvailableBalance, isRelayAssetReady, relayChainToken]);

  const confirmButtonDisabled = !amount || Boolean(errorMessage) || !conditionChecked;

  const handleMaxClick = () => {
    const depositAmount = relayChainAsset ? relayChainAsset.maxAvailableBalance : 0;
    const availableBalance = {
      [ActionType.DEPOSIT]: depositAmount > 0 ? depositAmount : 0,
      [ActionType.WITHDRAW]: data.availableBalance
    };
    const balance = availableBalance[action];
    const result = balance || 0;
    setAmountInput(result);
  };

  const handleClose = () => {
    setAmountInput(null);
    closeModal();
  };

  const generateTxCalls = useCallback(() => {
    const { availableBalance } = data;
    const transferAmount =
      availableBalance - (amount || 0) < MINIUM_ACCOUNTABLE_NUM ? availableBalance : amount;
    return paraApi.tx.xTokens.transfer(
      relayChainAsset!.assetId,
      amountToBalanceByDecimals<BN>(transferAmount || 0, relayChainAsset!.decimals, 'bn'),
      createXcm(account.address),
      { Unlimited: '' }
    );
  }, [data, amount, paraApi.tx.xTokens, relayChainAsset, account.address]);

  useEffect(() => {
    (async () => {
      if (amount) {
        setTransactionFee(null);
        const fee = await txFee(
          {
            tx: isDeposit ? generateXcmTx() : generateTxCalls(),
            account
          },
          isDeposit ? relayChainAsset.symbol : nativeAsset.symbol
        );
        setTransactionFee(fee);
      } else {
        setTransactionFee(undefined);
      }
    })();
  }, [
    account,
    amount,
    generateTxCalls,
    action,
    generateXcmTx,
    isDeposit,
    relayChainAsset?.symbol,
    nativeAsset?.symbol
  ]);

  const handleSend = () => {
    const tx = isDeposit ? generateXcmTx() : generateTxCalls();
    const api = isDeposit ? relayApi : paraApi;
    if (isDeposit) {
      GAEventTrackerByPlatform(`confirm_${relayChainAsset.symbol}_transfer`, `${amount}`);
    }
    signAndSend({
      api,
      tx,
      account
    });
    handleClose();
  };

  const infos = useMemo<InfoPanelProps['infos']>(() => {
    const newInfos = [
      {
        title: 'Parallel Balance',
        tip: 'This is your new balance on the Parallel chain.',
        value: (
          <Inline gap="2px">
            <AssetInfos gap="0.5rem">
              <H5>{balanceFormatter(get(parallelAsset, 'availableBalance', 0))}</H5>
              <Inline gap="0.5rem">
                <Icon name="arrowRight" size="medium" />
                <H5>
                  {balanceFormatter(
                    get(parallelAsset, 'availableBalance', 0) +
                      (isDeposit ? amount || 0 : -(amount || 0))
                  )}
                </H5>
                <H5>{relayChainAsset?.symbol || ''}</H5>
              </Inline>
            </AssetInfos>
          </Inline>
        )
      },
      {
        title: relayChainAsset?.symbol
          ? `${upperFirst(networkMapping[relayChainAsset.symbol as RelayChainToken])}  Balance`
          : '',
        tip: `After the ${action} balance completes, this is your new balance on the relay chain.`,
        value: (
          <Inline gap="2px">
            <AssetInfos gap="0.5rem">
              <H5>{balanceFormatter(get(relayChainAsset, 'availableBalance', 0))}</H5>
              <Inline gap="0.5rem">
                <Icon name="arrowRight" size="medium" />
                <H5>
                  {balanceFormatter(
                    get(relayChainAsset, 'availableBalance', 0) +
                      (isDeposit ? -(amount || 0) : amount || 0)
                  )}
                </H5>
                <H5>{relayChainAsset?.symbol || ''}</H5>
              </Inline>
            </AssetInfos>
          </Inline>
        )
      }
    ];

    if (isDeposit) {
      return newInfos.reverse();
    }

    return newInfos;
  }, [action, amount, isDeposit, parallelAsset, relayChainAsset]);

  const depositAndWithdrawDisabled = useMemo(
    () => getFeatureToggle('TOGGLE_RELAY_CHAIN_DEPOSIT_DISABLED'),
    []
  );

  return (
    <ContentWrapper gap="1rem">
      <Stack inset="0.5rem 0 0">
        {isDeposit && relayChainAsset ? (
          <Inline justifyContent="space-between" alignItems="center" inset="1rem 5rem">
            <CryptoAsset symbol={config.relayChainToken} symbolSize="large" />
            <Icon name="arrowRight" color="var(--clr-grey1)" />
            <ChainIcon name="parallel" size="large" />
          </Inline>
        ) : (
          <Inline justifyContent="space-between" alignItems="center" inset="1rem 5rem">
            <ChainIcon name="parallel" size="large" />
            <Icon name="arrowRight" color="var(--clr-grey1)" />
            <CryptoAsset symbol={config.relayChainToken} symbolSize="large" />
          </Inline>
        )}
        <StyledTokenInput
          label={<H5>{relayChainAsset?.symbol} Amount</H5>}
          hint={
            <Inline gap="0.25rem" alignItems="center">
              <Text skin="secondary">
                Available: {balanceFormatter(Number(data.availableBalance))}
              </Text>
              <Tooltip
                content={`This is your available balance that can be transferred from ${
                  isDeposit ? 'the relay chain to Parallel.' : 'Parallel to the relay chain'
                }`}
              />
            </Inline>
          }
          token={relayChainAsset?.symbol.toUpperCase()}
          value={isNil(amountInput) ? null : toFloorFixed(amountInput, 4)}
          placeholder="Amount"
          onChange={value => {
            if (isValidAmountInput(value?.toString() ?? '', 12, 4) || value === null) {
              setAmountInput(value);
            }
          }}
          onAction={handleMaxClick}
          actionButtonText="Max"
          error={errorMessage}
        />

        <InfoPanel infos={infos} />
        {warningMessage && <Alert type="warning">{warningMessage}</Alert>}

        <Checkbox
          checked={conditionChecked}
          onChange={target => setConditionChecked(target.checked)}
        >
          <TermsText>
            I acknowledge I am not using ledger wallet. Funds deposited from ledger will not be able
            to be used or refunded.
          </TermsText>
        </Checkbox>
        <Tooltip
          disabled={!depositAndWithdrawDisabled}
          content={`${upperFirst(action)} is currently not enabled for this asset.`}
        >
          <Button
            skin="primary"
            size="large"
            block
            disabled={confirmButtonDisabled || depositAndWithdrawDisabled}
            onClick={handleSend}
          >
            Confirm
          </Button>
        </Tooltip>
        <TxFeeTips txFee={transactionFee} />
      </Stack>
    </ContentWrapper>
  );
};

export default memo(TransactionModal);
