import { stringToU8a, u8aConcat, bnToU8a } from '@polkadot/util';
import { encodeAddress } from '@polkadot/keyring';

import { useChainConnections } from './useChainConnection';

import { getDerivativeList } from '@/pages/Staking/apis/staking';
import { subAccountId } from '@/utils/accountHelper';
import config from '@/config';

const EMPTY_U8A_32 = new Uint8Array(32);

const useDerivativeAccount = () => {
  const {
    parachain: { api }
  } = useChainConnections();

  const derivativeIndexList = getDerivativeList(api);

  return derivativeIndexList.map(derivativeIndexVal => {
    const SovereignAccountOfParachain = encodeAddress(
      u8aConcat(
        stringToU8a('para'),
        bnToU8a(config.paraId, { bitLength: 32, isLe: true }),
        EMPTY_U8A_32
      ).subarray(0, 32)
    );
    return subAccountId(SovereignAccountOfParachain, derivativeIndexVal);
  });
};

export default useDerivativeAccount;
