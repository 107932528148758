export const getElementTop = (element: HTMLElement) => {
  if (!element) return 0;
  let { offsetTop } = element;
  let childElement = element.offsetParent as HTMLElement;
  while (childElement != null) {
    offsetTop += childElement.offsetTop;
    childElement = childElement.offsetParent as HTMLElement;
  }

  return offsetTop;
};
