import { useState, useEffect, useMemo } from 'react';

import { calculatedValue, CalculatedValues } from './utils';
import { StreamListItem } from './useFetchList';
import { TOKEN_META } from './constant';

import { useChainConnections } from '@/hooks';
import { zero } from '@/utils/values';

const useCalculatedValue = (data: StreamListItem) => {
  const [timestamp, setTimestamp] = useState<string | undefined>(undefined);

  const {
    parachain: { api }
  } = useChainConnections();
  useEffect(() => {
    api.query.timestamp.now().then(value => {
      setTimestamp(value.toString());
    });
  }, [api]);

  const {
    claimableTotal = zero,
    claimed = zero,
    claimableNow = zero,
    remainingAfterCancel = zero,
    depositNumber = zero,
    remainingNumber = zero
  } = useMemo(() => {
    if (!timestamp) {
      return {} as CalculatedValues;
    }
    const { assetId, deposit, startTime, endTime, remainingBalance } = data;
    const { decimals } = TOKEN_META[assetId];
    const values = calculatedValue(
      timestamp,
      deposit,
      remainingBalance,
      decimals,
      startTime,
      endTime
    );

    return values;
  }, [data, timestamp]);

  return {
    claimableTotal,
    claimed,
    claimableNow,
    remainingAfterCancel,
    depositNumber,
    remainingNumber,
    timestamp
  };
};

export default useCalculatedValue;
