// @ts-nocheck
/* eslint-disable */

import { toNumber, noSpecialCharacters, timeStamp } from '../helpers/others';
import { Million } from '../utils/data/format';

export const lastTransactions = (transactions, assets, rates) => {
  if (transactions && assets && rates) {
    const limitTransactions = transactions.slice(0, 10);
    const transactionsMassage = limitTransactions.map((item, i) => {
      const initialPair = assets?.[item?.first_asset_id];
      const secondPair = assets?.[item?.second_asset_id];
      const initialRate =
        rates?.[initialPair?.symbol]?.[rates?.[initialPair?.symbol]?.length - 1]?.val;
      const secondRate =
        rates?.[secondPair?.symbol]?.[rates?.[secondPair?.symbol]?.length - 1]?.val;
      const initialValue = toNumber(item?.first_amount, initialPair?.decimals) * initialRate;
      const secondValue = toNumber(item?.second_amount, secondPair?.decimals) * secondRate;
      return {
        ticker: [initialPair.symbol, secondPair.symbol],
        type: item?.action,
        account: item?.trader,
        totalValue: `$${Million(initialValue + secondValue)}`,
        url: item?.hash,
        amounts: [
          `${Million(toNumber(item?.first_amount, initialPair?.decimals))} ${noSpecialCharacters(
            initialPair?.symbol
          )}`,
          `${Million(toNumber(item?.second_amount, secondPair?.decimals))} ${noSpecialCharacters(
            secondPair?.symbol
          )}`
        ],
        timestamp: timeStamp(item?.block_timestamp)
      };
    });

    return transactionsMassage;
  }
};
