import typography from './typography';

const colors = (darkMode: boolean) => ({
  preloader: darkMode ? '#1b1c21' : '#ffffff',
  main: darkMode ? '#cb2d74' : '#0c3ee3',
  subtle: darkMode ? '#cb2d74' : '#e7edff',
  header: darkMode ? '#0e0f15' : '#ffffff',

  // icons background
  icon1: darkMode ? '#ff67ab , #cb2d74' : '#0c3ee3, #25a6ee',
  icon2: darkMode ? '#cbb22d , #cb492d' : '#1fdeea, #11bc91',
  icon3: darkMode ? '#e30cc1 , #ee2525' : '#85d9ef, #3fadfc',

  // background
  bg1: darkMode ? '#1b1c21' : '#ffffff',
  bg2: darkMode ? 'rgba(255, 255, 255, 0.1)' : '#f0f3fb',
  bg3: darkMode ? '' : '#f2f2f2',

  // text
  text1: darkMode ? '#ffffff' : '#2f2f2f',
  text2: darkMode ? '#757575' : '#6b707e',
  text3: darkMode ? '' : '#cfdbf2',

  // button
  btn1: darkMode ? '#cb2d74' : '#0c3ee3',
  'btn1-press': darkMode ? '' : '#001352',
  btn2: darkMode ? '#cb2d74' : '#11bc91',
  'btn2-press': darkMode ? '#cb2d74' : '#02684E',
  'btn-disabled': '#bdbdbd'
});

const theme = (darkMode: boolean) => ({
  // TODO: will cleanup the colors from here when change the icons.
  colors: {
    white: '#ffffff',
    black: '#000000',
    orange: '#EB5757',
    green: '#11bc91',
    ...colors(darkMode)
  },
  typography
});

export { theme };
