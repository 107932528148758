import { memo } from 'react';
import { Inline, Tooltip } from '@parallel-mono/components';

import { useLiquidationFreeLimit } from '../hooks';
import liquidationFreeIcon from '../images/liquidation-free.svg';

import { Image } from '@/components';

export const LiquidationFreeIcon = memo(() => {
  const { lfBorrowAsset, lfCollateralAssets } = useLiquidationFreeLimit();

  const names = lfCollateralAssets
    ?.map(asset => {
      return asset?.symbol.toString();
    })
    .join(',');
  const Content = (
    <>
      {`Use ${names} as collateral to borrow ${lfBorrowAsset?.symbol} without any risk of liquidation. `}
      <a
        href="https://parallelfi.gitbook.io/parallel-finance/polkadot-network/parallel-product-guides/money-market-guide/liquidation-free-loan"
        onClick={e => e.stopPropagation()}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn More
      </a>
    </>
  );

  return (
    <Tooltip content={Content}>
      <Inline alignItems="center" gap="0.25rem" style={{ margin: '0 0.5rem' }}>
        <Image src={liquidationFreeIcon} width="24px" height="24px" />
      </Inline>
    </Tooltip>
  );
});
