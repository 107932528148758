import config from '@/config';

interface ITokenMeta {
  [key: number]: {
    tokenId: number;
    decimals: number;
    name: string;
    symbol: string;
    key: string;
  };
}
export const TOKEN_META: ITokenMeta = {
  0: {
    tokenId: 0,
    decimals: 12,
    name: 'Parallel Heiko',
    symbol: 'HKO',
    key: 'HKO'
  },
  1: {
    tokenId: 1,
    decimals: 12,
    name: 'PARA',
    symbol: 'PARA',
    key: 'PARA'
  },
  100: {
    tokenId: 100,
    decimals: 12,
    name: 'Kusama',
    symbol: 'KSM',
    key: 'KSM'
  },
  101: {
    tokenId: 101,
    decimals: 10,
    name: 'Polkadot',
    symbol: 'DOT',
    key: 'DOT'
  }
};

export const subscan = `${config.subscanBaseUrl}/block/`;
