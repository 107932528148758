import { useMemo, useContext, useState, useCallback } from 'react';
import { useAsync } from 'react-use';

import { LiquidityTabs } from '../components/LiquidityTabs';
import { DataContext } from '../DataContext';

import { AddLiquidity } from './AddLiquidity';
import { MyDashboard } from './MyDashboard';

import { KeepAliveTabs, KeepAliveTab } from '@/components';

export const Liquidity = () => {
  const { lpAssetsApySource } = useContext(DataContext);

  const [activeTab, setActiveTab] = useState('addLiquidity');
  const switchTab = useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  const tabs = useMemo<KeepAliveTab[]>(() => {
    return [
      {
        title: 'Add Liquidity',
        key: 'addLiquidity',
        tabContent: <AddLiquidity />
      },
      {
        title: 'My Dashboard',
        key: 'myDashboard',
        tabContent: <MyDashboard switchTab={switchTab} />
      }
    ];
  }, [switchTab]);

  useAsync(async () => {
    if (!lpAssetsApySource.ammData.value) {
      lpAssetsApySource.fetchAmmData();
    }
  }, []);

  return (
    <KeepAliveTabs
      gap="2rem"
      tabs={tabs}
      TabHeader={LiquidityTabs}
      activeKey={activeTab}
      onTabChange={switchTab}
    />
  );
};
