import { FC, useState } from 'react';
import { reduce, keys } from 'lodash';
import { Text, Tabs } from '@parallel-mono/components';
import { CryptoIcon } from '@parallel-mono/business-components';
import styled from 'styled-components';

import Menus, { Menu } from './components/Menus';
import { chainMenus, useProductMenus } from './menus';

import config, { Chain } from '@/config';
import { useDevice } from '@/hooks';

export type NavigationType = 'overview' | 'gallery';
const MenuIcon = styled(CryptoIcon)`
  border-radius: 50%;
  width: 2rem;
`;

type Props = {
  type?: NavigationType;
  callback?: () => void;
};

const StyledTabs = styled(Tabs)`
  margin-bottom: 1.5rem;
  .tab {
    padding: 0.25rem;
  }
`;

const ChainTabIndexMapping = {
  [Chain.PARALLEL]: 0,
  [Chain.HEIKO]: 1
};

const NavigationBar: FC<Props> = ({ callback, type = 'gallery' }) => {
  const { isDesktop } = useDevice();
  const productMenus = useProductMenus();

  const filteredMenus = reduce(
    keys(productMenus),
    (sum, key: string) => {
      const menus = productMenus[key].map(menu =>
        !menu.display ? { ...menu, display: ['gallery', 'overview'] as NavigationType[] } : menu
      );
      sum[key] = menus.filter(menu => menu.display!.includes(type));
      return sum;
    },
    {} as Record<string, Menu[]>
  );

  const [selectedIndex, setSelectedIndex] = useState(ChainTabIndexMapping[config.chain]);

  const tabs = [
    {
      title: isDesktop ? (
        <Text fontWeight="medium">{chainMenus[0].title}</Text>
      ) : (
        <MenuIcon symbol={chainMenus[0].icon} />
      ),
      content: (
        <Menus
          chain={Chain.PARALLEL}
          menus={filteredMenus.polkadot.map(menu => ({
            ...menu,
            host: config.PARALLEL_APPLICATION_URL
          }))}
          callback={callback}
        />
      )
    },
    {
      title: isDesktop ? (
        <Text fontWeight="medium">{chainMenus[1].title}</Text>
      ) : (
        <MenuIcon symbol={chainMenus[1].icon} />
      ),
      content: (
        <Menus
          iconBgColor="var(--clr-gray05)"
          chain={Chain.HEIKO}
          callback={callback}
          menus={filteredMenus.kusama.map(menu => ({
            ...menu,
            host: config.HEIKO_APPLICATION_URL
          }))}
        />
      )
    }
  ];

  return (
    <>
      <StyledTabs
        classNames={{ tab: 'tab' }}
        block={false}
        tabs={tabs}
        activeTab={tabs[selectedIndex]}
        onActiveTabChange={(_, index) => setSelectedIndex(index)}
      />
      {tabs[selectedIndex].content}
    </>
  );
};

export default NavigationBar;
