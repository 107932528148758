import { createContext, memo, useContext, useMemo } from 'react';

import { useMarketAssets } from '../hooks';

import { useAssetMarketStatus } from '@/hooks';
import { AssetMarketStatus, MarketData } from '@/hooks/types';

type ContextValue = MarketData<AssetMarketStatus> | undefined;

export const MarketStatusContext = createContext<ContextValue>({});

export const MarketStatusContextProvider = memo(({ children }) => {
  const { marketAssetIds } = useMarketAssets();
  const marketAssetsStatus = useAssetMarketStatus(marketAssetIds);

  const value = useMemo(() => marketAssetsStatus, [marketAssetsStatus]);

  return <MarketStatusContext.Provider value={value}>{children}</MarketStatusContext.Provider>;
});

export const useMarketAssetsStatusContext = () => useContext(MarketStatusContext);
