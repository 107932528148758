import { defaultBreakPoints } from './breakpoints';
import mediaWidthTemplates from './media';
import { blueSkin } from './skins';
import { ThemeConfig } from './types';
import { defaultTypography } from './typography';

export const defaultThemeConfig: ThemeConfig = {
  ...blueSkin,
  shadows: {
    primary: '0px 0px 16px rgba(58, 78, 88, 0.05)',
    secondary: '0px 4px 16px rgba(58, 78, 88, 0.15)',
  },
  border: {
    radius: {
      small: '4px',
      medium: '8px',
      large: '12px',
    },
    width: {
      small: '1px',
      medium: '2px',
      large: '3px',
    },
  },
  typography: defaultTypography,
  mediaWidth: mediaWidthTemplates,
  fontFamily: 'DM Sans',
  components: {
    Typography: {
      lightSkin: {
        primary: {
          main: '#2F2F2F',
        },
        secondary: {
          main: '#5E6D7C',
        },
      },
      darkSkin: {
        primary: {
          main: '#FFFFFF',
        },
        secondary: {
          main: '#757575',
        },
      },
    },
    DataGrid: {
      typography: {
        header4: {
          lineHeight: '24px',
        },
      },
    },
  },
  spacing: {
    base: '4px',
  },
  breakpoints: defaultBreakPoints,
};
