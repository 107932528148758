export type ElementClientRect = Omit<DOMRect, 'x' | 'y' | 'toJSON'>;

export const getRelativeElementClientRect = (
  element: HTMLElement,
  container: HTMLElement
): ElementClientRect => {
  const elementRect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  // catering for scroll position causing this function's disability to be compatability with transform
  // but will keep it this way before we have a solution
  return {
    width: elementRect.width,
    height: elementRect.height,
    top: elementRect.top - containerRect.top + container.scrollTop,
    left: elementRect.left - containerRect.left + container.scrollLeft,
    right: elementRect.left - containerRect.left + elementRect.width + container.scrollLeft,
    bottom: elementRect.top - containerRect.top + elementRect.height + container.scrollTop,
  };
};
