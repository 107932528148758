// @ts-nocheck
/* eslint-disable */

export const m = (value, network = 'polkadot', withoutDecimals = false) => {
  const decimals = network === 'kusama' ? 1000000000000 : 10000000000;
  const val = withoutDecimals ? value : value / decimals;
  return Math.abs(Number(val)) >= 1.0e9
    ? `${(Math.abs(Number(val)) / 1.0e9).toFixed(2)}B`
    : // Six Zeroes for Millions
    Math.abs(Number(val)) >= 1.0e6
    ? `${(Math.abs(Number(val)) / 1.0e6).toFixed(2)}M`
    : // Three Zeroes for Thousands
    Math.abs(Number(val)) >= 1.0e3
    ? `${(Math.abs(Number(val)) / 1.0e3).toFixed(1)}K`
    : Math.abs(Number(val)).toFixed(1);
};

export const Million = value => {
  return Math.abs(Number(value)) >= 1.0e9
    ? `${(Math.abs(Number(value)) / 1.0e9).toFixed(2)}B`
    : Math.abs(Number(value)) >= 1.0e6
    ? `${(Math.abs(Number(value)) / 1.0e6).toFixed(2)}M`
    : Math.abs(Number(value)) >= 1.0e3
    ? `${(Math.abs(Number(value)) / 1.0e3).toFixed(1)}K`
    : Math.abs(Number(value)).toFixed(2);
};

export const n = (value, decimalOverride = 0) => {
  const decimals = decimalOverride || (value < 1 ? (value === 0 ? 0 : 4) : 2);
  return parseFloat(Number(value)?.toFixed(decimals)).toLocaleString('en-US', {
    minimumFractionDigits: decimals
  });
};

export const date = date => {
  return date.substring(0, 6);
};

export const big = (number, activate = false) => {
  return activate ? number * 10000000000 : number / 10000000000;
};

export const bigk = (number, activate) => {
  return activate ? number * 1000000000000 : number / 1000000000000;
};

export const Porcent = value => {
  return n(value * 100);
};

export const DOT = value => {
  return `${n(value)} DOT `;
};

export const KSM = value => {
  return `${n(value)} KSM `;
};

export const USD = value => {
  return `$${n(value)} `;
};

export const P = value => {
  return `${n(value)}% `;
};

export const valueSymbol = ({ value, unit, decimalOverride = 0 }) => {
  return (() => {
    switch (unit) {
      case 'PORCENT':
        return P(value);
      case 'DOT':
        return DOT(value);
      case 'KSM':
        return KSM(value);
      case 'NUM':
        return `${n(value, decimalOverride)} `;
      case 'USD':
        return `${USD(value)} `;
      default:
        return USD(value);
    }
  })();
};
