import { memo, useMemo } from 'react';
import { Stack, Card } from '@parallel-mono/components';

import NetWorth from '../NetWorth';
import ParallelAssetsTable from '../ParallelAssetsTable';
import RelayChainAssetsTable from '../RelayChainAssetsTable';
// import { OneClickBanner } from '../OneClickBanner';
import ContentsLoading from '../Loading/ContentsLoading';

import { NotConnected } from '@/components';
import { Chains, useAccount, useAssetPrices } from '@/hooks';
import PageWrapper from '@/pages/PageWrapper';
import {
  useCurrentAccountAssetInfos,
  useCurrentAccountNativeAssetInfo,
  useCurrentAccountRelayAssetInfo
} from '@/contexts/AssetsInfoContext';

const OverviewContent = () => {
  const { assetInfos, isReady: AssetInfosIsReady } = useCurrentAccountAssetInfos();
  const assetsPrice = useAssetPrices();
  const { account } = useAccount();

  const { nativeAssetInfo, isReady: isReadyNativeAssetInfo } = useCurrentAccountNativeAssetInfo();
  const { relayAssetInfo, isReady: relayAssetInfoIsReady } = useCurrentAccountRelayAssetInfo();

  const xcmAssets = useMemo(() => {
    if (assetInfos) {
      return assetInfos.filter(asset => asset.symbol === relayAssetInfo?.symbol);
    }
    return [];
  }, [assetInfos, relayAssetInfo]);
  return (
    <>
      {account ? (
        <Stack>
          {/* <OneClickBanner /> */}
          <Card>
            <RelayChainAssetsTable
              isReady={AssetInfosIsReady && relayAssetInfoIsReady && !!assetsPrice}
              xcmAssets={xcmAssets}
              relayChainAssets={relayAssetInfo ? [relayAssetInfo] : []}
              assetsPrice={assetsPrice}
            />
          </Card>
          <Card>
            <ParallelAssetsTable
              isReady={
                AssetInfosIsReady &&
                isReadyNativeAssetInfo &&
                relayAssetInfoIsReady &&
                !!assetsPrice
              }
              parallelAssets={assetInfos}
              xcmAssets={relayAssetInfo ? [relayAssetInfo] : []}
              nativeAsset={nativeAssetInfo}
              assetsPrice={assetsPrice}
            />
          </Card>
        </Stack>
      ) : (
        <Card>
          <NetWorth title="Parallel Balance" asset={0} debt={0} />
          <NotConnected />
        </Card>
      )}
    </>
  );
};

export default memo(() => (
  <PageWrapper
    initialChains={[Chains.RelayChain, Chains.ParaChain]}
    loadingComponent={<ContentsLoading />}
  >
    <OverviewContent />
  </PageWrapper>
));
