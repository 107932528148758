import { useMemo } from 'react';
import { BN_ZERO } from '@polkadot/util';
import { min } from 'lodash';

import useAccountAssetsBorrow from './useAccountAssetsBorrow';
import useAccountAssetsDeposit from './useAccountAssetsDeposit';
import useMarketAssets from './useMarketAssets';
import { useLiquidationFreeLimit } from './useLiquidationFreeLimit';

import { useAssetPrices } from '@/hooks';
import { ratioToNumber } from '@/utils/utils';
import { sumAssetsValue } from '@/utils/calculations';
import { AssetsData, AssetMarket, CurrencyId } from '@/hooks/types';

const computeBorrowLimit = (
  accountAssetSupplies: AssetsData,
  assetsMarket: AssetMarket,
  assetsPrice: AssetsData,
  collateralAssets: CurrencyId[],
  ratioKey?: 'collateralFactor' | 'liquidationThreshold'
) =>
  collateralAssets.reduce((sum, id) => {
    const supplied = accountAssetSupplies[id] ?? 0;
    const price = assetsPrice[id] ?? 0;
    const factor = ratioKey ? ratioToNumber(assetsMarket[id]?.[ratioKey] ?? BN_ZERO) : 1;
    return sum + supplied * price * factor;
  }, 0);

const useBorrowLimit = () => {
  const { marketAssetIds, assetsMarket } = useMarketAssets();
  const { supplies, collaterals } = useAccountAssetsDeposit(marketAssetIds);
  const assetsPrice = useAssetPrices();
  const accountBorrow = useAccountAssetsBorrow(marketAssetIds);
  const { lfCollateralAssetIds, lfBorrowAssetId } = useLiquidationFreeLimit();
  const nonLfCollaterals = useMemo(
    () =>
      collaterals?.filter(id => lfCollateralAssetIds && !lfCollateralAssetIds.includes(id)) || [],
    [collaterals, lfCollateralAssetIds]
  );
  const totalSuppliedValue = useMemo(
    () =>
      supplies && collaterals && assetsPrice && assetsMarket
        ? computeBorrowLimit(supplies, assetsMarket, assetsPrice, nonLfCollaterals)
        : 0,
    [supplies, collaterals, assetsPrice, assetsMarket, nonLfCollaterals]
  );

  const collateralFactorLimit = useMemo(
    () =>
      supplies && collaterals && assetsPrice && assetsMarket
        ? computeBorrowLimit(
            supplies,
            assetsMarket,
            assetsPrice,
            nonLfCollaterals,
            'collateralFactor'
          )
        : 0,
    [supplies, collaterals, assetsPrice, assetsMarket, nonLfCollaterals]
  );

  const liquidationPointLimit = useMemo(
    () =>
      supplies && collaterals && assetsPrice && assetsMarket
        ? computeBorrowLimit(
            supplies,
            assetsMarket,
            assetsPrice,
            nonLfCollaterals,
            'liquidationThreshold'
          )
        : 0,
    [supplies, collaterals, assetsPrice, assetsMarket, nonLfCollaterals]
  );

  const lfCollateralLimit = useMemo<number>(() => {
    if (supplies && collaterals && assetsPrice && assetsMarket) {
      const lfCollaterals = collaterals?.filter(id => lfCollateralAssetIds?.includes(id));
      return Math.max(
        computeBorrowLimit(supplies, assetsMarket, assetsPrice, lfCollaterals, 'collateralFactor'),
        0
      );
    }
    return 0;
  }, [supplies, assetsMarket, assetsPrice, collaterals, lfCollateralAssetIds]);

  const totalAccountBorrowValue = useMemo(
    () => sumAssetsValue(accountBorrow, assetsPrice),
    [accountBorrow, assetsPrice]
  );

  const lfAccountBorrowValue = useMemo<number>(() => {
    if (accountBorrow && lfBorrowAssetId) {
      const lfAccountBorrow = {
        [lfBorrowAssetId]: accountBorrow[lfBorrowAssetId]
      };
      return sumAssetsValue(lfAccountBorrow, assetsPrice);
    }
    return 0;
  }, [accountBorrow, assetsPrice, lfBorrowAssetId]);

  const usedRegularBorrow = useMemo(
    () => Math.max(lfAccountBorrowValue - lfCollateralLimit, 0),
    [lfCollateralLimit, lfAccountBorrowValue]
  );

  const liquidationFreeBorrow = useMemo(
    () => min([lfCollateralLimit, lfAccountBorrowValue])!,
    [lfCollateralLimit, lfAccountBorrowValue]
  );

  const remainingBorrowLimit = useMemo(
    () => Math.max(collateralFactorLimit - totalAccountBorrowValue, 0),
    [collateralFactorLimit, totalAccountBorrowValue]
  );

  const lfCollateralLendTotal = useMemo(() => {
    if (supplies && collaterals && assetsPrice && assetsMarket && lfCollateralAssetIds) {
      const lfCollaterals = collaterals.filter(id => lfCollateralAssetIds.includes(id));

      return computeBorrowLimit(
        supplies,
        assetsMarket,
        assetsPrice,
        lfCollaterals,
        'liquidationThreshold'
      );
    }
    return 0;
  }, [supplies, assetsMarket, assetsPrice, collaterals, lfCollateralAssetIds]);

  return {
    totalSuppliedValue,
    collateralFactorLimit,
    liquidationPointLimit,
    remainingBorrowLimit,
    totalAccountBorrowValue,
    lfCollateralLendTotal,
    lfAccountBorrowValue,
    lfCollateralLimit,
    usedRegularBorrow,
    liquidationFreeBorrow
  };
};

export default useBorrowLimit;
