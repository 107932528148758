import {
  Button,
  Inline,
  SmallText,
  Text,
  Stack,
  Tooltip,
  H5,
  H3,
  Toggle,
  Skeleton
} from '@parallel-mono/components';
import { map } from 'lodash';
import { FC, useCallback, useMemo } from 'react';
import { formatNumber } from '@parallel-mono/utils';

import AssetColumn from '../components/AssetColumn';
import { useLiquidationFreeLimit } from '../hooks';
import { AssetRow } from '../LendAndBorrowTable';

import LendWithdrawModal, { SupplyActionType } from './LendWithdrawModal';
import CollateralModal from './CollateralModal';

import { AssetInfo, AssetMarket, AssetMarketStatus, AssetsData, MarketData } from '@/hooks/types';
import config from '@/config';
import {
  Collapse,
  StaticPill,
  StyledDataGrid,
  StyledDataGridColumn,
  TokenPill
} from '@/components';
import { useModal } from '@/hooks';
import { sumAssetsValue } from '@/utils/calculations';
import { balanceFormatter } from '@/utils/format';

export interface LendPositionAssetRow extends AssetRow {
  accountSupplied: number;
  isCollateral: boolean;
}

interface LendPositionsTableProps {
  dataIsReady: boolean;
  assetInfos?: AssetInfo[];
  assetsPrice?: AssetsData;
  marketStatus?: MarketData<AssetMarketStatus>;
  accountEarned?: AssetsData;
  accountSupplies?: AssetsData;
  assetsMarket?: AssetMarket;
  collaterals?: string[];
  supplyRewardApys?: AssetsData;
}

const MyLendPositions: FC<LendPositionsTableProps> = ({
  dataIsReady,
  assetInfos,
  assetsMarket,
  assetsPrice,
  marketStatus,
  accountEarned,
  collaterals,
  accountSupplies,
  supplyRewardApys
}) => {
  const { checkIsCollateralLiquidationFree } = useLiquidationFreeLimit();

  const totalSupplyValue = useMemo(
    () => sumAssetsValue(accountSupplies, assetsPrice),
    [accountSupplies, assetsPrice]
  );

  const rows: LendPositionAssetRow[] = map(assetInfos, ({ assetId, ...info }) => ({
    ...info,
    assetId,
    name: info.symbol,
    price: assetsPrice?.[assetId] ?? 0,
    accountSupplied: accountSupplies?.[assetId] ?? 0,
    supplyMarket: marketStatus?.[assetId]?.totalSupply ?? 0,
    supplyRate: marketStatus?.[assetId]?.supplyRate ?? 0,
    effectiveAPYLoading: marketStatus?.[assetId]?.effectiveAPYLoading ?? false,
    borrowMarket: marketStatus?.[assetId]?.totalBorrow ?? 0,
    borrowRate: marketStatus?.[assetId]?.borrowRate ?? 0,
    supplyRewardApy: supplyRewardApys?.[assetId] ?? 0,
    earned: accountEarned?.[assetId] ?? 0,
    isCollateral: collaterals?.includes(assetId) || false
  })).filter(a => {
    return assetsMarket?.[a.assetId]?.toJSON()?.state === 'Active' && a.accountSupplied > 0;
  });

  const { openModal, holder } = useModal(
    LendWithdrawModal,
    { size: '500px' },
    { assetInfos: rows }
  );
  const { openModal: openCollateralModal, holder: collateralModalHolder } = useModal(
    CollateralModal,
    { size: '500px' }
  );

  const handleClickLend = useCallback(
    (token: LendPositionAssetRow) => {
      openModal({
        tabKey: SupplyActionType.Lend,
        assetId: token.assetId
      });
    },
    [openModal]
  );

  const handleClickWithdraw = useCallback(
    (token: LendPositionAssetRow) => {
      openModal({
        tabKey: SupplyActionType.Withdraw,
        assetId: token.assetId
      });
    },
    [openModal]
  );

  const columns = useMemo<StyledDataGridColumn<LendPositionAssetRow>[]>(
    () => [
      {
        name: 'name',
        title: 'Assets',
        width: '1.5fr',
        isAvatar: true,
        render: ({ data: { name, assetId } }) => (
          <AssetColumn
            symbol={name}
            isLiquidationFree={checkIsCollateralLiquidationFree(assetId)}
          />
        )
      },
      {
        name: 'supplyMarket',
        title: 'Supplied',
        width: '1.5fr',
        render: ({ data: { accountSupplied, price } }) => (
          <Stack gap="0">
            <Text>{balanceFormatter(accountSupplied)}</Text>
            <SmallText skin="secondary">
              {formatNumber(accountSupplied * price, { output: 'currency' })}
            </SmallText>
          </Stack>
        )
      },
      {
        name: 'apyEarned',
        title: 'APY',
        width: '1.5fr',
        render: ({ data: { name, supplyRate, supplyRewardApy, effectiveAPYLoading, assetId } }) =>
          effectiveAPYLoading ? (
            <Skeleton.Button variant="round" />
          ) : (
            <Stack gap="0">
              <Inline gap="0.25rem">
                <Text>{formatNumber(supplyRate, { output: 'percent' })}</Text>
                {assetId === config.sReplayAssetId && <Tooltip content="Effective APY" />}
              </Inline>

              {supplyRewardApy && supplyRewardApy > 0 ? (
                <Tooltip
                  content={`This is farming rewards for supplying ${name}. It is calculated by per day compounding.`}
                >
                  <TokenPill
                    name={config.nativeToken.toUpperCase()}
                    content={formatNumber(supplyRewardApy, {
                      output: 'percent',
                      decimal: 1,
                      threshold: { min: 0.01, max: 100 }
                    })}
                  />
                </Tooltip>
              ) : null}
            </Stack>
          )
      },
      {
        name: 'isCollateral',
        title: 'Collateral',
        width: '1fr',
        render: ({ data: { isCollateral, assetId, name, accountSupplied, price } }) => (
          <Tooltip
            content="Supplied balance is 0; Supply assets first to enable collateral"
            disabled={accountSupplied > 0}
          >
            <Toggle
              checked={isCollateral}
              onChange={() => {
                openCollateralModal({
                  tokenId: assetId,
                  tokenName: name,
                  tokenPrice: price,
                  accountSupplied,
                  isCollateral
                });
              }}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )
      },
      {
        name: 'action',
        width: '2fr',
        title: '',
        justifyContent: 'flex-end',
        render: ({ data }) => (
          <Inline gap="0.5rem">
            <Button
              skin="secondary"
              onClick={e => {
                e.stopPropagation();
                handleClickLend(data);
              }}
            >
              Lend More
            </Button>
            <Button
              skin="secondary"
              onClick={e => {
                e.stopPropagation();
                handleClickWithdraw(data);
              }}
            >
              Withdraw
            </Button>
          </Inline>
        )
      }
    ],
    [checkIsCollateralLiquidationFree, handleClickLend, handleClickWithdraw, openCollateralModal]
  );

  return (
    <>
      <Collapse
        expand
        header={
          <Inline justifyContent="space-between" alignItems="center" inset="0 0 0 0.5rem">
            <H3>My Lent Position</H3>
            <StaticPill>
              <Inline gap="0.5rem" alignItems="center">
                <SmallText as="span">Supplied</SmallText>
                <H5 as="span">{formatNumber(totalSupplyValue, { output: 'currency' })}</H5>
              </Inline>
            </StaticPill>
          </Inline>
        }
      >
        <StyledDataGrid<LendPositionAssetRow>
          loading={!dataIsReady}
          columns={columns}
          data={rows}
          classNames={{ row: 'clickable' }}
        />
      </Collapse>
      {holder}
      {collateralModalHolder}
    </>
  );
};

export default MyLendPositions;
